import React from 'react'
import './InputDetail.css'
import { useRef } from 'react'

export default function InputDetail({el, regular, format, max}) {
  const data = useRef()
  function formatData() {
    if (regular) {
      if (format) {
        data.current.value = data.current.value.replace(/\s+/g, '').replace(/[^\d]/g, "").replace(regular, '$1:')
      } else if (format === "mobile"){
        data.current.value = data.current.value.replace(/\s+/g, '').replace(/[^\d]/g, "")
      }
    }
  }

  return (
    <input maxLength={max} ref={data} type={el[1]} onChange={() => {formatData(this)}} className='input-detail' placeholder={el[0]} />
  )
}
