import React, {useState} from 'react'
import './PhotoSelector.css'


export default function PhotoSelector({photos}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextPhoto = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevPhoto = () => {
      setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
      );
  };


  return (
      <div className='photo-selector__main-block'>
          <button className='photo-seelctor__button prev' onClick={prevPhoto}></button>
          <button className='photo-seelctor__button next' onClick={nextPhoto}></button>
          <img className='photo-selector__main-image' src={photos[currentIndex]} />
      </div>
  )
}
