export const catigories = {
"graduation-flower-bouquets": {
        "title": "Kwiaty na ukończenie studiów",
        "description": "Świętuj ukończenie studiów w Polsce z przystępnymi bukietami kwiatów od Flowers Poland. Ciesz się dostawą tego samego dnia i spraw, by ich dzień był niezapomniany."
    },
    "grand-opening-flowers": {
        "title": "Kwiaty na wielkie otwarcie",
        "description": "Świętuj swoje wielkie otwarcie z oszałamiającymi stoiskami kwiatowymi, które zostawią niezatarte wrażenie."
    },
    "condolence": {
        "title": "Stojaki z kwiatami kondolencyjnymi",
        "description": "Zapoznaj się z naszą kolekcją stojaków z kwiatami kondolencyjnymi, starannie zaprojektowanych, aby wyrazić twoje współczucie i wsparcie w trudnych chwilach."
    },
    "wedding-flower-bouquets": {
        "title": "Kwiaty na wesele",
        "description": "Przekształć swój wyjątkowy dzień wspaniałymi kwiatami ślubnymi, zaprojektowanymi tak, aby idealnie uzupełnić twoją uroczystość i stworzyć niezapomniane wspomnienia."
    },
    "anniversary-flower-bouquets": {
        "title": "Kwiaty na rocznicę",
        "description": "Świętuj swoją miłość za pomocą kwiatów na rocznicę, które mówią wiele i tworzą trwałe wspomnienia."
    },
    "birthday-flower-bouquets": {
        "title": "Kwiaty urodzinowe i bukiety",
        "description": "Rozświetl ich szczególny dzień żywymi kwiatami urodzinowymi i bukietami, które uchwycą radość świętowania."
    },
    "congratulation-flower-bouquets": {
        "title": "Kwiaty gratulacyjne",
        "description": "Wyślij swoje szczere gratulacje za pomocą bukietu, który świętuje ich sukces i radość."
    },
    "get-well-soon-flower-bouquets": {
        "title": "Kwiaty na zdrowie i bukiety",
        "description": "Wyślij odrobinę komfortu i radości za pomocą naszych kwiatów na zdrowie i bukietów, idealnych do podnoszenia na duchu i wspierania w powrocie do zdrowia."
    },
    "newborn-flower-bouquets": {
        "title": "Kwiaty na powitanie noworodka",
        "description": "Świętuj przyjście na świat nowego dziecka z naszą wspaniałą kolekcją kwiatów i bukietów dla noworodków, zaprojektowanych, aby powitać maluszka z miłością i radością."
    },
    "romantic": {
        "title": "Kwiaty i bukiety romantyczne",
        "description": "Wyraź swoje najgłębsze uczucia za pomocą naszej wyjątkowej gamy kwiatów i bukietów romantycznych, idealnych na każdą specjalną okazję."
    },
    "sorry-flower-bouquets": {
        "title": "Kwiaty przeprosinowe i bukiety",
        "description": "Przeproś za pomocą szczerych kwiatów przeprosinowych i bukietów, idealnych do wyrażania swoich szczerych przeprosin i naprawy relacji."
    },
    "engagement-flower-bouquets": {
        "title": "Kwiaty i bukiety na zaręczyny",
        "description": "Świętuj swoje zaręczyny z naszą wyjątkową selekcją kwiatów i bukietów, idealnych do zaznaczenia tej wyjątkowej chwili."
    },
    "baby-hampers": {
        "title": "Kosze dla niemowląt",
        "description": "Kosze dla niemowląt"
    },
    "fruit-hampers": {
        "title": "Kosze owocowe",
        "description": "Kosze owocowe"
    },
    "gourmet-hampers": {
        "title": "Kosze z jedzeniem premium",
        "description": "Kosze z jedzeniem premium"
    },
    "tea-set-hampers": {
        "title": "Kosze z herbatą",
        "description": "Kosze z herbatą"
    },
    "wellness-hampers": {
        "title": "Kosze na zdrowie",
        "description": "Kosze na zdrowie"
    },
    "organic-hampers": {
        "title": "Kosze organiczne",
        "description": "Kosze organiczne"
    },
    "chocolate-hampers": {
        "title": "Kosze czekoladowe",
        "description": "Kosze czekoladowe"
    },
    "corporate-hampers": {
        "title": "Kosze korporacyjne",
        "description": "Kosze korporacyjne"
    },
    "halal-food-hampers": {
        "title": "Kosze z jedzeniem halal",
        "description": "Kosze z jedzeniem halal"
    },
    "wine-gifts": {
        "title": "Kosze z winem",
        "description": "Zapoznaj się z naszą wyjątkową selekcją koszy z winem, idealnych do świętowania każdej okazji. Każdy kosz jest starannie skomponowany z premium win i przysmaków gastronomicznych, zapewniając niezapomniany prezent."
    },
    "baby-breath-bouquet-poland": {
        "title": "Kwiaty i bukiety z gipsówki",
        "description": "Zapoznaj się z naszymi delikatnymi kwiatami gipsówki i bukietami, idealnymi do dodania elegancji i uroku każdej okazji."
    },
    "calla-lily-flower-bouquets": {
        "title": "Kwiaty i bukiety z lilii calla",
        "description": "Zapoznaj się z naszą kolekcją kwiatów i bukietów z lilii calla, idealnymi do dodania elegancji każdej okazji."
    },
    "carnation-bouquet-flowers": {
        "title": "Kwiaty i bukiety goździków",
        "description": "Zapoznaj się z naszą wyjątkową kolekcją kwiatów i bukietów goździków, idealnych na każdą okazję i stworzonych, by zachwycać."
    },
    "eustoma-flower-bouquets": {
        "title": "Kwiaty i bukiety eustomy",
        "description": "Zapoznaj się z naszą kolekcją eleganckich kwiatów eustomy i bukietów, idealnych na każdą specjalną okazję."
    },
    "hydrangea-bouquet-flowers": {
        "title": "Kwiaty i bukiety hortensji",
        "description": "Zapoznaj się z naszymi eleganckimi kwiatami hortensji i bukietami, idealnymi do dodania uroku i piękna każdej okazji."
    },
    "lilies": {
        "title": "Bukiety lilii",
        "description": "Zapoznaj się z naszą oszałamiającą kolekcją bukietów lilii, idealnych na każdą okazję i zaprojektowanych, by robić wrażenie."
    },
    "rose-bouquets-poland": {
        "title": "Kwiaty i bukiety róż",
        "description": "Zapoznaj się z naszą kolekcją wyjątkowych bukietów róż na każdą specjalną okazję."
    },
    "sunflower-bouquets-poland": {
        "title": "Bukiety słoneczników",
        "description": "Rozświetl każdą okazję naszymi żywymi bukietami słoneczników, promieniującymi radością i ciepłem."
    },
    "tulip-flower-bouquets": {
        "title": "Kwiaty i bukiety tulipanów",
        "description": "Zapoznaj się z naszą kolekcją kwiatów i bukietów tulipanów, idealnych na każdą okazję."
    },
    "newborn-fnb": {
        "title": "Bukiet balonów na powitanie noworodka",
        "description": "Bukiet balonów na powitanie noworodka"
    },
    "get-well-soon-fnb": {
        "title": "Bukiet balonów na zdrowie",
        "description": "Bukiet balonów na zdrowie"
    },
    "happy-birthday-fnb": {
        "title": "Bukiet balonów urodzinowych",
        "description": "Bukiet balonów urodzinowych"
    },
    "congratulations": {
        "title": "Balony i kwiaty gratulacyjne",
        "description": "Świętuj szczególne chwile w życiu za pomocą naszego pięknego wyboru balonów i kwiatów gratulacyjnych, dostarczanych prosto do twoich drzwi."
    },
    "anniversary-fnb": {
        "title": "Balony i kwiaty na rocznicę",
        "description": "Świętuj swoją rocznicę wspaniałymi balonami i kwiatami od Flowers Poland, idealnymi na każdą szczególną chwilę."
    },
    "graduation-fnb": {
        "title": "Bukiet balonów na ukończenie studiów",
        "description": "Bukiet balonów na ukończenie studiów"
    }

}

export const products = [
    {
        "id": 0,
        "name": "Morwa",
        "handle": "mulberry",
        "description": "Ten oszałamiający bukiet składa się z żywych różowych róż, doskonale uzupełnionych bujną zielenią liści i delikatnymi białymi akcentami. Ta aranżacja, zawinięta w elegancki szary papier, emanuje wdziękiem i pięknem, dzięki czemu jest idealnym prezentem na każdą wyjątkową okazję lub uroczystość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Mulberry-2a.jpg",
            "/images/products/Mulberry-1a.jpg"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Fioletowe Róże. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1,
        "name": "Kryształ",
        "handle": "crystal",
        "description": "Ten uderzający bukiet przedstawia wyjątkową i urzekającą kompozycję niebieskich róż, otoczoną delikatnym, białym oddechem dziecka i bujną zielenią. Żywe niebieskie róże służą jako centralny element, tworząc odważną i zapadającą w pamięć kompozycję. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Crystal-2a.jpg",
            "/images/products/Crystal-1a.jpg"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 2,
        "name": "Przewiewny",
        "handle": "breezy",
        "description": "Ten żywy bukiet zawiera wesołą kompozycję słoneczników akcentowaną delikatnym oddechem dziecka. Bukiet ten, zawinięty w stylowy papier pakowy i przewiązany delikatną białą wstążką, będzie idealnym prezentem, który uświetni każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Breezy-2a.jpg?v=1721182381",
            "/images/products/Breezy-1a.jpg?v=1721182381"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki.  Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 3,
        "name": "Boski",
        "handle": "divine",
        "description": "Ten bukiet, będący symfonią kwiatowego piękna, łączy w sobie żywy, gorący róż z delikatnymi goździkami i bujnymi białymi kwiatami, a wszystko to połączone wdzięczną różową wstążką. To wykwintny prezent, który oddaje istotę wdzięku i kobiecości.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Divine-2a-171538.jpg?v=1720875692",
            "/images/products/Divine-1a.jpg?v=1720840956"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj róże.  Mamo. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 4,
        "name": "Burgundia",
        "handle": "burgundy",
        "description": "Ten bukiet to namiętna ekspozycja ciemnoróżowych i nieskazitelnych białych róż, pomysłowo zaaranżowanych z delikatną zielenią. Jest pięknie opakowany w delikatny różowy papier i zabezpieczony dużą, jedwabiście białą kokardką, tworząc wspaniały prezent, który wyraża miłość i podziw.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Burgundy-2a-358164.jpg?v=1720875616",
            "/images/products/Burgundy-1a.jpg?v=1720840795"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 5,
        "name": "Księżniczka",
        "handle": "princess",
        "description": "Ten bukiet to wizja miękkiej elegancji, z bujną kopułą pastelowych różowych hortensji, symbolizujących szczere emocje. Kwiaty są umieszczone na szczycie delikatnego, białego oddechu dziecka, tworząc podstawę przypominającą chmurę.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Princess-2b.jpg?v=1720796844",
            "/images/products/Princess-1b.jpg?v=1720796844"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 6,
        "name": "Fleur",
        "handle": "fleur",
        "description": "Uroczy zestaw pastelowych kwiatów, ten bukiet łączy lawendowe, różowe i kremowe róże z uzupełniającymi się sezonowymi kwiatami. Każdy kwiat jest starannie ułożony i zaprezentowany w wdzięcznym różu, oferując ponadczasowy wyraz elegancji i piękna.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Fleur-2a.jpg?v=1720889998",
            "/images/products/Fleur-1a_3eeaf10a-547b-474d-bf4b-54d10af8b5d4-358790.jpg?v=1720962298"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Tulipan. Goździki. Eustoma. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 7,
        "name": "Pasja",
        "handle": "passion",
        "description": "Podkreśl swoje chwile dzięki naszemu czarującemu bukietowi składającemu się z 10 czerwonych i różowych róż ozdobionych delikatną kaspią. Żywa symfonia kolorów tworzy oszałamiający efekt wizualny, a misterna aranżacja emanuje elegancją. Idealny do wyrażania miłości i świętowania specjalnych okazji, ten bukiet jest ponadczasowym wyrazem uczuć.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Passion-2a.jpg?v=1721188571",
            "/images/products/Passion-1a.jpg?v=1721188571"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 8,
        "name": "Euforia",
        "handle": "euphoria",
        "description": "Ten zachwycający bukiet w kształcie stożka od Flowers Poland składa się z pastelowej mieszanki lawendowych róż, różowych goździków i cytrynowożółtych kwiatów, akcentowanych różnorodnymi różowo-białymi goździkami. Ukryty w eleganckim stożku i wykończony kremową wstążką, stanowi fantazyjny akcent na każdą radosną okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Euphoria-2f-988112.jpg?v=1724775599",
            "/images/products/Euphoria-1f.jpg?v=1724724608"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 9,
        "name": "Lizak",
        "handle": "lollipop",
        "description": "Oszałamiający bukiet składający się z żywej mieszanki róż i kwiatów, elegancko opakowany w elegancki czarny papier. Bogata czerwień, delikatny róż i pastelowe odcienie tworzą uderzający kontrast, idealny na każdą okazję. Idealny do wyrażania miłości, uznania lub świętowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/lollipop-2b.jpg?v=1721211108",
            "/images/products/lollipop-1b.jpg?v=1721211108"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Mattiola.  Eustomy .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 10,
        "name": "Bebe",
        "handle": "baby",
        "description": "Ten elegancki bukiet zawiera oszałamiającą mieszankę czerwonych i różowych kwiatów, tworząc harmonijną i romantyczną kompozycję. Żywe czerwone róże uzupełniają delikatne różowe goździki, małe białe kwiaty i bujna zieleń, dodając głębi i tekstury bukietowi. Akcenty czerwonych jagód dodają dodatkowego koloru i świątecznego akcentu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Bebe-2a-696216.jpg?v=1721135616",
            "/images/products/Bebe-1a.jpg?v=1721100824"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Tulipan.  Goździki.  Spryskaj róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 11,
        "name": "Foreva",
        "handle": "foreva",
        "description": "Poczuj romans naszego zachwycającego bukietu składającego się z pięciu bogatych czerwonych róż i róż z różowymi końcówkami, z wdziękiem objętych delikatnym szeptem oddechu dziecka. Ten czarujący zestaw emanuje miłością i czułością, tworząc doskonały wyraz uczuć w wyjątkowych chwilach. Obejmij piękno miłości dzięki tej urzekającej kompozycji kwiatowej.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Foreva-2b.jpg?v=1720707386",
            "/images/products/Foreva-1b.jpg?v=1720707386"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 róż.  Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 12,
        "name": "Kondolencje (ja)",
        "handle": "condolence-i",
        "description": "Wyraź swoje najgłębsze kondolencje za pomocą naszych stojaków na kwiaty ozdobionych nieskazitelnie białymi chryzantemami. Ta elegancka aranżacja zapewnia pocieszenie i spokojny hołd, symbolizując czystość i współczucie w chwilach żałoby. Niech te kwiaty zapewnią pocieszenie i uhonorują zmarłych łaską.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Condolence-_I_-1d.jpg?v=1720145826",
            "/images/products/Condolence-Card-2024_1_9a50d3a6-e056-44b7-9c5b-7b7f03b315d5.jpg?v=1720145831",
            "/images/products/Condolence-_I_-2d.jpg?v=1720145826"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 13,
        "name": "Gołąb",
        "handle": "dovey",
        "description": "„Najlepsza miłość to taka, która budzi duszę, która sprawia, że ​​sięgamy po więcej, która rozpala ogień w naszych sercach i przynosi spokój umysłom. To właśnie mam nadzieję dać Ci na zawsze”.  - Notatnik",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/30fd2f07-5fce-453b-954d-a0dcf0e09e37.jpg?v=1650767314",
            "/images/products/WhatsAppImage2022-04-23at12.10.49PM_1.jpg?v=1650767314"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 25cm*30cm"
    },
    {
        "id": 14,
        "name": "Mistyk",
        "handle": "mystique",
        "description": "Ten bukiet emanuje spokojną elegancją dzięki delikatnym różowym różom i delikatnym kwiatom, położonym pośród bujnej zieleni. Zapakowany w jasnoniebieski papier i ozdobiony falującą jasnoniebieską wstążką, stanowi spokojną i wyrafinowaną ofertę.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Mystique-1b.jpg?v=1721350189",
            "/images/products/Mystique-2a.jpg?v=1720749015"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 15,
        "name": "Dobrobyt (ja)",
        "handle": "prosperity-i",
        "description": "Odsłoń blask orchidei w kolorze złotofioletowym podczas wszystkich uroczystych otwarć firm. Luksusowe orchidee, ozdobione złotymi odcieniami, tworzą wizualnie bogatą ekspozycję, obiecując sukces i dobrobyt dla Twojego kwitnącego przedsięwzięcia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Prosperity-_I_-1d.jpg?v=1720492483",
            "/images/products/Grand-Opening-Card-2024_b4dff7fc-72d5-4ad1-87ad-9da4b88caf33.jpg?v=1720492488",
            "/images/products/Prosperity-_I_-2d.jpg?v=1720492482"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidee. Lilie . Helikonia. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 16,
        "name": "Wenecja",
        "handle": "venice",
        "description": "Uroczy bukiet delikatnych różowych i białych róż, akcentowany gałązkami lawendy i eukaliptusa. Owinięta w delikatny różowy papier i ozdobiona pasującą wstążką, ta kompozycja emanuje elegancją i wdziękiem, idealną do wyrażenia swoich serdecznych uczuć.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Venice-2a.jpg?v=1720920608",
            "/images/products/Venice-1a.jpg?v=1720920608"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 17,
        "name": "Kondolencje (II)",
        "handle": "condolence-ii",
        "description": "Składając szczere kondolencje, nasze kwietniki stanowią pogodną mieszankę białych chryzantem, czystych białych gerber i delikatnych orchidei, ozdobionych pełnym szacunku czarnym dressingiem. Ta elegancka aranżacja stanowi godny hołd, symbolizując współczucie i pamięć, zapewniając pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Condolence-_II_-1d.jpg?v=1720181584",
            "/images/products/Condolence-Card-2024_1_0c060985-030b-49d9-ae02-9c61ddb4b885.jpg?v=1720181595",
            "/images/products/Condolence-_II_-2d.jpg?v=1720181584"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Eustoma. Orchidea. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 18,
        "name": "Sny",
        "handle": "dreams",
        "description": "Wykwintny bukiet białych kalii, których kwiaty w kształcie trąbki emanują wyrafinowaniem, uzupełnione świeżymi zielonymi liśćmi. Każdy kwiat jest elegancko zamknięty w chrupiącym, białym papierze do pakowania i wykończony wdzięczną białą satynową wstążką, tworząc luksusową i wyrafinowaną kompozycję kwiatową.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z lilii calla"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Dreams-2a.jpg?v=1721189107",
            "/images/products/Dreams-1a.jpg?v=1721189107"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie kalii. Listowie"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 19,
        "name": "Everlast",
        "handle": "everlast",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Everlast1A.jpg?v=1667886455",
            "/images/products/Everlast2A.jpg?v=1667886455"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Suszona lawenda"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 20,
        "name": "Łaska",
        "handle": "grace",
        "description": "Bukiet ten składa się z mieszanki pastelowych róż, białych kwiatów i delikatnego oddechu dziecka, zaakcentowanego bujną zielenią. Owinięty w delikatny brzoskwiniowy papier, przewiązany prostą wstążką, tworzy elegancką i uroczą kompozycję, idealną na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Grace-2a.jpg?v=1721101071",
            "/images/products/Grace-1a.jpg?v=1721101071"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździk . Mattiola. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 21,
        "name": "Domek",
        "handle": "cottage",
        "description": "Bukiet ten składa się z mieszanki białych, różowych i różowych róż menta, uzupełnionych delikatnymi żółtymi kwiatami i bujną zielenią. Opakowany w naturalny brązowy papier, przewiązany prostą wstążką, dzięki czemu będzie uroczym i eleganckim wyborem na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Cottage-2a.jpg?v=1721101303",
            "/images/products/Cottage-1a.jpg?v=1721101303"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Goździk .  Różany spray.  Stokrotka.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 22,
        "name": "Remi",
        "handle": "queen",
        "description": "Obejmij delikatne piękno naszego bukietu składającego się z 6 uroczych róż ozdobionych delikatną elegancją zakurzonego młynarza, a wszystko to gustownie zaprezentowane w uroczym różowym opakowaniu. Ten czarujący zestaw jest celebracją wdzięku i subtelności, dzięki czemu jest idealnym prezentem na urodziny, uroczystości lub wyrazy podziwu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Remi-2a.jpg?v=1720882958",
            "/images/products/Remi-1a.jpg?v=1720882958"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*36cm"
    },
    {
        "id": 23,
        "name": "Kondolencje (III)",
        "handle": "condolence-iii",
        "description": "W chwilach smutku nasze Stojaki z kwiatami kondolencyjnymi, zawierające nieskazitelnie białe chryzantemy, delikatne róże szampańskie i delikatne gerbery, oferują pocieszenie i pełen wdzięku hołd. Ta elegancka aranżacja symbolizuje współczucie i pamięć, zapewniając komfort i wsparcie w chwilach żałoby.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Condolence-_III_-1e.jpg?v=1720145596",
            "/images/products/Condolence-Card-2024_1_6ce56357-6a12-4f2d-a178-1534097f303b.jpg?v=1720145603",
            "/images/products/Condolence-_III_-2e.jpg?v=1720145597"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Mamo. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 24,
        "name": "Hipis",
        "handle": "hippy",
        "description": "Poczuj przypływ radości dzięki naszemu dużemu, tęczowemu bukietowi oddechu dla dziecka. Ta ekstrawagancka aranżacja, pełna żywego spektrum odcieni, emanuje szczęściem i świętowaniem. Idealny do dodania koloru każdemu wydarzeniu lub przestrzeni, podniesie na duchu i stworzy kalejdoskop niezapomnianych chwil wypełnionych ciepłem i radością.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Hippy-3.jpg?v=1625094566"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 45cm*55cm"
    },
    {
        "id": 25,
        "name": "Czar",
        "handle": "charm",
        "description": "Ten oszałamiający bukiet składa się z dużej, bujnej niebieskiej hortensji, pięknie zaakcentowanej delikatną zielenią. Zapakowany w elegancki szary papier i przewiązany pasującą wstążką, jest idealnym prezentem na każdą okazję, emanującym elegancją i urokiem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Charm-2a.jpg?v=1720745286",
            "/images/products/Charm-1a.jpg?v=1720745286"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 26,
        "name": "Sukces (ja)",
        "handle": "success-i",
        "description": "Rozpal bogactwo podczas wszystkich wielkich otwarciach biznesowych dzięki stoisku kwiatowemu Opulent Purple Wave Jumbo. Wysokie fioletowe kapustne, wesołe gerbery, delikatne eustomy i żywe fioletowe orchidee są akcentowane przez egzotyczne czerwone kwiaty imbiru. Cała aranżacja jest otoczona bogactwem falistej, czerwonej aksamitnej listwy, tworząc wspaniały i wyrafinowany element centralny.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "661.55",
        "inclTax": "661.55",
        "img": [
            "/images/products/Success-_I_-1c.jpg?v=1720524540",
            "/images/products/Grand-Opening-Card-2024_513cdb93-6f4a-4ac6-8149-2b9000996b2f.jpg?v=1721487989",
            "/images/products/Success-_I_-2c-560247.jpg?v=1720554458"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Gerbera . Eustoma. Orchidee. Czerwony imbir. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 27,
        "name": "Kondolencje (VIII)",
        "handle": "condolence-viii",
        "description": "Wyrażając sympatię do elegancji, nasze Stojaki z kwiatami kondolencyjnymi prezentują spokojne białe chryzantemy i czysto białe gerbery, ozdobione gustowną gamą listew przypodłogowych z motywem bieli i błękitu. Ta pełna wdzięku aranżacja zapewnia pocieszenie i godny hołd w chwilach smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Condolence-_VIII_-1d.jpg?v=1720157370",
            "/images/products/Condolence-Card-2024_1_7f8ca9d3-61a0-4607-b3ff-5be3477b4291.jpg?v=1720157383",
            "/images/products/Condolence-_VIII_-2d.jpg?v=1720157370"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Mattiola. Sztuczne ostróżki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 28,
        "name": "Ukończenie studiów I",
        "handle": "graduation-i",
        "description": "Ten zachwycający bukiet składa się z jasnych słoneczników i białych kwiatów, którym towarzyszy uroczy pluszowy miś w stroju kończącym szkołę. Zapakowany w rustykalny brązowy papier i ozdobiony wstążką, to idealny prezent, aby uczcić osiągnięcia akademickie bliskiej osoby.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-_I_-2a.jpg?v=1720665693",
            "/images/products/Graduation-_I_-1a.jpg?v=1720665692"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 29,
        "name": "Tęcza",
        "handle": "rainbow",
        "description": "„Czasami dwoje ludzi musi się rozstać, aby zdać sobie sprawę, jak bardzo muszą się ponownie połączyć.” - Anonimowy",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2021-09-09at1.48.51PM_1.jpg?v=1641311182",
            "/images/products/WhatsAppImage2021-09-09at12.59.22PM_1.jpg?v=1641311175"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Róże.  Eustomy .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 45cm*45cm"
    },
    {
        "id": 30,
        "name": "Buziaki",
        "handle": "kisses",
        "description": "Ten żywy bukiet składa się z oszałamiającej gamy ciemnoczerwonych róż i goździków, akcentowanych tulipanami i jagodami dziurawca. Zapakowany w elegancki kremowy papier i ozdobiony złotą etykietą Flowers Poland emanuje wyrafinowaniem i pasją, dzięki czemu jest idealnym prezentem do wyrażania głębokich emocji i podziwu.",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Kisses-2a-891668.jpg?v=1721570879",
            "/images/products/Kisses-1a.jpg?v=1721485646"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Tulipan"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*28cm"
    },
    {
        "id": 31,
        "name": "Smooch",
        "handle": "smooch",
        "description": "Ten elegancki bukiet zawiera urzekającą mieszankę fioletowych i białych kwiatów, w tym delikatnych tulipanów, róż i lisianthus, a wszystko to pięknie owinięte w miękki papier o neutralnej tonacji. Harmonijne połączenie fioletu i bieli tworzy wyrafinowaną i pełną wdzięku aranżację, idealną do wyrażenia podziwu lub celebrowania wyjątkowych chwil. Bukiet ten wnosi odrobinę elegancji i spokoju do każdego otoczenia, dzięki czemu jest idealnym wyborem na każdą okazję.",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Smooch-2a.jpg?v=1724817132",
            "/images/products/Smooch-1a.jpg?v=1724817132"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Astromeria. Eustoma. Tulipan. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 32,
        "name": "Ukończenie II",
        "handle": "graduation-ii",
        "description": "„Wszystkie nasze marzenia mogą się spełnić, jeśli mamy odwagę je realizować.” - Walta Disneya",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/WhatsAppImage2022-08-12at1.49.21PM_2.jpg?v=1660283463",
            "/images/products/WhatsAppImage2022-08-12at1.49.21PM_1_1.jpg?v=1660283463"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Suszone Kwiaty"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 33,
        "name": "Ukończenie III",
        "handle": "graduation-iii",
        "description": "„Tylko ci, którzy odważą się ponieść wielką porażkę, mogą osiągnąć znacznie.” -  Bobby'ego Kennedy'ego",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-III---frontc-min.jpg?v=1641329755",
            "/images/products/Graduation-III---downb-min-619303.jpg?v=1671685312",
            "/images/products/Graduation-III---ZoomB-min.jpg?v=1641329660"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Suszone Kwiaty"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 34,
        "name": "Wesoły",
        "handle": "cheerful",
        "description": "Rozkoszuj się promieniującym ciepłem naszego czarującego bukietu słoneczników z 3 łodygami. Trzy żywe słoneczniki, każdy symbol radości i pozytywnego nastawienia, stoją wysoko w tej zachwycającej aranżacji. Otoczony bujną zielenią bukiet emanuje naturalnym urokiem, idealnie nadaje się do rozjaśnienia każdej przestrzeni lub przekazania serdecznych życzeń. Wykorzystaj energię słońca dzięki temu wesołemu zestawowi.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Cheerful-1aa-656132.jpg?v=1705328636",
            "/images/products/Cheerful-2aa.jpg?v=1705247868"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Thlaspi. Kwiat Pszenicy. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 35,
        "name": "Hipopotam",
        "handle": "hippo",
        "description": "Ten czarujący bukiet zawiera hipnotyzującą gamę tęczowych oddechów dziecka, delikatnie ułożonych i zawiniętych w elegancki papier. Miękkie, pastelowe odcienie tworzą marzycielski i fantazyjny efekt, dzięki czemu ten bukiet jest idealnym wyborem, aby dodać odrobinę magii każdej okazji. Niezależnie od tego, czy będzie to wyjątkowy prezent, czy żywa dekoracja, ten bukiet emanuje radością i kreatywnością, wywołując uśmiech na każdej twarzy.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Hippo-2a.jpg?v=1724809619",
            "/images/products/Hippo-1a.jpg?v=1724809619"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 36,
        "name": "Ukończenie IV",
        "handle": "graduation-iv",
        "description": "„Niektórzy mężczyźni widzą rzeczy takimi, jakie są i pytają, dlaczego. Inni śnią o rzeczach, których nigdy nie było, i pytają, dlaczego nie. -  George'a Bernarda Shawa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-IV---FrontnewB-min.jpg?v=1641329652",
            "/images/products/Graduation-IV---down-min.jpg?v=1641329647",
            "/images/products/Graduation-IV---side-min.jpg?v=1641329639"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 37,
        "name": "Ślubne I",
        "handle": "bridal-i",
        "description": "Do tej pięknej aranżacji ślubnej dołączony będzie wazon =)",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na wesele",
            "Kwiaty i bukiety na zaręczyny"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Bridal-_I_-1.jpg?v=1625094574",
            "/images/products/Bridal-_I_-2-299560.jpg?v=1671685020",
            "/images/products/Bridal-_I_-3.jpg?v=1625094574"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "3 róże ogrodowe.  Eustomy .  Spryskaj róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 38,
        "name": "Stokrotka",
        "handle": "daisy",
        "description": "Ten uroczy bukiet zawiera bujną kompozycję delikatnych kwiatów rumianku i oddechu dziecka. Zapakowany w elegancki beżowy papier i przewiązany wstążką emanuje prostym, a zarazem wyrafinowanym pięknem, dzięki czemu będzie idealnym prezentem na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Daisy-2a.jpg?v=1720790378",
            "/images/products/Daisy-1a.jpg?v=1720790377"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Stokrotka. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 39,
        "name": "Gwiaździsty",
        "handle": "starry",
        "description": "Ten bukiet składa się z eleganckich różowych lilii otoczonych bujną zielenią i delikatnymi białymi kwiatami wypełniającymi. Zapakowany w delikatny różowy papier i przewiązany wstążką emanuje wdziękiem i urokiem, idealny na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Bukiety lilii"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Starry-2a.jpg?v=1721101921",
            "/images/products/Starry-1a.jpg?v=1721101921"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość:30cm*36cm"
    },
    {
        "id": 40,
        "name": "Obietnica",
        "handle": "promise",
        "description": "Wyraź swoją pasję za pomocą naszego efektownego bukietu czerwonych tulipanów. Ta urzekająca kompozycja składa się z żywych czerwonych tulipanów, elegancko owiniętych w elegancki czarny papier, tworząc dramatyczny kontrast, który podkreśla piękno kwiatów. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Promise-2a.jpg?v=1721214644",
            "/images/products/Promise-1a.jpg?v=1721214644"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 41,
        "name": "Ukończenie szkoły V",
        "handle": "graduation-v",
        "description": "„Horyzont pochyla się do przodu, oferując przestrzeń do postawienia nowych kroków zmian”.  - Maja Angelou",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Graduation-V---frontnewC-min.jpg?v=1641329533",
            "/images/products/Graduation-V---downB-min.jpg?v=1641329528",
            "/images/products/Graduation-V---zoomB-min.jpg?v=1641329521"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 42,
        "name": "Ukończenie VI",
        "handle": "graduation-vi",
        "description": "„Nikt nie lubi porażek, ale porażka jest istotną częścią życia i nauki. Jeśli twój mundur nie jest brudny, oznacza to, że nie brałeś udziału w grze. -  Bena Bernanke",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Graduation-VI---FrontB-min.jpg?v=1641329516",
            "/images/products/Graduation-VI---downB-min.jpg?v=1641329508",
            "/images/products/Graduation-VI---side2B-min.jpg?v=1641329420"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Spryskaj róże. Spryskaj goździki. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 43,
        "name": "Zakończenie VII",
        "handle": "graduation-vii",
        "description": "Semantyka tego bukietowego makijażu polega na estetycznym połączeniu słoneczników, róż i połączeniu unikalnych składników, aby stworzyć harmonijną kreację.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Graduation-VII---front2-min.jpg?v=1641329414",
            "/images/products/Graduation-VII---Down-min.jpg?v=1641329409",
            "/images/products/Graduation-VII---side3-min.jpg?v=1641329403",
            "/images/products/Graduation-VII---Side-min.jpg?v=1641329395",
            "/images/products/Graduation-VII---Side2-min.jpg?v=1641329390"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 44,
        "name": "Zakończenie VIII",
        "handle": "graduation-viii",
        "description": "Świętuj wyjątkowe ukończenie szkoły za pomocą tego uroczego bukietu z radosnym słonecznikiem w sercu, otoczonym delikatnymi różowymi różami, fioletowymi goździkami i różnorodnymi kwiatami. Pluszowy miś w czapce ukończenia szkoły dodaje całości fantazji, dzięki czemu jest idealnym prezentem pamiątkowym.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-VIII-2a.jpg?v=1720670767",
            "/images/products/Graduation-VIII-1a.jpg?v=1720670767"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Goździk . Eustoma. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 45,
        "name": "Zakończenie IX",
        "handle": "graduation-ix",
        "description": "Bukiet dyplomowy, który oddaje ducha osiągnięć, z wyrazistym, wesołym słonecznikiem otoczonym delikatnymi różowymi różami i rumieńcami. W środku znajduje się pamiątkowy pluszowy miś w czapce absolwenta, dzięki czemu jest to idealny prezent, aby uhonorować kamień milowy absolwenta.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-IX-2b.jpg?v=1720670421",
            "/images/products/Graduation-IX-1b.jpg?v=1720670421"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Spryskaj róże. Róża. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 46,
        "name": "Ukończenie szkoły X",
        "handle": "graduation-x",
        "description": "Ten kapryśny bukiet składa się z mieszanki różowych gerber, fioletowych róż i bujnej zieleni, zwieńczonych zabawną pluszową zabawką z motywem ukończenia szkoły. Zapakowany w elegancki szary papier i przewiązany wstążką, to zabawny i uroczysty prezent dla absolwentów.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Graduation-X-2a.jpg?v=1720669523",
            "/images/products/Graduation-X-1a.jpg?v=1720669524"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róża.Goździk. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 47,
        "name": "Flaming",
        "handle": "flamingo",
        "description": "Ten elegancki bukiet składa się z romantycznego asortymentu delikatnych róż i alstroemerii, uzupełnionych delikatnym, białym dziecięcym oddechem i bujnymi liśćmi. Umieszczony w eleganckim beżowym okrągłym pudełku przewiązanym satynową wstążką, będzie wyrafinowanym prezentem na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Flamingo-2c-393000.jpg?v=1724775599",
            "/images/products/Flamingo-1c.jpg?v=1724723434"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Tulipan. Spryskaj róże. Goździki. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 48,
        "name": "Sentyment",
        "handle": "fondness",
        "description": "Poczuj delikatny urok romantyzmu dzięki naszemu uroczemu bukietowi składającemu się z sześciu delikatnych różowych róż, elegancko otoczonych eterycznym szeptem oddechu dziecka. Ten czarujący zestaw emanuje delikatnością i słodyczą, tworząc doskonały wyraz uczuć w wyjątkowych chwilach. Obejmij piękno miłości dzięki tej urzekającej kompozycji kwiatowej.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-1aa.jpg?v=1704982249",
            "/images/products/Fondness-2aa.jpg?v=1704982249"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 49,
        "name": "Lato",
        "handle": "summer",
        "description": "Rozjaśnij czyjś dzień naszym promiennym bukietem słoneczników, zawierającym trzy wspaniałe słoneczniki otoczone delikatnym oddechem dziecka. Ta wesoła kompozycja, elegancko owinięta w ziemisty, brązowy papier, idealnie nadaje się do wniesienia ciepła i radości na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Summer-1b.jpg?v=1720661167",
            "/images/products/Summer-1a.jpg?v=1720532462"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 50,
        "name": "Samotność",
        "handle": "solitude",
        "description": "Oszałamiający bukiet z delikatnym różem i różami cuppucino, uzupełniony delikatnymi gałązkami lawendy i bujną zielenią. Opakowana w rustykalny brązowy papier i wykończona stylową wstążką, ta kompozycja uosabia ponadczasowe piękno i urok, idealna na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Solitude-2a.jpg?v=1720966021",
            "/images/products/Solitude-1a.jpg?v=1720966021"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Spryskaj róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 51,
        "name": "Kopciuszek",
        "handle": "cinderella",
        "description": "„Najlepsza miłość to taka, która budzi duszę, która sprawia, że ​​sięgamy po więcej, która rozpala ogień w naszych sercach i przynosi spokój umysłom. To właśnie mam nadzieję dać Ci na zawsze”. - Notatnik",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-10-01at10.31.01AM_2.jpg?v=1641343328",
            "/images/products/WhatsAppImage2021-10-01at10.31.01AM_1_1.jpg?v=1641343320"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Goździki.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*30cm"
    },
    {
        "id": 52,
        "name": "Lolita",
        "handle": "lolita",
        "description": "„Wiesz, że to miłość, kiedy wszystko, czego pragniesz, to to, aby ta osoba była szczęśliwa, nawet jeśli nie jesteś częścią jej szczęścia.” – Julia Roberts",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/WhatsAppImage2022-03-18at2.47.42PM_2.jpg?v=1647586515",
            "/images/products/WhatsAppImage2022-03-18at2.47.42PM_1_1.jpg?v=1647586517"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 40cm*50cm"
    },
    {
        "id": 53,
        "name": "Hazel FBS",
        "handle": "hazel",
        "description": "Ta zachwycająca kompozycja składa się z tęczy delikatnych kwiatów w kształcie dziecięcego oddechu, pomysłowo zaprezentowanych w stylowej brązowej torebce. Idealny na każdą okazję, wnosi eksplozję koloru i radości do dnia Twoich bliskich.",
        "type": "Seria Torebek",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Hazel-1a-301832.jpg?v=1720030582",
            "/images/products/Hazel-1b-949460.jpg?v=1720030582"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 54,
        "name": "Zachód słońca FBS",
        "handle": "sunset",
        "description": "Ta słoneczna kompozycja zawiera żywy słonecznik otoczony delikatnym oddechem dziecka, tworząc jasny i radosny obraz. Zapakowany w stylową brązową torebkę z miękką satynową wstążką, jest idealnym prezentem, który przyniesie szczęście i ciepło na każdą okazję.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Sunset-FBS-1a-980965.jpg?v=1720118501",
            "/images/products/Sunset-FBS-2a-418692.jpg?v=1720118501"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 55,
        "name": "Ferro FBS",
        "handle": "ferror",
        "description": "„Miłość pociesza jak słońce po deszczu”. – Williama Szekspira",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-09-01at9.45.49AM_2.jpg?v=1661996808",
            "/images/products/WhatsAppImage2022-09-01at9.45.49AM_1_1.jpg?v=1661996814"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 56,
        "name": "Lowy",
        "handle": "lowy",
        "description": "Świętując wiosnę, ten bukiet Flowers Poland pełen jest wesołych tulipanów w odcieniach wschodu słońca — różu, pomarańczy i żółci — akcentowanych pienistym oddechem dziecka i wyjątkowymi srebrzystymi liśćmi. Zapakowany w miękkie beżowe opakowanie przewiązane jedwabistą wstążką, wywoła uśmiech na każdy dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Lowy-2c-310984.jpg?v=1724775811",
            "/images/products/Lowy-1c.jpg?v=1724724116"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*32cm"
    },
    {
        "id": 57,
        "name": "Nana",
        "handle": "nana",
        "description": "„Nikt nigdy nie zmierzył – nawet poeci – ile miłości może pomieścić ludzkie serce”. - Zelda Fitzgerald",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Nana-Front1B.jpg?v=1625094590",
            "/images/products/Nana-Front3B.jpg?v=1641312847",
            "/images/products/Nana-Front2B.jpg?v=1625094590"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "16 tulipanów"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*36cm"
    },
    {
        "id": 58,
        "name": "Uroda",
        "handle": "beauty",
        "description": "Obejmij piękno naszego wykwintnego pastelowego bukietu, zawierającego kojące niebieskie hortensje, delikatne różowe róże i czarujący oddech dziecka. Akcentowana fantazyjnym pomponem i owinięta eleganckim różowym papierem, ta kompozycja jest idealna na każdą okazję, oferując zachwycające połączenie miękkości i wdzięku.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Beauty-2a.jpg?v=1721188709",
            "/images/products/Beauty-1a.jpg?v=1721188708"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Róża.  Mamo.  Bawełna  .  Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 59,
        "name": "Różany",
        "handle": "rosy",
        "description": "Ten bukiet to zachwycająca kompozycja składająca się z różnorodnych różowych i białych kwiatów, w tym róż i innych delikatnych kwiatów, akcentowanych srebrzystymi liśćmi. Bukiet jest elegancko owinięty w jasnofioletowy papier i przewiązany delikatną wstążką, tworząc uroczą i romantyczną prezentację.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Rosy-2a.jpg?v=1721102133",
            "/images/products/Rosy-1a-104147.jpg?v=1721136466"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustomy . Mamo. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 60,
        "name": "Miłość III",
        "handle": "fondness-iii",
        "description": "Zanurz się w urzekającym uroku naszego bukietu składającego się z sześciu królewskich fioletowych róż, z wdziękiem otoczonych delikatną elegancją oddechu dziecka. Ta urzekająca aranżacja emanuje wyrafinowaniem i urokiem, dzięki czemu jest idealnym prezentem, aby wyrazić podziw i uczucia na każdą wyjątkową okazję. Rozkoszuj się pięknem tych kwiatów, które wnoszą odrobinę luksusu do Twoich cennych chwil.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-III-1aa.jpg?v=1704983900",
            "/images/products/Fondness-III-2aa.jpg?v=1704983900"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 61,
        "name": "Miłość IV",
        "handle": "fondness-iv",
        "description": "Rozkoszuj się blaskiem radości i przyjaźni dzięki naszemu zachwycającemu bukietowi składającemu się z sześciu słonecznych, żółtych róż, delikatnie otoczonych miękkim, zwiewnym pięknem oddechu dziecka. Ten czarujący zestaw emanuje ciepłem i pozytywnym nastawieniem, dzięki czemu jest idealnym wyrazem uznania i uczucia w związku z wyjątkowymi chwilami. Poczuj energię tych kwiatów i podziel się szczęściem dzięki tej uroczej kompozycji kwiatowej.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-IV-1aa.jpg?v=1704984774",
            "/images/products/Fondness-IV-2aa.jpg?v=1704984773"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 62,
        "name": "Miłość II",
        "handle": "fondness-ii",
        "description": "Rozpal płomień namiętności naszym oszałamiającym bukietem, prezentującym sześć promiennych czerwonych róż otoczonych miękkim, eterycznym uściskiem oddechu dziecka. Ta klasyczna i romantyczna aranżacja jest ponadczasowym wyrazem miłości i pożądania, idealnym do przekazania serdecznych uczuć przy każdej wyjątkowej okazji. Rozkoszuj się pięknem i elegancją tego wykwintnego kwiatowego zestawu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-II-1aa-379104.jpg?v=1705069242",
            "/images/products/Fondness-II-2aa.jpg?v=1704982846"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 63,
        "name": "Iglet FBS",
        "handle": "iglet",
        "description": "Prezentujący wykwintną kompozycję kwiatową, ten egzemplarz przedstawia delikatne różowe róże i bujny oddech dziecka, umieszczone w eleganckim pudełku z papieru kraftowego z elegancką satynową wstążką. Dwie urocze, puszyste figurki króliczków dodają fantazyjnego akcentu i idealnie nadają się na luksusowy prezent.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-01-18at11.55.36AM_2.jpg?v=1642478314",
            "/images/products/Iglet---Delivery_1.jpg?v=1715783953",
            "/images/products/Iglet-M-Size---Delivery_1.jpg?v=1716520102",
            "/images/products/WhatsAppImage2022-01-18at11.55.36AM_1_1.jpg?v=1716520102"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Róża. Spryskaj róże. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 64,
        "name": "Wibrujący FBS",
        "handle": "vibrant",
        "description": "To elegancka kompozycja kwiatowa zawierająca harmonijne połączenie brzoskwini i pomarańczowo-różowych róż, zaakcentowana delikatną białą kaspią i bujną zielenią. Zapakowana w elegancką, minimalistyczną torebkę z papieru kraft z wdzięcznym podwójnym uchwytem w kształcie obręczy, stanowi nowoczesną wariację na temat klasycznego piękna.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Vibrant-FBS-1a.jpg?v=1696949004",
            "/images/products/Vibrant-FBS-2a.jpg?v=1696949004"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Spryskaj goździki.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 65,
        "name": "Zamek",
        "handle": "castle",
        "description": "Uświetnij każdą okazję naszym wykwintnym bukietem kwiatów z delikatnymi różowymi różami w sprayu i elegancką szampańską eustomą. Idealnie dobrana, aby przekazać ciepło i wyrafinowanie, ta aranżacja emanuje ponadczasowym pięknem i urokiem. Niezależnie od tego, czy jest to serdeczny prezent, czy oszałamiająca ozdoba na środek stołu, dodaje odrobiny luksusu każdej chwili.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Castle-2a.jpg?v=1720966320",
            "/images/products/Castle-1a.jpg?v=1720966320"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Spryskaj róże. Eustoma.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 66,
        "name": "Uśmiechnięty",
        "handle": "grinny",
        "description": "Ten bukiet emanuje jasną, wesołą atmosferą dzięki dominującemu słonecznikowi, otoczonemu ostrymi białymi goździkami, delikatnymi różowymi różami i żywą zieloną alstroemerią. Oddech dziecka dodaje odrobinę fantazji, a całość jest elegancko opakowana w papier pakowy i ozdobiona powiewającą wstążką.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Grinny-1aa.jpg?v=1704817731",
            "/images/products/Grinny-2aa.jpg?v=1704817731"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Spryskaj róże. Eustomy . Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 67,
        "name": "Szczęście",
        "handle": "happiness-1",
        "description": "Ten bukiet to oszałamiająca kompozycja składająca się z białych róż otoczonych wielobarwnym oddechem dziecka w odcieniach błękitu, różu, fioletu i pomarańczy. Kwiaty są elegancko owinięte w naturalny brązowy papier i przewiązane miękką wstążką, tworząc piękną i harmonijną prezentację, emanującą urokiem i wyrafinowaniem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Happiness-2a.jpg?v=1721102429",
            "/images/products/Happiness-1a.jpg?v=1721102429"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Tęczowy oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 68,
        "name": "Śliczny",
        "handle": "lovely",
        "description": "Dodaj odrobinę fantazji do swojego prezentu dzięki naszemu wspaniałemu bukietowi różowej hortensji. Ta czarująca kompozycja zawiera bujne różowe hortensje, żywe róże i zabawne akcenty oddechu dziecka, a wszystko to owinięte w delikatny różowy papier. Zwieńczony uroczym kwiatowym króliczkiem, idealnie nadaje się do wniesienia radości i uroku na każdą uroczystość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Lovely-2a.jpg?v=1721188453",
            "/images/products/Lovely-1a.jpg?v=1721188453"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Róża. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 69,
        "name": "Wspomnienia FBS",
        "handle": "memories",
        "description": "Ta piękna kompozycja składa się z miękkiej kremowej eustomy i delikatnej różowej gerbery, akcentowanej bujną zielenią. Zapakowany w elegancką brązową torebkę z satynową wstążką, jest idealnym prezentem, który wniesie odrobinę wdzięku i radości na każdą okazję.",
        "type": "Seria Torebek",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Memories-FBS-1a-954185.jpg?v=1720117360",
            "/images/products/Memories-FBS-2a-904830.jpg?v=1720117360"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 70,
        "name": "szwajcarski",
        "handle": "swiss",
        "description": "Ten elegancki bukiet zawiera oszałamiające połączenie różowych i fioletowych kwiatów, w tym róż i lisianthus, wzmocnione bujną zielenią i delikatnymi białymi akcentami. Kompozycja jest pięknie owinięta w miękki beżowy papier i przewiązana pasującą wstążką, dzięki czemu będzie wyrafinowanym wyborem na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Swiss-2a.jpg?v=1721175302",
            "/images/products/Swiss-1a.jpg?v=1721175302"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Spryskaj róże.  Eustomy .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 71,
        "name": "Caryca",
        "handle": "czarina",
        "description": "Ten elegancki bukiet zawiera harmonijną mieszankę czerwonych róż i róż cuppucino, starannie ułożonych tak, aby stworzyć oszałamiający efekt wizualny. Zapakowany w delikatny papier w kolorze kości słoniowej i ozdobiony ciemnoczerwoną wstążką, jest idealnym prezentem, aby wyrazić miłość i podziw.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Czarina-2d-843817.jpg?v=1721049037",
            "/images/products/Czarina-1d.jpg?v=1720966559"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "6 róż cappuccino. 6 czerwonych róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 72,
        "name": "Florero",
        "handle": "florero",
        "description": "Ten elegancki bukiet zawiera harmonijne połączenie delikatnych różowych róż, delikatnego lisiantusa i bujnej kapusty ozdobnej, tworząc niepowtarzalną i wyrafinowaną kompozycję. Akcentowany świeżą zielenią i gałązkami lawendy, ten bukiet będzie oszałamiającym centralnym elementem każdego otoczenia. Umieszczony w wazonie z przezroczystego szkła i przewiązany klasyczną wstążką, idealnie nadaje się na dodanie odrobiny luksusu i wdzięku na każdą okazję.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Florero-2a.jpg?v=1724125221",
            "/images/products/Florero-1a-253820.jpg?v=1724158461"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Eustoma. Spryskaj róże. Mattiola. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 73,
        "name": "Floro II",
        "handle": "florero-ii",
        "description": "Ta aranżacja wazonów emanuje ciepłem i radością dzięki połączeniu miękkich białych lilii, pastelowych róż i delikatnego oddechu dziecka. Aranżacja, wzbogacona bujną zielenią, emanuje świeżą i zachęcającą atmosferą. Bukiet ten, umieszczony w uroczym szklanym wazonie ozdobionym satynową wstążką, idealnie nadaje się do rozjaśnienia każdego pomieszczenia lub jako przemyślany prezent na specjalną okazję.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Florero-II-2a-239661.jpg?v=1724158463",
            "/images/products/Florero-II-1a-975220.jpg?v=1724158463"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie . Eustoma. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 74,
        "name": "Szczery",
        "handle": "sincere",
        "description": "Ten elegancki bukiet składa się z nieskazitelnych białych lilii uzupełnionych bujnymi zielonymi liśćmi i delikatnymi białymi kwiatami. Profesjonalnie zapakowany w miękki, miętowo-zielony papier, jest idealny na każdą okazję, dodając Twojemu wnętrzu odrobinę wyrafinowania i naturalnego piękna.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Sincere-2a.jpg?v=1721178422",
            "/images/products/Sincere-1a.jpg?v=1721178422"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 75,
        "name": "Kocham",
        "handle": "luvu",
        "description": "Rozpal namiętność naszym romantycznym bukietem – 9 czerwonych róż elegancko przedstawionych w urzekającej kompozycji w kształcie wierzby w kształcie serca. Ten czarujący zestaw, owinięty w miękkie różowe opakowanie, jest symbolem miłości i uczucia, co czyni go idealnym prezentem na rocznice, Walentynki lub inną serdeczną okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Luvu-2a.jpg?v=1720886407",
            "/images/products/Luvu-1a.jpg?v=1720886407"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 76,
        "name": "Bzdurny",
        "handle": "mushy",
        "description": "Świętuj elegancję dzięki naszemu czarującemu bukietowi tulipanów i dziecięcego oddechu. Ta kompozycja, ozdobiona delikatnymi różowymi tulipanami osadzonymi wśród przewiewnego oddechu dziecka, jest owinięta delikatnym różowym papierem i przewiązana satynową wstążką. Idealny na każdą okazję, wnosi odrobinę wdzięku i piękna w dzień ukochanej osoby.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Mushy-2a.jpg?v=1721188102",
            "/images/products/Mushy-1a.jpg?v=1721188102"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 tulipanów. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 77,
        "name": "Moda",
        "handle": "vogue",
        "description": "Wyrafinowany bukiet składający się z delikatnych pastelowych róż Menta, białych kalii i bujnej zieleni, elegancko opakowany w rustykalny brązowy papier. Ta wykwintna aranżacja idealnie nadaje się do wyrażania serdecznych uczuć i dodawania elegancji każdej wyjątkowej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z lilii calla",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Vogue-2a.jpg?v=1721011849",
            "/images/products/Vogue-1a.jpg?v=1721011850"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie kalii. Róże. Mamo.  Alstremeria.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 78,
        "name": "Wskazówka",
        "handle": "clue",
        "description": "Uchwyć spokój i ciszę czystego, błękitnego nieba dzięki naszemu bukietowi oddechu Blue Baby. Ten czarujący bukiet, wykonany ręcznie z precyzją i starannością, składa się z delikatnych, niebieskich kwiatów w kształcie oddechu dziecka, ułożonych perfekcyjnie. Każda łodyga jest starannie wybierana, aby stworzyć oszałamiającą kompozycję, która promieniuje pięknem i elegancją.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Clue-1a.jpg?v=1720527841",
            "/images/products/Clue-2a.jpg?v=1720527841"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 79,
        "name": "Puścić oczko",
        "handle": "wink",
        "description": "Obejmij delikatne piękno naszego różowego dziecięcego bukietu oddechowego, zachwycającego zestawu, który ucieleśnia słodycz i elegancję. Każda łodyga oddechu dziecka rozkwita delikatnymi odcieniami różu, tworząc wizję delikatnego uroku i wdzięku.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Wink-1a.jpg?v=1720527613",
            "/images/products/Wink-2a.jpg?v=1720662935"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 80,
        "name": "Beknięcie",
        "handle": "burp",
        "description": "Świętuj chwile życia dzięki naszemu fioletowemu bukietowi oddechu dziecka, symbolowi piękna, wdzięku i wyrafinowania. Pozwól, aby jego wyjątkowy urok zniewalał Twoje zmysły i urzekał otoczenie swoją czarującą obecnością.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Burp-1a.jpg?v=1720527408",
            "/images/products/Burp-2a.jpg?v=1720527409"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 81,
        "name": "Wydra",
        "handle": "otter",
        "description": "Ten uroczy bukiet składa się z pięknej mieszanki różowych goździków i tulipanów, akcentowanej pojedynczym białym lisianthusem i bujną zielenią. Zapakowany w elegancki papier pakowy i przewiązany delikatną różową wstążką, będzie wspaniałym prezentem na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Otter-2a.jpg?v=1720966865",
            "/images/products/Otter-1a.jpg?v=1720966865"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Tulipan.  Mamo. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 82,
        "name": "Migać",
        "handle": "blink",
        "description": "Czarujący bukiet pełen żywych róż, delikatnych goździków i bujnej zieleni, elegancko opakowany w różowy papier. Ta urocza aranżacja idealnie nadaje się do celebrowania miłości, radości i wyjątkowych chwil, wnosząc odrobinę romantyzmu i piękna na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Blink-2a.jpg?v=1721012042",
            "/images/products/Blink-1a.jpg?v=1721012042"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Mamo. Goździki. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 83,
        "name": "Babilon",
        "handle": "babylon",
        "description": "Ten delikatny bukiet składa się z uroczych różowych tulipanów, białych róż i puszystych pomponów, akcentowanych dziecięcym oddechem i bujną zielenią. Ta aranżacja, zawinięta w delikatny różowy papier, emanuje elegancją i wdziękiem, dzięki czemu jest idealnym prezentem, aby wyrazić miłość i uznanie.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Babylon-1a_3.jpg?v=1721012295",
            "/images/products/Babylon-1a_1.jpg?v=1721012295"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "RóżeMamy.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 84,
        "name": "Szepnij FBS",
        "handle": "whisper",
        "description": "„Kochaliśmy miłością, która była czymś więcej niż miłością”. -  Edgara Allena Poe",
        "type": "Seria Torebek",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Whisper-New1a-527537.jpg?v=1686169096",
            "/images/products/Whisper-New2a-733999.jpg?v=1686169096"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Mamo.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 85,
        "name": "Wiedeń",
        "handle": "vienna",
        "description": "Ten elegancki bukiet to harmonijna mieszanka różowych róż, goździków i chryzantem, pięknie ułożona z delikatną statyką i bujną zielenią. Zapakowana w miękki biały papier i przewiązana pasującą wstążką, ta kompozycja jest idealna do wyrażania miłości i uznania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Vienna-2a.jpg?v=1720967054",
            "/images/products/Vienna-1a.jpg?v=1720967054"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Eustomy . Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 86,
        "name": "Mable",
        "handle": "mable",
        "description": "Ten czarujący bukiet składa się z zachwycającej mieszanki miękkich brzoskwiniowych róż i żywych kwiatów pomarańczy, tworząc harmonijną mieszankę kolorów. Zaakcentowana delikatną zielenią aranżacja ta wnosi świeżą i żywą atmosferę do każdego wnętrza. Zapakowany w stylowy szklany wazon przewiązany satynową wstążką, jest idealnym prezentem, aby uczcić wyjątkowe chwile lub po prostu umilić komuś dzień.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Mable-2a-510015.jpg?v=1724158918",
            "/images/products/Mable-1a-773544.jpg?v=1724158918"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj róże. Goździk . Eustoma. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 16cm*28cm"
    },
    {
        "id": 87,
        "name": "Śnieżka",
        "handle": "snowie",
        "description": "Zanurz się w marzycielskim pokazie czystości i elegancji dzięki naszemu bukietowi oddechowemu Jumbo White Baby. Ta wspaniała kompozycja, pełna delikatnych kwiatów, urzeka swoim eterycznym pięknem. Idealny na wesela, wielkie uroczystości lub jako zapierający dech w piersiach element centralny, obiecuje oczarować i zachwycić, tworząc niezapomniane chwile wdzięku i spokoju.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Snowie-frontnew1.jpg?v=1625094604",
            "/images/products/Snowie-sidenew1.jpg?v=1641316681"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 50cm*60cm"
    },
    {
        "id": 88,
        "name": "Wiktoria",
        "handle": "victoria",
        "description": "Ten czarujący bukiet składa się z miękkiej i romantycznej mieszanki różowych tulipanów, delikatnych białych róż i pachnącego bulionu, a wszystko to pięknie opakowane w elegancki papier. Akcentowana gałązkami zieleni i kwiatami wypełniającymi, ta aranżacja emanuje delikatnym i pełnym wdzięku urokiem. Idealny do wyrażania miłości i podziwu, jest idealnym wyborem na specjalne okazje lub po prostu, aby umilić komuś dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Victoria-2b-980272.jpg?v=1724865614",
            "/images/products/Victoria-1a.jpg?v=1724808524"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Tulipany. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 89,
        "name": "Riwiera",
        "handle": "riviere",
        "description": "Ten wykwintny bukiet składa się z bujnej szampańskiej eustomy i żywych żółtych tulipanów, pomysłowo ułożonych wśród delikatnych gałązek stokrotek. Przewiązany delikatną żółtą wstążką, zapakowany w stylowe, różowe opakowanie z logo Flowers Poland, idealne na elegancki prezent i na specjalne okazje.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Riverie-2h-761732.jpg?v=1724775989",
            "/images/products/Riverie-1g.jpg?v=1724723092"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany.  Eustoma.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*28cm"
    },
    {
        "id": 90,
        "name": "Jovell",
        "handle": "jovell",
        "description": "Ten elegancki bukiet to harmonijne połączenie ciemnofioletowych tulipanów, delikatnych białych róż i gałązek bujnej zieleni, a wszystko to owinięte w wyrafinowany beżowy papier z charakterystycznym brandingiem Flowers Poland. Idealna na każdą okazję, ta aranżacja emanuje wyrafinowaniem i wdziękiem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Jovell-2b.jpg?v=1724891419",
            "/images/products/Jovell-1b.jpg?v=1724891419"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*28cm"
    },
    {
        "id": 91,
        "name": "Cuscaden FBS",
        "handle": "cuscaden",
        "description": "Promienny kwiatowy zestaw z odważnym słonecznikiem na środku pośród rumieniących się różowych róż i alabastrowych białych kwiatów, zapakowany w eleganckie pudełko z papieru kraft z luksusową satynową kokardką, tworząc ponadczasowy prezent naturalnego piękna i wyrafinowania.",
        "type": "Seria Torebek",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-01-18at11.55.45AM_2.jpg?v=1642478334",
            "/images/products/Cuscaden---Delivery_1-956595.jpg?v=1716500892",
            "/images/products/Cuscaden-M-Size---Delivery_1.jpg?v=1716520034",
            "/images/products/WhatsAppImage2022-01-18at11.55.45AM_1_1.jpg?v=1716520034"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Eustomy . Mattiola. Orchidea. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*35cm"
    },
    {
        "id": 92,
        "name": "Jagoda",
        "handle": "berry",
        "description": "Ten kapryśny bukiet składa się z zachwycającej mieszanki kolorowego oddechu dziecka i puszystych kwiatów bawełny, tworząc zabawną i uroczą kompozycję. Zapakowany w elegancki biały papier i zabezpieczony pasującą wstążką, jest idealnym prezentem na każdą okazję, aby umilić komuś dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Berry-2a.jpg?v=1721005579",
            "/images/products/Berry-1a.jpg?v=1721005579"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Bawełniane Kwiaty. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 93,
        "name": "Hiltona",
        "handle": "hilton",
        "description": "Ten oszałamiający bukiet łączy nieskazitelne białe tulipany z delikatnymi niebieskimi osetami, otoczony bujną zielenią. Opakowana w wyrafinowany beżowy papier i ozdobiona charakterystycznym dla Flowers Poland bukietem, ta kompozycja emanuje elegancją i urokiem, idealna na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Hilton-2c1.jpg?v=1724891883",
            "/images/products/Hilton-1c.jpg?v=1724891774"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Eustoma. Mattiola. Srebrna Brunia. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 94,
        "name": "Wiara",
        "handle": "faith",
        "description": "Wyrafinowany bukiet umieszczony w kremowym pudełku, pełen żywych czerwonych róż, różowych tulipanów i gaju białych stokrotek, uzupełniony czerwonymi goździkami i różnorodną zielenią, oferuje klasyczny i romantyczny prezent kwiatowy.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Faith-2a.jpg?v=1721527918",
            "/images/products/Faith-1a.jpg?v=1721527918"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Tulipany. Goździk . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 95,
        "name": "Radosny",
        "handle": "merry",
        "description": "Poczuj żywy urok dzięki naszemu bukietowi składającemu się z 6 oszałamiających, gorących róż, ozdobionych delikatnym pięknem kaspii i teksturowaną elegancją parvifolii. Odważne i żywe odcienie róż tworzą uderzający efekt wizualny, a uzupełniające się kaspia i parvifolia dodają dodatkowej warstwy uroku. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Merry-1a.jpg?v=1720777622",
            "/images/products/Merry-2a.jpg?v=1720777622"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gorące różowe róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 96,
        "name": "Olafa FBS",
        "handle": "olaf",
        "description": "„Może nie potrzebujesz, żeby kochał cię cały świat. Może potrzebujesz tylko jednej osoby.” - Kermit Żaba",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Olaf-Front-min.jpg?v=1641309468",
            "/images/products/Olaf-Side2-min.jpg?v=1641309379"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Gossypium. Goździk . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 97,
        "name": "Jupiter",
        "handle": "jupiter",
        "description": "Ten żywy bukiet składa się z promiennych słoneczników w połączeniu z kremowymi białymi różami, wzmocnionymi bujnymi zielonymi liśćmi i delikatnym oddechem dziecka. Opakowana w naturalny brązowy papier aranżacja emanuje rustykalnym urokiem i ciepłem. Idealny, aby dodać odrobinę słońca i elegancji na każdą okazję, co czyni go idealnym prezentem, aby rozjaśnić czyjś dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Jupiter-2a.jpg?v=1722296967",
            "/images/products/Jupiter-1a.jpg?v=1722296968"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 45cm*55cm"
    },
    {
        "id": 98,
        "name": "Ujście",
        "handle": "estuary",
        "description": "Ta elegancka kompozycja składa się z dziewiczych białych lilii w połączeniu z delikatnymi stokrotkami i gałązkami eukaliptusa, tworząc świeży i spokojny bukiet. Akcentowany akcentami niebieskiego ostu i owinięty stylową wstążką, ten bukiet jest prezentowany w wazonie z przezroczystego szkła. Idealny do wprowadzenia odrobiny natury do wnętrz, jest idealnym wyborem dla zapewnienia spokojnej i wyciszającej atmosfery.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Estuary-2a-511713.jpg?v=1724337584",
            "/images/products/Estuary-1a-964814.jpg?v=1724337584"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 99,
        "name": "Mroźny",
        "handle": "frosty",
        "description": "„Lepiej stracić i kochać, niż nigdy nie kochać”. – Ernesta Hemingwaya",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Frosty1A_c673726f-5a50-4f8c-9b69-2bee637c4647-374894.jpg?v=1674238788",
            "/images/products/Frosty2.jpg?v=1674181862"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 100,
        "name": "Drobna",
        "handle": "petite",
        "description": "Ten bukiet to kapryśna mieszanka radości natury, składająca się z wyjątkowego słonecznika otoczonego bladoróżowymi różami i kremowymi kwiatami. Pierzaste różowe kolce dodają zabawnego kontrastu, a tło bujnej zieleni i łyszczec zapewnia pełny wygląd.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Petite-1a.jpg?v=1720749189",
            "/images/products/Petite-2a.jpg?v=1720749189"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*32cm"
    },
    {
        "id": 101,
        "name": "Agnieszka",
        "handle": "valentine",
        "description": "Świętuj miłość naszym czarującym bukietem czerwonych róż Ten bukiet, składający się z 3 promiennych czerwonych róż, pomysłowo ułożonych z akcentami wierzby w kształcie serca, ozdobiony jest kapryśnym akcentem tęczowego oddechu dziecka. Symbol radości i uczucia, zamknięty w stylowym opakowaniu, dzięki czemu jest idealnym prezentem na rocznice, romantyczne okazje lub po prostu, aby umilić komuś dzień. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Agnes-2a.jpg?v=1720778303",
            "/images/products/Agnes-1a.jpg?v=1720778303"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Tęczowy oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 102,
        "name": "Regisa",
        "handle": "regis",
        "description": "Podnieś romantyzm dzięki naszemu bukietowi 12 oszałamiających czerwonych i różowych róż, pięknie zapakowanych w miękkie różowe opakowanie. Ta urzekająca aranżacja to symfonia namiętnych czerwieni i delikatnych różów, tworząca wizualne arcydzieło, idealne do wyrażania miłości z okazji rocznic lub innych serdecznych okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Regis-1a.jpg?v=1720658543",
            "/images/products/Regis-2a.jpg?v=1720658543"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 103,
        "name": "Miód",
        "handle": "honey",
        "description": "Ten uroczy bukiet składa się z delikatnych różowych i białych kwiatów osadzonych w bujnej zieleni, a wszystko pięknie ułożone w rustykalnej ramce z podwójnym sercem. Zapakowana w delikatny różowy papier i ozdobiona żywą wstążką, ta kompozycja będzie idealnym romantycznym gestem lub serdecznym prezentem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Honey-1b.jpg?v=1721012587",
            "/images/products/Honey-1a-635826.jpg?v=1721049343"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Eustoma.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 104,
        "name": "Płomień",
        "handle": "flame",
        "description": "Odkryj elegancję naszego charakterystycznego bukietu, składającego się z delikatnej mieszanki kremowych białych, delikatnego różu i pastelowych żółtych róż, akcentowanych bujną zielenią i oddechem dziecka. Idealna na każdą okazję, ta aranżacja wnosi odrobinę wyrafinowania i uroku do Twoich wyjątkowych chwil.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Flame-2a.jpg?v=1721184971",
            "/images/products/Flame-1a.jpg?v=1721184971"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 105,
        "name": "Kochanie",
        "handle": "darling",
        "description": "Zanurz się w języku miłości dzięki naszemu wykwintnemu bukietowi składającemu się z 12 luksusowych czerwonych róż, pomysłowo ułożonych i podkreślonych wierzbową ramką w kształcie serca. Ten czarujący zestaw oddaje istotę romantyzmu, dzięki czemu jest idealnym gestem na rocznice, zaręczyny lub wyraz głębokiego uczucia. Podnieś tę chwilę za pomocą tego symbolu trwałej miłości i pasji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Darling-2a-265479.jpg?v=1721396491",
            "/images/products/Darling-1a-256951.jpg?v=1721396491"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 106,
        "name": "Urażony",
        "handle": "smitten",
        "description": "Wyrusz w podróż pełną wibrującego piękna z naszym tęczowym bukietem oddechu dla dziecka w kształcie serca, oszałamiającym świadectwem miłości, radości i różnorodności. Bukiet ten, wykonany z niezwykłą starannością i artystycznym zacięciem, składa się z szeregu kwiatów z oddechem dziecka w spektrum promiennych kolorów, misternie ułożonych w kształt serca.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Smitten-2a_ee7ae15b-51a4-45ec-94dc-6ab163ca24a1.jpg?v=1720797264",
            "/images/products/Smitten-1a_9e39444f-b53d-4647-b010-f56e54c67d66-615399.jpg?v=1720877664"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 107,
        "name": "Miłość",
        "handle": "lovie",
        "description": "Zanurz się w ponadczasowej elegancji miłości dzięki naszemu czerwonemu bukietowi oddechu dziecka w kształcie serca, uderzającemu wyrazowi pasji, oddania i romantyzmu. Ten starannie dobrany i fachowo ułożony bukiet składa się z delikatnych, dziecięcych kwiatów w bogatych odcieniach czerwieni, misternie uformowanych w urzekający kształt serca.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Lovie-2a-393633.jpg?v=1720876225",
            "/images/products/Lovie-1a-240787.jpg?v=1720876225"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 108,
        "name": "Inspirować",
        "handle": "inspire",
        "description": "Rozjaśnij komuś dzień naszym żywym bukietem słoneczników i róż. Ta kompozycja, zawierająca wesołe słoneczniki w połączeniu z efektownymi czerwonymi różami i bujnym eukaliptusem, jest owinięta ciepłym pomarańczowym papierem. Idealny do wyrażania radości, uznania lub miłości, przynosi przypływ słońca i szczęścia na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Inspire-2a.jpg?v=1721189238",
            "/images/products/Inspire-1a.jpg?v=1721189238"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 109,
        "name": "DarDar",
        "handle": "dardar",
        "description": "Zanurz się w morzu namiętności dzięki naszemu wspaniałemu bukietowi składającemu się z 36 promiennych czerwonych róż misternie ułożonych wokół wierzbowej ramki w kształcie serca. Ten bogaty zestaw jest świadectwem wielkości miłości, co czyni go niezwykłym prezentem na rocznice, wielkie uroczystości lub jako wyraz głębokiego uczucia. Uchwyć esencję romantyzmu dzięki temu oszałamiającemu kwiatowemu arcydziełu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/DarDar--2a-252742.jpg?v=1727261656",
            "/images/products/DarDar-1-985348.jpg?v=1727261656"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "36 róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*40cm"
    },
    {
        "id": 110,
        "name": "Collyera",
        "handle": "collyer",
        "description": "Delikatny bukiet pastelowych róż w odcieniach kremu, delikatnego różu i bieli, przeplatany uroczymi gałązkami dziecięcego oddechu. Ta eteryczna kompozycja ozdobiona jest zabawnymi różowymi wstążkami i owinięta marzycielskim białym papierem, emanującym delikatnym wdziękiem i wyrafinowaniem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/WhatsAppImage2022-04-13at12.25.40PM_2_1.jpg?v=1650419590",
            "/images/products/WhatsAppImage2022-04-13at12.25.40PM_1.jpg?v=1650419590"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*40cm"
    },
    {
        "id": 111,
        "name": "Wielkość",
        "handle": "granduer",
        "description": "„Czasami ktoś pojawia się w Twoim życiu tak niespodziewanie, że zaskakuje Twoje serce i na zawsze zmienia Twoje życie.” - Nieznany",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Granduer-Insta2.jpg?v=1625094617"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 112,
        "name": "Dolina",
        "handle": "valle",
        "description": "Promieniuj radością dzięki naszej kompozycji słoneczników i gorących różowych róż. Trzy żywe słoneczniki i gorące różowe róże otoczone delikatną kaspią tworzą wesoły i pełen miłości obraz. Pomysłowo zaaranżowana w wierzbową ramkę w kształcie serca, ta aranżacja jest zachwycającym wyrazem uczuć, idealnym do celebrowania chwil szczęścia i miłości.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Valle-1aa.jpg?v=1705981530",
            "/images/products/Valle-2aa.jpg?v=1705981530"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki.  Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*36cm"
    },
    {
        "id": 113,
        "name": "Leonie",
        "handle": "leonie",
        "description": "Ten żywy bukiet składa się z jasnego słonecznika, różowych róż i czerwonych róż w sprayu, akcentowanych delikatnym oddechem dziecka. Zapakowany w miękki, niebieski papier i przewiązany białą wstążką, będzie wesołym i uroczym prezentem na każdą okazję",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Leonie-2a-857195.jpg?v=1720789290",
            "/images/products/Leonie-1a-121422.jpg?v=1720789290"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Spryskaj róże. Eustoma. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 114,
        "name": "Gaj",
        "handle": "grove",
        "description": "Urzekaj urokiem tego bukietu z urzekającym skupiskiem żywych lawendowych róż. Otulona w zwiewny biało-liliowy tiul z pełną wdzięku satynową kokardką, kompozycja od Flowers Poland emanuje elegancją, dzięki czemu jest idealnym prezentem na cenne chwile.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Grove-2f.jpg?v=1721006259",
            "/images/products/Grove-1f.jpg?v=1721006259"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 łodyg fioletowych róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 115,
        "name": "Dalvey'a",
        "handle": "dalvey",
        "description": "Odkryj uderzający urok naszego niebieskiego bukietu kwiatowego. Ta aranżacja, ozdobiona żywymi niebieskimi różami i delikatnym dziecięcym oddechem, akcentowana miękkimi bawełnianymi kwiatami, stanowi idealne połączenie elegancji i wyjątkowości. Zapakowany w odważny niebieski papier, tworzy urzekający efekt wizualny, dzięki czemu jest idealnym wyborem na specjalne uroczystości lub aby rozjaśnić czyjś dzień odrobiną wyrafinowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Dalvey-2a-404649.jpg?v=1721396487",
            "/images/products/Dalvey-1a.jpg?v=1721354048"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gossypium. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 116,
        "name": "Delikatny",
        "handle": "gentle",
        "description": "Ten elegancki bukiet składa się z delikatnych różowych róż, delikatnych kwiatów i bujnej zieleni, pięknie opakowanych w pastelowy różowy papier. Idealny na każdą okazję, emanuje wdziękiem i urokiem, dzięki czemu jest idealnym prezentem, aby wyrazić swoje najgłębsze uczucia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Gentle-2a.jpg?v=1721012778",
            "/images/products/Gentle-1a.jpg?v=1721012778"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Calla Lily. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 117,
        "name": "Ładny",
        "handle": "pretty",
        "description": "„Miłość nie polega na tym, aby na siebie patrzeć, ale na wspólnym patrzeniu na zewnątrz, w tym samym kierunku”. - Antoine de Saint-Exupéry",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Pretty-Insta.jpg?v=1641312354",
            "/images/products/Pretty-Front-815875.jpg?v=1671685636",
            "/images/products/Pretty-side.jpg?v=1641312259"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 118,
        "name": "Wattena",
        "handle": "watten",
        "description": "Ten czarujący bukiet składa się z delikatnych fioletowych róż, puszystych białych chryzantem i delikatnych niebieskich akcentów, pięknie skomponowanych z bujną zielenią. Zapakowany w elegancki niebieski papier i przewiązany pasującą wstążką, będzie wspaniałym prezentem na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Watten-2b.jpg?v=1720882480",
            "/images/products/Watten-1b.jpg?v=1720882480"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Mamo.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 119,
        "name": "Wymyślny",
        "handle": "fancy",
        "description": "Ten piękny bukiet składa się z zachwycającej mieszanki delikatnych brzoskwiniowych i kremowych róż w sprayu, akcentowanych delikatnymi białymi wypełniaczami i bujną zielenią. Opakowany w elegancki różowo-szary papier emanuje urokiem i wyrafinowaniem. Idealny na wesela, rocznice lub inne specjalne okazje, aby przekazać szczere uczucia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Fancy-2a.jpg?v=1721190563",
            "/images/products/Fancy-1a.jpg?v=1721190563"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Spryskaj róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 120,
        "name": "Oltona",
        "handle": "olton",
        "description": "Zachwyć się subtelnym pięknem naszego bukietu składającego się z 6 uroczych róż z różowymi końcówkami, ozdobionych delikatnymi kwiatami kaspii i misterną fakturą parvifolii. Delikatne różowe odcienie na końcach róż dodają elegancji, a uzupełniające się kaspia i parvifolia tworzą harmonijną i atrakcyjną wizualnie kompozycję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Olton-1a.jpg?v=1720777520",
            "/images/products/Olton-2a.jpg?v=1720777521"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Różowe róże z końcówkami. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*36cm"
    },
    {
        "id": 121,
        "name": "Rennella",
        "handle": "rennell",
        "description": "Ten czarujący bukiet składa się z bujnej gromady białego oddechu dziecka, elegancko ułożonej w rustykalnej wierzbie w kształcie serca. Zapakowany w ziemisty brązowy papier i przewiązany wstążką, jest idealnym prezentem na każdą okazję, emanującym prostotą i wdziękiem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Rennell-1c.jpg?v=1720744948",
            "/images/products/Rennell-1b.jpg?v=1720663420"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 122,
        "name": "Morrisa",
        "handle": "morris",
        "description": "„Długie i szczęśliwe życie to nie bajka – to wybór”. - Płowy Tkacz",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/56588767-bbed-44e8-af70-74ffadbeb9c3.jpg?v=1641313093",
            "/images/products/02f93627-1a8c-4052-adfe-56e2a410f37d.jpg?v=1641313087"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Tulipany.  Eustomy . Goździk . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 123,
        "name": "Sukces (II)",
        "handle": "success-ii",
        "description": "Podnieś poziom wszystkich startów biznesowych dzięki naszemu stojakowi kwiatowemu Majesty z czerwoną różą i gerberą. Wysokie czerwone róże i żywe gerbery tworzą królewski pokaz, symbolizując miłość, energię i dobrobyt dla Twojego kwitnącego przedsięwzięcia biznesowego.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Success-_II_-1d.jpg?v=1720407410",
            "/images/products/Grand-Opening-Card-2024_f19d390f-c35d-4483-9fdc-6e91f75608ab.jpg?v=1720407415",
            "/images/products/Success-_II_-2d.jpg?v=1720407410"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Mamo.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 124,
        "name": "Sukces (III)",
        "handle": "success-iii",
        "description": "Wejdź do królestwa królewskiej elegancji dzięki naszemu stojakowi na wielkie otwarcie Royal Crimson Blossom, oszałamiającemu pokazowi czerwonej gerbery, różowych lilii i zielonej mamy. Ta majestatyczna kompozycja kwiatowa bez trudu łączy ognistą pasję czerwonych gerber z królewskim wyrafinowaniem koloru, tworząc wizualne arcydzieło, które symbolizuje prestiż i sukces.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Success-_III_-1d.jpg?v=1720492623",
            "/images/products/Grand-Opening-Card-2024_d6b860f5-60b8-4251-ac1e-07a581448957.jpg?v=1720492630",
            "/images/products/Success-_III_-2d.jpg?v=1720492624"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Lilia. Mamo. Goździki. Czerwony imbir. Fioletowa statyka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 100cm*185cm"
    },
    {
        "id": 125,
        "name": "Sukces (IV)",
        "handle": "success-iv",
        "description": "Uświetnij otwarcie swojego wielkiego biznesu dzięki naszym stojakom na fioletowe gerbery z motywem różowej czerwieni. Symfonia żywych barw i bujnej zieleni tworzy oszałamiający wizualnie pokaz, symbolizujący pasję, energię i kwitnący początek Twojego przedsięwzięcia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Success-_IV_-1e.jpg?v=1720492969",
            "/images/products/Grand-Opening-Card-2024_db8e3928-2067-41d8-b95a-6032646214dc.jpg?v=1720492975",
            "/images/products/Success-_IV_-2e.jpg?v=1720492969"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Różowe Lilie. Orchidee. Helikonia. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 126,
        "name": "Vue",
        "handle": "vue",
        "description": "„W miarę upływu lat miłość staje się coraz bardziej pełna, szybka i przejmująca”. - Zane'a Graya",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Vue1_3cc9d33c-cfaf-46e1-84e4-34870c492226-890295.jpg?v=1671775974",
            "/images/products/Vue2.jpg?v=1667270849"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 40cm*50cm"
    },
    {
        "id": 127,
        "name": "Wieczność",
        "handle": "eternity",
        "description": "Odkryj urok romantyzmu dzięki naszemu bukietowi czerwonych róż Odkryj urok romansu dzięki temu bukietowi składającemu się z dziewięciu soczystych czerwonych róż, którym towarzyszy delikatny urok różowej kaspii. Ta aranżacja, gustownie opakowana w opakowanie w kolorze nude, równoważy pasję i subtelność, dzięki czemu jest idealnym prezentem do wyrażania miłości i uznania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Eternity-2b-446438.jpg?v=1720875853",
            "/images/products/Eternity-1b.jpg?v=1720839905"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 róż. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 128,
        "name": "Wieczność II",
        "handle": "eternity-ii",
        "description": "Zanurz się w delikatnym uroku naszego bukietu różowych róż. Zanurz się w delikatnym uroku naszego bukietu, składającego się z 9 uroczych różowych róż ozdobionych delikatną kaspią. Delikatne odcienie i misterna aranżacja tworzą elegancki zestaw, idealny do wyrażania uczuć z okazji urodzin, uroczystości lub rozjaśnienia czyjegoś dnia. Podnieś tę chwilę dzięki temu pięknemu, kwiatowemu wyrazowi wdzięku i podziwu. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Eternity-II-2a.jpg?v=1720839805",
            "/images/products/Eternity-II-1a.jpg?v=1720839805"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Różowe Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 129,
        "name": "Genewa",
        "handle": "geneve",
        "description": "Rozkoszuj się ponadczasowym urokiem romantyzmu dzięki naszemu wykwintnemu bukietowi składającemu się z 12 promiennych czerwonych róż, każda otoczona delikatną elegancją oddechu dziecka. Ta klasyczna aranżacja oddaje istotę miłości, dzięki czemu jest idealnym prezentem, który wyraża pasję i uczucie do cennych chwil.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Geneve-2c.jpg?v=1720880292",
            "/images/products/Geneve-1c.jpg?v=1720880292"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 róż.  Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 130,
        "name": "Tulipanowiec",
        "handle": "tulipwood",
        "description": "Ten elegancki bukiet składa się z delikatnych 9 różowych tulipanów pięknie ułożonych z bujnymi zielonymi łodygami i owiniętych w brązowo-biały papier. Przewiązany delikatną różową wstążką, jest idealnym prezentem, aby wyrazić miłość i podziw na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Tulipwood-2c.jpg?v=1720692824",
            "/images/products/Tulipwood-1c.jpg?v=1720692823"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 131,
        "name": "Księżniczka II",
        "handle": "princess-ii",
        "description": "Ten bukiet urzeka spokojną przestrzenią błękitnych hortensji, symbolem szczerej szczerości i obfitości. Bogate, obszerne kwiaty uzupełniają spienione morze oddechu dziecka, dodając odrobinę niewinności.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Princess-II-1a.jpg?v=1720745964",
            "/images/products/Princess-II-2a.jpg?v=1720745964"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 132,
        "name": "Carey",
        "handle": "carey",
        "description": "Ten romantyczny bukiet składa się z uroczej mieszanki czerwonych i różowych róż, akcentowanej delikatnym oddechem dziecka i zabawnym kwiatem pomponu. Zapakowany w elegancki różowy papier i ozdobiony gałązką w kształcie serca, jest idealnym prezentem, aby wyrazić miłość i uczucie.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Carey-1a.jpg?v=1721007091",
            "/images/products/Carey-2a.jpg?v=1721007091"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "= Róże .  Mamo. Spryskaj róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 133,
        "name": "Lód",
        "handle": "ice",
        "description": "Bukiet ten charakteryzuje się uderzającym połączeniem niebieskich i białych kwiatów. Pojedyncza, żywa, niebieska róża wyróżnia się spośród białych róż, delikatnych niebieskich ostróżek i gałązek dziecięcego oddechu. Bukiet akcentują bujne liście eukaliptusa, które dodają zieleni i tekstury. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Ice-2a.jpg?v=1721102686",
            "/images/products/Ice-1a.jpg?v=1721102686"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Mattiola. Delfin . Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 134,
        "name": "Ślubne II",
        "handle": "bridal-ii",
        "description": "Do tej pięknej aranżacji ślubnej dołączony będzie wazon =)",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na wesele",
            "Kwiaty i bukiety na zaręczyny"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Bridal-II---1.jpg?v=1625094629",
            "/images/products/Bridal-II---3.jpg?v=1641337579",
            "/images/products/Bridal-II---2.jpg?v=1641337573",
            "/images/products/Bridal-II---4.jpg?v=1625094629"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Eustomy . Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 135,
        "name": "Zakochani",
        "handle": "lovers",
        "description": "Świętuj elegancję dzięki naszemu uroczemu bukietowi różowych róż. Ta kompozycja, składająca się z trzech delikatnych różowych róż otoczonych bujnym oddechem dziecka i delikatnymi różowymi akcentami, jest pięknie opakowana w pastelowy różowy papier. Idealny do wyrażania miłości i podziwu przy każdej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Lovers-2a.jpg?v=1720532747",
            "/images/products/Lovers-1a.jpg?v=1720532747"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 136,
        "name": "Ślubne III",
        "handle": "bridal-iii",
        "description": "Do tej pięknej aranżacji ślubnej dołączony będzie wazon =)",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na wesele",
            "Kwiaty i bukiety na zaręczyny"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Bridal-III---1.jpg?v=1625094630",
            "/images/products/Bridal-III---3.jpg?v=1641337555",
            "/images/products/Bridal-III---2.jpg?v=1641337548"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Spryskaj róże. Eustomy . Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 137,
        "name": "Ślub IV",
        "handle": "bridal-iv",
        "description": "Do tej pięknej aranżacji ślubnej dołączony będzie wazon =)",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na wesele",
            "Kwiaty i bukiety na zaręczyny"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Bridal-IV---1.jpg?v=1625094631",
            "/images/products/Bridal-IV---2.jpg?v=1641337452",
            "/images/products/Bridal-IV---3.jpg?v=1641337446"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Eustomy . Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 138,
        "name": "Czystość",
        "handle": "purity",
        "description": "Centralnym elementem tego eleganckiego bukietu jest oszałamiająca biała hortensja, otoczona delikatnymi różowymi różami i delikatnymi kwiatami lisianthus. Zaakcentowana gałązkami zieleni i owinięta różową wstążką, ta aranżacja emanuje wdziękiem i urokiem. Idealny do wyrażania miłości lub celebrowania wyjątkowych chwil, wnosi odrobinę wyrafinowania i ciepła na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Purity-2a.jpg?v=1726622968",
            "/images/products/Purity-1D.jpg?v=1625094634",
            "/images/products/Purity-2.jpg?v=1641311659",
            "/images/products/Purity-3A.jpg?v=1625094634",
            "/images/products/Purity-1a.jpg?v=1726622968"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Róże.  Eustoma.  Spryskaj goździki.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 139,
        "name": "Zaczarować",
        "handle": "enchant",
        "description": "„Kiedy miłość jest najlepsza, kocha się tak bardzo, że nie można zapomnieć”. – Helen Hunt Jackson",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Enchant.jpg?v=1625094635",
            "/images/products/Enchant3.jpg?v=1625094635"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 140,
        "name": "Cudeńka",
        "handle": "wonders",
        "description": "Świętuj elegancję naszego pastelowego bukietu róż. Ta oszałamiająca kompozycja składa się z zachwycającej mieszanki delikatnych różowych i kremowo żółtych róż, akcentowanych dziecięcym oddechem i bujną zielenią. Zapakowany w rustykalny brązowy papier emanuje ponadczasowym urokiem i jest idealny na każdą okazję, od rocznic po serdeczne podziękowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Wonders-2a.jpg?v=1721190860",
            "/images/products/Wonders-1a.jpg?v=1721190860"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Mattiola. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 141,
        "name": "Gwiezdny",
        "handle": "stellar",
        "description": "„Miłość zdejmuje maski, bez których boimy się żyć i wiemy, że nie możemy w nich żyć.” – Jamesa Baldwina",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Stellar-1C.jpg?v=1625094636",
            "/images/products/Stellar-3.jpg?v=1641316447",
            "/images/products/Stellar-2.jpg?v=1625094636"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 142,
        "name": "Raj",
        "handle": "paradise",
        "description": "„Miłość nie patrzy oczami, ale umysłem / I dlatego skrzydlaty Kupidyn jest zamalowany na ślepo”. - Williama Szekspira",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Paradise1-665667.jpg?v=1696490755",
            "/images/products/Paradise1A-431014.jpg?v=1696490755"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Rajski ptak.  Eustomy .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*38cm"
    },
    {
        "id": 143,
        "name": "Atasi",
        "handle": "atasi",
        "description": "Ten uderzający bukiet składa się z żywych niebieskich tulipanów, elegancko połączonych z delikatnymi białymi wypełniaczami i bujnymi zielonymi liśćmi. Zapakowany w wyrafinowany biały papier, wykończony stylową wstążką, dzięki czemu jest idealnym prezentem, aby wyrazić swoje szczere uczucia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Atasi-2a.jpg?v=1721007616",
            "/images/products/Atasi-1a.jpg?v=1721007617"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 144,
        "name": "Chardonnay",
        "handle": "chardonnay",
        "description": "Bukiet ten to romantyczna kompozycja róż w rumianych odcieniach cappuccino i szampana, uzupełniona nutami zieleni. Elegancko owinięty w papier w kolorze kości słoniowej i przewiązany miękką satynową wstążką, emanuje klasycznym urokiem, idealnym na serdeczne okazje.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Chardonnay-2a.jpg?v=1720884927",
            "/images/products/Chardonnay-1a.jpg?v=1720884927"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 145,
        "name": "Lanore",
        "handle": "lanore",
        "description": "Zanurz się w luksusie dzięki naszemu zachwycającemu bukietowi, będącemu idealnym połączeniem romantyzmu i słodyczy. Zawiera 6 aksamitnie czerwonych róż, 6 czekoladek Ferrero Rocher i delikatny dziecięcy oddech, a wszystko to elegancko opakowane w nieskazitelnie białe opakowanie. Ten wykwintny zestaw stanowi harmonijną równowagę pasji i dekadencji, co czyni go idealnym prezentem na specjalne okazje lub wyrazy miłości. Podnieś swoje gesty dzięki tej urzekającej kompozycji kwiatowo-czekoladowej.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-01-09at9.00.33AM_2_1.jpg?v=1641690079",
            "/images/products/WhatsAppImage2022-01-09at9.00.33AM_1_1.jpg?v=1641690109"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka. Ferrero Rochera"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 146,
        "name": "Kondolencje (V)",
        "handle": "condolence-v",
        "description": "W wyrazie serdecznego współczucia nasze Stojaki z kwiatami kondolencyjnymi przedstawiają pogodny pokaz czystej bieli, delikatnej żółci i kojącej zielonej chryzantemy. Ta elegancka aranżacja, ozdobiona gustowną białą listwą przypodłogową, zapewnia ukojenie i godny hołd w chwilach kondolencji i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Condolence-_V_-1d.jpg?v=1720158806",
            "/images/products/Condolence-Card-2024_1_4550e8c6-5f33-4f7b-8da9-ba1157c65306.jpg?v=1720158822",
            "/images/products/Condolence-_V_-2d.jpg?v=1720158807"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Rajskie ptaki. Cyperusowy kij. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 100cm*180cm"
    },
    {
        "id": 147,
        "name": "Kondolencje (VI)",
        "handle": "condolence-vi",
        "description": "Na uroczystą pamiątkę nasze Stojaki z kwiatami kondolencyjnymi elegancko ozdobione są czystymi białymi chryzantemami w połączeniu z ciepłymi brązowymi eustomami. Ta aranżacja stanowi spokojny hołd, symbolizujący czystość i łaskę, zapewniając pocieszenie i wsparcie w chwilach kondolencji i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Condolence-_VI_-1f.jpg?v=1720145357",
            "/images/products/Condolence-Card-2024_1_c1ca1966-ebe2-4de9-9934-357a346f4d9a.jpg?v=1720145363",
            "/images/products/Condolence-_VI_-2f.jpg?v=1720145356"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Eustoma. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 100cm*180cm"
    },
    {
        "id": 148,
        "name": "Blenda",
        "handle": "blenda",
        "description": "Ten elegancki bukiet składa się z delikatnych różowych tulipanów i delikatnych białych wypełniaczy, pięknie komponujących się z bujną zielenią. Zapakowany w stylowy szary papier i przewiązany różową wstążką, jest idealnym prezentem na każdą okazję, wyrażającym wdzięk i wyrafinowanie.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Blenda-1a.jpg?v=1720785089",
            "/images/products/Blenda-2a.jpg?v=1720785089"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 149,
        "name": "Lonna",
        "handle": "lonna",
        "description": "„Twoim zadaniem nie jest szukanie miłości, ale jedynie szukanie i odnajdywanie w sobie wszystkich barier, które przed nią zbudowałeś”. - Rumi",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lonna-1B.jpg?v=1625094643",
            "/images/products/Lonna-3.jpg?v=1641322668",
            "/images/products/Lonna-2.jpg?v=1641322579"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 150,
        "name": "Lenita",
        "handle": "lenita",
        "description": "„Jedyną rzeczą, której nigdy nie mamy dość, jest miłość; a jedyną rzeczą, której nigdy nie dajemy wystarczająco dużo, jest miłość. - Henryk Miller",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/WhatsAppImage2022-05-17at7.40.54PM_2.jpg?v=1652837835",
            "/images/products/WhatsAppImage2022-05-17at7.40.54PM_1_1.jpg?v=1652837837"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*38cm"
    },
    {
        "id": 151,
        "name": "Chelsa",
        "handle": "juliette",
        "description": "Świętuj trwałe piękno z naszym bukietem trzech zakonserwowanych pastelowych róż i hortensji. Każdy kwiat, starannie zachowany, emanuje elegancją i wdziękiem. Ta urzekająca aranżacja jest szczerym wyrazem miłości, która przetrwa próbę czasu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Chelsa-1e_42679d8b-a42a-43d1-9a4d-f8389801e30f-609135.jpg?v=1704809553",
            "/images/products/Chelsa-2e_86f9bd85-05a5-4763-a2e6-0a4b4b1898de-934554.jpg?v=1704809553"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Zachowana hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 152,
        "name": "Julia",
        "handle": "julia",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Julia-1B.jpg?v=1641315228",
            "/images/products/Julia-2B.jpg?v=1625094647",
            "/images/products/Julia-3B.jpg?v=1625094647"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowany goździk. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 10cm*15cm"
    },
    {
        "id": 153,
        "name": "Lidia",
        "handle": "lydia",
        "description": "„Nieobecność jest dla miłości tym, czym wiatr dla ognia: gasi małych, a rozpala wielkich”. – Roger de Bussy-Rabutin",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lydia-1B.jpg?v=1641321714",
            "/images/products/Lydia-2B.jpg?v=1641321708",
            "/images/products/Lydia-3B.jpg?v=1641321619"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Zachowana hortensja"
            ]
        },
        "dimention": "Szerokość*Wysokość: 8cm*12cm"
    },
    {
        "id": 154,
        "name": "Jesień",
        "handle": "autumn",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Autumn-1c.jpg?v=1625094648",
            "/images/products/Autumn-2c.jpg?v=1625094649"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Zachowany goździk. Zachowana hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 155,
        "name": "Ewa",
        "handle": "eva",
        "description": "Delikatne różowe goździki kwitną pośród pienistych łyszczec, prezentowane w ręcznie tkanym koszu ozdobionym delikatną wstążką. Ta kreacja Flowers Poland emanuje osobliwym urokiem i delikatnym wdziękiem, idealna na chwile czystego uczucia.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Eva-1c.jpg?v=1727312359",
            "/images/products/Eva-2c.jpg?v=1727312359"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*26cm"
    },
    {
        "id": 156,
        "name": "Wyspa",
        "handle": "isla",
        "description": "Odważne czerwone goździki zajmują centralne miejsce w bukiecie Flowers Poland, otoczone delikatną chmurą łyszczec, a wszystko elegancko owinięte w różowawy papier przewiązany miękką wstążką, ucieleśniającą żywe wyznanie miłości.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety goździków"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Isla-1b.jpg?v=1720662192",
            "/images/products/Isla-2a.jpg?v=1720581187"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 157,
        "name": "Zawsze",
        "handle": "everly",
        "description": "Ten żywy bukiet składa się z czerwonych i różowych goździków, uzupełnionych delikatną zielenią i srebrnymi akcentami. Zapakowany w delikatny różowy papier i przewiązany odważną czerwoną wstążką, jest efektownym i eleganckim prezentem na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Everly-2a.jpg?v=1720690330",
            "/images/products/Everly-1a.jpg?v=1720690330"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 158,
        "name": "Kondolencje (VII)",
        "handle": "condolence-vii",
        "description": "Wyraź najszczersze kondolencje za pomocą naszego stojaka na kwiaty z białym motywem. Przemyślanie skomponowany z spokojnymi chryzantemami, jedwabnymi liliami i hortensjami, a także świeżymi białymi orchideami, ten elegancki hołd emanuje komfortem i współczuciem. Gustowny sposób na okazanie pocieszenia i wsparcia w trudnych chwilach, zapewniający spokojną i pocieszającą obecność.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Condolence-_VII_-1e.jpg?v=1720146629",
            "/images/products/Condolence-Card-2024_1_08105af7-677c-4b6d-b9b5-a53be6e4c2bc.jpg?v=1720146654",
            "/images/products/Condolence-_VII_-2e.jpg?v=1720146630"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Orchidee. Eustoma. Sztuczne lilie. Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 159,
        "name": "Charlotte FBS",
        "handle": "charlotte",
        "description": "„Uwielbiam to, jak sprawia, że ​​czuję, że wszystko jest możliwe i że życie jest tego warte”. -Toma Hansena",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-10-07at2.12.22PM_2.jpg?v=1641336228",
            "/images/products/WhatsAppImage2021-10-07at2.12.22PM_1_1.jpg?v=1641336139"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Spryskaj goździki. Mamo. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 160,
        "name": "Klara FBS",
        "handle": "clara",
        "description": "Przedstawiamy uroczą kompozycję delikatnych różowych goździków umieszczonych w stylowym pojemniku przypominającym torebkę. Ta kwiatowa kreacja, ozdobiona bujnymi zielonymi liśćmi i ozdobną różową wstążką, idealnie nadaje się na prezent na specjalne okazje lub doda elegancji każdemu pomieszczeniu. Ciesz się świeżym pięknem i ponadczasowym urokiem tej uroczej ekspozycji kwiatowej.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Clara-FBS-2a-654944.jpg?v=1721831483",
            "/images/products/Clara-FBS-1a-504700.jpg?v=1721831483"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Spryskaj goździki. Spryskaj róże. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 161,
        "name": "Avery’ego FBS",
        "handle": "avery",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-08-30at11.58.23AM_2.jpg?v=1641354828",
            "/images/products/WhatsAppImage2021-08-30at11.58.23AM_1_1.jpg?v=1641354739"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Kwiat bawełny. Goździki. Róże. Spray do goździków. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 162,
        "name": "Eliza",
        "handle": "eliza",
        "description": "Wymarzony bukiet Flowers Poland, w którym wśród morza łyszczec kwitną delikatne różowe goździki, owinięty w delikatny różowy papier przewiązany wdzięczną wstążką, przywołuje na myśl delikatny szept pierwszego światła.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Eliza-2a.jpg?v=1721180969",
            "/images/products/Eliza-1a.jpg?v=1721180970"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 163,
        "name": "Wrzos",
        "handle": "heather",
        "description": "„I w ostatecznym rozrachunku miłość, którą bierzesz, jest równa miłości, którą żywisz.” - Paula McCartneya",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Heather-1.jpg?v=1625094653",
            "/images/products/Heather-3.jpg?v=1625094653",
            "/images/products/Heather-2.jpg?v=1625094654"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Spryskaj goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 164,
        "name": "Wera",
        "handle": "vera",
        "description": "Ta aranżacja Flowers Poland to poetycka mieszanka bogatej fuksji i odważnych szkarłatnych goździków, osadzona wśród delikatnych liści i owinięta lawendowym uściskiem, wykończona delikatną wstążką – celebracja żywych barw miłości.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Vera-2a.jpg?v=1720797662",
            "/images/products/Vera-1a.jpg?v=1720797661"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 165,
        "name": "Celeste",
        "handle": "celeste",
        "description": "Odkryj delikatne piękno naszego bukietu tulipanów, składającego się z pięknej gamy różowych tulipanów. Bukiet ten, zawinięty w bogaty, ciemnoczerwony papier, uosabia elegancję i wyrafinowanie. Idealna do wyrażenia miłości, podziwu lub po prostu rozjaśnienia czyjegoś dnia, ta aranżacja to ponadczasowy prezent, który mówi wiele bez słów. Żywe tulipany w połączeniu z luksusowym opakowaniem tworzą oszałamiającą prezentację, idealną na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Celeste-1.jpg?v=1641336366",
            "/images/products/Celeste-3.jpg?v=1641336362",
            "/images/products/Celeste-2.jpg?v=1641336354"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 166,
        "name": "Klarysa",
        "handle": "clarissa",
        "description": "Odważny i pełen pasji bukiet Flowers Poland składa się z bujnych kępek ciemnoczerwonych goździków otoczonych pnączem w kształcie serca, owiniętych w promienny papier w kolorze fuksji przewiązanym kaskadą wstążką, symbolizującymi szczere emocje i trwałą miłość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Clarissa-2a.jpg?v=1721102906",
            "/images/products/Clarissa-1a-720147.jpg?v=1721135818"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 167,
        "name": "Gianna",
        "handle": "gianna",
        "description": "Różnorodne, różowe goździki, uchwycone na efektownym pokazie, są elegancko otoczone splecionymi wierzbami w kształcie serc, a wszystko owinięte w miękki liliowy papier przewiązany satynową wstążką, tworząc arcydzieło Flowers Poland, które symbolizuje miłość i podziw.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Gianna-2b.jpg?v=1720662077",
            "/images/products/Gianna-1b.jpg?v=1720662077"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 168,
        "name": "Elena",
        "handle": "elena",
        "description": "Rozkoszuj się ciepłem słońca z naszym promiennym bukietem, przedstawiającym trzy żywe słoneczniki otoczone delikatnym oddechem dziecka i wesołe stokrotki. Wywołująca poczucie radości i optymizmu, ta aranżacja wprowadza ducha lata do wnętrz, dzięki czemu jest wspaniałym prezentem, który swoim naturalnym urokiem rozjaśni każdy dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Elena-1a.jpg?v=1720839208",
            "/images/products/Elena-2a.jpg?v=1720839208"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Stokrotka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 169,
        "name": "Niedopałek",
        "handle": "ember",
        "description": "„Miłość nie jest uczuciem czy emocją, która zmienia się w odpowiednim czasie, jest wieczna…” –  D'vijay",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ember---1a-133111.jpg?v=1671685200",
            "/images/products/Ember---2a.jpg?v=1625094659"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*28cm"
    },
    {
        "id": 170,
        "name": "Eleonora",
        "handle": "eleanor",
        "description": "„Niektórzy uważają, że trzymanie się i trwanie w tym miejscu jest oznaką wielkiej siły. Są jednak chwile, kiedy potrzeba znacznie więcej siły, aby wiedzieć, kiedy odpuścić i wtedy to zrobić. - Ann Landers",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2022-04-12at10.48.30AM_2.jpg?v=1649732269",
            "/images/products/WhatsAppImage2022-04-12at10.48.30AM_1_1.jpg?v=1649732269"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*28cm"
    },
    {
        "id": 171,
        "name": "Ashley",
        "handle": "ashley",
        "description": "„Miłość nie jest uczuciem czy emocją, która zmienia się w odpowiednim czasie, jest wieczna…” –  D'vijay",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ashley---1a-938602.jpg?v=1671684750",
            "/images/products/Ashley---2a.jpg?v=1625094660"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 172,
        "name": "Ela",
        "handle": "ella",
        "description": "Ten elegancki bukiet składa się z delikatnych lawendowych róż i białych kalii, podkreślonych bujną zielenią i owiniętych w miękki lawendowy i biały papier. Idealna na każdą okazję, ta aranżacja doda każdemu wnętrzu odrobinę wyrafinowania i uroku.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z lilii calla"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ella-2a.jpg?v=1720840136",
            "/images/products/Ella-1a.jpg?v=1720840136"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie kalii. Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 173,
        "name": "Disneya",
        "handle": "disney",
        "description": "Ten żywy bukiet składa się z szeregu kolorowych róż, delikatnych kwiatów i bujnej zieleni, pięknie opakowanych w rustykalny brązowy papier. Idealny na każdą okazję, wnosi wesoły i żywy akcent do każdego otoczenia, dzięki czemu jest idealnym prezentem, który rozjaśni czyjś dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Disney-2a-688729.jpg?v=1721049041",
            "/images/products/Disney-1a-833621.jpg?v=1721049041"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździk . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 174,
        "name": "Hanna",
        "handle": "hannah",
        "description": "„Życie zaczyna się od środka. Kiedy zmieniasz się od wewnątrz, życie zmienia się na zewnątrz”. – Kamala Ravikanta",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Hannah1.jpg?v=1667947833",
            "/images/products/Hannah2.jpg?v=1667947833"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 175,
        "name": "Kellera",
        "handle": "keller",
        "description": "Ogarnij pasję swojego wyjątkowego dnia dzięki naszemu czarującemu bukietowi 12 jaskraworóżowych róż elegancko zamkniętych w eleganckim chromowanym opakowaniu. Każdy aksamitny kwiat emanuje miłością i pożądaniem, tworząc uderzający i współczesny wyraz uczuć. Podaruj ten bukiet, aby wyrazić swoje najgłębsze emocje i uczcić romantyzm tej okazji w wielkim stylu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Keller-1dd_2f843ff9-49e0-4d08-ae46-5d5e7adc076c.jpg?v=1704822134",
            "/images/products/Keller-2dd_5609bb14-8e37-4206-ab32-cf44fc0eb18a-501471.jpg?v=1704896327"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 gorących różowych róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 176,
        "name": "Beatrice",
        "handle": "beatrice",
        "description": "Ten bukiet przedstawia zachwycającą kolekcję drobnych stokrotek o białych kwiatach, oprawionych w stylową beżową chustę, zabezpieczoną miękką satynową wstążką. Bukiet emanuje spokojną elegancją, dzięki czemu jest idealnym prezentem, który wyraża czystość, ciepło i troskę.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Beatrice-2c-423174.jpg?v=1724863709",
            "/images/products/Beatrice-1c.jpg?v=1724809093"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Stokrotki"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 177,
        "name": "Luiza",
        "handle": "louise",
        "description": "„Życie nie polega na trzymaniu dobrych kart, ale czasami na dobrym rozegraniu słabej ręki”. - Jacka Londona",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Louise---1b-797303.jpg?v=1671685424",
            "/images/products/Louise---2b.jpg?v=1625094665"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 178,
        "name": "Nellie",
        "handle": "nellie",
        "description": "„Życie jest jak pudełko czekoladek. Nigdy nie wiesz, co dostaniesz”. - Forresta Gumpa",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2022-04-13at2.27.35PM_3_1.jpg?v=1649831316",
            "/images/products/WhatsAppImage2022-04-13at2.27.35PM_2_1.jpg?v=1649831316"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 179,
        "name": "Yvette",
        "handle": "yvette",
        "description": "Rozjaśnij każdą okazję naszym uroczym bukietem składającym się z 6 promiennych żółtych róż osadzonych wśród delikatnych kwiatów kaspii. Słoneczny odcień róż uzupełniony zwiewną elegancją kaspii tworzy urzekający zestaw. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Yvette-1a.jpg?v=1720777893",
            "/images/products/Yvette-2a.jpg?v=1720777893"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Żółte Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 180,
        "name": "Mak roślina",
        "handle": "poppy",
        "description": "Świętuj urodziny lub rocznice z naszym bukietem Sunshine Bliss Bouquet, zachwycającą kompozycją sześciu róż i róż w sprayu w wesołej fuzji żółci i różu. Żywe żółte róże symbolizują radość, a różowe róże w sprayu dodają odrobinę romantyzmu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Poppy-1j-388229.jpg?v=1720789706",
            "/images/products/Poppy_2i-296413.jpg?v=1720789706"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 181,
        "name": "Ofelia",
        "handle": "ophelia",
        "description": "Ten żywy bukiet składa się z oszałamiającej mieszanki pomarańczowych i żółtych róż, elegancko ułożonych z bujną zielenią i delikatnymi białymi wypełniaczami. Ozdobiony uroczymi wiklinowymi uchwytami w kształcie serca i owinięty rustykalnym brązowym papierem, jest idealnym prezentem, dzięki któremu możesz wyrazić płynące z serca emocje.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ophelia-2a-327229.jpg?v=1721049443",
            "/images/products/Ophelia-1a-807616.jpg?v=1721049443"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 182,
        "name": "Altówka",
        "handle": "viola",
        "description": "Ten bukiet przedstawia radosną kompozycję, w której centralnym elementem jest duży, żywy słonecznik. Słonecznik otaczają bujne żółte róże, które dodają bukietowi bogatej tekstury i głębi. Delikatne białe kwiaty wypełniające posypane są całą powierzchnią, zapewniając piękny kontrast i miękkość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Viola-1a_54504301-9a36-4257-af78-824eb970d62e-720448.jpg?v=1720791509",
            "/images/products/Viola-2a_5e40e2e7-5377-44d3-9f27-19808f306e8c.jpg?v=1720748606"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Spryskaj róże. Ammi Maju. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 183,
        "name": "Colleen",
        "handle": "colleen",
        "description": "Bukiet eleganckich brzoskwiniowych róż połączonych z delikatnymi białymi akcentami i bujną zielenią, pięknie opakowany w rustykalny brązowy papier. Ta aranżacja emanuje ponadczasowym urokiem i wyrafinowaniem, dzięki czemu jest idealnym prezentem, aby wyrazić miłość i podziw na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Colleen-2a-733446.jpg?v=1721048924",
            "/images/products/Colleen-1a-758566.jpg?v=1721048924"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 184,
        "name": "Paislee",
        "handle": "paislee",
        "description": "Obejmij spokój słońca dzięki temu bukietowi składającemu się z siedmiu żółtych róż z wdziękiem otoczonych koronką w żółtych i białych odcieniach. Harmonijne połączenie kolorów tworzy poczucie spokoju, dzięki czemu ta aranżacja jest idealnym prezentem do przekazywania życzeń, wdzięczności lub celebrowania wyjątkowych chwil z odrobiną wyrafinowanego piękna.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Paislee-1aa-104360.jpg?v=1706109372",
            "/images/products/Paislee-2aa-416881.jpg?v=1706109372"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 185,
        "name": "Bexleya",
        "handle": "bexley",
        "description": "Poczuj czystość i elegancję dzięki naszemu czarującemu bukietowi składającemu się z 6 nieskazitelnych białych kwiatów gossypium (bawełny), delikatnie ozdobionych kapryśnym pięknem tęczowego oddechu dziecka.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Bexley-2b.jpg?v=1720793862",
            "/images/products/Bexley-1b.jpg?v=1720793862"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Oddech dziecka w kolorze jednorożca"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 186,
        "name": "Merritt",
        "handle": "merritt",
        "description": "Postaw na prostotę dzięki naszemu eleganckiemu bukietowi białych tulipanów. Ta kompozycja składa się z dziewiczych białych tulipanów otoczonych bujnym eukaliptusem i delikatną ammi maju, a wszystko to owinięte w miękki niebieski papier. Idealny do dodania odrobiny wyrafinowania każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Merritt-1b-233208.jpg?v=1720789521",
            "/images/products/Merritt-1a-549408.jpg?v=1720878358"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 187,
        "name": "Oriana",
        "handle": "oriana",
        "description": "Ten bukiet to celebracja koloru, z wesołym słonecznikiem, różami w kolorze kości słoniowej i żywym różowym kwiatem w sercu. Aranżację akcentują delikatny oddech dziecka i srebrzyste liście, owinięte w przezroczystą warstwę pokrytą odważnym niebieskim papierem i dużą niebieską satynową wstążką dla efektownego wykończenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Oriana-1a.jpg?v=1720749966",
            "/images/products/Oriana-2a.jpg?v=1720749966"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 188,
        "name": "Ladonna",
        "handle": "ladonna",
        "description": "Świętuj elegancję dzięki naszemu pełnemu wdzięku bukietowi kalii. Ta kompozycja, ozdobiona oszałamiającymi białymi kaliami otoczonymi delikatnymi różowymi różami i bujną zielenią, jest owinięta delikatnym różowym papierem. Idealny na wesela, rocznice lub inne specjalne okazje, wnosi odrobinę wyrafinowania i uroku do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z lilii calla"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ladonna-2a.jpg?v=1721225224",
            "/images/products/Ladonna-1a.jpg?v=1721225225"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie kalii. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 189,
        "name": "Tinley",
        "handle": "tinley",
        "description": "Uchwyć urok naszego Mieszanego Bukietu Róż – zachwycającej fuzji 4 gorących róż, 4 białych i 4 różowych róż, pomysłowo otulonych miękkim różowym opakowaniem. Uzupełniony delikatnym oddechem dziecka, ten bukiet emanuje romantyzmem i elegancją, dzięki czemu jest idealnym prezentem na każdą okazję, która wymaga odrobiny wyrafinowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Tinley-2d-127638.jpg?v=1720963729",
            "/images/products/Tinley-1d.jpg?v=1720880515"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 190,
        "name": "Cynia",
        "handle": "zinnia",
        "description": "Wyrafinowany bukiet z eleganckimi różami w kolorze cappuccino, artystycznie ułożonymi i owiniętymi w nieskazitelny biały papier z delikatną białą koronkową wstążką. Ta ponadczasowa kompozycja doskonale wyraża wdzięk i wyrafinowanie, dzięki czemu jest idealnym prezentem na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Zinnia-2a.jpg?v=1721009303",
            "/images/products/Zinnia-1a.jpg?v=1721009303"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże capuccino"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 191,
        "name": "Szkarłatny FBS",
        "handle": "scarlet",
        "description": "„Nigdy nie pozwól, aby strach przed uderzeniem powstrzymywał cię od gry”. - Kochanie Ruth",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at10.42.59AM_2.jpg?v=1648003440",
            "/images/products/WhatsAppImage2022-03-23at10.42.59AM_1_1.jpg?v=1648003441"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 192,
        "name": "Iwana",
        "handle": "ivana",
        "description": "Czarujący bukiet składający się z harmonijnej mieszanki kremowobiałych i delikatnych lawendowych róż, uzupełniony delikatnymi wypełniaczami i bujnymi liśćmi eukaliptusa. Elegancko opakowana w beżowy papier z białą wstążką, ta kompozycja emanuje elegancją i urokiem, idealna na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ivana-2a_5a4dc3b8-352c-46ba-82f8-70e1cb05bd5d.jpg?v=1721010062",
            "/images/products/Ivana-1a.jpg?v=1721010062"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Meta róże. Białe Róże. Kwiaty Woskowe. Statyczne Kwiaty. Eukaliptus"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 193,
        "name": "Hadley FBS",
        "handle": "hadley",
        "description": "„Nikt nie może z tobą konkurować w byciu tobą. Większość życia to poszukiwanie tego, kto i co cię najbardziej potrzebuje”. - Morski Ravikant",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Hadley---1.jpg?v=1641328927",
            "/images/products/Hadley---2.jpg?v=1641328921"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 194,
        "name": "Dafne",
        "handle": "daphne",
        "description": "Bukiet ten składa się z brzoskwiniowych tulipanów z żółtymi nutami u nasady płatków, którym towarzyszą delikatna biała łyszczec i bujna zieleń. Owinięty w beżowo-biały papier z kremową satynową kokardką, zapewnia ciepłą, delikatną i elegancką prezentację.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Daphne-1d-653977.jpg?v=1720788754",
            "/images/products/Daphne-1c-424509.jpg?v=1720842430"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 195,
        "name": "Braelynn",
        "handle": "braelynn",
        "description": "Ten elegancki bukiet składa się z delikatnej mieszanki białych róż, delikatnych niebieskich kwiatów i żółtych lilii, akcentowanych bujną zielenią. Zapakowany w jasnożółty papier i przewiązany białą wstążką, będzie idealnym prezentem na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Braelynn-2a.jpg?v=1720742120",
            "/images/products/Braelynn-1a.jpg?v=1720742120"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 196,
        "name": "Darby",
        "handle": "darby",
        "description": "Uświetnij każdą okazję naszym wyrafinowanym bukietem lilii i orchidei. Ta kompozycja, zawierająca eleganckie białe lilie, delikatne róże i efektowne czerwone orchidee, jest pięknie zaakcentowana bujną zielenią. Zapakowany w nieskazitelny biały papier i przewiązany satynową wstążką, idealnie nadaje się do świętowania kamieni milowych, wyrażania wdzięczności lub dodawania odrobiny wyrafinowania każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety lilii",
            "Kwiaty i bukiety róż"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Darby-2a.jpg?v=1721216491",
            "/images/products/Darby-1a.jpg?v=1721216491"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie .  Eustoma.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*46cm"
    },
    {
        "id": 197,
        "name": "Callie FBS",
        "handle": "callie",
        "description": "„Wszystkie nasze marzenia mogą się spełnić, jeśli mamy odwagę je realizować”. Walta Disneya",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-10-07at12.03.08PM_2.jpg?v=1641337087",
            "/images/products/WhatsAppImage2021-10-07at12.03.08PM_1_1.jpg?v=1641337081"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Oddech dziecka. Niedźwiedź kończący szkołę. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 198,
        "name": "Faye",
        "handle": "faye",
        "description": "Dramatyczny i namiętny bukiet efektownych czerwonych róż, starannie ułożony i owinięty w elegancki czarny papier. Odważny kontrast uwydatnia żywy kolor róż, czyniąc ten bukiet doskonałym wyrazem miłości i podziwu. Idealny do romantycznych gestów i specjalnych okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Faye-2b.jpg?v=1721010443",
            "/images/products/Faye-1b.jpg?v=1721010443"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 róż. Spryskaj róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 199,
        "name": "Madison",
        "handle": "madison",
        "description": "Ten żywy bukiet składa się z trzech jasnych słoneczników, uzupełnionych bujną zielenią i czerwonymi jagodami. Zapakowany w rustykalny brązowy papier i przewiązany elegancką wstążką, emanuje ciepłem i radością, dzięki czemu jest idealnym prezentem na każdą radosną okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Madison-2a.jpg?v=1720790665",
            "/images/products/Madison-1a.jpg?v=1720790665"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Hypericum. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 200,
        "name": "Ellie",
        "handle": "ellie",
        "description": "Świętuj miłość naszym wykwintnym bukietem 6 namiętnych czerwonych róż, pięknie otoczonych delikatną kaspią, a wszystko to zamknięte w wierzbowej ramce w kształcie serca w urzekających różowych odcieniach. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ellie-2a.jpg?v=1720693306",
            "/images/products/Ellie-1a.jpg?v=1720693306"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 201,
        "name": "Lea",
        "handle": "leah",
        "description": "Ten czarujący bukiet składa się z delikatnych różowych tulipanów, delikatnego rumianku i pachnącej lawendy otulonych lekkim oddechem dziecka. Owinięta w pastelowy różowy papier i ozdobiona pasującą wstążką, ta kompozycja emanuje elegancją i urokiem, idealna na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Leah-2a.jpg?v=1720918864",
            "/images/products/Leah-1a.jpg?v=1720918864"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka. Stokrotka. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 202,
        "name": "Claire",
        "handle": "claire",
        "description": "Poczuj czystość i elegancję naszego oszałamiającego białego bukietu. Ta kompozycja, zawierająca harmonijną mieszankę białych róż, hortensji i delikatnych kwiatów, zaakcentowana bujną zielenią i fantazyjnymi detalami w postaci pomponów, jest owinięta w nieskazitelny biały papier. Idealny na wesela, rocznice lub inne specjalne okazje, emanuje ponadczasowym wdziękiem i wyrafinowaniem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Claire-1a.jpg?v=1721214340",
            "/images/products/Claire-2a.jpg?v=1721214340"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Goździki. Mamo. Eustoma. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 203,
        "name": "Janelle",
        "handle": "janelle",
        "description": "Elegancja spotyka się z romantyzmem w naszym oszałamiającym bukiecie z harmonijną mieszanką białych i różowych róż, podkreślonych delikatnym urokiem zakurzonego młynarza. Ten czarujący zestaw jest delikatnie zaaranżowany i zapakowany w urocze różowe opakowanie, tworząc ponadczasowy wyraz wyrafinowania. Idealny na wesela, rocznice lub do wyrażania uczuć podziwu, ten bukiet emanuje wdziękiem i pięknem w każdym rozkwicie.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Janelle-2a.jpg?v=1721272314",
            "/images/products/Janelle-1a.jpg?v=1721272313"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Zakurzeni Millerowie"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 204,
        "name": "Keily FBS",
        "handle": "keily",
        "description": "Zawiera żywe czerwone róże, delikatne białe stokrotki i bujną zieleń, a wszystko to pięknie ułożone w eleganckiej brązowej torebce. Idealny do wyrażenia miłości i podziwu, ten oszałamiający bukiet zapewnia ponadczasowe piękno na każdą okazję.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Keily-FBS-1a-548275.jpg?v=1720030669",
            "/images/products/Keily-FBS-2a-164301.jpg?v=1720030669"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 205,
        "name": "Lindsey",
        "handle": "lindsey",
        "description": "Ten czarujący bukiet składa się z zachwycającej mieszanki konserwowanych fioletowych i różowych kwiatów, akcentowanej pluszowym misiem w stroju kończącym szkołę. Zapakowany w elegancki lawendowy papier i przewiązany pasującą wstążką, będzie idealnym prezentem z okazji ukończenia szkoły.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lindsey-2a.jpg?v=1720675478",
            "/images/products/Lindsey-1a.jpg?v=1720675478"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Suszone Kwiaty. Niedźwiedź kończący szkołę"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 206,
        "name": "Sari",
        "handle": "sariah",
        "description": "Ten piękny bukiet składa się z mieszanki zakonserwowanych różowych i białych kwiatów, uzupełnionych uroczym pluszowym misiem w stroju kończącym szkołę. Zapakowany w delikatny różowy papier i przewiązany pasującą wstążką, jest idealnym prezentem z okazji ukończenia szkoły.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Sariah-2a.jpg?v=1720674582",
            "/images/products/Sariah-1a.jpg?v=1720674582"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Suszone Kwiaty. Niedźwiedź kończący szkołę"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 207,
        "name": "Diana",
        "handle": "diana",
        "description": "Odkryj uderzający urok naszego bukietu białych róż o czarnym odcieniu. Dwanaście nieskazitelnych białych róż, każda ozdobiona subtelnym czarnym odcieniem, jest nienagannie ułożonych i prezentowanych w eleganckim czarnym opakowaniu. To wyjątkowe połączenie czystości i wyrafinowania sprawia, że ​​jest to wykwintny prezent, idealny na każdą stylową i niezapomnianą uroczystość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Diana-2d.jpg?v=1721268933",
            "/images/products/Diana-1d.jpg?v=1721268933"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 208,
        "name": "Podróż",
        "handle": "journee",
        "description": "Świętuj z żywą elegancją naszego tęczowego oddechu dziecka i bukietu róż. Ta kompozycja, zawierająca klasyczne czerwone róże otoczone oszałamiającą gamą wielobarwnych dziecięcych oddechów, jest owinięta rustykalnym brązowym papierem i przewiązana satynową wstążką. Idealny do wyrażania miłości, radości lub upamiętniania specjalnych okazji, dodaje odrobinę kolorowego uroku i piękna każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Journee-2a.jpg?v=1721351699",
            "/images/products/Journee-1a.jpg?v=1721351699"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Tęczowy oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 209,
        "name": "Paige",
        "handle": "paige",
        "description": "Ten elegancki bukiet składa się z ciemnoniebieskich zakonserwowanych kwiatów i delikatnych białych kwiatów, zwieńczonych uroczym pluszowym misiem w stroju kończącym szkołę. Zapakowany w miękki biało-niebieski papier i przewiązany pasującą wstążką, jest idealnym prezentem dla absolwentów.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Paige-2a-246249.jpg?v=1720789705",
            "/images/products/Paige-1a.jpg?v=1720672333"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Suszone Kwiaty. Niedźwiedź kończący szkołę"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 210,
        "name": "Noelle FBS",
        "handle": "noelle",
        "description": "Zawiera wesołą mieszankę pomarańczowych i żółtych stokrotek gerbera, akcentowaną bujnym eukaliptusem i delikatnymi wypełniaczami. Bukiet ten, zapakowany w stylową brązową torebkę, emanuje ciepłem i radością, co czyni go idealnym prezentem na każdą okazję.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Noelle-FBS-1a.jpg?v=1719975994",
            "/images/products/Noelle-FBS-2a-847198.jpg?v=1720030794"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 211,
        "name": "Nowa",
        "handle": "novah",
        "description": "Ta tętniąca życiem kompozycja składa się z wesołych słoneczników, pomarańczowych róż i żółtych kwiatów, uzupełnionych bujną zielenią. Zapakowany w efektowny czarny papier i przewiązany pasującą wstążką, stanowi odważny i stylowy dodatek, idealny do rozjaśnienia każdego pomieszczenia lub okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Novah-1a.jpg?v=1720839426",
            "/images/products/Novah-2a-549396.jpg?v=1720876241"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Gerbera . Mamo. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 212,
        "name": "Kimorę",
        "handle": "kimora",
        "description": "Ten bukiet to zachwycająca i żywa kompozycja z udziałem wielobarwnych chryzantem pomponowych. Każdy pompon ma inny kolor tęczy, w tym czerwony, pomarańczowy, żółty, zielony, niebieski, fioletowy i różowy. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Kimora-2a.jpg?v=1721103101",
            "/images/products/Kimora-1a.jpg?v=1721103101"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Milczący"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 213,
        "name": "Azaria",
        "handle": "azaria",
        "description": "Rozkoszuj się ciepłem miłości dzięki naszemu bukietowi składającemu się z dziewięciu uroczych róż w kolorze cappuccino w połączeniu z delikatnymi pomarańczowymi różami w sprayu. Opakowana w wyrafinowany, nude, aranżacja ta emanuje elegancją i subtelną pasją. Podziel się bogactwem swoich uczuć dzięki temu wyjątkowemu i stylowemu wyrazowi uczuć.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Azaria-1d-984264.jpg?v=1721135613",
            "/images/products/Azaria-1c.jpg?v=1721103536"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże capuccino. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 214,
        "name": "Ingrid",
        "handle": "ingrid",
        "description": "Podnieś swoje kwiatowe doznania dzięki naszemu wykwintnemu bukietowi składającemu się z 6 szampańskich róż delikatnie uzupełnionych ponadczasowym urokiem kaspii. Miękkie, stonowane odcienie róż, przypominające musujący szampan, płynnie komponują się z delikatną kaspią, tworząc wyrafinowaną i wyrafinowaną aranżację. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ingrid-1a.jpg?v=1720841171",
            "/images/products/Ingrid-2a.jpg?v=1720841171"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Szampańskie róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 215,
        "name": "Kimber",
        "handle": "kimber",
        "description": "Wyraź odważne oświadczenie dzięki naszemu luksusowemu bukietowi czerwonych róż. Ta kompozycja, składająca się z tuzina promiennych czerwonych róż uzupełnionych akcentami eukaliptusowymi i owiniętych w elegancki beżowy papier, emanuje ponadczasowym romantyzmem i wyrafinowaniem. Idealny do wyrażenia głębokiej miłości i pasji, jest idealnym prezentem na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Kimber-2a-165026.jpg?v=1721222879",
            "/images/products/Kimber-1a-626001.jpg?v=1721222879"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 róż. Listowie"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 216,
        "name": "Elliota",
        "handle": "elliot",
        "description": "Ten elegancki bukiet przedstawia spokojną paletę pastelowych niebieskich róż, uzupełnioną chmurami oddechu dziecka. Kwiaty są starannie ułożone i owinięte w półprzezroczysty różowy papier, dodając prezentacji szeptu wdzięku i kobiecości.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Elliot-2f.jpg?v=1720710563",
            "/images/products/Elliot-1f.jpg?v=1720710563"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 róż. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 217,
        "name": "Tiana",
        "handle": "tiana",
        "description": "Urzekający czystą elegancją, ten bukiet składa się z nieskazitelnych białych róż, a każdy kwiat jest symbolem niewinności i lojalności, kołysany delikatnym uściskiem oddechu dziecka. Z wdziękiem zapakowany w przezroczystą chustę z satynowymi wstążkami, to klasyczny wybór na wesela, rocznice lub szczery gest.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Tiana-2d.jpg?v=1720710299",
            "/images/products/Tiana-1d.jpg?v=1720710299"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Białe Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 218,
        "name": "Katalina",
        "handle": "katalina",
        "description": "Urzekający bukiet składający się z eleganckich białych róż, żywego fioletu lisianthus i delikatnych akcentów lawendy. Ta oszałamiająca kompozycja jest pięknie owinięta w miękki pastelowy papier i wykończona pasującą wstążką, co czyni ją znakomitym wyborem do celebrowania wyjątkowych chwil w życiu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Katalina-1a.jpg?v=1720919130",
            "/images/products/Katalina-2a.jpg?v=1720919131"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Białe Róże. Eustoma z fioletową końcówką. Fioletowa Mattiola. Kwiaty Woskowe. Kaspia. Eukaliptus"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 219,
        "name": "Eliza",
        "handle": "elyse",
        "description": "„Życie to gra, a prawdziwa miłość to trofeum”. -Rufusa Wainwrighta",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2022-04-12at11.15.01AM_1.jpg?v=1649733341",
            "/images/products/WhatsAppImage2022-04-12at10.48.24AM_1_1.jpg?v=1649733341"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*28cm"
    },
    {
        "id": 220,
        "name": "Merlota",
        "handle": "merlot",
        "description": "Ten elegancki bukiet składa się z mieszanki czerwonych róż, brzoskwiniowego lisianthus i żywej pomarańczowej alstroemerii, akcentowanych bujną zielenią. Zapakowany w bogaty czerwony papier i przewiązany pasującą wstążką, jest efektownym prezentem, który pozwala wyrazić miłość i podziw.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Merlot-2b.jpg?v=1720841604",
            "/images/products/Merlot-1a.jpg?v=1720703652"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 221,
        "name": "Sawanna",
        "handle": "savannah",
        "description": "Ta oszałamiająca kompozycja składa się z żywych róż w kolorze fuksji, osadzonych wśród morza łyszczec, tworząc uderzający kontrast. Bukiet, pięknie owinięty w przezroczysty biały papier i ozdobiony przezroczystymi wstążkami, stanowi odważne wyrażenie głębokiego uczucia i jest idealny na każdą niezapomnianą uroczystość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Savannah-2b.jpg?v=1720710008",
            "/images/products/Savannah-1b.jpg?v=1720710008"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Fioletowe Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 222,
        "name": "Karolina",
        "handle": "caroline",
        "description": "Ten elegancki bukiet składa się z kilkunastu delikatnych różowych róż, z których każda symbolizuje podziw i wdzięczność. Róże otoczone chmurą oddechu dziecka są owinięte w wdzięczny biały i delikatny różowy papier, akcentowany żywą różową wstążką. Idealny do wyrażania najgłębszych emocji i sprawiania, że ​​każda okazja będzie wyjątkowa.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Caroline-2g.jpg?v=1720708845",
            "/images/products/Caroline-1g.jpg?v=1720708845"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Różowe Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 223,
        "name": "Presleya FBS",
        "handle": "presley",
        "description": "Ta elegancka kompozycja składa się z delikatnych różowych i fioletowych róż, uzupełnionych delikatnymi wypełniaczami i bujną zielenią. Zapakowany w elegancką brązową torebkę z lawendową wstążką, jest idealnym prezentem, który wyrazi wdzięk i wyrafinowanie na każdą wyjątkową okazję.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Presley-FBS-2b.jpg?v=1721692957",
            "/images/products/Presley-FBS-1b.jpg?v=1721692957"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 224,
        "name": "Haisley'a",
        "handle": "haisley",
        "description": "Wykwita pojedynczy odważny słonecznik, a jego złote płatki promieniują ciepłem i szczęściem. Uzupełniające się odcienie fioletu i lawendy, róż i kwiatów dodają królewskiego akcentu, a nuty delikatnej różowej hortensji wnoszą do aranżacji delikatny romans. Pasma zieleni i delikatne kwiaty wypełniające tworzą bujne tło, a wszystko to owinięte w papier rzemieślniczy o naturalnej fakturze.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Haisley-2f-802918.jpg?v=1720789072",
            "/images/products/Haisley-1f.jpg?v=1720744192"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Eustomy . Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 225,
        "name": "Ensleya",
        "handle": "ensley",
        "description": "Rozjaśnij swój dzień naszym bukietem słonecznikówNasz czarujący, mieszany bukiet kwiatowy składa się z żywego słonecznika otoczonego delikatnymi różowymi różami, goździkami i bujną zielenią, a wszystko to elegancko opakowane w rustykalny zestaw z brązowego papieru. Idealny na każdą okazję, ten bukiet niesie radość i ciepło, dzięki czemu będzie wspaniałym prezentem. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ensley-2a.jpg?v=1720780177",
            "/images/products/Ensley-1a.jpg?v=1720780177"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róża. Goździk . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 226,
        "name": "Walentyna",
        "handle": "valentina",
        "description": "Zanurz się w czystym pięknie naszego Bukietu Białych Róż, składającego się z sześciu nieskazitelnych białych róż elegancko otoczonych delikatnym oddechem dziecka, a wszystko zamknięte w uroczym różowym opakowaniu. Ta ponadczasowa aranżacja emanuje wdziękiem i prostotą, dzięki czemu jest doskonałym wyrazem czystości i serdecznych uczuć na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Valentina-2d-588477.jpg?v=1721050596",
            "/images/products/Valentina-1d-221804.jpg?v=1721050596"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 227,
        "name": "Nevaeh",
        "handle": "nevaeh",
        "description": "Bukiet ten oferuje uderzającą mieszankę faktur i kolorów, skupioną wokół żywego słonecznika. Karmazynowe róże i bogaty czerwony goździk nadają odrobinę klasycznego romansu, a żółta alstroemeria i różnorodna zieleń zapewniają żywe tło.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Naveah-2a.jpg?v=1720746439",
            "/images/products/Naveah-1a.jpg?v=1720746439"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róża. Eustoma. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 228,
        "name": "Quinn",
        "handle": "quinn",
        "description": "Symfonia siedmiu różowych róż rozwija się w zapierającym dech w piersiach pokazie, otoczonym różową i białą koronką. Połączenie romantycznych odcieni różu i misternego wzoru koronki tworzy bukiet marzycielski i wyrafinowany. Ta aranżacja jest znakomitym wyborem do wyrażania uczuć miłości i czułości przy specjalnych okazjach.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Quinn-1aa_1-461826.jpg?v=1706197543",
            "/images/products/Quinn-2aa_1-782550.jpg?v=1706197543"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 229,
        "name": "Eliana",
        "handle": "eliana",
        "description": "Ten urzekający bukiet kipi życiem, skupiając się wokół promiennego słonecznika. Uzupełniają go róże w odcieniach delikatnego kremu, różu i głębokiego karmazynu, tworząc dynamiczną paletę. Oddech dziecka i zielone liście dodają świeżości, a wszystko to elegancko opakowane w brązowy papier z kaskadową wstążką, ucieleśniające żywą elegancję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Eliana-1aa.jpg?v=1704817085",
            "/images/products/Eliana---Delivery-_1_1.jpg?v=1716209974",
            "/images/products/Eliana-2aa.jpg?v=1716209974"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róża. Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 230,
        "name": "Brooklynie",
        "handle": "brooklyn",
        "description": "Ten żywy bukiet składa się z efektownego słonecznika pośrodku, otoczonego eleganckimi białymi różami i lisianthus, akcentowanymi bujną zielenią i delikatnymi wypełniaczami. Zapakowany w rustykalny brązowy papier i przewiązany wstążką, to idealny prezent, który umili każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty na zdrowie i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Brooklyn-2a-663383.jpg?v=1720788698",
            "/images/products/Brooklyn-1a-810184.jpg?v=1720788698"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róża. Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 231,
        "name": "Natalia FBS",
        "handle": "natalie",
        "description": "Ta urocza kompozycja składa się z miękkich, puszystych bawełnianych kwiatów w połączeniu z kolorowym oddechem dziecka, tworząc kapryśny i spokojny obraz. Umieszczony w eleganckiej, brązowej torebce, jest idealnym prezentem, który doda elegancji i spokoju każdemu pomieszczeniu.",
        "type": "Seria Torebek",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Natalie-FBS-1a.jpg?v=1719975621",
            "/images/products/Natalie-FBS-2a.jpg?v=1719975621"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 232,
        "name": "Xandra",
        "handle": "xandra",
        "description": "Ten bukiet to symfonia odważnego romansu, składająca się z gorących, różowych róż otoczonych efektownym czarnym opakowaniem, podkreślonych obfitym oddechem dziecka. Duet kontrastujących różowych i czarnych wstążek dodaje nowoczesnego akcentu klasycznej róży, czyniąc ją niezapomnianym prezentem dla każdego miłośnika piękna.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Xandra-2c.jpg?v=1720708218",
            "/images/products/Xandra-1c.jpg?v=1720708218"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 233,
        "name": "Talia",
        "handle": "talia",
        "description": "„Najlepszych i najpiękniejszych rzeczy na tym świecie nie można zobaczyć ani nawet usłyszeć, ale trzeba je poczuć sercem”. - Helena Keller",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Talia-1.jpg?v=1628486564",
            "/images/products/Talia-2-664311.jpg?v=1671775915"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 234,
        "name": "Sabrina",
        "handle": "sabrina",
        "description": "„Życie bez miłości jest jak drzewo bez kwiatów i owoców”. – Khalila Gibrana",
        "type": "Bukiety",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Sabrina-1.jpg?v=1628486564",
            "/images/products/Sabrina-2.jpg?v=1641310319"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 50cm*60cm"
    },
    {
        "id": 235,
        "name": "Tabby FBS",
        "handle": "tabby",
        "description": "„Nigdy nie pozwól, aby strach przed uderzeniem powstrzymywał cię od gry”. - Kochanie Ruth",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at10.37.39AM.jpg?v=1648003197",
            "/images/products/WhatsAppImage2022-03-23at10.37.39AM_1.jpg?v=1648003200"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 236,
        "name": "Ebele FBS",
        "handle": "ebele",
        "description": "Centralnym elementem tej uroczej kompozycji jest efektowna niebieska róża, uzupełniona miękkimi bawełnianymi kwiatami i delikatną zielenią. Bukiet ten, zamknięty w wyjątkowej i stylowej torbie, przewiązanej jasnoniebieską wstążką, jest idealny dla tych, którzy cenią zarówno elegancję, jak i innowacyjność. Idealny na prezent lub jako element dekoracyjny, wnosi odrobinę nowoczesnego stylu do każdego wnętrza.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Ebele-2a.jpg?v=1724291587",
            "/images/products/Ebele-1a.jpg?v=1724291588"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 237,
        "name": "Edwina FBS",
        "handle": "edwina",
        "description": "„Nigdy nie pozwól, aby strach przed uderzeniem powstrzymywał cię od gry”. - Kochanie Ruth",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at10.35.11AM_2_1.jpg?v=1648003591",
            "/images/products/WhatsAppImage2022-03-23at10.35.11AM_1_1.jpg?v=1648003591"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 238,
        "name": "Elmira FBS",
        "handle": "elmira",
        "description": "„Nigdy nie pozwól, aby strach przed uderzeniem powstrzymywał cię od gry”. - Kochanie Ruth",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-04-01at11.02.09AM.jpg?v=1648782495",
            "/images/products/WhatsAppImage2022-04-01at11.02.09AM_1.jpg?v=1648782497"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowany goździk. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 239,
        "name": "Aubrey",
        "handle": "aubrey",
        "description": "„Jeśli chcesz wieść szczęśliwe życie, zwiąż je z celem, a nie z ludźmi czy rzeczami”. – Alberta Einsteina",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Aubrey-1-393592.jpg?v=1671684751",
            "/images/products/Aubrey-2-561472.jpg?v=1671684751"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 240,
        "name": "Smaczny",
        "handle": "matilda-fruit-hamper",
        "description": "Tasty Fresh Zachwyć każdego tą wspaniałą skrzynką świeżych owoców tropikalnych! Kosz Tasty Fresh jest wypełniony po brzegi przepysznymi i zachęcającymi owocami, a piękne kwiaty sprawiają, że kosz ten jest najdoskonalszym prezentem.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Matilda1-674503.jpg?v=1671775916",
            "/images/products/Matilda2-207885.jpg?v=1671775916"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Jabłko",
                "2. Smocze owoce",
                "3. Pomarańczowy",
                "4. Zielone winogrona",
                "5. Gruszka Nam Shui",
                "6. Kiwi",
                "7. Awokado",
                "8. Gruszka Forelle",
                "9. Zielone jabłko",
                "10. Mango",
                "11. W zestawie kwiaty i opakowanie"
            ]
        },
        "dimention": "Rozmiar Kosza Upominkowego: 40cm x25cm x 28cm"
    },
    {
        "id": 241,
        "name": "Crispy Bites WHP",
        "handle": "millie-wellness-hamper",
        "description": "Crispy BitesPozdrowienia dla zdrowia! Kosz Crispy Bites Get Well Soon zawiera wszystkie zdrowe, ale pyszne przekąski, jakie ma do zaoferowania natura! Zaprojektowany i stworzony, aby pomóc ukochanej osobie stanąć na nogi, ten asortyment zachwycających i zdrowych przekąsek z pewnością ożywi jej dzień!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Millie-WHP2a-511914.jpg?v=1671685198",
            "/images/products/Millie-WHP1a-117864.jpg?v=1671685198"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Płatki kukurydziane Kellogg’s 275g",
                "2. Ciasteczka owsiane z kawałkami czekolady Cowhead 178g",
                "3. Farmhouse Świeże Mleko UHT 1L",
                "4. Shoon Fatt Cream Krakersy Special 400g",
                "5. Batony musli Yogood Wildberry 138g",
                "6. Smakuj Azjatycka Owsianka Wegetariańska 400g",
                "7. Minisy owocowe St. Dalfour 3x 28g",
                "8. Woda kokosowa Coco Life 2 x 330ml",
                "9. Nutrione Pieczone Orzechy Koktajlowe 85g",
                "10. Kompozycja kwiatowa",
                "11. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 242,
        "name": "WHP niezbędne dla zdrowia",
        "handle": "desiree-wellness-hamper",
        "description": "Health Essentials Health Essentials jest właśnie tym, do czego został zaprojektowany – zdrowym asortymentem popularnych i zdrowych przekąsek! Ten wykwintny kosz upominkowy jest wypełniony smakołykami niezbędnymi do zjedzenia zdrowej przekąski! Powiedz, że wkrótce wrócisz do zdrowia dzięki temu pakietowi niezbędnych przekąsek już dziś! Bezpłatna dostawa tego samego dnia na terenie Polski jest dostępna dla zamówień złożonych przed godziną 15:00.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie",
            "Kosze z jedzeniem halal"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/The-Health-Essentials1-604425.jpg?v=1690594335",
            "/images/products/Desiree-WHP2a-747660.jpg?v=1690504787"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Amazin’ Graze Przeciwutleniacz Mieszanka Orzechów 130g",
                "2. Przepis Babci – Ciasteczka owsiane z żurawiną 130g",
                "3. Cowhead Original Plain Cracker 180g (8 saszetek)",
                "4. Milo Puszka 400g",
                "5. Amazin’ Graze Chocolate Banana Goodness Bowl Natychmiastowe płatki owsiane 6 x 40g",
                "6. Esencja Jagodowa Fomec 2 x 42ml",
                "7. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 243,
        "name": "Kolorowa świeżość FHP",
        "handle": "jean-fruit-hamper",
        "description": "Kolorowa świeżośćTen kosz owoców emanuje atmosferą miękkiej, delikatnej hojności dzięki zachwycającym odcieniom zarówno owoców, jak i bukietu uroczych kwiatów, które zawiera.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Colourful-Freshness-FHP1.jpg?v=1708663700",
            "/images/products/Colourful-Freshness-FHP2.jpg?v=1708663700"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Smocze owoce",
                "2. Fioletowe winogrona",
                "3. Gruszka Nam Shui",
                "4. Kiwi",
                "5. Jabłko Fuji",
                "6. Zielone jabłko",
                "7. Pomarańczowy",
                "8. Mango",
                "9. Gerbera",
                "10. Róże",
                "11. Eustoma",
                "12. Marmur chryzantemowy",
                "13. Liść monety",
                "14. Kosz biały bez rączki"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 244,
        "name": "Demetria",
        "handle": "demetria",
        "description": "„Akceptujemy miłość, na jaką według nas zasługujemy”. – Stephen Chbosky",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Demetria-1a.jpg?v=1700179652",
            "/images/products/Demetria-2a-813222.jpg?v=1700216902"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże ogrodowe. Eustoma. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 245,
        "name": "Dionne",
        "handle": "dionne",
        "description": "Przedstawiamy nasz pełen wdzięku bukiet hortensji i róż, luksusowy zestaw delikatnych różowych hortensji, eleganckich róż i delikatnych sezonowych kwiatów. Bukiet został starannie zaaranżowany z bujną zielenią i owinięty w pastelowy różowy papier, tworząc harmonijną mieszankę faktur i kolorów. Idealny do wyrażania serdecznych uczuć, stanowi wspaniały prezent na urodziny, rocznice lub każdą uroczystość wymagającą odrobiny wyrafinowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Dionne-2a.jpg?v=1724460140",
            "/images/products/Dionne-1a.jpg?v=1724460140"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensje.  Spryskaj róże.  Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 40cm*40cm"
    },
    {
        "id": 246,
        "name": "Delila FBS",
        "handle": "delila",
        "description": "„Życie jest jak moneta. Możesz je wydać na co chcesz, ale wydajesz je tylko raz.” – Lillian Dickson",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-04-06at2.42.20PM_2.jpg?v=1649227386",
            "/images/products/WhatsAppImage2022-04-06at2.42.20PM_1_1.jpg?v=1649227387"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 247,
        "name": "Różowy Zdrowia WHP",
        "handle": "hadleigh-wellness-hamper",
        "description": "Różowy Zdrowia Żyć cały czas w Różowym Zdrowiu to jest to, czego pragniemy. Przyślij nam koszyk szybkiego powrotu do zdrowia, wypełniony przekąskami i żywnością zawierającą składniki odżywcze.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "259.92",
        "inclTax": "259.92",
        "img": [
            "/images/products/HadleighWHP1_55b2272c-7755-4580-a7eb-f4399cdd85de-873060.jpg?v=1671685636",
            "/images/products/HadleighWHP2_4df01900-7a08-41f8-a8cc-235726adf2fc-433537.jpg?v=1671685636"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Spafood Super Cacao Premium Gorąca czekolada lawendowa z luteiną 300g",
                "2. Post Great Grains-Cranberry Almond Crunch 396g",
                "3. DJ",
                "4. Harvest Box Energy Mix Orzechy 45g",
                "5. Harvest Box Super Mix Orzechów i Czekoladek 45g",
                "6. Opakowania na różne kwiaty",
                "7. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 248,
        "name": "Rozkwitnij moje zdrowie kwiatami WHP",
        "handle": "genesis-wellness-hamper",
        "description": "Bloom My Health with Flower Wypełniony po brzegi butelkami toniku Bird’s Nest i kwiatami kosz Bloom My Health with Flower Get Well Soon to ciepły i wesoły prezent, który przywróci blask komuś, kto nie ma dobrej pogody. Pomóż bliskiej osobie przyspieszyć powrót do zdrowia dzięki naszym koszykom wypełnionym produktami poprawiającymi nastrój i zdrowie!  ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Genesis-WHP1-194955.jpg?v=1671684899",
            "/images/products/Genesis-WHP2_4e3db062-65b8-43b2-acec-d1c00fb06f9a-478775.jpg?v=1671684899"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "2. Esencja Kurczaka z Księżyca w Nowiu 3 x 70ml",
                "3. Opakowania na różne kwiaty",
                "4. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*35cm"
    },
    {
        "id": 249,
        "name": "Rylee",
        "handle": "rylee",
        "description": "„Miłość to rozkoszna przerwa między spotkaniem pięknej dziewczyny a odkryciem, że wygląda jak plamiak”. - Johna Barrymore'a",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Rylee-1.jpg?v=1628486581",
            "/images/products/Rylee-2.jpg?v=1641310328"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Zachowana hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 250,
        "name": "Małgorzata",
        "handle": "margaret",
        "description": "„Bez wątpienia najlepiej jest kochać mądrze, ale kochać głupio jest lepiej, niż nie móc kochać wcale”. – Williama Makepeace’a Thackeraya",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Margaret-1a.jpg?v=1641321481",
            "/images/products/Margaret-2.jpg?v=1641321474"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Zachowana hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 251,
        "name": "Noemi",
        "handle": "naomi",
        "description": "„Dla świata możesz być jedną osobą, ale dla jednej osoby jesteś całym światem.” – Billa Wilsona",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Naomi-2a-880366.jpg?v=1721396913",
            "/images/products/Naomi-1a-781509.jpg?v=1721396913"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża ogrodowa. Eustoma. Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 252,
        "name": "Elżbieta",
        "handle": "elizabeth",
        "description": "„Najlepsza miłość to taka, która budzi duszę, która sprawia, że ​​sięgamy po więcej, która rozpala ogień w naszych sercach i przynosi spokój umysłom. To właśnie mam nadzieję dać Ci na zawsze”. - Notatnik",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-05-07at1.13.37PM_2.jpg?v=1651900539",
            "/images/products/WhatsAppImage2022-05-07at1.13.37PM_1_1.jpg?v=1651900540"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Goździki Eustoma.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*30cm"
    },
    {
        "id": 253,
        "name": "Beryl",
        "handle": "beryl",
        "description": "Świętuj miłość naszym czarującym bukietem mieszanych róż. Ta kompozycja, zawierająca żywą fuksję i delikatne różowe róże uzupełnione bujną zielenią i delikatnymi białymi jagodami, jest owinięta eleganckim różowym papierem. Idealny do wyrażania podziwu i uczuć przy każdej wyjątkowej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Beryl-1c-219506.jpg?v=1721657474",
            "/images/products/Beryl-1a.jpg?v=1720534484"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Hypericum. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 254,
        "name": "Bethany",
        "handle": "bethany",
        "description": "Ta tętniąca życiem aranżacja wazonów to oszałamiające połączenie promiennych słoneczników i delikatnych różowych róż, pięknie zaakcentowanych bujnymi liśćmi eukaliptusa. Jasnożółte słoneczniki przynoszą ciepło i radość, a delikatne róże dodają elegancji. Idealny do rozjaśnienia każdego pomieszczenia, ten bukiet jest idealnym wyborem do celebrowania radosnych chwil i dodawania blasku słońca do każdego otoczenia.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Bethany-2a-513524.jpg?v=1724687451",
            "/images/products/Bethany-1a-666634.jpg?v=1724687451"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 40cm*50cm"
    },
    {
        "id": 255,
        "name": "Kondolencje (IV)",
        "handle": "condolence-iv-1",
        "description": "Składając szczere kondolencje, nasze kwietniki z czułością przedstawiają czyste białe chryzantemy, pomysłowo połączone z kojącymi zielonymi odcieniami i delikatnymi orchideami. Ten spokojny zespół składa delikatny hołd, symbolizując współczucie i pamięć, zapewniając pocieszenie i komfort w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Condolence-_IV_-1d.jpg?v=1720187925",
            "/images/products/Condolence-Card-2024_1_cc0ee89c-dc7e-4ae2-928d-9ec8e66df2d1.jpg?v=1720187930",
            "/images/products/Condolence-_IV_-2d.jpg?v=1720187925"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Orchidee. Sztuczne Kwiaty. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 256,
        "name": "Empatia (IV)",
        "handle": "empathy-iv-1",
        "description": "W wielkim wyrazie współczucia nasze duże Stojaki z kwiatami kondolencyjnymi prezentują bogatą kompozycję czystych białych chryzantem i delikatnych lilii. Ten bogaty zespół stanowi godny i pocieszający hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "844.11",
        "inclTax": "844.11",
        "img": [
            "/images/products/Empathy-_IV_-2c.jpg?v=1727184810",
            "/images/products/Condolence-Card-2024_1_fd8dff27-c725-4a2b-b1e5-a7602d3537c3.jpg?v=1720191144",
            "/images/products/Empathy-_IV_-1c.jpg?v=1727184805"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Gerbera . Lilia. Sztuczne Kwiaty. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 160cm*180cm"
    },
    {
        "id": 257,
        "name": "Współczucie (ja)",
        "handle": "sympathy-i",
        "description": "W czułym wyrazie współczucia nasze Stojaki z kwiatami kondolencyjnymi przedstawiają nieskazitelnie białe chryzantemy uzupełnione delikatną paletą kolorów eustom i orchidei o tematyce szampana. Ta elegancka aranżacja zapewnia pocieszenie i spokojny hołd, symbolizując współczucie i pamięć w chwilach żałoby.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Sympathy-_I_-1d.jpg?v=1720157523",
            "/images/products/Condolence-Card-2024_1_94846050-b7df-4f53-8a6d-719257cc03c2.jpg?v=1720157529",
            "/images/products/Sympathy-_I_-2d.jpg?v=1720157523"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Eustoma. Orchidee. Mattiola. Sztuczne lilie. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 258,
        "name": "Współczucie (II)",
        "handle": "sympathy-ii",
        "description": "Z wdziękiem składając kondolencje, nasze kwietniki prezentują czyste białe chryzantemy pośród kojącej palety różowych i fioletowych kwiatów. Ta elegancka aranżacja składa czuły hołd, symbolizując współczucie i pamięć, przynosząc pocieszenie i komfort w chwilach smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Sympathy-_II_-1d.jpg?v=1720157700",
            "/images/products/Condolence-Card-2024_1_b2d46ee9-42b0-4e92-813d-1387b086b924.jpg?v=1720157706",
            "/images/products/Sympathy-_II_-2d.jpg?v=1720157700"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Róża. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 259,
        "name": "Melodia",
        "handle": "melody",
        "description": "Rozkoszuj się pięknem romantyzmu dzięki naszemu urzekającemu bukietowi składającemu się z 9 ciemnoczerwonych róż ozdobionych delikatnym różowym i białym dziecięcym oddechem. Ten czarujący zespół to symfonia pasji i czułości, przedstawiona w doskonałej równowadze kolorów. Idealny do wyrażania miłości z okazji rocznic, Walentynek lub innych okazji, które wymagają odrobiny serdecznej elegancji. Rozkoszuj się ponadczasowym urokiem tej oszałamiającej kompozycji kwiatowej.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/WhatsAppImage2022-01-07at2.12.32PM_2.jpg?v=1641536042",
            "/images/products/WhatsAppImage2022-01-07at2.12.32PM_1_1.jpg?v=1641536047"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 260,
        "name": "Zdrowe wibracje z Flower WHP",
        "handle": "vivienne-wellness-hamper",
        "description": "Zdrowe wibracje z kwiatem Ten prezent wellness pomoże ukochanej osobie, która jest na drodze do wyzdrowienia, w odpowiednim czasie odzyskać pełne zdrowie. Od olejku eterycznego po organiczną herbatę, organiczny sok, płatki owsiane i tak dalej, koszyk zaspokaja podstawowe potrzeby chorego.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Vivienne2-592822.jpg?v=1671685348",
            "/images/products/Vivienne1-320909.jpg?v=1671685348"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Spa Foods - Herbata kwiatowa z groszku błękitnego i miodem Manuka 150g",
                "2. Post Great Grains Płatki Żurawinowo-Migdałowe Crunch 396g",
                "3. DJ",
                "4. Ciasteczka maślane Danisa 200g",
                "5. Opakowania na różne kwiaty",
                "6. Wykwintna drewniana skrzynia (M)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*35cm"
    },
    {
        "id": 261,
        "name": "Jadeit",
        "handle": "jade",
        "description": "„Wiedzieć, kiedy odejść, a kiedy podejść bliżej, jest kluczem do każdego trwałego związku”. - Doménico Cieri Estrada",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "1597.17",
        "inclTax": "1597.17",
        "img": [
            "/images/products/Jade-3a-185553.jpg?v=1671685417",
            "/images/products/Jade-1-252223.jpg?v=1671685417",
            "/images/products/Jade-2-246908.jpg?v=1671685417"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "99 Łodyg Tulipanów"
            ]
        },
        "dimention": "Szerokość*Wysokość: 45cm*55cm"
    },
    {
        "id": 262,
        "name": "Taylora",
        "handle": "taylor",
        "description": "Wyraź odważne oświadczenie dzięki naszemu oszałamiającemu bukietowi tulipanów. Ta aranżacja, zawierająca urzekającą mieszankę żywych czerwonych i delikatnych różowych tulipanów, jest akcentowana delikatnym oddechem dziecka i bujną zielenią. Zapakowany w elegancki czarny papier emanuje elegancją i wyrafinowaniem, idealny do wyrażania miłości, podziwu lub świętowania jakiejkolwiek specjalnej okazji z polotem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Taylor-2a.jpg?v=1721215630",
            "/images/products/Taylor-1a.jpg?v=1721215630"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 263,
        "name": "Przeznaczenie",
        "handle": "destiny",
        "description": "Celebruj elegancję dzięki naszemu oszałamiającemu bukietowi inspirowanemu jesienią. Ta kompozycja, zawierająca piękną mieszankę brzoskwini i białych róż, akcentowana żywymi kwiatami pomarańczy, delikatnymi stokrotkami i bujnym eukaliptusem, jest owinięta eleganckim biało-beżowym papierem. Idealny na każdą okazję, wnosi odrobinę ciepła i wyrafinowania do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Destiny-1a.jpg?v=1721272054",
            "/images/products/Destiny-2a.jpg?v=1721272053"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 264,
        "name": "Daleyza",
        "handle": "daleyza",
        "description": "Zanurz się w uroku tajemnicy i zachwytu dzięki naszemu bukietowi 6 hipnotyzujących fioletowych róż, z wdziękiem umieszczonych wśród delikatnych kępek kaspii. Bogate, królewskie odcienie róż w połączeniu z subtelnym urokiem kaspii tworzą harmonijną i wyrafinowaną kompozycję kwiatową.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Daleyza-1a.jpg?v=1720777780",
            "/images/products/Daleyza-2a.jpg?v=1720777780"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Fioletowe Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 265,
        "name": "Paryż",
        "handle": "paris",
        "description": "Ten piękny bukiet składa się z pastelowych róż menta, delikatnych goździków i uroczych tulipanów, akcentowanych bujną zielenią i owiniętych w elegancki brązowy papier. Idealny na każdą wyjątkową okazję, wnosi odrobinę wyrafinowania i uroku do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Paris-2a.jpg?v=1721179579",
            "/images/products/Paris-1a.jpg?v=1721179579"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździk . Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 266,
        "name": "Rebeka",
        "handle": "rebecca",
        "description": "Spraw, aby każda okazja była niezapomniana dzięki naszemu wykwintnemu mieszanemu bukietowi róż. Ta kompozycja, zawierająca harmonijne połączenie czerwonych, różowych i żółtych róż, akcentowana delikatną różową alstroemerią, kwiatami woskowymi i bujną zielenią, jest opakowana w elegancki różowy i biały papier. Idealny do wyrażania miłości, uznania lub świętowania wyjątkowych chwil w życiu, dodaje odrobinę piękna i uroku każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Rebecca-2a.jpg?v=1721271571",
            "/images/products/Rebecca-1a.jpg?v=1721271571"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże.  Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 267,
        "name": "Cud",
        "handle": "miracle",
        "description": "Ten elegancki bukiet stanowi uroczą mieszankę lawendy, różu i białych tulipanów, symbolizujących wdzięk i spokój. Zapakowana w delikatny różowy papier i przewiązana pasującą wstążką kompozycja emanuje delikatnym urokiem. Idealny do wyrażenia podziwu, wdzięczności lub po prostu wniesienia odrobiny wiosennego piękna do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Miracle-2a-794283.jpg?v=1722358166",
            "/images/products/Miracle-1a.jpg?v=1722297796"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "30 tulipanów (15 fioletowych, 10 różowych, 5 białych)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 36cm*42cm"
    },
    {
        "id": 268,
        "name": "Nayeli",
        "handle": "nayeli",
        "description": "Ten bukiet to wykwintny zestaw kremowych białych róż i goździków przeplatanych bujną zielenią i żywymi kwiatami, a wszystko to otoczone miękkim, różowawym papierem. To przejaw naturalnego wdzięku i delikatnego uroku, idealny na każdą okazję, która wymaga odrobiny delikatności i elegancji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nayeli-2b.jpg?v=1720890553",
            "/images/products/Nayeli-1b.jpg?v=1720890552"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 269,
        "name": "Janessa FBS",
        "handle": "janessa",
        "description": "Ta elegancka aranżacja składa się z żywych goździków, delikatnych miniróży i uroczej chryzantemy, uzupełnionych bujną zielenią i wypełniaczami. Zapakowana w elegancką brązową torebkę z fioletową wstążką, stanowi idealne połączenie piękna i wyrafinowania na każdą okazję.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Janessa-FBS-1a-599880.jpg?v=1720117283",
            "/images/products/Janessa-FBS-2a-814473.jpg?v=1720117283"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Spryskaj różę. Goździki. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 270,
        "name": "Emmarie",
        "handle": "emmarie",
        "description": "„Kocha się nie dzięki znalezieniu idealnej osoby, ale dzięki doskonałemu widzeniu osoby niedoskonałej.” – Sam Keen",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Emmarie-1.jpg?v=1628486590",
            "/images/products/Emmarie-2.jpg?v=1641324000"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże ogrodowe. Eustoma. Mattiola. Alstremeria"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*36cm"
    },
    {
        "id": 271,
        "name": "Giavanna",
        "handle": "giavanna",
        "description": "Ten piękny bukiet składa się z harmonijnej mieszanki białych róż, delikatnych niebieskich tulipanów, delikatnych białych pomponów i małych niebieskich kwiatów. Kompozycja jest elegancko owinięta w jasnoszary papier i przewiązana pasującą wstążką, tworząc wyrafinowaną i uspokajającą estetykę, idealną na każdą specjalną okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Giavanna-2a.jpg?v=1721177193",
            "/images/products/Giavanna-1a.jpg?v=1721177193"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Mamo. Eustomy . Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 272,
        "name": "Walery",
        "handle": "valery",
        "description": "Wzbudź delikatne uczucia naszym bukietem „Pastel Serenity”. Trzy jasnoróżowe róże w połączeniu z eustomą i chryzantemą ucieleśniają wdzięk i uznanie. Delikatnie zaprezentowana w pastelowym zielonym opakowaniu, ta aranżacja emanuje spokojem i elegancją. Idealny do przekazania szczerych emocji przy każdej okazji, to gest piękna i szczerości.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Valery-1e.jpg?v=1720662664",
            "/images/products/Valery-1d.jpg?v=1720525481"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Mamo. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 273,
        "name": "Esperanza",
        "handle": "esperanza",
        "description": "Ta zachwycająca kompozycja wazonowa składa się z uroczego grona delikatnych stokrotek otoczonych bujną zielenią i delikatnymi liśćmi. Wesołe, białe i żółte kwiaty emanują świeżym i żywym urokiem, dzięki czemu ten bukiet jest idealny do wniesienia odrobiny natury do wnętrz. Niezależnie od tego, czy jest to przemyślany prezent, czy urocza dekoracja, wnosi powiew świeżości i prostoty do każdego wnętrza.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Esperanza-2a-534762.jpg?v=1724687723",
            "/images/products/Esperanza-aa-435668.jpg?v=1724687723"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Stokrotka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 274,
        "name": "Krystyna",
        "handle": "christine",
        "description": "Zachwyć się elegancją naszego bukietu tulipanów. Ta kompozycja, zawierająca mieszankę różowych i białych tulipanów uzupełnionych delikatnymi stokrotkami i bujną zielenią, jest owinięta delikatnym różowym papierem. Idealny do dodania odrobiny wiosny na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Christine-1b.jpg?v=1720661274",
            "/images/products/Christine-1a.jpg?v=1720533067"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 275,
        "name": "Natalia",
        "handle": "nataly",
        "description": "Ten elegancki bukiet łączy w sobie róże cappuccino z wyrazistymi białymi kwiatami i odrobiną zieleni, a wszystko to pięknie zamknięte w delikatnym różowym opakowaniu. Luksusowy gest, idealny do przekazania ciepła i uczuć przy specjalnych okazjach lub po prostu rozjaśnienia czyjegoś dnia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nataly-2c-265793.jpg?v=1720962612",
            "/images/products/Nataly-1c-138531.jpg?v=1720962612"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Astylbe. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 276,
        "name": "Brynleigh",
        "handle": "brynleigh",
        "description": "Uroczy bukiet pastelowych kwiatów z oddechem dziecka, delikatnie ułożonych i ozdobionych uroczą dekoracją króliczka. Ta urocza kompozycja, owinięta w elegancki biały papier z różowymi wstążkami, idealnie nadaje się na dodanie fantazyjnego akcentu każdej specjalnej okazji lub uroczystości.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/lollipop-2a_38f7090c-5b8b-4a03-8d86-cde3e57a9a06.jpg?v=1721011638",
            "/images/products/Brynleigh-1a_8aa848f5-5a04-4e27-8f86-2daaeb793585-571953.jpg?v=1721048922"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka. Milczący"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 277,
        "name": "Gabriela",
        "handle": "gabriella",
        "description": "„Tęsknienie za tobą to moje hobby, troska o ciebie to moja praca, uszczęśliwianie cię to mój obowiązek, a kochanie cię to moje życie”. Anonimowy",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Gabriella-1.jpg?v=1641319572",
            "/images/products/Gabriella-2.jpg?v=1641319566"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "20 łodyg róż kenijskich"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*35cm"
    },
    {
        "id": 278,
        "name": "Melanie FBS",
        "handle": "melanie",
        "description": "„Miłość to coś więcej niż rzeczownik – to czasownik; to coś więcej niż uczucie – to troska, dzielenie się, pomaganie i poświęcenie”. – Williama Arthura Warda",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-01-23at8.41.41AM_2.jpg?v=1642908775",
            "/images/products/WhatsAppImage2022-01-23at8.41.41AM_1_1.jpg?v=1642908775"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 279,
        "name": "Vivian FBS",
        "handle": "vivian",
        "description": "Przedstawiamy nasz wykwintny bukiet z delikatną zakonserwowaną różą w kolorze tiffany blue, otoczony bujną aranżacją białych hortensji, oddechem dziecka i wyjątkowymi akcentami suszonych kwiatów. Elegancko zaprezentowana w eleganckim pudełku z podwójnymi czarnymi obręczami, ta aranżacja jest idealna na każdą specjalną okazję, dodając Twojej uroczystości odrobinę elegancji i uroku.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Vivian-FBS---2c.jpg?v=1721660848",
            "/images/products/Vivian-FBS---1b.jpg?v=1721660847"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 280,
        "name": "Iris FBS",
        "handle": "iris",
        "description": "„Jeśli miłość jest powszechna, nikogo nie można pominąć”. - Deepak Chopra",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Iglet-1.1.jpg?v=1628486594",
            "/images/products/iglet-2-min_2.jpg?v=1641318616"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 281,
        "name": "Kimberly FBS",
        "handle": "kimberly",
        "description": "„Miłość nie sprawia, że ​​świat się kręci, ale z pewnością sprawia, że ​​podróż jest tego warta”. -Mae West",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Kimberly-1-860518.jpg?v=1671685468",
            "/images/products/Kimberly-2.jpg?v=1641314508"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbery . Ferrero Rochers. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 282,
        "name": "Jocelyn",
        "handle": "jocelyn",
        "description": "„Prawdziwa miłość to nie zabawa w chowanego; w prawdziwej miłości oboje kochankowie szukają się nawzajem”. - Michaela Basseya Johnsona",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jocelyn-1.jpg?v=1641315732",
            "/images/products/Jocelyn-2.jpg?v=1628486595"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Zachowana hortensja. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 12cm*12cm"
    },
    {
        "id": 283,
        "name": "Cecylia",
        "handle": "cecilia",
        "description": "Ten uroczy bukiet przedstawia żywe różowe tulipany i bujną zieleń, owinięty w elegancki brzoskwiniowy papier. Idealny do wyrażenia miłości i uznania, dodaje odrobiny piękna i wdzięku każdej uroczystości.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Cecilia-2a.jpg?v=1721179939",
            "/images/products/Cecilia-1a.jpg?v=1721179939"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 284,
        "name": "Fiona",
        "handle": "fiona",
        "description": "Ten bukiet zawiera delikatne połączenie różowych róż, białych tulipanów i oddechu dziecka, pięknie ułożone z bujnymi zielonymi liśćmi. Kwiaty są elegancko owinięte w półprzezroczysty biały papier i przewiązane pasującą białą wstążką, tworząc wyrafinowaną i spokojną prezentację.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Fiona-2a.jpg?v=1721140221",
            "/images/products/Fiona-1a.jpg?v=1721140221"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Spryskaj róże. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 285,
        "name": "Współczucie (III)",
        "handle": "sympathy-iii",
        "description": "Składając szczere kondolencje, nasze kwietniki ozdobione są czystymi białymi chryzantemami ozdobionymi kojącą aranżacją z motywem zieleni. Ten spokojny zespół składa delikatny hołd, symbolizując współczucie i pamięć, zapewniając pocieszenie i komfort w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Sympathy-_III_-1d.jpg?v=1720187503",
            "/images/products/Condolence-Card-2024_1_c12781ec-2b59-48ae-b732-b76a38bbb2b8.jpg?v=1720187532",
            "/images/products/Sympathy-_III_-2d.jpg?v=1720187503"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 286,
        "name": "Współczucie (IV)",
        "handle": "sympathy-iv",
        "description": "Przekaż szczere kondolencje dzięki naszym eleganckim Stojakom z kwiatami kondolencyjnymi, ozdobionym sztucznymi różowymi liliami, czystymi białymi chryzantemami i królewskimi fioletowymi orchideami. Ta serdeczna aranżacja stanowi delikatny wyraz współczucia, okazując zmarłym łaskę i współczucie w chwilach żałoby.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Sympathy-_IV_-1d.jpg?v=1720145052",
            "/images/products/Condolence-Card-2024_1_ccaa1b84-57d9-4a39-a17f-0c981fddd296.jpg?v=1720145058",
            "/images/products/Sympathy-_IV_-2d.jpg?v=1720145052"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Orchidee. Sztuczne lilie. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 287,
        "name": "Litość",
        "handle": "ruth",
        "description": "„Nigdy nie możemy stracić tego, czym się kiedyś cieszyliśmy. Wszystko, co kochamy głęboko, staje się częścią nas”. - Helena Keller",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ruth-1.jpg?v=1641310428",
            "/images/products/Ruth-2.jpg?v=1641310338"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 288,
        "name": "Elsie",
        "handle": "elsie",
        "description": "„Rozpowszechniaj miłość, gdziekolwiek się udasz. Niech nikt nigdy nie przyjdzie do ciebie, nie wychodząc szczęśliwszy”. - Matka Teresa",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Elsie-1.jpg?v=1641333960",
            "/images/products/Elsie-2.jpg?v=1641333954"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 289,
        "name": "Dobrobyt (II)",
        "handle": "prosperity-ii",
        "description": "Poczuj sukces dzięki naszemu stojakowi na wielkie otwarcie Crimson Elegance. Żywe czerwone gerbery i ciemnofioletowe chryzantemy łączą się w oszałamiającej aranżacji, promieniującej energią i wyrafinowaniem. Kwiatowe arcydzieło, które w każdym płatku odzwierciedla wielkość Twojego wyjątkowego dnia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Prosperity-_II_-1g.jpg?v=1720406333",
            "/images/products/Grand-Opening-Card-2024_f872b97b-2e74-445b-996b-41cf76b3ae8c.jpg?v=1720406345",
            "/images/products/Prosperity-_II_-2g.jpg?v=1720406333"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Wierzby. Cyperusowy kij. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 290,
        "name": "Dobrobyt (III)",
        "handle": "prosperity-iii",
        "description": "Żywe arcydzieło, które opowiada historię świętowania i sukcesu. Niezależnie od tego, czy zdobi wejście, czy zdobi środkową scenę, nasz uroczysty stojak na kwiaty obiecuje oczarować wszystkich, którzy będą świadkami jego piękna, pozostawiając trwałe wrażenie, które odzwierciedla wielkość okazji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Prosperity-_III_-1e.jpg?v=1720405319",
            "/images/products/Grand-Opening-Card-2024_58646203-b46f-470f-bba8-e444b11266a3.jpg?v=1720405326",
            "/images/products/Prosperity-_III_-2e.jpg?v=1720405319"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Sztuczne słoneczniki. Goździki. Cyperusowy kij. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 291,
        "name": "Gracelynn",
        "handle": "gracelynn",
        "description": "Przedstawiamy „Gracelynn” – pięknie wykonaną kompozycję kwiatową firmy Flowers Poland. Ten uroczy kosz zawiera żywą mieszankę kwiatów czerwieni, różu i brzoskwini, w tym róż i orchidei, uzupełnioną bujną zielenią. Idealna do rozjaśnienia każdej przestrzeni, Gracelynn została zaprojektowana, aby wnieść radość i elegancję do Twojego dnia.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Gracelynn-1-328879.jpg?v=1671685313",
            "/images/products/Graelyn-1b_2-347103.jpg?v=1715806130",
            "/images/products/Gracelynn-2-518422.jpg?v=1715783664"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Eustoma. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 292,
        "name": "Weronika",
        "handle": "veronica",
        "description": "„Piękno kobiety trzeba widzieć w jej oczach, bo to są drzwi do jej serca, miejsca, w którym mieszka miłość”. -Audrey Hepburn",
        "type": "Flower Baskets",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-03-31at9.49.48AM_2.jpg?v=1648693762",
            "/images/products/WhatsAppImage2022-03-31at9.49.48AM_1_1.jpg?v=1648693762"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Eustoma. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 293,
        "name": "Keira",
        "handle": "keira",
        "description": "Zanurz się w ponadczasowym pięknie naszego wykwintnego bukietu, łączącego delikatne niebieskie hortensje, delikatne różowe róże, nieskazitelnie białe eustomy i zwiewny oddech dziecka. Promieniująca elegancją i urokiem, ta aranżacja jest idealna na wesela, rocznice lub inne specjalne okazje, dodając odrobinę romantyzmu i wyrafinowania każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Keira-2a.jpg?v=1721211439",
            "/images/products/Keira-1a.jpg?v=1721211439"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Eustoma. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 294,
        "name": "Ciara",
        "handle": "ciara",
        "description": "Ta urocza aranżacja wazonów składa się z delikatnej mieszanki miękkich białych róż, pastelowych kwiatów i wyjątkowych akcentów, takich jak bawełna i osty. Uzupełniony bujną zielenią i srebrzystymi liśćmi, ten bukiet wnosi odrobinę fantazji i elegancji do każdego otoczenia. Idealny do celebrowania wyjątkowych chwil, oferuje spokojną i pełną wdzięku atmosferę, która oczaruje każdego, kto go otrzyma.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Ciara-2a-833777.jpg?v=1724687593",
            "/images/products/Ciara-1a-575790.jpg?v=1724687593"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj róże. Gossypium. Eustomy . Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 16cm*28cm"
    },
    {
        "id": 295,
        "name": "Megan",
        "handle": "megan",
        "description": "Poczuj urok naszego zachwycającego bukietu składającego się z 6 uroczych różowych róż pomysłowo ułożonych w rustykalnym rattanowym koszu. Miękkie, różowe odcienie róż uzupełniają naturalną fakturę kosza, tworząc słodki i malowniczy zestaw. Idealny do wyrażenia uczuć podczas urodzin, uroczystości lub jako przemyślany gest, ten bukiet emanuje ciepłem i prostotą. Obejrzyj piękno natury dzięki tej czarującej kompozycji kwiatowej.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Megan-1-849258.jpg?v=1671685491",
            "/images/products/Megan-2-382088.jpg?v=1671685491"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 296,
        "name": "Lauren",
        "handle": "lauren",
        "description": "Ten elegancki bukiet składa się z kremowych białych róż i żywych pomarańczowych róż w sprayu, akcentowanych delikatnym dziecięcym oddechem i bujną zielenią. Zapakowany w miękki brzoskwiniowy papier i przewiązany pasującą wstążką, będzie idealnym prezentem na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Lauren-2b.jpg?v=1720709100",
            "/images/products/Lauren-1b.jpg?v=1720709101"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 297,
        "name": "Nancy",
        "handle": "nancy",
        "description": "Podnieś swój wyjątkowy dzień dzięki naszemu czarującemu bukietowi, delikatnej symfonii różowego i białego gossypium oraz miękkiemu urokowi różowego oddechu dziecka. Ta pomysłowo wykonana aranżacja emanuje romantyzmem i czystością, tworząc doskonały wyraz miłości. Podziel się pięknem tej pory roku dzięki temu eterycznemu i delikatnemu zestawowi kwiatowemu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Nancy-1cc_24a35d00-1c69-449e-b717-a8e05a558e07-677728.jpg?v=1704983144",
            "/images/products/Nancy-2cc_1f9277bc-df3a-4afe-b2df-309735bdbe66-536699.jpg?v=1704983144"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Bawełniane Kwiaty. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 298,
        "name": "Aubriel",
        "handle": "aubrielle",
        "description": "Wzbogać swoje doznania związane z prezentami dzięki harmonijnemu połączeniu konserwowanych kwiatów i rozkosznego szampana w urzekającej mieszance gorących odcieni różu i fioletu. Ta wykwintna aranżacja, starannie wykonana, aby wytrzymać próbę czasu, emanuje wiecznym pięknem. W towarzystwie 20-cl butelki szampana MOET jest to doskonały, wyrafinowany prezent, pozwalający celebrować chwile, które pozostają na zawsze w pamięci i rozkwitają.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Aubrielle-1cc-498046.jpg?v=1705588160",
            "/images/products/Aubrielle-2cc-615059.jpg?v=1705588160"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*20cm"
    },
    {
        "id": 299,
        "name": "Joyce'a",
        "handle": "joyce",
        "description": "Obejmij romans z naszym czarującym bukietem 12 różowych róż Delikatnie ułożony w zestaw w kształcie stożka, nasz bukiet 12 różowych róż jest idealny na rocznice, urodziny i specjalne okazje. Ten elegancki kwiatowy wyraz wyraża miłość i czułość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Joyce-1h-672379.jpg?v=1720789288",
            "/images/products/Joyce-1g-300698.jpg?v=1720843130"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 300,
        "name": "Luella",
        "handle": "luella",
        "description": "„Kocham cię” zaczyna się od „ja”, a kończy przez ciebie” – Charles de Leusse",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Luella-1-133715.jpg?v=1671685471",
            "/images/products/Luella-2-687327.jpg?v=1671685471"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Lilie . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 301,
        "name": "Dobrobyt (IV)",
        "handle": "prosperity-iv",
        "description": "Rozświetl wszystkie starty biznesowe dzięki naszemu fioletowemu stojakowi z motywem kwiatowym Elegant Bloom. Wieży fioletowych kapustnych towarzyszą żywe różowe gerbery, delikatne eustomy, egzotyczne fioletowe orchidee i odważna obecność czerwonych goździków, różowych lilii, tworząc bogaty i elegancki element centralny.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Prosperity-_IV_-1h.jpg?v=1720493386",
            "/images/products/Grand-Opening-Card-2024_16ba4503-b04a-4039-a69e-569bd34aecd9.jpg?v=1720495255",
            "/images/products/Prosperity-_IV_-2h.jpg?v=1720495394"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Gerbera . Eustoma. Goździk . Orchidee. Anturium . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 302,
        "name": "Raquel",
        "handle": "raquel",
        "description": "Zanurz się w ciepłym i pocieszającym pięknie naszego bukietu składającego się z 9 wykwintnych róż cappuccino akcentowanych aromatycznym akcentem eukaliptusa. Subtelne, ziemiste odcienie róż przywołują bogactwo filiżanki cappuccino, a eukaliptus dodaje odświeżającego i eleganckiego akcentu. Idealny, aby wyrazić podziw lub wprowadzić przytulną atmosferę na każdą okazję, ten bukiet to wyrafinowane połączenie piękna i zapachu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Raquel-2a.jpg?v=1721268694",
            "/images/products/Raquel-1a.jpg?v=1721268694"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże capuccino. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 303,
        "name": "Debora",
        "handle": "deborah",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Deborah-1.jpg?v=1641335397",
            "/images/products/Deborah-2-887787.jpg?v=1671685198"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 304,
        "name": "Pyszny Detoks FHP",
        "handle": "casey-fruit-hamper",
        "description": "Pyszny detoks",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Casey1-899608.jpg?v=1671685078",
            "/images/products/Casey2-620543.jpg?v=1671685078"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Smocze owoce",
                "2. Zielone jabłko",
                "3. Zielone winogrona",
                "4. Mango",
                "5. Gruszka Forelle",
                "6. Gruszka Nam Shui",
                "7. Kiwi",
                "8. Jabłko Fuji",
                "9. Pomarańczowy",
                "10. Roślina kwiatowa"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 305,
        "name": "Emani",
        "handle": "emani",
        "description": "Uchwyć delikatne piękno naszego pastelowego bukietu tulipanów. Ta kompozycja, ozdobiona delikatnymi różowymi tulipanami z akcentami uroczych stokrotek i bujnego eukaliptusa, jest owinięta w opalizujący różowy papier i przewiązana satynową wstążką. Idealny do wyrażania miłości, wdzięczności lub świętowania jakiejkolwiek specjalnej okazji, wnosi odrobinę elegancji i spokoju do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Emani-2a.jpg?v=1721314236",
            "/images/products/Emani-1a.jpg?v=1721314236"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany.  Stokrotka. Listowie"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 306,
        "name": "Lory FBS",
        "handle": "lory",
        "description": "„Życie nie polega na odnalezieniu siebie. Życie polega na tworzeniu siebie.”  – George’a Bernarda Shawa",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-09-14at3.33.06PM_2.jpg?v=1641322550",
            "/images/products/WhatsAppImage2021-09-15at10.23.37AM_1.jpg?v=1641322458"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 307,
        "name": "Lainey FBS",
        "handle": "lainey",
        "description": "„Największym szczęściem w życiu jest przekonanie, że jesteśmy kochani; kochani ze względu na nas samych, a raczej kochani pomimo nas samych”. - Wiktor Hugo",
        "type": "Seria Torebek",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-09-16at11.00.12AM_2.jpg?v=1641314274",
            "/images/products/WhatsAppImage2021-09-16at11.00.12AM_1_1.jpg?v=1641314268"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 308,
        "name": "Colette",
        "handle": "colette",
        "description": "Przywołaj czystość i niewinność noworodka dzięki naszemu Bukietowi Oddechu Białego Dziecka. Ten uroczy bukiet, delikatnie wykonany z nieskazitelnie białych kwiatów o oddechu dziecka, ucieleśnia wdzięk i delikatność. Każda łodyga oddechu dziecka jest starannie ułożona, aby stworzyć wizję eterycznego piękna, idealną do celebrowania cennych chwil w życiu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Colette-1b.jpg?v=1720528153",
            "/images/products/Colette-2a.jpg?v=1720528086"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 309,
        "name": "Josie",
        "handle": "josie",
        "description": "Luksusowy bukiet delikatnych różowych róż, starannie ułożony wśród delikatnej aureoli oddechu dziecka, zamknięty w eleganckim opakowaniu z kaskadą różowych wstążek, które szepczą słodkie uczucia miłości i uczucia. Idealnie nadaje się na prezent od serca lub romantyczną okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Josie-1b-896010.jpg?v=1713490244",
            "/images/products/Josie-2b-987124.jpg?v=1713490244"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "40 róż. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 45cm*45cm"
    },
    {
        "id": 310,
        "name": "Współczucie (V)",
        "handle": "sympathy-v",
        "description": "Wyraź szczere kondolencje za pomocą naszych stojaków na kwiaty, zawierających pogodne białe chryzantemy i ciemnoczerwone róże misternie ułożone w kształcie serca. Ten elegancki hołd jest symbolem miłości i pamięci, oferującym pocieszenie i współczucie w chwilach smutku i straty.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Sympathy-_V_-1d.jpg?v=1720155834",
            "/images/products/Condolence-Card-2024_1_26283ad4-0690-450f-a50d-63bc45993d24.jpg?v=1720155848",
            "/images/products/Sympathy-_V_-2d.jpg?v=1720155835"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Mamy. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 100cm*180cm"
    },
    {
        "id": 311,
        "name": "Bailey'a",
        "handle": "bailey",
        "description": "Ten wykwintny bukiet składa się z eleganckich różowych końcówek akcentowanych gałązkami lawendy i bujnymi, srebrzystymi liśćmi. Zapakowany w miękki beżowy papier i przewiązany pasującą wstążką, to idealny prezent, który wyrazi wyrafinowanie i wdzięk.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Bailey-2a.jpg?v=1721180451",
            "/images/products/Bailey-1a.jpg?v=1721180451"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 312,
        "name": "Dobrobyt (V)",
        "handle": "prosperity-v",
        "description": "Zanurz swój wielki biznes w blasku Purple Orchid Radiance dzięki naszemu fioletowemu stojakowi z motywem kwiatowym. Wysokie fioletowe kapustne, wesołe gerbery, delikatne fioletowe eustomy i żywe fioletowe orchidee są podkreślone egzotyczną obecnością czerwonych kwiatów gerbery. Cała aranżacja jest elegancko owinięta falistą listwą z czerwonego aksamitu, tworząc oszałamiającą wizualnie i niepowtarzalną ekspozycję.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Prosperity-_V_-1d.jpg?v=1720408133",
            "/images/products/Grand-Opening-Card-2024_7e15ba60-3334-4e2a-aed4-cb39846fb5c5.jpg?v=1720408143",
            "/images/products/Prosperity-_V_-2d.jpg?v=1720408132"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Gerbera . Eustoma. Sztuczne Kwiaty. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 313,
        "name": "Natalia FBS",
        "handle": "natala",
        "description": "„Żyje się tylko raz, ale jeśli zrobisz to dobrze, raz wystarczy.” -Mae West",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-01-09at10.16.18AM_1.jpg?v=1641694675",
            "/images/products/WhatsAppImage2022-01-09at10.16.44AM_1.jpg?v=1641694669"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 314,
        "name": "Betlejem",
        "handle": "bethlehem",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Bethlelem-1.jpg?v=1641354001",
            "/images/products/Bethlelem-2.jpg?v=1628486613"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 315,
        "name": "Zuzanna",
        "handle": "susan",
        "description": "„Kocham cię” zaczyna się od „ja”, a kończy przez ciebie” – Charles de Leusse",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Susan-1.jpg?v=1628486615",
            "/images/products/Susan-2.jpg?v=1628486615"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Mamo. Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 316,
        "name": "Współczucie (VI)",
        "handle": "sympathy-vi",
        "description": "W ramach pogodnego hołdu kondolencyjnego nasze kwietniki prezentują czyste białe i zielone chryzantemy w towarzystwie delikatnych róż i eustom. Ta elegancka aranżacja zapewnia ukojenie i komfort, symbolizując współczucie i pamięć w chwilach smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Sympathy-_VI_-1c.jpg?v=1720181846",
            "/images/products/Condolence-Card-2024_1_550ab6d1-579b-4b0b-9e54-1bdd9a68d216.jpg?v=1720181863",
            "/images/products/Sympathy-_VI_-2c.jpg?v=1720181847"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Lilie . Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 317,
        "name": "Komfort (ja)",
        "handle": "comfort-i",
        "description": "W chwilach współczucia w naszych Stojakach z kwiatami kondolencyjnymi znajdziesz promienne pomarańczowe orchidee, delikatne białe lilie oraz harmonijną mieszankę białych i zielonych chryzantem. Ta elegancka aranżacja zapewnia pocieszenie i żywy hołd, symbolizując współczucie i pamięć w chwilach żałoby.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Comfort-_I_-1d.jpg?v=1720156766",
            "/images/products/Condolence-Card-2024_1_731dd51e-30a9-42a3-a0fb-157ab84ec6ad.jpg?v=1720156774",
            "/images/products/Comfort-_I_-2d.jpg?v=1720156766"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidee. Lilie . Eustomy . Mamo. Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 318,
        "name": "Bogactwo (ja)",
        "handle": "wealth-i",
        "description": "Wznieś na wyższy poziom wielkie otwarcie dzięki naszemu zespołowi Colourful Blossom. Ten oszałamiający stojak na kwiaty, pełen żywej gamy odcieni, symbolizuje radość, różnorodność i kwitnący początek wszystkich przedsięwzięć biznesowych. Luksusowe orchidee, ozdobione złotymi odcieniami, tworzą wizualnie bogatą ekspozycję, obiecując sukces i dobrobyt dla Twojego kwitnącego przedsięwzięcia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/Wealth-_I_-1d.jpg?v=1720524036",
            "/images/products/Grand-Opening-Card-2024_5ed8612e-db82-4f3e-8054-a62564372369.jpg?v=1720524041",
            "/images/products/Wealth-_I_-2d.jpg?v=1720524036"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Gerbera . Anturium . Ożywić . Lilie . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 319,
        "name": "Ślubne V",
        "handle": "bridal-v",
        "description": "Do tej pięknej aranżacji ślubnej dołączony będzie wazon =)",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na wesele",
            "Kwiaty i bukiety na zaręczyny"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Bridal-_V_-1.jpg?v=1641337442",
            "/images/products/Bridal-_V_-2.jpg?v=1641337434"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie kalii. Astromeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 320,
        "name": "Fileo",
        "handle": "phileo",
        "description": "Zanurz się w morzu namiętności z naszym zapierającym dech w piersiach bukietem 22 promiennych czerwonych róż. Każdy kwiat jest świadectwem intensywności i piękna miłości, starannie zaaranżowany tak, aby stworzyć oszałamiający efekt wizualny. Idealny do wyrażenia głębokich emocji podczas rocznic, romantycznych okazji lub jako wielki gest, ten bukiet oddaje esencję ponadczasowej miłości w każdym aksamitnym płatku.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Phileo-2a-901209.jpg?v=1721310185",
            "/images/products/Phileo-1a-170950.jpg?v=1721310185"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "22 róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 321,
        "name": "Jill",
        "handle": "jill-1",
        "description": "Podnieś esencję miłości dzięki naszemu czarującemu bukietowi składającemu się z dziewięciu namiętnych czerwonych róż, delikatnie otoczonych delikatną gracją oddechu dziecka. Ta aranżacja to symfonia romantyzmu, idealna do wyrażenia głębokiego uczucia i podziwu. Niech piękno tych kwiatów przekaże Twoje najgłębsze emocje podczas każdej wyjątkowej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Jill-2b.jpg?v=1721360496",
            "/images/products/Jill-1b.jpg?v=1721360496"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 róż. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 322,
        "name": "Lorelei",
        "handle": "lorelei",
        "description": "Podnieś romantyzm dzięki naszemu czarującemu bukietowi składającemu się z 18 ciemnoczerwonych róż delikatnie akcentowanych eterycznym pięknem oddechu dziecka. Każda aksamitna róża emanuje pasją, a delikatne białe kwiaty dodają odrobiny czystości i wdzięku. Idealny do wyrażenia miłości i podziwu z okazji rocznic, specjalnych okazji lub jako szczery gest, ten bukiet jest urzekającym symbolem trwałego uczucia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-01-07at2.37.35PM_2.jpg?v=1641537606",
            "/images/products/WhatsAppImage2022-01-07at2.37.35PM_1_1.jpg?v=1641537601"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*40cm"
    },
    {
        "id": 323,
        "name": "Wygórowanie",
        "handle": "highness",
        "description": "Zanurz się w delikatnych odcieniach romantyzmu dzięki naszemu zachwycającemu bukietowi składającemu się z dziewięciu uroczych różowych róż, wdzięcznie otoczonych delikatnymi gronami kaspii. Ta urzekająca aranżacja oddaje istotę miłości i czułości, dzięki czemu jest idealnym prezentem do wyrażania podziwu i uczuć. Rozkoszuj się pięknem tych kwiatów, które dodadzą elegancji Twoim wyjątkowym chwilom.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Highness-1aa.jpg?v=1704954711",
            "/images/products/Highness---Delivery-_1_1-393673.jpg?v=1715632102",
            "/images/products/Highness-2aa.jpg?v=1715577642"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Listowie"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 324,
        "name": "Kamila",
        "handle": "camila",
        "description": "Zanurz się w urzekającym uroku naszego bukietu składającego się z 17 promiennych czerwonych róż. Każdy kwiat jest wyznaniem miłości, starannie zaaranżowanym tak, aby stworzyć oszałamiający wizualnie zespół. Idealny do wyrażenia pasji z okazji rocznic, romantycznych okazji lub jako ponadczasowy gest, ten bukiet przemawia bogatymi czerwonymi odcieniami i elegancką prostotą.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Camila-2a-744736.jpg?v=1721396416",
            "/images/products/Camila-1a-102491.jpg?v=1721396416"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "17 róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 325,
        "name": "Pragnienie",
        "handle": "desire",
        "description": "Ten oszałamiający bukiet czerwonych róż, zaakcentowany delikatną zielenią i owinięty w delikatny różowy papier, emanuje elegancją i romantyzmem. Idealny do wyrażenia miłości i podziwu, stanowi ponadczasowy prezent na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Desire-2a.jpg?v=1721182774",
            "/images/products/Desire-1a.jpg?v=1721182774"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 róż. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 326,
        "name": "Zosia",
        "handle": "zoe",
        "description": "„Na próżno się zmagałem. To nie wystarczy. Moje uczucia nie zostaną stłumione. Musisz pozwolić mi powiedzieć ci, jak gorąco cię podziwiam i kocham. -  Jane Austen",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Zoe-1a-304939.jpg?v=1671776017",
            "/images/products/Zoe-2a-237516.jpg?v=1671776017"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "20 róż. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*40cm"
    },
    {
        "id": 327,
        "name": "Luks",
        "handle": "luxe",
        "description": "Zanurz się w uroku naszej zachwycającej aranżacji, składającej się z wyselekcjonowanego zestawu czerwonych róż, delikatnego oddechu dziecka i misternych parvifolii, a wszystko to elegancko zaprezentowane w rustykalnym rattanowym koszu. Namiętne czerwone róże, zwiewny oddech dziecka i teksturowana parvifolia tworzą harmonijne połączenie romantyzmu i naturalnego piękna. Idealna do wyrażenia miłości z okazji rocznic, uroczystości lub jako podnoszący na duchu prezent, ta aranżacja emanuje elegancją i przemyślanym urokiem. Uchwyć ponadczasowe piękno natury dzięki temu urzekającemu zestawowi kwiatowemu.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Luxe-New_cf05fe6e-a6ef-445b-9d2b-f3a0a028e748.jpg?v=1641321979",
            "/images/products/Luxe-New-2_85c98870-22f4-4116-9161-12e9988bc4da.jpg?v=1641321972"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "22 Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 328,
        "name": "Priscilla",
        "handle": "priscilla",
        "description": "„Na próżno się zmagałem. To nie wystarczy. Moje uczucia nie zostaną stłumione. Musisz pozwolić mi powiedzieć ci, jak gorąco cię podziwiam i kocham. -  Jane Austen",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Priscilla-1b.jpg?v=1641312114",
            "/images/products/Priscilla-2b.jpg?v=1611625560"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 329,
        "name": "Nicole",
        "handle": "nicole",
        "description": "Przedstawiamy nasz bukiet Love's Spectrum Bouquet, prawdziwie czarującą kreację składającą się z trzech oszałamiających, tęczowych róż, symbolizujących szereg emocji. Bukiet ten, umieszczony w różowym koszu z wierzby w kształcie serca, jest wyjątkowym i ponadczasowym wyrazem miłości. Żywe kolory i wykwintna prezentacja sprawiają, że jest to idealny prezent, który pozwala wyrazić uczucia, radość i trwały podziw.",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nicole-1bb-126886.jpg?v=1705415697",
            "/images/products/Nicole-2bb-119734.jpg?v=1705415697"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 330,
        "name": "Biondi",
        "handle": "biondi",
        "description": "Świętuj wiosnę z naszym żywym bukietem tulipanówTen żywy bukiet to kalejdoskop kolorów, składający się z szeregu jaskrawych tulipanów owiniętych w elegancki biały papier i przewiązanych delikatną wstążką Flowers Poland. Wesoły prezent, idealny na każdą okazję, celebruje piękno wiosny w każdym płatku. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Biondi-2b.jpg?v=1721013755",
            "/images/products/Biondi-1b.jpg?v=1721013755"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Holenderskie tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 331,
        "name": "Nadia",
        "handle": "nadia",
        "description": "Poczuj uosobienie romantyzmu dzięki naszej uroczej aranżacji składającej się z 6 ciemnoczerwonych róż pomysłowo splecionych z subtelnym urokiem fioletowej kaspii. Ten zachwycający zestaw jest prezentowany w rustykalnym rattanowym koszu, który dodaje odrobinę naturalnej elegancji. Idealna do wyrażania miłości i podziwu przy różnych okazjach, ta kompozycja łączy w sobie namiętny urok czerwonych róż z kapryśnym pięknem fioletowej kaspii w rustykalnej, ale wyrafinowanej oprawie.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nadia-1.jpg?v=1641312960",
            "/images/products/Nadia-2.jpg?v=1641312954"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 332,
        "name": "Jaselyn",
        "handle": "jaselyn",
        "description": "Ta urocza aranżacja kosza składa się z żywych słoneczników, symbolizujących szczęście i witalność, w połączeniu z pomarańczowymi różami, które przynoszą ciepły, podnoszący na duchu akcent. Kwiaty elegancko wkomponowane są wśród bujnej zieleni i delikatnych kwiatów wypełniających, tworząc zachwycającą kompozycję. Ozdobiony rustykalnym tkanym koszem i przemyślanym przesłaniem „Kocham Cię” jest idealnym prezentem na każdą radosną okazję.",
        "type": "Flower Baskets",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jaselyn-2a-913637.jpg?v=1726743727",
            "/images/products/Jaselyn-1a.jpg?v=1726621424"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 333,
        "name": "Everetta",
        "handle": "everett",
        "description": "„Na próżno się zmagałem. To nie wystarczy. Moje uczucia nie zostaną stłumione. Musisz pozwolić mi powiedzieć ci, jak gorąco cię podziwiam i kocham. -  Jane Austen",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Everett-1a_e2a3344e-1523-4737-8938-1f0d2fee02bf.jpg?v=1641321126",
            "/images/products/Everett-2a_e9e9472d-e1dc-4751-b91d-622c02c5c388.jpg?v=1641321120"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 334,
        "name": "Sheila",
        "handle": "sheila",
        "description": "„Na próżno się zmagałem. To nie wystarczy. Moje uczucia nie zostaną stłumione. Musisz pozwolić mi powiedzieć ci, jak gorąco cię podziwiam i kocham. -  Jane Austen",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Sheila-1a.jpg?v=1641316926",
            "/images/products/Sheila-2a.jpg?v=1641316920"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 335,
        "name": "Muślin",
        "handle": "tiffany",
        "description": "„Na próżno się zmagałem. To nie wystarczy. Moje uczucia nie zostaną stłumione. Musisz pozwolić mi powiedzieć ci, jak gorąco cię podziwiam i kocham. -  Jane Austen",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/tiffany-1.jpg?v=1641303961",
            "/images/products/tiffany-2.jpg?v=1641303955"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 336,
        "name": "Bogactwo (II)",
        "handle": "wealth-ii",
        "description": "Oświetl wszystkie wielkie otwarcia za pomocą statywu Golden Radiance. Jasnożółte gerbery, róże i chryzantemy przeplatają się, ozdobione złotymi liśćmi, tworząc kwiatowe arcydzieło, które symbolizuje dobrobyt i elegancję. Promienny punkt centralny radosnego świętowania.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_II_-1b.jpg?v=1720094601",
            "/images/products/Grand-Opening-Card-2024_ed0d6bad-c442-4914-9e4d-cfa78849fe55.jpg?v=1720094610",
            "/images/products/Wealth-_II_-2b.jpg?v=1720094601"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Eustoma. Mattiola. Goździki. Wierzby. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 337,
        "name": "Bogactwo (III)",
        "handle": "wealth-iii",
        "description": "Zachwyć się ponadczasową estetyką podczas wszystkich wielkich otwarciach biznesowych dzięki naszemu statywowi z ponadczasową białą elegancją. Piękne kwiaty spływają kaskadą spod delikatnych białych listew z siateczki, tworząc oszałamiający i klasyczny element centralny, który emanuje wyrafinowaniem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_III_-1a.jpg?v=1720406076",
            "/images/products/Grand-Opening-Card-2024_7c612a2f-7424-4900-bd7d-5edfe00fce18.jpg?v=1720406101",
            "/images/products/Wealth-_III_-2a.jpg?v=1720406077"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Goździki. Eustoma. Anturium . Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 338,
        "name": "Donna",
        "handle": "donna",
        "description": "Uświetnij każdą okazję naszym wyrafinowanym pastelowym bukietem. Ta wyrafinowana kompozycja składa się z delikatnych różowych róż ogrodowych, kremowo białych kwiatów i bujnego eukaliptusa, elegancko opakowanych w miękki beżowy papier. Idealny na wesela, rocznice lub po prostu, aby pokazać komuś, na kim Ci zależy, dodaje odrobiny wdzięku i piękna każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Donna-2a.jpg?v=1721215385",
            "/images/products/Donna-1a.jpg?v=1721215385"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża ogrodowa. Spryskaj róże. Goździk . Eustoma. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 339,
        "name": "Yolanda",
        "handle": "yolanda",
        "description": "Rozkoszuj się romantyzmem dzięki naszemu różowemu bukietowi oddechu dziecka w kształcie serca. Miękkie odcienie różu splatają się w urzekającą formację serca, przywołującą miłość i czułość. Ten starannie wykonany bukiet ucieleśnia elegancję i urok, idealny do świętowania miłości z okazji rocznic, Walentynek lub innych serdecznych okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Yolanda-2b.jpg?v=1720796211",
            "/images/products/Yolanda-1a.jpg?v=1720794931"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 340,
        "name": "Helena",
        "handle": "helen",
        "description": "Podnieś poziom romantyzmu dzięki naszemu dziecięcemu bukietowi oddechu w kształcie serca w kolorze Tiffany Blue. Misternie ułożona kaskada oddechu dziecka w urzekającym kształcie serca, emanująca wyrafinowaniem i urokiem. Idealny na śluby, zaręczyny lub do wyrażania uczuć, ten bukiet emanuje elegancją, oferując ponadczasowy symbol miłości w wyjątkowym odcieniu błękitu Tiffany Blue.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Helen-2a-685333.jpg?v=1720875987",
            "/images/products/Helen-1a.jpg?v=1720795917"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 341,
        "name": "Świt",
        "handle": "dawn",
        "description": "„Najlepszych i najpiękniejszych rzeczy na tym świecie nie można zobaczyć ani nawet usłyszeć, ale trzeba je poczuć sercem”. - Helena Keller",
        "type": "Flower Baskets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Dawn-1b.jpg?v=1641335414",
            "/images/products/Dawn-2b.jpg?v=1641335408"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Kwiat bawełny. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 342,
        "name": "Komfort (II)",
        "handle": "comfort-ii",
        "description": "Składając kondolencje, nasze kwietniki elegancko łączą niebieskie róże i sztuczne białe lilie z żywymi zielonymi chryzantemami. Ta spokojna aranżacja symbolizuje spokój i współczucie, zapewniając delikatny hołd zmarłym i pocieszenie w chwilach żalu i pamięci.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Comfort-_II_-1d.jpg?v=1720156595",
            "/images/products/Condolence-Card-2024_1_ccadc7aa-9403-4e55-a5a6-f3e366cf41e7.jpg?v=1720156601",
            "/images/products/Comfort-_II_-2d.jpg?v=1720156595"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidee. Róże. Mattiola. Mamo. Sztuczna hortensja"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 343,
        "name": "Jolene",
        "handle": "jolene",
        "description": "Zanurz się w czystości naszego Bukietu Oddechu Białego Dziecka, elegancko opakowanego w opakowanie w kratkę. Każda delikatna łodyga emanuje wdziękiem i urokiem, pięknie komponując się z żywym opakowaniem. Idealny na uroczystości, wesela lub jako szczery gest, ten bukiet dodaje odrobiny wyrafinowania i radości każdej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jolene-2a.jpg?v=1721366444",
            "/images/products/Jolene-1a.jpg?v=1721366444"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 344,
        "name": "Cynthia",
        "handle": "cynthia",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/cynthia-1.jpg?v=1641336020",
            "/images/products/cynthia-2.jpg?v=1641336012"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Bawełniane Kwiaty. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 345,
        "name": "Jacquelyn",
        "handle": "jacquelyn",
        "description": "Ten elegancki bukiet składa się z delikatnych pastelowych róż, białych chryzantem i delikatnego lisiantusa, akcentowanych bujną zielenią. Zapakowany w brzoskwiniowy papier i przewiązany białą wstążką, jest idealnym prezentem na każdą wyjątkową okazję, emanującym pięknem i wdziękiem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jacquelyn-2b.jpg?v=1720841951",
            "/images/products/Jacquelyn-1b.jpg?v=1720841951"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Meta róże. Eustoma. Mattiola. Oddech dziecka. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 346,
        "name": "Bogactwo (V)",
        "handle": "wealth-v",
        "description": "Wyraź się podczas wszystkich uroczystych wernisaży biznesowych dzięki naszemu statywowi Pink Elegance. Ozdobiony pastelowymi różowymi kompozycjami kwiatowymi owiniętymi w piękne różowe listwy przypodłogowe, jest wdzięcznym i wyrafinowanym elementem centralnym, który oznacza sukces i nowe, kwitnące przedsięwzięcie.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_V_-1b.jpg?v=1720094782",
            "/images/products/Grand-Opening-Card-2024_a602e631-d8c2-4992-87b9-ee355d61151a.jpg?v=1720094788",
            "/images/products/Wealth-_V_-2b.jpg?v=1720094783"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustomy . Mattiola. Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 347,
        "name": "Kathy",
        "handle": "kathy",
        "description": "Ten romantyczny bukiet składa się z delikatnych pastelowych róż i lisiantusa, akcentowanych delikatnymi kwiatami rumianku i bujną zielenią. Uroku dodają dwa wiklinowe akcenty w kształcie serca. Zapakowany w elegancki beżowy papier i przewiązany wstążką, idealnie nadaje się do wyrażania miłości i uczuć.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Kathy-2b.jpg?v=1720709703",
            "/images/products/Kathy-1b.jpg?v=1720709703"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Eustoma.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 348,
        "name": "Janice",
        "handle": "janice",
        "description": "Ten oszałamiający bukiet składa się z żywych czerwonych róż i pomarańczowych róż w sprayu, akcentowanych eukaliptusem i delikatną zielenią. Zapakowany w elegancki beżowy papier i ozdobiony wstążką, to idealny prezent, aby wyrazić głęboką miłość i podziw.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Janice-2a.jpg?v=1720706501",
            "/images/products/Janice-1a.jpg?v=1720706501"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 349,
        "name": "Komfort (III)",
        "handle": "comfort-iii",
        "description": "Składając szczere kondolencje, nasze trójnogi na kwiaty mają motyw królewskiej fioletu, ozdobiony wykwintnymi kapustnymi, eleganckimi orchideami, chryzantemami i delikatnymi eustomami. Taka aranżacja stanowi godny hołd, symbolizując współczucie i pamięć, oferując pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_III_-1b.jpg?v=1720144036",
            "/images/products/Condolence-Card-2024_1_d85f303c-37ee-4dc8-a204-5aeca42b6ab3.jpg?v=1720144041",
            "/images/products/Comfort-_III_-2b.jpg?v=1720144036"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Eustoma. Orchidee. Sztuczna hortensja"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 350,
        "name": "Jessego",
        "handle": "jesse",
        "description": "Ten wesoły bukiet składa się z trzech żywych słoneczników otoczonych delikatnym oddechem dziecka, tworząc piękny kontrast. Zapakowany w elegancki biało-szary papier i przewiązany białą wstążką, to idealny prezent, który rozjaśni każdą okazję ciepłem i szczęściem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jesse-2b-886908.jpg?v=1726743728",
            "/images/products/Jesse-1b-184656.jpg?v=1726743728"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 351,
        "name": "Estelle",
        "handle": "estelle",
        "description": "Promieniuj spokojem bukietem siedmiu białych róż elegancko przedstawionym w opakowaniu ozdobionym białą koronką. Uspokajające piękno białych róż jest wzmocnione misternymi koronkowymi detalami, tworząc poczucie spokoju i wdzięku. Ta aranżacja idealnie nadaje się do wyrażania spokojnych uczuć przy okazjach, które wymagają dyskretnego piękna.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Estelle-1aa-286857.jpg?v=1706196885",
            "/images/products/Estelle-2aa-359413.jpg?v=1706196885"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 352,
        "name": "Verlynn",
        "handle": "verlynn",
        "description": "Ponadczasowy i romantyczny bukiet złożony z 18 łodyg różowych róż. Jednolitość koloru stwarza poczucie spokoju i wdzięku, a bujne kwiaty wyrażają uczucia miłości i podziwu. Ta klasyczna aranżacja idealnie nadaje się do wyrażania serdecznych emocji przy specjalnych okazjach.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Verlynn-1bb-559543.jpg?v=1706110522",
            "/images/products/Verlynn-2bb-527971.jpg?v=1706110522"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "18 róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 353,
        "name": "Linda",
        "handle": "linda",
        "description": "Ta tętniąca życiem aranżacja wazonu zawiera radosną mieszankę promiennych czerwonych róż, wesołych słoneczników i delikatnych białych kwiatów, podkreślonych delikatnymi różowymi akcentami i bujną zielenią. Dodatek uroczych gerber i figlarnych jagód Hypericum dodaje rozkosznego akcentu, dzięki czemu ten bukiet idealnie nadaje się do rozjaśnienia każdej przestrzeni. Emanuje ciepłem i szczęściem, wprowadzając żywą i elegancką atmosferę na każdą okazję.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Linda-2a-791842.jpg?v=1724688022",
            "/images/products/Linda-1a.jpg?v=1724633520"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Słonecznik. Mattiola. Spray do goździków. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 354,
        "name": "Bogactwo (VI)",
        "handle": "wealth-vi",
        "description": "Skąpane w złotym blasku słoneczników nasze stoisko emanuje ciepłem i pozytywnym nastawieniem, ucieleśniając ducha nowych początków. Każdy słonecznik ze swoimi żywymi płatkami i wysokim wzrostem symbolizuje radość i obfitość, jakie niosą ze sobą wielkie uroczystości otwarcia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_VI_-1d.jpg?v=1720094961",
            "/images/products/Grand-Opening-Card-2024_3bb00e35-459e-425c-8619-bb36a8506ed0.jpg?v=1720094968",
            "/images/products/Wealth-_VI_-2d.jpg?v=1720094961"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże w sprayu. Eustomy . Orchidee. Mamo. Wierzby. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 355,
        "name": "Komfort (VI)",
        "handle": "comfort-vi",
        "description": "W geście głębokiego współczucia nasze Stojaki z kwiatami kondolencyjnymi ozdobione są bogatymi, fioletowymi chryzantemami i delikatnymi białymi liliami. Ta pełna wdzięku aranżacja wyraża czuły hołd, symbolizując współczucie i pamięć, oferując pocieszenie i pocieszenie w chwilach smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Comfort-_VI_-1d.jpg?v=1720158969",
            "/images/products/Condolence-Card-2024_1_e95f98df-86d0-4b70-ae64-0d041df569dc.jpg?v=1720158985",
            "/images/products/Comfort-_VI_-2d.jpg?v=1720158969"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Spryskaj goździki. Sztuczne kwiaty. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 356,
        "name": "Komfort (VII)",
        "handle": "comfort-vii",
        "description": "W ramach wyrazu szacunku nasze statywy na kwiaty kondolencyjne przedstawiają spokojne białe chryzantemy i delikatne niebieskie gerbery, elegancko ozdobione białym dressingiem. Taka aranżacja stanowi godny i kojący hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_VII_-1b.jpg?v=1720143058",
            "/images/products/Condolence-Card-2024_1_4965929c-d85f-407d-b5ec-2973d525d48f.jpg?v=1720143063",
            "/images/products/Comfort-_VII_-2b.jpg?v=1720143058"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Eustoma. Mattiola. Różane spraye. Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 357,
        "name": "Quinnlyn",
        "handle": "quinnlyn",
        "description": "Zachwyć się ponadczasową elegancją naszego bukietu składającego się z 7 ciemnoczerwonych róż, z których każda otoczona jest delikatnym urokiem koronki. Ten czarujący zespół stanowi idealną równowagę pasji i wyrafinowania, tworząc oszałamiającą wizualnie aranżację. Idealny do wyrażenia miłości na specjalne okazje, rocznice lub jako romantyczny gest, ten bukiet emanuje wdziękiem i wyrafinowaniem. Obejmij piękno miłości dzięki tej urzekającej koronkowej prezentacji kwiatowej.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Quinnlyn-1a.jpg?v=1721312409",
            "/images/products/Quinnlyn-2a.jpg?v=1721312409"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 358,
        "name": "Khloe",
        "handle": "khloe",
        "description": "Zachwyć się elegancją naszego kremowego bukietu róż. Ta kompozycja, zawierająca miękkie róże w kolorze kości słoniowej w połączeniu z uroczymi stokrotkami i bujnym eukaliptusem, emanuje ponadczasowym pięknem. Zapakowany w delikatny biały papier i ozdobiony satynową wstążką, jest idealny na wesela, rocznice lub każdą okazję, podczas której chcesz wyrazić wdzięk i wyrafinowanie.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Khloe-2a-965416.jpg?v=1721222878",
            "/images/products/Khloe-1a.jpg?v=1721216088"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 359,
        "name": "Wiosenne deszcze FNB",
        "handle": "spring-showers-fnb",
        "description": "Spring Showers Wyraźna i wyrafinowana kompozycja kwiatowa i balonowa Spring Showers została zaprojektowana przez naszych kwiaciarni tak, aby była klasycznie elegancka. Ten bukiet kwiatów i balonów, zawierający róże, goździki i konserwowaną hortensję, jest dostarczany w wykwintnym pudełku na kapelusze, które tylko czeka na personalizację za pomocą dostosowywalnego komunikatu na naszym balonie!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Felicity1-534032.jpg?v=1671775907",
            "/images/products/Felicity2a-829340.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Goździk",
                "4. Zachowana hortensja",
                "5. Kaspia",
                "6. Eukaliptus",
                "7. Spersonalizowany tekst na balonie z 4 mini balonami",
                "8. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 360,
        "name": "Frida",
        "handle": "frida-1",
        "description": "Ten wykwintny bukiet składa się z róż cuppuccino w połączeniu z puszystymi, białymi, bawełnianymi łodygami, tworząc niepowtarzalną i elegancką kompozycję. Zaakcentowana bujną zielenią i delikatnymi wypełniaczami, oprawiona w uroczy, brzoskwiniowy papier.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Frida-2b.jpg?v=1720778642",
            "/images/products/Frida-1b.jpg?v=1720778642"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże capuccino. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 361,
        "name": "Daniela",
        "handle": "daniella",
        "description": "Uosobienie słońca, ten promienny bukiet słonecznych, żółtych róż, symbolizujący przyjaźń i radość, uzupełniony delikatną aureolą oddechu dziecka. Zapakowany w miękki żółty papier przewiązany delikatną białą wstążką, przypomina ciepły uścisk w jasny dzień, idealny do podnoszenia na duchu i celebrowania wesołych chwil w życiu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Daniella-2d-616279.jpg?v=1720788754",
            "/images/products/Daniella-1d.jpg?v=1720708515"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Żółte Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 362,
        "name": "Empatia (ja)",
        "handle": "empathy-i",
        "description": "W chwilach straty nasz Stoisko z Kondolencjami składa wyrazy serdecznego współczucia. Ta kompozycja, zawierająca spokojną mieszankę fioletowych chryzantem, różowej gerbery, fioletowych orchidei i białych róż, stanowi pocieszający hołd, symbolizujący współczucie i pamięć w chwilach smutku.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Empathy-_I_-1d.jpg?v=1720147024",
            "/images/products/Condolence-Card-2024_1_c356a1bc-8f21-44bf-ac62-6a8989e37ffa.jpg?v=1720147029",
            "/images/products/Empathy-_I_-2d.jpg?v=1720147024"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidee. Róże. Mamo. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 363,
        "name": "Empatia (II)",
        "handle": "empathy-ii",
        "description": "W chwilach kondolencji nasze kwietniki ozdobione nieskazitelnymi białymi chryzantemami, delikatnymi gerberami w kolorze szampana i miękkimi różami oferują pocieszenie i delikatny hołd. Ta elegancka aranżacja symbolizuje współczucie i pamięć, zapewniając komfort i wsparcie w chwilach żałoby.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Empathy-_II_-1d.jpg?v=1720156418",
            "/images/products/Condolence-Card-2024_1_2929c2c8-4e95-4e9f-ab3c-2fb14b51a0cb.jpg?v=1720156425",
            "/images/products/Empathy-_II_-2d.jpg?v=1720156418"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Róże. Eustoma.Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 364,
        "name": "Sukces (V)",
        "handle": "success-v",
        "description": "Odkryj wspaniałość wszystkich wielkich otwarć dzięki naszemu statywowi Sunlit Splendor. Złote akcenty liści podkreślają blask słoneczników i żółtej gerbery, tworząc promienny spektakl, który symbolizuje dobrobyt i radość. Ten kwiatowy zestaw wyróżnia się, dodając wyjątkowej okazji złotego akcentu elegancji i sukcesu.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Success-_V_-1b.jpg?v=1720094414",
            "/images/products/Grand-Opening-Card-2024_91780e97-abbe-4a92-8493-cdbff9c4f0c0.jpg?v=1720094426",
            "/images/products/Success-_V_-2b.jpg?v=1720094414"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Gerbera . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 365,
        "name": "Candice",
        "handle": "candice",
        "description": "Ten piękny bukiet składa się z oszałamiającego słonecznika otoczonego brzoskwiniowymi różami, pomarańczowymi różami w sprayu i bujną zielenią. Zapakowany w miękki, beżowy papier i przewiązany elegancką wstążką, będzie idealnym prezentem na każdą okazję, wnoszącym ciepło i radość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Candice-2a.jpg?v=1720701225",
            "/images/products/Candice-1a.jpg?v=1720701225"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże.  Słonecznik.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 366,
        "name": "Roztropność",
        "handle": "prudence",
        "description": "Ten zachwycający bukiet składa się z żywego słonecznika, delikatnych różowych róż i delikatnego dziecięcego oddechu, a całość uzupełnia uroczy pluszowy miś w stroju kończącym szkołę. Zapakowany w opalizujący papier i przewiązany wstążką, to idealny prezent z okazji ukończenia szkoły.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów",
            "Bukiety słoneczników"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Prudence-2a.jpg?v=1720668336",
            "/images/products/Prudence-1b.jpg?v=1720668468"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Spryskaj róże. Eustoma. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 367,
        "name": "Empatia (III)",
        "handle": "empathy-iii",
        "description": "W delikatnym wyrazie współczucia, nasze trójnogi z kwiatami kondolencyjnymi prezentują delikatne różowe gerbery i miękkie róże szampańskie, akcentowane delikatnym oddechem dziecka. Ta elegancka aranżacja stanowi pocieszający hołd, symbolizujący współczucie i pamięć, zapewniając pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Empathy-_III_-1b.jpg?v=1720143890",
            "/images/products/Condolence-Card-2024_1_c93a6b99-e6d6-4bcf-b008-cc4973e6b692.jpg?v=1720143901",
            "/images/products/Empathy-_III_-2b.jpg?v=1720143890"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Rajski ptak. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 368,
        "name": "Luby",
        "handle": "luby-1",
        "description": "Wzbudź emocje naszym wykwintnym bukietem składającym się z 6 czerwonych róż i 6 różowych róż końcówek, elegancko ułożonych z delikatną różową kaspią. Ten urzekający kwiatowy zestaw, owinięty w jaskrawo różowe opakowanie, jest doskonałym wyrazem miłości i podziwu. Spraw radość pięknem tych starannie wybranych i pomysłowo zaprezentowanych róż.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Luby-2g-179479.jpg?v=1721396910",
            "/images/products/Luby-1g-809302.jpg?v=1721396910"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 róż. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 369,
        "name": "Ferlyn",
        "handle": "ferlyn",
        "description": "Ten skąpany w słońcu bukiet składa się z jasnożółtych słoneczników i róż, przeplatanych delikatnymi białymi kwiatami i zielonymi liśćmi, zwieńczonym wesołym pluszowym misiem w czapce ukończenia szkoły, symbolizującym radosne osiągnięcia i nowy początek.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów",
            "Kwiaty gratulacyjne",
            "Bukiety słoneczników"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ferlyn-2b-615424.jpg?v=1720788920",
            "/images/products/Ferlyn-1b-838416.jpg?v=1720788920"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Stokrotka. Eustoma. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 370,
        "name": "Harley",
        "handle": "harley",
        "description": "Świętuj z wesołym urokiem naszego bukietu w kratkę. Ta kompozycja, zawierająca promienną mieszankę szampańskich mini różyczek, delikatnych stokrotek i bujnej zieleni, jest owinięta w zachwycający pomarańczowy papier w kratkę i przewiązana pasującą kokardką. Idealny na urodziny, rocznice lub inne radosne okazje, wnosi przypływ słońca i szczęścia do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Harley-2a.jpg?v=1721313919",
            "/images/products/Harley-1a.jpg?v=1721313919"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Spryskaj róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 371,
        "name": "Zina",
        "handle": "zina",
        "description": "Ten elegancki bukiet składa się z ciemnoczerwonych róż i delikatnych różowych goździków osadzonych w chmurze delikatnego oddechu dziecka. Zapakowany w delikatny różowy papier emanuje romantyzmem i urokiem, dzięki czemu jest idealnym prezentem, aby wyrazić miłość i podziw.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Zina-2a.jpg?v=1721361112",
            "/images/products/Zina-1a.jpg?v=1721361112"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Goździki. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 372,
        "name": "Noura",
        "handle": "noura",
        "description": "Ten elegancki bukiet przedstawia harmonijne połączenie delikatnych różowych i białych kwiatów, w tym róż i goździków, z delikatnymi akcentami eukaliptusa. Zapakowany w jasnoróżowy papier i ozdobiony pasującą wstążką emanuje ponadczasowym urokiem, idealnym do wyrażania miłości i uznania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Noura-2a.jpg?v=1721367603",
            "/images/products/Noura-1a.jpg?v=1721367602"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 373,
        "name": "Kylie",
        "handle": "kylie",
        "description": "Oszałamiający bukiet Flowers Poland, w którym żywe różowe goździki i kremowe róże mieszają się ze srebrzystymi liśćmi, a wszystko to owinięte w różowy papier z kaskadowymi wstążkami, wywołuje poczucie ponadczasowej elegancji i radosnego świętowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety eustomy"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kylie-2b-861833.jpg?v=1721222878",
            "/images/products/Kylie-1b.jpg?v=1721180765"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Eustoma. Zakurzeni Millerowie"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 374,
        "name": "Sukces (VI)",
        "handle": "success-vi",
        "description": "Niech Święto Kaskady Słoneczników będzie centralnym punktem wszystkich wielkich otwarć. Kaskadowe słoneczniki w połączeniu z delikatnym urokiem goździków tworzą oszałamiający spektakl wizualny, przygotowując scenę na dzień pełen sukcesów i radości.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Success-_VI_-1da.jpg?v=1720408475",
            "/images/products/Grand-Opening-Card-2024_0de2a1c4-1e3d-437b-a498-d4bb9126eb4b.jpg?v=1720408493",
            "/images/products/Prosperity-_VI_-2d.jpg?v=1720408393"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Goździki. Gerbera . Eustomy . Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 375,
        "name": "Luksus (ja)",
        "handle": "luxury-i",
        "description": "Zrób kolosalne wrażenie podczas wszystkich uroczystych otwarć firm dzięki naszemu stojakowi z kwiatami Sunlit Spectacle Jumbo. Wysokie słoneczniki, róże gałązkowe i kwiaty anturium tworzą zapierający dech w piersiach pokaz symbolizujący żywotność i sukces.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "547.45",
        "inclTax": "547.45",
        "img": [
            "/images/products/Luxury-_I_-1c.jpg?v=1720524189",
            "/images/products/Grand-Opening-Card-2024_44a83f0c-71af-4a3a-8458-9a67b45cc2b0.jpg?v=1720524194",
            "/images/products/Luxury-_I_-2c.jpg?v=1720524189"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Orchidee. Anturium . Róże. Mattiola. Mamo. Rajskie ptaki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 376,
        "name": "Perłowy FBS",
        "handle": "pearlyn",
        "description": "Ta urocza kompozycja zawiera żywe czerwone goździki otoczone delikatnym oddechem dziecka, tworząc klasyczną i elegancką kompozycję. Zapakowany w elegancką brązową torebkę z czerwoną wstążką, jest idealnym prezentem, który pozwoli wyrazić Twoje najgłębsze emocje i umilić każdą okazję.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Pearlyn-FBS-2c.jpg?v=1721788441",
            "/images/products/Pearlyn-FBS-1b.jpg?v=1721788182"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 377,
        "name": "Ridley'a",
        "handle": "ridley-1",
        "description": "Oszałamiający bukiet róż Menta, doskonale uzupełniony delikatnymi białymi akcentami i bujną zielenią. Ta elegancka kompozycja jest owinięta miękkim papierem w kolorze kości słoniowej, tworząc wyrafinowany i ponadczasowy prezent, idealny do wyrażenia podziwu i miłości przy każdej wyjątkowej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ridley-2a_ccdf39a8-9676-46ef-a90a-0c34163ccab2.jpg?v=1721017771",
            "/images/products/Ridley-1a_c3780c87-1bf3-4d48-8326-cecf4501a7d0-562796.jpg?v=1721049662"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Meta róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 378,
        "name": "Madelyn",
        "handle": "madelyn",
        "description": "Kolorowy wiosenny wazon z tulipanami to zachwycająca mieszanka żywych tulipanów w tęczowej kolorystyce, uzupełniona świeżą zielenią i delikatnymi stokrotkami. Umieszczony w wazonie z przezroczystego szkła i przewiązany wstążką, ten bukiet to doskonały sposób na uczczenie wiosennych radości i rozjaśnienie każdego pomieszczenia.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Madelyn-2a-372473.jpg?v=1724600050",
            "/images/products/Madelyn-1a-764799.jpg?v=1724600050"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Stokrotka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 379,
        "name": "Izabela",
        "handle": "isabella",
        "description": "Country Meadow Daisy Bouquet to zachwycająca mieszanka stokrotek i polnych kwiatów, ułożona w klasycznym szklanym słoju z rustykalnym akcentem sznurka. Ten bukiet oddaje esencję kwitnącej wsi, wnosząc świeży i naturalny urok do Twojego domu lub jako przemyślany prezent dla kogoś wyjątkowego.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Isabella-2b.jpg?v=1724548843",
            "/images/products/Isabella-1b.jpg?v=1724548843"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Stokrotki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 380,
        "name": "Sandra",
        "handle": "sandra",
        "description": "Romantyczna aranżacja różowo-czerwonych róż to urzekająca mieszanka delikatnych różowych róż, żywych czerwonych kwiatów i delikatnych białych akcentów, pięknie skomponowanych z bujną zielenią. Bukiet ten, umieszczony w wazonie z przezroczystego szkła, jest doskonałym wyrazem miłości i podziwu, idealny na rocznice, Walentynki lub każdą romantyczną okazję.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Sandra-2a-538491.jpg?v=1724600284",
            "/images/products/Sandra-1a.jpg?v=1724549735"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Piwonie. Tulipany. Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 381,
        "name": "Quincy’ego",
        "handle": "quincy",
        "description": "Świętuj wyjątkowe chwile w życiu dzięki naszemu żywemu, mieszanemu bukietowi kwiatów. Ta aranżacja, charakteryzująca się zachwycającą mieszanką brzoskwiń i białych róż, wesołych różowych goździków i uroczych stokrotek, jest akcentowana bujną zielenią. Zapakowany w elegancki biały papier, idealnie nadaje się do wyrażania radości, wdzięczności lub miłości, wnosząc eksplozję koloru i szczęścia na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Quincy-2a.jpg?v=1721268492",
            "/images/products/Quincy-1a.jpg?v=1721268492"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża ogrodowa. Róża. Spryskaj róże. Goździk .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 382,
        "name": "Gwyndolin",
        "handle": "gwyndolin",
        "description": "„To zdrowie jest prawdziwym bogactwem, a nie kawałki złota i srebra”. – Mahatmy Gandhiego",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/FD-NP-1-min-_1_-min.jpg?v=1628486650",
            "/images/products/FD-NP-1.2-min_1.jpg?v=1641329030"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "Asortyment Orzechów. Carlsberg (4 puszki). Szampan Moet"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*30cm"
    },
    {
        "id": 383,
        "name": "Kaathe",
        "handle": "kaathe",
        "description": "„To zdrowie jest prawdziwym bogactwem, a nie kawałki złota i srebra”. – Mahatmy Gandhiego",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/FD-NP-2-min-_1_-min.jpg?v=1641315013",
            "/images/products/FD-NP-2.1-min_1.jpg?v=1641315003"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "Asortyment orzeszków ziemnych. Piwo Carlsberg (5 puszek)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*30cm"
    },
    {
        "id": 384,
        "name": "Teagan",
        "handle": "teagan",
        "description": "Miłość to niekończący się akt przebaczenia – Beyonce",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Teagan-1-min_1.jpg?v=1641304090",
            "/images/products/Teagan-2-min_3_1.jpg?v=1641304081"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Frezja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*36cm"
    },
    {
        "id": 385,
        "name": "Uriasz",
        "handle": "uriah",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Uriah-1.jpg?v=1625011637",
            "/images/products/Uriah-2.jpg?v=1641303714"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*25cm"
    },
    {
        "id": 386,
        "name": "Kaelyn",
        "handle": "kaelyn",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Kaelyn-1.jpg?v=1625011379",
            "/images/products/Kaelyn-2.jpg?v=1625011380"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*28cm"
    },
    {
        "id": 387,
        "name": "Bluszcz",
        "handle": "ivy",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ivy-Final-1.jpg?v=1628486652",
            "/images/products/Ivy-Final-2.jpg?v=1628486652"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*20cm"
    },
    {
        "id": 388,
        "name": "Królowa",
        "handle": "queena",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "171.15",
        "inclTax": "171.15",
        "img": [
            "/images/products/Queena-final-1.jpg?v=1628486653",
            "/images/products/Queena-final-2.jpg?v=1628486653"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*28cm"
    },
    {
        "id": 389,
        "name": "Wierzba",
        "handle": "willow",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Willow-F1.jpg?v=1625012535",
            "/images/products/Willow-F2.jpg?v=1625012536"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*20cm"
    },
    {
        "id": 390,
        "name": "Kelly'ego",
        "handle": "kelly",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kelly-1.1.jpg?v=1628486653",
            "/images/products/Kelly-2.jpg?v=1628486654"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*20cm"
    },
    {
        "id": 391,
        "name": "Gruzja",
        "handle": "georgina",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Georgina-f1-min_1.jpg?v=1641319339",
            "/images/products/Georgina-f2-min_1.jpg?v=1641319334"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*25cm"
    },
    {
        "id": 392,
        "name": "Penelopa",
        "handle": "penelope",
        "description": "Przedstawiamy nasz wiosenny bukiet tulipanów, oszałamiającą kompozycję, która oddaje esencję świeżych, kwitnących ogrodów. Bukiet ten, będący zachwycającą mieszanką pastelowych tulipanów i delikatnej zieleni, jest pięknie ułożony w eleganckim szklanym wazonie z kokardką. Idealny do rozjaśnienia każdego pomieszczenia, ten elegancki stojak wnosi odrobinę wiosny do Twojego domu lub dnia ukochanej osoby.",
        "type": "Vase Arrangement",
        "tags": [
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Penelope-2a-894665.jpg?v=1724338306",
            "/images/products/Penelope-1a-500345.jpg?v=1724338306"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Stokrotka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 393,
        "name": "Ornsteina",
        "handle": "ornstein",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Kosze korporacyjne"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ornstein-1.1-min_1.jpg?v=1641309140",
            "/images/products/Ornstein-2.2-min_1.jpg?v=1641309134"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Spryskaj róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 394,
        "name": "Paulina",
        "handle": "pauline",
        "description": "Zanurz się w nieustannym uroku naszego bukietu składającego się z konserwowanych pastelowych różowych róż i miękkich płatków hortensji. Ta ponadczasowa aranżacja jest symbolem wiecznej miłości i podziwu, co czyni ją cenionym i trwałym prezentem na wyjątkowe chwile.",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Pauline-1g_fa338a82-b2a1-444f-93e6-ce04e87c6a03-130570.jpg?v=1704810097",
            "/images/products/Pauline-2g_16bbaf2f-1656-4ca8-8d87-3d32ec649cd3-946039.jpg?v=1704810097"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 395,
        "name": "Rozaria",
        "handle": "rosaria",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/Rosaria-1a.jpg?v=1662569384",
            "/images/products/Rosaria-2a.jpg?v=1662569384"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 396,
        "name": "Layla",
        "handle": "layla",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Layla-fp11-288020.jpg?v=1671685468",
            "/images/products/Layla-2-997225.jpg?v=1671685469"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Tulipany. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 397,
        "name": "Kora",
        "handle": "cora",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Cora3A-726799.jpg?v=1671685195",
            "/images/products/Cora3B.jpg?v=1666155447"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*25cm"
    },
    {
        "id": 398,
        "name": "Ellen",
        "handle": "ellen",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/final-form-ellenn1-884532.jpg?v=1671685199",
            "/images/products/Ellen-2-min_1.jpg?v=1641334100"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*25cm"
    },
    {
        "id": 399,
        "name": "Soraja",
        "handle": "soraya",
        "description": "„Życie nie polega na trzymaniu dobrych kart, ale czasami na dobrym rozegraniu słabej ręki”. - Jacka Londona",
        "type": "Bukiety",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Soraya-1-min_1.jpg?v=1641316579",
            "/images/products/Soraya-2-min_1.jpg?v=1641316576"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 400,
        "name": "Serafina",
        "handle": "seraphina",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Kosze korporacyjne"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Seraphina-1.1-min_3_1.jpg?v=1641317039",
            "/images/products/Seraphina-2.1-min_3_1.jpg?v=1641317033"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Spryskaj róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 401,
        "name": "Vanessa",
        "handle": "vanessa",
        "description": "Ten bukiet składa się z efektownych niebieskich róż i pasującego do nich niebieskiego kwiatu pomponu, uzupełnionego białymi różami, niebieskimi tulipanami i mieszanką zieleni. Kompozycja jest owinięta jasnoniebieskim papierem i przewiązana białą wstążką, tworząc chłodną i elegancką prezentację. Niebieskie odcienie nadają mu spokojny i niepowtarzalny wygląd, idealny do złożenia oświadczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Vanessa-2a.jpg?v=1721141250",
            "/images/products/Vanessa-1a.jpg?v=1721141250"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Mamo. Eustomy . Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 402,
        "name": "Ślub VI",
        "handle": "bridal-set",
        "description": "Do tej pięknej aranżacji ślubnej dołączony będzie wazon =)",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na wesele",
            "Kwiaty i bukiety na zaręczyny"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/set-b.jpg?v=1628486791",
            "/images/products/WhatsAppImage2021-07-15at2.18.24PM_1_1.jpg?v=1641337340",
            "/images/products/WhatsAppImage2021-07-15at2.18.24PM_2_1.jpg?v=1641337335"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Bukiet ślubny. 2x Bukiet Małej Druhny. Białe Róże. Eustomy . Delfin . Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 403,
        "name": "Kruk",
        "handle": "raven",
        "description": "Kompozycja kwiatowa Blue Elegance zawiera urzekającą kolekcję ciemnoniebieskich róż, akcentowanych gałązkami zieleni i oddechem dziecka. Bukiet ten, umieszczony w szklanym wazonie przewiązanym wstążką, jest idealny dla tych, którzy cenią sobie odrobinę niezwykłości, wnosząc chłodne i uspokajające piękno na każdą okazję.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Raven-2a-226230.jpg?v=1724688265",
            "/images/products/Raven1a-467970.jpg?v=1724688265"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Niebieskie Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*32cm"
    },
    {
        "id": 404,
        "name": "Katarina",
        "handle": "catarina",
        "description": "Podaruj romantyzm naszemu delikatnemu pastelowemu bukietowi. Ta kompozycja, zawierająca piękną mieszankę różu, delikatnej lawendy i kremowych róż menta, akcentowana różowymi goździkami i bujną zielenią, jest owinięta rustykalnym beżowym papierem. Idealny do wyrażania miłości, uznania lub świętowania dowolnej specjalnej okazji, dodaje elegancji i uroku każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Catarina-2a.jpg?v=1721313701",
            "/images/products/Catarina-1a.jpg?v=1721313700"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździk . Mattiola. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 405,
        "name": "Jennie",
        "handle": "jennie",
        "description": "Świętuj z ponadczasową elegancją naszego mieszanego bukietu róż. Ta kompozycja, zawierająca piękną mieszankę czerwonych, różowych i białych róż, akcentowana delikatnymi wypełniaczami i bujnym eukaliptusem, jest owinięta w delikatny różowy papier i przewiązana satynową wstążką. Idealny do wyrażenia miłości, uznania lub uczczenia jakiejś specjalnej okazji, dodaje odrobinę klasycznego piękna każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Jennie-2a.jpg?v=1721350796",
            "/images/products/Jennie-1a.jpg?v=1721350796"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 406,
        "name": "Nala",
        "handle": "nala",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Nala-FP-10-min_1.jpg?v=1641312949",
            "/images/products/Nala-FP2.jpg?v=1628049763"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Tulipany. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 407,
        "name": "Zorza polarna",
        "handle": "aurora",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Aurora-1.1.jpg?v=1628486978",
            "/images/products/Aurora-2.jpg?v=1628486978"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Tulipany. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 408,
        "name": "Cruella",
        "handle": "cruella",
        "description": "„Na całym świecie nie ma nic piękniejszego niż dziewczyna zakochana w każdym oddechu”. - Atticus",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Cruella-f10-min_1.jpg?v=1641342118",
            "/images/products/Cruella-f20-min_1.jpg?v=1641342110"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 409,
        "name": "Los",
        "handle": "fate",
        "description": "Zanurz się w spokojnym pięknie naszego delikatnego bukietu lawendowych róż. Kompozycja ta, ozdobiona delikatnymi lawendowymi i kremowymi różami, uzupełnionymi uroczymi stokrotkami i bujną zielenią, jest opakowana w elegancki różowy papier. Idealny do wyrażania miłości, wdzięczności lub świętowania jakiejkolwiek specjalnej okazji z wdziękiem i wyrafinowaniem, dodaje odrobiny spokoju i elegancji każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Fate-2a.jpg?v=1721265139",
            "/images/products/Fate-1a.jpg?v=1721265139"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Stokrotki. Astylbe. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 410,
        "name": "Cindy",
        "handle": "cindy",
        "description": "Klasyczny biały i różowy kwiatowy display to harmonijne połączenie białych róż, delikatnych różowych goździków i eterycznego oddechu dziecka, a wszystko to na tle bujnej zieleni. Prezentowana w przezroczystym wazonie przewiązanym wstążką, ta aranżacja idealnie nadaje się do dodania odrobiny dyskretnego luksusu każdemu wydarzeniu lub przestrzeni.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Cindy-2a.jpg?v=1724549217",
            "/images/products/Cindy-1a.jpg?v=1724549216"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj róże. Lilie kalii. Mattiola. Goździk . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 411,
        "name": "Merida",
        "handle": "merida",
        "description": "„Przyjaciel to ktoś, kto wie o Tobie wszystko, a mimo to Cię kocha.” -  Elberta Hubbarda",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/16th-A1000-min_1.jpg?v=1641313681",
            "/images/products/WhatsAppImage2021-08-16at2.25.12PM_1.jpg?v=1641313676"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 412,
        "name": "Roszpunka",
        "handle": "rapunzel",
        "description": "Rozjaśnij każdą okazję naszym promiennym bukietem słoneczników. Ta aranżacja, zawierająca żywe słoneczniki, delikatny biały lisianthus i miękkie brzoskwiniowe róże, jest zaakcentowana bujną zielenią i owinięta rustykalnym brązowym papierem. Idealny do wyrażania radości, wdzięczności lub miłości, wnosi odrobinę słońca i ciepła do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Rapunzel-2a.jpg?v=1721269485",
            "/images/products/Rapunzel-1a.jpg?v=1721269485"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 413,
        "name": "Quelaag",
        "handle": "quelaag",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "1049.49",
        "inclTax": "1049.49",
        "img": [
            "/images/products/WhatsAppImage2021-08-17at1.22.16PM_2.jpg?v=1641311396",
            "/images/products/WhatsAppImage2021-08-17at1.22.16PM_1_1.jpg?v=1641311389"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensje. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 45cm*55cm"
    },
    {
        "id": 414,
        "name": "Buffy",
        "handle": "buffy",
        "description": "Ten czarujący bukiet składa się z delikatnych różowych róż i tulipanów, wzmocnionych delikatnym białym lisianthusem i zakurzonymi liśćmi młynarza. Zapakowana w jasnoróżowy papier i ozdobiona różową wstążką kompozycja emanuje słodką i romantyczną aurą, idealną do wyrażania miłości i uczuć przy specjalnych okazjach.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Buffy-2a.jpg?v=1721366150",
            "/images/products/Buffy-1a.jpg?v=1721366150"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustomy . Goździki. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*36cm"
    },
    {
        "id": 415,
        "name": "Jela",
        "handle": "copy-of-candy",
        "description": "„Życie nie staje się łatwiejsze ani bardziej wybaczające, stajemy się silniejsi i odporniejsi”. – Steve’a Maraboliego",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/WhatsAppImage2021-09-28at11.14.33AM_1.jpg?v=1641316100",
            "/images/products/WhatsAppImage2021-09-09at2.35.47PM_1_1.jpg?v=1641316094"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Tulipan. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 416,
        "name": "Larisa",
        "handle": "larisa",
        "description": "Ten uderzający bukiet składa się z bogatych czerwonych róż, bladoróżowego lisianthus i delikatnych różowych goździków. Zaakcentowana bujną zielenią i owinięta opalizującym, holograficznym papierem, aranżacja jest odważna i elegancka. Idealny do dodania odrobiny blasku każdej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Larisa-2a.jpg?v=1721367314",
            "/images/products/Larisa-1a.jpg?v=1721367314"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 417,
        "name": "Eufrazja",
        "handle": "euphrasia",
        "description": "Wazon z wiosennymi kwiatami to piękna mieszanka tulipanów i goździków w odcieniach różu, czerwieni i żółci. Ta żywa aranżacja, umieszczona w wazonie ze szkła przyciemnianego na zielono i przewiązana wstążką, wnosi świeżą i radosną atmosferę do każdego pomieszczenia, dzięki czemu jest przemyślanym prezentem dla bliskich.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Euphrasia-2a-837418.jpg?v=1724599806",
            "/images/products/Euphrasia-1a-781620.jpg?v=1724599806"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Stokrotka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 418,
        "name": "Belladona",
        "handle": "belladonna",
        "description": "Ten uderzający bukiet składa się z żywych niebieskich róż otoczonych delikatnym, białym oddechem dziecka, tworząc oszałamiający kontrast. Zapakowany w elegancki bladoniebieski papier emanuje spokojem i pogodą ducha. Idealny na specjalne okazje, rocznice lub do przekazania wyjątkowego i zapadającego w pamięć przekazu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-01-07at1.50.19PM_2.jpg?v=1641534711",
            "/images/products/WhatsAppImage2022-01-07at1.50.19PM_3.jpg?v=1641534854"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 róż. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 419,
        "name": "Adela",
        "handle": "adelle",
        "description": "Poczuj elegancję dzięki naszym bukietom róż w sprayu z szampanem i bukietem oddechu dziecka. Ta oszałamiająca kompozycja składa się z miękkich róż w sprayu w kolorze szampana otoczonych chmurą białego oddechu dziecka, a wszystko elegancko opakowane w nieskazitelny biały papier z czarnymi akcentami. Idealny na każdą okazję, ten bukiet emanuje wdziękiem i wyrafinowaniem. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Adelle-1a.jpg?v=1720882750",
            "/images/products/Adelle-2a.jpg?v=1720882750"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Różany spray. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 420,
        "name": "Jillian",
        "handle": "jillian",
        "description": "Ten uroczy bukiet zawiera eleganckie różowe tulipany, symbolizujące miłość i troskę. Opakowany w nieskazitelny biały papier przewiązany delikatną różową wstążką emanuje prostotą i wdziękiem. Idealny na urodziny, rocznice lub aby rozjaśnić czyjś dzień odrobiną naturalnego piękna i elegancji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/WhatsAppImage2021-08-23at11.30.53.jpg?v=1641315859",
            "/images/products/Adelaide-II-2B.jpg?v=1630301114",
            "/images/products/Jillian---Delivery_1.jpg?v=1716433146",
            "/images/products/Adelaide-II-3B.jpg?v=1716433146"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 421,
        "name": "Aria FBS",
        "handle": "aria",
        "description": "„Życie nie polega na odnalezieniu siebie. Życie polega na tworzeniu siebie.”  – George’a Bernarda Shawa",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-08-25at1.49.29PM_2.jpg?v=1641355442",
            "/images/products/WhatsAppImage2021-08-25at1.49.29PM_1_1.jpg?v=1641355436"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 422,
        "name": "Aleksa",
        "handle": "alexa",
        "description": "Ten czarujący bukiet zawiera oszałamiającą gamę pastelowych oddechów dziecka, tworząc marzycielski i kapryśny wygląd. Zapakowany w naturalny brązowy papier, nadaje rustykalny urok, idealny na każdą okazję. Idealny na urodziny, rocznice lub po prostu, aby rozjaśnić czyjś dzień odrobiną magii.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Alexa-1a.jpg?v=1720533900",
            "/images/products/Alexa-2a.jpg?v=1720533900"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 423,
        "name": "Ania",
        "handle": "aniya",
        "description": "Ten jasny i wesoły bukiet składa się z żywych żółtych tulipanów, pięknie ułożonych z bujną zielenią. Zapakowany w miękki żółty papier i przewiązany białą wstążką, jest idealnym prezentem na każdą okazję, emanującym szczęściem i pozytywnym nastawieniem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Aniya-1a.jpg?v=1720788475",
            "/images/products/Aniya-2a.jpg?v=1720788475"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 424,
        "name": "Anadia",
        "handle": "anadia",
        "description": "Wyraź uczucie naszym eleganckim bukietem czerwonych róż i różowych lilii. Zawiera żywe czerwone róże i delikatne różowe lilie, akcentowane bujną zielenią i owinięte w zakurzony różowy papier. Idealny do wyrażania głębokich uczuć, celebrowania wyjątkowych chwil lub okazywania wdzięczności, to klasyczny i romantyczny wybór na każdą szczerą okazję. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Bukiety lilii"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/c1505210-d40a-4257-8ef0-f6e03b674103-656746.jpg?v=1671684750",
            "/images/products/Anadia-2.jpg?v=1630301115",
            "/images/products/Anadia-3-363818.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie . Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość:30cm*36cm"
    },
    {
        "id": 425,
        "name": "Alaina",
        "handle": "alaina",
        "description": "Zanurz się w pięknie prostoty dzięki naszemu urzekającemu bukietowi czerwonych róż. Bukiet składający się z 6 ciemnoczerwonych róż, którym elegancko towarzyszą delikatne kaspie i parvifolia, tworzy harmonijną i wyrafinowaną aranżację. Idealny do wyrażania miłości i podziwu przy różnych okazjach, łączy klasyczny romans z naturalną elegancją.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Alaina-1a.jpg?v=1720778081",
            "/images/products/Alaina-2a.jpg?v=1720778080"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Czerwone Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 426,
        "name": "Adelaida",
        "handle": "adelaide",
        "description": "Rozjaśnij każdą okazję dzięki naszemu bukietowi kwiatów w różnych kolorach Żywe odcienie łączą się w naszym bukiecie kwiatów w różnych kolorach, kalejdoskopie piękna natury. Róże, tulipany i eustoma w niezliczonej liczbie kolorów są elegancko zapakowane i zamknięte w świeżym, zielonym opakowaniu. Ta żywiołowa kompozycja to idealny prezent, który umili każdą okazję. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Adelaide-2e-911833.jpg?v=1721048737",
            "/images/products/Adelaide-1e.jpg?v=1721013978"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Tulipan. Goździki. Eustoma. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 427,
        "name": "Opactwo",
        "handle": "abbey",
        "description": "Bukiet ten składa się z delikatnych pastelowych róż menta i kwiatów lisianthus w odcieniach różu i kremu, uzupełnionych delikatną zielenią. Kompozycja owinięta jasnożółtym papierem i przewiązana pasującą wstążką tworzy delikatną i elegancką prezentację. Pastelowe odcienie nadają mu romantyczny i kojący wygląd, idealny na każdą specjalną okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Abbey-2a.jpg?v=1721141655",
            "/images/products/Abbey-1a.jpg?v=1721141655"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Mattiola.  Eustomy .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 428,
        "name": "Astoria",
        "handle": "astoria",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at11.00.47AM_2.jpg?v=1648014733",
            "/images/products/WhatsAppImage2022-03-23at11.00.47AM_1_1.jpg?v=1648014733"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 429,
        "name": "Adelina",
        "handle": "adeline",
        "description": "Wyraź swoje uczucia za pomocą naszego bukietu czerwonych róż i róż w kolorze cappuccino. Ta wykwintna kompozycja składa się z żywych czerwonych róż i delikatnych róż w kolorze cappuccino, akcentowanych bujną zielenią i drobnymi białymi stokrotkami. Elegancko opakowany w miękki kremowy papier, idealnie sprawdzi się jako wyraz miłości i uznania przy każdej okazji. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Adelaide-2a-824407.jpg?v=1721048737",
            "/images/products/Adeline-1a.jpg?v=1721015181"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*36cm"
    },
    {
        "id": 430,
        "name": "Abigail",
        "handle": "abigail",
        "description": "Przekaż czar za pomocą naszego bukietu fioletowych róż Hipnotyzujący bukiet składający się z siedmiu promiennych fioletowych róż, elegancko zamkniętych w opakowaniu z koronkowym wzorem. Misterne koronkowe detale podkreślają piękno bukietu, dodając wyrafinowania efektownym fioletowym kwiatom. Idealne, żeby zrobić wrażenie. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Abigail-1aa-628616.jpg?v=1706108684",
            "/images/products/Abigail-2aa-764015.jpg?v=1706108684"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Fioletowe róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 431,
        "name": "Aaliyah",
        "handle": "aaliyah",
        "description": "Poczuj elegancję dzięki naszemu wykwintnemu bukietowi różowych róż. Zanurz się w delikatnym uroku naszego wykwintnego bukietu, składającego się z delikatnych różowych róż i lisiantusa, akcentowanych gałązkami białych kwiatów wosku i zakurzonymi liśćmi młynarza. Idealny na urodziny, rocznice lub inne uroczystości miłosne, ten bukiet jest pięknym wyrazem Twoich serdecznych uczuć. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Aaliyah-2a.jpg?v=1721015735",
            "/images/products/Aaliyah-1a.jpg?v=1721015735"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 432,
        "name": "Adilena",
        "handle": "adilene",
        "description": "Poczuj spokój dzięki naszemu bukietowi fioletowego tulipana i białej eustomy. Ta oszałamiająca kompozycja składa się z delikatnych, barwionych na fioletowo tulipanów i białych eustomy, akcentowanych bujną zielenią i gałązkami oddechu dziecka. Bukiet ten, elegancko opakowany w miękki, niebieski papier, uosabia spokój i elegancję, idealny na każdą okazję. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "422.17",
        "inclTax": "422.17",
        "img": [
            "/images/products/Adilene-2a.jpg?v=1721792049",
            "/images/products/Adilene-1a.jpg?v=1721792050"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 433,
        "name": "Aleksandria FBS",
        "handle": "alexandria",
        "description": "Poczuj wyjątkową elegancję dzięki naszej torbie z bukietem czarnych konserwowanych róż. Ta urzekająca kompozycja przedstawia uderzającą czarną różę otoczoną suszonymi hortensjami, trawą pampasową i bujną zielenią. Zapakowany w stylową torbę z czarnymi uchwytami, emanuje wyrafinowaniem i urokiem. Idealnie doda elegancji każdemu wnętrzu. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at10.41.19AM_2.jpg?v=1648003359",
            "/images/products/WhatsAppImage2022-03-23at10.41.19AM_1_1.jpg?v=1648003359"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 434,
        "name": "Alivia FBS",
        "handle": "alivia",
        "description": "Rozkoszuj się urokiem naszej pastelowej różowej torby z kwiatami Ta urocza kompozycja składa się z delikatnej różowej zakonserwowanej róży, miękkich bawełnianych kwiatów i szeregu pastelowych kwiatów, a wszystko to pięknie prezentuje się w stylowej brązowej torbie z czarnymi uchwytami. Połączenie faktur i kolorów tworzy fantazyjną i elegancką ekspozycję, idealną na każdą okazję. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Alivia1-565960.jpg?v=1671684750",
            "/images/products/Alivia2-460817.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 435,
        "name": "Amada",
        "handle": "amada",
        "description": "Świętuj elegancję dzięki naszej żywej aranżacji wazonów Ta zachwycająca aranżacja wazonów składa się z połączenia wesołego szampana i białej eustomy, spokojnych brzoskwiniowych róż w sprayu i delikatnego uroku białych gerber. Akcentowany gałązkami zieleni i delikatnymi stokrotkami, ten bukiet emanuje radością i wyrafinowaniem. Idealny na każdą okazję, wnosi odrobinę elegancji i szczęścia do każdego otoczenia. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Amada-2a-872009.jpg?v=1724687450",
            "/images/products/Amada-1a-124730.jpg?v=1724687450"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 436,
        "name": "Zdumieć",
        "handle": "amaze",
        "description": "Rozjaśnij każde pomieszczenie dzięki naszej słonecznej aranżacji wazonowej Ta tętniąca życiem aranżacja wazonowa składa się z wesołych słoneczników, jasnożółtych gerber i uroczych pomarańczowych róż. Zaakcentowana bujną zielenią i delikatnymi kwiatami, wnosi przypływ słońca i szczęścia do każdego otoczenia. Idealnie sprawdzi się na specjalne okazje lub po prostu umili komuś dzień. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Amaze-2d.jpg?v=1726627730",
            "/images/products/Amaze-1d.jpg?v=1726627730"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera. Słoneczniki. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 437,
        "name": "Andrea",
        "handle": "andrea",
        "description": "Zanurz się w spokoju dzięki naszemu pogodnemu bukietowi niebieskiej hortensji. Ta wykwintna kompozycja, akcentowana eleganckimi białymi tulipanami i bujną zielenią, owinięta jest w wyrazisty biały papier z czarnymi wykończeniami, tworząc wyrafinowaną i uspokajającą prezentację. Idealny, aby wyrazić swoje najgłębsze uczucia lub po prostu rozjaśnić czyjś dzień odrobiną elegancji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Andrea-1a.jpg?v=1721188282",
            "/images/products/Andrea-2a.jpg?v=1721188282"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 438,
        "name": "Annalee FBS",
        "handle": "annalee",
        "description": "Delikatne goździki i róże w różnych odcieniach różu współgrają ze sobą, tworząc delikatną i romantyczną kompozycję. Ten wyrafinowany zestaw, zapakowany w szykowną torebkę, idealnie nadaje się do wyrażania miłości, podziwu lub celebrowania specjalnych okazji z odrobiną kwiatowego blasku.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Annalee-FBS-1a-761185.jpg?v=1720116839",
            "/images/products/Annalee-FBS-2a-126540.jpg?v=1720116839"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 439,
        "name": "Anna",
        "handle": "anne",
        "description": "Ta elegancka aranżacja wazonów zawiera spokojną mieszankę delikatnych róż w kolorze różu, kremowo białych kwiatów i delikatnych kalii, akcentowanych bujną zielenią i delikatnymi stokrotkami. Subtelne połączenie faktur i delikatnych odcieni tworzy bukiet emanujący ponadczasowym pięknem i wyrafinowaniem. Idealny na każdą okazję, wnosi odrobinę wdzięku i spokoju do każdego otoczenia.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Anne-2a-257181.jpg?v=1724687449",
            "/images/products/Anne-1a.jpg?v=1724634584"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj róże. Goździk . Eustoma. Lilie kalii. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 440,
        "name": "Ariya",
        "handle": "ariya",
        "description": "Ten bukiet to kwitnący promień słońca, zawierający odważny słonecznik pośród rumieniących się różowych kwiatów i różnorodnych goździków. Subtelne lawendowe akcenty i pierzasta zieleń dodają mu uroku. Zapakowany w papier pakowy i przewiązany różową wstążką z delikatnym przesłaniem, jest to podnoszący na duchu gest radości i uczucia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na zdrowie i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Ariya-1a.jpg?v=1720749671",
            "/images/products/Ariya-2a.jpg?v=1720749671"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róża. Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 441,
        "name": "Amente",
        "handle": "amente-1",
        "description": "Wyraź uczucie za pomocą naszego eleganckiego bukietu róż z różowymi końcówkami i kalii. Zawiera róże z różowymi końcówkami i kalie, akcentowane białą kaspią i bujną zielenią, a wszystko to zawinięte w miękki biały papier do pakowania. Idealny do wyrażenia uczuć, świętowania lub uznania, to klasyczny wybór na każdą szczerą okazję. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Amente1d-403117.jpg?v=1671684750",
            "/images/products/Amente2d-787439.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Calla Lily. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 442,
        "name": "Lucyna",
        "handle": "lucinda",
        "description": "Przedstawiamy bukiet, który uosabia elegancję i spokój, zawierający harmonijną mieszankę delikatnych pastelowych kwiatów. Ta aranżacja obejmuje delikatne lawendowe róże, żywe fioletowe tulipany oraz urocze białe i żółte stokrotki, uzupełnione bujną zielenią i akcentującymi kwiatami.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Lucinda-2a.jpg?v=1721360156",
            "/images/products/Lucinda-1a.jpg?v=1721360156"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 443,
        "name": "Morgana",
        "handle": "morgana",
        "description": "Bukiet Flowers Poland to romantyczna kompozycja różu i ciemnoczerwonych róż, zaakcentowana delikatnymi różowymi kwiatami i bujną zielenią, elegancko przewiązana satynową wstążką – idealna do wyrażania serdecznych emocji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Morgana-2b.jpg?v=1721269208",
            "/images/products/Morgana-1b.jpg?v=1721269208"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Astylbe. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 444,
        "name": "Tremaine",
        "handle": "tremaine",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Kosze korporacyjne"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-08-24at12.49.41PM_2.jpg?v=1641303852",
            "/images/products/WhatsAppImage2021-08-24at12.49.41PM_1_1.jpg?v=1641303847"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Spryskaj róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 445,
        "name": "Grimhilda",
        "handle": "grimhilde",
        "description": "Kompozycja Serenity Blue Hydrangea zapewnia poczucie spokoju dzięki bujnym niebieskim hortensjom, uzupełnionym eleganckimi białymi kwiatami i żywą zielenią. Bukiet ten, umieszczony w przezroczystym wazonie i wykończony delikatną wstążką, emanuje spokojem i wyrafinowaniem, dzięki czemu jest idealnym prezentem na każdą okazję, w której pożądany jest spokój i wygoda.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Grimhilde-2a-507359.jpg?v=1724599866",
            "/images/products/Grimhilde-1a-172210.jpg?v=1724599866"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Tulipany. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 446,
        "name": "Ingrith",
        "handle": "ingrith",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/ingrith-10.jpg?v=1630301145",
            "/images/products/WhatsAppImage2021-08-25at10.10.44AM_3_1.jpg?v=1641318829"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 8cm*12cm"
    },
    {
        "id": 447,
        "name": "Narisa",
        "handle": "narissa",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-08-25at11.51.31AM-min_1.jpg?v=1641309973",
            "/images/products/WhatsAppImage2021-08-25at11.36.42AM_4_-min.jpg?v=1641309968"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Eustoma. Gerbera . Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*35cm"
    },
    {
        "id": 448,
        "name": "Queenie",
        "handle": "queenie",
        "description": "Zanurz się w czystym bogactwie naszego niezwykłego bukietu składającego się z 99 różowo zabarwionych białych róż, pięknie przedstawionych w eleganckim białym opakowaniu. Ten wspaniały zespół emanuje wyrafinowaniem i czystością, dzięki czemu jest spektakularnym gestem podczas doniosłych uroczystości, wielkich wydarzeń lub ekstrawaganckiej deklaracji miłości. Każdy kwiat, delikatnie dotknięty różem, dodaje wysublimowanego akcentu temu zapierającemu dech w piersiach kwiatowemu arcydziełu, tworząc wizualną symfonię elegancji i wdzięku.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "1140.77",
        "inclTax": "1140.77",
        "img": [
            "/images/products/WhatsAppImage2021-08-27at11.28.29AM_1.jpg?v=1641311408",
            "/images/products/WhatsAppImage2021-08-27at10.47.40AM_1_1.jpg?v=1641311403"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże."
            ]
        },
        "dimention": "Szerokość*Wysokość: 45cm*55cm"
    },
    {
        "id": 449,
        "name": "Lamia",
        "handle": "lamia",
        "description": "Rozkoszuj się promiennym pięknem naszego wykwintnego układu koszy z dwoma żywymi słonecznikami, elegancko uzupełnionymi wdziękiem tulipanów i ponadczasowym urokiem róż. Ten oszałamiający zestaw oddaje esencję radości i wyrafinowania, dzięki czemu jest idealnym kwiatowym wyrazem na każdą okazję. Rozkoszuj się harmonijną mieszanką najwspanialszych kwiatów natury dzięki tej urzekającej aranżacji.",
        "type": "Flower Baskets",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Lamia-1dd.jpg?v=1715132529",
            "/images/products/Lamia-2dd.jpg?v=1715132529"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Tulipan. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*28cm"
    },
    {
        "id": 450,
        "name": "Pandora",
        "handle": "pandora",
        "description": "Wyraź odważne oświadczenie dzięki naszemu żywemu, mieszanemu bukietowi kwiatów. Ta kompozycja, ozdobiona efektownymi czerwonymi różami, wesołymi pomarańczowymi goździkami i delikatną alstremerią, akcentowana bujną zielenią i białymi kwiatami wypełniającymi, jest owinięta olśniewającym holograficznym i czarnym papierem. Idealny na urodziny, uroczystości lub inne specjalne okazje, wnosi odrobinę blasku i radości do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Pandora-2a.jpg?v=1721312596",
            "/images/products/Pandora-1a-123256.jpg?v=1721397153"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Tulipan. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 451,
        "name": "Rawana",
        "handle": "ravana",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2021-08-30at2.36.23PM_1.jpg?v=1641311036",
            "/images/products/WhatsAppImage2021-08-30at2.30.27PM_1_1.jpg?v=1641311028"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Kochanie, oddychaj"
            ]
        },
        "dimention": "Szerokość*Wysokość: 36cm*40cm"
    },
    {
        "id": 452,
        "name": "Empatia (V)",
        "handle": "empathy-v",
        "description": "Składając szczere kondolencje, nasze trójnogi na kwiaty przedstawiają gerbery w kształcie czystych białych chryzantem na tle dostojnego czarnego motywu. Ozdobiona czarnymi dekoracjami w kształcie wachlarza, białymi balonami i gustownym siateczkowym opatrunkiem, ta aranżacja stanowi pełen szacunku hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Empathy-_V_-1b.jpg?v=1720105960",
            "/images/products/Condolence-Card-2024_1_84e74d2f-8bf9-4f65-9d66-5af67d5dd173.jpg?v=1720105970",
            "/images/products/Empathy-_V_-2b.jpg?v=1720105959"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Gerbera . Mamo. Róża. Sztuczna orchidea. Goździk. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 453,
        "name": "Dominik",
        "handle": "dominique",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/WhatsAppImage2021-08-31at1.41.24PM.jpg?v=1641334820",
            "/images/products/WhatsAppImage2021-08-31at1.41.24PM_1.jpg?v=1641334814"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 454,
        "name": "Ainsleya",
        "handle": "ainsley",
        "description": "Obejmij piękno wiosny naszą pogodną niebiesko-fioletową aranżacją wazonów. Ten oszałamiający bukiet składa się z bujnych niebieskich hortensji, fioletowego lisianthus, różowych róż i gałązek ostu, elegancko ułożonych w szklanym wazonie przewiązanym jasnofioletową wstążką. Idealny na każdą okazję, wnosi odrobinę elegancji i spokoju do każdego otoczenia. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Ainsley-2b.jpg?v=1724126267",
            "/images/products/Ainsley-1b-162120.jpg?v=1724157934"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Tulipany. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 455,
        "name": "Skrzydlak",
        "handle": "samara",
        "description": "Piękna kompozycja żywych różowych tulipanów, elegancko opakowana w nieskazitelny biały papier i akcentowana świeżymi zielonymi liśćmi. Jasnoróżowa wstążka dodaje zabawnego akcentu, dzięki czemu ten bukiet będzie wspaniałym prezentem, który pozwoli celebrować wyjątkowe chwile i szerzyć radość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Samara-2a.jpg?v=1721018233",
            "/images/products/Samara-1a.jpg?v=1721018233"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 456,
        "name": "Dana",
        "handle": "dana",
        "description": "Zanurz się w urzekającym pięknie naszego pastelowego bukietu kwiatowego. Ta kompozycja, zawierająca harmonijne połączenie lawendy i białych róż, delikatnych fioletowych goździków i bujnej zieleni, jest owinięta marzycielskim opalizującym papierem. Idealny do wyrażania uczuć, wdzięczności lub świętowania jakiejkolwiek specjalnej okazji, dodaje odrobiny fantazji i elegancji każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Dana-2a-974510.jpg?v=1721396489",
            "/images/products/Dana-1a-411877.jpg?v=1721396489"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 457,
        "name": "Charlene",
        "handle": "charlene",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-09-07at11.31.13AM_1.jpg?v=1641336240",
            "/images/products/WhatsAppImage2021-09-07at11.16.46AM_1.jpg?v=1641336235"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Tulipany. Goździki. Eustoma. Calla Lily. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 458,
        "name": "Sadie",
        "handle": "sadie",
        "description": "Ten bukiet składa się z zachwycającej mieszanki kwiatów różu, fioletu i lawendy, w tym róż i tulipanów, uzupełnionych gałązkami zieleni. Kwiaty są owinięte w delikatny różowy papier i przewiązane pasującą wstążką, tworząc elegancką i uroczą prezentację. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Sadie-2a-372389.jpg?v=1721223175",
            "/images/products/Sadie-1a.jpg?v=1721141897"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 459,
        "name": "Luksus (II)",
        "handle": "luxury-ii",
        "description": "Odkryj elegancję podczas wszystkich uroczystych otwarć firm dzięki temu czarującemu zestawowi kwiatowemu. Pełen żywych kolorów i bujnej zieleni, tworzy urzekającą i wyrafinowaną atmosferę, symbolizując sukces i dobrobyt Twojego kwitnącego przedsięwzięcia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Luxury-_II_-1d.jpg?v=1720493628",
            "/images/products/Grand-Opening-Card-2024_23f214ad-caca-449b-95f4-70e72f8e3f4f.jpg?v=1720493643",
            "/images/products/Luxury-_II_-2d.jpg?v=1720493628"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Orchidee. Lilie . Czerwony imbir. Rajskie ptaki. Wierzby. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 460,
        "name": "Empatia (VI)",
        "handle": "empathy-vi",
        "description": "W chwilach głębokiego współczucia nasze Stojaki z kwiatami kondolencyjnymi przedstawiają pogodne białe i fioletowe chryzantemy z wdziękiem uzupełnione czystymi białymi gerberami. Ta delikatna aranżacja zapewnia pocieszenie, symbolizując współczucie i pamięć, zapewniając pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Empathy-_VI_-1d.jpg?v=1720181426",
            "/images/products/Condolence-Card-2024_1_44bb6fbf-0128-4981-bbcb-a3ccb97b4652.jpg?v=1720181440",
            "/images/products/Empathy-_VI_-2d.jpg?v=1720181426"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 461,
        "name": "Charona",
        "handle": "charon",
        "description": "„Najlepszych i najpiękniejszych rzeczy na tym świecie nie można zobaczyć ani nawet usłyszeć, ale trzeba je poczuć sercem”. - Helena Keller",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-11-17at1.45.22PM_2.jpg?v=1641343579",
            "/images/products/WhatsAppImage2021-11-17at1.45.22PM_1_1.jpg?v=1641343573"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Chamelaucium. Astylbe. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 462,
        "name": "Carmila",
        "handle": "carmilla",
        "description": "„Największym szczęściem w życiu jest przekonanie, że jesteśmy kochani; kochani ze względu na nas samych, a raczej kochani pomimo nas samych”. - Wiktor Hugo",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/WhatsAppImage2021-09-10at12.55.07PM_2.jpg?v=1641336860",
            "/images/products/WhatsAppImage2021-09-10at12.55.07PM_1_1.jpg?v=1641336853"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 463,
        "name": "Morana",
        "handle": "morana",
        "description": "„Miłość jest całkowicie bezsensowna. Ale musimy to robić dalej, bo inaczej się zgubimy i miłość wymrze, a ludzkość powinna po prostu to ogarnąć. Bo miłość to najlepsza rzecz, jaką możemy zrobić”. -Josha Radnora",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-09-10at3.18.47PM_2.jpg?v=1641313202",
            "/images/products/WhatsAppImage2021-09-10at3.18.47PM_1_1.jpg?v=1641313195"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Eustoma. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 464,
        "name": "Lawendowy FBS",
        "handle": "lavender",
        "description": "Wyrafinowana aranżacja emanująca luksusem, z aksamitnymi fioletowymi różami przeplatającymi się z delikatnymi różowymi kwiatami i subtelną zielenią, a wszystko to zamknięte w specjalnie wykonanym pudełku z papieru kraft ozdobionego wdzięczną lawendową wstążką. Ten bukiet stanowi symfonię elegancji i uroku.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Lavender-FBS-2b.jpg?v=1721798379",
            "/images/products/Lavender-FBS-1b.jpg?v=1721798379"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 465,
        "name": "Blanka",
        "handle": "blanca",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-09-15at12.00.31PM_2.jpg?v=1641353778",
            "/images/products/WhatsAppImage2021-09-15at12.00.31PM_1_1.jpg?v=1641353772"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Tulipany. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 466,
        "name": "Addisona",
        "handle": "addison",
        "description": "Poczuj spokój dzięki naszemu bukietowi niebieskiej hortensji. Zanurz się w spokoju dzięki naszemu wyjątkowemu bukietowi niebieskiej hortensji. Ta oszałamiająca kompozycja składa się z bujnych niebieskich hortensji otoczonych delikatnymi białymi stokrotkami, wszystkie elegancko owinięte w pasujący niebieski papier. Idealny na każdą okazję, ten bukiet wnosi spokój i piękno do każdego otoczenia. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Addison-2a.jpg?v=1720918387",
            "/images/products/Addison-1a.jpg?v=1720918387"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Stokrotka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 467,
        "name": "Luksus (III)",
        "handle": "luxury-ii-1",
        "description": "Uwolnij majestatyczną symfonię sztucznych lilii dzięki naszemu stojakowi z kwiatami Jumbo. Wysokie, sztuczne różowe lilie stoją wysoko, harmonizując z żywą gamą gerber, tworząc wizualnie bogaty i wspaniały element centralny wszystkich wielkich otwarć firm.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Luxury-_III_-1c.jpg?v=1720524776",
            "/images/products/Grand-Opening-Card-2024_824643d5-39d7-404d-b5b6-33168ed9c126.jpg?v=1720524789",
            "/images/products/Luxury-_III_-2c.jpg?v=1720524776"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Sztuczna lilia. Orchidee. Goździki. Złoty Feniks. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 150cm*185cm"
    },
    {
        "id": 468,
        "name": "Magnusa",
        "handle": "magnus",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2021-09-20at2.21.31PM_1.jpg?v=1641321493",
            "/images/products/WhatsAppImage2021-09-20at2.26.50PM_1.jpg?v=1641321486"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*25cm"
    },
    {
        "id": 469,
        "name": "Ayden",
        "handle": "ayden",
        "description": "„Trzymajcie miłość w swoim sercu. Życie bez niego jest jak ogród bez słońca, gdy kwiaty zwiędną.” - Oscar Wilde",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów",
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/WhatsAppImage2021-09-20at4.06.47PM_3.jpg?v=1641354720",
            "/images/products/WhatsAppImage2021-09-20at4.06.47PM_1_2.jpg?v=1641354714"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Gerbera . Eustoma. Stokrotka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 470,
        "name": "Atena",
        "handle": "athena",
        "description": "Ten bukiet ukończenia szkoły pęka mieszanką żywych fioletowych, delikatnych białych i słonecznych żółtych kwiatów, w tym efektownego słonecznika i eleganckich róż. W jego sercu znajduje się pluszowy żółty plusz z czapką, która dodaje kapryśnego akcentu uroczystości do wyrafinowanej aranżacji, a wszystko to otoczone bujną zielenią, aby radośnie oddać hołd osiągnięciom.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów",
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Athena-2b-681627.jpg?v=1720788381",
            "/images/products/Athena-1b.jpg?v=1720667417"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Spryskaj róże. Eustoma. Goździk . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 471,
        "name": "Luksus (IV)",
        "handle": "luxury-iv",
        "description": "Zaprezentuj wyrafinowanie na wszystkich wielkich otwarciach dzięki naszemu stojakowi z kwiatami Elegance in Bloom. Zawierające trzy czarujące kwiaty – różową gerberę, delikatne, gorące różowe róże i kapryśne pomarańczowe orchidee – to kwiatowe arcydzieło jest wysokie, symbolizując wdzięk i dobrobyt, tworząc urzekający punkt centralny wszystkich doniosłych okazji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Luxury-_IV_-1d-183863.jpg?v=1721487966",
            "/images/products/Grand-Opening-Card-2024_484de493-4f19-4133-9457-32a0987bdfe4.jpg?v=1721487966",
            "/images/products/Luxury-_IV_-2d-255367.jpg?v=1720552464"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Orchidee. Helikonia. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 472,
        "name": "Talia",
        "handle": "thalia",
        "description": "Rozjaśnij każdy dzień naszym żywym bukietem słoneczników i tulipanów. Kompozycja z wesołymi słonecznikami i uroczymi pomarańczowymi tulipanami, akcentowana delikatnym oddechem dziecka, oprawiona jest w elegancki beżowy papier. Idealny do wyrażania radości, wdzięczności lub świętowania jakiejkolwiek specjalnej okazji, wnosi przypływ słońca i szczęścia do każdej przestrzeni.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Bukiety słoneczników"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Thalia-2a.jpg?v=1721267465",
            "/images/products/Thalia-1a-409139.jpg?v=1721311226"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Dziecko oddycha. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 473,
        "name": "Danica",
        "handle": "danica",
        "description": "Przynieś radość i fantazję dzięki naszemu uroczemu pastelowemu bukietowi króliczków. Zawiera uroczą mieszankę delikatnych różowych róż, kremowo-żółtych kwiatów i delikatnych białych kwiatów, akcentowanych bujną zielenią i zabawną dekoracją króliczka. Zapakowana w elegancki różowy papier, ta kompozycja jest idealna do świętowania specjalnych okazji lub po prostu dodania odrobiny szczęścia każdemu dniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Danica-2a.jpg?v=1721264541",
            "/images/products/Danica-1a.jpg?v=1721264541"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździk . Mattiola. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 474,
        "name": "Grecja",
        "handle": "grecia",
        "description": "Odkryj żywy urok naszego mieszanego bukietu kwiatowego, składającego się z wykwintnej mieszanki różowych i brzoskwiniowych goździków, delikatnie uzupełnionych świeżymi zielonymi liśćmi i białymi akcentami. Ta oszałamiająca kompozycja jest elegancko opakowana w miękki zielony papier, co czyni ją idealnym wyborem, aby wnieść radość i piękno na każdą okazję. Idealny na uroczystości, wyrazy miłości lub po prostu aby rozjaśnić czyjś dzień, ten bukiet z pewnością pozostawi trwałe wrażenie dzięki uroczym kolorom i zachwycającej prezentacji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Grecia-2a.jpg?v=1721354649",
            "/images/products/Grecia-1a.jpg?v=1721354649"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Oksypetal . Astromeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 475,
        "name": "Audrey",
        "handle": "audrey",
        "description": "„Życie nie staje się łatwiejsze ani bardziej wybaczające, stajemy się silniejsi i odporniejsi”. – Steve’a Maraboliego",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/WhatsAppImage2021-09-29at2.22.14PM_2.jpg?v=1641354949",
            "/images/products/WhatsAppImage2021-09-29at2.22.14PM_1_1.jpg?v=1641354858"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Tulipan. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 476,
        "name": "Zarodek",
        "handle": "embry",
        "description": "Bukiet zawiera wydatny słonecznik otoczony białymi eustomami i mniejszymi kwiatami, akcentowany liśćmi eukaliptusa, stokrotkami i białą kaspią. Zapakowany w jasnoniebieski papier i przewiązany markową białą wstążką, prezentuje się radośnie i zachęcająco.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Embry-1a.jpg?v=1720889515",
            "/images/products/Embry-2a.jpg?v=1720889515"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 477,
        "name": "Ariana",
        "handle": "ariana",
        "description": "W centrum tego uroczego bukietu znajduje się promienny słonecznik otoczony eleganckimi białymi różami i delikatnymi różowymi goździkami. Zaakcentowana świeżą zielenią i owinięta miękkim beżowym papierem to idealna aranżacja, która rozjaśni każde pomieszczenie lub okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ariana-2a.jpg?v=1720841535",
            "/images/products/Ariana-1a.jpg?v=1720841535"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Słonecznik.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 478,
        "name": "Selena",
        "handle": "selena",
        "description": "Bukiet ten składa się z połączenia delikatnych różowych róż i żywych fioletowych tulipanów, akcentowanych oddechem dziecka, co nadaje mu delikatny, romantyczny wygląd. Kwiaty elegancko opakowane w biały papier i przewiązane różową wstążką tworzą pełną wdzięku i wyrafinowaną kompozycję. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Selena-2a.jpg?v=1721142166",
            "/images/products/Selena-1a.jpg?v=1721142166"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Tulipany. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 479,
        "name": "Britta",
        "handle": "britta",
        "description": "Ten piękny bukiet zawiera promienny słonecznik otoczony żywymi czerwonymi różami, delikatnymi różowymi różami i delikatną zielenią. Zapakowany w rustykalny brązowy papier i przewiązany białą wstążką, to idealny prezent, który rozjaśni każdą okazję ciepłem i urokiem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Britta-2a_2c8e3b02-1b8b-45b3-ade3-94ff6fe92167-746464.jpg?v=1720875616",
            "/images/products/Britta-1a.jpg?v=1720793158"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździk .  Słonecznik. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 480,
        "name": "Cathie",
        "handle": "cathie",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/WhatsAppImage2021-10-01at2.49.54PM.jpg?v=1641336477",
            "/images/products/WhatsAppImage2021-10-01at2.51.50PM.jpg?v=1641336470"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Eustoma. Gerbera . Piwonia . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*35cm"
    },
    {
        "id": 481,
        "name": "Clarabelle FBS",
        "handle": "clarabelle",
        "description": "Ten urzekający bukiet umiejętnie łączy śmiałość pojedynczego słonecznika z delikatną elegancją róż z kości słoniowej i żywych goździków, a wszystko to gustownie ułożone w stylowym pudełku z papieru kraft i wykończone elegancką satynową wstążką, ucieleśniającą luksus w rozkwicie.",
        "type": "Seria Torebek",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2021-10-01at5.37.48PM_2.jpg?v=1641343195",
            "/images/products/WhatsAppImage2021-10-01at5.37.48PM_1_1.jpg?v=1641343188"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Spryskaj róże. Eustoma. Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*35cm"
    },
    {
        "id": 482,
        "name": "Avira FBS",
        "handle": "avira",
        "description": "Żywa i czarująca kompozycja kwiatowa pełna jest bogato zabarwionych róż w kolorze magenta, słonecznej żółci i głębokiego fioletu, uzupełnionych delikatnymi różowymi kwiatami i białym oddechem dziecka. Zamknięty w eleganckiej torbie z papieru kraft z innowacyjnym podwójnym uchwytem, ​​emanuje współczesnym stylem, idealnym na efektowny prezent lub urzekający akcent w domu.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Avira-FBS-2a-412754.jpg?v=1721804103",
            "/images/products/Avira-FBS-1a-490279.jpg?v=1721804103"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 483,
        "name": "Ziva FBS",
        "handle": "ziva",
        "description": "Ta wykwintna kompozycja kwiatowa składa się z żywej mieszanki różowych gerber, goździków, róż i fioletowych kwiatów, elegancko umieszczonych w wyjątkowym pojemniku przypominającym torebkę. Idealny na każdą okazję, ten stylowy i uroczy bukiet wnosi odrobinę elegancji i świeżości do każdej przestrzeni, dzięki czemu jest idealnym prezentem lub pięknym dodatkiem do wystroju domu.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Ziva-FBS-2b.jpg?v=1721693811",
            "/images/products/Ziva-FBS-1a.jpg?v=1721693758"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 484,
        "name": "Ulubiony FBS",
        "handle": "faven",
        "description": "Ta oszałamiająca kompozycja zawiera bogate czerwone i delikatnie różowe róże, akcentowane delikatnymi białymi wypełniaczami i bujną zielenią. Zapakowany w elegancką brązową torebkę z satynową wstążką, to doskonały sposób na wyrażenie swojej miłości i uznania na każdą wyjątkową okazję.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Faven1_c31d9207-8037-436a-a1d7-00f097a29629-733080.jpg?v=1675473763",
            "/images/products/Faven2-363603.jpg?v=1675473763"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 485,
        "name": "Mendy'ego",
        "handle": "mendy",
        "description": "Rozjaśnij komuś dzień naszym uroczym bukietem tulipanów. Zawiera wykwintną mieszankę żywych różowych i nieskazitelnie białych tulipanów, elegancko ułożonych z bujną zielenią i owiniętych w delikatny różowy papier. Ta zachwycająca aranżacja idealnie nadaje się do wyrażania miłości, wdzięczności lub po prostu sprawiania radości przy każdej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Mendy-1a.jpg?v=1721186991",
            "/images/products/Mendy-2a.jpg?v=1721186991"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Parwifolia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 486,
        "name": "Amelia",
        "handle": "amelia",
        "description": "Zapewnij sobie spokój dzięki naszemu fioletowo-białemu bukietowi tulipanówTen elegancki bukiet składa się z pięknej mieszanki fioletowych i białych tulipanów, akcentowanych bujną zielenią i owiniętych w miękki lawendowy papier. Spokojne zestawienie kolorów tworzy spokojną i wyrafinowaną aranżację, idealną na każdą okazję. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Amelia-1a-423070.jpg?v=1721222347",
            "/images/products/Amelia-2a.jpg?v=1721188949"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Parwifolia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 487,
        "name": "Księżniczka III",
        "handle": "princess-iii",
        "description": "Bukiet ten to poetycka kompozycja, w której kopuła z lawendy i barwinka sugeruje subtelne przejście od wiosny do lata. Kwiaty otulone koronką z czystego, białego oddechu dziecka nadają im marzycielską miękkość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Princess-III-2a_1.jpg?v=1720745760",
            "/images/products/Princess-III-2a_2.jpg?v=1720745760"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 488,
        "name": "Luksus (V)",
        "handle": "wealth-v-1",
        "description": "Świętuj wszystkie premiery biznesowe dzięki naszemu statywowi Pastel Dreamscape. Ozdobiony różowymi i białymi kwiatami oraz delikatną gamą pastelowych kwiatów, tworzy uroczy, pełen radości punkt centralny, symbolizujący początek udanej i zachwycającej podróży.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "661.55",
        "inclTax": "661.55",
        "img": [
            "/images/products/Luxury-_V_-1b.jpg?v=1720064966",
            "/images/products/Grand-Opening-Card-2024_dd7eb0c7-5ccc-44be-a8ef-329e38a85e3b.jpg?v=1720064972",
            "/images/products/Luxury-_V_-2b.jpg?v=1720064965"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Eustoma. Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 489,
        "name": "Everleigh",
        "handle": "everleigh",
        "description": "Ten kapryśny bukiet zawiera urzekający, lawendowy oddech dziecka, tworząc marzycielski i eteryczny wygląd. Zwieńczona uroczą srebrną tiarą, jest idealna, aby sprawić, że ktoś poczuje się jak członek rodziny królewskiej. Opakowany w delikatny biały papier przewiązany delikatną lawendową wstążką, idealnie sprawdzi się na specjalne okazje lub po prostu, aby umilić komuś dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Everleigh-2a.jpg?v=1721018405",
            "/images/products/Everleigh-1a.jpg?v=1721018405"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 490,
        "name": "Esmeralda",
        "handle": "esmeralda",
        "description": "Uroczy bukiet składający się z żywych pomarańczowych tulipanów w połączeniu z delikatnymi białymi stokrotkami, tworzy odświeżającą i żywą kompozycję. Zapakowana w elegancki kremowy papier i przewiązana pasującą wstążką kompozycja emanuje ciepłem i radością, idealna do rozjaśnienia każdej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Esmeralda-2a-255548.jpg?v=1721135940",
            "/images/products/Esmeralda-1a.jpg?v=1721055673"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*32cm"
    },
    {
        "id": 491,
        "name": "Elektra",
        "handle": "elektra",
        "description": "Żywy bukiet składający się z odważnych czerwonych i delikatnie różowych róż, uzupełniony brzoskwiniowymi i żółtymi kwiatami. Aranżacja podkreślona delikatną zielenią, owinięta w elegancki papier w kolorze turkusowo-białym i przewiązana białą wstążką, dzięki czemu idealnie sprawdzi się jako prezent na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Elektra-2a.jpg?v=1721056341",
            "/images/products/Elektra-1a.jpg?v=1721056342"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździk . Mattiola. Astylbe. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 492,
        "name": "Karmazynowy",
        "handle": "crimson",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2021-10-27at10.29.06AM.jpg?v=1641342128",
            "/images/products/WhatsAppImage2021-10-27at10.29.06AM_1.jpg?v=1641342123"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*25cm"
    },
    {
        "id": 493,
        "name": "Laurie",
        "handle": "laurie",
        "description": "Ten bukiet zawiera jasnożółte tulipany i bujne zielone liście, tworząc wesołą i żywą kompozycję. Kwiaty otulone są ziemistym, brązowym papierem, przewiązanym prostą, elegancką wstążką. Bukiet emanuje świeżym, naturalnym urokiem, dzięki słonecznemu i podnoszącemu na duchu wyglądowi idealnie nadaje się do rozjaśnienia czyjegoś dnia lub uczczenia radosnej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Laurie-2a.jpg?v=1721142447",
            "/images/products/Laurie-1a.jpg?v=1721142448"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Holenderskie tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 494,
        "name": "Ewangelina",
        "handle": "evangeline",
        "description": "Ten zachwycający bukiet składa się z mieszanki żółtych tulipanów, brzoskwiniowych goździków i białych róż, tworząc harmonijną mieszankę kolorów. Zapakowana w miękki liliowy papier i ozdobiona satynową wstążką kompozycja emanuje elegancją i urokiem, dzięki czemu jest idealnym prezentem na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Evangeline-1a-934302.jpg?v=1721396676",
            "/images/products/Evangeline-2a.jpg?v=1721361698"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 495,
        "name": "Marta",
        "handle": "martha",
        "description": "Odkryj urok naszego pastelowego bukietu, składającego się z zachwycającej mieszanki delikatnych różowych i żółtych róż, akcentowanych delikatnym dziecięcym oddechem i zielonymi liśćmi. Ta aranżacja jest starannie opakowana w elegancki brązowy papier, co czyni ją idealnym prezentem na każdą okazję, niezależnie od tego, czy chodzi o uczczenie specjalnego wydarzenia, czy po prostu o rozjaśnienie czyjegoś dnia delikatnym pięknem i pogodnymi kolorami.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Martha-2a-957878.jpg?v=1721396909",
            "/images/products/Martha-1a.jpg?v=1721354452"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 496,
        "name": "Devora",
        "handle": "devora",
        "description": "Ten bukiet to wizja słodyczy, oferująca piękną mieszankę różowych tulipanów i białych/różowych róż. Tulipany przynoszą odrobinę wiosennej radości, a róże dodają ponadczasowego akcentu czystości i miłości. Razem tworzą piękny kontrast kolorów i kształtów, dzięki czemu ten bukiet będzie wspaniałym prezentem, który pozwoli uczcić dowolne wyjątkowe chwile w życiu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Devora-1b-813159.jpg?v=1712275557",
            "/images/products/Devora-2b-291137.jpg?v=1712275557"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 różowych róż. 9 białych róż. 12 różowych tulipanów"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 497,
        "name": "Renee",
        "handle": "renee",
        "description": "„Długie i szczęśliwe życie to nie bajka – to wybór”. - Płowy Tkacz",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2021-11-02at2.31.13PM_2.jpg?v=1641310807",
            "/images/products/WhatsAppImage2021-11-02at2.31.13PM_1_1.jpg?v=1641310801"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Tulipany.  Eustomy . Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 498,
        "name": "Pamela",
        "handle": "pamela",
        "description": "Uroczy bukiet składający się z żywych pomarańczowych róż w połączeniu z delikatnymi białymi stokrotkami. Zapakowana w miękki papier w kolorze kości słoniowej i wykończona pasującą wstążką, ta aranżacja emanuje świeżą i radosną elegancją, idealną do rozjaśnienia każdej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Pamela-2a-812280.jpg?v=1721136345",
            "/images/products/Pamela-1a-834680.jpg?v=1721136345"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Spryskaj róże. Stokrotka.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 499,
        "name": "Barbary",
        "handle": "barbara",
        "description": "Bukiet Słonecznikowo-Różowy Harmonia łączy w sobie jasne i odważne piękno słoneczników z delikatną elegancją róż, akcentowaną wyjątkowymi kulkami i zielenią. Ta aranżacja, umieszczona w szklanym wazonie, jest idealnym wyborem dla tych, którzy cenią sobie połączenie rustykalnego uroku i wyrafinowanego stylu, dzięki czemu każda okazja będzie wyjątkowa.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Barbara-2b-991935.jpg?v=1726743000",
            "/images/products/Barbara-1b-123084.jpg?v=1726743000"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Mamo. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 500,
        "name": "Zendaya",
        "handle": "zendaya",
        "description": "„Miłość to nieodparte pragnienie bycia nieodparcie pożądanym.” -  Roberta Frosta",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2021-11-19at10.11.20AM_3.jpg?v=1641302410",
            "/images/products/WhatsAppImage2021-11-18at2.28.22PM_3_1_1.jpg?v=1641302401"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Tulipan.  Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 501,
        "name": "Mikasa",
        "handle": "mikasa",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-11-23at11.24.48AM_2.jpg?v=1641313454",
            "/images/products/WhatsAppImage2021-11-23at11.24.48AM_1_1.jpg?v=1641313447"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Tulipany. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 502,
        "name": "Erina",
        "handle": "erina",
        "description": "„Życie nie staje się łatwiejsze ani bardziej wybaczające, stajemy się silniejsi i odporniejsi”. – Steve’a Maraboliego",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/WhatsAppImage2021-11-24at2.27.22PM_2.jpg?v=1641323648",
            "/images/products/WhatsAppImage2021-11-24at2.27.22PM_1_1.jpg?v=1641323641"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Tulipan. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 503,
        "name": "Yuna",
        "handle": "yuna",
        "description": "Emanuj elegancją dzięki naszemu czarującemu bukietowi lilii. Ta oszałamiająca kompozycja składa się z nieskazitelnych białych lilii otoczonych delikatnym różowym oddechem dziecka i bujną zielenią. Zapakowany w delikatny różowy papier i przewiązany satynową wstążką, idealnie nadaje się do wyrażenia współczucia, świętowania specjalnej okazji lub po prostu wniesienia odrobiny spokoju i piękna do każdej przestrzeni.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety lilii"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Yuna-2a.jpg?v=1721215076",
            "/images/products/Yuna-1a.jpg?v=1721215076"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie . Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 504,
        "name": "Hattie",
        "handle": "hattie",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2021-11-27at12.12.35PM_2.jpg?v=1641326654",
            "/images/products/WhatsAppImage2021-11-27at12.12.35PM_1_1.jpg?v=1641326648"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 505,
        "name": "Harriet",
        "handle": "harriet",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/WhatsAppImage2021-11-28at10.32.26AM_1.jpg?v=1641326750",
            "/images/products/WhatsAppImage2021-11-28at10.34.10AM_1.jpg?v=1641326660"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 506,
        "name": "Gabby",
        "handle": "gabby",
        "description": "Zachwycający bukiet składający się z delikatnych różowych gerber, uzupełnionych gałązkami zieleni i drobnymi różowymi kwiatami. Kompozycja jest elegancko owinięta w połyskujący, opalizujący i miękki różowy papier, przewiązany pasującą różową wstążką, tworząc uroczą i wesołą prezentację, która emanuje radością i pięknem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Gabby-2a.jpg?v=1721056857",
            "/images/products/Gabby-1a.jpg?v=1721056857"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 507,
        "name": "Rudolf",
        "handle": "rudolph",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/WhatsAppImage2021-12-01at1.12.52PM_3.jpg?v=1641310451",
            "/images/products/WhatsAppImage2021-12-01at1.12.52PM_1_2.jpg?v=1641310437"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 8cm*12cm"
    },
    {
        "id": 508,
        "name": "Kolęda",
        "handle": "carol",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/WhatsAppImage2021-12-01at1.13.00PM_2.jpg?v=1641336621",
            "/images/products/WhatsAppImage2021-12-01at1.13.00PM_1_1.jpg?v=1641336614"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 8cm*12cm"
    },
    {
        "id": 509,
        "name": "Piękność",
        "handle": "belle",
        "description": "Ten czarujący bukiet charakteryzuje się bujną kompozycją delikatnych białych kwiatów rumianku, emanującą prostotą i elegancją. Zapakowany w miękki biały papier i przewiązany złotą wstążką, jest idealnym prezentem na każdą okazję, oferując naturalne piękno i wdzięk.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Belle-2a-995904.jpg?v=1720788383",
            "/images/products/Belle-1a-755013.jpg?v=1720788383"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Stokrotka. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 510,
        "name": "Serena",
        "handle": "serena",
        "description": "„Najlepszych i najpiękniejszych rzeczy na tym świecie nie można zobaczyć ani nawet usłyszeć, ale trzeba je poczuć sercem”. - Helena Keller",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-12-08at2.00.32PM_2.jpg?v=1641316939",
            "/images/products/WhatsAppImage2021-12-08at2.00.32PM_1_1.jpg?v=1641316933"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Chamelaucium. Astylbe. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 511,
        "name": "Michelle",
        "handle": "michelle",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2021-12-10at10.00.23AM.jpg?v=1641313547",
            "/images/products/WhatsAppImage2021-12-10at10.00.23AM_1.jpg?v=1641313458"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*25cm"
    },
    {
        "id": 512,
        "name": "Królowa I",
        "handle": "queen-i",
        "description": "Ten oszałamiający bukiet składa się z żywych różowych tulipanów w połączeniu z delikatnymi jasnoróżowymi kwiatami, tworząc harmonijną i atrakcyjną wizualnie kompozycję. Kwiaty są podkreślone bujną zielenią liści i owinięte w opalizujący, połyskujący papier, dodający elegancji i wyrafinowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-I-2a.jpg?v=1721142688",
            "/images/products/Queen-I-1a-780489.jpg?v=1721223117"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Spryskaj róże. Parwifolia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 513,
        "name": "Królowa II",
        "handle": "queen-ii",
        "description": "Ten bukiet przedstawia odważne i piękne czerwone tulipany, elegancko ułożone z bujnymi zielonymi liśćmi. Żywe kwiaty owinięte są w delikatny, opalizujący różowy papier, dodający blasku i wyrafinowania. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-II-2a.jpg?v=1721143726",
            "/images/products/Queen-II-1a.jpg?v=1721143725"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Spryskaj róże. Parwifolia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 514,
        "name": "Królowa III",
        "handle": "queen-iii",
        "description": "Bukiet ten stanowi zachwycające połączenie jasnożółtych tulipanów i miękkich brzoskwiniowych róż, tworząc żywy i czarujący obraz. Kwiaty są pięknie opakowane w opalizujący papier, który dodaje subtelnego połysku, podkreślając ogólną elegancję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-III-2b.jpg?v=1721144631",
            "/images/products/Queen-III-1b.jpg?v=1721144630"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Spryskaj róże. Parwifolia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 515,
        "name": "Królowa IV",
        "handle": "queen-iv",
        "description": "Ten uderzający bukiet zawiera żywe pomarańczowe tulipany i róże, tworząc odważną i żywą kompozycję. Kwiaty są ułożone z bujną zielenią, dodając świeżego i naturalnego akcentu. Bukiet owinięty jest w opalizujący i ciemny papier, co nadaje mu nowoczesny i wyrafinowany wygląd.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-IV-2a.jpg?v=1721144878",
            "/images/products/Queen-IV-1a.jpg?v=1721144878"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Spryskaj róże. Parwifolia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 516,
        "name": "Królowa V",
        "handle": "queen-v",
        "description": "Ten bukiet składa się z żywych fioletowych tulipanów w połączeniu z delikatnymi białymi różami, owiniętych w opalizujący i pastelowy papier, przewiązany białą wstążką. Bujna zieleń dodaje świeżego akcentu, tworząc nowoczesną i elegancką aranżację, idealną na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Queen-V-2a.jpg?v=1721145122",
            "/images/products/Queen-V-1a.jpg?v=1721145122"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Spryskaj róże. Parwifolia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 517,
        "name": "Stacy",
        "handle": "stacy",
        "description": "Ten czarujący bukiet łączy delikatne pastelowe róże z delikatnym oddechem dziecka, tworząc marzycielską i romantyczną aranżację. Zapakowany w elegancki kremowy papier i ozdobiony satynową wstążką, jest idealnym prezentem z okazji celebrowania miłości, przyjaźni lub innej specjalnej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Stacy-2a.jpg?v=1721362101",
            "/images/products/Stacy-1a.jpg?v=1721362101"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Hortensja. Goździk . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 518,
        "name": "Miłość V",
        "handle": "fondness-v",
        "description": "Podnieś atmosferę czystym i ponadczasowym pięknem naszego bukietu, przedstawiającego sześć nieskazitelnych białych róż delikatnie otoczonych delikatną gracją oddechu dziecka. Ta czarująca aranżacja emanuje elegancją i czystością, dzięki czemu jest idealnym wyborem do wyrażania serdecznych uczuć przy każdej wyjątkowej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-V-2b.jpg?v=1720958457",
            "/images/products/Fondness-V-1b.jpg?v=1720958457"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 519,
        "name": "Miłość VI",
        "handle": "fondness-vi",
        "description": "Zanurz się w subtelnej elegancji naszego wykwintnego bukietu składającego się z sześciu róż szampańskich, z wdziękiem otoczonych delikatnymi skupiskami oddechu dziecka. Ta czarująca aranżacja emanuje wyrafinowaniem i urokiem, dzięki czemu jest idealnym wyborem do wyrażania wyrafinowanych uczuć i tworzenia chwil ponadczasowego piękna. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-VI-2b.jpg?v=1720956831",
            "/images/products/Fondness-VI-1b.jpg?v=1720956831"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 520,
        "name": "Miłość VII",
        "handle": "fondness-vii",
        "description": "Zanurz się w wibracji miłości dzięki naszemu urzekającemu bukietowi składającemu się z sześciu efektownych, gorących róż, delikatnie objętych miękkim, chmurnym pięknem oddechu dziecka. Ten czarujący zestaw emanuje energią i urokiem, dzięki czemu jest idealnym wyrazem pasji i uczucia dla Twoich cennych chwil. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fondness-VI-2c.jpg?v=1720958128",
            "/images/products/Fondness-VII-1c.jpg?v=1720958128"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 521,
        "name": "Minnie",
        "handle": "minnie",
        "description": "Zanurz się w urzekającym pięknie naszego bukietu składającego się z harmonijnej mieszanki czerwonych i fioletowych róż, ozdobionych kapryśnym urokiem tęczowego oddechu dziecka. Bogate odcienie czerwieni i królewskiego fioletu tworzą uderzający kontrast wizualny, a żywy oddech dziecka dodaje czarującego akcentu. Idealny do wyrażenia miłości, podziwu lub świętowania specjalnych okazji, ten bukiet jest żywym i eleganckim symbolem uczuć.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/WhatsAppImage2021-12-23at2.28.30PM_2.jpg?v=1641313323",
            "/images/products/WhatsAppImage2021-12-23at2.28.30PM_1_1.jpg?v=1641313316"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*40cm"
    },
    {
        "id": 522,
        "name": "Jolyne",
        "handle": "jolyne",
        "description": "Ta urocza, zachowana kompozycja przedstawia delikatne niebieskie róże, delikatne kwiaty bawełny i hortensje z kości słoniowej, pomysłowo uzupełnione suszonymi wypełniaczami i oddechem dziecka. Umieszczony w niewielkim szklanym słoiczku i ozdobiony satynową wstążką, oferuje ponadczasową nutę elegancji. Idealny do dodania uspokajającego, wiecznego piękna do każdej przestrzeni lub specjalnej okazji.",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jolyne-2a.jpg?v=1727140775",
            "/images/products/Jolyne-1a.jpg?v=1727140775"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*20cm"
    },
    {
        "id": 523,
        "name": "Suzi",
        "handle": "suzi",
        "description": "Ta czarująca, zachowana kompozycja składa się z delikatnych różowych róż, delikatnych kwiatów bawełny i eleganckich hortensji, uzupełnionych subtelnymi suszonymi akcentami w odcieniach kremu i różu. Zamknięty w szklanym słoiczku i przewiązany różową satynową wstążką emanuje romantycznym i ponadczasowym urokiem. Idealny do dodania odrobiny delikatnej elegancji do każdego pomieszczenia lub okazji.",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Suzi-2a.jpg?v=1727142097",
            "/images/products/Suzi-1a.jpg?v=1727142098"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*20cm"
    },
    {
        "id": 524,
        "name": "Ermesa",
        "handle": "ermes",
        "description": "Ta delikatnie zachowana kompozycja składa się z miękkich beżowych róż, puszystych bawełnianych kwiatów i suszonych kwiatów w neutralnej tonacji, tworząc spokojną i ponadczasową estetykę. Umieszczony w przezroczystym szklanym słoju i akcentowany subtelną białą wstążką emanuje atmosferą spokojnego wyrafinowania. Idealny wybór dla osób poszukujących trwałego akcentu naturalnej elegancji w swojej przestrzeni.",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ermes-2a.jpg?v=1727141686",
            "/images/products/Ermes-1a.jpg?v=1727141686"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*20cm"
    },
    {
        "id": 525,
        "name": "Lisa",
        "handle": "lisa",
        "description": "Ta czarująca, zachowana kompozycja składa się z delikatnych różowych róż, delikatnych kwiatów bawełny i eleganckich hortensji, uzupełnionych subtelnymi suszonymi akcentami w odcieniach kremu i różu. Zamknięty w szklanym słoiczku i przewiązany różową satynową wstążką emanuje romantycznym i ponadczasowym urokiem. Idealny do dodania odrobiny delikatnej elegancji do każdego pomieszczenia lub okazji.",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lisa-2a.jpg?v=1727141883",
            "/images/products/Lisa-1a.jpg?v=1727141883"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*20cm"
    },
    {
        "id": 526,
        "name": "Trish",
        "handle": "trish",
        "description": "„Wiele życiowych porażek to ludzie, którzy się poddali, nie zdając sobie sprawy, jak blisko byli sukcesu”. – Thomasa A. Edisona",
        "type": "Bukiety",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-01-04at11.05.09AM_2.jpg?v=1641303840",
            "/images/products/WhatsAppImage2022-01-04at11.05.09AM_1_1.jpg?v=1641303834"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 527,
        "name": "Nena",
        "handle": "nena",
        "description": "Poczuj wieczny urok naszych zakonserwowanych czerwonych i różowych róż przeplatanych delikatnym gossypium w tym urzekającym bukiecie. Kwiaty te, starannie konserwowane, zachowują swoje naturalne piękno przez czas nieokreślony, symbolizując trwałą miłość i ponadczasową elegancję. ",
        "type": "Bukiety",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Nena-1aa_6b5ab146-a350-4999-b888-f701dbb577b9.jpg?v=1704334063",
            "/images/products/Nena-2aa_a1166a21-f646-4210-b54f-54f9c6b78794.jpg?v=1704334064"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 528,
        "name": "Gloria",
        "handle": "gloria",
        "description": "Ten bukiet, będący spokojną aranżacją Flowers Poland, łączy w sobie kremowe róże i miękkie bawełniane gałązki z subtelną zielenią, owinięty delikatnym żółtym papierem i wstążką, przywołując kojącą, marzycielską atmosferę.",
        "type": "Bukiety",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-01-04at11.05.27AM_2.jpg?v=1641319201",
            "/images/products/WhatsAppImage2022-01-04at11.05.27AM_1_1.jpg?v=1641319195"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 529,
        "name": "Cukier",
        "handle": "sugar",
        "description": "Zanurz się w wiecznej radości naszego wiecznie zachowanego bukietu tęczowej róży i tęczowego gossypium. Ta tętniąca życiem aranżacja oddaje spektrum kolorów, starannie zachowanych, aby zachować ich żywe piękno przez czas nieokreślony. ",
        "type": "Bukiety",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Sugar-1bb_e901f550-09ce-4a40-b613-b0c8bb8f6815.jpg?v=1704724654",
            "/images/products/Sugar-2bb_3b1f2fe0-534b-45dc-8699-9ec9585888c9.jpg?v=1704724654"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 530,
        "name": "Irena",
        "handle": "irene",
        "description": "Ten elegancki bukiet składa się z delikatnej mieszanki delikatnych różowych róż, pięknie zaakcentowanych bujną zielenią i kaspiami. Zapakowany w pastelowy różowy papier i przewiązany wstążką, jest idealnym prezentem, aby wyrazić miłość i uznanie na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Irene-2a.jpg?v=1720747298",
            "/images/products/Irene-1a.jpg?v=1720747298"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 531,
        "name": "Donatella",
        "handle": "donatella",
        "description": "Ten piękny bukiet składa się z oszałamiającej mieszanki czerwonych i różowych róż, elegancko uzupełnionych delikatnymi białymi i różowymi kwiatami oraz bujną zielenią. Kompozycja jest owinięta miękkim, biało-różowym papierem, przewiązanym pastelową różową wstążką, tworząc romantyczną i wyrafinowaną prezentację, emanującą elegancją i urokiem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Donatella-2a.jpg?v=1721057421",
            "/images/products/Donatella-1a-699430.jpg?v=1721135914"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 532,
        "name": "Huat I",
        "handle": "huat-i",
        "description": "Pełen wdzięku orchidea Phalaenopsis, ozdobiona delikatnymi kwiatami przypominającymi motyle, elegancko ułożona w olśniewającej różowej doniczce. Ten symbol piękna i wyrafinowania w zestawieniu z bogactwem różu tworzy idealny element centralny obchodów chińskiego Nowego Roku, wnosząc dobrobyt, szczęście i odrobinę naturalnej elegancji do świątecznego wystroju.",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-I-1a-277635.jpg?v=1705155937",
            "/images/products/Huat-I-2a-148008.jpg?v=1705155937"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidea Phalaenopsis"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*35cm"
    },
    {
        "id": 533,
        "name": "Huat II",
        "handle": "huat-ii",
        "description": "Wykwintna orchidea Phalaenopsis, symbol piękna i wyrafinowania, zdobi elegancką doniczkę, dodając odrobinę wyrafinowania obchodom Chińskiego Nowego Roku. Bujne kwiaty i elegancki wygląd tworzą harmonijny element centralny, symbolizujący dobrobyt i szczęście, gdy witasz Księżycowy Nowy Rok ze stylem i wdziękiem.",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-II-1a-131663.jpg?v=1705155937",
            "/images/products/Huat-II-2a-819203.jpg?v=1705155937"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidea Phalaenopsis"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*35cm"
    },
    {
        "id": 534,
        "name": "Huat III",
        "handle": "huat-iii",
        "description": "Świętuj Chiński Nowy Rok z oszałamiającą kompozycją kwiatową z żywymi chryzantemami i goździkami, symbolizującymi długowieczność i szczęście. Pełen wdzięku wierzby kiczowate poprawiają wygląd, oznaczając wzrost i odporność. Złoty wachlarz dodaje elegancji i reprezentuje dobrobyt. Taka aranżacja wnosi harmonijne połączenie tradycji i piękna do świątecznego wystroju.",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-III-1b-425360.jpg?v=1705155938",
            "/images/products/Huat-III-2b-736416.jpg?v=1705155938"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Goździki. Cipki Wierzby. Złoty Wachlarz"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*35cm"
    },
    {
        "id": 535,
        "name": "Huat IV",
        "handle": "huat-iv",
        "description": "Świętuj Chiński Nowy Rok z oszałamiającą kompozycją kwiatową z żywymi chryzantemami i goździkami, symbolizującymi długowieczność i szczęście. Pełen wdzięku wierzby kiczowate poprawiają wygląd, oznaczając wzrost i odporność. Złoty wachlarz dodaje elegancji i reprezentuje dobrobyt. Taka aranżacja wnosi harmonijne połączenie tradycji i piękna do świątecznego wystroju.",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-IV-1a-329496.jpg?v=1705155937",
            "/images/products/Huat-IV-2a-532205.jpg?v=1705155937"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Goździki. Cipki Wierzby. Złoty Wachlarz"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*35cm"
    },
    {
        "id": 536,
        "name": "Huat W",
        "handle": "huat-v",
        "description": "Świętuj Chiński Nowy Rok z naszą aranżacją symbolizującą wieczny dobrobyt. Bogate czerwienie i promienne złoto łączą się w harmonijnym tańcu, ozdobione pomyślnymi akcentami, które przyniosą szczęście Twojemu domowi.",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-_V_-1b-497304.jpg?v=1706716479",
            "/images/products/Huat-_V_-2b-588528.jpg?v=1706716479"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Cipka Willow. Zachowana hortensja"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*40cm"
    },
    {
        "id": 537,
        "name": "Kochanie",
        "handle": "sweetheart",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-01-08at12.02.52PM_2.jpg?v=1641881635",
            "/images/products/WhatsAppImage2022-01-08at12.02.52PM_1_1.jpg?v=1641881629"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 róż."
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 538,
        "name": "99 róż",
        "handle": "99-roses",
        "description": "Rozkoszuj się wspaniałością naszego ekstrawaganckiego bukietu 99 czerwonych róż Zapierająca dech w piersiach kompozycja 99 ciemnoczerwonych róż otoczonych delikatnym, białym oddechem dziecka. Idealny na wielkie uroczystości, rocznice lub jako wystawny wyraz miłości, ten bukiet jest oszałamiającym uosobieniem ponadczasowego piękna i romantyzmu. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [],
        "price": "912.57",
        "inclTax": "912.57",
        "img": [
            "/images/products/99-Roses-With-Love-156077.jpg?v=1671684749"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "99 róż. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 50cm*50cm"
    },
    {
        "id": 539,
        "name": "Elwa",
        "handle": "elva",
        "description": "Świętuj z elegancją, korzystając z naszego oszałamiającego bukietu hortensji i róż. Ta kompozycja, ozdobiona piękną różową hortensją pośrodku, otoczona delikatnymi żółtymi i rumianymi różami, delikatnymi goździkami i bujną zielenią, jest owinięta pięknym różowym papierem. Idealny na urodziny, rocznice lub inną specjalną okazję, dodaje odrobiny wdzięku i uroku każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Elva-2a.jpg?v=1721271101",
            "/images/products/Elva-1a.jpg?v=1721271101"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Eustoma. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 540,
        "name": "Ronda",
        "handle": "ronda",
        "description": "„A teraz pozostają te trzy: wiara, nadzieja i miłość. Ale z nich największa jest miłość.” -  Anonimowy",
        "type": "Flower Baskets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-03-04at11.27.13AM_1_8a9abe77-b647-4858-b83e-4fc30ef4a66c.jpg?v=1646364490",
            "/images/products/WhatsAppImage2022-03-04at11.16.44AM_1_1.jpg?v=1646364490"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 541,
        "name": "Mikaela",
        "handle": "mikaela",
        "description": "„A teraz pozostają te trzy: wiara, nadzieja i miłość. Ale z nich największa jest miłość.” -  Anonimowy",
        "type": "Flower Baskets",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-03-04at11.25.41AM_1.jpg?v=1646364367",
            "/images/products/WhatsAppImage2022-03-04at11.16.44AM_2.jpg?v=1646364367"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 542,
        "name": "Natasza BBS",
        "handle": "natasha",
        "description": "Ta urocza kompozycja kwiatowa jest elegancko zaprezentowana w eleganckim różowym pudełku ozdobionym jaskrawą różową wstążką. Zawiera uderzające połączenie odważnej czerwonej róży, delikatnych róż i delikatnych fioletowych kwiatów, otoczonych bujną zielenią. Idealna na prezent, ta aranżacja doda każdemu wnętrzu odrobinę wyrafinowania i uroku.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Natasha-BBS-2a-551465.jpg?v=1721658056",
            "/images/products/Natasha-BBS-1a-326569.jpg?v=1721658056"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Matthiola.Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 543,
        "name": "MacKenziego",
        "handle": "mackenzie",
        "description": "Poczuj żywe piękno naszej wielobarwnej kolekcji tulipanów. Te tulipany, mieniące się kalejdoskopem barw, ożywią każdą okazję. Każda łodyga jest arcydziełem, tworząc oszałamiający bukiet, który symbolizuje radość, różnorodność i cuda natury. Wzbogać swoje doznania kwiatowe dzięki naszym hipnotyzującym tulipanom.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-03-04at1.24.37PM_2.jpg?v=1646373068",
            "/images/products/WhatsAppImage2022-03-04at1.24.37PM_1_1.jpg?v=1646373068"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 544,
        "name": "Prietio",
        "handle": "prietio",
        "description": "Ten bukiet przedstawia żywą kompozycję jasnożółtych tulipanów, których każdy kwiat jest wysoki i emanuje radosną energią. Tulipany są pięknie owinięte w śnieżnobiały papier i ozdobione pasującą żółtą wstążką, tworząc czystą i świeżą prezentację, która emanuje pozytywnością i radością.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Prietio-2a-342712.jpg?v=1721136345",
            "/images/products/Prietio-1a.jpg?v=1721057662"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 545,
        "name": "Rousey'a",
        "handle": "rousey",
        "description": "Ten uroczy bukiet łączy w sobie piękno różowych gerber, białych eustom i wielu innych świeżych kwiatów. Zapakowany w delikatny biały papier, emanuje miękkością i elegancją, idealny na każdą wyjątkową okazję. Harmonijne połączenie kolorów i kwiatów sprawia, że ​​jest to wspaniały prezent, który pozwala wyrazić ciepło i uczucie.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Rousey-2a.jpg?v=1721361473",
            "/images/products/Rousey-1a.jpg?v=1721361474"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera.Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 546,
        "name": "Sonia",
        "handle": "sonja",
        "description": "Ten wesoły bukiet składa się z mieszanki kolorowych kwiatów, w tym czerwonych tulipanów, żółtych goździków, pomarańczowych róż i fioletowych kwiatów, uzupełnionych delikatną zielenią i białymi stokrotkami. Zapakowany w delikatny różowy i zielony papier i przewiązany białą wstążką, ożywi każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Sonja-2a.jpg?v=1721145542",
            "/images/products/Sonja-1a.jpg?v=1721145542"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Tulipan. Goździki. Stokrotka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 547,
        "name": "Mariona",
        "handle": "marion",
        "description": "Ten zachwycający bukiet składa się z mieszanki żywych żółtych, delikatnych różów i odważnych czerwonych kwiatów, w tym chryzantem, tulipanów i róż. Zaakcentowany delikatną zielenią i owinięty w pastelowy fioletowy papier, wnosi wesoły i świeży akcent do każdego otoczenia, idealny do świętowania radosnych okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Marion-2a.jpg?v=1721367489",
            "/images/products/Marion-1a.jpg?v=1721367489"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera.Spryskaj róże. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 548,
        "name": "Peggy",
        "handle": "peggy",
        "description": "Ten wesoły bukiet składa się z żywego słonecznika, jasnożółtych tulipanów i eleganckich białych róż, akcentowanych bujną zielenią i delikatnymi wypełniaczami. Zapakowany w delikatny różowo-beżowy papier i przewiązany wstążką będzie idealnym prezentem, który umili każdy dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na zdrowie i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Peggy-2a.jpg?v=1720780669",
            "/images/products/Peggy-1a.jpg?v=1720780669"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róża. Eustomy . Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 549,
        "name": "Billyna",
        "handle": "billyn",
        "description": "Ten bukiet to żywa i kolorowa kompozycja zawierająca różnorodne kwiaty. Jasnożółte róże, fioletowe tulipany, różowe kwiaty i małe białe stokrotki tworzą żywy i wesoły obraz. Bukiet owinięty jest w delikatny lawendowy papier z pasującymi wstążkami, co dodaje mu eleganckiego i odświętnego wyglądu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Billyn-2a.jpg?v=1721058175",
            "/images/products/Billyn-1a.jpg?v=1721058175"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Tulipan. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 550,
        "name": "Klaudia FBS",
        "handle": "claudia",
        "description": "Ta efektowna kompozycja zawiera odważne róże w kolorze fuksji otoczone delikatnym oddechem dziecka, tworząc oszałamiający kontrast. Zapakowany w elegancką brązową torebkę z pasującą wstążką w kolorze fuksji, jest idealnym prezentem, który zrobi trwałe wrażenie i sprawi radość na każdą okazję.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Claudia-FBS-2b.jpg?v=1721694283",
            "/images/products/Claudia-FBS-1b.jpg?v=1721694283"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 551,
        "name": "Korin FBS",
        "handle": "corin",
        "description": "„Życie nie polega na odnalezieniu siebie. Życie polega na tworzeniu siebie.”  – George’a Bernarda Shawa",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-03-17at2.42.46PM.jpg?v=1647499483",
            "/images/products/WhatsAppImage2022-03-17at2.42.46PM_1.jpg?v=1647499483"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 552,
        "name": "Współczucie (VII)",
        "handle": "sympathy-vii",
        "description": "W wielkim wyrazie współczucia nasze duże Stojaki z kwiatami kondolencyjnymi prezentują bogatą kompozycję czystych białych i żółtych chryzantem, niebieskich sztucznych hortensji i delikatnych lilii. Ten bogaty zespół stanowi godny i pocieszający hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "682.32",
        "inclTax": "682.32",
        "img": [
            "/images/products/Sympathy-_VII_-2c.jpg?v=1727186307",
            "/images/products/Condolence-Card-2024_1_0a9d2e95-ea3f-4344-8876-79bcb47d2631.jpg?v=1720190387",
            "/images/products/Sympathy-_VII_-1c.jpg?v=1727186308"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbery . Chryzantema. Lilie . Sztuczne kwiaty. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 553,
        "name": "Miranda",
        "handle": "miranda",
        "description": "Obejmij spokój naszego lawendowo-białego bukietu. Ta piękna kompozycja zawiera miękkie lawendowe róże, nieskazitelne białe kwiaty i delikatne fioletowe akcenty, uzupełnione bujną zielenią. Zapakowany w elegancki biały papier i przewiązany satynową wstążką, idealnie nadaje się na wesela, rocznice lub każdą okazję, podczas której chcesz przekazać spokój i wdzięk.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Miranda-2a.jpg?v=1721225048",
            "/images/products/Miranda-1a.jpg?v=1721225048"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 554,
        "name": "Nariya",
        "handle": "nariya",
        "description": "„A teraz pozostają te trzy: wiara, nadzieja i miłość. Ale z nich największa jest miłość.” -  Anonimowy",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-03-23at2.19.08PM_1.jpg?v=1648016392",
            "/images/products/WhatsAppImage2022-03-23at9.53.48AM_3_1.jpg?v=1648016392"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Eustoma. .Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 555,
        "name": "Tyryjski FBS",
        "handle": "tyrian",
        "description": "Ta seria torebek przedstawia delikatną lawendową różę otoczoną delikatnymi fioletowymi hortensjami i bujnymi liśćmi. Kwiatowy zestaw podkreślają dwie zabawne bawełniane pufy, które dodają fantazyjnego akcentu temu wyrafinowanemu projektowi.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Tyrian-FBS-2a.jpg?v=1723683220",
            "/images/products/Tyrian-FBS-1a.jpg?v=1723683220"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowany goździk. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 556,
        "name": "Fortuna (II)",
        "handle": "fortune-ii",
        "description": "Poczuj urok Scarlet Harmony dzięki naszemu wielkiemu otwieranemu statywowi. Ognista elegancja kwiatów imbiru, bogatych czerwonych chryzantem i efektownego czerwonego Anthurium łączą się w triumfalnym pokazie, tworząc oszałamiający punkt centralny, który ucieleśnia pasję, dobrobyt i obietnicę sukcesu.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Fortune-_II_-1d.jpg?v=1720093479",
            "/images/products/Grand-Opening-Card-2024_834cb499-9555-4478-a829-f99008eda531.jpg?v=1720093484",
            "/images/products/Fortune-_II_-sd.jpg?v=1720093479"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 557,
        "name": "Empatia (VII)",
        "handle": "empathy-vii",
        "description": "Wyrażając sympatię do elegancji, nasze Stojaki z kwiatami kondolencyjnymi prezentują pogodne białe chryzantemy i czysto białe eustomy, ozdobione gustowną gamą listew przypodłogowych z motywem bieli i błękitu. Ta pełna wdzięku aranżacja zapewnia pocieszenie i godny hołd w chwilach smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Empathy-_VII_-1d.jpg?v=1720189542",
            "/images/products/Condolence-Card-2024_1_29489b1c-ca03-4e34-a801-4af1cbed9be5.jpg?v=1720189553",
            "/images/products/Empathy-_VII_-2d.jpg?v=1720189541"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Milczący. Eustoma. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 100cm*180cm"
    },
    {
        "id": 558,
        "name": "Arika",
        "handle": "arica",
        "description": "Ten czarujący bukiet zawiera jasny słonecznik otoczony delikatnym rumiankiem i dziecięcym oddechem, zaakcentowany pluszowym misiem z podziałką. Zapakowany w wesoły papier w kratkę i przewiązany wstążką, będzie wspaniałym prezentem z okazji celebrowania osiągnięć akademickich.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Arica-2a-288522.jpg?v=1720788381",
            "/images/products/Arica-1a-848508.jpg?v=1720788381"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Stokrotka. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 559,
        "name": "Allyssa",
        "handle": "allyssa",
        "description": "Świętuj sukces dzięki naszemu bukietowi kwiatowemu na zakończenie szkoły. Ten bukiet łączy w sobie radość jasnego słonecznika ze spokojem niebieskich hortensji i delikatnym urokiem róż w sprayu w kolorze szampana. Wyżej wznosi się pluszowy miś w czapce dyplomowej, symbolizujący dumę z osiągnięć akademickich. Zaaranżowany wśród bujnej zieleni i subtelnych kwiatów stokrotek tworzy tętniącą życiem celebrację sukcesu. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Allyssa-2b-879328.jpg?v=1720788379",
            "/images/products/Allyssa-1b-562499.jpg?v=1720788379"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Stokrotka. Hortensja. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 560,
        "name": "Hillion",
        "handle": "hillion",
        "description": "„Kochający ludzie żyją w kochającym świecie. Wrodzy ludzie żyją we wrogim świecie. Ten sam świat. - Wayne’a Dyera",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-04-10at12.20.42PM_2.jpg?v=1649564692",
            "/images/products/WhatsAppImage2022-04-10at12.20.42PM_1_1.jpg?v=1649564692"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Eustoma. Mattiola. Srebrna Brunia. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 561,
        "name": "Judyta BBS",
        "handle": "judy",
        "description": "To luksusowe pudełko na kwiaty od Flowers Poland to gobelin o kwiatowej elegancji, przedstawiający róże w kolorze liliowym, majestatyczne fioletowe kwiaty i efektowną różową lilię. Komplet znajduje się w stylowym kremowym pudełku z rączką, ozdobionym lawendową satynową wstążką, idealnym na każdą wielką okazję.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Judy-BBS-2c.jpg?v=1721615574",
            "/images/products/Judy-BBS-1c.jpg?v=1721615574"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilia. Róża. Spryskaj róże. Goździki. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 563,
        "name": "Wielkanoc",
        "handle": "easter",
        "description": "Świętuj z żywym urokiem naszego bukietu tulipanów. Ta kompozycja, zawierająca uroczą mieszankę różowych i fioletowych tulipanów, akcentowana fantazyjnymi ozdobnymi kulkami, jest owinięta w elegancki biały papier i przewiązana różową satynową wstążką. Idealny na urodziny, rocznice lub inne radosne okazje, wnosi eksplozję koloru i szczęścia do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Easter-2a.jpg?v=1721274100",
            "/images/products/Easter-1a.jpg?v=1721274100"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 564,
        "name": "Nami",
        "handle": "nami",
        "description": "Ten promienny bukiet przedstawia asortyment żywych goździków w kolorze czerwonym, różowym, żółtym i białym, pięknie skomponowanych z bujną zielenią. Zapakowany w opalizujący i biały papier emanuje żywym i świątecznym urokiem, idealny do celebrowania wyjątkowych chwil lub wnoszenia radości do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Nami-2a-281883.jpg?v=1721222927",
            "/images/products/Nami-1a.jpg?v=1721145763"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 565,
        "name": "Alana",
        "handle": "alana",
        "description": "Złóż odważne oświadczenie dzięki naszej czarującej kopule z 60 czerwonych róż Czarująca kopuła z 60 żywych czerwonych róż, otoczona koronką z łyszczec, stanowi odważne wyznanie pasji i piękna. Bukiet, zawinięty w klasyczny papier w kolorze kości słoniowej ze złotymi akcentami, jest ponadczasowym wyrazem głębokiej miłości i podziwu. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/WhatsAppImage2022-04-13at12.01.10PM_1.jpg?v=1650336854",
            "/images/products/WhatsAppImage2022-04-13at11.17.58AM_2_1.jpg?v=1650336859"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "60 róż. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 50cm*50cm"
    },
    {
        "id": 566,
        "name": "Diament",
        "handle": "diamante",
        "description": "„Każdy może pokochać jakąś rzecz, ponieważ. To tak proste, jak włożenie grosza do kieszeni. Ale kochać coś pomimo. Znać wady i kochać je. To rzadkie, czyste i doskonałe”. - Patrick Rothfuss",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/WhatsAppImage2022-06-29at2.31.22PM_2.jpg?v=1656562907",
            "/images/products/WhatsAppImage2022-06-29at2.31.22PM_1_1.jpg?v=1656562907"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 567,
        "name": "Jane FBS",
        "handle": "jane",
        "description": "„Życie nie polega na odnalezieniu siebie. Życie polega na tworzeniu siebie.”  – George’a Bernarda Shawa",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/WhatsAppImage2022-04-20at10.39.25AM_3_1.jpg?v=1650422480",
            "/images/products/WhatsAppImage2022-04-20at10.39.25AM_2_1.jpg?v=1650422480"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 568,
        "name": "Buźka",
        "handle": "smiley",
        "description": "Ten uroczy bukiet składa się z jasnożółtych kwiatów pomponów, ułożonych w zachwycający wzór uśmiechniętej buźki, akcentowany delikatnymi białymi stokrotkami. Wesoła kompozycja jest owinięta białym papierem przewiązanym wstążką w kratkę, co czyni ją idealnym prezentem, który przyniesie radość i pozytywność.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Smiley-2a-586039.jpg?v=1721224012",
            "/images/products/Smiley-1a.jpg?v=1721173272"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Milczący"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 569,
        "name": "Mały palec u ręki",
        "handle": "pinkie",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/WhatsAppImage2022-04-23at12.28.06PM_1.jpg?v=1650688218",
            "/images/products/WhatsAppImage2022-04-23at12.25.29PM_1.jpg?v=1650688215"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Ohara i pachnące róże."
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 570,
        "name": "Mavis",
        "handle": "mavis",
        "description": "Urocza mieszanka różowych i różnorodnych goździków łączy się w tym ręcznie wykonanym koszyku Flowers Poland, ozdobionym żywą czerwoną wstążką dla odrobiny klasycznego romantyzmu. To słodki wyraz miłości i uznania.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety goździków"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Mavis-1b-162164.jpg?v=1714965776",
            "/images/products/Mavis-2b-738067.jpg?v=1714965776"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                ".  Goździki.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*30cm"
    },
    {
        "id": 571,
        "name": "Nikita",
        "handle": "nikita",
        "description": "Zachwyć się luksusową elegancją naszego bukietu składającego się z 30 czarujących fioletowych róż, wdzięcznie ozdobionych delikatnymi gałązkami oddechu dziecka. Królewskie fioletowe odcienie tworzą hipnotyzujący efekt wizualny, uzupełniony zwiewnym pięknem oddechu dziecka. Idealny do wyrażenia podziwu, świętowania kamieni milowych lub dodania odrobiny wyrafinowania każdej okazji, ten bukiet jest oszałamiającą celebracją wdzięku i piękna.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety róż"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Nikita-1a.jpg?v=1713355038",
            "/images/products/Nikita-2a-551224.jpg?v=1713490372"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "30 łodyg fioletowych róż. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*38cm"
    },
    {
        "id": 572,
        "name": "Jada",
        "handle": "baselyn",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-04-23at12.10.56PM_2.jpg?v=1650772313",
            "/images/products/WhatsAppImage2022-04-23at12.10.56PM_1_1.jpg?v=1650772313"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustoma. Goździki. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 573,
        "name": "Buźka II",
        "handle": "smiley-ii",
        "description": "Ten uroczy bukiet składa się z różowych pomponów ułożonych w okrąg z uroczą uśmiechniętą buźką pośrodku, otoczonych delikatnym białym oddechem dziecka. Kompozycja jest owinięta białym papierem i przewiązana różową wstążką w kratkę, co czyni ją idealnym prezentem, który ma szerzyć szczęście i radość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Smiley-II-2a.jpg?v=1721173444",
            "/images/products/Smiley-II-1a.jpg?v=1721173444"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Milczący"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 574,
        "name": "Maveena",
        "handle": "maveena",
        "description": "„Nie kocha się kogoś za jego wygląd, ubrania czy fantazyjny samochód, ale za to, że śpiewa piosenkę, którą tylko ty możesz usłyszeć”. - Oskar Wilde",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-04-24at12.09.05PM_2.jpg?v=1650773484",
            "/images/products/WhatsAppImage2022-04-24at12.09.05PM_1.jpg?v=1650773484"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera.Spryskaj róże. Eustoma. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 575,
        "name": "Sammy'ego",
        "handle": "sammy",
        "description": "„Najlepsza miłość to taka, która budzi duszę, która sprawia, że ​​sięgamy po więcej, która rozpala ogień w naszych sercach i przynosi spokój umysłom. To właśnie mam nadzieję dać Ci na zawsze”. - Notatnik",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-04-27at9.11.48AM_2.jpg?v=1651022102",
            "/images/products/WhatsAppImage2022-04-27at9.11.48AM_1_1.jpg?v=1651022104"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Goździki.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*30cm"
    },
    {
        "id": 576,
        "name": "Melo",
        "handle": "melo",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-04-27at9.11.58AM_3.jpg?v=1651023008",
            "/images/products/WhatsAppImage2022-04-27at9.11.58AM_2.jpg?v=1651023010"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*20cm"
    },
    {
        "id": 577,
        "name": "Mediolan",
        "handle": "milan",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-04-27at10.51.40AM_2.jpg?v=1651028045",
            "/images/products/WhatsAppImage2022-04-27at10.51.40AM_1_1.jpg?v=1651028045"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*20cm"
    },
    {
        "id": 578,
        "name": "Chantelle",
        "handle": "chantelle",
        "description": "Ta tętniąca życiem kompozycja od Flowers Poland przepełniona jest bogatymi czerwonymi goździkami, osadzonymi w ręcznie tkanym koszu. Ozdobiony sercem z napisem „I Love You” i satynową wstążką stanowi szczery wyraz miłości i oddania.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-05-06at5.04.16PM_2.jpg?v=1651827947",
            "/images/products/WhatsAppImage2022-05-06at5.04.16PM_1_1.jpg?v=1651827947"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 579,
        "name": "Joanna",
        "handle": "joanna",
        "description": "Uroczy zestaw kremowo-żółtych goździków od Flowers Poland, umieszczony w osobliwym tkanym koszyku, kwitnie rustykalną elegancją, uzupełniony krzepiącą ozdobą „I Love You” i delikatną wstążką, idealną na szczere chwile.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/WhatsAppImage2022-05-06at5.04.16PM_3_1.jpg?v=1651828029",
            "/images/products/WhatsAppImage2022-05-06at5.04.16PM_4_1.jpg?v=1651828029"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 580,
        "name": "Karol",
        "handle": "chaarles",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/WhatsAppImage2022-05-14at11.59.44AM_2.jpg?v=1652672069",
            "/images/products/WhatsAppImage2022-05-14at11.59.44AM_1_1.jpg?v=1652672070"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Ohara i pachnące róże."
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 581,
        "name": "Leanie",
        "handle": "leanie",
        "description": "Ten elegancki bukiet łączy w sobie białe róże i lisianthus z różowymi goździkami i żywymi różowymi tulipanami. Zieleń i biały papier do pakowania dodają nuty świeżości i wyrafinowania, dzięki czemu jest to idealny prezent na każdą okazję, która wymaga delikatnej i ponadczasowej kompozycji kwiatowej.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Leanie-2a.jpg?v=1721366737",
            "/images/products/Leanie-1a.jpg?v=1721366737"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 582,
        "name": "Winleya",
        "handle": "winley",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-02at8.05.57AM_2.jpg?v=1654480830",
            "/images/products/WhatsAppImage2022-06-02at8.05.57AM_1_1.jpg?v=1654480830"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Stokrotka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 583,
        "name": "Inessa",
        "handle": "inessa",
        "description": "„Czasami dwoje ludzi musi się rozstać, aby zdać sobie sprawę, jak bardzo muszą się ponownie połączyć.” - Anonimowy",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-06-06at2.26.30PM_1.jpg?v=1654496836",
            "/images/products/WhatsAppImage2022-05-31at1.02.22PM_2.jpg?v=1654496836"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Róże. Oddech dziecka.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 50cm*60cm"
    },
    {
        "id": 584,
        "name": "Vida",
        "handle": "lori",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Kosze korporacyjne"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-07at1.34.36PM_2.jpg?v=1654580324",
            "/images/products/WhatsAppImage2022-06-07at1.34.36PM_1_1.jpg?v=1654580325"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Spryskaj róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 585,
        "name": "Greinera",
        "handle": "greiner",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Kosze korporacyjne"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-07at1.34.29PM_2.jpg?v=1654580365",
            "/images/products/WhatsAppImage2022-06-07at1.34.29PM_1_1.jpg?v=1654580365"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Spryskaj róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 586,
        "name": "Rhesley'a",
        "handle": "rhesley",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/WhatsAppImage2022-06-13at2.23.03PM_2.jpg?v=1655101571",
            "/images/products/WhatsAppImage2022-06-13at2.23.03PM_1_1.jpg?v=1655101571"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Christa"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 587,
        "name": "Ayesha",
        "handle": "ayesha",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/WhatsAppImage2022-06-16at7.26.26PM_2.jpg?v=1655444674",
            "/images/products/WhatsAppImage2022-06-16at7.26.26PM_1_1.jpg?v=1655444679"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Christa"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 588,
        "name": "Larsona",
        "handle": "larson",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/WhatsAppImage2022-06-16at7.26.27PM_2.jpg?v=1655444802",
            "/images/products/WhatsAppImage2022-06-16at7.26.27PM_1_1.jpg?v=1655444804"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Christa"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 589,
        "name": "Marley",
        "handle": "marley",
        "description": "Poczuj spokojne piękno naszego bukietu niebieskiej hortensji. Z oszałamiającą niebieską hortensją pośrodku, uzupełnioną delikatnymi białymi i lawendowymi różami, uroczymi stokrotkami i bujną zielenią, ta kompozycja jest owinięta eleganckim białym papierem. Idealny na wesela, rocznice lub inne specjalne okazje, wnosi odrobinę spokoju i elegancji do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Marley-2a.jpg?v=1721351352",
            "/images/products/Marley-1a.jpg?v=1721351352"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Eustoma. Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 590,
        "name": "Bruna",
        "handle": "bruna",
        "description": "Zanurz się w blasku tego pomysłowo skomponowanego bukietu, w którym brzoskwiniowa róża ogrodowa, kremowe tulipany i żywe gałązki luksusowo mieszają się, owinięte w miękki, pastelowy papier i wykończone satynową wstążką – prawdziwa symfonia kwiatowej elegancji dla wymagającego wielbiciela.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-20at1.32.16PM_2.jpg?v=1655704826",
            "/images/products/WhatsAppImage2022-06-20at1.32.16PM_1_1.jpg?v=1655704827"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Róża ogrodowa. Tulipan. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 591,
        "name": "Igglion",
        "handle": "igglion",
        "description": "Obejmij urzekające piękno naszego bukietu składającego się z 22 wdzięcznych różowych róż. Każdy delikatny kwiat jest świadectwem piękna i delikatności, pomysłowo zaaranżowany tak, aby stworzyć urzekający zespół. Idealny do wyrażania uczuć, celebrowania radosnych chwil lub dodawania elegancji każdej przestrzeni, ten bukiet emanuje ciepłem i urokiem w delikatnych, różowych odcieniach.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Rapunzel-2a_60369545-1660-4ec0-86ae-d898cf2be975-340399.jpg?v=1721309887",
            "/images/products/Igglion-1a-181038.jpg?v=1721309887"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "22 róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*36cm"
    },
    {
        "id": 592,
        "name": "Antonela",
        "handle": "antonela",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "216.79",
        "inclTax": "216.79",
        "img": [
            "/images/products/WhatsAppImage2022-06-24at7.44.16AM_2.jpg?v=1656027985",
            "/images/products/WhatsAppImage2022-06-24at7.44.16AM_1_1.jpg?v=1656027985"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Eustoma. Gerbera . Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*35cm"
    },
    {
        "id": 593,
        "name": "Shayk",
        "handle": "shayk",
        "description": "Elegancki i wyrafinowany bukiet składa się z soczystych różowych tulipanów w połączeniu z chmurą łyszczec, znaną również jako oddech dziecka. Jest owinięty gradientowym różowym papierem, tworzącym piękny kontrast, i przewiązany długą, różową satynową wstążką.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Shayk-2b.jpg?v=1720662889",
            "/images/products/Shayk-1a.jpg?v=1720526011"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 594,
        "name": "Shelby",
        "handle": "shelby",
        "description": "„Każdy może pokochać jakąś rzecz, ponieważ. To tak proste, jak włożenie grosza do kieszeni. Ale kochać coś pomimo. Znać wady i kochać je. To rzadkie, czyste i doskonałe”. - Patrick Rothfuss",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-06-22at8.38.38AM.jpg?v=1656297412",
            "/images/products/WhatsAppImage2022-06-22at8.38.38AM_1.jpg?v=1656297413"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 595,
        "name": "Burgessa",
        "handle": "burgess",
        "description": "„Każdy może pokochać jakąś rzecz, ponieważ. To tak proste, jak włożenie grosza do kieszeni. Ale kochać coś pomimo. Znać wady i kochać je. To rzadkie, czyste i doskonałe”. - Patrick Rothfuss",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-06-22at8.38.23AM_1_1.jpg?v=1656297762",
            "/images/products/WhatsAppImage2022-06-22at8.38.23AM_2_1.jpg?v=1656297762"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 596,
        "name": "Ada",
        "handle": "ada",
        "description": "„Każdy może pokochać jakąś rzecz, ponieważ. To tak proste, jak włożenie grosza do kieszeni. Ale kochać coś pomimo. Znać wady i kochać je. To rzadkie, czyste i doskonałe”. - Patrick Rothfuss",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/WhatsAppImage2022-06-22at8.38.31AM_1_1.jpg?v=1656297562",
            "/images/products/WhatsAppImage2022-06-22at8.38.31AM_2_1.jpg?v=1656297564"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 597,
        "name": "Sakura",
        "handle": "sakura",
        "description": "„I ostatecznie miłość, którą otrzymujesz, jest równa miłości, którą kochasz”. - Paula McCartneya",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety z lilii calla",
            "Kwiaty i bukiety róż"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/WhatsAppImage2022-06-27at1.27.04PM.jpg?v=1656308487",
            "/images/products/WhatsAppImage2022-06-27at1.27.04PM_1.jpg?v=1656308487"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie kalii. Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 598,
        "name": "Ymir",
        "handle": "ymir-1",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-27at1.33.41PM_2.jpg?v=1656309149",
            "/images/products/WhatsAppImage2022-06-27at1.33.41PM_1_1.jpg?v=1656309149"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*35cm"
    },
    {
        "id": 599,
        "name": "Rozpoznać",
        "handle": "ken",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/WhatsAppImage2022-06-27at2.28.14PM_2.jpg?v=1656311780",
            "/images/products/WhatsAppImage2022-06-27at2.28.14PM_1_1.jpg?v=1656311780"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*35cm"
    },
    {
        "id": 600,
        "name": "Empatia (IX)",
        "handle": "empathy-ix",
        "description": "W uroczystym hołdzie nasze Stojaki z kwiatami kondolencyjnymi w uspokajającym fioletowym motywie ukazują pogodne piękno chryzantem, elegancję białych orchidei i ponadczasowy wdzięk sztucznych białych hortensji. Takie rozwiązanie zapewnia pocieszenie i współczucie, okazując współczucie w chwilach głębokiego smutku i wspomnień.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Empathy-_IX_-1d.jpg?v=1720156099",
            "/images/products/Condolence-Card-2024_1_3ff735d2-4e8b-48fb-8ccc-b6da38db9ade.jpg?v=1720156105",
            "/images/products/Empathy-_IX_-2d.jpg?v=1720156099"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Mamo. Sztuczne storczyki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 601,
        "name": "Karina FBS",
        "handle": "karin",
        "description": "Ta zachwycająca kompozycja zawiera delikatne różowe końcówki otoczone delikatnym oddechem dziecka, tworząc słodką i romantyczną kompozycję. Zapakowany w stylową brązową torebkę z pastelową różową wstążką, jest idealnym prezentem, aby rozjaśnić czyjś dzień i okazać swoje uczucia.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Karin-FBS-2b.jpg?v=1721782124",
            "/images/products/Karin-FBS-1b.jpg?v=1721782124"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 602,
        "name": "Elden",
        "handle": "elden",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/WhatsAppImage2022-06-29at10.12.59AM_2.jpg?v=1656570119",
            "/images/products/WhatsAppImage2022-06-29at10.12.59AM_1_1.jpg?v=1656570120"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Christa"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 604,
        "name": "Thara",
        "handle": "thara",
        "description": "„Czasami dwoje ludzi musi się rozstać, aby zdać sobie sprawę, jak bardzo muszą się ponownie połączyć.” - Anonimowy",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/WhatsAppImage2022-07-05at1.38.05PM_1.jpg?v=1657088867",
            "/images/products/WhatsAppImage2022-07-05at1.37.34PM_1.jpg?v=1657088867"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Róże.  Eustomy .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*35cm"
    },
    {
        "id": 605,
        "name": "Zeus",
        "handle": "zeus",
        "description": "Ten oszałamiający bukiet składa się z żywej mieszanki słoneczników, czerwonych i brzoskwiniowych róż oraz wesołych kwiatów pomarańczy, a wszystko to pięknie owinięte w miękki żółty papier. Zaakcentowana bujną zielenią aranżacja emanuje ciepłem i radością, dzięki czemu jest idealnym wyborem do celebrowania szczęśliwych chwil w życiu. Niezależnie od tego, czy jest to serdeczny prezent, czy też jasna ozdoba na środek stołu, ten bukiet wnosi słońce i szczęście na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Zeus-2a-304643.jpg?v=1724777098",
            "/images/products/Zeus-1a-264748.jpg?v=1724777098"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 45cm*55cm"
    },
    {
        "id": 606,
        "name": "Milion",
        "handle": "millie-1",
        "description": "Ten żywy bukiet składa się z wesołych słoneczników, delikatnych niebieskich róż i bujnej zieleni, podkreślonej przez uroczego misia w stroju na zakończenie szkoły. Zapakowany w stylowy niebiesko-biały papier i przewiązany wstążką, to idealny prezent z okazji ukończenia szkoły.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Million-2a.jpg?v=1720666401",
            "/images/products/Million-1a.jpg?v=1720666401"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 607,
        "name": "Pokój (ja)",
        "handle": "peace-i",
        "description": "W chwilach głębokiego współczucia nasze kwietniki elegancko prezentują ponurą paletę czarno-białych chryzantem, zharmonizowanych z szarymi liliami i orchideami. Ta wyrafinowana aranżacja składa hołd pełen szacunku, symbolizując współczucie i pamięć, zapewniając pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Peace-_I_-1d.jpg?v=1720181111",
            "/images/products/Condolence-Card-2024_1_c97d684e-7e28-4069-a96e-9043f8e23b61.jpg?v=1720181129",
            "/images/products/Peace-_I_-2d.jpg?v=1720181112"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Orchidee. Sztuczne falenopsisy. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*170cm"
    },
    {
        "id": 608,
        "name": "Harmonia (ja)",
        "handle": "harmony-i",
        "description": "Świętuj triumf dzięki naszemu stojakowi kwiatowemu Majestic Fusion z fioletową kapustą, żywymi pomarańczowymi różami i efektownym czerwonym anturium. Ta urzekająca mieszanka symbolizuje dobrobyt i entuzjazm, tworząc wielkie oświadczenie otwierające, które rezonuje z elegancją i sukcesem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_I_-1d.jpg?v=1720493859",
            "/images/products/Grand-Opening-Card-2024_4033b3c4-d584-4456-9669-dce9a875fb5f.jpg?v=1720493866",
            "/images/products/Harmony-_I_-2d.jpg?v=1720493859"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Róże. Anturium . Mamo. Sztuczne Delphinium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 609,
        "name": "Pokój (II)",
        "handle": "peace-ii",
        "description": "W wielkim wyrazie współczucia nasze duże Stojaki z kwiatami kondolencyjnymi prezentują bogatą kompozycję czystych białych chryzantem, gerber i delikatnych lilii. Ten bogaty zespół stanowi godny i pocieszający hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Peace-_II_-2d.jpg?v=1727191541",
            "/images/products/Condolence-Card-2024_1_d14792b0-266e-478e-b4cf-c6d1df2e9e06.jpg?v=1720191415",
            "/images/products/Peace-_II_-1d.jpg?v=1727191541"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Gerbera . Sztuczna lilia. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 610,
        "name": "Cyron",
        "handle": "cyron",
        "description": "„Każdy może pokochać jakąś rzecz, ponieważ. To tak proste, jak włożenie grosza do kieszeni. Ale kochać coś pomimo. Znać wady i kochać je. To rzadkie, czyste i doskonałe”. - Patrick Rothfuss",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/WhatsAppImage2022-07-25at2.50.47PM_2.jpg?v=1658731997",
            "/images/products/WhatsAppImage2022-07-25at2.50.47PM_1_1.jpg?v=1658731997"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 611,
        "name": "Pokój (III)",
        "handle": "peace-iii",
        "description": "W chwilach kondolencji nasze kwietniki delikatnie ozdobią czyste białe chryzantemy, podkreślając delikatną elegancję różowych hortensji i gerber. Ta delikatna aranżacja zapewnia pocieszenie, symbolizując współczucie i pamięć, zapewniając delikatne pocieszenie w chwilach smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Peace-_III_-1e.jpg?v=1720158493",
            "/images/products/Condolence-Card-2024_1_2f9f7d12-ed38-4351-90af-42f60963cc56.jpg?v=1720158501",
            "/images/products/Peace-_III_-2e.jpg?v=1720158492"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Gerbera . Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 612,
        "name": "Nikola",
        "handle": "nicola",
        "description": "Ten efektowny bukiet składa się z żywych czerwonych gerber, pięknie otoczonych delikatnym, białym oddechem dziecka. Zapakowana w elegancki czerwono-biały papier i wykończona satynową wstążką, ta kompozycja jest idealna do wyrażenia miłości i podziwu przy każdej wyjątkowej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Nicola-2a.jpg?v=1721361939",
            "/images/products/Nicola-1a-823187.jpg?v=1721396913"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 613,
        "name": "Pokój (IV)",
        "handle": "peace-iv",
        "description": "W delikatnym wyrazie współczucia nasze Stojaki z kwiatami kondolencyjnymi przedstawiają spokojną mieszankę białych i niebieskich hortensji, którym z wdziękiem towarzyszą spokojne gerbery. Ta elegancka aranżacja zapewnia pocieszenie, symbolizując współczucie i pamięć w chwilach smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Peace-_IV_-1d.jpg?v=1720181979",
            "/images/products/Condolence-Card-2024_1_6780842f-a77c-4da8-8880-9a34ac5f5b4f.jpg?v=1720181992",
            "/images/products/Peace-_IV_-2d.jpg?v=1720181979"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Gerbera . Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 614,
        "name": "Ramiresa",
        "handle": "ramires",
        "description": "Ten żywy bukiet składa się z odważnych czerwonych róż w połączeniu z delikatnym różowym lisianthus i kremowymi różami. Akcenty czerwonych jagód dziurawca i bujnej zieleni dodają głębi i kontrastu, a delikatny beżowy papier spaja aranżację. Idealny do złożenia oświadczenia i rozjaśnienia każdej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Ramires-2a.jpg?v=1721367077",
            "/images/products/Ramires-1a.jpg?v=1721367077"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 615,
        "name": "Pokój (V)",
        "handle": "peace-v",
        "description": "Składając szczere kondolencje, nasze kwietniki ozdobione są czystymi białymi chryzantemami i gerberami ozdobionymi kojącą aranżacją z motywem zieleni. Ten spokojny zespół składa delikatny hołd, symbolizując współczucie i pamięć, zapewniając pocieszenie i komfort w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Peace-_V_-1d.jpg?v=1720189762",
            "/images/products/Condolence-Card-2024_1_6d3abf75-ada1-4fd4-a028-392f036c2202.jpg?v=1720189778",
            "/images/products/Peace-_V_-2d.jpg?v=1720189761"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Gerbera . Sztuczna lilia. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 616,
        "name": "Kaidon",
        "handle": "kaidon",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/WhatsAppImage2022-08-12at1.58.37PM_1.jpg?v=1660283959",
            "/images/products/WhatsAppImage2022-08-08at2.28.16PM_1_1.jpg?v=1660283959"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "25 łodyg róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*35cm"
    },
    {
        "id": 617,
        "name": "Rudzik",
        "handle": "ring",
        "description": "Oto wyrafinowany bukiet, który emanuje elegancją i delikatnością, idealny do wyrażania serdecznych uczuć. Ta oszałamiająca kompozycja składa się z delikatnych różowych róż, potarganych różowych goździków i delikatnego oddechu dziecka, podkreślonego świeżymi zielonymi liśćmi. Bukiet, zawinięty w przezroczysty, biało-różowy papier do pakowania z delikatną wstążką, emanuje ponadczasowym urokiem i wdziękiem, idealny na każdą specjalną okazję lub jako piękny element centralny.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Robin-2a.jpg?v=1721360788",
            "/images/products/Robin-1a-909029.jpg?v=1721397229"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Mattiola.  Eustomy . Hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 618,
        "name": "Ronny'ego",
        "handle": "ronny",
        "description": "Odkryj spokojną elegancję naszego niebiesko-białego bukietu hortensji. Ta oszałamiająca kompozycja składa się z mieszanki dziewiczych białych hortensji i delikatnych niebieskich róż, tworząc uspokajającą i wyrafinowaną estetykę. Idealny do wyrażenia współczucia, świętowania nowych początków lub po prostu dodania odrobiny spokoju do dowolnej przestrzeni, ten bukiet jest pięknie opakowany w miękki biały papier. Połączenie bujnych kwiatów i gustownego opakowania sprawia, że ​​ta aranżacja to wyjątkowy wybór na każdą okazję, emanująca wdziękiem i spokojem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty na powitanie noworodka",
            "Kwiaty i bukiety hortensji",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ronny-2a.jpg?v=1721359608",
            "/images/products/Ronny-1a.jpg?v=1721359608"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Róże. Delfin . Eustoma. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 619,
        "name": "Harmonia (II)",
        "handle": "harmony-ii",
        "description": "Stwórz harmonię w kwiatach dzięki naszemu stojakowi na kwiaty Jumbo. Wysokie słoneczniki, żywe gerbery, eleganckie lilie i bujne hortensje harmonizują, tworząc urzekający wizualnie i spójny element centralny wszystkich wielkich otwarć firm.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Harmony-_II_-1c.jpg?v=1720524925",
            "/images/products/Grand-Opening-Card-2024_171361c1-cc38-4013-bf28-97a039c8be75.jpg?v=1720524937",
            "/images/products/Harmony-_II_-2c.jpg?v=1720524925"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Gerbera . Sztuczna hortensja. Sztuczne storczyki Cymbidium. Rajskie ptaki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 620,
        "name": "Pomiń",
        "handle": "smiss",
        "description": "Ten czarujący bukiet składa się z dużej, bujnej fioletowej hortensji, pięknie otoczonej delikatnymi kwiatami rumianku. Zapakowany w miękki biały papier i przewiązany pasującą wstążką, jest idealnym prezentem na każdą okazję, emanującym urokiem i elegancją.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Smiss-2a.jpg?v=1720710817",
            "/images/products/Smiss-1a-475698.jpg?v=1720791364"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Stokrotka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 621,
        "name": "Raiden",
        "handle": "raiden",
        "description": "„O cokolwiek walczysz, idziesz z pełną pasją i miłością”. - Michelle Kwan",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-08-12at1.37.42PM_2.jpg?v=1660282814",
            "/images/products/WhatsAppImage2022-08-12at1.37.42PM_1_1.jpg?v=1660282816"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Piwonia . Spryskaj róże. Goździki. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*35cm"
    },
    {
        "id": 622,
        "name": "Hamilia",
        "handle": "hamilia",
        "description": "Zanurz się w delikatnej elegancji naszego pastelowego różowego bukietu. Ta kompozycja, ozdobiona delikatnymi różowymi różami i tulipanami, akcentowanymi akcentami niebieskich kwiatów i bujnego eukaliptusa, jest owinięta delikatnym różowym papierem. Idealny do wyrażania miłości, wdzięczności lub świętowania jakiejkolwiek specjalnej okazji, wnosi odrobinę wdzięku i piękna w każdą chwilę.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Hamilia-2a.jpg?v=1721264068",
            "/images/products/Hamilia-1a.jpg?v=1721264068"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 623,
        "name": "Pokój (VI)",
        "handle": "peace-vi",
        "description": "W wielkim wyrazie współczucia nasze duże Stojaki z kwiatami kondolencyjnymi prezentują bogatą kompozycję czystych białych chryzantem i delikatnych lilii. Ten bogaty zespół stanowi godny i pocieszający hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Peace-_VI_-3c.jpg?v=1727192703",
            "/images/products/Condolence-Card-2024_1_79ebe900-e033-4f1a-b1e1-e928694a1494.jpg?v=1720190129",
            "/images/products/Peace-_VI_-1c.jpg?v=1727192702"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Sztuczne lilie. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 624,
        "name": "Jeman",
        "handle": "yeman",
        "description": "Emanuj elegancją dzięki naszemu nieskazitelnemu bukietowi białych lilii. Ta kompozycja, ozdobiona oszałamiającymi białymi liliami otoczonymi bujną zielenią, jest owinięta miękkim beżowym papierem i przewiązana satynową wstążką. Idealny na wesela, rocznice lub inne specjalne okazje, wnosi odrobinę spokoju i wyrafinowania do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Bukiety lilii"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Yeman-2a.jpg?v=1721272696",
            "/images/products/Yeman-1a.jpg?v=1721272696"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość:30cm*36cm"
    },
    {
        "id": 625,
        "name": "Brendino",
        "handle": "brendino",
        "description": "Ten promienny bukiet składa się z żywych słoneczników i eleganckich białych lilii, akcentowanych bujną zielenią. Zapakowany w delikatny różowy papier i przewiązany delikatną wstążką, jest idealnym prezentem na każdą okazję, wnosząc ciepło i radość do każdego otoczenia.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Bukiety lilii",
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Brendino-2a-570688.jpg?v=1720788559",
            "/images/products/Brendino-1a-865529.jpg?v=1720788559"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Lilie . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość:30cm*36cm"
    },
    {
        "id": 626,
        "name": "Co",
        "handle": "whis",
        "description": "Ten zmysłowy bukiet z tęczowym oddechem dziecka spełni pragnienia Twojego serca tej jedynej..",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/WhatsAppImage2022-08-19at10.11.43AM_2.jpg?v=1660875526",
            "/images/products/WhatsAppImage2022-08-19at10.11.43AM_1_1.jpg?v=1660875527"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Bawełniane Kwiaty. Zachowane róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 627,
        "name": "Jolin",
        "handle": "jolin",
        "description": "Ten elegancki bukiet składa się z delikatnych różowych gerber i alstroemerii, akcentowanych bujną zielenią. Zapakowany w rustykalny brązowy papier i przewiązany delikatną różową wstążką, stanowi piękny prezent na każdą okazję, emanujący urokiem i świeżością.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jolin-2a.jpg?v=1720689552",
            "/images/products/Jolin-1a.jpg?v=1720689552"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 628,
        "name": "Teresa",
        "handle": "teresa",
        "description": "Ten wykwintny bukiet składa się z mieszanki delikatnych różowych goździków, białych tulipanów i eleganckich białych róż, uzupełnionych bujną zielenią. Zapakowana w delikatny biały papier i przewiązana pasującą wstążką, ta kompozycja jest idealna do świętowania ślubów, rocznic lub innych specjalnych okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Teresa-2a_1f9cb106-2aef-472c-ab19-87d994ed002f.jpg?v=1720887182",
            "/images/products/Teresa-1a_27452f3d-61ad-4d2d-b5e8-0b468267543c-628524.jpg?v=1720963729"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Tulipany. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 629,
        "name": "Inoshee",
        "handle": "inoshee",
        "description": "Ten delikatny i marzycielski bukiet składa się z połączenia pastelowych róż, niebieskich hortensji i miękkich stokrotek, a wszystko to elegancko zawinięte w opalizujący papier, który pięknie odbija światło. Delikatna paleta barw i urokliwa aranżacja sprawiają, że idealnie sprawdzi się na każdą wyjątkową okazję, dodając elegancji i wdzięku.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Hilton-2b_dc8faca6-f810-4ebb-8da8-f8a9d9309de7.jpg?v=1721541947",
            "/images/products/Innoshee-1a.jpg?v=1721541948"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździki. Eustoma. Hortensja. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*30cm"
    },
    {
        "id": 630,
        "name": "Kodomo",
        "handle": "kodomo",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [
            "Kosze korporacyjne"
        ],
        "price": "593.09",
        "inclTax": "593.09",
        "img": [
            "/images/products/WhatsAppImage2022-09-06at10.06.36AM_1.jpg?v=1662430220",
            "/images/products/WhatsAppImage2022-09-06at10.08.18AM_1.jpg?v=1662430222"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 631,
        "name": "Desiree BHP",
        "handle": "desiree-bhp",
        "description": "„To, co jesz, dosłownie staje się tobą. masz wybór, z czego jesteś wykonany. - Anonimowy",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Desiree-WHP-1_1_1c4ffb1c-62b2-4e77-b5a6-b2f299018f92.jpg?v=1663239980",
            "/images/products/Desiree-WHP-2_1_6633480e-cd8c-4b5c-8ab8-ca48241f007a.jpg?v=1663239980"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "Marki Bird Nest 42ml (6 butelek). Zestaw upominkowy dla dziecka Nożyczki. Kompozycja kwiatowa"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*30cm"
    },
    {
        "id": 632,
        "name": "Geneza BHP",
        "handle": "genesis-bhp",
        "description": "„Szczęście jest najwyższą formą zdrowia”. - Dalajlama",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Genesis-WHP-1_9976d6e1-1b7d-4e4f-b992-a33947049a3d-357835.jpg?v=1671685311",
            "/images/products/Genesis-WHP-2_13981bb1-0d9f-4ac8-a53b-0ed90104fc3e-264569.jpg?v=1671685312"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "Esencja z kurczaka HockHua 75 ml (6 butelek). Zestaw upominkowy dla dziecka Shears. Kompozycja kwiatowa"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*30cm"
    },
    {
        "id": 633,
        "name": "Nakarm go dobrze BHP",
        "handle": "rayson-bhp",
        "description": "Nakarm go dobrze Ten starannie zebrany kosz jest starannie wykonany z codziennych potrzeb, ubrań i zabawek. Z pewnością sprawi, że każde małe dziecko będzie szczęśliwe i wygodne przez cały dzień!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Feed-Him-Well2-909056.jpg?v=1708913557",
            "/images/products/Feed-Him-Well1-399914.jpg?v=1708913557"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy dla niemowląt K",
                "2. Talerz dla dziecka Dino",
                "3. Talerz z przyssawką Lucky Baby",
                "4. Widelec ssący Lucky Baby",
                "5. Zestaw Munchkin — zestaw dozowników mleka modyfikowanego",
                "6. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 634,
        "name": "Mały Pan Muffet BHP",
        "handle": "douglas-bhp",
        "description": "Mały Mr MuffetBaby nie potrzebuje tufta, aby cieszyć się przysmakami podczas posiłku, ponieważ dołączyliśmy przyssawkę i łatwe do uchwycenia miseczki, dzięki którym jedzenie pozostanie na swoim miejscu! Koszyczek dla niemowląt Little Mr Muffet, idealny dla dzieci odkrywających potrzebę samodzielnego karmienia i zabawy w przekąskach, to dopasowany kolorystycznie zestaw niezbędnych akcesoriów do posiłków, dzięki którym dziecko zawsze będzie mogło czerpać przyjemność z kulinarnej zabawy! ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Little-Mr-Muffet-BHP1.jpg?v=1722228897",
            "/images/products/Little-Mr-Muffet-BHP2.jpg?v=1722228897",
            "/images/products/Little-Mr-Muffet-BHP3.jpg?v=1722228896"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy dla niemowląt K",
                "2. Talerz dla dziecka Dino",
                "3. Płyn do mycia twarzy Fiffy 750ml",
                "4. Dozownik Lucky Baby Hippy Milk",
                "5. Niezbędnik Lucky Baby Kutter w podróży",
                "6. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 635,
        "name": "Książęcy Komfort i Opieka BHP",
        "handle": "thomas-bhp",
        "description": "Princely Comfort and Care Przygotuj się na królewską wygodę dzięki koszowi upominkowemu dla dziecka Princely Comfort and Care! Ten starannie dobrany prezent, zapakowany w wykwintne pudełko na tace, zawiera wysokiej jakości polarowy koc i organiczne myjki, a także towarzyszy do zabawy pasujących do książąt!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Thomas2-486931.jpg?v=1671685637",
            "/images/products/Thomas1-295050.jpg?v=1671685637"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Kocyk polarowy Baby K",
                "2. Myjka Baby K- Organic Bamboo 6 szt. w opakowaniu",
                "3. Munchkin – pakiet zbiorczy dozownika mleka Formula",
                "4. Bandana Buddies Taggies Lion",
                "5. Suszarki Wee Wee Dry Pieluchy rozmiar M (4szt)",
                "6. Pluszowy króliczek",
                "7. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 636,
        "name": "Idealny Książę Mamy BHP",
        "handle": "pierre-bhp",
        "description": "Mama's Perfect Prince Przywitaj się z nowonarodzonym chłopcem dzięki koszykowi prezentowemu dla dziecka Mum's Perfect Prince! Obejmuje to kocyki, które zapewnią dziecku najdelikatniejszy dotyk od pierwszego oddechu. Dziecko będzie także pocieszone drewnianym samochodzikiem BabyK i pluszowym króliczkiem, podczas gdy mama robi regenerujące zdjęcia ptasiego gniazda z żeń-szeniem i esencją jagodową.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Pierre5-282054.jpg?v=1671685494",
            "/images/products/Pierre1-629994.jpg?v=1671685494"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "2. Esencja Jagodowa Formec 70ml x 2",
                "3. Owen -2 szt. Koc odbiorczy",
                "4. Kocyk polarowy Baby K",
                "5. Drewniany samochód-zabawka Baby K",
                "6. Pluszowy króliczek",
                "7. Suszarki Wee Wee Dry Pieluszki rozmiar M (4szt)",
                "8. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 637,
        "name": "Nakarm ją dobrze BHP",
        "handle": "sharon-bhp",
        "description": "Nakarm ją dobrze Ten starannie zebrany kosz jest starannie wykonany z codziennych potrzeb, ubrań i zabawek. Z pewnością sprawi, że każde małe dziecko będzie szczęśliwe i wygodne przez cały dzień!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Feed-Her-Well-BHP1_ca907348-2626-4b4b-854e-f18b5b92ba0b-449377.jpg?v=1722357610",
            "/images/products/Feed-Her-Well-BHP2.jpg?v=1722227789",
            "/images/products/Feed-Her-Well-BHP3.jpg?v=1722227788"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy dla niemowląt K",
                "2. Talerz dla dziecka Dino",
                "3. Miska z przyssawką Lucky Baby Groomy z łyżeczką",
                "4. Lucky Baby Skoop Łyżki wrażliwe na ciepło 2szt",
                "5. Zestaw Munchkin — zestaw dozowników mleka modyfikowanego",
                "6. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 638,
        "name": "Mała Miss Muffet BHP",
        "handle": "yvonne-bhp",
        "description": "Little Miss MuffetBaby nie potrzebuje tufta, aby cieszyć się przysmakami podczas posiłku, ponieważ dołączyliśmy przyssawkę i łatwe do uchwycenia miseczki, dzięki którym jedzenie pozostanie na swoim miejscu! Koszyczek dla niemowląt Little Mrs Muffet, idealny dla dzieci odkrywających potrzebę samodzielnego karmienia i ciekawego podjadania, to dopasowany kolorystycznie zestaw niezbędnych akcesoriów do posiłków, dzięki którym dziecko zawsze będzie mogło czerpać przyjemność z kulinarnej zabawy! ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Little-Miss-Muffet-BHP1_465f9c24-5055-4460-be9f-42cf967011c5.jpg?v=1722228505",
            "/images/products/Little-Miss-Muffet-BHP2.jpg?v=1722228504",
            "/images/products/Little-Miss-Muffet-BHP3.jpg?v=1722228504"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy dla niemowląt K",
                "2. Talerz dla dziecka Dino",
                "3. Płyn do mycia twarzy Fiffy 750ml",
                "4. Dozownik Lucky Baby Hippy Milk",
                "5. Niezbędnik Lucky Baby Kutter w podróży",
                "6. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 639,
        "name": "Księżniczka Komfort i Opieka BHP",
        "handle": "britney-bhp",
        "description": "Princess Comfort and CarePrzygotuj się na królewską wygodę dzięki koszowi upominkowemu dla dziecka Princess Comfort and Care! Ten starannie dobrany prezent, zapakowany w wykwintne pudełko na tace, zawiera wysokiej jakości polarowy koc i organiczne myjki, a także towarzyszy do zabawy odpowiednich dla małej księżniczki!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Britney2-139579.jpg?v=1671685637",
            "/images/products/Britney1-202684.jpg?v=1671685637"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Kocyk polarowy Baby K",
                "2. Myjka Baby K- Organic Bamboo 6 szt. w opakowaniu",
                "3. Munchkin – pakiet zbiorczy dozownika mleka Formula",
                "4. Bandana Buddies Taggies Lion",
                "5. Suszarki Wee Wee Dry Pieluchy rozmiar M (4szt)",
                "6. Pluszowy króliczek",
                "7. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 640,
        "name": "Słoneczna dziewczyna BHP",
        "handle": "tiffany-bhp",
        "description": "Sunshine GirlKosz na prezent dla dziecka Sunshine Girl z pewnością ożywi każdego nowego rodzica i stylowo powita jego noworodka. Kosz dla noworodków, zapakowany w wykwintny kosz upominkowy, zawiera nie tylko artykuły dziecięce, takie jak hipoalergiczne chusteczki dla niemowląt i organiczne myjki bambusowe firmy BabyK, ale także ulubione ptasie gniazdo mamy z żeń-szeniem!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Sunshine-Girl-BHP1_358b63bb-8110-49a8-9747-4c25beffb225.jpg?v=1723438469",
            "/images/products/Sunshine-Girl-BHP2_2e07da7b-9b51-4753-95ae-17e943dff4c5.jpg?v=1723438469",
            "/images/products/Sunshine-Girl-BHP3.jpg?v=1723438470"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo z żeń-szeniem",
                "2. Zestaw upominkowy dla niemowląt K",
                "3. Fiffy Organic Nawilżający żel do mycia od stóp do głów 750ml",
                "4. Fiffy Organiczny balsam nawilżający 450ml",
                "5. Waciki Fiffy Mini 400 szt",
                "6. Myjka Baby K Organic Bamboo 6 szt. w opakowaniu",
                "7. Organiczne chusteczki dla niemowląt KinderNurture Lata 80",
                "8. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 641,
        "name": "Przekąski, och, Crumbly GHP",
        "handle": "crumbly-ghp",
        "description": "Przekąski och CrumblyCo to za święto bez pysznych smakołyków? The Snacks oh Crumbly Hamper zawiera kompilację ulubionych przekąsek, które zachwycą bliskich, współpracowników, a nawet sąsiadów!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne",
            "Kosze z jedzeniem halal"
        ],
        "price": "145.82",
        "inclTax": "145.82",
        "img": [
            "/images/products/Snacks-oh-Crumbly-GHP1-296263.jpg?v=1687215928",
            "/images/products/Snacks-oh-Crumbly-GHP2-399564.jpg?v=1687145560"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Golden Bon Bon Migdałowy Chrupiący Nugat Klonowy 70g",
                "2. Melvados Citrus Citrus Pistacjowe Biscotti 100g",
                "3. Vochelle Doypack 100g",
                "4. Amazin Graze Chleb Bananowy Chleb Granola 40g",
                "5. Ciasteczka maślane czekoladowo-czekoladowe Beryl 70g",
                "6. Gorące chipsy z tortilli misji",
                "7. Małgosia Kiełkująca Orzechy Nerkowca Naturalnie Pieczone 50g",
                "8. Ciasteczka maślane jagodowe K.Harrodson 70g",
                "9. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*30cm"
    },
    {
        "id": 642,
        "name": "Splendor GHP",
        "handle": "splendour-ghp",
        "description": "Splendor Najbardziej wszechstronny zestaw przekąsek, jaki kiedykolwiek znajdziesz, kosz na żywność dla smakoszy Splendor zachwyca smacznymi czekoladowymi smakołykami i chrupiącymi, smakowitymi orzechami. Idealne na popołudniową herbatę lub po prostu prezent, który zachwyci nawet najbardziej wybrednych przekąsek.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "157.23",
        "inclTax": "157.23",
        "img": [
            "/images/products/Splendour1.jpg?v=1701063148",
            "/images/products/Splendour2_0ff53832-9ce7-4f92-a80b-e333c7e5f503-453906.jpg?v=1701170506"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Excelcium - Praliny BonBons De Chocolate 200g",
                "2. Paluszki Cupido Ciemna Czekolada 125g",
                "3. Forum Belgijskie Ciasteczka Wafle 100g",
                "4. Złote Bon Bon",
                "5. Nugat migdałowy miękki 70g",
                "6. Truskawka Walker",
                "7. Nibbles Premium Mieszane Orzechy 45g",
                "8. Napój bezalkoholowy Bickfords Premium Australia",
                "9. Soda kremowa 275 ml",
                "10. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 643,
        "name": "Lavish traktuje GHP",
        "handle": "lavish-ghp",
        "description": "Wystawne smakołyki",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "157.23",
        "inclTax": "157.23",
        "img": [
            "/images/products/Lavish-Treats-GHP1.jpg?v=1707104670",
            "/images/products/Lavish-Treats-GHP2.jpg?v=1707104671"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Golden Bon Bon Nougat DO lub Miękki Nugat Migdałowo-Migdałowy w czekoladzie 70g",
                "2. Esprit Musujący Napój Bezalkoholowy Marakuja 300ml",
                "3. Mini Madeleine St Michel z kawałkami czekolady 75g",
                "4. Pastylki Droste Mleczne/Ciemne 85g",
                "5. Ciasteczka Fiorella Monte Classic z gorzką czekoladą 102g",
                "6. Nibbles Premium Natural Mieszane Orzechy 45g",
                "7. Kostka waflowa Jacker 100g",
                "8. Miniprzekąska Vicenzi z kremem orzechowym 75g",
                "9. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 644,
        "name": "Słodkie przysmaki GHP",
        "handle": "treats-ghp",
        "description": "Sweet TreatsKosz na przekąski Sweet Treats zawiera wybór najwyższej jakości nie tylko słodkich, ale także pikantnych przekąsek na te wyjątkowe romantyczne chwile. Jeśli szukasz dostawy koszyka z przekąskami, nie szukaj dalej! Nasz stylowo prezentowany pakiet przekąsek to jeden z najlepszych sposobów na przesłanie najsłodszych myśli!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Sweet-Treats-GHP1.jpg?v=1707106685",
            "/images/products/Sweet-Treats-GHP2.jpg?v=1707106685"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Golden Bon Bon Nougat DO lub Miękki Nugat Migdałowo-Migdałowy w czekoladzie 70g",
                "2. Esprit Musujący Napój Bezalkoholowy Marakuja 300ml",
                "3. Mini Madeleine St Michel z kawałkami czekolady 75g",
                "4. Fiorella Monte Ciasteczka Czekoladowe 102g",
                "5. Rossella Pouchette Fondant Frutta 45g",
                "6. Yava Krispi Ptysie Słodkie Orzechy Nerkowca 45g",
                "7. La Mere Poulard 1888 Les Sables Francuskie ciasteczka maślane 46,8 g",
                "8. Vochelle Migdałowy Doypack 100g",
                "9. Hunters Gourmet Cherry Tomato Olive Ręcznie Gotowane Chipsy Ziemniaczane 25g",
                "10. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 645,
        "name": "Zabawne niespodzianki GHP",
        "handle": "fun-ghp",
        "description": "Fun Surprises Doświadczenie kulinarne, które zostało stworzone, aby zaskoczyć i zachwycić odbiorcę, kosz na jedzenie dla smakoszy Fun Surprises z pewnością zrobi niezatarte wrażenie. Ten wyjątkowy prezent w postaci dobrego gustu obejmuje ulubioną grę karcianą UNO, która zapewnia zabawę gościom, którzy uwielbiają grać tak samo, jak uwielbiają przekąski.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "202.87",
        "inclTax": "202.87",
        "img": [
            "/images/products/Fun2-763367.jpg?v=1671685208",
            "/images/products/Fun1-552284.jpg?v=1671685208"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Wernli Truffet 100g",
                "2. Popcorn o smaku filmowym 30g",
                "3. Ciasteczka maślane jagodowe K.Harrodson 70g",
                "4. Chipsy Ziemniaczane Dijon Miodowe Kettle 56g",
                "5. Coca-Cola Classic Puszka 250ml x2",
                "6. Gra planszowa Szarady dla dzieci",
                "7. Gra karciana Uno",
                "8. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*30cm"
    },
    {
        "id": 646,
        "name": "Czekoladowy GHP",
        "handle": "chocolicious-ghp",
        "description": "ChocoliciousZamów najbardziej luksusowy kosz na czekoladę, który zachwyci poważnych czekoladoholików. Nasz kosz Chocolicious zapewni im szeroki wybór czekoladowych smakołyków, zaprojektowanych tak, aby zachwycać wyselekcjonowanym asortymentem przepysznych i wspaniałych przysmaków.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Chocolicious2.jpg?v=1664377933",
            "/images/products/Chocolicious1-747214.jpg?v=1671685022"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Excelcium. Praliny BonBons De Chocolat 200g",
                "2. Cupido Muszle Czekoladowe z Orzechami Laskowymi 250g",
                "3. Forum Hamlet Finest Wafel Crisp 100g",
                "4. Gorące Kakao Swiss Miss Dark Chocolate Sensation 283g",
                "5. Ciasteczka maślane jagodowe K.Harrodson 180g",
                "6. Napój bezalkoholowy Bickfords Premium Australia",
                "7. Cytryna Limonka 275ml",
                "8. Napój bezalkoholowy Bickfords Premium Australia",
                "9. Sarsaparilla 275ml",
                "10. Chipsy ziemniaczane Dijon z miodem czajnikowym 56g",
                "11. Vochelle Orzechy laskowe Doypack 100g",
                "12. Wykwintny kosz na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 647,
        "name": "Crunch, Fizz, Chill GHP",
        "handle": "crunch-ghp",
        "description": "Crunch, Fizz, Chill Idealny jako uniwersalny kosz piknikowy lub po prostu przekąska na każde dobre spotkanie, ten wyśmienity asortyment słodkich i pikantnych przekąsek to gastronomiczna rozkosz nawet dla najbardziej wybrednych przekąsek. Zmierzcie się ze sobą w grze o piciu alkoholu w Drunken Tower, aby zapewnić sobie dodatkową zabawę! Wypróbuj nasz koszyk dla smakoszy Crunch, Fizz, Chill, aby cieszyć się pełnią wrażeń z gier i przekąsek!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "225.69",
        "inclTax": "225.69",
        "img": [
            "/images/products/Crunch2.jpg?v=1664377954",
            "/images/products/Crunch1-110333.jpg?v=1671685197"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Oryginalny popcorn Movietimes 20g",
                "2. Chipsy serowe Fox Nacho 40g",
                "3. Piwo butelkowe Heineken 330g x3",
                "4. Bób palony Tai Sun 150g",
                "5. Vochelle Doypack 100g",
                "6. Orzechy nerkowca prażone w miodzie Tong Garden 40g",
                "7. Gra w pijacką wieżę",
                "8. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: Szerokość*Wysokość: 30cm*35cm"
    },
    {
        "id": 648,
        "name": "Słodycze GHP",
        "handle": "brunet-ghp",
        "description": "Sweet ToothPrezentujemy szereg wyśmienitych słodkich przysmaków i przysmaków zapewniających poważną przyjemność. Kosz na czekoladę Sweet Tooth to symfonia wyśmienitych smakołyków, stworzona z myślą o najbardziej wymagających podniebieniach. Zarezerwowane tylko na specjalne okazje, ponieważ na pewno pozostawi niezatarte wrażenie!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "253.07",
        "inclTax": "253.07",
        "img": [
            "/images/products/Brunet2.jpg?v=1664377815",
            "/images/products/Brunet1-369967.jpg?v=1671775914"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Napój bezalkoholowy Bickfords Premium Australia",
                "2. Czerwone Winogrono Z Hibiskusem 700ml",
                "3. Hamlet – Asortyment Belgijska Czekolada 125g",
                "4. Hamlet Oublies Forum 125g",
                "5. Feiny Ciasteczka Wafle Kakaowe 250g",
                "6. Nibbles Bite Ciasteczka Czekoladowe Maślane 50g",
                "7. Opakowanie herbaty Basilur 30g",
                "8. K.Harrodson Ciasteczka maślane malinowe 180g",
                "9. Excelcium - Praliny BonBons De Chocolat 200g",
                "10. Beryl’s Czekolada Mleczna Migdałowa 70g",
                "11. Znakomity kosz na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 649,
        "name": "Machie Boy BHP",
        "handle": "owen-bhp",
        "description": "Machie BoyKosz prezentowy dla dziecka Machie Boy z radością powita każdego energicznego chłopczyka w wielkim stylu! Zawiera zestaw upominkowy romper BabyK, dzięki któremu dziecko będzie modne i wygodne, a także niezbędne akcesoria do pielęgnacji, takie jak delikatne chusteczki i waciki. Zadbaliśmy o to, aby w zestawie znalazło się ptasie gniazdo, aby nowi rodzice również czuli się dopieszczeni.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Machie-Boy-BHP1_68b52ef6-d380-4b97-9e7b-e6620b506fd5.jpg?v=1723438099",
            "/images/products/Machie-Boy-BHP2_6b727dc7-d6ac-4305-b72a-3e273dbae3e2.jpg?v=1723438099",
            "/images/products/Machie-Boy-BHP3.jpg?v=1723438099"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo z żeń-szeniem",
                "2. Zestaw upominkowy dla niemowląt K",
                "3. Fiffy Organic Nawilżający żel do mycia od stóp do głów 750ml",
                "4. Fiffy Organiczny balsam nawilżający 450ml",
                "5. Waciki Fiffy Mini 400 szt",
                "6. Myjka Baby K Organic Bamboo 6 szt. w opakowaniu",
                "7. Organiczne chusteczki dla niemowląt KinderNurture Lata 80",
                "8. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 650,
        "name": "Króliczy Kwiat BHP",
        "handle": "tracey-bhp",
        "description": "Bunny BlossomPodaruj dziecku pierwszego kumpla dzięki koszykowi dla noworodka Bunny Blossom. Zawiera niezbędne artykuły dla młodych rodziców, takie jak kocyk i zestaw podarunkowy BabyK, a także poradnik przetrwania, który pomoże im przetrwać pierwsze, pełne wyzwań miesiące. Aby pomóc rodzicom w nieprzespane noce, starannie dodaliśmy także esencję Fomec Berry Essence.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Bunny-Blossom-BHP1-845696.jpg?v=1708969689",
            "/images/products/Bunny-Blossom-BHP2.jpg?v=1708924221"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Esencja z jagód Fomec 41ml x 2",
                "2. Pluszowy króliczek",
                "3. Zestaw upominkowy dla niemowląt K",
                "4. Kocyk Baby K z organicznego bambusa",
                "5. Organiczne grzechotki do rąk Baby K",
                "6. Opieka nad dzieckiem Poradnik przetrwania dla młodych rodziców",
                "7. Kubek niekapek Baby K",
                "8. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 651,
        "name": "Organiczne pudełko zdrowia OHP",
        "handle": "kate-ohp",
        "description": "Organic Health BoxTo organiczne pudełko odnowy biologicznej przedstawia weganina, który jest na drodze do odzyskania zdrowia. Możesz być pewien, że obdarowana osoba nie tylko będzie cieszyć się znajdującymi się w niej przedmiotami, ale także będzie miło wspominać Ciebie.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze organiczne"
        ],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Kate-OHP1-632072.jpg?v=1671685498",
            "/images/products/Kate-OHP2-449137.jpg?v=1671685498"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Termos 500ml",
                "2. Germinal Organiczne Paluszki Ciastkowe Z Suszonymi Owocami Wegańskie 110g",
                "3. Origins Healthfood Organiczne płatki owsiane 500g",
                "4. Dj",
                "5. Certyfikowane organiczne ciastko czekoladowe Serious Cookie 170g",
                "6. Fiorentini Organiczna przekąska ryżowa z rozmarynem 40g",
                "7. Organiczny sok pomarańczowy Sun Blast 200ml",
                "8. Sun Blast Organiczny Sok Jabłko i Gujawa 200ml",
                "9. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 652,
        "name": "Rozrusznik imprezy GHP",
        "handle": "party-ghp",
        "description": "Rozrusznik imprezyTen zachwycający kosz smakołyków jest duszą samej imprezy! Kosz dla smakoszy Party Starter zawiera pyszny wybór słodkich i pikantnych przekąsek oraz ulubioną grę GB Roulette 16 Glass Lucky Shot Drinking Game, stworzoną specjalnie z myślą o zabawie i ekscytujących spotkaniach! Z tym pysznym zestawem przekąsek nigdy nie wpadniesz w nudę!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "266.77",
        "inclTax": "266.77",
        "img": [
            "/images/products/Party2-858131.jpg?v=1671775917",
            "/images/products/Party1-118775.jpg?v=1671775917"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Napój bezalkoholowy Bickfords Premium Australia",
                "2. Czerwone Winogrono z Hibiskusem 700ml",
                "3. Hamlet – Asortyment Belgijska Czekolada 125g",
                "4. Hamlet Oublies Forum 125g",
                "5. Feiny Ciasteczka Wafle Kakaowe 250g",
                "6. Nibbles Bite Ciasteczka Czekoladowe Maślane 50g",
                "7. Opakowanie herbaty Basilur 30g",
                "8. K.Harrodson Ciasteczka maślane malinowe 180g",
                "9. Excelcium - Praliny BonBons De Chocolat 200g",
                "10. Beryl’s Migdałowa Czekolada Mleczna 70g",
                "11. Znakomity kosz na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 35cm"
    },
    {
        "id": 653,
        "name": "Króliczek Bluebell BHP",
        "handle": "wayne-bhp",
        "description": "Bunny Bluebell Zaskocz nowych rodziców koszem prezentowym dla noworodka Bunny Bluebell. Zawiera niezbędne artykuły dla młodych rodziców, takie jak kocyk i zestaw podarunkowy BabyK, a także poradnik przetrwania, który pomoże im przetrwać pierwsze, pełne wyzwań miesiące. Aby pomóc rodzicom w nieprzespane noce, starannie dodaliśmy także esencję Fomec Berry Essence.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Bunny-Bluebell-BHP2-395462.jpg?v=1708969690",
            "/images/products/Bunny-Bluebell-BHP1-399615.jpg?v=1708969690"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Esencja z jagód Fomec 41ml x 2",
                "2. Pluszowy króliczek",
                "3. Zestaw upominkowy dla niemowląt K",
                "4. Kocyk Baby K- Organiczny bambusowy rożek",
                "5. Organiczne grzechotki do rąk Baby K",
                "6. Opieka nad dzieckiem Poradnik przetrwania dla młodych rodziców",
                "7. Kubek niekapek Baby K",
                "8. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 654,
        "name": "Nadchodzi Bailey BHP",
        "handle": "peter-bhp",
        "description": "Here Comes BaileyPrzywitaj się z Here Comes Bailey Hamper! W zestawie znajduje się pluszowy pies Bailey z naszej kolekcji Bailey Friends! Zaprojektowany dla rodziców, którzy cenią przydatne rzeczy i chcą zachować trwałe wspomnienia, ten zachwycający zestaw mieści się w kuferku inspirowanym stylem vintage, zaprojektowanym również po to, aby pomieścić wszystkie Twoje cenne pamiątki jako etui na pamiątkę.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "287.30",
        "inclTax": "287.30",
        "img": [
            "/images/products/Peter2-692586.jpg?v=1671685463",
            "/images/products/Peter1-840074.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pluszowy piesek Bailey",
                "2. Kayen",
                "3. Poduszka 3D Airmesh 11,5 x 7,5",
                "4. BabyK Kompas opieki nad dzieckiem",
                "5. Bawełniany zestaw startowy BabyK 4 szt",
                "-Śpioch",
                "-Rękawiczka",
                "-Buty",
                "-Czapka",
                "6. Ściereczka do mycia niemowląt BabyK",
                "7. Wielofunkcyjny koc flanelowy BabyK",
                "8. Bailey'a",
                "9. Logo Joybox w białej papierowej torbie"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm x 26cm"
    },
    {
        "id": 655,
        "name": "Rekreacja Małego Księcia BHP",
        "handle": "patrick-bhp",
        "description": "Little Prince Recreation Przygotuj się na książęcą zabawę z koszykiem dla dziecka Little Prince Recreation! Zawiera koc z polaru i poduszkę do kształtowania głowy z bawełny organicznej firmy BabyK, aby zapewnić dziecku po królewsku odpoczynek, a także pluszowego króliczka i drewniany samochód wyścigowy do zabaw odpowiednich dla małego książąt!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Patrick2-726518.jpg?v=1671685423",
            "/images/products/Patrick1-285032.jpg?v=1671685423"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Poduszka dla dziecka K-Bawełna organiczna kształtująca głowę",
                "2. Kocyk polarowy Baby K – niebieski",
                "3. Marek",
                "4. Baby K - Drewniany samochód-zabawka",
                "5. Owen -2 szt. Koc odbiorczy",
                "6. Pluszowy króliczek",
                "7. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 656,
        "name": "Niebieski kosz mamy z balonikiem BHP",
        "handle": "alexis-bhp",
        "description": "Niebieski kosz mamy z balonikiem Świętuj zarówno mamę, jak i dziecko, korzystając z kosza na prezent dla noworodka Mum's Blue Basket. Ułożony artystycznie w wykwintnym koszu prezentowym, zawiera smakołyki zarówno dla mamy, jak i nowonarodzonego księcia! Przydatny prezent w postaci koszyka, w tym niebieski kocyk z polaru dla dziecka oraz zdrowe toniki i herbata dla mamy. Ten koszyk dla dziecka spełnia wszystkie wymagania, dzięki czemu możesz zrobić najlepsze wrażenie!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "326.10",
        "inclTax": "326.10",
        "img": [
            "/images/products/Alexis2-424255.jpg?v=1671685492",
            "/images/products/Alexis1-349245.jpg?v=1671685492"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "Longana",
                "3. Zestaw upominkowy Nożyce Muji 4 szt. – Szara Zebra",
                "4. Kocyk polarowy Baby K – niebieski",
                "5. Marek",
                "6. Różne balony bez helu",
                "7. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 657,
        "name": "Pozdrawiam i wznoszę toast (bezalkoholowy) GHP",
        "handle": "cheers-ghp",
        "description": "Wiwat i toast (bezalkoholowy)",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Cheer-and-Toast-_Non-Alcoholic_-GHP2.jpg?v=1718173061",
            "/images/products/Cheer-and-Toast-_Non-Alcoholic_-GHP1_54c47b2d-0ac6-46a7-9925-cc5d091e9671.jpg?v=1718173062"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Bickfords Cordial Australia 750ml",
                "2. Excelcium Czarne Praliny BonBons De Chocolat 200g",
                "3. Marcepan San Andres z owocami kandyzowanymi 200g",
                "4. Hamlet Oublies 125g",
                "5. Tradycyjne paluszki chlebowe Primo Forno z parmezanem 125g",
                "6. Forum Wafelek Karmelowy 230g",
                "7. Ore Liete Cantuccini z kawałkami ciemnej czekolady 180g",
                "8. Wykwintny kosz piknikowy (M)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 658,
        "name": "Twinkle Twinkle Mała dziewczynka BHP",
        "handle": "eunice-bhp",
        "description": "Twinkle Twinkle Little GirlGratulujemy najsłodszego koszyka dla noworodka! Koszyczek dla dziewczynki Twinkle Twinkle Little Girl zawiera ekscytującą serię przyborów i zabawek, które zapewnią dziecku ekscytację i rozrywkę! Dodaliśmy także Księgę kamieni milowych dziecka, aby rodzice mogli śledzić swoją nową, ekscytującą podróż!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Eunice2-459192.jpg?v=1671775918",
            "/images/products/Eunice1-190489.jpg?v=1671775918"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Esencja z jagód Fomec 41ml x 2",
                "2. Ptasie Gniazdo z Żeń-szeniem",
                "3. Zabawny zestaw zabawek Orbit Turn Happy Ball",
                "4. Gryzak silikonowy żyrafa Baby K przeznaczony do kontaktu z żywnością",
                "5. Kubek niekapek Baby K",
                "6. Zestaw silikonowych łyżek do samodzielnego karmienia Baby K",
                "7. Wobbler Baby K Penguin",
                "8. Opakowania na różne kwiaty",
                "9. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 659,
        "name": "Nadchodzi Molly BHP",
        "handle": "molly-bhp",
        "description": "Nadchodzi Molly. Przywitaj się z Nadchodzi Bailey Hamper! W zestawie znajduje się pluszowa kotka Molly z naszej kolekcji Bailey Friends! Zaprojektowany dla rodziców, którzy cenią przydatne rzeczy i chcą zachować trwałe wspomnienia, ten zachwycający zestaw mieści się w kuferku inspirowanym stylem vintage, zaprojektowanym również po to, aby pomieścić wszystkie Twoje cenne pamiątki jako etui na pamiątkę.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "287.30",
        "inclTax": "287.30",
        "img": [
            "/images/products/Molly2-900952.jpg?v=1671685463",
            "/images/products/Molly1-184833.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pluszowa zabawka-kot Molley",
                "2. Kayen",
                "3. Poduszka 3D Airmesh 11,5 x 7,5",
                "4. BabyK Kompas opieki nad dzieckiem",
                "5. Bawełniany zestaw startowy BabyK 4 szt",
                "-Śpioch",
                "-Rękawiczka",
                "-Buty",
                "-Czapka",
                "6. Ściereczka do mycia niemowląt BabyK",
                "7. Wielofunkcyjny koc flanelowy BabyK",
                "8. Bailey'a"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm x 26cm"
    },
    {
        "id": 660,
        "name": "Mała Księżniczka Rekreacyjna BHP",
        "handle": "shirley-bhp",
        "description": "Little Princess Recreation Przygotuj się na zabawę w księżniczkę z koszykiem dla dziecka Little Princess Recreation! Zawiera koc z polaru i poduszkę do kształtowania głowy z bawełny organicznej firmy BabyK, aby zapewnić dziecku po królewsku odpoczynek, a także pluszowego króliczka i drewniany samochód wyścigowy do zabaw odpowiednich dla małych członków rodziny królewskiej!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Shirley2-351100.jpg?v=1671685469",
            "/images/products/Shirley1-882489.jpg?v=1671685469"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Poduszka dla dziecka K-Bawełna organiczna kształtująca głowę",
                "2. Kocyk polarowy Baby K – różowy",
                "3. Marek",
                "4. Baby K - Drewniany samochód-zabawka",
                "5. Owen -2 szt. Koc odbiorczy",
                "6. Pluszowy króliczek",
                "7. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 661,
        "name": "Różowa pasja mamy z balonikiem BHP",
        "handle": "eileen-bhp",
        "description": "Różowa pasja mamy z balonikiem Spraw radość mamie i dziecku dzięki koszyczkowi prezentowemu dla noworodka Mum's Pink Passion. Ułożony artystycznie w wykwintnym koszu upominkowym, zawiera smakołyki zarówno dla mamy, jak i nowonarodzonej księżniczki! Przydatny prezent w postaci koszyka, w tym różowy kocyk z polaru dla dziecka oraz zdrowe toniki i herbata dla mamy. Ten koszyk dla dziecka spełnia wszystkie wymogi, dzięki czemu możesz zrobić najlepsze wrażenie!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "326.10",
        "inclTax": "326.10",
        "img": [
            "/images/products/Eileen2-555281.jpg?v=1671685494",
            "/images/products/Eileen1-130176.jpg?v=1671685494"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "2. SpaFoods-Longan",
                "3. Nożyce Muji 4-częściowy zestaw upominkowy – Kitty Pink",
                "4. Kocyk polarowy Baby K – różowy",
                "5. Marek",
                "6. Różne balony bez helu",
                "7. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 662,
        "name": "Odpust (bezalkoholowy) GHP",
        "handle": "indulgence-ghp",
        "description": "Odpust (bezalkoholowy)",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "326.10",
        "inclTax": "326.10",
        "img": [
            "/images/products/Indulgence-_Non-Alcoholic_-GHP2_fd05691f-9c7b-4c6d-a52e-ffc075c0be3c.jpg?v=1718245014",
            "/images/products/Indulgence-_Non-Alcoholic_-GHP1_1c87d163-b29e-45ac-b2f5-88b7aec0a13f.jpg?v=1718245014"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Bickfords Cordial Australia 750ml",
                "2. Cupido Seashells Belgijska Czekolada z Nadzieniem Orzechowym 250g",
                "3. Tradycyjne paluszki chlebowe Primo Forno z parmezanem 125g",
                "4. Gavottes Crpes Dentelle 125g",
                "5. Pudełko upominkowe GBB Soft Almond Nugat 130g",
                "6. Rozmaryn z dobrego sera",
                "7. Kawa Tchibo Gold Selection 50g",
                "8. Ciasteczka maślane Danisa 200g",
                "9. Jules Destrooper Migdałowy rozrzedzacz z ciemną czekoladą",
                "10. Wykwintny kosz piknikowy (M)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 663,
        "name": "Pozdrawiam i wznoszę toast (białe wino) GHP",
        "handle": "orvieto-ghp",
        "description": "Cheer and Toast (białe wino) Kosz na żywność dla smakoszy Cheer and Toast (białe wino) to smaczny asortyment wykwintnych przysmaków, doskonale połączonych z owocowym i łagodnym De Valier Bianco. Rozkoszuj się dobrym smakiem, wznoś toast za wspaniałe chwile, które stają się jeszcze bardziej wyjątkowe dzięki tej żywej kombinacji smaków!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "394.79",
        "inclTax": "394.79",
        "img": [
            "/images/products/Orvieto2.jpg?v=1664465110",
            "/images/products/Orvieto1-730995.jpg?v=1671685194"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. De Valier Bianco 75cl",
                "2. Aalst Mleczna Czekolada Z Całe Migdały Puszka Prezentowa 150g",
                "3. Kubeczki deserowe Cupido Tiramisu 125g",
                "4. Herbata Juan 9g x 2 pudełka",
                "5. K.Harrodson Ciasteczka Maślane Puszka 180g",
                "6. Pani Bridges – Marmolada Pomarańczowa z Miodem 340g",
                "7. Forum Hamlet Oublies 125g",
                "8. Excelcium – Praliny BonBons De Chocolat 200g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 664,
        "name": "Odpust (czerwone wino) GHP",
        "handle": "nougat-ghp",
        "description": "Odpust (czerwone wino)",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Indulgence-_Red-Wine_-GHP2_11c197c8-7fc8-41f9-b93b-951c28a389bd.jpg?v=1718173941",
            "/images/products/Indulgence-_Red-Wine_-GHP1_fb0772df-0953-4566-a988-730189e19686.jpg?v=1718173940"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Noblesse Cabernet Sauvignon 75cl",
                "2. Cupido Seashells Belgijska Czekolada z Nadzieniem Orzechowym 250g",
                "3. Tradycyjne paluszki chlebowe Primo Forno z parmezanem 125g",
                "4. Gavottes Crpes Dentelle 125g",
                "5. Pudełko upominkowe GBB Soft Almond Nugat 130g",
                "6. Rozmaryn z dobrego sera",
                "7. Kawa Tchibo Gold Selection 50g",
                "8. Ciasteczka maślane Danisa 200g",
                "9. Jules Destrooper Migdałowy rozrzedzacz z ciemną czekoladą",
                "10. Wykwintny kosz piknikowy (M)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 665,
        "name": "Odpust (białe wino) GHP",
        "handle": "jules-ghp",
        "description": "Indulgence (białe wino) Nasz koszyk dla smakoszy Indulgence (białe wino) został stworzony specjalnie po to, aby urozmaicić przyjęcie herbaciane! Dzięki ulubionym podwieczorkom i bogatemu sauvignon blanc możesz mieć pewność, że Twój odbiorca będzie z radością rozkoszował się tym przepysznym koszem smakołyków!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "415.32",
        "inclTax": "415.32",
        "img": [
            "/images/products/Jules2.jpg?v=1664494085",
            "/images/products/Jules1.jpg?v=1664494085"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Noblesse Sauvignon Blanc 75cl",
                "2. Ciasteczka maślane Danisa 200g",
                "3. Czekolady Guylian Belgijskie Premium Ciemne 72% 100g",
                "4. Jules Destrooper Czekolada Rozrzedzająca 100g",
                "5. Pudełko upominkowe Golden Bon Bon Miękki Nugat Migdałowy 130g",
                "6. Cupido Muszelki Czekoladowe z Orzechami Laskowymi 250g",
                "7. Mini tradycyjne opakowanie konfitur Mrs Bridges 42 g x 3",
                "8. Herbata zielona Tipson Ceylon 25 torebek",
                "9. Farmhouse Luxury Ciasteczka Solony Karmel 150g",
                "10. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 666,
        "name": "Gastronomia (bezalkoholowa) GHP",
        "handle": "gastronome-ghp",
        "description": "Gastronom (bezalkoholowy) Oto zestaw upominkowy, który doceni każdy enofil, a który składa się w zasadzie z butelki soczystego kordiału Bickfords. Ale to nie wszystko; jest też wiele smakołyków.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "394.56",
        "inclTax": "394.56",
        "img": [
            "/images/products/Gastronome2-979474.jpg?v=1671685311",
            "/images/products/Gastronome1-785808.jpg?v=1671685311"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Kordiały Bickfords 750ml Excelcium",
                "2. Excelcium – Praliny BonBons De Chocolat 200g",
                "3. St.Michael 9Grandes Galettes Masło Czekoladowe 150g",
                "4. Popcorn budyniowy toffi 80g",
                "5. Hamlet Asortyment Belgijska Czekolada 125g",
                "6. Davidoff Café Grande Cuvee Bogaty aromat 45g",
                "7. Walkers Biszkopty Belgijskiej Czekolady Kawałki 150g",
                "8. Nugat Złoty Boronia Original Crunchy 250g",
                "9. Konfitura Tiptree Morello Wiśnia 340g",
                "10. Pomarańczowe drzewko tipi",
                "11. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 667,
        "name": "Pozdrawiam i wznoszę toast (musujący) GHP",
        "handle": "sparkling-ghp",
        "description": "Cheer and Toast (Sparkling) Kosz na żywność dla smakoszy Cheer and Toast (Sparkling) to smaczny asortyment wykwintnych przysmaków, idealnie połączonych z Sparkling Cuvée firmy Lindeman. Rozkoszuj się dobrym smakiem, wznoś toast za wspaniałe chwile, które stają się jeszcze bardziej wyjątkowe dzięki tej żywej kombinacji smaków!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "440.43",
        "inclTax": "440.43",
        "img": [
            "/images/products/Sparkling2.jpg?v=1664495955",
            "/images/products/Sparkling1-330851.jpg?v=1671684909"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Val D’OGA Millesimato Prosecco 75cl",
                "2. Aalst Mleczna Czekolada Z Całe Migdały Puszka Prezentowa 150g",
                "3. Kubeczki deserowe Cupido Tiramisu 125g",
                "4. Herbata Juan 9g x 2 pudełka",
                "5. K.Harrodson Ciasteczka Maślane Puszka 180g",
                "6. Pani Bridges – Marmolada Pomarańczowa z Miodem 340g",
                "7. Forum Hamlet Oublies 125g",
                "8. Excelcium – Praliny BonBons De Chocolate 200g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 668,
        "name": "Odpust (musujący) GHP",
        "handle": "frizzante-ghp",
        "description": "Indulgence (musujący) Nasz koszyk dla smakoszy Indulgence (musujący) został stworzony specjalnie po to, aby podnieść poziom przyjęcia herbacianego! Dzięki ulubionym podwieczorkom oraz słodkim i wyśmienitym smakołykom możesz mieć pewność, że Twój odbiorca będzie z radością rozkoszował się tym przepysznym koszem smakołyków!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "458.68",
        "inclTax": "458.68",
        "img": [
            "/images/products/Frizzante-2.jpg?v=1664495946",
            "/images/products/Frizzante-1.jpg?v=1664495946"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Val D’Oga Millesimato Prosecco 75cl",
                "2. Ciasteczka maślane Danisa 200g",
                "3. Czekolady Guylian Belgijskie Premium Ciemne 72% 100g",
                "4. Jules Destrooper Czekolada Rozrzedzająca 100g",
                "5. Pudełko upominkowe Golden Bon Bon Miękki Nugat Migdałowy 130g",
                "6. Cupido Muszelki Czekoladowe z Orzechami Laskowymi 250g",
                "7. Mini tradycyjne opakowanie konfitur Mrs Bridges 42 g x 3",
                "8. Herbata zielona Tipson Ceylon 25 torebek",
                "9. Farmhouse Luxury Ciasteczka Solony Karmel 150g",
                "10. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 669,
        "name": "Celebracja (bezalkoholowa) GHP",
        "handle": "celebration-ghp",
        "description": "Świętowanie (bezalkoholowe)",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "481.27",
        "inclTax": "481.27",
        "img": [
            "/images/products/The-Celebration-_Non-Alcoholic_-GHP1.jpg?v=1706589461",
            "/images/products/The-Celebration-_Non-Alcoholic_-GHP2-443615.jpg?v=1706717674"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Bickfords Cordial Australia Czarna Porzeczka 750ml",
                "2. Rossella Pouchette Fondant Frutta 45g",
                "3. Walkers Ciastka Szkockie Serowe 250g",
                "4. Bombones z ciemnej czekolady El Almendro 70% kakao 150g",
                "5. Tradycyjne paluszki chlebowe Primo Forno z parmezanem 125g",
                "6. Heidi Mountains Orzechy laskowe Premium Czekolada 150g",
                "7. Nugat migdałowy San Andres 150g",
                "8. Fiorella Ciasteczka Czekoladowe 102g",
                "9. Malina Nairns",
                "10. Kremy Buk Fioletowy 90g",
                "11. Ciasteczka La Mere Poulard Eclat Chocolat Ciasteczka z kawałkami czekolady 200g",
                "12. Wykwintny kosz piknikowy (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 670,
        "name": "Gastronomia (czerwone wino) GHP",
        "handle": "celebrazione-ghp",
        "description": "Gastronom (czerwone wino) Oto zestaw upominkowy, który doceni każdy enofil i który w zasadzie składa się z butelki soczystego czerwonego wina. Ale to nie wszystko; jest też wiele smakołyków.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "531.48",
        "inclTax": "531.48",
        "img": [
            "/images/products/Celebrazione2-125707.jpg?v=1671685312",
            "/images/products/Celebrazione1-839031.jpg?v=1671685312"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Elegancja Cabernet Sauvignon 75cl",
                "2. Excelcium – Praliny BonBons De Chocolat 200g",
                "3. St.Michael 9 Grandes Galettes Masło Czekoladowe 150g",
                "4. Popcorn budyniowy toffi 80g",
                "5. Hamlet – Asortyment Belgijska Czekolada 125g",
                "6. Davidoff Café – Grande Cuvee Rich Aroma 45g",
                "7. Walkers Biszkopty Belgijskiej Czekolady Kawałki 150g",
                "8. Nugat Złoty Boronia Original Crunchy 250g",
                "9. Tiptree – Morello Cherry Conserve 340g",
                "10. Tiptree – Pomarańczowe",
                "11. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 671,
        "name": "Słodki Pie BHP",
        "handle": "precious-bhp",
        "description": "Cutie Pie Świętuj pojawienie się nowego uroczego ciasta z koszem prezentowym dla noworodka Cutie Pie! Dołączyliśmy zabawne zabawki i przybory do pielęgnacji, a także księgę kamieni milowych Hello Baby, aby można było rejestrować wszystkie te cenne kamienie milowe.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Jenny2a-122773.jpg?v=1671685198",
            "/images/products/Jenny1-727449.jpg?v=1671685198"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "2. Zabawny zestaw zabawek Orbit Turn Happy Ball",
                "3. Suszarki Wee Wee Dry Pieluszki rozmiar M (4szt)",
                "4. Chusteczki dla niemowląt Pigeon 30s 100% czystej wody",
                "5. Księga kamieni milowych „Witaj kochanie”.",
                "6. Gryzak Baby K w kształcie kwiatka",
                "7. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 672,
        "name": "Kochanie, mały BHP",
        "handle": "honey-bhp",
        "description": "Honey LittlePrezent dla noworodka Honey Little mówi nowym rodzicom, jak słodka będzie ich nowa, ekscytująca podróż! Oprócz niezbędnych artykułów pielęgnacyjnych dołączyliśmy także zestaw podarunkowy BabyK, dzięki któremu dziecko będzie ubrane stylowo! Zapakowany w wykwintny kosz prezentowy, ten kosz z pewnością zachwyci młodych rodziców.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Honey-Little-BHP1-627399.jpg?v=1722357913",
            "/images/products/Honey-Little-BHP2-458731.jpg?v=1722357913"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy dla niemowląt K",
                "2. Ptasie gniazdo z żeń-szeniem",
                "3. Organiczne chusteczki dla niemowląt KinderNurture 20s",
                "4. Fiffy Organiczny krem ​​nawilżający od stóp do głów 750ml",
                "5. Waciki Fiffy Mini 400 szt",
                "6. Wykwintny kosz upominkowy (S)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 673,
        "name": "Miłość mamusi BHP",
        "handle": "sandy-bhp",
        "description": "Mummy’s Love Świętuj miłość mamusi i powiedz jej, że dobrze się spisałeś, korzystając z koszyka dla noworodków Mummy Love. W zestawie mamy organiczny kocyk bambusowy, myjki i grzechotkę do rąk firmy BabyK, a także organiczne chusteczki do pielęgnacji niemowląt w podróży! Aby zapewnić dodatkową odrobinę rozpieszczania, dodaliśmy ptasie gniazdo z żeń-szeniem, aby mieć pewność, że wysiłki mamy zostaną nagrodzone!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "184.61",
        "inclTax": "184.61",
        "img": [
            "/images/products/Sandy2-869901.jpg?v=1671685492",
            "/images/products/Sandy1-789801.jpg?v=1671685492"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "2. Kocyk Baby K- Organiczny bambusowy rożek",
                "3. Myjka Baby K- Organic Bamboo 6 szt",
                "4. Organiczne grzechotki do rąk Baby K",
                "5. Organiczne chusteczki dla niemowląt KinderNurture 40-te",
                "6. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 674,
        "name": "Skromne świętowanie BHP",
        "handle": "selphvy-bhp",
        "description": "Demure CelebrationPrezent dla dziecka Demure Celebration w wielkim stylu upamiętnia przyjście na świat poprzedniego noworodka. Ten dyskretnie wyrafinowany i subtelnie piękny kosz na śmieci jest pełen niezbędnych artykułów dla dzieci, które są organiczne i bezpieczne w użyciu dla delikatnej skóry noworodka. Dołączyliśmy także Księgę kamieni milowych Hello Baby, aby mieć pewność, że te cenne wspomnienia zostaną zapisane i zapamiętane.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Selphvy2-383468.jpg?v=1671685199",
            "/images/products/Selphvy1-164010.jpg?v=1671685199"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Poduszka Baby K-Bawełna organiczna kształtująca głowę Safari",
                "2. Myjka Baby K- Organic Bamboo 6 szt. w opakowaniu",
                "3. Księga kamieni milowych „Hello Baby”.",
                "4. Esencja z Jagód Fomec 41ml x 2",
                "5. Gryzak silikonowy żyrafa Baby K przeznaczony do kontaktu z żywnością",
                "6. Zestaw szczotek do kąpieli Baby K",
                "7. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 675,
        "name": "Mój niezbędny na świeżym powietrzu BHP",
        "handle": "emmanuel-bhp",
        "description": "My Outdoor Essential Przygotuj się na przygody na świeżym powietrzu dzięki koszykowi prezentowemu dla noworodków My Outdoor Essential! Zapewnij dziecku chłód i bezpieczeństwo przed szkodnikami dzięki mini przypinanemu wentylatorowi z ultradźwiękowym środkiem odstraszającym komary! Zabawiaj ich zmysły w podróży dzięki owocowemu smoczkowi-gryzakowi BabyK i posprzątaj bałagan za pomocą naszych poręcznych, nawilżanych chusteczek! Ten kosz na prezenty to naprawdę idealny gadżet na świeżym powietrzu dla rodzin kochających przygody!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/My-Outdoor-Essential-BHP1_7ec110e4-fb2d-41c9-abc1-413fd27c8c3e-747635.jpg?v=1722358168",
            "/images/products/My-Outdoor-Essential-BHP2_ad249e6c-b7f4-46e4-9bc9-41363fd5cab5-842500.jpg?v=1722358168",
            "/images/products/My-Outdoor-Essential-BHP3_f0373ec8-b8f0-403e-b264-705529b3afaf-319408.jpg?v=1722358168"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo z żeń-szeniem",
                "2. Esencja Kurczaka z Księżyca w Nowiu 2 x 68ml",
                "3. Klips do osłony przeciwsłonecznej Lucky Baby Sunny Shield",
                "4. Płyn do mycia gołębi 200ml",
                "5. Smoczek Baby K Fruit Feeder",
                "6. Suszarki Wee Wee Dry Pieluszki rozmiar M (4szt)",
                "7. Chusteczki dla niemowląt Pigeon 30s 100% czystej wody",
                "8. Ręcznik jednorazowy Fiffy Baby skompresowany (70cm x 105cm) x 2",
                "9. Plaster odstraszający komary My Little One 10szt",
                "10. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 676,
        "name": "Najczystsza miłość mamy BHP",
        "handle": "xavier-bhp",
        "description": "Mum’s Purest LoveKoszyk dla niemowląt Mum’s Purest Love to jeden z najlepszych koszyków w Polsce, jaki można dostać dla młodych rodziców. Dla dzieci przygotowaliśmy organiczną poduszkę kształtującą główkę i organiczne myjki bambusowe firmy BabyK, a dla mam dodaliśmy ptasie gniazdo, aby zapewnić dodatkową odrobinę rozpieszczania.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Angelia2-510020.jpg?v=1671685495",
            "/images/products/Angelia1-390206.jpg?v=1671685495"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "2. Myjka Baby K Organic Bamboo 6 szt. (zielona",
                "3. Poduszka dla dziecka Baby K z bawełny organicznej kształtująca głowę Safari",
                "4. Organiczna zabawka do uścisku dłoni Baby K",
                "5. Naturaverde Bio Organiczny płyn do mycia ciała i włosów 200ml",
                "6. Organiczne chusteczki dla niemowląt KinderNurture 40-te",
                "7. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 677,
        "name": "Mama i dziecko Cenna dziewczynka BHP",
        "handle": "ivine-bhp",
        "description": "Mama i dziecko Precious GirlZwiastuj przybycie cennej córeczki za pomocą kosza na prezent dla noworodka „Mama i dziecko”. Bogaty wybór ulubionych ulubionych zabawek jest pięknie zapakowany wraz z woblerem Baby K Penguin i książeczką z kamieniami milowymi dla dziecka, aby naprawdę rozpieszczać dziecko i mieć pewność, że każdy kamień milowy zostanie zarejestrowany!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Ivine2.jpg?v=1664683006",
            "/images/products/Ivine1-894134.jpg?v=1671685490"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Wobbler Baby K Penguin",
                "2. Zabawka ząbkująca Baby K Dino przeznaczona do kontaktu z żywnością",
                "3. Kubek niekapek Lucky Baby Edee",
                "4. Regulowany pasek Lucky Baby Sippy Buddy umożliwia mocowanie butelki dla dziecka, kubka itp",
                "5. Pluszowy króliczek",
                "6. Ptasie Gniazdo z Żeń-szeniem",
                "7. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 678,
        "name": "Mama i dziecko Cenny chłopiec BHP",
        "handle": "adrian-bhp",
        "description": "Mama i dziecko Drogi chłopiecZwiastuj przyjście na świat kochanego chłopczyka za pomocą kosza na prezent dla noworodka „Mama i dziecko”. Bogaty wybór ulubionych ulubionych zabawek jest pięknie zapakowany wraz z woblerem Baby K Penguin i książeczką z kamieniami milowymi dla dziecka, aby naprawdę rozpieszczać dziecko i mieć pewność, że każdy kamień milowy zostanie zarejestrowany!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Adrian2-719061.jpg?v=1671685491",
            "/images/products/Adrian1-702097.jpg?v=1671685491"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Wobbler Baby K Penguin z książeczką Baby Milestone Book",
                "2. Zabawka ząbkująca Baby K Dino przeznaczona do kontaktu z żywnością",
                "3. Kubek niekapek Lucky Baby Edee",
                "4. Regulowany pasek Lucky Baby Sippy Buddy umożliwia mocowanie butelki dla dziecka, kubka itp",
                "5. Pluszowy króliczek",
                "6. Ptasie Gniazdo z Żeń-szeniem",
                "7. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 679,
        "name": "Kwitnące Owoce FHP",
        "handle": "fruities-fhp",
        "description": "Blooming FruitsKiedy świeże owoce spotykają się z kwiatami, tak stworzyliśmy Blooming Fruits. Ma różną jakość świeżych owoców i kwiatów. Ten koszyk zawiera mieszankę 7 odmian soczystych, świeżych owoców, które dają właścicielowi dodatkowe korzyści.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe",
            "Kosze korporacyjne"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Fruities1-902251.jpg?v=1671684899",
            "/images/products/Fruities2-108676.jpg?v=1671684899"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Smocze owoce",
                "2. Gruszka Nam Shui",
                "3. Jabłko",
                "4. Zielone jabłko",
                "5. Kiwi",
                "6. Gruszka Forelle",
                "7. Zielone winogrona",
                "8. W zestawie kwiaty i opakowanie"
            ]
        },
        "dimention": "Rozmiar Kosza Upominkowego: 33cm x 23cm x 26cm"
    },
    {
        "id": 680,
        "name": "Odżywcze rozkosze FHP",
        "handle": "nourish-fhp",
        "description": "Nourishing DelightsTen klasyczny koszyk zawiera zdrowe, niezbędne produkty, których każdy potrzebuje, aby poczuć się odmłodzonym i ożywionym. Jeśli nie możesz się zdecydować, który koszyk kupić, Nourishing Delights to bezpieczny wybór!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe",
            "Kosze korporacyjne"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Nourish1-747827.jpg?v=1671685496",
            "/images/products/Nourish2-175752.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Marki Esencja z kurczaka 42ml x 6",
                "2. Pomarańczowy",
                "3. Jabłko Fuji",
                "4. Zielone jabłko",
                "5. Kiwi",
                "6. Gruszka Forelle",
                "7. Gerbera",
                "8. Róże",
                "9. Spray różany"
            ]
        },
        "dimention": "Rozmiar Kosza Upominkowego: 29cm x 21cm x 34cm"
    },
    {
        "id": 681,
        "name": "Fanny BBS",
        "handle": "fanny",
        "description": "To wykwintne pudełko kwiatowe od Flowers Poland przedstawia symfonię róż w kolorze różu i odważnego pomarańczu, w połączeniu ze śnieżnobiałymi kwiatami i białą kaspią. Zapakowany w nowoczesne kremowe pudełko z eleganckim uchwytem i satynową wstążką, jest eleganckim i przenośnym kwiatowym akcentem.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Fanny-BBS-2b.jpg?v=1721619661",
            "/images/products/Fanny-BBS-1b.jpg?v=1721619661"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Matthiola.Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 682,
        "name": "Carine BBS",
        "handle": "carine",
        "description": "Ta urocza kompozycja kwiatowa w delikatnym różowym pudełku zawiera zachwycającą mieszankę różowych gerber, róż i goździków, akcentowaną białymi kwiatami i bujną zielenią. Kompozycja jest elegancko zwieńczona różową wstążką, dodającą odrobiny wyrafinowania i słodyczy, idealna na każdą okazję, która wymaga kobiecego i pełnego wdzięku dotyku.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Carine-BBS-2a-747967.jpg?v=1721657635",
            "/images/products/Carine-BBS-1a.jpg?v=1721614240"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Gerbera . Goździk . Spryskaj goździki. Eustoma.Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 683,
        "name": "Hilda BBS",
        "handle": "hilda",
        "description": "Ta elegancka kompozycja w wyrafinowanym czarnym pudełku zawiera oszałamiające połączenie różowych, fioletowych i niebieskich kwiatów. Aranżację podkreśla centralna fioletowa róża, otoczona delikatnymi różowymi kwiatami, niebieskimi akcentami i bujną zielenią. Zwieńczona lawendową wstążką emanuje poczuciem luksusu i wdzięku, dzięki czemu doskonale zrobi wrażenie na każdą okazję.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Hilda-BBS-2a.jpg?v=1721614824",
            "/images/products/Hilda-BBS-1a.jpg?v=1721614824"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj goździki. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 684,
        "name": "Kwiatowy świeży FHP",
        "handle": "betty-fhp",
        "description": "Floral Fresh Te żywe owoce są delikatnie ułożone w wysokiej jakości wiklinowym koszu, zwieńczonym elegancką wstążką i zachwycającym bukietem żywych, świeżych kwiatów.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Betty1-661008.jpg?v=1671685205",
            "/images/products/Betty2-997423.jpg?v=1671685205"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mango",
                "2. Smocze owoce",
                "3. Jabłko Fuji",
                "4. Gruszka Forelle",
                "5. Zielone winogrona",
                "6. Gruszka Nam Shui",
                "7. Pomarańczowy",
                "8. Zielone jabłko",
                "9. Kiwi",
                "10. Granat",
                "11. Kompozycja kwiatowa: róże, goździki, eustoma, liść monety"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 685,
        "name": "Odbiór FHP",
        "handle": "shannon-fhp",
        "description": "Odbierz mnie",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "305.56",
        "inclTax": "305.56",
        "img": [
            "/images/products/Shannon1_1-806267.jpg?v=1671685636",
            "/images/products/Shannon2_1-987345.jpg?v=1671685636"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Marki Birdnest 70ml x 6",
                "2. Fioletowe winogrona",
                "3. Zielone jabłko",
                "4. Jabłko Fuji",
                "5. Pomarańczowy",
                "6. Kiwi"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 686,
        "name": "Słodka Rewitalizacja FHP",
        "handle": "verdure-fhp",
        "description": "Słodka RewitalizacjaGdy świeże owoce spotykają się z balonem z kwiatami, tak stworzyliśmy Słodką Rewitalizację. Ma różną jakość świeżych owoców i kwiatów. Ten koszyk zawiera mieszankę 8 odmian soczystych, świeżych owoców, które dają właścicielowi dodatkowe korzyści.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/verdure1-542942.jpg?v=1671775915",
            "/images/products/verdure2-782335.jpg?v=1671775915"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Krystalicznie czysty balon „Wróć do zdrowia wkrótce”.",
                "2. Pomarańczowy",
                "3. Kiwi",
                "4. Smocze owoce",
                "5. Zielone winogrona",
                "6. Awokado",
                "7. Zielone jabłko",
                "8. Jabłko Fuji",
                "9. Gruszka Nam Shui",
                "10. Drewniana skrzynia (L)",
                "11. Kompozycja kwiatowa: róże, spray różany, goździk, eustoma, weronika"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 687,
        "name": "Sasza BBS",
        "handle": "sasha",
        "description": "Ta elegancka kompozycja, umieszczona w eleganckim czarnym pudełku ozdobionym pasującą czarną wstążką, zawiera wyrafinowaną mieszankę kwiatów. W sercu delikatnej róży otoczone są ciemnoczerwonymi różami, bogatymi bordowymi kwiatami i bujnymi zielonymi liśćmi. Akcenty czerwonych jagód dodają świątecznego akcentu, dzięki czemu jest to idealny dodatek na każdą okazję.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Sasha-BBS-2a-224601.jpg?v=1721658151",
            "/images/products/Sasha-BBS-1a.jpg?v=1721613077"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża capuccino. Spryskaj róże. Goździki. Eustoma. Hypericum. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 688,
        "name": "Celia BBS",
        "handle": "celia",
        "description": "Ta elegancka kompozycja kwiatowa, umieszczona w eleganckim kremowym pudełku, zawiera oszałamiającą mieszankę żywych żółtych lilii, pastelowych różowych róż i delikatnych białych kwiatów. Doskonale zaakcentowany bujną zielenią i przewiązany wdzięczną wstążką, ten bukiet emanuje wyrafinowaniem i urokiem, dzięki czemu jest idealnym prezentem na każdą okazję.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Celia-BBS-2a.jpg?v=1721615905",
            "/images/products/Celia-BBS-1b-625190.jpg?v=1726200290"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mentalna Róża. Mamo. Eustoma. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 689,
        "name": "Jennifer BBS",
        "handle": "jennifer",
        "description": "Ta urocza kompozycja kwiatowa, zapakowana w stylowe różowe pudełko z jasnoróżową wstążką, zawiera wesołą mieszankę kwiatów. Uderzająca żółta gerbera zajmuje centralne miejsce, otoczona czerwonym goździkiem, delikatnymi różowymi różami i kremowymi białymi kwiatami, uzupełnionymi bujną zielenią. Idealna do rozjaśnienia każdej przestrzeni, ta aranżacja jest uroczym i przemyślanym prezentem.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Jennifer-BBS-2a-972018.jpg?v=1721657935",
            "/images/products/Jennifer-BBS-1a.jpg?v=1721612681"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róża. Eustoma. Goździk . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 690,
        "name": "Fortuna (III)",
        "handle": "fortune-iii",
        "description": "Podnieś wszystkie wielkie otwarcia dzięki statywowi Tripod Radiant Bloom Trio. Urzekające połączenie różowej, szampańskiej, żółtej, czerwonej i białej gerbery, to kwiatowe arcydzieło emanuje radością i różnorodnością, symbolizując tętniącą życiem podróż, która nas czeka. Idealny element centralny do doniosłej uroczystości.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Fortune-_III_-1b.jpg?v=1720094022",
            "/images/products/Grand-Opening-Card-2024_10fabc28-8207-42c8-a1ee-ae4fc02ac3f3.jpg?v=1720094029",
            "/images/products/Fortune-_III_-2b.jpg?v=1720094021"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbery . Złoty Feniks. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 691,
        "name": "Smaczny",
        "handle": "julian-whp",
        "description": "Tasty Hearty Pack with Flower Misternie zapakowany koszyk wellness, który będzie atrakcyjny dla wzroku odbiorcy, a także zapewni pożywienie sercu jednymi z najlepszych napojów zdrowotnych, jakie kiedykolwiek znajdziesz i które uwielbiasz smakować.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Julian-WHP1A-818204.jpg?v=1671775916",
            "/images/products/Julian-WHP2A-713061.jpg?v=1671775916"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Markowa Esencja Kurczaka 3 x 41ml",
                "2. Ptasie Gniazda marki z cukrem kamiennym 2 x 41ml",
                "3. Napój Yu Xiang Yan White Fungus Bird’s Nest 3 x 75ml",
                "4. Opakowania na różne kwiaty",
                "5. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Approximate dimension : 35cm x 40cm"
    },
    {
        "id": 692,
        "name": "Zdrowie to bogactwo WHP",
        "handle": "amy-whp",
        "description": "Zdrowie to bogactwo Misternie zapakowany koszyk wellness, który będzie atrakcyjny dla wzroku odbiorcy, a także zapewni pożywienie sercu jednymi z najlepszych napojów zdrowotnych, jakie kiedykolwiek znajdziesz i które uwielbiasz smakować.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Amy-WHP1-144866.jpg?v=1671685322",
            "/images/products/Amy-WHP2_c591f310-8120-493f-9ea5-5824e3d3f7a3-435068.jpg?v=1671685322"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy Ptasie Gniazdo Kinohimitsu 8 x 75g",
                "2. Dragon King - Żeń-szeń Qing Dun Tang (人参清炖汤) 100g",
                "3. Król Smoków - Liu Wei Qing Bu Tang (六味清补汤) 100g",
                "4. Zestaw herbat Juan Tea Indulgence z mieszanką kwiatową, 10 szt",
                "5. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 693,
        "name": "Ul Miodowy Z Kwiatem OHP",
        "handle": "chloe-ohp",
        "description": "Honey Hive With FlowerTo organiczne, kwiatowe pudełko odnowy biologicznej przedstawia wegankę, która jest na drodze do odzyskania zdrowia. Możesz być pewien, że obdarowana osoba nie tylko będzie cieszyć się znajdującymi się w niej przedmiotami, ale także będzie miło wspominać Ciebie.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze organiczne"
        ],
        "price": "358.05",
        "inclTax": "358.05",
        "img": [
            "/images/products/Chloe-OHP1.jpg?v=1667530809",
            "/images/products/Chloe-OHP2-398909.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Amazin’ Graze Przeciwutleniająca mieszanka jagód i orzechów 130g",
                "2. Nasiona Chia Meksykańskie Amazin’ Graze 300g",
                "3. Owocowe chipsy truskawkowe",
                "4. Serious Cookies Biała Czekolada Macadamia 170g",
                "5. Organiczny sok pomarańczowy Sun Blast 200ml",
                "6. Sun Blast Organiczny Sok Jabłko i Gujawa 200ml",
                "7. Breitsamer Organiczny miód Golden Selection 500g",
                "8. Post Great Grains-Cranberry Crunch Migdałowy 396g",
                "9. Opakowania na różne kwiaty",
                "10. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 35m"
    },
    {
        "id": 694,
        "name": "Idealna księżniczka BHP mamy",
        "handle": "lavinia-bhp",
        "description": "Mama's Perfect Princess Przywitaj się z nowonarodzoną dziewczynką dzięki koszykowi prezentowemu dla dziecka Mum's Perfect Princess! Obejmuje to kocyki, które zapewnią dziecku najdelikatniejszy dotyk od pierwszego oddechu. Dziecko będzie także pocieszone drewnianym samochodzikiem BabyK i pluszowym króliczkiem, podczas gdy mama robi regenerujące zdjęcia ptasiego gniazda z żeń-szeniem i esencją jagodową. Wyślij dziś miłość i troskę za pomocą tego szczęśliwego kosza.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Lavinia2-174369.jpg?v=1671685494",
            "/images/products/Lavinia1-317589.jpg?v=1671685494"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "2. Esencja Jagodowa Formec 70ml x 2",
                "3. Owen - 2 szt. Koc odbiorczy",
                "4. Kocyk polarowy Baby K",
                "5. Drewniany samochód-zabawka Baby K",
                "6. Pluszowy króliczek",
                "7. Suszarki Wee Wee Dry Pieluszki rozmiar M (4szt)",
                "8. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 695,
        "name": "Nadchodzi Buddy BHP",
        "handle": "aiden-bhp",
        "description": "Here Comes BuddyPrzywitaj się z koszykiem Here Comes Buddy! W tym zestawie znajduje się pluszowy Miś Buddy z naszej kolekcji Bailey Friends! Zaprojektowany dla rodziców, którzy cenią przydatne rzeczy i chcą zachować trwałe wspomnienia, ten zachwycający zestaw mieści się w kuferku inspirowanym stylem vintage, zaprojektowanym również po to, aby pomieścić wszystkie Twoje cenne pamiątki jako etui na pamiątkę.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "287.30",
        "inclTax": "287.30",
        "img": [
            "/images/products/Aiden2-690513.jpg?v=1671685463",
            "/images/products/Aiden1-736580.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pluszowy miś Buddy",
                "2. Kayen",
                "3. Poduszka 3D Airmesh 11,5 x 7,5",
                "4. BabyK Kompas opieki nad dzieckiem",
                "5. Bawełniany zestaw startowy BabyK 4 szt",
                "-Śpioch",
                "-Rękawiczka",
                "-Buty",
                "-Czapka",
                "6. Ściereczka do mycia niemowląt BabyK",
                "7. Wielofunkcyjny koc flanelowy BabyK",
                "8. Bailey'a"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm x 26cm"
    },
    {
        "id": 696,
        "name": "Zabawa w jedzenie BHP",
        "handle": "foodtime-bhp",
        "description": "Dzięki koszykowi prezentowemu dla dziecka Foodtime Fun posiłki nigdy nie będą nudne! W zestawie termiczny słoiczek na żywność BabyK i zestaw silikonowych łyżek, dzięki którym karmienie będzie zabawą i ekscytującym doświadczeniem, a także chusteczki dla niemowląt, które pozwolą wytrzeć wszelkie bałagany związane z nauką. Jedzenie będzie wyłącznie ekscytujące, a nie rozlane płyny! Już dziś wprowadź dziecko w radość jedzenia!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Foodtime-BHP2-752823.jpg?v=1671685311",
            "/images/products/Foodtime-BHP1-277595.jpg?v=1671685311"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Termiczny słoiczek na żywność Baby K",
                "2. Zestaw Munchkin – zestaw dozowników mleka modyfikowanego",
                "3. Zestaw łyżek silikonowych Baby K Bamboo",
                "4. Chusteczki dla niemowląt Pigeon 30s 100% czystej wody",
                "5. Płyn do mycia gołębi 200ml",
                "6. Gryzak silikonowy żyrafa Baby K przeznaczony do kontaktu z żywnością",
                "7. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 697,
        "name": "Witamy w świecie BHP",
        "handle": "dearest-bhp",
        "description": "Witamy w świecieKosz prezentowy dla dziecka „Witamy w świecie” gwarantuje, że ekscytujące przybycie dziecka zostanie uczczone w wielkim stylu. Do zestawu dołączyliśmy artykuły dziecięce, takie jak myjki z organicznego bambusa i poduszkę kształtującą główkę firmy BabyK oraz grzechotkę ręczną wspomagającą rozwój dziecka! Nie zapomnieliśmy także o nowych mamach i tatusiach i dodaliśmy ptasie gniazdo, aby pomóc im nabrać sił i odpocząć.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Dearest2-358761.jpg?v=1671775978",
            "/images/products/Dearest1-398488.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Naturaverde Bio Organiczny płyn do mycia ciała i włosów 200ml",
                "2. Poduszka dla dziecka K-Bawełna organiczna kształtująca głowę",
                "3. Organiczne śliniaki Shears, 3-częściowe",
                "4. Organiczna zabawka do uścisku dłoni Baby K",
                "5. Myjka Baby K- Organic Bamboo 6 szt. w opakowaniu",
                "6. Ptasie Gniazdo z Żeń-szeniem",
                "7. Znakomite pudełko kartonowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 698,
        "name": "Pierwszy prezent dla mamy i dziecka BHP",
        "handle": "mummy-bhp",
        "description": "Pierwszy prezent dla mamy i dziecka Przywitaj wyjątkowego noworodka z koszykiem na pierwszy prezent dla mamy i dziecka. Dołączyliśmy rożek, który zapewni dziecku przytulność, oraz organiczny zestaw śpioszków, dzięki którym dziecko będzie stylowe! Uroczo zapakowany w drewnianą skrzynkę, ten koszyk dla noworodka sprawi, że Twoje życzenia będą niezapomniane.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Mum-and-Baby-First-Gift-BHP1-449894.jpg?v=1722358166",
            "/images/products/Mum-and-Baby-First-Gift-BHP2.jpg?v=1722231167",
            "/images/products/Mum-and-Baby-First-Gift-BHP3_ade2bf6f-c3a0-49c5-97c3-44e9be091a9e.jpg?v=1722231205"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Płyn do prania Fiffy ze zmiękczaczem 1000ml",
                "2. Kocyk do pieluszki Baby K z organicznego bambusa",
                "3. Shears Organic 2-częściowy zestaw upominkowy ze romperem",
                "4. Różne opakowania kwiatowe",
                "5. Wykwintna drewniana skrzynia (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 699,
        "name": "Tyke Care BHP",
        "handle": "cubie-bhp",
        "description": "Tyke Care Piękny koszyk dla noworodków, zawierający niezbędne akcesoria do posiłków, zestaw Tyke Care został starannie dobrany tak, aby karmienie było dla dziecka zabawą i ekscytacją! Śliniak Sassy, ​​zapakowany w wykwintny kosz upominkowy, łączy wszystko w jedną całość, zapewniając idealną rozkosz podczas posiłku, na którą dziecko i mama mogą czekać codziennie!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Cubie2-380228.jpg?v=1671775918",
            "/images/products/Cubie1-870217.jpg?v=1671775918"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Nożyce - 5 szt. Zestaw do karmienia bambusa",
                "2. Munchkin – Łyżeczka dla niemowląt z miękką końcówką, 6 szt",
                "3. Dozownik receptury Munchkina",
                "4. Pierwszy nóż do cięcia żywności Lucky Baby Safety",
                "5. Płyn do mycia gołębi 700 ml x 2 butelki",
                "6. Śliniak Sassy 1szt",
                "7. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 700,
        "name": "Mały Aniołek Mamy BHP",
        "handle": "angel-bhp",
        "description": "Mama's Little Angel Piękny koszyk dla noworodka Mama's Little Angel zawiera wszystkie niezbędne akcesoria do kąpieli, dzięki którym świeżo upieczeni rodzice będą przygotowani! Rozpieszczaj mumie ptasimi gniazdami, aby mogły dotrzymać kroku swojemu nowemu pakietowi radości! Już dziś prześlij najlepsze życzenia nowym rodzicom, korzystając z tego kosza na prezent dla dziecka.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Mum_s-Little-Angel1.jpg?v=1705976299",
            "/images/products/Mum_s-Little-Angel2.jpg?v=1705976300"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "2. Zestaw upominkowy dla niemowląt K",
                "3. Płyn do prania Pigeon Baby Pure 800ml",
                "4. Zestaw 4 szt. Naturalnych, botanicznych produktów podróżnych dla niemowląt",
                "– Głowa Dziecka",
                "– Żel wodny dla niemowląt 45ml",
                "– Mleczny balsam dla niemowląt 45ml",
                "– Olejek do masażu dla dzieci 45ml",
                "5. Opakowania na różne kwiaty",
                "6. Wykwintna drewniana skrzynia (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 701,
        "name": "Bunny's Welcome BHP",
        "handle": "bunny-bhp",
        "description": "Bunny’s Welcome Upamiętnij tę radosną okazję za pomocą kosza prezentowego dla dziecka Bunny’s Welcome. Świętujemy nie tylko cenne przyjście na świat noworodka, ale także nowych rodziców! Dlatego właśnie dołączyliśmy nie tylko artykuły niezbędne dla dziecka, ale także poradnik przetrwania, który pomoże mu przetrwać pierwsze, pełne wyzwań miesiące. Dodaliśmy także najlepszego przyjaciela króliczka, dla dodatkowego komfortu i przytulania.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "383.15",
        "inclTax": "383.15",
        "img": [
            "/images/products/Bunny_s-Welcome-BHP2-416407.jpg?v=1708969689",
            "/images/products/Bunny_s-Welcome-BHP1.jpg?v=1708921778"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Kosz z organizerem na pieluchy Baby K",
                "2. Koreański Napój Kolagenowy 5300mg 6 x 50ml",
                "3. Opieka nad dzieckiem — poradnik przetrwania dla młodych rodziców",
                "4. Pluszowy króliczek",
                "5. Naturalny, botaniczny płyn do mycia głowy i ciała dla niemowląt Pigeon 200ml",
                "6. Naturalny Botaniczny Mleczny Balsam dla Dzieci Gołębi 200ml",
                "7. Natura Cloversoft"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 35cm"
    },
    {
        "id": 702,
        "name": "Radosne powitanie BHP",
        "handle": "joyous-bhp",
        "description": "Joyous Welcome Upamiętnij tę radosną okazję za pomocą kosza prezentowego dla dziecka Joyous Welcome. Świętujemy nie tylko cenne przyjście na świat noworodka, ale także nowych rodziców! Dlatego właśnie dołączyliśmy nie tylko artykuły niezbędne dla dziecka, ale także poradnik przetrwania, który pomoże mu przetrwać pierwsze, pełne wyzwań miesiące. Dla dodatkowego odprężenia dodaliśmy także ptasie gniazdo jako poczęstunek dla mam i tatusiów!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Joyous-Welcome-672277.jpg?v=1698138496",
            "/images/products/Joyous-Welcome1-975065.jpg?v=1698138496"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Torba na pieluchy Baby K Signature",
                "2. Opieka nad dzieckiem – poradnik przetrwania dla młodych rodziców",
                "3. Ptasie Gniazdo z Żeń-szeniem",
                "4. Zestaw upominkowy Baby K Romper",
                "5. Termiczny słoiczek na żywność Baby K",
                "6. Suszarki Pieluchy DryPantz rozmiar M (4 szt.)",
                "7. Księga kamieni milowych „Witaj kochanie”.",
                "8. Wykwintny biały kosz (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 703,
        "name": "Kojący monolog z kwiatem BHP",
        "handle": "soliloquy-bhp",
        "description": "Kojący Soliloquy z Kwiatem Uspokój nowe mumie smakowitą i odżywczą zawartością kosza prezentowego dla dziecka Soothing Soliloquy. Stworzony z myślą o młodych rodzicach, a zwłaszcza o nowych mamach, zaprasza je do oderwania się od napiętego harmonogramu nowego rodzicielstwa i poszukiwania spokojnej chwili spokoju w ramach zabiegu przypominającego spa.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "405.97",
        "inclTax": "405.97",
        "img": [
            "/images/products/Soliloquy2-253789.jpg?v=1671775907",
            "/images/products/Soliloquy1-560254.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Torba na pieluchy Baby K Signature",
                "2. SpaFoods - Longan",
                "3. Termos",
                "4. SpaFoods - Oryginalna herbata dojrzewająca imbirowa - Petite Jar 100g",
                "5. Opakowania na różne kwiaty",
                "6. Wykwintny kosz upominkowy (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 704,
        "name": "Belinda",
        "handle": "belinda",
        "description": "Ten wykwintny bukiet przedstawia harmonijną mieszankę fioletowych, różowych i białych kwiatów, w tym chryzantem, goździków i lisiantusa. Zapakowany w elegancki biały papier i ozdobiony lawendową wstążką, będzie wspaniałym prezentem na każdą uroczystość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Belinda-2c.jpg?v=1720692684",
            "/images/products/Belinda-1c.jpg?v=1720692684"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Goździki. Mattiola.  Eustomy .  Tulipan. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 705,
        "name": "Alanis",
        "handle": "alanis",
        "description": "Obejmij spokój naszym bukietem białych róż w niebieskim odcieniu. Ten wykwintny bukiet składa się z 6 białych róż w niebieskim odcieniu, delikatnie akcentowanych gałązkami zieleni i owiniętych w elegancki biały papier. Delikatne odcienie i pełna wdzięku aranżacja sprawiają, że jest to doskonały wyraz spokoju i piękna na każdą okazję. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Alanis-2a.jpg?v=1720920236",
            "/images/products/Alanis-1a.jpg?v=1720920237"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 706,
        "name": "Owocowy esencjonalny FHP",
        "handle": "botany-fhp",
        "description": "Fruity Essentials W tej uroczej drewnianej skrzynce znajdują się najświeższe i najsmaczniejsze owoce, jakie udało nam się znaleźć! Przepyszne i orzeźwiające owoce są pięknie ułożone w pięknej skrzynce, idealnej na prezent.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Aimer-FHP1-638115.jpg?v=1671685311",
            "/images/products/Aimer-FHP2-401451.jpg?v=1671685311"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gruszka Nam Shui",
                "2. Fioletowe winogrona",
                "3. Gruszka Forelle",
                "4. Pomarańczowy",
                "5. Smocze owoce",
                "6. Jabłko Fuji",
                "7. Awokado",
                "8. Kiwi",
                "9. Drewniana skrzynia (W)"
            ]
        },
        "dimention": "Rozmiar Kosza Upominkowego : 30cm x 23cm x 25cm"
    },
    {
        "id": 707,
        "name": "Oceaniczne sny FNB",
        "handle": "andrius-fnb",
        "description": "Ocean Dreams Wypłyń z bukietem kwiatów i balonów Ocean Dreams i ciesz się spokojem i spokojem płynącymi przez kojące niebieskie odcienie tej kompozycji kwiatowej. Kwiaty, w tym róże, eustoma i zakonserwowane hortensje, zawarte w tym wyjątkowym pudełku na kapelusze sprawiają, że idealnie nadaje się jako uzupełnienie każdej wiadomości, którą chcesz wysłać.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty gratulacyjne",
            "Bukiet balonów na ukończenie studiów"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Andrius-FNB1-844310.jpg?v=1671685497",
            "/images/products/Andrius-FNB2-352019.jpg?v=1671685497"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Zachowana hortensja",
                "4. Eukaliptus",
                "5. Spersonalizowany tekst na balonie z 4 mini balonami",
                "6. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 708,
        "name": "Kapryśny zachód słońca FNB",
        "handle": "laurice-fnb",
        "description": "Fantazyjny zachód słońca Fantazyjny zachód słońca łączy bajkę z romantyzmem w pięknej mieszance bujnej zieleni i kwiatów, w tym róż, eustom, gerber, konserwowanej hortensji i liści monet. Ten bukiet kwiatów z balonami, zapakowany w wykwintne pudełko na kapelusze, wysyła na balonie spersonalizowaną wiadomość, aby dać znać bliskim, jak bardzo rozświetlają Twoje życie.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Laurice1-808690.jpg?v=1671775978",
            "/images/products/Laurice2_1d43ad42-f678-485a-b1eb-3cfcd18bb344-162505.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Gerbera",
                "4. Zachowana hortensja",
                "5. Eukaliptus",
                "6. Spersonalizowany tekst na balonie",
                "7. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 709,
        "name": "Serdeczne życzenia FNB",
        "handle": "monalisa-fnb",
        "description": "Endearing Wishes Podobnie jak jej imiennik, kompozycja kwiatowa i balonowa Endearing Wishes oferuje obdarowanej osobie najcieplejsze i najserdeczniejsze życzenia w najbardziej niezapomniany sposób. Róże, eustoma i mattiola w tym eleganckim pudełku z kwiatami zapewnią odpowiedni nastrój na każdą okazję. Wyślij spersonalizowaną wiadomość na balonie, aby uczynić ten prezent wyjątkowym.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Monalisa-FNB1-868010.jpg?v=1671685202",
            "/images/products/Monalisa-FNB2-716363.jpg?v=1671685202"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Spray różany",
                "4. Eukaliptus",
                "5. Mattiola",
                "6. Zachowany kwiat ryżu",
                "7. Spersonalizowany tekst na balonie z 4 mini balonami",
                "8. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 710,
        "name": "Ivory Dreams FNB",
        "handle": "claudine-fnb",
        "description": "Ivory Dreams Definicja wdzięku i piękna, Ivory Dreams to elegancki bukiet, który przyniesie obdarowanej osobie radość i kwitnące wyrafinowanie. Zawiera kolekcję hortensji, eustomy i róż, podkreśloną dziurawcem, kaspią i liśćmi monety.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Claudine1A-757902.jpg?v=1671685464",
            "/images/products/Claudine3-902323.jpg?v=1671685464"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Spray różany",
                "4. Zachowana hortensja",
                "5. Kaspia",
                "6. Eukaliptus",
                "7. Spersonalizowany tekst na balonie",
                "8. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 711,
        "name": "Zmierzch FNB",
        "handle": "shanice-fnb",
        "description": "Dusk Kwitnący romantyczność i tajemniczość tuż przed zapadnięciem zmroku, bukiet kwiatów i balonów Dusk przedstawia bogate odcienie natury poprzez róże, eustomę, goździki i mattiolę.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty gratulacyjne"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/DuskFNB1-443429.jpg?v=1709056409",
            "/images/products/Shanice2-934469.jpg?v=1709013253"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Goździk",
                "4. Zachowana hortensja",
                "5. Kaspia",
                "6. Eukaliptus",
                "7. Spersonalizowany tekst na balonie z 4 mini balonami",
                "8. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 712,
        "name": "Skromne szepty FNB",
        "handle": "stella-fnb",
        "description": "Demure WhispersWyraź komfort, miłość i czułość dzięki bukietowi kwiatów i balonów Demure Whispers. Stylizowana na różowe i czysto białe kwiaty hortensji, eustomy i róż, z akcentami dziurawca, kaspii i liści monety, jest to aranżacja na te najsłodsze chwile. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "323.82",
        "inclTax": "323.82",
        "img": [
            "/images/products/Demure-Whispers-FNB1-454412.jpg?v=1709143338",
            "/images/products/Aila2A_cfb46203-7468-49ae-86ef-0f1c0d09da0e-930299.jpg?v=1709083986"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Hortensja",
                "2. Eustoma",
                "3. Róże",
                "4. Spray różany",
                "5. Hypericum",
                "6. Kaspia",
                "7. Eukaliptus",
                "8. Spersonalizowany tekst na balonie z 4 mini balonami",
                "9. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 713,
        "name": "Błękitna Wróżka FNB",
        "handle": "emma-fnb",
        "description": "Błękitna WróżkaKosz z kwiatami i balonami Błękitna Wróżka zawiera róże, eustomy, konserwowane hortensje i liście monet, aby wysłać obdarowanej osobie ducha spokoju i pogody ducha. Rozpieszczaj je, pomagając im w dalszym relaksie dzięki naszemu dyfuzorowi mgiełki drzewnej w Sleep Ocean, aby delikatnie unieść je spokojem morza. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty na rocznicę"
        ],
        "price": "583.96",
        "inclTax": "583.96",
        "img": [
            "/images/products/Emma1A-621180.jpg?v=1671685011",
            "/images/products/Emma2-668178.jpg?v=1671685011"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mały dyfuzor Wood Mist Sleep Ocean 60ml",
                "2. Róże",
                "3. Eustoma",
                "4. Zachowana hortensja",
                "5. Eukaliptus"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 714,
        "name": "Magiczny Minstrel FNB",
        "handle": "sophia-fnb",
        "description": "Magical MinstrelMagiczny Minstrel to bukiet kwiatów i balonów w rumianym wyrazie miłującej dobroci. Miękkie różowe róże słodko olśniewają wśród eustomy, gerber, kwiatów wosku, goździków i bujnej zieleni dziurawca i liści monet.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "303.28",
        "inclTax": "303.28",
        "img": [
            "/images/products/Sophia1B-103244.jpg?v=1671685491",
            "/images/products/Sophia2-362093.jpg?v=1671685491"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Gerbera",
                "4. Wosk",
                "5. Hypericum",
                "6. Goździk",
                "7. Eukaliptus",
                "8. Spersonalizowany tekst na balonie z 4 mini balonami",
                "9. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 715,
        "name": "Czysta świeżość FHP",
        "handle": "radiance-fhp",
        "description": "Pure Freshness Pure Freshness z pewnością będzie świetnym prezentem dla każdego, kto czuje się trochę przygnębiony. Ze wspaniałymi kwiatami, najświeższymi owocami i zdrowym ptasim gniazdem, który nie poczuje się ożywiony.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe",
            "Kosze korporacyjne"
        ],
        "price": "355.76",
        "inclTax": "355.76",
        "img": [
            "/images/products/Radiance1a-574205.jpg?v=1671685641",
            "/images/products/Radiance2a-321311.jpg?v=1671685641"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gniazdo dla ptaków w nowiu 70 ml x 6",
                "2. Awokado",
                "3. Kiwi",
                "4. Zielone jabłko",
                "5. Pomarańczowy",
                "6. Jabłko Fuji",
                "7. Róże",
                "8. Gerbera",
                "9. Średnia orchidea",
                "10. Eustoma",
                "11. Drewniana skrzynia (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 716,
        "name": "Sakura marzy o FNB",
        "handle": "mia-fnb",
        "description": "Sakura Dreams Różowe akcenty w tej aranżacji z suchymi kwiatami bawełny, różami i sprayem różanym sprawią, że obdarowana osoba poczuje się, jakby została obsypana pięknymi kwiatami wiśni, które oznaczają nowy początek. Spersonalizuj swoje życzenia za pomocą dostosowywanej wiadomości w balonie!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie",
            "Bukiet balonów urodzinowych",
            "Balony i kwiaty gratulacyjne",
            "Bukiet balonów na ukończenie studiów"
        ],
        "price": "323.82",
        "inclTax": "323.82",
        "img": [
            "/images/products/Sakura-Dreams-FNB1.jpg?v=1709094099",
            "/images/products/Mia2-929632.jpg?v=1709094099"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Bawełna z suszonymi kwiatami",
                "2. Róże",
                "3. Eustoma",
                "4. Spray różany",
                "5. Hypericum",
                "6. Zachowana hortensja",
                "7. Eukaliptus",
                "8. Spersonalizowany tekst na balonie z 6 mini balonami",
                "9. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 717,
        "name": "Pakiet odzyskiwania FHP",
        "handle": "flourish-fhp",
        "description": "Pakiet Regeneracyjny Ten niesamowity pojemnik z tonikiem owocowym z pewnością będzie świetnym prezentem dla każdego, kto czuje się trochę przygnębiony. Ze wspaniałymi kwiatami, najświeższymi owocami i zdrową esencją z kurczaka marki Brand, która nie poczuje się ożywiona tego samego dnia.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe",
            "Kosze korporacyjne"
        ],
        "price": "408.25",
        "inclTax": "408.25",
        "img": [
            "/images/products/Flourish-FHP1-350877.jpg?v=1702988906",
            "/images/products/Flourish-FHP2-288362.jpg?v=1702988906"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Marki Esencja z kurczaka 42ml x 6",
                "2. Fioletowe winogrona",
                "3. Pomarańczowy",
                "4. Mango",
                "5. Smocze owoce",
                "6. Kiwi",
                "7. Kompozycja kwiatowa: hortensja, róże, gerbera, mathiola, eustoma",
                "8. Drewniana skrzynia (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 35cm"
    },
    {
        "id": 718,
        "name": "Niezbędny piknik FHP",
        "handle": "naturie-fhp",
        "description": "Picnic Essentials Ten niesamowity kosz z kwiatami owocowymi z pewnością będzie doskonałym prezentem dla każdego. Ze wspaniałymi kwiatami i najświeższymi owocami, kto nie poczułby się ożywiony tego samego dnia.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe",
            "Kosze korporacyjne"
        ],
        "price": "431.07",
        "inclTax": "431.07",
        "img": [
            "/images/products/Naturie-FHP1-676778.jpg?v=1671685636",
            "/images/products/Naturie-FHP2-364632.jpg?v=1671685636"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Jabłko Fuji",
                "2. Pomarańczowy",
                "3. Kiwi",
                "4. Zielone jabłko",
                "5. Gruszka Nam Shui",
                "6. Granat",
                "7. Smocze owoce",
                "8. Gruszka Forelle",
                "9. Truskawka",
                "10. Awokado",
                "11. Zielone winogrona",
                "12. Kompozycja kwiatowa: Gerbera, róże, spray różany, dziurawiec, liść monety",
                "13. Duży kosz na śmieci (zmiana kosza piknikowego)"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 719,
        "name": "Płatek Marzeń FNB",
        "handle": "vida-fnb",
        "description": "Petal Dreams Celowo delikatny bukiet kwiatów i balonów, składający się z suchych kwiatów bawełny, róż i sprayów różanych, Petal Dreams wysyła więcej niż tylko subtelny przekaz! Każdy kwiat jest delikatnym, ale przemyślanym przypomnieniem Twoich życzeń i miłości, aby stworzyć piękny sposób na wyrażenie swoich najcieplejszych uczuć.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie",
            "Bukiet balonów urodzinowych",
            "Balony i kwiaty gratulacyjne",
            "Balony i kwiaty na rocznicę",
            "Bukiet balonów na ukończenie studiów"
        ],
        "price": "378.58",
        "inclTax": "378.58",
        "img": [
            "/images/products/Petal-Dreams-FNB1.jpg?v=1709094495",
            "/images/products/Vida2_2f558139-7286-4ce0-ad16-026d83ed0725-362869.jpg?v=1709094495"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Szwajcarska cienka czekolada Lindt",
                "2. Bawełna z suszonymi kwiatami",
                "3. Róże",
                "4. Eustoma",
                "5. Spray różany",
                "6. Hyperikum",
                "7. Zachowana hortensja",
                "8. Eukaliptus",
                "9. Spersonalizowany tekst na balonie z 6 mini balonami",
                "10. Wykwintne, ręcznie wykonane pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 720,
        "name": "Odnowione Wellness FHP",
        "handle": "wellness-fhp",
        "description": "Renewed WellnessPure Freshness z pewnością będzie doskonałym prezentem dla każdego, kto czuje się trochę przygnębiony. Ze wspaniałymi kwiatami, najświeższymi owocami i zdrowym ptasim gniazdem, który nie poczuje się ożywiony.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe",
            "Kosze korporacyjne"
        ],
        "price": "451.61",
        "inclTax": "451.61",
        "img": [
            "/images/products/Wellness-FHP1-655946.jpg?v=1671685641",
            "/images/products/Wellness-FHP2.jpg?v=1666319006"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gniazdo dla ptaków w nowiu 70 ml x 6",
                "2. Mango",
                "3. Kiwi",
                "4. Borówka",
                "5. Zielone winogrona",
                "6. Smocze owoce Wietnam",
                "7. Kompozycja kwiatowa: Anturium małe, róże, gerbera, średnia orchidea, protea"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 721,
        "name": "Powrót do Twojego najlepszego FHP",
        "handle": "sequoia-fhp",
        "description": "Powrót do najlepszej formy Ten pięknie wykonany kosz na owoce i tonik z pewnością będzie wspaniałym prezentem dla każdego. Ze wspaniałymi kwiatami, najświeższymi owocami, Yomeishu, Esencją z kurczaka i zdrową esencją jagodową, która nie czułaby się teraz ożywiona.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe",
            "Kosze korporacyjne"
        ],
        "price": "691.22",
        "inclTax": "691.22",
        "img": [
            "/images/products/Sequoia-FHP1-727059.jpg?v=1671684752",
            "/images/products/Sequoia-FHP2-461133.jpg?v=1671684752"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Yomeishu 750ml",
                "2. Marka Esencja Jagodowa",
                "3. Marka Esencja Kurczaka 42ml x 6",
                "4. Mango",
                "5. Krystalicznie czysty balon „Wróć do zdrowia wkrótce”.",
                "6. Zielone winogrona",
                "7. Jabłko Fuji",
                "8. Zielone jabłko",
                "9. Gruszka Nam Shui",
                "10. Kiwi",
                "11. Roślina kwiatowa"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 722,
        "name": "Jesienne kwiaty FNB",
        "handle": "della-fnb",
        "description": "Autumn Blooms Bukiet kwiatów i balonów Autumn Blooms składa hołd najbardziej przytulnej porze roku. Róże, eustomy, hortensje i woski są artystycznie stylizowane w wykwintnym pudełku na kapelusze przez jednych z najlepszych kwiaciarni w Polsce.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Della1-560832.jpg?v=1671684751",
            "/images/products/Della2-367445.jpg?v=1671684751"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Hortensja",
                "4. Spray różany",
                "5. Wosk",
                "6. Różowa poduszka",
                "7. Chińska latarnia",
                "8. Dziki kwiat",
                "9. Eukaliptus",
                "10. Spersonalizowany tekst na balonie z 4 mini balonami",
                "11. Wykwintne, ręcznie wykonane pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 723,
        "name": "Z poważaniem FNB",
        "handle": "alice-fnb",
        "description": "Truly YoursZorganizuj ukochanej osobie naprawdę wyjątkową okazję za pomocą balonu Truly Yours i kosza na kwiaty. Kosz na balony, składający się z mini tortu z czarnego lasu, będzie zapakowany w wykwintne pudełko na kapelusze wypełnione zakonserwowanymi różami, hortensjami i kwiatami ryżu. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Truly-Yours-FNB1.jpg?v=1709014877",
            "/images/products/Alice3-224521.jpg?v=1709014877"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mini Tort Czarny Las",
                "2. Zachowana róża",
                "3. Zachowana hortensja",
                "4. Zachowany kwiat ryżu",
                "5. Mini nasiona Pittosporum",
                "6. Zachowana Gerbera",
                "7. Eukaliptus",
                "8. Drewniana zawieszka z okazji urodzin"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 724,
        "name": "Owsianko Orzechowy Z Kwiatowym WHP",
        "handle": "fann-whp",
        "description": "Oatly Nuttylicious With Flower Zachwycający asortyment świeżych kwiatów i zdrowych przekąsek w koszyku Oatly Nuttylicious With Flower Get Well Soon sprawia, że ​​jest to naprawdę niesamowity prezent. Ten uwielbiany asortyment przekąsek jest dostępny w wykwintnej skrzynce, dzięki której Twoja ukochana osoba natychmiast poczuje się lepiej! ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "337.51",
        "inclTax": "337.51",
        "img": [
            "/images/products/FannWHP1a-400037.jpg?v=1671685496",
            "/images/products/Fann-WHP2a-488051.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pudełko z odmianami Amazin Graze Granola x 8 opakowań 320g",
                "2. Płatki owsiane błyskawiczne The Muesli Company o niskiej zawartości cukru 500g",
                "3. English Tea Shop – Ekologiczna herbata Super Berries Premium 40g",
                "4. Ciasteczka pełnoziarniste Colada 144g",
                "5. Organiczny napój owsiany bez nabiału z Pacyfiku 946ml",
                "6. Harvest Box Super Mix 45g",
                "7. Skrzynia do zbiorów PowerMix 45g",
                "8. Opakowania na różne kwiaty",
                "9. Wykwintna skrzynia (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 725,
        "name": "Słodka witalność FNB",
        "handle": "kara-fnb-2",
        "description": "Sweet Vitality, pełen niezbędnych zdrowotnych toników, balonik Sweet Vitality bezpośrednio przekaże Twoje życzenia i pomoże ukochanej osobie stanąć na nogi w mgnieniu oka! Zapakowane w wykwintne pudełko na kapelusze, możesz być pewien, że Twoje słodkie uczucia będą z przyjemnością patrzeć! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Kara1-425005.jpg?v=1671775915",
            "/images/products/Kara2_cd990c6b-e174-4202-a2ce-d2340b91ce26-115049.jpg?v=1671775915"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Esencje z jagód Fomec 2 x 42g",
                "2. Esencje z jagód Fomec 2 x 42g",
                "3. Esencje z kurczaka w nowiu 4 x 68g"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 726,
        "name": "Święto Kolorów FNB",
        "handle": "alaska-fnb",
        "description": "Święto Kolorów Uczta dla oczu i ust, kosz na owoce Święto Kolorów to prawdziwy nasz bestseller.  Ta piękna kompozycja zachwyca zmysły niezliczoną ilością świeżych kolorów natury, które będą upamiętniać każdą okazję. Idealny dla miłośników owoców lub tych, którzy po prostu nie mogą oprzeć się naturze, ten balonowy kosz na owoce to prezent, który naprawdę przemawia prosto z serca. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Feast-of-Colours-FNB1-721251.jpg?v=1709143343",
            "/images/products/Alaska2-635136.jpg?v=1709083432"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Truskawka",
                "2. Mango",
                "3. Pomarańczowy",
                "4. Jabłko",
                "5. Kiwi",
                "6. Zielone jabłko",
                "7. Smoczy owoc"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 727,
        "name": "Dar Natury FNB",
        "handle": "emily-fnb",
        "description": "Dar NaturyWierzymy, że prezent od natury to najlepszy prezent, jaki możesz otrzymać! Mając to na uwadze, stworzyliśmy balonowy koszyk z owocami Nature’s Gift, zaprojektowany tak, aby zachwycać zmysły i podniebienie! Dostarcz dodatkową dawkę rozkoszy dzięki spersonalizowanej wiadomości wyświetlanej na naszym balonie!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Nature_s-Gift-FNB1.jpg?v=1709084250",
            "/images/products/Emily2-387663.jpg?v=1709084250"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Esencje jagodowe Formec 2 x 42g",
                "2. Smakuj Azjatycka Owsianka Wegetariańska 400g",
                "3. Cavendisha",
                "4. Tradycyjny napój gazowany Bickford's – Sarsaparilla 275ml",
                "5. Owoce borówki"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 728,
        "name": "Świecące FNB",
        "handle": "evie-fnb",
        "description": "Glow Up Zaskocz entuzjastów zdrowia świecącymi owocami i koszykiem na balony! Zawierający wyśmienite smakołyki od Amazin’ Graze, pyszne świeże owoce i soki, ten pełen radości kosz owoców naprawdę rozpieści Twojego odbiorcę! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty gratulacyjne"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Glow-Up-FNB1-553106.jpg?v=1709056521",
            "/images/products/Evie2-770749.jpg?v=1709013929"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Miska na dobroć niesamowitego wypasu",
                "2. Ciasteczka pełnoziarniste Colada 144g",
                "3. Gusto Organiczna sycylijska czerwona pomarańcza 275ml",
                "4. Pomarańczowy",
                "5. Jabłko",
                "6. Zielone jabłko",
                "7. Awokado",
                "8. Kiwi",
                "9. Gruszka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 729,
        "name": "Oszałamiające uśmiechy FNB",
        "handle": "florence-fnb",
        "description": "Oszałamiające uśmiechy Twój odbiorca będzie promienieć radością, gdy otrzyma balon Oszałamiający uśmiech i kosz na jedzenie! Ten kosz, pełen pysznych smakołyków, jest po prostu idealny na każdą okazję. Aby Twój prezent był wyjątkowy, umieść spersonalizowaną wiadomość na naszym balonie ekspozycyjnym! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie",
            "Balony i kwiaty gratulacyjne"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Stunning-Smiles-FNB1_75a2147c-10d0-4a2e-9206-714ab12d58f0-389261.jpg?v=1709144582",
            "/images/products/Florence2-570213.jpg?v=1709085455"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pudełko upominkowe Golden Bon Bon Soft Almond Nougat 130g",
                "2. Cupido Muszle Czekoladowe z Orzechami Laskowymi 250g",
                "3. Cavendisha",
                "4. Gavottes Crepe Dentelle 125g",
                "5. Masło Owocowe ST Dalfour ( 3 x 28g )",
                "6. Spersonalizowany tekst na balonie z 6 mini balonami",
                "7. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 730,
        "name": "Prosta harmonia FNB",
        "handle": "maya-fnb",
        "description": "Simple Harmony, wypełniony niezbędnymi tonikami zdrowotnymi, kosz na balony Sweet Vitality bezpośrednio przekaże Twoje życzenia i pomoże ukochanej osobie stanąć na nogi w mgnieniu oka! Zapakowane w wykwintne pudełko na kapelusze, możesz być pewien, że Twoje słodkie uczucia będą z przyjemnością patrzeć! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Maya1-328078.jpg?v=1671775907",
            "/images/products/Maya2_1ea941a0-961f-4b3f-8151-471d69e52a24-233210.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo Złoty Skarb Śnieżny Grzyb 3x70ml",
                "2. Tradycyjny napój gazowany Bickfords – cytryna i limonka",
                "3. Tiptree – Pomarańczowe",
                "4. Ciasteczka pełnoziarniste Colada 144g",
                "5. Pluszowy króliczek"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 731,
        "name": "Podnoszący na duchu nastrój FNB",
        "handle": "heidi-fnb",
        "description": "Podnoszący na duchZdrowy wybór pysznych i odżywczych smakołyków w koszyku z balonem Uplifting Mood jest gotowy, aby w dobrym stylu przesłać Ci najgorętsze życzenia zdrowia. Ten kosz na prezenty, zapakowany w wykwintne pudełko na kapelusze, to spersonalizowana wiadomość w formie balonu, która pomoże obdarowanej osobie stanąć na nogi!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "419.66",
        "inclTax": "419.66",
        "img": [
            "/images/products/Heidi1-160979.jpg?v=1671775918",
            "/images/products/Heidi2-528771.jpg?v=1671775919"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Botanica Okrągły Żel Lekki 90g",
                "2. Germinal Organiczne Płatki Gryczane 200g",
                "3. Ciasteczka Waflowe Beryl’s z Mleczną Czekoladą Gianduja 80g",
                "4. Ciasteczka pełnoziarniste Colada 144g",
                "5. Marmolada Mrs. Bridges z pomarańczy i mandarynek",
                "6. Tiptree – Pomarańczowe",
                "7. Naturalny 100% Naturalny Sok Gruzji – Gruszka 300ml",
                "8. Małgosia z kiełkami nerkowca Himalajska różowa sól, 150g"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 732,
        "name": "Annabelle",
        "handle": "annabelle",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Flower Boxes",
        "tags": [],
        "price": "171.15",
        "inclTax": "171.15",
        "img": [
            "/images/products/Annabelle1-395461.jpg?v=1671684749",
            "/images/products/Annabelle2.jpg?v=1666850558"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mieszanka suszona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 21cm*25cm"
    },
    {
        "id": 733,
        "name": "Komfort (IV)",
        "handle": "comfort-iv",
        "description": "Składając szczere kondolencje, nasze kwietniki stanowią pogodną mieszankę białych i szampańskich chryzantem, którym z wdziękiem towarzyszą czyste białe lilie i delikatne gerbery. Ta elegancka aranżacja stanowi godny hołd, symbolizując współczucie i pamięć, zapewniając pocieszenie w chwilach smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Comfort-_IV_-1d.jpg?v=1720181297",
            "/images/products/Condolence-Card-2024_1_93a23b96-2f79-40ad-bf81-caa9540b660b.jpg?v=1720181313",
            "/images/products/Comfort-_IV_-2d.jpg?v=1720181297"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Spryskaj goździki. Sztuczne kwiaty. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 734,
        "name": "Życzę powodzenia FNB",
        "handle": "nyra-fnb",
        "description": "Well WishesIdealne dla osób regenerujących się w domu, ponieważ mogą delektować się naszymi domowymi i smacznymi smakołykami, które zostały wybrane tak, aby rozgrzać duszę. Dla dodatkowego zastrzyku relaksu dołączyliśmy także świecę zapachową Botanica Home! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "513.22",
        "inclTax": "513.22",
        "img": [
            "/images/products/Nyra1_65b220a6-f87b-46d1-b785-5485f534fe98-668107.jpg?v=1671775978",
            "/images/products/Nyra2-937954.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Świeca zapachowa Fleur Botanica Home",
                "2. Ekologiczne Płatki Gryczane Germinal 200g",
                "3. Cavendisha",
                "4. Taylors of Harrogate - Cytryna i Imbir 50g",
                "5. Marmolada Mrs. Bridges z pomarańczy i mandarynek",
                "6. Ciasteczka Waflowe Beryl z Mleczną Czekoladą Gianduja 80g",
                "7. Tiptree – Pomarańczowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 735,
        "name": "Różowy gazowany/wyrafinowany FNB",
        "handle": "brianna-fnb",
        "description": "Pink Fizzy/WyrafinowanieNapij się szampana i przygotuj się na najlepsze święto w historii! Bukiet różowych kwiatów i balonów Fizzy zawiera szampana Moneta, róże, spray różany, eustomę, konserwowane hortensje, dziurawiec i liście monet i jest imprezą samą w sobie! Zwieńczony balonem z Twoją spersonalizowaną wiadomością, to wyjątkowe pudełko na kwiaty wywoła uśmiech na twarzy odbiorcy i sprawi, że będzie to naprawdę niezapomniana uroczystość.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych",
            "Balony i kwiaty gratulacyjne",
            "Balony i kwiaty na rocznicę"
        ],
        "price": "574.84",
        "inclTax": "574.84",
        "img": [
            "/images/products/Pink-Fizzy-Sophistication-FNB1.jpg?v=1709013645",
            "/images/products/Brianna2_591d1bcb-66b3-40dc-9d43-5306170e20e8-597711.jpg?v=1709013645"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Moeta i Chandona 375ml",
                "2. Bawełna z suszonymi kwiatami",
                "3. Róże",
                "4. Eustoma",
                "5. Spray różany",
                "6. Hyperikum",
                "7. Zachowana hortensja",
                "8. Eukaliptus",
                "9. Spersonalizowany tekst na balonie z 6 mini balonami",
                "10. Wykwintne, ręcznie wykonane pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 736,
        "name": "Kwitnący kosz zdrowia z kwiatem WHP",
        "handle": "mildred-whp",
        "description": "Kwitnący Kosz Zdrowia z Kwiatami Rozbudź radość dzięki Kwitnącemu Koszowi Zdrowia z Kwiatem Koszyczek szybkiego powrotu do zdrowia, pełen odżywczych i zachwycających smakołyków! Dodaj dodatkowej dawki zdrowia swoim odbiorcom dzięki naszym najwyższej jakości tonikom zdrowotnym prezentowanym w wykwintnym koszu upominkowym!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Mildred2a-270828.jpg?v=1671685207",
            "/images/products/Mildred1a-593942.jpg?v=1671685207"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "2. Markowa Esencja Kurczaka 3 x 41ml",
                "3. Napój Yu Xiang Yan White Fungas Bird’s Nest 3 x 75ml",
                "4. Koreański Napój Kolagenowy 5300mg 6 x 50ml",
                "5. Opakowania na różne kwiaty",
                "6. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 737,
        "name": "Obfite piękno FNB",
        "handle": "carmen-fnb",
        "description": "Obfite pięknoPiękno to zdrowie, a zdrowie to szczęście! Życz komuś wspaniałego zdrowia i urody z balonem Bountiful Beauty i koszem na owoce! Ten wspaniały kosz, wypełniony zdrowymi smakołykami i świeżymi owocami, z pewnością wywoła uśmiech na twarzy obdarowanej osoby. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "595.37",
        "inclTax": "595.37",
        "img": [
            "/images/products/Bountiful-Beauty-FNB1-737754.jpg?v=1709143101",
            "/images/products/Carmen2-665481.jpg?v=1709084428"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Marki Ptasie Gniazdo 2 x 42ml",
                "2. Benedyktyński DOM 37,5ml",
                "3. Aalst Puszka czekolady mlecznej z całych orzechów laskowych 150g",
                "4. Breitsamer Honig Surowy Miód 500g",
                "5. Jabłko",
                "6. Awokado",
                "7. Zielone jabłko",
                "8. Kiwi"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 738,
        "name": "Tawny Zaklęcie FNB",
        "handle": "alma-fnb",
        "description": "Tawny EnchantmentTawny pochodzi od anglo-normańskiego słowa taune, które oznacza opalony, i właśnie taki nastrój opalenizny oddajemy w pudełku z kwiatami i balonami Tawny Enchantment. Piękne róże, eustomy, spraye różane i liście monet osadzone są w wykwintnym pudełku na kapelusze, które nadaje wygodny, nowoczesny klimat. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na ukończenie studiów"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Alma1-294385.jpg?v=1671775916",
            "/images/products/Alma2-760761.jpg?v=1671775916"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Hypericum",
                "4. Spray różany",
                "5. Ping-pong",
                "6. Eukaliptus",
                "7. Spersonalizowany tekst na balonie z 3 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 739,
        "name": "Lojalne uściski FNB",
        "handle": "davina-fnb",
        "description": "Lojalne Objęcie Lojalność jest cnotą, którą należy należycie celebrować. Czy jest na to lepszy sposób poprzez Lojalne Uściski? Wspaniały bukiet kwiatów i balonów, który robi ogromne wrażenie wraz z eleganckimi różami, eustomami, dziurawcem i liśćmi monet, będzie właściwym wyrazem uznania w przypadku okazji wymagającej jedynie ekstrawagancji i stylu. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Davina1-555625.jpg?v=1671685424",
            "/images/products/Davina2.jpg?v=1667266787"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Kwiat ping-ponga",
                "4. Hypericum",
                "5. Eukaliptus",
                "6. Spersonalizowany tekst na balonie z 4 mini balonami",
                "7. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 740,
        "name": "Eleganckie Perłowe FNB",
        "handle": "charley-fnb",
        "description": "Elegancka perła Proste wyrafinowanie z anturium, weroniką, eustomą, mattiolą i konserwowanymi kwiatami ryżu było tym, o co zabiegali nasi kwiaciarnie, tworząc eleganckie pudełko z kwiatami perłowymi i balonem. Aby zapewnić dodatkową zabawę, dodaliśmy JP. Chenet Ice Edition, aby stworzyć z tego bukiet kwiatów i balonów typu „wszystko w jednym” dla tych przyjęć, które wymagają nieco większej okazałości! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty gratulacyjne"
        ],
        "price": "378.58",
        "inclTax": "378.58",
        "img": [
            "/images/products/Elegant-Pearl-FNB1.jpg?v=1709004447",
            "/images/products/Charley2_c559649f-e539-4d7b-8c8a-4c549ef48e9f-659860.jpg?v=1709004447"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. JP. Chenet Colombard Sauvignon 250ml",
                "2. Róże",
                "3. Anturium",
                "4. Weronika",
                "5. Eustoma",
                "6. Kwiat ping-ponga",
                "7. Mattiola",
                "8. Zachowany kwiat ryżu",
                "9. Mini nasiona Pittosporum",
                "10. Liście Pittosporum",
                "11. Spersonalizowany tekst na balonie z 3 mini balonami",
                "12. Wykwintne, ręcznie wykonane pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 741,
        "name": "Uroczy szmaragdowy FNB",
        "handle": "malina-fnb",
        "description": "Uroczy szmaragd Uroczy szmaragd z pewnością rozpieszcza obdarowaną osobę, rozpieszczając ją bukietem z balonu i koszyka, pięknie ułożonym z róż, eustom, sprayów z goździków i konserwowanych plasterków pomarańczy. Pomóż bliskiej osobie zrelaksować się dzięki naszemu dyfuzorowi mgiełki drzewnej w Sleep Ocean.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "629.60",
        "inclTax": "629.60",
        "img": [
            "/images/products/Charming-Emerald-FNB1.jpg?v=1709084748",
            "/images/products/Malina2-337836.jpg?v=1709084748"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mały dyfuzor Wood Mist Sleep Ocean 60ml",
                "2. Róże",
                "3. Eustoma",
                "4. Spray goździkowy",
                "5. Konserwowany plasterek pomarańczy",
                "6. Zachowany kwiat ryżu",
                "7. Mini nasiona Pittosporum",
                "8. Liście Pittosporum"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 742,
        "name": "Dzięgiel",
        "handle": "angelica",
        "description": "„Emocja, która może złamać ci serce, czasami jest tą, która je leczy”. - Nicholasa Sparksa",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Angelica1A-507952.jpg?v=1671684750",
            "/images/products/Angelica2A-445687.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże RP Moab i Pomarosa."
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 743,
        "name": "Emmie",
        "handle": "emmie",
        "description": "Bukiet ten zawiera delikatną mieszankę pastelowych róż, białej eustomy, mattioli i subtelnej zieleni, podkreślonej akcentami delikatnych różowych kwiatów woskowych i niebieskiego oxypetalum. Jest elegancko owinięty w jasnofioletowy papier i wstążkę, tworząc wdzięczną i romantyczną prezentację kwiatową, idealną dla delikatnych uczuć.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Emmie-2e.jpg?v=1720889708",
            "/images/products/Emmie-1e.jpg?v=1720889708"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 744,
        "name": "Russet Zaklęcie FNB",
        "handle": "rosa-fnb",
        "description": "Russet Enchantment Russet Enchantment to ręcznie zbierana kompozycja kwiatowo-balonowa we wspaniałych rdzawych odcieniach. Róże, eustomy, goździki, dziurawiec i liście monet to prawdziwy pokaz kwiatowego kunsztu.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Rosa1-251436.jpg?v=1702988969",
            "/images/products/Rosa2_a17e3d5d-c740-4013-a11f-91c6e618672f-188444.jpg?v=1702988969"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Goździk",
                "4. Hypericum",
                "5. Eukaliptus",
                "6. Spersonalizowany tekst na balonie z 4 mini balonami",
                "7. Drewniana skrzynia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 745,
        "name": "Radosna pieszczota FNB",
        "handle": "kassidy-fnb",
        "description": "Joyful CaresRadosna pieszczota jest właśnie tym, dla czego zaprojektowano ten bukiet kwiatów i balonów. Wesołe czerwone i różowe odcienie kwiatów, takich jak róże, spray różany, goździki, eustomy i dziurawiec są pomysłowo ułożone w drewnianej skrzyni, aby przekazać najszczęśliwsze uczucia.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty gratulacyjne"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Joyful-Caress-FNB1-753815.jpg?v=1709056634",
            "/images/products/Kassidy2-479080.jpg?v=1709013420"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Spray różany",
                "3. Goździk",
                "4. Eustoma",
                "5. Hypericum",
                "6. Eukaliptus",
                "7. Spersonalizowany tekst na balonie z 3 mini balonami",
                "8. Drewniana skrzynia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 746,
        "name": "Słodka lawenda FNB",
        "handle": "estrella-fnb",
        "description": "Słodka LawendaPokaż bliskiej osobie, że jest Królową Twojego Serca, wysyłając bukiet kwiatów i balonów ze Słodką Lawendą. Ekstrawaganckie róże, eustomy, Weroniki i Mattiole znajdują się w skrzyni ekspozycyjnej, a personalizowana wiadomość na balonie sprawia, że ​​ten wspaniały zestaw lawendowych odcieni będzie prezentem godnym rodziny królewskiej!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty na rocznicę"
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Estrella1-497981.jpg?v=1671775914",
            "/images/products/Estrella2-553581.jpg?v=1671775914"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Bawełna z suszonymi kwiatami",
                "3. Eustoma",
                "4. Weronika",
                "5. Mattiola",
                "6. Spray różany",
                "7. Goździk",
                "8. Zachowany kwiat ryżu",
                "9. Pittosporum Mini",
                "10. Rozmaryn",
                "11. Spersonalizowany tekst na balonie",
                "12. Drewniana skrzynia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 747,
        "name": "Święto Indygo FNB",
        "handle": "jayley-fnb",
        "description": "Święto Indygo Święto Indygo jest hołdem dla powiedzenia „Pogodne wiatry i morza”, które służy do wysyłania życzeń dobrej pogody i bezpiecznej podróży osobom wyruszającym w morze. To pudełko z kwiatami i balonami życzy odbiorcy bezpiecznej podróży i powodzenia wraz z różami, eustomami, zakonserwowanymi kwiatami ryżu i hortensjami.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty gratulacyjne"
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Jayley1_9ce36f91-cf3b-4a07-9026-aa78461f6cea-401491.jpg?v=1671685463",
            "/images/products/Jayley2_597e4d83-3eda-4b11-ae64-cdecae865bf3-393423.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Eustoma",
                "3. Zachowany kwiat ryżu",
                "4. Mattiola",
                "5. Zachowana hortensja",
                "6. Astrantia",
                "7. Mini nasiona Pittosporum",
                "8. Liście Pittosporum",
                "9. Spersonalizowany tekst na balonie z 3 mini balonami",
                "10. Drewniana skrzynia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 748,
        "name": "Fortuna (V)",
        "handle": "fortune-v",
        "description": "Oświetl wszystkie wielkie otwarcia za pomocą statywu Golden Radiance, olśniewającego połączenia szampańskich róż, żywych słoneczników i egzotycznych kwiatów rajskich ptaków. Ta majestatyczna kompozycja kwiatowa jest wysoka i symbolizuje dobrobyt, radość i początek promiennej podróży. Ucieleśnienie sukcesu i świętowania.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Fortune-_V_-1b.jpg?v=1720101382",
            "/images/products/Grand-Opening-Card-2024_554b1409-9d1d-4a0d-b479-e970fa9bb716.jpg?v=1720101387",
            "/images/products/Fortune-_V_-2b.jpg?v=1720101382"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Eustoma. Hypericum. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 749,
        "name": "Fortuna (VI)",
        "handle": "fortune-vi",
        "description": "Odkryj wyrafinowanie dzięki naszemu statywowi Sunlit Elegance. Ozdobiona żółto-białymi różami, akcentowanymi przez bogate złote anturium, a wszystko otoczone elegancką, nudną listwą przypodłogową. Taki układ oznacza sukces i wyrafinowany początek wszystkich wielkich otwarć firm.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "844.11",
        "inclTax": "844.11",
        "img": [
            "/images/products/Fortune-_VI_-1b.jpg?v=1720102411",
            "/images/products/Grand-Opening-Card-2024_242929ad-fc76-4e96-a086-70f8c5ca4340.jpg?v=1720102418",
            "/images/products/Fortune-_VI_-2b.jpg?v=1720102412"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Anturium . Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 750,
        "name": "Fortuna (VII)",
        "handle": "fortune-vii",
        "description": "Promieniuj sukcesem podczas wszystkich wielkich otwarcia biznesów dzięki naszemu statywowi Sun-Kissed Success. Słoneczniki i pomarańczowe róże otoczone żywymi pomarańczowymi listwami tworzą urzekający obraz symbolizujący dobrobyt i ciepło nowych początków. Rozświetl wszystkie przedsięwzięcia tym żywym i eleganckim elementem centralnym.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Fortune-_VII_-1b.jpg?v=1720101570",
            "/images/products/Grand-Opening-Card-2024_6e14825f-cb39-4064-8c04-67ea15e3347c.jpg?v=1720101575",
            "/images/products/Fortune-_VII_-2b.jpg?v=1720101570"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Eustoma. Hypericum. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 751,
        "name": "Uroczystość Cerulean FNB",
        "handle": "paulina-fnb",
        "description": "Cerulean CelebrationWypłyń na niekończące się lato na Morzu Śródziemnym dzięki pudełku z kwiatami i balonem Cerulean Celebration. Subtelne jasne błękity i fiolety goździków, sprayu różanego i mattioli nadają tej kompozycji kwiatów i balonów delikatny, czarujący i stylowy nastrój. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Paulina1-601690.jpg?v=1671684909",
            "/images/products/Paulina2-324559.jpg?v=1671684909"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Goździk",
                "2. Spray różany",
                "3. Mattiola",
                "4. Zachowana hortensja",
                "5. Zachowany kwiat ryżu",
                "6. Pittosporum Mini",
                "7. Eustoma",
                "8. Liście Pittosporum",
                "9. Spersonalizowany tekst na balonie z 3 mini balonami",
                "10. Drewniana skrzynia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 752,
        "name": "Jesienna przyprawa FNB",
        "handle": "cleo-fnb",
        "description": "Jesienna przyprawaCukier, przyprawy i wszystko, co miłe! Bukiet kwiatów i balonów Autumn Spice oddaje przytulne piękno jesieni i gwarantuje, że napełni Twoją obdarowaną osobę radością. Bogate czerwienie róż, eustom, sprayów z goździków i zakonserwowanych kwiatów ryżu dodają głębi i ciepła tej odważnej aranżacji z kwiatów i balonów, która obiecuje przyciągnąć uwagę swoim pięknem! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty na rocznicę"
        ],
        "price": "399.12",
        "inclTax": "399.12",
        "img": [
            "/images/products/Cleo1-676369.jpg?v=1671684751",
            "/images/products/Cleo2-763804.jpg?v=1671684751"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Różowe cappuccino",
                "2. Eustoma",
                "3. Spray goździkowy",
                "4. Spray różany",
                "5. Zachowany kwiat ryżu",
                "6. Kwiat ping-ponga",
                "7. Mattiola",
                "8. Mini nasiona Pittosporum",
                "9. Liście Pittosporum",
                "10. Spersonalizowany tekst na balonie z 3 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 753,
        "name": "Twinkle Twinkle Mały chłopiec BHP",
        "handle": "liam-bhp",
        "description": "Twinkle Twinkle Little Boy Pogratuluj najsłodszego koszyka dla noworodka! Koszyczek dla chłopca Twinkle Twinkle Little Boy zawiera ekscytującą serię przyborów i zabawek, które zapewnią dziecku ekscytację i rozrywkę! Dodaliśmy także Księgę kamieni milowych dziecka, aby rodzice mogli śledzić swoją nową, ekscytującą podróż!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "287.30",
        "inclTax": "287.30",
        "img": [
            "/images/products/Liam1-941559.jpg?v=1671775918",
            "/images/products/Twinkle-Twinkle-Little-Boy-BHP2-405974.jpg?v=1708971358"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Esencja z jagód Fomec 41ml x 2",
                "2. Ptasie Gniazdo z Żeń-szeniem",
                "3. Zabawny zestaw zabawek Orbit Turn Happy Ball",
                "4. Gryzak Baby K Żyrafa",
                "5. Kubek niekapek Baby K",
                "6. Silikonowa łyżka do samodzielnego karmienia Baby K x 2",
                "7. Wobbler Baby K Penguin",
                "8. Opakowania na różne kwiaty",
                "9. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 754,
        "name": "Lacey",
        "handle": "lacey",
        "description": "„Wiesz, że to miłość, kiedy wszystko, czego pragniesz, to to, aby ta osoba była szczęśliwa, nawet jeśli nie jesteś częścią jej szczęścia.” – Julia Roberts",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Lacey1A-873093.jpg?v=1671685468",
            "/images/products/Lacey2A-249574.jpg?v=1671685468"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 40cm*50cm"
    },
    {
        "id": 755,
        "name": "FNB Somebunny's Teaparty",
        "handle": "rosalee-fnb",
        "description": "Somebunny's Teaparty Somebunny's Teaparty to idealny kosz na przekąski, który ma celebrować powszechną miłość do przekąsek! Znajdź wystarczającą ilość przekąsek, które zapewnią Ci energię na cały dzień, w tym lemon curd, nugat żurawinowy, sok i nie tylko, a wszystko to w wykwintnej drewnianej skrzyni. Zaprosiliśmy nawet specjalnego gościa na Twoje wyjątkowe przyjęcie herbaciane! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na powitanie noworodka",
            "Bukiet balonów urodzinowych"
        ],
        "price": "453.89",
        "inclTax": "453.89",
        "img": [
            "/images/products/Somebunny_s-Teaparty-FNB1.jpg?v=1709015392",
            "/images/products/Rosalee2.jpg?v=1709015392"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pluszowy króliczek",
                "2. Twaróg cytrynowy Tiptree 340g",
                "3. Róża świecowa Botanica",
                "4. Georgias Natural 100% Naturalny Sok - Gruszka 300ml",
                "5. Nibbles Cranberry ręcznie robione miękkie nugaty miodowe 126g",
                "6. Naturalny Sok Świetlika - Cytryna, Limonka 330ml"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 756,
        "name": "Smakosz Goodie BHP",
        "handle": "zora-bhp",
        "description": "Foodie GoodieTen starannie zebrany kosz jest starannie wykonany z codziennych potrzeb, ubrań i zabawek. Z pewnością sprawi, że każde małe dziecko będzie szczęśliwe i wygodne przez cały dzień!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "189.18",
        "inclTax": "189.18",
        "img": [
            "/images/products/Zora1-324597.jpg?v=1671685207",
            "/images/products/Zora2-371575.jpg?v=1671685207"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy dla niemowląt K",
                "2. Zabawny śliniaczek Lucky Baby Calico",
                "3. Pierwszy nóż do cięcia żywności Lucky Baby Safety",
                "4. Suszarki Wee Wee Dry Pieluchy rozmiar M (4szt)",
                "5. Kubek na szczęście dla małego dziecka",
                "6. Organiczne chusteczki dla niemowląt KinderNurture 20-te",
                "7. Pluszowy króliczek",
                "8. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 757,
        "name": "Clean 'n' Pure BHP",
        "handle": "vanna-bhp",
        "description": "Clean 'n' Pure „Koszyk dla niemowląt Clean 'n' Pure sprawi, że kąpiel będzie zawsze przyjemnością! W naszej ofercie znalazły się niezbędniki do kąpieli, takie jak myjki i ręcznik z kapturem marki BabyK, a także niebielone chusteczki dla niemowląt, dzięki czemu wrażliwa skóra dziecka będzie odpowiednio chroniona. Odkryj z nami najlepsze Kosze dla niemowląt w Polsce i ciesz się bezpłatną dostawą koszyków dla noworodków.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Vanna1-943584.jpg?v=1671685024",
            "/images/products/Vanna2-661767.jpg?v=1671685024"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Baby Carrie Organic Delikatne mydło od stóp do głów 500ml",
                "2. Kocyk Baby K- Organiczny bambusowy rożek",
                "3. Myjka Baby K- Organic Bamboo 6 szt",
                "4. Ręcznik z kapturem Baby K- Organic Bamboo Nowość",
                "5. Natura Cloversoft",
                "6. Wykwintny kosz upominkowy (S)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 758,
        "name": "Owocowa herbatka FNB",
        "handle": "kairi-fnb",
        "description": "Fruity Teaparty Zaskocz kogoś koszem na przekąski Fruity Teaparty! Ten kosz na przekąski, wypełniony smacznymi przekąskami, w tym ciasteczkami, chai i tradycyjnym napojem gazowanym Bickfords, składa hołd lokalnym przysmakom, takim jak ciasteczka Nonya Delight Kueh Kosui z Old Seng Choong. Jako dodatkowy akcent luksusu, nasz okrągły żel Light Botanica zagwarantuje, że Twoje przyjęcie herbaciane będzie sukcesem! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "460.74",
        "inclTax": "460.74",
        "img": [
            "/images/products/Kairi1-102540.jpg?v=1671685312",
            "/images/products/Kairi2.jpg?v=1667874903"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ciasteczka Old Seng Choong – Nonya Delight Kueh Kosui 180g",
                "2. Ciasteczka z podwójnymi kawałkami czekolady Beryl’s 100g",
                "3. Botanica Okrągły Żel Lekki 90g",
                "4. Taylors of Harrogate - Classic Chai 50g x 20 saszetek",
                "5. Marmolada Mrs. Bridges z pomarańczy i mandarynek",
                "6. Tradycyjny napój gazowany Bickfords – cytryna i limonka",
                "7. Tiptree – Pomarańczowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 759,
        "name": "Komfort (VIII)",
        "handle": "comfort-viii",
        "description": "Przekaż najszczersze kondolencje za pomocą naszych kwietników z żywymi pomarańczowymi orchideami, czystą białą kapustą, delikatnymi chryzantemami, pachnącymi matiolą i delikatnymi gerberami. Ten harmonijny układ zapewnia pocieszenie, symbolizując współczucie i pamięć w chwilach smutku, zapewniając pocieszenie i wsparcie w chwilach smutku.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Comfort-_VIII_-1d.jpg?v=1720156910",
            "/images/products/Condolence-Card-2024_1_30873d2a-c0bc-46ef-96c0-81218e3b71ed.jpg?v=1720156919",
            "/images/products/Comfort-_VIII_-2d.jpg?v=1720156913"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Gerbera . Mamo. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 760,
        "name": "Empatia (VIII)",
        "handle": "empathy-viii",
        "description": "W chwilach smutku nasze Stojaki z kwiatami kondolencyjnymi, zawierające nieskazitelnie białe chryzantemy, delikatne róże szampańskie i delikatne gerbery, oferują pocieszenie i pełen wdzięku hołd. Ta elegancka aranżacja symbolizuje współczucie i pamięć, zapewniając komfort i wsparcie w chwilach żałoby.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Empathy-_VIII_-1e.jpg?v=1720155537",
            "/images/products/Condolence-Card-2024_1_0927badd-4ee4-4cd2-bad5-9691b4ca79db.jpg?v=1720155545",
            "/images/products/Empathy-_VIII_-2e.jpg?v=1720155537"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Gerbera . Róże. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 761,
        "name": "Słodki Lavish FNB",
        "handle": "vada-fnb",
        "description": "Sweet Lavish Idealny kosz na jedzenie, w którym możesz obdarować kogoś swoimi szczerymi uczuciami, balony Sweet Lavish zawierają ulubione przekąski, które zachwycą każde podniebienie! Nazywamy to uniwersalnym koszem piknikowym, który z pewnością zrobi wrażenie! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "460.74",
        "inclTax": "460.74",
        "img": [
            "/images/products/Sweet-Lavish-FNB1-918031.jpg?v=1709057857",
            "/images/products/Vada2-514976.jpg?v=1709015072"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Excelcium - Praliny BonBons De Chocolat 180g",
                "2. Czekolada Asortyment Hamlet 125g",
                "3. Krakersy The Fine Cheese Co Rose Mary i Extra Virgin Oil 125g",
                "4. Pasta owocowa St Dalfour 3 x 28 g",
                "5.JP. Edycja lodowa Cheneta",
                "6. Tiptree - Pomarańczowe",
                "7. Pokusa - Domowe Ciasteczka Czekoladowe 250g",
                "8. Kuszenie - Ciasteczka Domowe Podwójna Czekolada 250g"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 762,
        "name": "Angielska herbatka FNB",
        "handle": "elia-fnb",
        "description": "English Teaparty English Teaparty to idealny kosz na jedzenie, który ma celebrować powszechną miłość do przekąsek! Znajdź wystarczającą ilość przekąsek, które zapewnią Ci energię na cały dzień, w tym konfiturę morelową, świeże owoce, organiczną angielską herbatę, a wszystko to zapakowane w wykwintną drewnianą skrzynkę. Zaprosiliśmy nawet specjalnego gościa na Twoje wyjątkowe przyjęcie herbaciane! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Elia1-897397.jpg?v=1671685200",
            "/images/products/Elia2.jpg?v=1667953062"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pluszowy króliczek",
                "2. Tiptree - Konfitura Morelowa 340g",
                "3. Tiptree - Pomarańczowe",
                "4. Georgias Natural 100% Naturalny Sok - Gruszka 300ml",
                "5. Organiczny rumianek z angielskiej herbaty",
                "6. Róża świecowa Botanica",
                "7. Świeże winogrona"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 763,
        "name": "Pełen wdzięku blask FNB",
        "handle": "raina-fnb",
        "description": "Graceful RadianceGraceful Radiance – tak wyobrażał sobie Twój odbiorca, gdy otrzyma ten wspaniały balonowy kosz na żywność! Idealny dla każdego, kto ma ochotę na słodycze, nasz asortyment pysznych przekąsek naprawdę zachwyci ich wybredne podniebienie! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Graceful-Radiance-FNB1-426581.jpg?v=1709143470",
            "/images/products/Raina2-398433.jpg?v=1709085242"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ciasteczka Waflowe Beryl’s z Mleczną Czekoladą Gianduja 80g",
                "2. Czekolada Asortyment Hamlet 125g",
                "3. Tiptree – Pomarańczowe",
                "4. Pasta owocowa St Dalfour 3 x 28 g",
                "5. Ciasteczka maślane jagodowe K.Harrodson 70g",
                "6. Cavendisha",
                "7.JP. Chenet Ice Edition 200ml",
                "8. K. Rogers Ciasteczka zbożowe Crunch z płatkami kukurydzianymi 150g",
                "9. Spersonalizowany tekst na balonie z 6 mini balonami",
                "10. Wykwintny kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 764,
        "name": "Lexy",
        "handle": "lexi",
        "description": "Zanurz się w niezmiennym uroku naszych wiecznie zakonserwowanych róż w kolorze tiffany blue i delikatnego gossypium misternie ułożonych w bukiet w kształcie serca. To ponadczasowe dzieło symbolizuje wieczną miłość i wyrafinowanie, oddając esencję trwałego piękna. Podnieś jakość każdej przestrzeni dzięki tej wyjątkowej aranżacji, będącej wieczną deklaracją miłości i łaski.",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lexi-1aa-471334.jpg?v=1704463726",
            "/images/products/Lexi-2aa.jpg?v=1704306838"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Zachowana hortensja. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 765,
        "name": "Wakacyjny smakosz (NA)",
        "handle": "holiday-gourmet-na",
        "description": "Holiday Gourmet (NA) Jasna i wesoła aranżacja bożonarodzeniowa, wyjątkowy kosz na prezenty świąteczne Holiday Gourmet został zaprojektowany tak, aby można go było wystawić jako element centralny, ozdobić dom lub po prostu dodać odrobinę świątecznej radości do niemal każdego miejsca w całym domu. sezon wakacyjny. Wesołe smakołyki wypełnią ten zachwycający prezent po brzegi, a do bezalkoholowej zabawy dołączyliśmy nalewkę z czarnej porzeczki! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "278.18",
        "inclTax": "278.18",
        "img": [
            "/images/products/Holiday-Gourmet-_NA_1-607710.jpg?v=1671685463",
            "/images/products/Holiday-Gourmet-_NA_2-634244.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Bickfords Cordial Australia Kordiał z czarnej porzeczki 750ml",
                "2. Loison Amarena Panettoncino z Rodzynkami i Kandyzowanymi Wiśniami 100g",
                "3. Tradycyjny Biszkopt St Michel 150g",
                "4. Huntly",
                "5. Tradycyjne Kruche Paluszki Duncan’s of Deeside 150g",
                "6. Forum Hamleta Oubliesa 125g",
                "7. Godivine Matcha Biscotti 70g",
                "8. Excelcium – Praliny BonBons De Chocolat 200g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 766,
        "name": "Uroczysta uczta (NA)",
        "handle": "festive-feast-na",
        "description": "Świąteczna uczta (NA) Świąteczny prezent świąteczny w postaci jedzenia to wspaniały sposób na przesłanie życzeń świątecznych w grudniu! Świąteczne smakołyki, które cieszą tłumy, są zapakowane wraz z nalewką z czarnej porzeczki, co stanowi wspaniałe przeżycie jako prezent, który z pewnością zachwyci zmysły!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "312.41",
        "inclTax": "312.41",
        "img": [
            "/images/products/Festive-Feast-_NA_1-731393.jpg?v=1671685310",
            "/images/products/Festive-Feast-_NA_2_945f273b-151c-4817-b44a-4b5c1a241243-874963.jpg?v=1671685310"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Bickfords Cordial Australia Kordiał z czarnej porzeczki 750ml",
                "2. Muszle Natrajacali Belgid’Or (Mleczne) 250g",
                "3. Gavottes Naleśniki Mleczne Dentelle 90g",
                "4. Duncan’s of Deeside Solony Karmel Maślane Kruche Ciasto Ręcznie Pieczone 150g",
                "5. Golden Bon Bon Miękki Nugat Migdałowy – Klon 70g",
                "6. Ciasteczka Winter Woodlands 150g",
                "7. Nibbles Pieczone Orzechy Nerkowca 35g",
                "8. Hunter Hand Cook Pesto z chipsów ziemniaczanych i parmezanem 150g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 767,
        "name": "Wakacyjny smakosz (RW)",
        "handle": "holiday-gourmet-rw",
        "description": "Holiday Gourmet (RW) Graceful Radiance – tak wyobrażał sobie Twój odbiorca, gdy otrzyma ten wspaniały balonowy kosz na jedzenie! Idealny dla każdego, kto ma ochotę na słodycze, nasz asortyment pysznych przekąsek naprawdę zachwyci ich wybredne podniebienie!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "332.94",
        "inclTax": "332.94",
        "img": [
            "/images/products/Holiday-Gourmet-_RW_1-151868.jpg?v=1671685349",
            "/images/products/Holiday-Gourmet-_RW_2-500941.jpg?v=1671685349"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. De Valier Rosso 75cl",
                "2. Loison Amarena Panettoncino z Rodzynkami i Kandyzowanymi Wiśniami 100g",
                "3. Tradycyjny Biszkopt St Michel 150g",
                "4. Huntly",
                "5. Tradycyjne Kruche Paluszki Duncan’s of Deeside 150g",
                "6. Forum Hamleta Oubliesa 125g",
                "7. Godivine Matcha Biscotti 70g",
                "8. Excelcium – Praliny BonBons De Chocolat 200g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 768,
        "name": "Wakacyjny smakosz (WW)",
        "handle": "holiday-gourmet-ww",
        "description": "Holiday Gourmet (WW) Jasna i wesoła aranżacja świąteczna, wyjątkowy kosz na świąteczne jedzenie Holiday Gourmet został zaprojektowany tak, aby można go było wystawić jako element centralny, ozdobić dom lub po prostu dodać odrobinę świątecznej radości do niemal każdego miejsca w całym domu. sezon wakacyjny. Wesołe smakołyki wypełnią ten wspaniały prezent po brzegi, a dodaliśmy świąteczne białe wino, które zapewni dodatkową zimową zabawę! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "332.94",
        "inclTax": "332.94",
        "img": [
            "/images/products/Holiday-Gourmet-_WW_1-915722.jpg?v=1671685325",
            "/images/products/Holiday-Gourmet-_WW_2-254466.jpg?v=1671685325"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. De Valier Bianco 75cl",
                "2. Loison Amarena Panettoncino z Rodzynkami i Kandyzowanymi Wiśniami 100g",
                "3. Tradycyjny Biszkopt St Michel 150g",
                "4. Huntly",
                "5. Tradycyjne Kruche Paluszki Duncan’s of Deeside 150g",
                "6. Forum Hamleta Oubliesa 125g",
                "7. Godivine Matcha Biscotti 70g",
                "8. Excelcium – Praliny BonBons De Chocolat 200g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 769,
        "name": "Zorina",
        "handle": "zorine",
        "description": "Ten żywy bukiet przedstawia oszałamiający słonecznik jako centralny element, otoczony różnorodnymi kolorowymi kwiatami, w tym różowymi goździkami, lawendowymi różami i białymi kwiatami pomponów. Aranżacja zaakcentowana delikatną zielenią i owinięta wesołym, pomarańczowym papierem w kratkę sprawia, że ​​będzie żywym i radosnym prezentem na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Zorine-2a.jpg?v=1721173643",
            "/images/products/Zorine-1a.jpg?v=1721173643"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 770,
        "name": "Wakacyjny smakosz (S)",
        "handle": "holiday-gourmet-s",
        "description": "Holiday Gourmet (S) Jasna i wesoła aranżacja bożonarodzeniowa, wyjątkowy kosz na prezenty świąteczne Holiday Gourmet został zaprojektowany tak, aby można go było wystawić jako element centralny, ozdobić dom lub po prostu dodać odrobinę świątecznej radości do niemal każdego miejsca w całym domu. sezon wakacyjny. Wesołe gadżety wypełnią ten wspaniały prezent po brzegi, a my dołączyliśmy świąteczną musującą szampankę, która zapewni dodatkową zimową zabawę!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "353.48",
        "inclTax": "353.48",
        "img": [
            "/images/products/Holiday-Gourmet-_S_1_ddc11d5d-04d4-4e54-bfd0-e1c43457d67f-408895.jpg?v=1671685463",
            "/images/products/Holiday-Gourmet-_S_2_16fd1870-a26b-4486-9757-d0be1c6933c7-630941.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Arione D.O.C Piemonte Brachetto Musujące 75cl",
                "2. Loison Amarena Panettoncino z Rodzynkami i Kandyzowanymi Wiśniami 100g",
                "3. Tradycyjny Biszkopt St Michel 150g",
                "4. Huntly",
                "5. Tradycyjne Kruche Paluszki Duncan’s of Deeside 150g",
                "6. Forum Hamleta Oubliesa 125g",
                "7. Godivine Matcha Biscotti 70g",
                "8. Excelcium – Praliny BonBons De Chocolat 200g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 771,
        "name": "Uroczysta Uczta (RW)",
        "handle": "festive-feast-rw",
        "description": "Świąteczna uczta (RW) Świąteczny prezent świąteczny w postaci jedzenia to wspaniały sposób na przesłanie życzeń świątecznych w grudniu! Świąteczne smakołyki, które cieszą tłumy, są zapakowane wraz z nalewką z czarnej porzeczki, co stanowi wspaniałe przeżycie jako prezent, który z pewnością zachwyci zmysły!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "312.41",
        "inclTax": "312.41",
        "img": [
            "/images/products/Festive-Feast-_RW_1-793589.jpg?v=1671685203",
            "/images/products/Festive-Feast-_RW_2-634047.jpg?v=1671685203"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Wino Fernandez Merlot 75cl",
                "2. Muszle Natrajacali Belgid’Or (Mleczne) 250g",
                "3. Gavottes Naleśniki Mleczne Dentelle 90g",
                "4. Duncan’s of Deeside Solony Karmel Maślane Kruche Ciasto Ręcznie Pieczone 150g",
                "5. Golden Bon Bon Miękki Nugat Migdałowy – Klon 70g",
                "6. Ciasteczka Winter Woodlands 150g",
                "7. Nibbles Pieczone Orzechy Nerkowca 35g",
                "8. Hunter Hand Cook Pesto z chipsów ziemniaczanych i parmezanem 150g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 772,
        "name": "Uroczysta Uczta (WW)",
        "handle": "festive-feast-ww",
        "description": "Świąteczna uczta (WW) Świąteczny prezent świąteczny w postaci jedzenia to wspaniały sposób na przesłanie życzeń świątecznych w grudniu! Przyjemne dla tłumów gadżety są pakowane wraz ze świątecznym białym winem, co stanowi wspaniałe przeżycie związane z prezentem, które z pewnością zachwyci zmysły!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "362.61",
        "inclTax": "362.61",
        "img": [
            "/images/products/Festive-Feast-_WW_1-956896.jpg?v=1671685204",
            "/images/products/Festive-Feast-_WW_2-334560.jpg?v=1671685204"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Wino Fernandez Sauvignon Blanc 75cl",
                "2. Muszle Natrajacali Belgid’Or (Mleczne) 250g",
                "3. Gavottes Naleśniki Mleczne Dentelle 90g",
                "4. Duncan’s of Deeside Solony Karmel Maślane Kruche Ciasto Ręcznie Pieczone 150g",
                "5. Golden Bon Bon Miękki Nugat Migdałowy – Klon 70g",
                "6. Ciasteczka Winter Woodlands 150g",
                "7. Nibbles Pieczone Orzechy Nerkowca 35g",
                "8. Hunter Hand Cook Pesto z chipsów ziemniaczanych i parmezanem 150g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 773,
        "name": "Świąteczna Uczta (S)",
        "handle": "festive-feast-s",
        "description": "Świąteczna uczta (S) Świąteczny prezent świąteczny w postaci jedzenia to wspaniały sposób na przesłanie życzeń świątecznych w grudniu! Przyjemne dla tłumów gadżety są pakowane wraz ze świątecznym musującym nalewem, co zapewnia wspaniałe przeżycie w postaci prezentów, które z pewnością zachwyci zmysły! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "383.15",
        "inclTax": "383.15",
        "img": [
            "/images/products/Festive-Feast-_S_1-331442.jpg?v=1671685310",
            "/images/products/Festive-Feast-_S_2-640100.jpg?v=1671685310"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Anon Musująca Cuvee 75cl",
                "2. Muszle Natrajacali Belgid’Or (Mleczne) 250g",
                "3. Gavottes Naleśniki Mleczne Dentelle 90g",
                "4. Duncan’s of Deeside Solony Karmel Maślane Kruche Ciasto Ręcznie Pieczone 150g",
                "5. Golden Bon Bon Miękki Nugat Migdałowy – Klon 70g",
                "6. Ciasteczka Winter Woodlands 150g",
                "7. Nibbles Pieczone Orzechy Nerkowca 35g",
                "8. Hunter Hand Cook Pesto z chipsów ziemniaczanych i parmezanem 150g",
                "9. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 774,
        "name": "Przysmaki z Krainy Czarów (NA)",
        "handle": "wonderland-treats-na",
        "description": "Wonderland Treats (NA) Nie ma to jak sezon świąteczny, dlatego zaprojektowaliśmy świąteczny prezent kulinarny Wonderland Treats, aby rozjaśnić każdą przestrzeń! Uzupełnij swoje dekoracje świąteczne tym elementem centralnym, który zrobi wrażenie i zachwyci! Wypełnione po brzegi luksusowymi przekąskami i rozkoszującym się syropem z czarnej porzeczki, będzie to przeżycie dla kubków smakowych i zmysłów! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "405.97",
        "inclTax": "405.97",
        "img": [
            "/images/products/Wonderland-Treats-_NA_2-293086.jpg?v=1671775979",
            "/images/products/Wonderland-Treats-_NA_3-919265.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Bickfords Cordial Australia Kordiał z czarnej porzeczki 750ml",
                "2. Loison Amarena Panettoncino z Rodzynkami i Kandyzowanymi Wiśniami 100g",
                "3. Wedgewood Ręcznie robiony nugat miodowy Assortowana ciemna czekolada miętowa 120g",
                "4. Huntleya",
                "5. Ciasteczka Farmhouse Luksusowe Kruszonka Jabłkowa 150g",
                "6. Les Cookies de La Mere Poulard Wszystkie czekoladowe ciasteczka francuskie 200g",
                "7. Excelcium – Praliny BonBons De Chocolat 200g",
                "8. Godivine Nutty Mix Orzechów 80g",
                "9. LIR Trufle Solone Karmelowe 145g",
                "10. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 775,
        "name": "Przysmaki z Krainy Czarów (RWW)",
        "handle": "wonderland-treats-rww",
        "description": "Wonderland Treats (RWW) Nie ma to jak okres świąteczny, a my zaprojektowaliśmy świąteczny prezent kulinarny Wonderland Treats, aby rozjaśnić każdą przestrzeń! Uzupełnij swoje dekoracje świąteczne tym elementem centralnym, który zrobi wrażenie i zachwyci! Wypełnione po brzegi luksusowymi przekąskami i rozkoszującym się syropem z czarnej porzeczki, będzie to przeżycie dla kubków smakowych i zmysłów! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "567.99",
        "inclTax": "567.99",
        "img": [
            "/images/products/Wonderland-Treats-_RWW_1_66e28d42-03e5-4f45-b6a7-757c5d190c26-390780.jpg?v=1671775979",
            "/images/products/Wonderland-Treats-_RWW_2_71d82c79-a3d2-42b7-aeb9-fb85e3bea215-926853.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Noblesse Merlot 75cl",
                "2. Noblesse Sauvignon Blanc 75cl",
                "3. Loison Amarena Panettoncino z Rodzynkami i Kandyzowanymi Wiśniami 100g",
                "4. Wedgewood Ręcznie robiony nugat miodowy Mieszany ciemna czekolada miętowa 120g",
                "5. Huntleya",
                "6. Ciasteczka Farmhouse Luksusowe Kruszonka Jabłkowa 150g",
                "7. Les Cookies de La Mere Poulard Wszystkie czekoladowe ciasteczka francuskie 200g",
                "8. Excelcium – Praliny BonBons De Chocolat 200g",
                "9. Godivine Nutty Mix Orzechów 80g",
                "10. LIR Trufle Solone Karmelowe 145g",
                "11. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 776,
        "name": "Przysmaki z Krainy Czarów (S)",
        "handle": "wonderland-treats-s",
        "description": "Wonderland Treats (S) Nie ma to jak sezon świąteczny, dlatego zaprojektowaliśmy świąteczny prezent kulinarny Wonderland Treats, aby rozjaśnić każdą przestrzeń! Uzupełnij swoje dekoracje świąteczne tym elementem centralnym, który zrobi wrażenie i zachwyci! Wypełnione po brzegi luksusowymi przekąskami i musującym prosecco, będzie to przeżycie dla kubków smakowych i zmysłów!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "567.99",
        "inclTax": "567.99",
        "img": [
            "/images/products/Wonderland-Treats-_S_1-827063.jpg?v=1671775979",
            "/images/products/Wonderland-Treats-_S_2-952245.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Santa Margherita Prosecco, Włochy 75cl",
                "2. Loison Amarena Panettoncino z Rodzynkami i Kandyzowanymi Wiśniami 100g",
                "3. Wedgewood Ręcznie robiony nugat miodowy Assortowana ciemna czekolada miętowa 120g",
                "4. Huntleya",
                "5. Ciasteczka Farmhouse Luksusowe Kruszonka Jabłkowa 150g",
                "6. Les Cookies de La Mere Poulard Wszystkie czekoladowe ciasteczka francuskie 200g",
                "7. Excelcium – Praliny BonBons De Chocolat 200g",
                "8. Godivine Nutty Mix Orzechów 80g",
                "9. LIR Trufle Solone Karmelowe 145g",
                "10. Wykwintny kosz piknikowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 777,
        "name": "Współczucie (VIII)",
        "handle": "sympathy-viii",
        "description": "W wielkim geście współczucia nasze duże Stojaki z kwiatami kondolencyjnymi przedstawiają bujną białą kapustę, czysto białe chryzantemy i pełne wdzięku gerbery, podkreślone szampańską eustomą. Ta luksusowa aranżacja stanowi godny hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "613.86",
        "inclTax": "613.86",
        "img": [
            "/images/products/Sympathy-_VIII_-2c.jpg?v=1727189857",
            "/images/products/Condolence-Card-2024_1_ac356481-95d5-4a3f-8e5b-85510962f315.jpg?v=1720190727",
            "/images/products/Sympathy-_VIII_-1c.jpg?v=1727189857"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Gerbery . Eustoma. Mattiola. Chryzantema. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 778,
        "name": "Urok jemioły",
        "handle": "mistletoe-charm",
        "description": "Zawieszka z jemiołąGotowy na stylowy prezent i sprawi, że Święta Bożego Narodzenia 2022 będą wyglądać idealnie? W te święta podaruj świąteczny kosz prezentowy z jemiołą Charm, aby dodać wyjątkowego blasku tej porze roku! Świąteczny poczęstunek i gra planszowa dla dzieci sprawiają, że w tej aranżacji każdy znajdzie coś dla siebie!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "209.72",
        "inclTax": "209.72",
        "img": [
            "/images/products/Mistletoe-Charm1-719957.jpg?v=1671685491",
            "/images/products/Mistletoe-Charm2-515465.jpg?v=1671685492"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gra planszowa Szarady dla dzieci",
                "2. Werther’s Original Popcorn Classic 140g",
                "3. Godivine Ciasteczka Migdałowe Żurawinowe 70g",
                "4. Napój kakaowy Van Houten 100g",
                "5. Gra karciana",
                "6. Bickfords Premium Soft Drink Australia – Sarsaparilla 275ml",
                "7. Wafelek czekoladowy Loacker Quadratini 125g",
                "8. Excelcium – Praliny BonBons De Chocolat 200g",
                "9. Wykwintny kosz upominkowy",
                "10. Wykwintny kosz piknikowy (XL)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 779,
        "name": "Pozdrowienia sezonu (BB)",
        "handle": "seasons-cheers-bb",
        "description": "Season's Cheers (BB)Season's Cheers to kosz na prezenty świąteczne, który przywołuje radosne i cenne chwile, jakie niesie ze sobą ta wyjątkowa pora roku! W tej świątecznej aranżacji znajdują się ekscytujące przekąski i piwo, a dla bardziej żądnych przygód dodaliśmy także grę w picie alkoholu w Drunken Tower! W tym sezonie przygotuj się na zabawę i mnóstwo chichotu! Ciesz się bezproblemowym dawaniem prezentów dzięki naszym dedykowanym usługom! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "269.05",
        "inclTax": "269.05",
        "img": [
            "/images/products/Season_sCheers1-479151.jpg?v=1702988969",
            "/images/products/Season_sCheers_BB_2-817162.jpg?v=1702988969"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gra w pijacką wieżę",
                "2. Piwo Heineken 330ml",
                "3. Piwo Hoegarden 330ml",
                "4. Piwo Kronenbourg 1664 330ml",
                "5. Piwo Corona 355ml",
                "6. Nibbles Premium Natural Mieszane Orzechy 45g",
                "7. Dojrzały Cheddar Tyrrells",
                "8. Chipsy Farmland Thin Curv 160g",
                "9. Chipsy ziemniaczane z czarną truflą Hunter’s Gourmet 40g",
                "10. Pomsticki Lorenza 70g",
                "11. Wykwintny kosz (L)",
                "12. Wykwintny kosz piknikowy (XL)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 780,
        "name": "Duch Świąt (BB)",
        "handle": "christmas-spirit-bb",
        "description": "Świąteczny Duch (BB) Przesyłanie świątecznej radości nigdy nie smakowało tak dobrze! W naszym koszyku na prezenty świąteczne Christmas Spirit znajdują się wspaniałe smakołyki, świąteczne piwa i ciesząca się dużym zainteresowaniem gra w alkoholizm Roulette 16 Glass Lucky Shot, która zapewni radosne, zabawne i ekscytujące wakacje!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "278.18",
        "inclTax": "278.18",
        "img": [
            "/images/products/Christmas-Spirit-_BB_1-644369.jpg?v=1671685195",
            "/images/products/Christmas-Spirit-_BB_2-269833.jpg?v=1671685195"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ruletka 16 Szklanych Szczęśliwych Strzałów Gra Alkoholowa",
                "2. Piwo Heineken 330ml",
                "3. Piwo Hoegarden 330ml",
                "4. Piwo Kronenbourg 1664 330ml",
                "5. Piwo Corona 355ml",
                "6. Nibbles Premium Natural Mieszane Orzechy 45g",
                "7. Dojrzały Cheddar Tyrrells",
                "8. Chipsy Farmland Thin Curv 160g",
                "9. Chipsy ziemniaczane z czarną truflą Hunter’s Gourmet 40g",
                "10. Pomsticki Lorenza 70g",
                "11. Wykwintny kosz (L)",
                "12. Wykwintny kosz piknikowy (XL)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 781,
        "name": "Pozdrowienia sezonu (SJ)",
        "handle": "seasons-cheers-sj",
        "description": "Season's Cheers (SJ)Season's Cheers to kosz na prezenty świąteczne, który przywołuje radosne i cenne chwile, które niesie ze sobą ta wyjątkowa pora roku! Ekscytujące przekąski i soju są zapakowane w tę świąteczną aranżację, a dla bardziej odważnych dorzuciliśmy także grę w picie alkoholu w Drunken Tower! W tym sezonie przygotuj się na zabawę i mnóstwo chichotu! Ciesz się bezproblemowym dawaniem prezentów dzięki naszym dedykowanym usługom!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "289.59",
        "inclTax": "289.59",
        "img": [
            "/images/products/Season_s-Cheers-_SJ_1-625332.jpg?v=1702988970",
            "/images/products/Season_s-Cheers-_SJ_2_1ca6f367-6cd0-4aaf-862b-404114f04bd3-150506.jpg?v=1702988970"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gra w pijacką wieżę",
                "2. Jinro Soju 360ml x 2",
                "3. Nibbles Premium Natural Mieszane Orzechy 45g",
                "4. Dojrzały Cheddar Tyrrells",
                "5. Chipsy Farmland Thin Curv 160g",
                "6. Chipsy ziemniaczane z czarną truflą Hunter’s Gourmet 40g",
                "7. Pomsticki Lorenz 70g",
                "8. Wykwintny kosz (L)",
                "9. Wykwintny kosz piknikowy (XL)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 782,
        "name": "Duch Bożego Narodzenia (SJ)",
        "handle": "christmas-spirit-sj",
        "description": "Christmas Spirit (SJ)Świąteczna radość nigdy nie smakowała tak dobrze! W naszym koszyku na prezenty świąteczne Christmas Spirit znajdują się wspaniałe smakołyki, świąteczne piwa i ciesząca się dużym zainteresowaniem gra w alkoholizm Roulette 16 Glass Lucky Shot, która zapewni radosne, zabawne i ekscytujące wakacje! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "298.71",
        "inclTax": "298.71",
        "img": [
            "/images/products/Christmas-Spirit-_SJ_1-638025.jpg?v=1671685077",
            "/images/products/Christmas-Spirit-_SJ_2-244360.jpg?v=1671685077"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ruletka 16 Szklanych Szczęśliwych Strzałów Gra Alkoholowa",
                "2. Jinro Soju 360ml x 2",
                "3. Nibbles Premium Natural Mieszane Orzechy 45g",
                "4. Dojrzały Cheddar Tyrrells",
                "5. Chipsy Farmland Thin Curv 160g",
                "6. Chipsy ziemniaczane z czarną truflą Hunter’s Gourmet 40g",
                "7. Pomsticki Lorenz 70g",
                "8. Wykwintny kosz (L)",
                "9. Wykwintny kosz piknikowy (XL)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 783,
        "name": "Pozdrowienia sezonu (LQ)",
        "handle": "seasons-cheers-lq",
        "description": "Season's Cheers (LQ)Season's Cheers to kosz na prezenty świąteczne, który przywołuje radosne i cenne chwile, jakie niesie ze sobą ta wyjątkowa pora roku! Ekscytujące przekąski i wyśmienity irlandzki krem ​​Bailey's są zapakowane w tę świąteczną aranżację, a dla bardziej odważnych dorzuciliśmy także grę w picie alkoholu w Drunken Tower! W tym sezonie przygotuj się na zabawę i mnóstwo chichotu! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "332.94",
        "inclTax": "332.94",
        "img": [
            "/images/products/Season_s-Cheers-_LQ_1-341934.jpg?v=1702988970",
            "/images/products/Season_s-Cheers-_LQ_2_43c7c3eb-fc54-41de-89fb-f9cd6d893602-382507.jpg?v=1702988970"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gra w pijacką wieżę",
                "2. Krem irlandzki Baileys 70cl",
                "3. Nibbles Premium Natural Mieszane Orzechy 45g",
                "4. Dojrzały Cheddar Tyrrells",
                "5. Chipsy Farmland Thin Curv 160g",
                "6. Chipsy ziemniaczane z czarną truflą Hunter’s Gourmet 40g",
                "7. Pomsticki Lorenz 70g",
                "8. Wykwintny kosz (L)",
                "9. Wykwintny kosz piknikowy (XL)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 784,
        "name": "Duch Świąt (LQ)",
        "handle": "christmas-spirit-lq",
        "description": "Świąteczny duch (LQ) Świąteczna radość nigdy nie smakowała tak dobrze! W naszym świątecznym koszu na prezenty Christmas Spirit znajdują się wspaniałe smakołyki, świąteczne piwa i ciesząca się dużym zainteresowaniem gra w alkoholizm Roulette 16 Glass Lucky Shot, która zapewni radosne, zabawne i ekscytujące wakacje! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Christmas-Spirit-_LQ_1-699973.jpg?v=1671685195",
            "/images/products/Christmas-Spirit-_LQ_2-233172.jpg?v=1671685195"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ruletka 16 Szklanych Szczęśliwych Strzałów Gra Alkoholowa",
                "2. Krem irlandzki Baileys 70cl",
                "3. Nibbles Premium Natural Mieszane Orzechy 45g",
                "4. Dojrzały Cheddar Tyrrells",
                "5. Chipsy Farmland Thin Curv 160g",
                "6. Chipsy ziemniaczane z czarną truflą Hunter’s Gourmet 40g",
                "7. Pomsticki Lorenz 70g",
                "8. Wykwintny kosz (L)",
                "9. Wykwintny kosz piknikowy (XL)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 785,
        "name": "Przerwa zimowa (T)",
        "handle": "winter-break-t",
        "description": "Przerwa zimowa (T) Wspaniały sposób na udekorowanie korytarzy w okresie świątecznym lub wysłanie prezentu w celu życzyenia komuś najlepszych Świąt Bożego Narodzenia. Kosz świąteczny z okazji przerwy zimowej został stworzony, aby zaimponować i zachwycić! Zaprojektowane z wyśmienitych mieszanek herbat Juan i relaksujących smakołyków, aby zapewnić najwyższą przyjemność, to wyjątkowe pudełko na prezent bożonarodzeniowy zapewnia im najlepszą zabawę w tym sezonie świątecznym. ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "191.46",
        "inclTax": "191.46",
        "img": [
            "/images/products/WinterBreak_T_2-883868.jpg?v=1671775978",
            "/images/products/WinterBreak_T_1-362271.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw herbat Juan Tea Indulgence z mieszanką kwiatową, lata 10-te",
                "2. Rozluźniacz szyi",
                "3. Masażer skóry głowy",
                "4. MegRhythm Steam Maska na oczy 5-tych",
                "5. Godivine Ciasteczka Migdałowe Żurawinowe 70g",
                "6. Godivine Matcha Biscotti 70g",
                "7. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 786,
        "name": "Przerwa zimowa (C)",
        "handle": "winter-break-c",
        "description": "Przerwa zimowa (C) Wspaniały sposób na udekorowanie korytarzy w okresie świątecznym lub wysłanie komuś jako prezentu życzeń najlepszych Świąt Bożego Narodzenia. Kosz świąteczny na przerwę zimową został stworzony, aby zaimponować i zachwycić! Zaprojektowane z wyśmienitą mokką z kawiarni Starbucks i relaksującymi smakołykami, aby zapewnić najwyższą przyjemność, to wyjątkowe pudełko na prezent bożonarodzeniowy zapewnia im najlepszą zabawę w tym sezonie świątecznym. ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "191.46",
        "inclTax": "191.46",
        "img": [
            "/images/products/Winter-Break-_C_1-580054.jpg?v=1671775979",
            "/images/products/Winter-Break-_C_2_77315f75-b670-4e24-b94a-618529cbff14-177421.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mieszanki Starbucks Caffe Mocha Premium 88g",
                "2. Rozluźniacz szyi",
                "3. Masażer skóry głowy",
                "4. MegRhythm Steam Maska na oczy 5-tych",
                "5. Godivine Ciasteczka Migdałowe Żurawinowe 70g",
                "6. Godivine Matcha Biscotti 70g",
                "7. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 787,
        "name": "Ciepły",
        "handle": "warm-glow-t",
        "description": "Warm Glow (T) Wspaniały sposób, aby pomóc komuś zabłysnąć podczas najlepszych Świąt Bożego Narodzenia. Kosz świąteczny Warm Glow został stworzony, aby zaimponować i zachwycić! Zaprojektowane z wyśmienitych mieszanek herbat Juan i pysznych przekąsek, aby zapewnić najwyższą przyjemność, to wyjątkowe pudełko na prezent bożonarodzeniowy zapewnia im najlepszy czas dla siebie w podróży. ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "191.46",
        "inclTax": "191.46",
        "img": [
            "/images/products/Warm-_-Glow-_T_1-956053.jpg?v=1671775976",
            "/images/products/Warm-_-Glow-_T_2-610937.jpg?v=1671775976"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw herbat Juan Tea Indulgence z mieszanką kwiatową, lata 10-te",
                "2. Składany kubek",
                "3. Składane pudełko na drugie śniadanie (M/L)",
                "4. Torba do recyklingu",
                "5. Godivine Ciasteczka Migdałowe Żurawinowe 70g",
                "6. Godivine Matcha Biscotti 70g",
                "7. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 788,
        "name": "Ciepły",
        "handle": "warm-glow-c",
        "description": "Ciepły blask (C) Wspaniały sposób, aby pomóc komuś zabłysnąć podczas najlepszych Świąt Bożego Narodzenia. Kosz świąteczny Warm Glow został stworzony, aby zaimponować i zachwycić! Zaprojektowane z mocną kawową mokką Starbucks i pysznymi przekąskami, aby zapewnić najwyższą przyjemność, to wyjątkowe pudełko na prezent bożonarodzeniowy zapewnia im najlepszą zabawę w podróży. ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "191.46",
        "inclTax": "191.46",
        "img": [
            "/images/products/Warm-_-Glow-_C_1_777688d0-31e2-43e9-8f73-8b43fe4217d5-780590.jpg?v=1671775975",
            "/images/products/Warm-_-Glow-_C_2-491310.jpg?v=1671775975"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mieszanki Starbucks Caffe Mocha Premium 88g",
                "2. Składany kubek",
                "3. Składane pudełko na drugie śniadanie (M/L)",
                "4. Torba do recyklingu",
                "5. Godivine Ciasteczka Migdałowe Żurawinowe 70g",
                "6. Godivine Matcha Biscotti 70g",
                "7. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 789,
        "name": "Zimowe ciepło",
        "handle": "winter-warmth",
        "description": "Zimowe ciepło Świąteczne pudełko upominkowe Winter Warmth z pewnością zapewni ciepło bliskiej osobie w mroźną zimową noc. Podaruj ich oczom tę piękną kolekcję smakołyków, które z pewnością rozjaśnią ich dzień. Przejrzyj naszą obszerną kolekcję świątecznych koszy na prezenty świąteczne i przygotuj się na zachwyt i zaimponowanie odbiorcy naszą dedykowaną usługą dostawy.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Winter-Warmth1-195881.jpg?v=1671775978",
            "/images/products/Winter-Warmth2-962508.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Chipsy ziemniaczane Tyrells Sweet Chilli i czerwona papryka 40g",
                "2. Śmietana z cienkimi chipsami Vurv",
                "3. Esprit Musujący Napój Bezalkoholowy Marakuja 300ml",
                "4. Mieszanka orzechów kokosowych Amazin Graze Pandan 30g",
                "5. El Almendro Cubits Biała Czekolada z Malinami 25g",
                "6. Mini Madeleine St Michel z kawałkami czekolady 75g",
                "7. Bonart Dragee Czekolada Orzechowa Mleczna 100g",
                "8. Kostka waflowa Jacker 100g",
                "9. Godivine Ciastko Migdałowe Żurawinowe 70g",
                "10. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 790,
        "name": "Wakacyjne szczęście",
        "handle": "holiday-happiness",
        "description": "Holiday HappinessHoliday Happiness to świąteczny kosz na prezenty o tematyce zimowej, który odzwierciedla ducha dzielenia się w okresie świątecznym. Już dziś życz komuś najweselszych Świąt Bożego Narodzenia w 2022 roku dzięki tej wspaniałej aranżacji pysznych przekąsek i świątecznego piwa! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "189.18",
        "inclTax": "189.18",
        "img": [
            "/images/products/Holiday-Happiness1_eac2dd0f-d0ae-47ff-b906-8ffba880e21e-581377.jpg?v=1671685351",
            "/images/products/Holiday-Happiness2-214475.jpg?v=1671685351"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Piwo Heineken 330ml",
                "2. Piwo Hoegarden 330ml",
                "3. Piwo Kronenbourg 1664 330ml",
                "4. Piwo Corona 355ml",
                "5. Mieszanka orzechów kokosowych Amazin Graze Pandan 30g",
                "6. Hunter’s Gourmet Ręcznie Gotowane Chipsy Ziemniaczane Czarna Trufla 40g",
                "7. Słodki film",
                "8. Nibbles Pieczone Orzechy Nerkowca 35g",
                "9. Dojrzały Cheddar Tyrrells",
                "10. Toblerone Chrupiące Migdały 100g",
                "11. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 791,
        "name": "Złote Święta",
        "handle": "golden-holidays",
        "description": "Golden HolidaysŚwiąteczny kosz na prezenty Golden Holidays to wspaniały sposób na uczczenie Świąt Bożego Narodzenia! Ten wspaniały świąteczny kosz pełen słodkich smakołyków i win musujących z pewnością osłodzi sezon. Bądź tym, który zrobi najlepsze wrażenie dzięki pysznym smakołykom i świątecznym nalewkom w tym wesołym prezencie już dziś! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Golden-Holidays1-582136.jpg?v=1671685312",
            "/images/products/Golden-Holidays2-113319.jpg?v=1671685312"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. JP. Chenet Ice Edition 200ml",
                "2. JP. Chenet Ice Edition Róża 200ml",
                "3. Godiva Signature Batonik Migdałowy z Ciemnej Czekolady 90g",
                "4. Małgosia z kiełkami nerkowca Himalajska różowa sól 56g",
                "5. Nugat Migdałowy Golden Bon Bon - różne 70g",
                "6. Excelcium – Praliny BonBons De Chocolat 200g Złoto",
                "7. Ręcznie przyrządzane chipsy ziemniaczane z czarną truflą Hunter’s Gourmet 40g",
                "8. Kostka waflowa Jacker 100g",
                "9. Znakomite pudełko magnetyczne"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 792,
        "name": "Sekretny Mikołaj",
        "handle": "secret-santa",
        "description": "Sekretny Mikołaj Świętuj święta dzięki świątecznemu koszyczkowi na wino Sekretnego Świętego Mikołaja! Rozkoszuj się połączeniem czekolady i wina na najweselszy okres świąt Bożego Narodzenia! Dzięki temu prostemu koszowi świąteczne prezenty będą proste! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "170.92",
        "inclTax": "170.92",
        "img": [
            "/images/products/Secret-Santa1-658655.jpg?v=1702988974",
            "/images/products/Secret-Santa2-112767.jpg?v=1702988974"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zamek Fleurs Vignobles 75cl",
                "2. Hamlet Różne Belgijskie Czekoladki 125g",
                "3. Wykwintna skrzynka na wino"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 793,
        "name": "Błyszczące sny",
        "handle": "sparkling-dreams",
        "description": "Sparkling Dreams Dodaj trochę wesołego ho-ho-ho do swojego spotkania dzięki świątecznemu koszykowi na wino Sparkling Dreams! Ten zachwycający prezent, zawierający szeroki wybór czekoladek i świąteczne czerwone wino, jest pięknie zaprezentowany w wykwintnej drewnianej skrzynce. ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Sparkling-Dreams1_6b82ff51-2fa6-47c2-8afe-d7da5517410a-351845.jpg?v=1671775907",
            "/images/products/Sparkling-Dreams2-799898.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Grand Jacques Cabernet Sauvignon Francja Wino czerwone 75cl",
                "2. Asortyment Zimowy Hamlet z Nadzieniem Orzechowym 250g",
                "3. GuyLian The Original Seashells Premium Belgijska Czekolada 250g",
                "4. Wykwintna drewniana skrzynka na wino"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 794,
        "name": "Srebrne Dzwonki",
        "handle": "silver-bells",
        "description": "Silver BellsŚwiętuj święta dzięki świątecznemu koszykowi na wino Silver Bells! Rozkoszuj się naszymi świątecznymi nalewkami na najweselszy okres świąt Bożego Narodzenia! Ta prosta aranżacja jest zapakowana w wykwintną podwójną skrzynkę na wino, dzięki czemu świąteczne prezenty będą reprezentacyjne i zachwycające! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "234.82",
        "inclTax": "234.82",
        "img": [
            "/images/products/Silver-Bells1-892826.jpg?v=1671775907",
            "/images/products/Silver-Bells2-692704.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Les Ormes De Cambras Cabernet Sauvignon 75cl",
                "2. Les Ormes De Cambras Sauvignon 75cl",
                "3. Wykwintna podwójna skrzynka na wino"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 795,
        "name": "Zimowy Anioł",
        "handle": "winter-angel",
        "description": "Winter AngelWznieś toast za święta świątecznym koszykiem na wino Winter Angel! Rozkoszuj się naszym ulubionym świątecznym szampanem na najweselszy okres świąt Bożego Narodzenia! Ta prosta aranżacja jest zapakowana w wykwintną drewnianą skrzynkę, dzięki czemu świąteczne prezenty będą reprezentacyjne i zachwycające! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "597.66",
        "inclTax": "597.66",
        "img": [
            "/images/products/Winter-Angel1-578289.jpg?v=1671775978",
            "/images/products/Winter-Angel2-954505.jpg?v=1671775978"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Moët",
                "2. Szampan Veuve Clicquot Yellow Label NV 75 cl",
                "3. Wykwintna drewniana skrzynia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 796,
        "name": "Rozprzestrzenianie uroczystości",
        "handle": "celebration-spread",
        "description": "Celebration SpreadWysyłanie świątecznej radości nigdy nie smakowało tak dobrze! W naszym świątecznym podarunku z jedzeniem Celebration Spread znajdują się pyszne słodkie smakołyki oraz pikantne przekąski, dzięki którym możesz życzyć bliskiej Ci osobie wesołych i radosnych zimowych wakacji! ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "312.41",
        "inclTax": "312.41",
        "img": [
            "/images/products/Celebration-Spread1-126128.jpg?v=1671685076",
            "/images/products/Celebration-Spread2-737455.jpg?v=1671685076"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Cantine Paolini Nero D Avola Terre Siciliane 75cl",
                "2. La Mere Poulard Les Sables Francuskie ciasteczka maślane 125g",
                "3. Herbatniki Szkockie Walker Serowe 250g",
                "4. Wernli Truffet 100g",
                "5. Excelcium – Praliny BonBons De Chocolat 200g (ZŁOTE)",
                "6. Godivine Nutty Mix Orzechów 80g",
                "7. Paluszki z ciasta francuskiego Vicenzi Millefoglie 125g",
                "8. Godivine Matcha Biscotti 70g",
                "9. Wykwintne pudełko ze skrzynią"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 797,
        "name": "Uroczystości sezonu",
        "handle": "seasons-celebrations",
        "description": "Season's Celebrations Podaruj im świąteczną przekąskę w tym sezonie dzięki koszowi na prezenty świąteczne Season's Celebrations. Jest pełen ulubionych smakołyków i świątecznych nalewek, które sprawią, że Twoi bliscy będą radośni! Ten świąteczny kosz, nasz ulubiony, jest pięknie ułożony w wykwintnym koszu piknikowym.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "878.34",
        "inclTax": "878.34",
        "img": [
            "/images/products/Season_s-Celebrations1-547318.jpg?v=1702988969",
            "/images/products/Season_s-Celebrations2-471474.jpg?v=1702988969"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Les Ormes De Cambras Cabernet Sauvignon 75cl",
                "2. Les Ormes De Cambras Sauvignon 75cl",
                "3. Tuba Baileys Trufle 320g",
                "4. Zestaw herbat Juan Tea Indulgence z mieszanką kwiatową, 18 torebek",
                "5. Walkers Luksusowe mini tarty owocowe z dodatkiem ginu 135g",
                "6. Rozmaryn z dobrego sera",
                "7. Biszkopt czekoladowo-nugatowy Walters Angel 150g",
                "8. Walkers Asortyment Ciasteczek Szkockich 250g",
                "9. Desobry Luksusowe Ciasteczka Belgijskie 375g",
                "10. „Niesamowite pudełko z różnymi orzechami”",
                "– 2x Mieszanka Kokosowego Curry Limonkowo Orzechowego 30g",
                "– 2x Mieszanka Orzechów Słodkiego Chilli 30g",
                "– 2x Mieszanka Orzechów Kokosowych Pandan 30g",
                "– 2x Mieszanka pikantnych orzechów klonowych 30g",
                "11. Pani Bridges – Marmolada Pomarańczowa z Miodem 340g",
                "12. Farmhouse Biscuits Summer Garden Solony Karmel Tubka 150g",
                "13. Wykwintny kosz piknikowy (XL)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 798,
        "name": "Zoja",
        "handle": "zoya",
        "description": "Przedstawiamy nasz bukiet Garden of Elegance, bujną i żywą kompozycję, która celebruje piękno wiosny. Ta oszałamiająca wystawa składa się z mieszanki promienistych tulipanów, delikatnych róż i zabawnych gerber, a wszystko to pomysłowo zaaranżowane z bujną zielenią. Bukiet ten, umieszczony w eleganckim szklanym wazonie, jest idealny na każdą okazję, dodając odrobinę wdzięku i koloru każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Zoya-2a-843623.jpg?v=1724340103",
            "/images/products/Zoya-1a-727202.jpg?v=1724340103"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Spryskaj różę. Eustoma. Gerbera . Róża. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 799,
        "name": "Arlette",
        "handle": "arlette",
        "description": "Elegancja rozkwita dzięki naszym Białym Różom ozdobionym delikatnym Fioletowym Oddechem Dziecka, Stokrotkami i żywym Zielonym Dzwonkiem, pięknie zaprezentowanym w stylowym fioletowym opakowaniu. Ten czarujący bukiet łączy w sobie czystość białych róż z kapryśnym pięknem fioletowych i zielonych akcentów, tworząc oszałamiającą kompozycję, która urzeka zmysły.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Arlette-1aa.jpg?v=1706235654",
            "/images/products/Arlette-2aa.jpg?v=1706235653"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 800,
        "name": "Mila",
        "handle": "mila",
        "description": "Pielęgnuj wieczne piękno naszych wiecznie zachowanych fioletowych i różowych róż, starannie ułożonych w wieniec w kształcie serca. Ta wyjątkowa aranżacja, symbolizująca trwałą miłość i delikatne emocje, jest ponadczasowym wyrazem uczuć, który urzeka i oczarowuje, dodając odrobinę wiecznego romansu do każdej przestrzeni.",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Mila-1aa-326052.jpg?v=1704463772",
            "/images/products/Mila-2aa-249109.jpg?v=1704463772"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Gossypium. Suszone wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 801,
        "name": "Zimowe cuda",
        "handle": "winter-wonders",
        "description": "Winter WondersŚwiąteczny kosz na balony na ogrzane powietrze Winter Wonders z pewnością zapewni ciepło bliskiej osobie w mroźną zimową noc. Podaruj ich oczom tę piękną kolekcję świątecznych kwiatów, świeżo zebranych, aby rozjaśnić ich dzień. ",
        "type": "Balony i Kwiaty",
        "tags": [],
        "price": "234.82",
        "inclTax": "234.82",
        "img": [
            "/images/products/Winter-Wonders1A-583869.jpg?v=1671775979",
            "/images/products/Winter-Wonders2_57a7c82a-bb83-4686-9688-e68b885df750-811341.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Kryształowy Balon (maksymalna liczba znaków w nazwie: 8) . Czerwona Róża. Gerbera . Hypericum. Szyszka sosnowa. Konserwowane wapno. Zachowany plasterek pomarańczy. Orchidea. Eukaliptus Sinara. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 802,
        "name": "Wakacyjne dziedzictwo",
        "handle": "holiday-heritage",
        "description": "Holiday HeritageŚwiąteczny kosz na balony Holiday Heritage to bukiet kwiatów o tematyce zimowej, który odzwierciedla przytulne i ciepłe kolory sezonu świątecznego. Życz komuś najweselszego grudnia 2022 roku dzięki tej wspaniałej aranżacji. ",
        "type": "Balony i Kwiaty",
        "tags": [],
        "price": "255.36",
        "inclTax": "255.36",
        "img": [
            "/images/products/Holiday-Heritage1A-929373.jpg?v=1671685463",
            "/images/products/Holiday-Heritage2-991978.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Kryształowy Balon (maksymalna liczba znaków w nazwie: 8) . Zielone Jabłko. Gerbera . Róże. Hypericum. Konserwowane wapno. Szyszka sosnowa. Liście Świąteczne. Orchidea. Gomphocarpus Physocarpus. Eukaliptus . Zachowaj plasterek pomarańczy. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 803,
        "name": "Kolęda",
        "handle": "christmas-carol",
        "description": "Kosz na balony na ogrzane powietrze, będący hołdem dla zimowych i chłodnych Świąt Bożego Narodzenia, kosz na balony na ogrzane powietrze Christmas Carol zabierze Twoją wyobraźnię daleko. Pomyśl o ośnieżonych domkach i świątecznych ciepłych naparach. Ten wesoły, niebieski prezent bożonarodzeniowy z pewnością będzie przyciągającą uwagę ozdobą każdego domu w okresie świątecznym! ",
        "type": "Balony i Kwiaty",
        "tags": [],
        "price": "255.36",
        "inclTax": "255.36",
        "img": [
            "/images/products/Christmas-Carol1-397690.jpg?v=1671685194",
            "/images/products/Christmas-Carol2-430901.jpg?v=1671685194"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Kryształowy Balon (maksymalna liczba znaków w nazwie: 8) . Szyszka sosnowa. Goździk . Fioletowe Róże. Eustoma (lisanthum). Wosk Kwiatowy. Weronika. Eukaliptus Sinara. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 804,
        "name": "Świąteczna radość",
        "handle": "holiday-cheer",
        "description": "Świąteczna radośćWysyłanie świątecznej radości nigdy nie smakowało tak dobrze! W naszym świątecznym koszyku na balony na ogrzane powietrze Holiday Cheer znajdują się wspaniałe smakołyki, świąteczne piwo i przyjemna dla publiczności gra w pijacką wieżę, dzięki której możesz życzyć swojej wyjątkowej osobie zabawnych i radosnych zimowych wakacji!",
        "type": "Balony i Kwiaty",
        "tags": [],
        "price": "255.36",
        "inclTax": "255.36",
        "img": [
            "/images/products/Holiday-Cheer1A-570924.jpg?v=1671685323",
            "/images/products/Holiday-Cheer2-530182.jpg?v=1671685323"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Wernli Truffet 100g",
                "2. Beryl’s Tiramisu Migdałowa Czekolada Mleczna 65g",
                "3. Piwo Heineken 330g",
                "4. Gra w pijacką wieżę"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 805,
        "name": "Zimowe życzenia",
        "handle": "winter-wishes",
        "description": "Winter WishesWesoły i jasny, ten radosny świąteczny balonik Winter Wishes jest gotowy do rozpowszechniania świątecznych życzeń! Wypełniony po brzegi pikantnymi i słodkimi smakołykami, dołączyliśmy także szampana dla tych, którzy szukają dodatkowej przyjemności!",
        "type": "Balony i Kwiaty",
        "tags": [],
        "price": "469.86",
        "inclTax": "469.86",
        "img": [
            "/images/products/Winter-Wishes1A-270687.jpg?v=1671775979",
            "/images/products/Winter-Wishes2-276860.jpg?v=1671775979"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Słodkie chili Tyrrells",
                "2. Asortyment Delfi 180g",
                "3. Truskawki Walkers",
                "4. Moët"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 806,
        "name": "Odpust Świętego Mikołaja",
        "handle": "santas-indulgence",
        "description": "Odpust Świętego Mikołaja Świętuj święta dzięki temu świątecznemu koszykowi na balony! Santa's Indulgence z pewnością zachwyci wesołym wyborem pysznych słodkich smakołyków i świątecznych nalewek! Ta wesoła koszyczka to impreza w pakiecie, w komplecie z kieliszkiem do wina, zapewniającym najwyższą rozkosz.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "415.10",
        "inclTax": "415.10",
        "img": [
            "/images/products/Santa_s-Indulgence1_7ffabfb5-d6b0-47de-a571-d7030a3e3bbd-570465.jpg?v=1702988968",
            "/images/products/Santa_s-Indulgence2-699976.jpg?v=1702988968"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Asortyment Hamleta Czekoladki belgijskie (czerwone)",
                "2. Czekolada Hamlet Excelcium 180g",
                "3. Beryl’s Tiramisu Migdałowa Czekolada Mleczna 65g",
                "4. Cavendisha",
                "5. Domini Veneti Classico Valpolicella DOC Pruviniano 2019 750 ML",
                "6. Kieliszek do wina"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 807,
        "name": "Niespodzianka sezonu",
        "handle": "seasons-surprise",
        "description": "Sezonowa Niespodzianka Spraw najlepszą niespodziankę w te święta dzięki świątecznemu koszykowi na balony z Niespodzianką Sezonu! Świętuj radosne i cenne chwile, które niesie ze sobą ta wyjątkowa pora roku, dzięki temu wybornemu asortymentowi świątecznych smakołyków, które z pewnością zachwycą cukroholika!  ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "266.77",
        "inclTax": "266.77",
        "img": [
            "/images/products/Season_s-Surprise1-162773.jpg?v=1702988970",
            "/images/products/Season_s-Surprise2-298481.jpg?v=1702988970"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Cupido Muszle Czekoladowe z Orzechami Laskowymi 250g",
                "2. LINDT Lindor Cornet różne 200g",
                "3. Cavendisha",
                "4. Piwo Heineken 330g",
                "5. Złoty Bonbon Miękki Nugat Migdałowy 70g"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 808,
        "name": "Sekret Świętego Mikołaja",
        "handle": "santas-secret",
        "description": "Sekret Świętego Mikołaja Dodaj trochę wesołych ho-ho-ho do swojego spotkania dzięki świątecznemu koszykowi na balony Sekret Świętego Mikołaja! Ten wspaniały prezent, zawierający wesoły wybór słodkich smakołyków i świąteczne wino musujące, jest pięknie zaprezentowany w wykwintnej drewnianej skrzynce.  ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "298.71",
        "inclTax": "298.71",
        "img": [
            "/images/products/Santa_s-Secret1-932283.jpg?v=1702988969",
            "/images/products/Santa_s-Secret2_12f5c3e6-c038-40eb-b4e1-ce5894783cdf-793641.jpg?v=1702988969"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Cupido Muszle Czekoladowe z Orzechami Laskowymi 250g",
                "2. Cavendisha",
                "3. LINDT Lindor Cornet różne 200g",
                "4. Asortyment Hamleta Belgijskie czekoladki (Złote)",
                "5. Movietime Oryginalna sól morska do popcornu 20g",
                "6.JP. Chenet Ice Edition Blanc Białe wino musujące 200 ml"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 809,
        "name": "Świąteczne pozdrowienia",
        "handle": "festive-cheers",
        "description": "Świąteczne okrzykiWysyłanie świątecznej radości nigdy nie smakowało tak dobrze! W naszym świątecznym koszyku z balonikami Festive Cheers znajdują się wspaniałe smakołyki oraz świąteczne nalewki, które zapewnią wesołe, zabawne i ekscytujące wakacje!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "725.45",
        "inclTax": "725.45",
        "img": [
            "/images/products/Festive-Cheers1_dbe5a098-313d-46e1-8e29-543e65c12a8f-272389.jpg?v=1671685303",
            "/images/products/Festive-Cheers2_0107740e-3e5f-45ad-a923-86fab1d92d0a-693074.jpg?v=1671685303"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Golden Bonbon Miękki Nugat Migdałowy 130g",
                "2. Jules Destrooper Biscuits – Belgijska Czekolada Cienkie 100g",
                "3. Val d’Oca Blue Millesimato Prosecco Extra Dry 750ml",
                "4. Moeta",
                "5. Błękitna Jagoda"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 810,
        "name": "Przysmak Dziadka do Orzechów",
        "handle": "nutcrackers-delight",
        "description": "Świąteczny balonik Dziadek do orzechów Delight Kwitnący świątecznym urokiem, który przywołuje wspomnienia bajkowych okresów świątecznych, a jednocześnie nawiązuje do tradycyjnego stylu stylizacji, świąteczny balonik Dziadek do orzechów jest gotowy, aby szerzyć radość sezonu wśród Twoich bliskich! Idealny jako prezent bożonarodzeniowy dla mamy lub atrakcja na każdej uroczystości, wystarczy spersonalizowana wiadomość od Ciebie, aby rozjaśnić świąteczną radość!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "383.15",
        "inclTax": "383.15",
        "img": [
            "/images/products/Nutcracker_s-Delight1-782275.jpg?v=1671685496",
            "/images/products/Nutcracker_s-Delight2-267930.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Domini Veneti Classico Valpolicella DOC Pruviniano 750 ML",
                "2. Czerwone róże",
                "3. Gerbera",
                "4. Hypericum",
                "5. Goździk",
                "6. Eukaliptus",
                "7. Konserwowane wapno"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 811,
        "name": "Pozdrawiam przy kominku",
        "handle": "fireside-cheers",
        "description": "Fireside CheersWspaniały sposób na udekorowanie sal w okresie świątecznym lub wysłanie komuś jako prezentu, aby życzyć komuś najlepszych Świąt, świąteczny kosz na balony na ogrzane powietrze Fireside Cheers został zaprojektowany tak, aby robić wrażenie i zachwycać! Świąteczny cabernet umieszczony jest wśród wesołych świątecznych kwiatów, aby nadać temu wyjątkowemu koszowi wyrafinowany i czarujący charakter, jaki sobie wyobrażamy. ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "447.04",
        "inclTax": "447.04",
        "img": [
            "/images/products/Fireside-Cheers1-818514.jpg?v=1671685311",
            "/images/products/Fireside-Cheers2-449397.jpg?v=1671685311"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Penfolds Max’s Shiraz Cabernet 750ml",
                "2. Protea",
                "3. Nowa Gerbera",
                "4. Czerwone róże",
                "5. Szyszka",
                "6. Hyperikum",
                "7. Orchidea",
                "8. Eukaliptus"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 812,
        "name": "Świąteczna elegancja",
        "handle": "festive-elegance",
        "description": "Świąteczna elegancjaGotowy na stylowy prezent i sprawić, by Boże Narodzenie 2022 wyglądało idealnie? W te święta podaruj świąteczny kosz na balony Festive Elegance, aby dodać wyjątkowego blasku temu sezonowi! Świąteczne nalewki i świąteczne kwiaty ozdabiają tę kompozycję, tworząc wesołe, święte i szczęśliwe życzenia świąteczne. ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "469.86",
        "inclTax": "469.86",
        "img": [
            "/images/products/Festive-Elegance1_230955ca-7509-43db-a1c1-adbe090eb7ee-852095.jpg?v=1671685309",
            "/images/products/Festive-Elegance2-715323.jpg?v=1671685309"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. George Wyndham Bin 444 Cabernet Sauvignon 750ml",
                "2. Penfolds Max’s Shiraz Cabernet 750ml",
                "3. Wykwintna kompozycja kwiatowa"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 813,
        "name": "Świąteczne łyki",
        "handle": "christmas-sips",
        "description": "Christmas Sips Akcentowany asortymentem świątecznych kwiatów i prezentowany w wykwintnym pudełku na kapelusze, świąteczny koszyk na balony na gorące powietrze Christmas Sips z motywem Moet Chandon Imperial sprawi, że zostaniesz zauważony! Wyślij pozdrowienia świąteczne swoim przyjaciołom i rodzinie, blisko i daleko, i weź udział w ich obchodach w tym okresie świątecznym!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "478.99",
        "inclTax": "478.99",
        "img": [
            "/images/products/Christmas-Sips1-120484.jpg?v=1671685195",
            "/images/products/Christmas-Sips2-871837.jpg?v=1671685195"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Moeta",
                "2. Protea",
                "3. Nowa Gerbera",
                "4. Czerwone róże",
                "5. Szyszka",
                "6. Hyperikum",
                "7. Orchidea",
                "8. Eukaliptus"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 814,
        "name": "Łucja",
        "handle": "lucia",
        "description": "Ten elegancki bukiet przedstawia nieskazitelne białe róże uzupełnione delikatnymi srebrnymi liśćmi i delikatnymi białymi akcentami. Zapakowany w luksusowy biały papier i wykończony pasującą wstążką, emanuje ponadczasowym wdziękiem i wyrafinowaniem, idealny na wesela, rocznice lub inne specjalne okazje.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Lucia-2a.jpg?v=1720880003",
            "/images/products/Lucia-1a.jpg?v=1720880002"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 24cm*30cm"
    },
    {
        "id": 815,
        "name": "Dobrobyt (VI)",
        "handle": "prosperity-vi",
        "description": "Świętuj wszystkie wielkie otwarcia w wielkim stylu dzięki naszemu stojakowi na kwiaty Vibrant Harmony. Symfonia różowej, szampana i czerwonej gerbery, róż i kwiatów łączy się, tworząc zachwycającą wizualną melodię, która reprezentuje radość, różnorodność i tętniącego życiem ducha nowych początków. To kwiatowe arcydzieło wyróżnia się wysoką elegancją i pozytywnym nastawieniem, dzięki czemu będzie idealnym elementem centralnym ważnej okazji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Prosperity-_VI_-1e.jpg?v=1720496961",
            "/images/products/Grand-Opening-Card-2024_417df5f5-5ae2-41bc-a85e-7a0c64f1031a.jpg?v=1720496972",
            "/images/products/Prosperity-_VI_-12.jpg?v=1720496962"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Eustoma. Lilie . Anturium . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 816,
        "name": "Bogactwo (IV)",
        "handle": "wealth-iv",
        "description": "Podnieś wszystkie wielkie otwarcia dzięki naszemu statywowi Peachy Petal Ensemble, harmonijnemu połączeniu różowych i brzoskwiniowych róż, w połączeniu z delikatnym urokiem różowej i zakurzonej hortensji Matthiola. To kwiatowe arcydzieło jest wysokie, emanuje elegancją i urokiem, obiecując uroczystość pełną piękna i sukcesu.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Wealth-_IV_-1b.jpg?v=1720101783",
            "/images/products/Grand-Opening-Card-2024_db40ba53-e800-42b9-acfa-ccdb17d5e3be.jpg?v=1720101788",
            "/images/products/Wealth-_IV_-2b.jpg?v=1720101783"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Hortensje. Mattiola. Wierzby. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 817,
        "name": "Bogactwo (VII)",
        "handle": "wealth-vii",
        "description": "Wyraź odważne oświadczenie podczas wszystkich wielkich otwarciach biznesowych dzięki naszemu statywowi Vibrant Citrus Bloom. Pełna pomarańczowych i niebieskich kwiatów, pięknie ozdobiona pasującymi listwami przypodłogowymi, ta aranżacja oznacza energię, kreatywność i początek dynamicznego przedsięwzięcia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Wealth-_VII_-1b.jpg?v=1720101928",
            "/images/products/Grand-Opening-Card-2024_d33ff1dd-b465-4fd2-ae0a-e67582ab7132.jpg?v=1720101936",
            "/images/products/Wealth-_VII_-2b.jpg?v=1720101928"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Hortensje. Eustoma. Sztuczne storczyki. Anturium . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 818,
        "name": "Bogactwo (VIII)",
        "handle": "wealth-viii",
        "description": "Zanurz wszystkie wielkie otwarcia w neutralnym bogactwie dzięki naszemu statywowi. Miękkie i wyrafinowane kwiaty w kolorze nude harmonijnie prezentują się na dopasowanych listwach przypodłogowych, tworząc luksusowy element centralny, który oznacza sukces i początek prestiżowej podróży biznesowej.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Wealth-_VIII_-1b.jpg?v=1720102128",
            "/images/products/Grand-Opening-Card-2024_a34ef03a-4bee-47ed-9fda-e342c676cd87.jpg?v=1720102136",
            "/images/products/Wealth-_VIII_-2b.jpg?v=1720102129"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustoma. Mattiola. Anturium . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 819,
        "name": "Lilibet",
        "handle": "lilibet",
        "description": "Ten delikatny bukiet składa się z szeregu miękkiej, pastelowej eustomy i małych kwiatów przypominających stokrotki. Akcentowana oddechem dziecka i bujną zielenią, kompozycja jest owinięta delikatnym różowo-białym papierem, co nadaje jej romantyczny i elegancki wygląd, idealny na każdą wyjątkową okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety eustomy"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Lilibet-2a-368923.jpg?v=1721222880",
            "/images/products/Lilibet-1a-569404.jpg?v=1721222880"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 820,
        "name": "Obfitość (I)",
        "handle": "abundance-i",
        "description": "Odkryj wspaniałość dzięki naszemu stojakowi na kwiaty Jumbo. Ozdobiona niebieskimi i różowymi hortensjami i gerberami, ta harmonijna mieszanka kolorów oznacza dobrobyt, wyrafinowanie i kwitnącą podróż. Tworząc oszałamiający wizualnie i spójny element centralny, symbolizuje sukces i elegancję podczas otwierania Twojej firmy. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Abundance-_I_-1h.jpg?v=1720496756",
            "/images/products/Grand-Opening-Card-2024.jpg?v=1720496763",
            "/images/products/Abundance-_I_-2g.jpg?v=1720494321"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Sztuczne hortensje. Mattiola. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 821,
        "name": "Caitlin",
        "handle": "caitlin",
        "description": "Ten oszałamiający bukiet składa się z eleganckich białych stokrotek gerbery, miękkich brzoskwiniowych róż i bujnej zieleni, zwieńczonych uroczym pluszowym misiem w stroju na zakończenie szkoły. Zapakowany w śnieżnobiały papier i przewiązany wstążką, jest idealnym prezentem dla absolwenta.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Caitlin-2a.jpg?v=1720666147",
            "/images/products/Caitlin-1a-122571.jpg?v=1720788697"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera. Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 822,
        "name": "Zia",
        "handle": "zia-1",
        "description": "„Miłość jest ze wszystkich namiętności najsilniejsza, gdyż atakuje jednocześnie głowę, serce i zmysły”. - Lao Tzu",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Zia1C_9d6b1102-9a07-4540-82f5-819b01ecee12.jpg?v=1670371898",
            "/images/products/Zia2C.jpg?v=1670371995"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże z tajemniczego ogrodu. Srebrne róże w sprayu z cieniami. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 823,
        "name": "Obfitość (II)",
        "handle": "abundance-ii",
        "description": "Poczuj sukces dzięki naszemu stoisku na wielkie otwarcie Crimson Elegance Skąpane w złotym blasku słoneczników, nasze stoisko emanuje ciepłem i pozytywnym nastawieniem, ucieleśniając ducha nowych początków. Każdy słonecznik ze swoimi żywymi płatkami i wysokim wzrostem symbolizuje radość i obfitość, jakie niesie ze sobą uroczyste otwarcie. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Abundance-_II_-1e.jpg?v=1720494970",
            "/images/products/Grand-Opening-Card-2024_5f7887bb-e802-42b0-b97d-a7a3a97e110d.jpg?v=1720494977",
            "/images/products/Abundance-_II_-2e.jpg?v=1720494970"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Sztuczne hortensje. Mattiola. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 824,
        "name": "Wschód słońca FBS",
        "handle": "sunrise-fbs",
        "description": "Ta aranżacja, ozdobiona promiennym słonecznikiem pośrodku i otoczonym kolorowym oddechem dziecka, wnosi przypływ słońca i radości. Zapakowany w stylową brązową torebkę z satynową wstążką, jest idealnym prezentem, który rozjaśni każdy dzień i szerze szczęście.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Sunrise-FBS-1b-725115.jpg?v=1720118499",
            "/images/products/Sunrise-FBS-2a.jpg?v=1720063489"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Oddech tęczowego dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 825,
        "name": "Dobrobyt (VII)",
        "handle": "prosperity-vii",
        "description": "Rozpocznij pełną sukcesów podróż dzięki naszemu stojakowi kwiatowemu Blooming Success. Słoneczniki stoją wysoko pośród morza róż, ucieleśniając żywotność i osiągnięcia, które reprezentują wszystkie wielkie otwarcia. Ta uroczysta kompozycja kwiatowa na otwarcie to wizualne arcydzieło, które mówi wiele o elegancji i wdzięku wszystkich specjalnych okazji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Prosperity-_VII_-1d.jpg?v=1720406605",
            "/images/products/Grand-Opening-Card-2024_9b84234f-3a06-4b7a-8f22-c47ccc7c731f.jpg?v=1720406608",
            "/images/products/Prosperity-_VII_-2d.jpg?v=1720406604"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 826,
        "name": "Łaskawe Sentymenty FNB",
        "handle": "gracious-sentiments-fnb",
        "description": "Łaskawe uczucia Jeśli chcesz wysłać odbiorcy łaskawe uczucia, nie szukaj dalej. Balon i kosz na żywność Gracious Sentiments wywrą odpowiednie wrażenie na odbiorcy dzięki asortymentowi pysznych, ulubionych przekąsek! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Gracious-Sentiments-FNB1-740018.jpg?v=1709143475",
            "/images/products/Gracious-Sentiments2-630204.jpg?v=1709084592"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Excelcium – Praliny BonBons De Chocolat 180g",
                "2. Czekolada Asortyment Hamlet 125g",
                "3. Lindt Lindor Mieszana szwajcarska czekolada 200g",
                "4. Tiptree – Pomarańczowe",
                "5. Breitsamer Honig Surowy Miód 500g",
                "6. Gusto Organiczna sycylijska czerwona pomarańcza 275ml",
                "7. Kubeczki deserowe Cupido Latte Macchiato 125g",
                "8. Spersonalizowany tekst na balonie z 6 mini balonami",
                "9. Wykwintny kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 827,
        "name": "Natychmiastowa radość FNB",
        "handle": "instant-joy-fnb",
        "description": "Instant Joy Podaruj natychmiastową radość dzięki naszemu balonowi Instant Joy i koszykowi na jedzenie! Idealny, gdy bliska Ci osoba potrzebuje pocieszenia, ten wspaniały zestaw pocieszających smakołyków załatwi sprawę!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie"
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Instant-Joy-FNB1-182794.jpg?v=1709056579",
            "/images/products/Instant-Joy2_ee1dc805-6742-4698-9e47-7468e532f151-283659.jpg?v=1709012750"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Aalst- Owoce i Orzechy Czekolada Mleczna Puszka 150g",
                "2. Bahlsen Deloba Jagoda 100g",
                "3. Temptation - Domowe ciasteczka z kawałkami czekolady 250g",
                "4. Pomarańczowe drzewko tipi",
                "5. Oh So Healthy Guava Purple Yam Banana Chipsy owocowe 40g",
                "6. Geogias Naturalny Sok Gruszkowy 300ml",
                "7. Spersonalizowany tekst na balonie z 6 mini balonami",
                "8. Wykwintny kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 828,
        "name": "Najlepsze początki FNB",
        "handle": "best-beginnings-fnb",
        "description": "Najlepsze początkiŻycz komuś najlepszych początków dzięki temu uroczemu balonowi i koszykowi na jedzenie. Zaprojektowane tak, aby wywierać odpowiedni wpływ, opakowanie przekąsek Best Beginnings jest dostarczane z JP. Chenet Ice Edition, aby uczcić wszystkie wyjątkowe chwile! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "451.61",
        "inclTax": "451.61",
        "img": [
            "/images/products/Best-Beginnings-FNB1-570091.jpg?v=1708969544",
            "/images/products/Best-Beginnings2-416229.jpg?v=1708927848"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Złoty BonBon Nugat Migdałowy 130g",
                "2. Praliny Excelcium BonBons De Chocolat 180g",
                "3. Czekolada Asortyment Hamlet 125g",
                "4. Temptation - Domowe ciasteczka z kawałkami czekolady 250g",
                "5. Temptation - Ciasteczka Domowe Podwójna Czekolada 250g",
                "6.JP. Chenet Colombard Sauvignon 250ml",
                "7. Taylors Classic Chai 50g",
                "8. Spersonalizowany tekst na balonie z 6 mini balonami",
                "9. Wykwintny kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 829,
        "name": "Słodkie Uroki FNB",
        "handle": "sweet-charms-fnb",
        "description": "Sweet CharmsOsłodź dzień bliskiej Ci osoby balonem Sweet Charms i koszem na kwiaty. Ten wyjątkowy kosz, zapakowany w mini przezroczyste pudełko na ciasto, zawiera mini czekoladowe ciasto etoile oraz kwiaty zakonserwowanych róż, chryzantemy i plasterki pomarańczy. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "419.66",
        "inclTax": "419.66",
        "img": [
            "/images/products/Sweet-Charms1-939928.jpg?v=1671775914",
            "/images/products/Sweet-Charms2-766139.jpg?v=1671775914"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mini Ciasto Choc Etoile",
                "2. Zachowana róża",
                "3. Konserwowany plasterek pomarańczy",
                "4. Konserwowana chryzantema",
                "5. Zachowana Gerbera",
                "6. Mini nasiona Pittosporum",
                "7. Liście Pittosporum"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 830,
        "name": "Pełne wdzięku życzenia FNB",
        "handle": "graceful-wishes-fnb",
        "description": "Pełne wdzięku życzeniaWyślij pełne wdzięku kwiaty, dzięki którym dzień obdarowanej osoby rozpocznie się w wielkim stylu! Bukiet balonowy Graceful Wishes obejmuje mini tort z czarnego lasu oraz kompozycję z zakonserwowanych suszonych róż, chryzantem i plasterków pomarańczy. Ten balonowy prezent, zapakowany w mini przezroczyste pudełko na ciasto, sprawi, że ten wyjątkowy dzień będzie niezapomniany. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów urodzinowych"
        ],
        "price": "399.12",
        "inclTax": "399.12",
        "img": [
            "/images/products/Graceful-Wishes1-612352.jpg?v=1671685211",
            "/images/products/Graceful-Wishes2-602358.jpg?v=1671685211"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mini Tort Czarny Las",
                "2. Zachowana róża",
                "3. Konserwowany plasterek pomarańczy",
                "4. Konserwowana chryzantema",
                "5. Zachowana Gerbera",
                "6. Mini nasiona Pittosporum",
                "7. Liście Pittosporum"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 831,
        "name": "Lemony Teaparty FNB",
        "handle": "lemony-teaparty-fnb",
        "description": "Lemony Teaparty Lemony Teaparty to idealny kosz na przekąski, który dzięki żółtym balonikom podkreśla letnią atmosferę! Znajdź wystarczającą ilość przekąsek, które zapewnią Ci energię na cały dzień, w tym lemon curd, czekoladki, sok i nie tylko, a wszystko to w wykwintnej drewnianej skrzyni. Dodaliśmy nawet zapach Botanica Home do wyjątkowo relaksującej herbaty! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Balony i kwiaty gratulacyjne"
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Lemony-Teaparty-FNB1-258873.jpg?v=1709056634",
            "/images/products/Lemony-Teaparty2-234394.jpg?v=1709014152"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Miska na dobroć niesamowitego wypasu",
                "2. Świeca zapachowa Fleur Botanica Home",
                "3. Wilkina",
                "4. Marmolada Mrs. Bridges z pomarańczy i mandarynek",
                "5. Czekolada z asortymentu Hamlet",
                "6. Bawełna z suszonymi kwiatami",
                "7. Naturalny Sok Świetlika Cytryna, Limonka 330ml",
                "8. Biały dziki kwiat"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 832,
        "name": "Ślub VII",
        "handle": "bridal-vii",
        "description": "Do tej pięknej aranżacji ślubnej dołączony będzie wazon =)",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na wesele",
            "Kwiaty i bukiety na zaręczyny"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Bridal-_VII_1A-711104.jpg?v=1671685194",
            "/images/products/Bridal-_VII_2A-563933.jpg?v=1671685194"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany."
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 833,
        "name": "Wesoły Wesołek",
        "handle": "jolly-merrymaker",
        "description": "Jolly Merrymaker Wesoły i jasny kosz na prezenty świąteczne Jolly Merrymaker jest gotowy do szerzenia świątecznej radości! Wypełnione po brzegi pikantnymi i słodkimi smakołykami, dodaliśmy także gorące kakao do tych pysznych słodyczy! Zachwyć czekoladoholika już dziś tym świątecznym prezentem świątecznym!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "209.72",
        "inclTax": "209.72",
        "img": [
            "/images/products/Jolly-Merrymaker1-601186.jpg?v=1671685466",
            "/images/products/Jolly-Merrymaker2-576824.jpg?v=1671685466"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gorące Kakao Swiss Miss Dark Chocolate Sensation 283g",
                "2. Cupido Royal Mints 200g",
                "3. Beryl’s Tiramisu Migdałowa Czekolada Mleczna 65g",
                "4. Tradycyjne ciasteczka maślane Danisa 200g",
                "5. Quickbury Mini Brownies Czekolada i Orzech Laskowy 125g",
                "6. Chipsy z manioku Max’s Farm 100g",
                "7. Jules Destrooper Belgian Choco Virtuoso 100g",
                "8. Royal Select Musujący Sok Winogronowy 750ml",
                "9. Wykwintne pudełko ze skrzynią"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 834,
        "name": "Sugarplum Babybum FNB",
        "handle": "sugarplum-babybum-fnb",
        "description": "Sugarplum Babybum Pudełko upominkowe dla dziewczynki Sugarplum Babybum to idealny prezent, aby powitać świat i pogratulować wyjątkowego przybycia na świat. Ten różowy koszyk dla noworodka, wypełniony niezbędnymi akcesoriami, zawiera 3-częściowy zestaw upominkowy Kayen Kayes™ ze 100% bawełny, gryzaki i silikonowe łyżki, które nowi rodzice będą zachwyceni! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Sugarplum-Babybum1.jpg?v=1700445247",
            "/images/products/Sugarplum-Babybum2.jpg?v=1700445247"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Kayen",
                "2. Bambusowa łyżka silikonowa BabyK™ (zestaw 3 szt.)",
                "3. Silikonowa zabawka-żyrafa BabyK™",
                "4. Silikonowy gryzak dinozaur BabyK™",
                "5. Drewniana skrzynia",
                "6. Spersonalizowany tekst na balonie z 4 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 835,
        "name": "Mały chłopiec Niebieski FNB",
        "handle": "little-boy-blue-fnb",
        "description": "Little Boy Blue Pudełko upominkowe dla chłopca Little Boy Blue to idealny prezent, aby powitać na świecie i pogratulować wyjątkowego przybycia na świat. Ten niebieski koszyk dla noworodka, pełen niezbędnych rzeczy, zawiera 4-częściowy zestaw upominkowy BabyK ze 100% bawełny, gryzaki i silikonowe łyżki, które nowi rodzice będą zachwyceni! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Little-Boy-Blue-FNB1.jpg?v=1709097860",
            "/images/products/Little-Boy-Blue-FNB2-603650.jpg?v=1709143576"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy BabyK™, 100% bawełny, 4-częściowy (niebieski) (czapka/rękawiczka/romper/skarpetka)",
                "2. Bambusowa łyżka silikonowa BabyK™ (zestaw 3 szt.)",
                "3. Silikonowa zabawka-żyrafa BabyK™",
                "4. Silikonowy gryzak dinozaur BabyK™",
                "5. Drewniana skrzynia",
                "6. Spersonalizowany tekst na balonie z 4 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 836,
        "name": "Oddech dziecka FNB",
        "handle": "babys-breath-fnb",
        "description": "Baby's Breath Pudełko upominkowe dla niemowląt Baby's Breath to idealny prezent, aby powitać na świecie i pogratulować wyjątkowego przybycia. Wypełniony niezbędnymi artykułami koszyk dla noworodka dla chłopca zawiera 4-częściowy zestaw upominkowy BabyK ze 100% bawełny, gryzaki i silikonowe łyżki, które nowi rodzice będą zachwyceni! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Baby_s-Breath-FNB1-258072.jpg?v=1709142969",
            "/images/products/Baby_s-Breath2-784447.jpg?v=1709097054"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy BabyK™, 100% bawełny, 4-częściowy (żółty) (czapka/rękawiczka/romper/skarpetka)",
                "2. Bambusowa łyżka silikonowa BabyK™ (zestaw 3 szt.)",
                "3. Silikonowa zabawka-żyrafa BabyK™",
                "4. Silikonowy gryzak dinozaur BabyK™",
                "5. Drewniana skrzynia",
                "6. Spersonalizowany tekst na balonie z 4 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 837,
        "name": "Róż do policzków dla niemowląt FNB",
        "handle": "babys-blush-fnb",
        "description": "Koszyczek dla dziewczynki Baby’s Blush jest wypełniony uroczymi artykułami niezbędnymi dla noworodka, których otrzymanie z pewnością będzie przyjemnością. Zapakowany w wykwintny rattanowy kosz, zawiera organiczną poduszkę do modelowania głowy, myjki, gryzaki i masażery do skóry głowy firmy BabyK, dzięki czemu jest to zestaw niezbędny dla młodych rodziców! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "303.28",
        "inclTax": "303.28",
        "img": [
            "/images/products/Baby_s-Blush1-566484.jpg?v=1671684830",
            "/images/products/Baby_s-Blush2-439271.jpg?v=1671684830"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Organiczna poduszka kształtująca głowę BabyK™",
                "2. Bambusowa łyżka silikonowa BabyK™ (zestaw 3 szt.)",
                "3. BabyK™ 6 szt. Ściereczki Bamboo Wash",
                "4. Silikonowy gryzak BabyK™",
                "5. Masażer do kąpieli dla dzieci BabyK™ do skóry głowy",
                "6. Pieluszki bambusowe BabyK™",
                "7. Ręcznie wykonany kosz wiklinowy",
                "8. Spersonalizowany tekst na balonie z 6 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 838,
        "name": "Dziecięcy Starshine FNB",
        "handle": "babys-starshine-fnb",
        "description": "Baby's Starshine Podaruj nowonarodzonemu chłopcu wspaniałe prezenty z koszyka na balony Baby’s Starshine! W zestawie znajduje się 4-częściowy zestaw upominkowy BabyK wykonany w 100% z bawełny, masażer skóry głowy, gryzak i album na pamiątki, które mają pomóc młodym rodzicom w zarejestrowaniu ich nowej, ekscytującej podróży w rodzicielstwo!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "314.69",
        "inclTax": "314.69",
        "img": [
            "/images/products/Baby_s-Starshine-FNB1.jpg?v=1709097248",
            "/images/products/Baby_s-Starshine-FNB2-379970.jpg?v=1709142969"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Organiczna poduszka kształtująca głowę BabyK™",
                "2. Zestaw upominkowy BabyK™, 100% bawełny, 4-częściowy (niebieski) (czapka/rękawiczka/pajacyk/skarpetka)",
                "3. Masażer skóry głowy BabyK™ do kąpieli dla niemowląt",
                "4. Silikonowy gryzak dinozaur BabyK™",
                "5. Miniksiążeczka z pamiątkami BabyK™",
                "6. Koc polarowy",
                "7. Spersonalizowany tekst na balonie",
                "8. Ręcznie wykonany kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 839,
        "name": "Śmiech dziecka FNB",
        "handle": "babys-chuckle-fnb",
        "description": "Baby's ChuckleNeutralny pod względem płci koszyczek dla niemowląt Baby's Chuckle jest wypełniony uroczymi artykułami niezbędnymi dla noworodka, których otrzymanie z pewnością będzie przyjemnością. Zapakowany w wykwintny rattanowy kosz, zawiera organiczną poduszkę do modelowania głowy, myjki, gryzaki i masażery do skóry głowy firmy BabyK, dzięki czemu jest to zestaw niezbędny dla młodych rodziców! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "303.28",
        "inclTax": "303.28",
        "img": [
            "/images/products/Baby_s-Chuckle1-106219.jpg?v=1671684898",
            "/images/products/Baby_s-Chuckle2-183475.jpg?v=1671684898"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Organiczna poduszka kształtująca głowę BabyK™",
                "2. Bambusowa łyżka silikonowa BabyK™ (zestaw 3 szt.)",
                "3. BabyK™ 6 szt. Ściereczki Bamboo Wash",
                "4. Silikonowa zabawka-żyrafa BabyK™",
                "5. Masażer do kąpieli dla dzieci BabyK™ do skóry głowy",
                "6. Pieluszki bambusowe BabyK™",
                "7. Ręcznie wykonany kosz wiklinowy",
                "8. Spersonalizowany tekst na balonie z 6 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 840,
        "name": "Konstancja",
        "handle": "constance",
        "description": "„Każdy może pokochać jakąś rzecz, ponieważ. To tak proste, jak włożenie grosza do kieszeni. Ale kochać coś pomimo. Znać wady i kochać je. To rzadkie, czyste i doskonałe”. - Patrick Rothfuss",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Constance1A_5d4eafa4-565d-4ac3-8ebe-c48dedda425c-881966.jpg?v=1671685077",
            "/images/products/Constance2A-631200.jpg?v=1671685077"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże Christy i księżniczki Hitomi. Róże Sancerre w sprayu. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 841,
        "name": "Violetta",
        "handle": "violeta",
        "description": "„Miłość jest ze wszystkich namiętności najsilniejsza, gdyż atakuje jednocześnie głowę, serce i zmysły”. - Lao Tzu",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Violeta1B_93fe3060-f746-440e-8325-89e04b876c33-342327.jpg?v=1671775975",
            "/images/products/Violeta2B-801602.jpg?v=1671775975"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 842,
        "name": "Rachela",
        "handle": "rachel",
        "description": "„Miłość jest ze wszystkich namiętności najsilniejsza, gdyż atakuje jednocześnie głowę, serce i zmysły”. - Lao Tzu",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Rachel1D_74c16f23-953f-4295-bb6b-231565a5172b-740726.jpg?v=1702988905",
            "/images/products/Rachel2D-639331.jpg?v=1702988905"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Perfumy marki Grande Roses."
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 843,
        "name": "Witaj, książę FNB",
        "handle": "hello-prince-fnb",
        "description": "Witaj KsiążęWitaj Książę! Pudełko upominkowe dla niemowląt powita w wielkim stylu nowonarodzonego chłopca! Dodaliśmy artykuły do ​​zabawy, przekąski, a także czytania, dzięki czemu jest to jeden z najlepszych, wszechstronnych koszy dla niemowląt w Polsce!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "378.58",
        "inclTax": "378.58",
        "img": [
            "/images/products/Hello-Prince1-745594.jpg?v=1671685463",
            "/images/products/Hello-Prince2-960520.jpg?v=1671685463",
            "/images/products/Hello-Prince-FNB3.jpg?v=1709098426"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Koc polarowy",
                "2. Kubek niekapek BabyK™",
                "3. Kayen",
                "4. Kosz z organizerem na pieluchy Baby K",
                "5. Miniksiążeczka z pamiątkami BabyK™",
                "6. Buds-Krem dla niemowląt 75ml",
                "7. Minizestaw podróżniczy Gaia",
                "-Wanna",
                "-Szampon dla dzieci 50ml",
                "-Nawilżający krem ​​dla niemowląt 50ml"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 844,
        "name": "Bubbly Baby FNB",
        "handle": "bubbly-baby-fnb",
        "description": "Bubbly Baby Szczęśliwe dziecko to pełne życia dziecko. Powitaj ten nowy dodatek dzięki koszykowi dla noworodka Bubbly Baby. Wyposażony w przyssawkę, silikonowe łyżeczki i ekscytujący album na pamiątki, w którym można zapisać cenne chwile, ten balonowy kosz jest po prostu idealnym uzupełnieniem rozwoju dziecka. Zamieściliśmy także zdjęcia ptasich gniazd, aby nowi rodzice również mogli cieszyć się zdrowiem! Wszystko, czego potrzebuje ten balonowy kosz, to tylko spersonalizowana notatka, która idealnie go podkreśli!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Bubbly-Baby1-854156.jpg?v=1671684907",
            "/images/products/Bubbly-Baby2-771623.jpg?v=1671684907"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Deli Jaskółka Ptasie Gniazdo z Żeń-szeniem i Białym Grzybem",
                "2. Marek",
                "3. Bambusowa łyżka silikonowa BabyK™ (zestaw 3 szt.)",
                "4. Miniksiążeczka z pamiątkami BabyK™",
                "5. Gaia Baby – Balsam łagodzący skórę 100ml",
                "6. Naturaverde Bio Organiczny płyn do mycia ciała i włosów 200ml",
                "7. Buds – Butelka bezpieczna dla dziecka",
                "8. Buds -Krem dla niemowląt 75ml",
                "9. Spersonalizowany tekst na balonie",
                "10. Ręcznie wykonany kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 845,
        "name": "Peek-a-boo Baby FNB",
        "handle": "peek-a-boo-baby-fnb",
        "description": "Peek-a-boo BabyKoszyk dla noworodków Peek-a-boo Baby zawiera wszystko, czego potrzebują nowi rodzice, aby zapewnić dziecku wygodę i szczęście! Do zestawu dołączyliśmy wysokiej jakości ściereczki i ręczniki, a także gryzaki, które ukoją dziecko i sprawią, że będzie ono bezproblemowe! Dodaliśmy także króliczka-kumpela, aby mieć pewność, że dziecko będzie miało nowego najlepszego przyjaciela, do którego będzie mogło się przytulać.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "335.23",
        "inclTax": "335.23",
        "img": [
            "/images/products/Peek-a-boo-Baby1-475475.jpg?v=1671685634",
            "/images/products/Peek-a-boo-Baby2-125862.jpg?v=1671685634"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. BabyK™ 6 szt. Ściereczki Bamboo Wash",
                "2. Kayen",
                "3. Nowatorski bawełniany ręcznik z kapturem BabyK™",
                "4. Silikonowy gryzak dinozaur BabyK™",
                "5. Pluszowy króliczek",
                "6. Spersonalizowany tekst na balonie z 5 mini balonami",
                "7. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 846,
        "name": "Urocze dziecko FNB",
        "handle": "charming-baby-fnb",
        "description": "Charming BabyKoszulka dla noworodka dziewczynki Charming Baby zawiera wysokiej jakości zabawki, które zaprojektowano tak, aby służyły przez długi czas. Dołączyliśmy także album pamiątkowy, który pomoże nowym rodzicom rejestrować każdy kamień milowy w ich nowej, ekscytującej podróży. Zadbaliśmy również o to, aby nie zapomniano o nowych tatusiach i mamach, dlatego dodaliśmy do nich takie smakołyki jak ptasie gniazdo, sok gruszkowy i marmoladę, aby ich dzień był jeszcze słodszy. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Charming-Baby1-478124.jpg?v=1671685076",
            "/images/products/Charming-Baby2-926858.jpg?v=1671685076"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Deli Jaskółka Ptasie Gniazdo z Żeń-szeniem i Białym Grzybem",
                "2. Miękkie klocki",
                "3. Zabawka-wobbler-pingwin BabyK™",
                "4. Drewniany samochodzik BabyK™",
                "5. Miniksiążeczka z pamiątkami BabyK™",
                "6. Marmolada Mrs. Bridges z pomarańczy i mandarynek",
                "7. Gruszka Naturalna Gruzja 300ml",
                "8. Spersonalizowany tekst na balonie",
                "9. Ręcznie wykonany kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 847,
        "name": "Sherbert Baby FNB",
        "handle": "sherbert-baby-fnb",
        "description": "Sherbert BabyKoszyczek na balony dla noworodka Sherbert Baby zachwyci nawet najbardziej wybredne dziecko! Zawarliśmy w nim szczegółowo wyselekcjonowane produkty, które sprawią, że rozwój dziecka będzie zabawą i niezapomnianym przeżyciem. Załadowany do wykwintnego, ręcznie wykonanego pudełka na kapelusze, wszystko, czego potrzebuje ten piękny kosz na balony, to spersonalizowana wiadomość, którą możesz wysłać na naszym balonie!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "408.25",
        "inclTax": "408.25",
        "img": [
            "/images/products/Sherbert-Baby1-940953.jpg?v=1671775907",
            "/images/products/Sherbert-Baby2-185627.jpg?v=1671775907"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pieluszki bambusowe BabyK™",
                "2. Masażer do kąpieli dla dzieci BabyK™ do skóry głowy",
                "3. Kayen",
                "4. Kayen",
                "5. Buds -Krem dla niemowląt 75ml",
                "6. Buds - Płyn do mycia dla niemowląt od stóp do głów 225 ml",
                "7. Spersonalizowany tekst na balonie z 4 mini balonami",
                "8. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 848,
        "name": "Słoneczne Dziecko FNB",
        "handle": "sunny-baby-fnb",
        "description": "Sunny Baby Wyślij słońce i radość świeżo upieczonym rodzicom i ich ukochanemu noworodkowi za pomocą balonowego koszyka Sunny Baby. Wybraliśmy popularne produkty niezbędne podczas pierwszej kulinarnej przygody dziecka, a także przysmaki na podwieczorek dla młodych rodziców! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "408.25",
        "inclTax": "408.25",
        "img": [
            "/images/products/Sunny-Baby-FNB1-467852.jpg?v=1709144583",
            "/images/products/Sunny-Baby-FNB2-201399.jpg?v=1709144583"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Kayen",
                "2. Gryzak owocowy BabyK",
                "3. Termiczny słoiczek na żywność Baby K",
                "4. Buds - Butelka bezpieczna dla dziecka",
                "5. Marmolada Mrs. Bridges z pomarańczy i mandarynek",
                "6. Miniksiążeczka BabyK Keepsake",
                "7. Spersonalizowany tekst na balonie z 6 mini balonami",
                "8. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 849,
        "name": "Sweetpea Baby FNB",
        "handle": "sweetpea-baby-fnb",
        "description": "Sweetpea Baby Naprawdę nie ma nic słodszego niż gruchająca dziewczynka niż kosz na śmieci dla noworodka Sweetpea! Jest wyposażony w niezbędne akcesoria, w tym 4-częściowy zestaw upominkowy BabyK ze 100% bawełny, bezpieczny dla dziecka środek do czyszczenia naczyń, silikonowe łyżki i miskę z przyssawką, aby zapewnić zaspokojenie podstawowych potrzeb dziecka! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "431.07",
        "inclTax": "431.07",
        "img": [
            "/images/products/Sweetpea-Baby1A.jpg?v=1703646540",
            "/images/products/Sweetpea-Baby2A-961925.jpg?v=1703684230"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy BabyK™, 100% bawełny, 4-częściowy (różowy) (czapka/rękawiczka/pajacyk/skarpetka)",
                "2. Bambusowa łyżka silikonowa BabyK™ (zestaw 3 szt.)",
                "3. Marek",
                "4. Buds - Butelka bezpieczna dla dziecka",
                "5. Koc polarowy",
                "6. Silikonowy gryzak dinozaur BabyK™",
                "7. Miniksiążeczka z pamiątkami BabyK™",
                "8. Spersonalizowany tekst na balonie z 6 mini balonami",
                "9. Ręcznie wykonany kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 850,
        "name": "Kwitnące dziecko FNB",
        "handle": "blossoming-baby-fnb",
        "description": "Blossoming BabyNajsłodsze dziecko zasługuje na najsłodszy balonik! Kosz prezentowy dla noworodków Blossoming Baby zawiera ulubione produkty do kąpieli, a także bambusową rożek BabyK, aby zapewnić dziecku czystość, świeżość, wygodę i bezproblemowość! Już dziś spraw, aby to pudełko z kwiatami z balonów zostało zapamiętane dzięki spersonalizowanej wiadomości z balonu!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "431.07",
        "inclTax": "431.07",
        "img": [
            "/images/products/Blossoming-Baby1-744218.jpg?v=1671684899",
            "/images/products/Blossoming-Baby2-673743.jpg?v=1671684899"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pieluszki bambusowe BabyK™",
                "2. Masażer do kąpieli dla dzieci BabyK™ do skóry głowy",
                "3. Gaia Baby – Balsam łagodzący skórę 100ml",
                "4. Naturaverde Bio Organiczny płyn do mycia ciała i włosów 200ml",
                "5. Munchkins - Squirtin Barnard Friends - 8 sztuk zestawów zabawek do kąpieli",
                "6. Spersonalizowany tekst na balonie z 4 mini balonami",
                "7. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 851,
        "name": "Baby's Sweetnote FNB",
        "handle": "babys-sweetnote-fnb",
        "description": "Baby's SweetnoteKosz dla dziewczynki Baby's Sweetnote jest wypełniony niezbędnymi dla noworodka artykułami, których otrzymanie z pewnością będzie przyjemnością. Zapakowany w wykwintny rattanowy kosz, zawiera organiczną poduszkę do kształtowania głowy, ręczniki, zabawki i gryzaki od BabyK oraz szampana Moet i Chandron dla mam i tatusiów!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Baby_s-Sweetnote1-373406.jpg?v=1671684832",
            "/images/products/Baby_s-Sweetnote2-406462.jpg?v=1671684833"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Organiczna poduszka kształtująca głowę BabyK™",
                "2. Nowatorski bawełniany ręcznik z kapturem BabyK™",
                "3. Bambusowa łyżka silikonowa BabyK™ (zestaw 3 szt.)",
                "4. Silikonowy gryzak dinozaur BabyK™",
                "5. Drewniany samochodzik BabyK™",
                "6. Moet i Chandon 200ml",
                "7. Breitsamer Honig Surowy Miód 500g",
                "8. Ręcznie wykonany kosz wiklinowy",
                "9. Spersonalizowany tekst na balonie z 6 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 852,
        "name": "Dziarskie dziecko FNB",
        "handle": "perky-baby-fnb",
        "description": "Perky BabyBabies lubią być rozpieszczane, więc podaruj im wygodę i przyjemność dzięki koszykowi na balony dla noworodków Perky Baby! Do zestawu dołączyliśmy akcesoria do kąpieli i pielęgnacji, a także zabawki pomagające dziecku w opanowaniu kluczowych etapów rozwoju! Spersonalizuj ten balonowy kosz z przesłaniem prosto z serca!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "545.17",
        "inclTax": "545.17",
        "img": [
            "/images/products/Perky-Baby-FNB1.jpg?v=1709095879",
            "/images/products/Perky-Baby2-488115.jpg?v=1709095879"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pieluszki bambusowe BabyK™",
                "2. Kayen",
                "3. Silikonowa zabawka-żyrafa BabyK™",
                "4. Gaia Baby – Balsam łagodzący skórę 100ml",
                "5. Green People- Organics Babies Balsam dla niemowląt-Lawenda 150ml",
                "6. Gaia Baby – Naturalny puder dla niemowląt 100g",
                "7. Natura Cloversoft",
                "8. Spersonalizowany tekst na balonie z 4 mini balonami",
                "9. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 853,
        "name": "Wiciokrzew Mały FNB",
        "handle": "honeysuckle-baby-fnb",
        "description": "Honeysuckle BabyRozpieszczaj rodziców i dziecko dzięki koszykowi na balony dla noworodków Wiciokrzew! W naszej ofercie znajdziesz ulubione produkty do kąpieli i tradycyjne toniki, aby zapewnić opiekę zarówno dziecku, jak i rodzicom! Dodaj spersonalizowaną wiadomość na balonie, aby otrzymać prezent, który naprawdę gratuluje!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "574.84",
        "inclTax": "574.84",
        "img": [
            "/images/products/Honeysuckle-Baby1-899605.jpg?v=1671685351",
            "/images/products/Honeysuckle-Baby2-276438.jpg?v=1671685351"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Deli Jaskółka Ptasie Gniazdo z Żeń-szeniem i Białym Grzybem",
                "2. Botanica Okrągły Żel Lekki 120g",
                "3. Naturaverde Bio Organiczny płyn do mycia ciała i włosów 200ml",
                "4. Gaia Baby – Balsam łagodzący skórę 100ml",
                "5. Gaia Baby – Naturalny puder dla niemowląt 100g",
                "6. Książka pielęgnacji dziecka BabyK™",
                "7. Koc polarowy",
                "8. Marmolada Mrs. Bridges z pomarańczy i mandarynek",
                "9. Spersonalizowany tekst na balonie z 4 mini balonami",
                "10. Wykwintne, ręcznie wykonane pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 50cm"
    },
    {
        "id": 854,
        "name": "Zdrowy uśmiech WHP",
        "handle": "healthy-smiles-whp",
        "description": "Zdrowy uśmiech Nic nie zachęca lepiej do szybkiego powrotu do zdrowia niż podarowanie komuś zdrowia. Naładowany zdrowymi tonikami, które dodadzą im zastrzyku miłości i energii, kosz Healthy Smiles Get Well Soon prześle najszczersze życzenia najważniejszym dla nas osobom.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Healthy-Smiles1_0b3b086a-b2db-4feb-b607-7518bf0f4f61-779118.jpg?v=1671685463",
            "/images/products/Healthy-Smiles2-262993.jpg?v=1671685463"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy Ptasie Gniazdo Kinohimitsu 8 x 75g",
                "2. Esencja Kurczaka z Księżyca w Nowiu 4 x 70g",
                "3. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 855,
        "name": "Zdrowo pyszne WHP",
        "handle": "healthily-yummy-whp",
        "description": "Zdrowo Pyszne Misternie zapakowany koszyk wellness, który będzie atrakcyjny dla wzroku odbiorcy, a także zapewni pożywienie sercu jednymi z najlepszych napojów zdrowotnych, jakie kiedykolwiek znajdziesz i które uwielbiasz smakować.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "243.95",
        "inclTax": "243.95",
        "img": [
            "/images/products/Healthily-Yummy1_f4e1df77-3072-4a8d-a4da-117b9b932d59-789792.jpg?v=1671685414",
            "/images/products/Healthily-Yummy2-183172.jpg?v=1671685414"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie Gniazdo z Żeń-szeniem",
                "2. Markowa Esencja Kurczaka 3 x 41ml",
                "3. Płatki kukurydziane Kellogg’s 275g",
                "4. FarmHouse UTH Świeże Mleko 1L",
                "5. Herbata mleczna Old Katong Premium - bez cukru 250g",
                "6. The Muesli Company Płatki owsiane błyskawiczne o niskiej zawartości cukru 500g",
                "7. Ciasteczka pełnoziarniste Colada 144g",
                "8. Shoon Fatt Kanapka z masłem orzechowym 175g",
                "9. Pakowanie różnych kwiatów",
                "10. Wykwintne pudełko upominkowe (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 856,
        "name": "Zdrowy Odpust WHP",
        "handle": "healthy-indulgence-whp",
        "description": "Healthy Indulgence Misternie zapakowany koszyk wellness, który będzie atrakcyjny dla wzroku odbiorcy, a także zapewni pożywienie sercu jednymi z najlepszych napojów zdrowotnych, jakie kiedykolwiek znajdziesz i które uwielbiasz smakować.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Healthy-Indulgence-WHP2-273073.jpg?v=1708536717",
            "/images/products/Healthy-Indulgence-WHP1-995762.jpg?v=1708536717"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Esencja z jagód Fomecs 2 x 42ml",
                "2. Ciasteczka owsiane Bakery Havana z Chocochipem 120g",
                "3. Amazin Graze Chocolate Banana Bowl Posiłek na bazie owsa 240g",
                "4. Smakuj Asia Gotową do spożycia Owsiankę z kurczakiem 400g",
                "5. Cowhead Original Plain Cracker 180g (8 saszetek)",
                "6. Yogood Batoniki musli w jogurtowej panierce 138g",
                "7. Harvest Box Super Mix Orzechów 45g",
                "8. Opakowania na różne kwiaty",
                "9. Wykwintna drewniana skrzynia (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 857,
        "name": "Podniebienie natury z kwiatem OHP",
        "handle": "nature-palate-with-flower-ohp",
        "description": "Podniebienie natury z kwiatem",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze organiczne"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Nature-Palate-with-Flower1-193797.jpg?v=1671685496",
            "/images/products/Nature-Palate-with-Flower2-450486.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Germinal BIO Organiczne Płatki Gryczane 200g",
                "2. Pacific – Organiczny napój sojowy bez nabiału 946ml",
                "3. English Tea Shop – Ekologiczna herbata rumiankowo-lawendowa Premium",
                "4. ORGRAN Classic Choc Biscotti Bezglutenowe 150g",
                "5. Amazin’ Graze Przeciwutleniająca mieszanka jagód i orzechów 130g",
                "6. Brothers All Natural Fuji Jabłkowe chipsy owocowe 10g",
                "7. Opakowania na różne kwiaty",
                "8. Wykwintne pudełko upominkowe (S)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 858,
        "name": "Rozbłyśnij i uśmiechnij się dzięki Flower WHP",
        "handle": "glow-and-smile-with-flower-ohp",
        "description": "Świeć i uśmiechaj się z kwiatami Słoneczna strona życia jest jaśniejszą stroną życia i możesz pomóc ukochanej osobie odkryć ją na nowo dzięki temu wyjątkowemu koszowi upominkowemu wypełnionemu niezbędnymi zdrowotnymi tonikami i białymi grzybami oraz ozdobionym delikatnymi kwiatami.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "506.38",
        "inclTax": "506.38",
        "img": [
            "/images/products/Glow-and-Smile-with-Flower1-236239.jpg?v=1671685312",
            "/images/products/Glow-and-Smile-with-Flower2-367769.jpg?v=1671685312"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Tonik zdrowotny Yomeishu 70cl",
                "2. Ptasie Gniazdo Księżyca w Nowiu z cukrem kamiennym 2 x 70ml",
                "3. Esencja Kurczaka z Księżyca w Nowiu 3 x 70ml",
                "4. Deli Jaskółka Ptasie Gniazdo Biały Grzyb 3 x 70g",
                "5. Opakowania na różne kwiaty",
                "6. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 859,
        "name": "Świąteczne pozdrowienia",
        "handle": "holiday-cheers",
        "description": "Holiday CheersŚwiąteczny prezent kulinarny Holiday Cheers to taki, który przywołuje radosne i cenne chwile, które towarzyszą tej wyjątkowej porze roku! Ekscytujące przekąski, słodkie i słone, są zapakowane w ten świąteczny kosz na świąteczne jedzenie, który jest ułożony w rustykalnej skrzynce. Ciesz się bezproblemowym dawaniem prezentów dzięki naszym dedykowanym usługom!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "289.59",
        "inclTax": "289.59",
        "img": [
            "/images/products/Holiday-Cheers1-931219.jpg?v=1671685324",
            "/images/products/Holiday-Cheers2-717685.jpg?v=1671685324"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Prestigium Cuvee Speciale Rouge 75cl",
                "2. Duncan’s of Deeside Kruche Ciasto Kokosowe Ręcznie Pieczone 150g",
                "3. Aalst Puszka czekolady mlecznej z całych orzechów laskowych 150g",
                "4. Czekoladowe ciasteczka St Michel 210g",
                "5. Grubi piechurzy",
                "6. Godivine Nutty Mix Orzechów 80g",
                "7. Hamlet B",
                "8. Ręcznie gotowane chipsy ziemniaczane Hunter z czarną truflą, 40 g, puszka",
                "9. Wykwintne pudełko ze skrzynią"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 860,
        "name": "Esencja Zdrowia HHP",
        "handle": "essence-of-health-whp",
        "description": "Essence of Health Prześlij swoje życzenia za pomocą koszyka Essence Of Health Wellness! Pięknie zapakowany, zawierający wspaniałe kwiaty, wysokiej jakości esencję z kurczaka i esencję jagodową, ten prezent z pewnością wywoła uśmiech na ich twarzy.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Essence-of-Health-WHP1-443416.jpg?v=1708536445",
            "/images/products/Essence-of-Health-WHP2-395445.jpg?v=1708536445"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Marki Esencja Kurczaka 6 x 41ml",
                "2. Marki Berry Essence 3 x 41ml",
                "3. Kompozycja z różnych kwiatów",
                "4. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 861,
        "name": "Plentiful Box w cenie CNY",
        "handle": "plentiful-box-财运亨通-cny",
        "description": "Plentiful Box 财运亨通 Dobry rok zaczyna się od dobrej uczty. Nasz zestaw upominkowy na Chiński Nowy Rok w pudełku obfity zawiera tradycyjne, świąteczne przysmaki, takie jak kuszenie Buddy, małże brzytwy i zupę z przegrzebków rybnych, co stanowi wspaniałą ucztę, która rozpieszcza rodzinę i przyjaciół!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Plentiful-Box-_1-433458.jpg?v=1671685637",
            "/images/products/Plentiful-Box-_2-663610.jpg?v=1671685637",
            "/images/products/Plentiful-Box-_3_92277758-ea4e-4251-9621-23e7d0358845-118506.jpg?v=1671685637"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Budda Yu Xiang Yan Abalone skacze przez ścianę 425g",
                "2. Małże Bao Jin Tian Premium Razor Małże 425g",
                "3. Yu Xiang Yan Premium Zupa Z Przegrzebków Rybnych 425g",
                "4. Wykwintne, pikowane pudełko upominkowe CNY ze sztucznej skóry",
                "宇鄕燕鲍鱼佛跳墙 425g",
                "鲍今天特级鲍贝 425g",
                "宇鄉燕特级带子花胶汤 425g",
                "精美仿皮新年礼盒"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 862,
        "name": "Kwitnące pudełko 心想事成 CNY",
        "handle": "flourishing-box-心想事成-cny",
        "description": "Flourishing Box 心想事成Rozpocznij ten rok dobrobytu dobrą ucztą. Nasz zestaw upominkowy na Chiński Nowy Rok Flourishing Box zawiera tradycyjne, świąteczne przysmaki, takie jak duszone japońskie uchowce, małże brzytwy i Pokusa Buddy, co stanowi rozkoszną ucztę, która rozpieszcza rodzinę i przyjaciół!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Flourishing-Box-_1-811411.jpg?v=1671685207",
            "/images/products/Flourishing-Box-_2-586688.jpg?v=1671685207",
            "/images/products/FlourishingBox_3-783946.jpg?v=1671685207"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Budda Yu Xiang Yan Abalone skacze przez ścianę 425g",
                "2. Kinohimitsu Imperial Duszone Uchowce Japońskie 425g",
                "3. Małże Bao Jin Tian Premium Razor Małże 425g",
                "4. Zupa Yu Xiang Yan Premium z przegrzebkami rybnymi 425g",
                "5. Wykwintne, pikowane pudełko upominkowe CNY ze sztucznej skóry",
                "宇鄉燕鲍鱼佛跳墙 425g",
                "Kinohimitsu 御皇红烧日本鲍鱼 425g",
                "鲍今天特级鲍贝 425g",
                "宇鄉燕特级带子花胶汤 425g",
                "精美仿皮新年礼盒"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 863,
        "name": "Bridget BBS",
        "handle": "luca-1",
        "description": "„A teraz pozostają te trzy: wiara, nadzieja i miłość. Ale z nich największa jest miłość.” -  Anonimowy",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Luca1_6b0df1bc-83dc-464f-b656-e1d2d97547c0-854378.jpg?v=1711515702",
            "/images/products/Luca2-233586.jpg?v=1711515702"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 864,
        "name": "Wesoły",
        "handle": "jolly-1",
        "description": "„Dary czasu i miłości to z pewnością podstawowe składniki naprawdę wesołych Świąt”. – Peg Bracken",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jolly1B-674358.jpg?v=1671685464",
            "/images/products/Jolly2B.jpg?v=1670986632"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Szyszka sosnowa. Liść Sosny."
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 865,
        "name": "Brzęk",
        "handle": "jingle",
        "description": "„Bo dając, otrzymujemy”. – Franciszek z Asyżu",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Jingle1B_817dedda-dd21-4539-b25c-13e063622d93-951216.jpg?v=1671685464",
            "/images/products/Jingle2B_ee8e7873-1f20-4c65-8660-f976c7a371f4-656542.jpg?v=1671685464"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Szyszka sosnowa. Liść Sosny."
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 866,
        "name": "Anioł",
        "handle": "angel",
        "description": "„Boże Narodzenie to czas rozpalania ognia gościnności”. – Waszyngtona Irvinga",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Angel1C_16607d45-f631-447b-a1d1-c52623aca648-613998.jpg?v=1671684750",
            "/images/products/Angel2C-645314.jpg?v=1671684750"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Szyszka sosnowa. Liść Sosny."
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 867,
        "name": "Komin",
        "handle": "c",
        "description": "„Boże Narodzenie daje nam możliwość zatrzymania się i zastanowienia się nad ważnymi rzeczami wokół nas”. – David Cameron",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Chimney1.jpg?v=1671071317",
            "/images/products/Chimney2_a0a263ea-19a2-43b0-8766-e407781002f0.jpg?v=1671071317"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Szyszka sosnowa. Liść Sosny."
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 868,
        "name": "Kringle",
        "handle": "kringle",
        "description": "„Boże Narodzenie nie polega tak bardzo na otwarciu prezentów, jak na otwarciu naszych serc”. – Janice Maeditere",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kringle1A_d10884b7-20b5-4a83-8176-912fc77ea622.jpg?v=1671071005",
            "/images/products/Kringle2A.jpg?v=1671071157"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gossypium. Liść Sosny."
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 869,
        "name": "Życzę powodzenia 四季平安",
        "handle": "prosperous-cheers-四季平安",
        "description": "Życzę im dobrego samopoczucia i szczęścia w chińskim Nowym Roku 2023 dzięki naszemu koszowi na chiński Nowy Rok „Dostatnie szczęście”! Dodaliśmy najwyższej jakości świąteczne przysmaki, takie jak Kuszenie Buddy, uchowiec i ptasie gniazdo, i uzupełniliśmy je wyśmienitym czerwonym winem, aby uroczystość trwała dalej!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "417.38",
        "inclTax": "417.38",
        "img": [
            "/images/products/Prosperous-Cheers-_1_c6e189cb-fa3c-4590-adae-7989fb2ad295-109489.jpg?v=1671685638",
            "/images/products/Prosperous-Cheers-_2-179575.jpg?v=1671685638"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Zamek Fleurs Vignobles 75cl",
                "2. Kinohimitsu Imperial Duszone Uchowce Japońskie 425g",
                "3. Yu Xiang Yan Premium Zupa Z Przegrzebków Rybnych 425g",
                "4. Pojedynczy uchowiec Bao Jin Tian w butelce po 3 sztuki w pudełku prezentowym",
                "5. Daktyle Czerwone Premium 100g",
                "6. Grzyb Premium Biały 100g",
                "7. Grzyby Herbaciane Superior 100g",
                "8. Kurczak Kinohimitsu z esencją żeń-szenia amerykańskiego",
                "9. Wykwintny kosz upominkowy w CNY",
                "红酒",
                "Kinohimitsu 御皇红烧日本鲍鱼 425g",
                "宇鄉燕特级带子花胶汤 425g",
                "鲍今天单只鲍鱼瓶礼盒 x 3",
                "100 g",
                "100 g",
                "100 g",
                "Kinohimitsu 花旗参虫草鸡精 75ml x 3",
                "精美贺年礼篮"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 870,
        "name": "Dostatnie szczęście 百花齐放",
        "handle": "prosperous-happiness-百花齐放",
        "description": "Dostatnie szczęście 百花齐放Życz im dobrego samopoczucia i szczęścia w Chiński Nowy Rok 2023 dzięki naszemu koszykowi na Chiński Nowy Rok Prosperous Happiness! Dodaliśmy najwyższej jakości świąteczne przysmaki, takie jak Kuszenie Buddy, uchowiec i ptasie gniazdo, i uzupełniliśmy je wyśmienitym czerwonym winem, aby uroczystość trwała dalej!",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "570.27",
        "inclTax": "570.27",
        "img": [
            "/images/products/Prosperous-Happiness-_1-661827.jpg?v=1671685637",
            "/images/products/Prosperous-Happiness-_2-749995.jpg?v=1671685638"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Grand Jacques Cabernet Sauvignon Francja Wino czerwone 75cl",
                "2. Bao Jin Tian Australijskie Uchowce Duszone 425g",
                "3. Budda Yu Xiang Yan Abalone skacze przez ścianę 425g",
                "4. Pojedynczy uchowiec Bao Jin Tian w butelce po 3 sztuki w pudełku prezentowym",
                "5. Ptasie gniazdo Yu Xiang Yan Premium 80 ml po 3 sztuki w pudełku prezentowym",
                "6. Grzyby Herbaciane Superior 100g",
                "7. Przegrzebki suszone Superior 30g",
                "8. Daktyle Czerwone Premium 100g",
                "9. Kinohimitsu Kurczak Esencji Lingzhi 75ml x 3",
                "10. Obdarowany fortuną premium",
                "11. Wykwintny kosz upominkowy w CNY",
                "红酒",
                "鲍今天澳洲红烧鲍鱼 425g",
                "宇鄕燕鲍鱼佛跳墙 425g",
                "鲍今天单只鲍鱼瓶礼盒 x 3",
                "宇鄕燕特级燕窝礼盒 80ml x 3",
                "100 g",
                "Opakowanie 30g",
                "100 g",
                "Kinohimitsu灵芝鸡精 75ml x 3",
                "附送琉云漓彩琉璃艺品",
                "精美贺年礼篮"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 871,
        "name": "Pomyślna zabawa 辉煌四射",
        "handle": "prosperous-merriment-辉煌四射",
        "description": "Wesołych Świąt 辉煌四射Życz im dobrego samopoczucia i szczęścia w Chiński Nowy Rok 2023 dzięki naszemu koszykowi na Chiński Nowy Rok Dostatniej Wesołej! Dodaliśmy najwyższej jakości świąteczne przysmaki, takie jak Kuszenie Buddy, uchowiec i ptasie gniazdo, i uzupełniliśmy je wyśmienitym czerwonym winem, aby uroczystość trwała dalej.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "759.68",
        "inclTax": "759.68",
        "img": [
            "/images/products/Prosperous-Merriment-_1-692452.jpg?v=1702988905",
            "/images/products/Prosperous-Merriment-_2-745799.jpg?v=1702988905"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Zamek Fleurs Vignobles 75cl",
                "2. Grand Jacques Cabernet Sauvignon Francja Wino czerwone 75cl",
                "3. Yu Xiang Yan Australia Uchowiec w zalewie 425g",
                "4. Zupa Yu Xiang Yan Premium z przegrzebkami rybnymi 425g",
                "5. Budda Yu Xiang Yan Abalone skacze przez ścianę 425g",
                "6. Małże Bao Jin Tian Premium Razor Małże 425g",
                "7. Ptasie Gniazdo Yu Xiang Yan Premium 80ml x 4",
                "8. Kurczak Kinohimitsu z esencją żeń-szenia amerykańskiego",
                "9. Grzyb Premium Biały 100g",
                "10. Grzyby Herbaciane Superior 100g",
                "11. Superior Fish Maw 117g",
                "12. Seria Yu Xiang Yan White Fungus Bird’s Nest 3s’ 75ml",
                "13. Obdarowany fortuną premium",
                "14. Wykwintny kosz upominkowy w CNY",
                "红酒",
                "红酒",
                "宇鄉燕澳洲卤水鲍鱼 425g",
                "宇鄉燕特级带子花胶汤 425g",
                "宇鄕燕鲍鱼佛跳墙 425g",
                "鲍今天特级鲍贝 425g",
                "宇鄕燕特级燕窝 80ml x 4",
                "Kinohimitsu 花旗参虫草鸡精礼盒 75ml x 3",
                "100 g",
                "100 g",
                "上等鱼鳔 117g",
                "宇鄕燕银耳燕窝系列 75ml x 3",
                "附送琉云漓彩琉璃艺品",
                "精美贺年礼篮"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 872,
        "name": "Odpust pomyślności 万紫千红",
        "handle": "prosperous-indulgence-万紫千红",
        "description": "Życzymy im dobrego samopoczucia i szczęścia w chińskim Nowym Roku 2023 dzięki naszemu koszowi na chiński Nowy Rok Prosperous Indulgence! Dodaliśmy najwyższej jakości świąteczne przysmaki, takie jak kuszenie Buddy, uchowiec i ptasie gniazdo, i uzupełniliśmy je świątecznym 12-letnim Chivas, aby uroczystość trwała dalej.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "971.90",
        "inclTax": "971.90",
        "img": [
            "/images/products/Prosperous-Indulgence-_1-942047.jpg?v=1671685638",
            "/images/products/Prosperous-Indulgence-_2-529195.jpg?v=1671685638"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Chivas Regal 12 lat 70 kl",
                "2. Yu Xiang Yan Australia Uchowiec w zalewie 425g",
                "3. Kinohimitsu Imperial Duszone Uchowce Japońskie 425g",
                "4. Kurczak Kinohimitsu z esencją żeń-szenia amerykańskiego",
                "5. Ptasie Gniazdo Yu Xiang Yan Premium 80ml x 3",
                "6. Seria Yu Xiang Yan White Fungus Bird’s Nest 3s’ 75ml",
                "7. Budda Yu Xiang Yan Abalone skacze przez ścianę 425g",
                "8. Ptasie gniazdo Yu Xiang Yan Superior w pojedynczym pojemniku 165 ml",
                "9. Superior Fish Maw 117g",
                "10. Grzyby Herbaciane Superior 100g",
                "11. Kawałek uchowca Superior 100g",
                "12. Daktyle Czerwone Premium 100g",
                "13. Obdarowany fortuną premium",
                "14. Wykwintny kosz upominkowy w CNY",
                "12 sztuk 70 cl",
                "宇鄉燕澳洲卤水鲍鱼 425g",
                "Kinohimitsu 御皇红烧日本鲍鱼 425g",
                "Kinohimitsu 花旗参虫草鸡精礼盒 75ml x 3",
                "宇鄕燕特级燕窝 80ml x 3",
                "宇鄕燕银耳燕窝系列 75ml x 3",
                "宇鄕燕鲍鱼佛跳墙 425g",
                "宇鄕燕特级燕窝(单罐装) 165ml",
                "上等鱼鳔 117g",
                "100 g",
                "上等鲍鱼片 100g",
                "100 g",
                "附送琉云漓彩琉璃艺品",
                "精美贺年礼篮"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 873,
        "name": "Avalynn",
        "handle": "jaylani",
        "description": "Ten bukiet przedstawia słodkiego pluszowego misia absolwenta pośród kapryśnej kompozycji kwiatów w kolorze tiffany blue, podkreślonych majestatyczną niebieską różą i miękkimi bawełnianymi akcentami, zawiniętych w elegancki jasnoniebieski papier dla radosnego świętowania sukcesu akademickiego.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Avalynn-2a.jpg?v=1720665186",
            "/images/products/Avalynn-1a.jpg?v=1720665186"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Zachowana hortensja. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 874,
        "name": "Fortuna (VIII)",
        "handle": "fortune-viii",
        "description": "Świętuj wszystkie premiery biznesowe dzięki naszemu statywowi Pastel Dreamscape. Ozdobiony różowymi i białymi balonami oraz delikatną gamą pastelowych kwiatów, tworzy uroczy, pełen radości punkt centralny, symbolizujący początek udanej i zachwycającej podróży.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Fortune-_VIII_-1b.jpg?v=1720095211",
            "/images/products/Grand-Opening-Card-2024_a3d8a5ee-fba7-4ec8-a2db-12b7e4066f0a.jpg?v=1720095216",
            "/images/products/Fortune-_VIII_-2b.jpg?v=1720095211"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustoma. Mamo. Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 875,
        "name": "Fortuna (IV)",
        "handle": "fortune-iv",
        "description": "Oświetl wszystkie wielkie otwarcia za pomocą statywu Golden Radiance, olśniewającego połączenia szampańskich róż, żywych słoneczników i egzotycznych kwiatów rajskich ptaków. Ta majestatyczna kompozycja kwiatowa jest wysoka i symbolizuje dobrobyt, radość i początek promiennej podróży. Ucieleśnienie sukcesu i świętowania.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Fortune-_IV_-1b.jpg?v=1720094243",
            "/images/products/Grand-Opening-Card-2024_145fd1c8-c4c6-4cca-9ce7-fce803bad4e9.jpg?v=1720094248",
            "/images/products/Fortune-_IV_-2b.jpg?v=1720094244"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Eustoma. Rajskie ptaki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 876,
        "name": "Regana",
        "handle": "regan",
        "description": "Ten bukiet przedstawia słodkiego pluszowego misia absolwenta pośród fantazyjnej kompozycji różowych i liliowych kwiatów, podkreślonych majestatyczną różową konserwowaną różą i miękkimi bawełnianymi akcentami, owiniętego w elegancki jasnoróżowy papier dla radosnego świętowania sukcesu akademickiego.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na ukończenie studiów"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Regan-2b-768189.jpg?v=1720789880",
            "/images/products/Regan-1b.jpg?v=1720664581"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowana róża. Zachowana hortensja. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 877,
        "name": "Fortuna (ja)",
        "handle": "fortune-i",
        "description": "Zaznacz wielkie otwarcie wszystkich firm dzięki statywowi Opulent Harmony. Prezentuje nieskazitelne piękno białej rośliny kapustnej, uzupełnione czerwonymi i różowymi różami, orchideami i eustomami, a wszystko to elegancko owinięte w czerwoną listwę. Ta luksusowa aranżacja symbolizuje sukces, dobrobyt i początek wyrafinowanej podróży biznesowej.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Fortune-_I_-1b.jpg?v=1720095402",
            "/images/products/Grand-Opening-Card-2024_813f42f1-9838-4bfc-841b-439c7a74a143.jpg?v=1720095408",
            "/images/products/Fortune-_I_-2b.jpg?v=1720095402"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Róże. Eustoma. Sztuczne orchidee. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 878,
        "name": "Fortuna (IX)",
        "handle": "fortune-ix",
        "description": "Świętuj z naszym stojakiem kwiatowym na otwarcie wielkiego biznesu z niebieską listwą przypodłogową i pastelowymi kwiatami. Ten elegancki zestaw charakteryzuje się harmonijną mieszanką pastelowych odcieni, symbolizujących wyrafinowanie, spokój i kwitnący początek Twojego przedsięwzięcia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Fortune-_IX_-1b.jpg?v=1720095679",
            "/images/products/Grand-Opening-Card-2024_890d5d09-1f40-4853-847e-306232dbd23d.jpg?v=1720095684",
            "/images/products/Fortune-_IX_-2b.jpg?v=1720095679"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Eustoma. Mamo. Sztuczna hortensja. Słonecznik. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 879,
        "name": "Komfort (IX)",
        "handle": "comfort-ix",
        "description": "W ramach uroczystego hołdu nasze statywy z kondolencjami na kwiaty prezentują czyste białe chryzantemy zestawione ze spokojnym niebieskim motywem, elegancko ozdobione granatowym dressingiem. Ta aranżacja stanowi godny i pogodny hołd, symbolizujący współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_IX_-1b.jpg?v=1720143731",
            "/images/products/Condolence-Card-2024_1_3b81dc6e-7e7e-4224-9ed7-d9d2d9364a76.jpg?v=1720143738",
            "/images/products/Comfort-_IX_-2b.jpg?v=1720143731"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Eustoma. Orchidee. Alstremeria. Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 880,
        "name": "Obfitość (III)",
        "handle": "abundance-iii",
        "description": "Uwiecznij wielkie otwarcia dzięki stoisku Złotego Jubileuszu Spraw, aby każde wielkie otwarcie było niezapomniane dzięki stoisku Wielkiego Otwarcia Złotego Jubileuszu. Radosne połączenie pomarańczowych gerber, czerwonych róż i słoneczników uzupełnione złotymi balonami, ta promienna aranżacja oznacza dobrobyt, miłość i sukces. Przygotuj scenę do doniosłej uroczystości dzięki tej wspaniałej ekspozycji kwiatowej. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Abundance-_III_-1e.jpg?v=1720494638",
            "/images/products/Grand-Opening-Card-2024_f153ed0a-226b-4d6b-b1ef-1e72aa4a0382.jpg?v=1720494645",
            "/images/products/Abundance-_III_-2e.jpg?v=1720494638"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Słonecznik. Balony . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 881,
        "name": "Obfitość (IV)",
        "handle": "abundance-iv",
        "description": "Rozpal bogactwo dzięki naszemu bogatemu, czerwonemu stojakowi z kwiatami. Przekształć wielkie otwarcia biznesowe dzięki naszemu bogatemu, czerwonemu stojakowi z kwiatami. Wysokie różowe lilie, wesołe czerwone róże i delikatne orchidee są akcentowane egzotycznymi czerwonymi kwiatami imbiru. Otoczona bogactwem falistej, czerwonej aksamitnej listwy, ta aranżacja tworzy wspaniały i wyrafinowany element centralny. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "319.25",
        "inclTax": "319.25",
        "img": [
            "/images/products/Abundance-_IV_-1f.jpg?v=1720497239",
            "/images/products/Grand-Opening-Card-2024_9b50ed97-bcc2-4f63-a54f-211da49e2f6b.jpg?v=1720497246",
            "/images/products/Abundance-_IV_-2f.jpg?v=1720497239"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidee. Róże. Mamo. Kwiat Imbiru. Sztuczna orchidea Cymbidium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 882,
        "name": "Kosz Owoców FHP",
        "handle": "basket-of-fruit",
        "description": "Kosz Owoców Nie można odmówić koszykowi zawierającemu 8 rodzajów owoców, a jednocześnie pełnym witamin A, C, E i K. Ozdobiony żywymi kwiatami może wywołać uśmiech na twarzach Twoich bliskich. Zapakowany w ekskluzywny koszyk w kolorze czekolady, prezent ten będzie idealny dla każdego.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Basket-Of-Fruits1_f445d2bc-6f3c-42a6-8164-39962bcbd0f8-316422.jpg?v=1708969395",
            "/images/products/Basket-Of-Fruits2_66898be4-b07c-4f02-8624-98727d5708b2-188051.jpg?v=1708969396"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gruszka Nam Shui",
                "2. Awokado",
                "3. Jabłko Fuji",
                "4. Zielone jabłko",
                "5. Smocze owoce",
                "6. Zielone winogrona",
                "7. Pomarańczowy",
                "8. Kiwi",
                "9. Kosz (wymień na jasnobrązowy)",
                "10. Kompozycja kwiatowa: Gerbera, róża, dziurawiec, pszenica, liść monety"
            ]
        },
        "dimention": "Rozmiar Kosza Upominkowego : 40cm x 18cm x 31cm"
    },
    {
        "id": 883,
        "name": "Obfitość (V)",
        "handle": "abundance-v",
        "description": "Rozświetl starty firm promiennymi kwiatami Świętuj wielkie otwarcia promiennymi kwiatami i harmonijnym stoiskiem symbolizującym sukces i świetlaną przyszłość. Taka aranżacja zapewni Twój biznes niezapomniany i kwitnący start. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Abundance-_V_-1f.jpg?v=1720407608",
            "/images/products/Grand-Opening-Card-2024_c93b27e5-9d1f-46f2-954b-2366e111bf16.jpg?v=1720407615",
            "/images/products/Abundance-_V_-2f.jpg?v=1720407608"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 884,
        "name": "Pożywne przekąski FHP",
        "handle": "nutritious-nibbles",
        "description": "Pożywne przekąski",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Nutritious-Nibbles1-246089.jpg?v=1671685496",
            "/images/products/Nutritious-Nibbles2-828598.jpg?v=1671685496"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Herbata Juan 9g x 2 pudełka",
                "2. Breitsamer Organiczny miód Golden Selection 500g",
                "3. Gruszka Nam Shui",
                "4. Truskawka",
                "5. Jabłko Fuji",
                "6. Zielone jabłko",
                "7. Awokado",
                "8. Kiwi",
                "9. Kompozycja kwiatowa: Gerbera, spray różany, biały feniks"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 885,
        "name": "Sok",
        "handle": "juice-recharge",
        "description": "Doładowanie soku",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Juice-_-Recharge1-111451.jpg?v=1671774899",
            "/images/products/Juice-_-Recharge2-518883.jpg?v=1671774899"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Marki Esencja z kurczaka 42ml x 6",
                "2. Mango",
                "3. Pomarańczowy",
                "4. Jabłko Fuji",
                "5. Zielone winogrona",
                "6. Kiwi",
                "7. Roślina kwiatowa",
                "8. Kosz (wymień na jasnobrązowy)"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 886,
        "name": "Obfitość (IX)",
        "handle": "fortune-ix-1",
        "description": "Odmień wielkie otwarcia biznesowe za pomocą naszych stojaków na kwiaty z motywem fioletu. Fioletowe róże zajmują centralne miejsce, tworząc wystawny przejaw elegancji i dobrobytu. Harmonijne połączenie eleganckiej bieli i fioletu symbolizuje sukces i kwitnące przedsięwzięcie. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Abundance-_IX_-1b.jpg?v=1720100100",
            "/images/products/Grand-Opening-Card-2024_9ad558f8-89d6-4efa-8c45-80a8304a6fea.jpg?v=1720100104",
            "/images/products/Abundance-_IX_-2b_1547ef2d-615d-4411-b8a9-ee6e7d90e5a2.jpg?v=1720105309"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Mamo. Goździk . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 887,
        "name": "Fortuna (X)",
        "handle": "fortune-x",
        "description": "Wejdź w atmosferę wyrafinowanego piękna dzięki naszemu statywowi Blushing Elegance. Zawierający różowe róże, goździki, różowe białe eustoma i fioletową Matthiolę, ten kwiatowy zestaw emanuje wdziękiem, tworząc oszałamiający element centralny podczas uroczystego otwarcia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Fortune-_X_-1b.jpg?v=1720100265",
            "/images/products/Grand-Opening-Card-2024_1e1874f9-f020-4b2a-9f4e-4a4f9f60aeba.jpg?v=1720100271",
            "/images/products/Fortune-_X_-2b.jpg?v=1720100265"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Mattiola. Goździk . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 888,
        "name": "Zuri",
        "handle": "zuri",
        "description": "Ten wykwintny bukiet składa się z miękkiej palety kremowych i różowych róż, delikatnych hortensji i kwiatów bawełny, elegancko ułożonych z bujną zielenią i trawą pampasową. Owinięta warstwami papieru w kolorze kości słoniowej i wykończona satynową wstążką, ta aranżacja emanuje wyrafinowaniem i urokiem. Idealny na specjalne okazje lub jako przemyślany prezent, dodaje odrobinę ponadczasowego piękna każdemu otoczeniu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Zuri-2b-407288.jpg?v=1724340103",
            "/images/products/Zuri-1b.jpg?v=1724305836"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Zachowane róże. Zachowany oddech dziecka. Zachowana hortensja. Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 889,
        "name": "Słodki",
        "handle": "sweet-fresh-treats-fhp",
        "description": "Słodkie, świeże smakołyki",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Sweet-_-Fresh-Treats-FHP1-130879.jpg?v=1672135278",
            "/images/products/Sweet-_-Fresh-Treats-FHP2-951900.jpg?v=1672135278"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pomarańczowy",
                "2. Jabłko Fuji",
                "3. Zielone winogrona",
                "4. Kiwi",
                "5. Zielone jabłko",
                "6. Belgijska czekolada Hamlet Assortiment",
                "7. Kubek deserowy Cupido Latte Macchiato",
                "8. Gerbera",
                "9. Róże",
                "10. Eustoma",
                "11. Hyperikum",
                "12. Liść monety",
                "13. Okrągłe pudełko na kapelusze"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 890,
        "name": "Owoce",
        "handle": "fruits-blooms-fhp",
        "description": "Kwitną owoce",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "241.66",
        "inclTax": "241.66",
        "img": [
            "/images/products/Fruits-_-Blooms-FHP1-265400.jpg?v=1672134076",
            "/images/products/Fruits-_-Blooms-FHP2-656819.jpg?v=1672134076"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zielone jabłko",
                "2. Pomarańczowy",
                "3. Kiwi",
                "4. Gruszka Forelle",
                "5. Gruszka Nam Shui",
                "6. Zielone winogrona",
                "7. Smocze owoce",
                "8. Kompozycja kwiatowa: Gerbera, róże, eustoma",
                "9. Okrągłe pudełko na kapelusze"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 891,
        "name": "Owocowy kwiat FHP",
        "handle": "fruity-blossom-fhp",
        "description": "Fruity BlossomTo zachwycające pudełko jest pełne nieodpartych i przepysznych owoców. Piękny bukiet słodko pachnących różowych, liliowych i pastelowych kwiatów uzupełnia ten wzruszający prezent.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Fruity-Blossom1-134927.jpg?v=1672221466",
            "/images/products/Fruity-Blossom2-663557.jpg?v=1672221466"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mango",
                "2. Truskawka",
                "3. Awokado",
                "4. Pomarańczowy",
                "5. Gruszka Nam Shui",
                "6. Fioletowe winogrona",
                "7. Smocze owoce",
                "8. Jabłko Fuji",
                "9. Zielone jabłko",
                "10. Drewniana skrzynia (L)",
                "11. Drewniana skrzynia (W)",
                "12. Kompozycja kwiatowa: Gerbera, Eustoma, Hypericum, Liść Monety"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 892,
        "name": "Wellness Goodies FHP",
        "handle": "wellness-goodies-fhp",
        "description": "Przysmaki Wellness",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "469.86",
        "inclTax": "469.86",
        "img": [
            "/images/products/Wellness-Goodies1-743940.jpg?v=1672222726",
            "/images/products/Wellness-Goodies2_a981bcaa-aa41-41ef-94a0-7f8f032186f3-348960.jpg?v=1672222726"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "Dom Benedyktyński 37,5cl. Markowa esencja z kurczaka 42ml x 6 . Gerbera . Róże. Hypericum. Eustoma. Liść monety. Jabłko Fuji. Forelle Gruszka. Kiwi. Koszyk"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 893,
        "name": "Obfitość (VI)",
        "handle": "abundance-vi",
        "description": "Uatrakcyjnij otwieranie firm dzięki naszemu trójnógowemu stojakowi Elegance Blossoms. Ta oszałamiająca kompozycja, ozdobiona asortymentem wyrafinowanych kwiatów w eleganckich kolorach, ucieleśnia esencję sukcesu i wyrafinowania, tworząc urzekający element centralny wszystkich wielkich wydarzeń. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całego kraju Polska.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Abundance-_VI_-1b.jpg?v=1720100464",
            "/images/products/Grand-Opening-Card-2024_dc7d0bb9-e568-4337-af33-4fa3d2327405.jpg?v=1720100469",
            "/images/products/Abundance-_VI_-2b.jpg?v=1720100462"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Meta róże. Eustoma. Gerbera . Pampasy. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 894,
        "name": "Obfitość (VII)",
        "handle": "abundance-vii",
        "description": "Stojak trójnóg Cappuccino Elegance na wielkie otwarcia biznesowe. Otoczony różami w kolorze cappuccino, gossypium i suszonymi kwiatami, ozdobiony elegancką listwą przypodłogową w kolorze nude, ta aranżacja oznacza wyrafinowane początki i trwały sukces. Gustowna ozdoba ważnego przedsięwzięcia biznesowego. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Abundance-_VII_-1d.jpg?v=1720104911",
            "/images/products/Grand-Opening-Card-2024_8c7140c6-72d8-45d4-972b-308f4b9bdd5e.jpg?v=1720104918",
            "/images/products/Abundance-_VII_-2d.jpg?v=1720104912"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże capuccino. Bawełna . Pszenica . Pampasy. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 895,
        "name": "Heng I",
        "handle": "heng-i",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_I_-1A-666042.jpg?v=1672746064",
            "/images/products/Heng2-807601.jpg?v=1672723782"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Cipka Willow. Sztuczne kwiaty. Zachowana hortensja"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 896,
        "name": "Obfitość (VIII)",
        "handle": "abundance-viii",
        "description": "Odkryj wyrafinowanie dzięki naszej elegancji w Bloom. Trójnóg Stojak na trójnóg Zawiera trzy czarujące kwiaty – różowe i szampańskie gerbery, delikatne różowe róże i kapryśny oddech dziecka – to kwiatowe arcydzieło stoi wysoko, symbolizując wdzięk i dobrobyt. Stwórz urzekający punkt centralny swojego wielkiego otwarcia dzięki tej oszałamiającej aranżacji. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Abundance-_VIII_-1c.jpg?v=1720093252",
            "/images/products/Grand-Opening-Card-2024_4e11dba0-9f0b-4826-986b-155e9430746f.jpg?v=1720093258",
            "/images/products/Abundance-_VIII_-2c.jpg?v=1720093252"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 897,
        "name": "Heng II",
        "handle": "heng-ii",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_II_-1A-153315.jpg?v=1672746066",
            "/images/products/Heng-_II_-2-427129.jpg?v=1672723479"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Cipka Willow. Mamy. Sztuczne kwiaty"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 898,
        "name": "Obfity (ja)",
        "handle": "bountiful-i",
        "description": "Świętuj wszystkie premiery biznesowe dzięki naszemu pastelowemu stojakowi na kwiaty Dreamscape. Ozdobiony różowymi i białymi kwiatami oraz delikatną gamą pastelowych kwiatów, tworzy uroczy, pełen radości punkt centralny, symbolizujący początek udanej i zachwycającej podróży.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Bountiful-_I_-1d.jpg?v=1720497819",
            "/images/products/Grand-Opening-Card-2024_957d3276-2506-42a8-a427-cab4daaa4602.jpg?v=1720497826",
            "/images/products/Bountiful-_I_-2e.jpg?v=1720497874"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Sztuczne orchidee. Róże. Sztuczne hortensje. Mattiola. Eustoma. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 899,
        "name": "Heng III",
        "handle": "heng-iii",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_III_-1A-526594.jpg?v=1672397093",
            "/images/products/Heng-_III_-2A_08f93ca2-3c7f-4dc3-9d71-16ab287ddf9a-710476.jpg?v=1672658625"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Cipka Willow. Mamo. Różowy Goździk. Sztuczne kwiaty"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 900,
        "name": "Komfort (V)",
        "handle": "bountiful-ii",
        "description": "Składając szczere kondolencje, nasze trójnogi na kwiaty mają kojący motyw bieli i szampana z wdzięcznymi różami i chryzantemami, elegancko ubranymi w spokojną biel. Taka aranżacja stanowi godny i pocieszający hołd, symbolizujący współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_V_-1b.jpg?v=1720143421",
            "/images/products/Condolence-Card-2024_1_9c6bd4e4-bc49-4356-8954-e39a63fb6528.jpg?v=1720143428",
            "/images/products/Comfort-_V_-2b.jpg?v=1720143422"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Kwiaty Woskowe. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 901,
        "name": "Heng IV",
        "handle": "heng-iv",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_IV_-1A_18319db7-f753-4622-9104-de2d46577533-500419.jpg?v=1672397093",
            "/images/products/Heng-_IV_-2A-692814.jpg?v=1672397093"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Cipka Willow. Mamo. Czerwony Goździk. Sztuczne kwiaty"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 902,
        "name": "Komfort (X)",
        "handle": "comfort-x",
        "description": "Jako wyraz współczucia, nasze statywy na kwiaty kondolencyjne mają uspokajający zielono-biały motyw, ozdobiony wdzięcznymi orchideami, chryzantemami, delikatnymi eustomami i hortensjami. Ta elegancka aranżacja stanowi pocieszający hołd, symbolizujący współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Comfort-_X_-1b.jpg?v=1720144317",
            "/images/products/Condolence-Card-2024_1_ce5eb82b-0891-4f67-9771-23a6320328dc.jpg?v=1720144322",
            "/images/products/Comfort-_X_-2b.jpg?v=1720144316"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Eustoma. Mattiola. Sztuczna orchidea. Sztuczna hortensja. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 903,
        "name": "Empatia (X)",
        "handle": "empathy-x",
        "description": "Składając szczere kondolencje, nasze trójnogi na kwiaty mają pocieszający żółty motyw, ozdobiony wdzięcznymi orchideami, chryzantemami i delikatnymi eustomami. Ta aranżacja, ubrana w pomarańczowe kratki, stanowi godny i kojący hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Empathy-_X_-1b.jpg?v=1720144187",
            "/images/products/Condolence-Card-2024_1_f3f67139-e036-4a88-9971-e8127c6a2939.jpg?v=1720144202",
            "/images/products/Empathy-_X_-2b.jpg?v=1720144187"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Eustoma. Mattiola. Róża. Różane spraye. Sztuczna orchidea. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 904,
        "name": "Kondolencje (IX)",
        "handle": "condolence-ix",
        "description": "Składając z wdziękiem kondolencje, nasze statywy do kwiatów przedstawiają spokojne żółte, czysto białe i ciemnoczerwone kwiaty, elegancko ubrane w spokojną biel. Ta aranżacja stanowi godny hołd, symbolizujący współczucie i pamięć, zapewniając pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Condolence-_IX_-1b.jpg?v=1720138069",
            "/images/products/Condolence-Card-2024_1_32847373-7ecb-4c59-85c4-71624e555fc4.jpg?v=1720138074",
            "/images/products/Condolence-_IX_-2b.jpg?v=1720138069"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Lilie . Róża. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 905,
        "name": "Heng V",
        "handle": "heng-v",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "157.18",
        "inclTax": "157.18",
        "img": [
            "/images/products/Heng-_V_-1-452672.jpg?v=1672833285",
            "/images/products/Heng-_V_-2-160696.jpg?v=1672833285"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidea Phalaenopsis"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 906,
        "name": "Heng VI",
        "handle": "heng-vi",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "157.18",
        "inclTax": "157.18",
        "img": [
            "/images/products/Heng-_VI_-1A_33612889-bf9c-450b-b2dc-fc47f86a74c7-663888.jpg?v=1672833285",
            "/images/products/Heng-_VI_-2A_9172ec48-54e6-4fce-bf2f-11604fa259b7-511013.jpg?v=1672833285"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Orchidea Phalaenopsis"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 907,
        "name": "Pocieszenie (ja)",
        "handle": "solace-i",
        "description": "W ramach czułego wyrazu kondolencji nasze stojaki na kwiaty przedstawiają róże w pastelowych fioletach i kojących żółtych odcieniach, gustownie ozdobione pogodnym białym dressingiem. Ten elegancki zespół składa delikatny hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Solace-_I_-1c.jpg?v=1720105602",
            "/images/products/Condolence-Card-2024_1_7a0b062c-f2ff-4f2c-802b-746b3c02c6b5.jpg?v=1720105608",
            "/images/products/Solace-_I_-2c.jpg?v=1720105603"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Eustoma. Mattiola. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 908,
        "name": "Pocieszenie (II)",
        "handle": "solace-ii",
        "description": "Składając szczere kondolencje, nasze statywy na kwiaty charakteryzują się wyrafinowaną mieszanką odcieni fioletu, nude i błękitu, ozdobionymi majestatycznymi różami i delikatnymi eustomami. Ta elegancka aranżacja stanowi godny hołd, symbolizując współczucie i pamięć, oferując pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Solace-_II_-1b.jpg?v=1720142840",
            "/images/products/Condolence-Card-2024_1_17c0b9f4-543f-4d29-9924-050867833d54.jpg?v=1720142846",
            "/images/products/Solace-_II_-2b.jpg?v=1720142840"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Eustoma. Mattiola. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 909,
        "name": "Pocieszenie (III)",
        "handle": "solace-iii",
        "description": "W ramach pełnego szacunku hołdu nasze trójnogi z kondolencjami na kwiaty przedstawiają czyste białe chryzantemy elegancko ozdobione kojącą aranżacją z motywem zieleni. Ubrany w spokojną biel, ten zespół stanowi godny i delikatny hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Solace-_III_-1c.jpg?v=1720105760",
            "/images/products/Condolence-Card-2024_1_5948eb92-8d90-4bc5-a4ed-88234a331b6a.jpg?v=1720105768",
            "/images/products/Solace-_III_-2c.jpg?v=1720105760"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Eustoma. Mattiola. Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*150cm"
    },
    {
        "id": 910,
        "name": "Pocieszenie (IV)",
        "handle": "solace-iv",
        "description": "Z wdziękiem składając kondolencje, nasze stojaki na kwiaty przedstawiają pogodne białe chryzantemy, delikatne różowe róże i eleganckie eustomy. Ta aranżacja stanowi delikatny i pocieszający hołd, symbolizujący współczucie i pamięć, zapewniając pocieszenie w chwilach głębokiego smutku i refleksji z delikatnym pięknem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Solace-_IV_-1b.jpg?v=1720142353",
            "/images/products/Condolence-Card-2024_1_b4d0255c-5ca5-4027-a6a4-6a98ccf6650a.jpg?v=1720142361",
            "/images/products/Solace-_IV_-2b.jpg?v=1720142354"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Mamo. Sztuczne orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 911,
        "name": "Pomyślne pomarańcze 吉祥如意",
        "handle": "auspicious-oranges-吉祥如意",
        "description": "Pomyślne pomarańcze 吉祥如意Przywitaj Księżycowy Nowy Rok z pomyślnym koszem mandarynek, które przyniosą szczęście i szczęście Twoim bliskim.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "145.82",
        "inclTax": "145.82",
        "img": [
            "/images/products/Auspicious-Oranges_1_7b2259b3-a82e-4c4e-aecb-fccb20ed827f-172020.jpg?v=1673358471",
            "/images/products/Template-696158.jpg?v=1673358471"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mandarynki x 16",
                "2. Kosz"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 912,
        "name": "Obfite pomarańcze 大吉大利",
        "handle": "bountiful-oranges-大吉大利",
        "description": "Bountiful Oranges 大吉大利Życz swojej rodzinie i przyjaciołom obfitości bogactwa i dobrego zdrowia dzięki świątecznemu koszowi wypełnionemu pomarańczami, idealnemu do dzielenia się.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Bountiful-Oranges-_1_23aeb64b-0b46-4a78-8cfb-6faa64379808-228471.jpg?v=1673446478",
            "/images/products/Template_d212cd96-57d2-43c7-8440-7016052caae7-486233.jpg?v=1673411351"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mandarynki x 18",
                "2. Skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 913,
        "name": "Wiosenne kwiaty 春暖花开",
        "handle": "spring-blossoms-春暖花开",
        "description": "Spring Blossoms 春暖花开Świętuj Święto Wiosny z pięknym asortymentem starannie dobranej flory, aby życzyć rodzinie i przyjaciołom kwitnącego i pomyślnego nadchodzącego roku.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "166.36",
        "inclTax": "166.36",
        "img": [
            "/images/products/Spring-Blossoms-_1-737668.jpg?v=1673360362",
            "/images/products/Template_21ad38c5-e840-4978-85a8-0e0a36b6e71b-438881.jpg?v=1673360362"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Kompozycja kwiatowa",
                "2. Pudełko"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 914,
        "name": "Pomarańczowe skarby 橙心如意",
        "handle": "orange-treasures-橙心如意",
        "description": "Pomarańczowe skarby 橙心如意Ta skarbnica wypełniona pulchnymi pomarańczami reprezentuje rok obfitujący w sukcesy i bogactwa, a z pewnością będzie wspaniałym prezentem dla Twojej rodziny i przyjaciół.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Orange-Treasures-_1-621355.jpg?v=1673358868",
            "/images/products/Template_190e3062-5fa7-4275-9a23-b53eaf6495c8-541360.jpg?v=1673358868"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mandarynki x 18",
                "2. Kosz"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 915,
        "name": "Fortune Oranges 橘祥如意",
        "handle": "fortune-oranges-橘祥如意",
        "description": "Fortune Oranges 橘祥如意 Liczba „8” ma ogromne znaczenie jako szczęśliwa liczba, która przynosi wielkie bogactwo i sukces. Dlatego ten piękny prezent będzie idealny dla Twoich bliskich, którzy mogą pochwalić się kolekcją 8 pomarańczy w towarzystwie doskonałej butelki czerwonego wina.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Fortune-Oranges-_1-163592.jpg?v=1673446668",
            "/images/products/Template-600503.jpg?v=1673446668"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mandarynki x 8",
                "2. Skrzynia",
                "3. Grand Jacques Cabernet Sauvignon 75cl"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 916,
        "name": "Kwiatowe błogosławieństwo 花开富貴",
        "handle": "floral-blessing-花开富貴",
        "description": "Kwiatowe błogosławieństwo Skrupulatnie wykonane do perfekcji, to rzemieślnicze pudełko z kwiatami jest idealne, aby powitać ducha Księżycowego Nowego Roku i przynieść błogosławieństwa bogactwa i fortuny swojej rodzinie, przyjaciołom i współpracownikom.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Floral-Blessing-_1-149470.jpg?v=1673446668",
            "/images/products/Template_151fafa1-9d25-45a3-8429-de342cba19af-326577.jpg?v=1673446668"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Kompozycja kwiatowa",
                "2. Pudełko"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 917,
        "name": "Ogród bogactwa 花团锦族",
        "handle": "garden-of-wealth-花团锦族",
        "description": "Garden of Wealth Ta pełna wdzięku i marzycielska kompozycja kwiatowa to idealny prezent dla Twojej rodziny i przyjaciół na nadchodzący Księżycowy Nowy Rok.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Garden-of-Wealth-_1-125521.jpg?v=1673446669",
            "/images/products/Template_6b64a1d8-6978-4b5c-9d1b-94fa8aed0a95-405460.jpg?v=1673446669"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Kompozycja kwiatowa",
                "2. Pudełko"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 918,
        "name": "Błogie pomarańcze 橙心橙意",
        "handle": "blissful-oranges-橙心橙意",
        "description": "Blissful Oranges 橙心橙意 Wspaniały prezent dla Twoich bliskich, ciesz się piękną kompozycją żywych kwiatów w towarzystwie pulchnych pomarańczy i czerwonego wina premium.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "209.72",
        "inclTax": "209.72",
        "img": [
            "/images/products/Blissful-Oranges-_1-695648.jpg?v=1673446368",
            "/images/products/Template_b66f9c64-621f-43c0-bbf7-b19347259ffe-468794.jpg?v=1673446368"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mandarynki x 2",
                "2. Grand Jacques Cabernet Sauvignon 75cl",
                "3. Kompozycja kwiatowa",
                "4. Pudełko"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 919,
        "name": "Gentle Bliss 百花齐放",
        "handle": "gentle-bliss-百花齐放",
        "description": "Gentle Bliss 百花齐放Życz życia pełnego sukcesów i bogactwa dzięki pięknemu pudełku kwiatów, które z pewnością wywołają uśmiech na Twoich bliskich.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "218.84",
        "inclTax": "218.84",
        "img": [
            "/images/products/Gentle-Bliss-_1-930968.jpg?v=1673446668",
            "/images/products/Template_a18040d7-7ea8-48a2-bc38-a870fe108134-676687.jpg?v=1673446668"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Kompozycja kwiatowa",
                "2. Pudełko"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 920,
        "name": "Pomyślne pomarańcze 吉隆之喜",
        "handle": "prosperous-oranges-吉隆之喜",
        "description": "Bogate Pomarańcze Ten skromny kosz pomarańczy, orzechowych smakołyków i wybornej butelki czerwonego wina z pewnością wywoła uśmiech na twarzach Twoich bliskich.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Prosperous-Oranges-_1-689810.jpg?v=1673447102",
            "/images/products/Template_31b95a8e-1ea7-4362-85e9-b50dea885f4b-251832.jpg?v=1673447102"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mandarynki x 2",
                "2. Pudełko upominkowe Amazin Grace Lunar Nut Mix 8 x 30g mieszanka orzechów (odmiana) - lewe zielone pudełko upominkowe (czerwone jest niedostępne)",
                "3. Zamek Du Vigneron Tempranillo 75cl",
                "4. Kosz"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 921,
        "name": "Książka „Dostatnie kwiaty”",
        "handle": "prosperous-blooms-充满花香",
        "description": "Prosperous Blooms Dodaj jasne kolory do domu swoich bliskich podczas tych pomyślnych wakacji z małym ogrodem pełnym majestatycznej flory.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Prosperous-Blooms-_1-850055.jpg?v=1673447102",
            "/images/products/Template_ae94d631-5ec7-48fb-a58f-72bb416e525a-905007.jpg?v=1673447102"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Kompozycja kwiatowa",
                "2. Pudełko"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 922,
        "name": "Pomarańczowe błogosławieństwo 大功告橙",
        "handle": "orange-blessing-大功告橙",
        "description": "Orange Blessing 大功告橙Wyślij swoje błogosławieństwa i wyrazy wdzięczności swoim przyjaciołom i współpracownikom, korzystając z wysokiej jakości kosza upominkowego wypełnionego pięknie dojrzałymi pomarańczami, luksusowym czerwonym winem i asortymentem kolorowych kwiatów.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Orange-Blessing-_1_5beef075-4738-4ba4-aeed-4a96d3be6ba5-653531.jpg?v=1673446986",
            "/images/products/Template_1f5ea4f3-19ab-4852-9e61-82b0af056a7a-914094.jpg?v=1673446986"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mandarynki x 8",
                "2. Grand Jacques Cabernet Sauvignon 75cl",
                "3. Kompozycja kwiatowa",
                "4. Kosz"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 923,
        "name": "Wishful Oranges 心想事橙",
        "handle": "wishful-oranges-心想事橙",
        "description": "Wishful Oranges 心想事橙Świętuj świąteczne wakacje i delektuj się świeżymi, soczystymi pomarańczami i czerwonym winem premium wraz z rodziną i przyjaciółmi.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "401.40",
        "inclTax": "401.40",
        "img": [
            "/images/products/Wishful-Oranges-_1_daa31a39-7e4f-449b-8c14-a1a9c5f69643-611123.jpg?v=1673448121",
            "/images/products/Template_1c7296a4-256d-47cc-a9de-7e5ae280f44d-649383.jpg?v=1673448121"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Mandarynki x 18",
                "2. Castelforte Corvina Veronese Indicazione Geografica Tipica, 75cl",
                "3. Le Morette Bardolino Classico 2019 75cl",
                "4. Kosz"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 924,
        "name": "Życzliwość (ja)",
        "handle": "benevolence-i",
        "description": "Składając uroczyste kondolencje, nasze kwietniki z wdziękiem przedstawiają czyste białe chryzantemy w spokojnej, niebieskiej aranżacji. Ten spokojny zespół składa czuły hołd, symbolizując współczucie i pamięć, zapewniając pocieszenie i pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_I_-1d.jpg?v=1720187683",
            "/images/products/Condolence-Card-2024_1.jpg?v=1720187690",
            "/images/products/Benevolence-_I_-2d.jpg?v=1720187683"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 925,
        "name": "Życzliwość (II)",
        "handle": "benevolence-ii",
        "description": "W chwilach kondolencji nasze kwietniki elegancko ozdobią chryzantemy w kojącym motywie szampańskiej bieli. Ta spokojna aranżacja składa godny hołd, symbolizując współczucie i pamięć. Niech przyniesie ukojenie i ukojenie w chwilach smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_II_-1d.jpg?v=1720181713",
            "/images/products/Condolence-Card-2024_1_e4a43a3c-35f3-422b-80e9-57c2d05d7890.jpg?v=1720181725",
            "/images/products/Benevolence-_II_-2d.jpg?v=1720181714"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 926,
        "name": "Życzliwość (III)",
        "handle": "benevolence-iii",
        "description": "Składając szczere kondolencje, nasze kwietniki czule przedstawiają czyste białe chryzantemy w kojącym zestawie z motywem zieleni. Ta spokojna aranżacja składa delikatny hołd, symbolizując współczucie i pamięć, zapewniając pocieszenie i komfort w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_III_-1d.jpg?v=1720187166",
            "/images/products/Condolence-Card-2024_1_3637f644-1546-4829-9a2a-71e96ef47ee0.jpg?v=1720187178",
            "/images/products/Benevolence-_III_-2d.jpg?v=1720187166"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 927,
        "name": "Życzliwość (IV)",
        "handle": "benevolence-iv",
        "description": "W wyrazie najgłębszego współczucia nasze Stojaki z kwiatami kondolencyjnymi przedstawiają chryzantemy w kolorze spokojnej bieli i królewskiej fioletu. Ta elegancka aranżacja składa czuły hołd, symbolizując współczucie i pamięć, przynosząc pocieszenie i pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_IV_-1d.jpg?v=1720187321",
            "/images/products/Condolence-Card-2024_1_19c5281a-8166-40a6-8c79-7c0374b41b9a.jpg?v=1720187334",
            "/images/products/Benevolence-_IV_-2d.jpg?v=1720187321"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 928,
        "name": "Życzliwość (V)",
        "handle": "benevolence-v",
        "description": "Składając uroczyste kondolencje, nasze kwietniki z wdziękiem ozdobione są czystymi białymi i kojącymi żółtymi chryzantemami. Ta spokojna aranżacja składa czuły hołd, symbolizując współczucie i pamięć, przynosząc pocieszenie i pocieszenie w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Benevolence-_V_-1d.jpg?v=1720187022",
            "/images/products/Condolence-Card-2024_1_9ad47213-828e-4072-baa1-34b8ccf7fe14.jpg?v=1720187034",
            "/images/products/Benevolence-_V_-2d.jpg?v=1720187022"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 929,
        "name": "Kamari",
        "handle": "kamari",
        "description": "„Na całym świecie nie ma nic piękniejszego niż dziewczyna zakochana w każdym oddechu”. - Atticus",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Kamari1B_6fc128a6-4062-4702-8e5b-df5ed0704113-231855.jpg?v=1673622207",
            "/images/products/Kamari2B-650429.jpg?v=1673622207"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 930,
        "name": "Augusta",
        "handle": "augusta",
        "description": "Uświetnij każdą okazję naszym urzekającym bukietem składającym się z pięciu słoneczników i aromatycznego eukaliptusa. Niezależnie od tego, czy świętujesz urodziny, wyrażasz wdzięczność, czy przesyłasz życzenia, ta aranżacja emanuje radością i elegancją. Zachwyć swoich bliskich żywymi barwami i świeżym zapachem tego oszałamiającego bukietu.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Augusta-1a.jpg?v=1720749796",
            "/images/products/Augusta-2a.jpg?v=1720749796"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Eukaliptus"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 931,
        "name": "Antonina",
        "handle": "antoinette",
        "description": "Ten wykwintny bukiet składa się z nieskazitelnych białych róż i lisiantusa, elegancko uzupełnionych gałązkami eukaliptusa. Zapakowany w miękki biały papier i przewiązany satynową wstążką, emanuje czystą elegancją i spokojem, co czyni go idealnym wyborem na wesela, rocznice lub każdą okazję, która wymaga odrobiny wdzięku i wyrafinowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Antoinette-2a.jpg?v=1721365874",
            "/images/products/Antoinette-1a.jpg?v=1721365874"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 932,
        "name": "Maribella",
        "handle": "maribelle",
        "description": "Poczuj klasyczny urok naszego bukietu składającego się z 9 ciemnoczerwonych róż, starannie połączonych z aromatyczną elegancją eukaliptusa. Bogate, aksamitnie czerwone kwiaty symbolizują pasję, a pachnący eukaliptus dodaje orzeźwiającego akcentu. ",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Maribelle-1a.jpg?v=1721182595",
            "/images/products/Maribelle-2a.jpg?v=1721182594"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eukaliptus"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 933,
        "name": "Maeve",
        "handle": "maeve",
        "description": "Podnieś jakość dawania prezentów dzięki naszej luksusowej kompozycji składającej się z 12 namiętnych czerwonych róż pięknie zaprezentowanych w okrągłym srebrnym pudełku. Każdy aksamitny kwiat emanuje elegancją, tworząc uderzający kontrast wizualny z metalicznym połyskiem. Idealna do wyrażenia głębokiej miłości lub odważnego oświadczenia, ta aranżacja stanowi wyrafinowane połączenie romantyzmu i nowoczesnej estetyki, zamykając piękno w srebrnym uścisku.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Maeve-1a-136878.jpg?v=1702988691",
            "/images/products/Maeve-2a-531568.jpg?v=1702988691"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*20cm"
    },
    {
        "id": 934,
        "name": "Błogie pozdrowienia HHP",
        "handle": "blissful-greetings-hhp",
        "description": "Błogie pozdrowienia HHP Ten kosz Hari Raya zawiera różnorodne pożywne i pyszne smakołyki! Pięknie prezentowany w wykwintnym pudełku na tace, kosz dla smakoszy halal Blissful Pozdrowienia jest idealnym prezentem dla Twoich bliskich w okresie świątecznym. Przygotuj się na rozkosz!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "138.97",
        "inclTax": "138.97",
        "img": [
            "/images/products/Blissful-Greetings1.jpg?v=1698123686",
            "/images/products/Blissful-Greetings2.jpg?v=1698123686"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Chrupiące płatki owsiane Bonz Cookie",
                "2. Gofry maślane Julie’s 100g",
                "3. Beryl’s Migdałowa Czekolada Mleczna 70g",
                "4. Ciasteczka maślane Cowhead Cappuccino 150g",
                "5. Hausboom Musujący Prawdziwy Sok 275ml",
                "6. Amazin’ Graze Czekolada Orzech Laskowy 40g",
                "7. Amazin Graze Mini Pandan Mieszanka Orzechów Kokosowych 30g",
                "8. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 935,
        "name": "Przekąski HHP",
        "handle": "snack-bites-hhp",
        "description": "Snack Bites HHPŚwiętuj radość Rayi dzięki koszykowi Snack Bites Hari Raya. Zapakowany w wykwintne pudełko na tace i wypełniony pysznymi przekąskami, ten kosz dla smakoszy halal uświetni Twoje uroczystości Raya i sprawi, że ta uroczystość będzie niezapomniana.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "157.23",
        "inclTax": "157.23",
        "img": [
            "/images/products/Snack-Bites1_6a89c53d-8412-46fd-b49c-f669ff36bacd-715641.jpg?v=1687215928",
            "/images/products/Snack-Bites2.jpg?v=1687145542"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Loackers Quadratini Mini Ciemna Czekolada 125g",
                "2. Czekolada Asortyment Beryl 70g",
                "3. Ciasteczka z podwójnymi kawałkami czekolady Beryl’s 100g",
                "4. Melvados- Brownie Kruche 100g",
                "5. Gorące chipsy z tortilli misji",
                "6. Tong Garden Nutrione Pieczone Orzechy 85g",
                "7. Hausboom Musujący Prawdziwy Sok 275ml",
                "8. Prażone orzechy nerkowca z miodem Tong Garden 40g",
                "9. Wykwintne pudełko na tace"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 936,
        "name": "Indygo BBS",
        "handle": "indigo",
        "description": "Ta elegancka kompozycja w eleganckim czarnym pudełku zawiera nieskazitelną mieszankę białych tulipanów, róż i stokrotek, akcentowaną gałązkami niebieskiego ostu i bujną zielenią. Czarna wstążka i pudełko zapewniają uderzający kontrast, podkreślając czystość i prostotę białych kwiatów. Idealny do wyrafinowanej i nowoczesnej ekspozycji.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Indigo-BBS-2a.jpg?v=1721613789",
            "/images/products/Indigo-BBS-1a.jpg?v=1721613789"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 937,
        "name": "Heng VII",
        "handle": "heng-vii",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_VII_-1-990255.jpg?v=1673974469",
            "/images/products/Heng-_VII_-2-683396.jpg?v=1673974469"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Kwiaty suche i zakonserwowane"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 938,
        "name": "Heng VIII",
        "handle": "heng-viii",
        "description": "„Życie to pierwsze wrażenie. Masz jedną szansę. Niech będzie wieczne.”  -  J.R. Rim",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Heng-_VIII_-1_47fff779-e8cd-46a9-b9ea-1a1d4e831dad-478809.jpg?v=1673974471",
            "/images/products/Heng-_VIII_1-835094.jpg?v=1673974471",
            "/images/products/Heng-_VIII_-2-669997.jpg?v=1673974471"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Kwiaty suche i zakonserwowane"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 939,
        "name": "Eliasz",
        "handle": "elijah",
        "description": "Uderzający pojedynczy bukiet słoneczników, którego żywe żółte płatki wyróżniają się na tle śnieżnobiałego opakowania, akcentowane miękką, beżową wstążką. Ta słoneczna i wesoła kompozycja kwiatowa w swojej prostocie i elegancji przekazuje przesłanie ciepła, szczęścia i uwielbienia.",
        "type": "Bukiety",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Elijah1a-385575.jpg?v=1674238789",
            "/images/products/Elijah2a-538451.jpg?v=1674238789"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 940,
        "name": "Arleth",
        "handle": "arleth",
        "description": "Odważna, pojedyncza ciemnoczerwona róża wyłania się z artystycznej oprawy czerni i kremu, tworząc dramatyczny kontrast. Przewiązany kremową wstążką, ten efektowny bukiet symbolizuje głęboką i namiętną miłość, doskonale zamkniętą w jednym wykwintnym rozkwicie.",
        "type": "Bukiety",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Arleth1a-739477.jpg?v=1674238112",
            "/images/products/Arleth2a.jpg?v=1674182586"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Czerwona Róża"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 941,
        "name": "Zaria",
        "handle": "zaria",
        "description": "Delektuj się wiecznym pięknem miłości – Dziewięć wiecznie zakonserwowanych czerwonych róż w okrągłym pudełku. Ta wyjątkowa aranżacja emanuje pasją i wdziękiem, oddając esencję trwałej miłości.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Zaria-1bb_c37e0c78-5081-4857-b41c-e37b06991473-127269.jpg?v=1704811217",
            "/images/products/Zaria-2bb_bf9d043a-5175-4b12-a820-1e4df5322ade-261797.jpg?v=1704811217"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 Zachowanych Róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*20cm"
    },
    {
        "id": 942,
        "name": "Julisa",
        "handle": "julissa",
        "description": "Delektuj się wiecznym pięknem miłości dzięki naszej kolekcji róż zakonserwowanych – Dziewięć wiecznie zakonserwowanych gorących róż w okrągłym pudełku. Ta wyjątkowa aranżacja emanuje pasją i wdziękiem, oddając esencję trwałej miłości. Podnieś swoje Walentynki dzięki darowi wiecznego romansu i czaru.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Julissa-1aa-620471.jpg?v=1704463727",
            "/images/products/Julissa-2aa-963358.jpg?v=1704463727"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 Zachowanych Róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*20cm"
    },
    {
        "id": 943,
        "name": "Kynlee",
        "handle": "kynlee",
        "description": "Delektuj się prostotą miłości dzięki naszemu zachwycającemu bukietowi składającemu się z 5 żywych czerwonych róż, pomysłowo ułożonych w kwadratowym rattanowym koszu. Każdy kwiat emanuje pasją i urokiem, tworząc przyjemny wizualnie zespół. Idealny do wyrażania uczuć przy różnych okazjach lub jako uroczy element centralny, ten bukiet w kwadratowym rattanowym koszu to idealne połączenie elegancji i rustykalnego uroku, tworząc szczere oświadczenie w każdym otoczeniu.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Kynlee1A-923417_e429a32b-2fce-4df9-983f-93a78084786f-559561.jpg?v=1674678208",
            "/images/products/Kynlee2A-501076_9259107f-49ff-4a53-90ae-3594c4a038e5-339832.jpg?v=1674678208"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 944,
        "name": "Judyta",
        "handle": "judith",
        "description": "Delektuj się urokiem naszego czarującego bukietu składającego się z 5 delikatnych różowych róż, elegancko otoczonych wdzięcznymi kwiatami kaspii i teksturowanymi liśćmi parvifolii. Ten piękny zestaw jest starannie ułożony w kwadratowym rattanowym koszu, co dodaje mu rustykalnej elegancji. Idealny do wyrażania słodkich uczuć przy różnych okazjach, ten bukiet oddaje esencję naturalnego piękna i wyrafinowania, przedstawioną w uroczym kwadratowym rattanowym koszu.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Judith1-523817_a6c3ac35-0ecf-47d0-b7bb-4b97eb602c4b-570453.jpg?v=1674678092",
            "/images/products/Judith2-808904_988781a5-91fc-4a9d-bf67-049e531e8aba-564988.jpg?v=1674678092"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 945,
        "name": "Elza",
        "handle": "elsa",
        "description": "Odkryj czar miłości dzięki naszym wiecznie zakonserwowanym fioletowym różom w okrągłym pudełku, starannie wykonanym na każdy dzień w roku. Dziewięć urzekających kwiatów mówi wiele, symbolizując trwałą miłość i łaskę.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Elsa-1aa.jpg?v=1704335744",
            "/images/products/Elsa-2aa.jpg?v=1704335745"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 Zachowanych Róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*20cm"
    },
    {
        "id": 946,
        "name": "Debbie",
        "handle": "debbie",
        "description": "Podnieś swój prezent z kwiatami dzięki naszym wiecznie zakonserwowanym białym różom, pomysłowo ułożonym w eleganckim okrągłym pudełku. Każdy kwiat kryje w sobie wieczną miłość, dzięki czemu ten wyjątkowy prezent jest ponadczasowym wyrazem Twoich uczuć.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Debbie-1aa-444907.jpg?v=1704463372",
            "/images/products/Debbie-2aa.jpg?v=1704336147"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 Zachowanych Róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*20cm"
    },
    {
        "id": 947,
        "name": "Aspyn",
        "handle": "aspyn",
        "description": "Świętuj miłość, która trwa wiecznie – Dziewięć wiecznie zakonserwowanych czarnych róż umieszczonych w okrągłym pudełku. Ta czarująca aranżacja emanuje romantyzmem i wyrafinowaniem, stanowiąc doskonały wyraz trwałego uczucia.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Aspyn-1aa.jpg?v=1704337330",
            "/images/products/Aspyn-2aa.jpg?v=1704337330"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 zachowanych róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*20cm"
    },
    {
        "id": 948,
        "name": "Tina",
        "handle": "tina",
        "description": "Rozpal płomień pasji dzięki naszemu ekskluzywnemu Bloom Boxowi – Dziewięciu Wiecznie Utrwalonych Czerwonych Róż elegancko ułożonych w okrągłym pudełku. Każdy kwiat zachowuje intensywność miłości, czyniąc ten prezent ponadczasowym symbolem romantyzmu.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Tina-1aa-540557.jpg?v=1704465103",
            "/images/products/Tina-2aa-109963.jpg?v=1704465103"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 zachowanych róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*20cm"
    },
    {
        "id": 949,
        "name": "Słodkie święto HHP",
        "handle": "sweet-festivity-hhp",
        "description": "Sweet Festivity HHPS Rozbudzaj radość dzięki temu koszowi na Sweet Festivity! Wypełniony po brzegi tradycyjnymi przysmakami oraz różnorodnymi słodkimi i pikantnymi smakołykami, ten koszyk dla smakoszy zgodny z wymogami halal zapewni pełen smakowitych przekąsek czas na świąteczne uroczystości!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "175.49",
        "inclTax": "175.49",
        "img": [
            "/images/products/Sweet-Festivity1A_7c55a536-dd67-4fdb-a107-2fc278fe9e9f-236170.jpg?v=1689295909",
            "/images/products/Sweet-Festivity2A_1ceac1dc-50aa-4a67-ab39-778f615319d7-940021.jpg?v=1689295909"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Sok musujący Haus Boom Manga 275ml",
                "2. Małgosia z kiełkami nerkowca Sól truflowa 50g",
                "3. Beryl’s Classic Tiramisu Mleczna Czekolada Migdałowa 65g",
                "4. Chrupiące krakersy maślane Cowhead 208g",
                "5. Gorące chipsy ziemniaczane Pana",
                "6. Melvados Browney Kruchy 100g",
                "7. Julie’s Love Letters Czekolada 100g",
                "8. Ciasteczka Truskawkowe z Nadzieniem Truskawkowym 49g",
                "9. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 950,
        "name": "Przekąska Delight HHP",
        "handle": "snack-delight-hhp",
        "description": "Przekąska Delight HHP",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "157.23",
        "inclTax": "157.23",
        "img": [
            "/images/products/Snack-Delight1-645674.jpg?v=1675121148",
            "/images/products/Snack-Delight2.jpg?v=1675207837"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Tradycyjny Biszkopt St Michel 150g",
                "2. Tradycyjne Kruche Paluszki Duncan’s of Deeside 150g",
                "3. Forum Hamleta Oubliesa 125g",
                "4. Wernli Truffet 100g",
                "5. Hamlet Różne Belgijskie Czekoladki 125g",
                "6. Huntly",
                "7. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 951,
        "name": "Wszystkiego po trochu HHP",
        "handle": "a-bit-of-everything-hhp",
        "description": "Wszystkiego po trochu HHP",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "237.10",
        "inclTax": "237.10",
        "img": [
            "/images/products/A-Bit-Of-Everything1A_1b2b1bb9-6975-4a0d-9226-180608a036e5-918395.jpg?v=1701168956",
            "/images/products/A-Bit-Of-Everything2A-970813.jpg?v=1701168956"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Royal Select Musujący Sok Winogronowy 750ml",
                "2. Cavendisha",
                "3. Delfi Czekolada Mleczna Migdałowa 180g",
                "4. Ciasteczka z rodzynkami Yamazaki z kawałkami czekolady i rodzynkami 130g",
                "5. Tong Garden Nutrione Pieczone Orzechy Koktajlowe 85g",
                "6. Malina K.Harrodsona",
                "7. Chrupiący owies Bonz",
                "8. Ciasteczka z serem Moore 50g",
                "9. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 952,
        "name": "Darla BBS",
        "handle": "darla",
        "description": "Ta zachwycająca kompozycja kwiatowa w stylowym kremowym pudełku przedstawia eleganckie białe gerbery, delikatne niebieskie róże i bujną zieleń. Idealny na każdą okazję, emanuje spokojnym i wyrafinowanym urokiem, dzięki czemu jest pięknym prezentem lub pięknym dodatkiem do każdej przestrzeni.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Darla-BBS-2a.jpg?v=1721620484",
            "/images/products/Darla-BBS-1a.jpg?v=1721620484"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Eustoma. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 953,
        "name": "Przepyszny asortyment HHP",
        "handle": "scrumptious-assortment-hhp",
        "description": "Scrumptious Assortment HHP Rozumiemy, że każdy ma inny gust i dlatego Scrumptious Assortment Hari Raya Hamper zawiera przysmaki dla smakoszy halal, które zadowolą każdego! Od słodkich po pikantne, delikatne po chrupiące – ten kosz upominkowy Hari Raya dotyka wszystkich podstaw swoimi pysznymi smakołykami, dzięki czemu Twoje wyjątkowe święto będzie niezapomnianym świętem.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "237.10",
        "inclTax": "237.10",
        "img": [
            "/images/products/Scrumptious-Assortment-HHP1A-258605.jpg?v=1700650616",
            "/images/products/Scrumptious-Assortment-HHP2A_6275f5ee-6b8f-4390-b0c0-7b1bd384749d-398047.jpg?v=1700650616"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Chipsy Farmland Thin Curv 160g",
                "2. Roladki z ciasta francuskiego Matilde Vicenzi nadziewane kremem z orzechów laskowych 125g",
                "3. Golden Bon Bon Miękki Nugat Migdałowy 70g",
                "4. Kawa Tchibo Golden Selection 50g",
                "5. Aalst- Migdałowa Czekolada Mleczna Puszka 150g",
                "6. Loackers Classic Cremkakao 225g",
                "7. Cud natury Fuzja Owoców i Orzechów 130g",
                "8. Orion Choco Pie Podwójna czekolada 12-tek",
                "9. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 954,
        "name": "Dorota",
        "handle": "dorothy",
        "description": "Zanurz się w wyrafinowaniu dzięki naszej wyjątkowej aranżacji składającej się z 10 luksusowych róż cappuccino elegancko umieszczonych w nieskazitelnym białym okrągłym pudełku. Ciepłe płatki w kolorze kawy tworzą urzekający kontrast wizualny z czystym białym tłem, oferując idealne połączenie elegancji i subtelności. Idealna do wyrażenia podziwu lub jako wyrafinowany prezent, ta kompozycja emanuje pięknem i urokiem w każdym starannie dobranym rozkwicie.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Dorothy1-208959.jpg?v=1675473763",
            "/images/products/Dorothy2-385415.jpg?v=1675473763"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "10 róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*20cm"
    },
    {
        "id": 955,
        "name": "Czekoladowe rozkosze HHP",
        "handle": "chocolate-delights-hhp",
        "description": "Chocolate Delights HHP Zachwyć swoją wyjątkową osobę koszem Chocolate Delights Hari Raya! Wyselekcjonowane czekoladowe smakołyki są umieszczane w wykwintnym koszu upominkowym, dzięki czemu ta koszyczka dla smakoszy halal zapada w pamięć. Zachwyć, zaimponuj i wywołaj ciepło tym przemiłym prezentem już dziś! ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Chocolate-Delights-HHP1A-744583.jpg?v=1701169122",
            "/images/products/Chocolate-Delights-HHP2A-115150.jpg?v=1701169122"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ciasteczka Waflowe Beryl’s Oblane Czekoladą Mleczną Gianduja 80g",
                "2. Napój kakaowy Van Houten 100g",
                "3. Orion Choco Pie Double Chocolate 12-tki",
                "4. Aalst Puszka Czekolady Owoce i Orzechy 150g",
                "5. Royal Select Musujący Sok Winogronowy 750ml",
                "6. Loacker Minis Napolitaner i Cremkakao 12szt x 10g",
                "7. Melvados- Brownie Kruche 100g",
                "8. Ciasteczka z podwójnymi kawałkami czekolady Beryl’s 100g",
                "9. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 956,
        "name": "Smak kolorów HHP",
        "handle": "flavour-of-colors-hhp",
        "description": "Flavour of Colors HHP Kosz dla smakoszy halal Flavour of Colors to asortyment zachwycających smakołyków zapakowanych w wykwintne pudełko upominkowe. Jego wyśmienite smakołyki z pewnością zachwycą miłośników słodyczy i wywołają uśmiech na ich twarzach! Spraw, aby ta Hari Raya była słodka, wysyłając swojej wyjątkowej osobie ten prezent smaków.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "282.74",
        "inclTax": "282.74",
        "img": [
            "/images/products/Flavour-of-Colors-HHP1-619827.jpg?v=1700650135",
            "/images/products/Flavour-of-Colors2_e9580a36-c0e7-44c6-a75d-0d5c49ef7ff1.jpg?v=1700625495"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pudełko upominkowe Golden Bon Bon Soft Almond Nougat 130g",
                "2. Ciasto francuskie Dahlsen Deloba 100g",
                "3. Ciasteczka maślane jagodowe K.Harrodson 180g",
                "4. Loackers Classic Fondente 225g",
                "5. Aalst- Owoce i Orzechy Czekolada Mleczna Puszka 150g",
                "6. Chipsy Ahoj! Oryginalne ciasteczka czekoladowe 228g",
                "7. Amazin’ Graze Przeciwutleniacz Mieszanka Orzechów 130g",
                "8. Dilmah Premium Pure Ceylon Torebki na herbatę 50g",
                "9. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 957,
        "name": "Błyszcząca niespodzianka HHP",
        "handle": "sparkling-surprise-hhp",
        "description": "Sparkling Surprise HHPBądź powodem, dla którego Twoi bliscy będą błyszczeć dzięki koszowi Sparkling Surprise Hari Raya! Zaskocz ich tym wykwintnym prezentem halal zawierającym słodkie i pikantne przekąski, które podtrzymają imprezę! Zapakowany w wykwintny kosz upominkowy z pewnością będzie atrakcją tej świątecznej uroczystości.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem halal"
        ],
        "price": "330.66",
        "inclTax": "330.66",
        "img": [
            "/images/products/Sparkling-Surprise-HHP1-678458.jpg?v=1700651491",
            "/images/products/Sparkling-Surprise2.jpg?v=1700626295"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Golden Bon Bon - chrupiące nugaty migdałowe w pudełku prezentowym 130g",
                "2. Mieszanka Orzechów Kokosowych Amazin’Graze Pandan 100g",
                "3. Royal Select Musujący Sok Winogronowy 750ml",
                "4. Bonz Czekoladowo-orzechowa przekąska ciasteczkowa 100g",
                "5. Cavendisha",
                "6. Gardenia Snack’Em Chipsy z soczewicy 50g",
                "7. Elit Bohem Wybór czekoladek 200g",
                "8. Gorące Kakao Swiss Miss Dark Chocolate Sensation 283g",
                "9. K.Harrodson Ciasteczka maślane malinowe 180g",
                "10. Delfi Migdałowa Czekolada Mleczna Czerwone Pudełko 180g",
                "11. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 958,
        "name": "Krzyś",
        "handle": "kris",
        "description": "Elegancja zajmuje centralne miejsce w tym czarującym bukiecie złożonym z dziewięciu delikatnych róż szampańskich otoczonych delikatnym urokiem kwiatów kaspii. Każda róża, symbol wdzięku i podziwu, jest starannie otoczona eterycznym pięknem kaspii, tworząc romantyczną i wyrafinowaną kompozycję, idealną na każdą wyjątkową okazję lub szczery gest.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kris-1aa-886988.jpg?v=1705156011",
            "/images/products/Kris-2aa-242523.jpg?v=1705156011"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 959,
        "name": "Lia",
        "handle": "lia",
        "description": "Zanurz się w uroku naszego bukietu składającego się z 9 urzekających fioletowych róż, delikatnie akcentowanych ponadczasowym pięknem kaspii i aromatycznym akcentem eukaliptusa. Królewskie odcienie fioletu uzupełniają subtelne faktury, tworząc harmonijną i wyrafinowaną aranżację.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Lia-2a.jpg?v=1720709359",
            "/images/products/Lia-1a.jpg?v=1720709359"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 961,
        "name": "Tangerine Grace FNB",
        "handle": "tangerine-grace-fnb",
        "description": "Tangerine GraceMłodzieńcze, zabawne i energiczne róże, spraye z goździkami, mattiole i zakonserwowane kwiaty ryżu w pudełku z kwiatami i balonami Tangerine Grace mieszają dzikość czerwieni z radością żółci! Energia tego wyjątkowego bukietu kwiatów łączy się z rustykalnymi kolorami balonów, aby przekazać nadzieję, szczęście i wdzięk w każdym pięknym kwiacie. ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na powitanie noworodka",
            "Bukiet balonów na zdrowie",
            "Bukiet balonów urodzinowych",
            "Balony i kwiaty gratulacyjne",
            "Balony i kwiaty na rocznicę",
            "Bukiet balonów na ukończenie studiów"
        ],
        "price": "383.15",
        "inclTax": "383.15",
        "img": [
            "/images/products/Tangerine-Grace-247340.jpg?v=1676885823",
            "/images/products/Tangerine-Grace1-512777.jpg?v=1676885823"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Róże",
                "2. Spray goździkowy",
                "3. Mattiola",
                "4. Eustoma",
                "5. Weronika",
                "6. Zachowany kwiat ryżu",
                "7. Liście Pittosporum",
                "8. Eukaliptus",
                "9. Spersonalizowany tekst na balonie z 4 mini balonami",
                "10. Drewniana skrzynia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 962,
        "name": "Ślub VIII",
        "handle": "bridal-viii",
        "description": "Do tej pięknej aranżacji ślubnej dołączony będzie wazon =)",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na wesele",
            "Kwiaty i bukiety na zaręczyny"
        ],
        "price": "410.53",
        "inclTax": "410.53",
        "img": [
            "/images/products/Bridal-_VIII_7715f377-230f-489b-ac46-ed78de592afe-608946.jpg?v=1677236803",
            "/images/products/Bridal-_VIII_2-304356.jpg?v=1677236803"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie kalii. Mattiolas. Róża"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 963,
        "name": "99 różowych róż",
        "handle": "99-pink-roses",
        "description": "Poczuj żywą elegancję naszego bukietu 99 gorących róż Zanurz się w żywej elegancji naszego niezwykłego bukietu składającego się z 99 olśniewających, gorących różowych róż, z wdziękiem otoczonych delikatnym, białym oddechem dziecka. Idealny na wielkie uroczystości, rocznice lub jako luksusowy wyraz miłości, ten bukiet emanuje energią i ponadczasowym urokiem. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [],
        "price": "981.03",
        "inclTax": "981.03",
        "img": [
            "/images/products/99-Pink-Roses1-619668.jpg?v=1681183829"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "99 gorących różowych róż. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 50cm*50cm"
    },
    {
        "id": 964,
        "name": "Obfity (II)",
        "handle": "bountiful-ii-1",
        "description": "Przekształć wszystkie wielkie otwarcia w eksplozję cytrusowej radości dzięki naszemu statywowi Vibrant Citrus Celebration. Ten żywy zespół, wyposażony w pomarańczowe opakowania w kratkę, balony i kwiaty pomarańczowego szampana, emanuje pozytywnym nastrojem, przygotowując grunt pod radosne i niezapomniane wydarzenie.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Bountiful-_II_-1b.jpg?v=1720101193",
            "/images/products/Grand-Opening-Card-2024_e990338c-85ea-403d-9a50-50667846a486.jpg?v=1720101201",
            "/images/products/Bountiful-_II_-2b.jpg?v=1720101193"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Gerbera . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 965,
        "name": "Obfity (III)",
        "handle": "bountiful-iii",
        "description": "Przyciągaj uwagę podczas wszystkich wielkich otwarciach biznesowych dzięki naszemu stojakowi trójnógowemu Radiant Red Bloom. Żywe czerwone balony uzupełniają szereg odważnych czerwonych kwiatów, elegancko owiniętych w dopasowane listwy przypodłogowe. Ta aranżacja jest potężnym symbolem pasji i rozkwitu nowych możliwości.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Bountiful-_III_-1b.jpg?v=1720100607",
            "/images/products/Grand-Opening-Card-2024_6c73d8b6-52c4-4ab3-9336-ef61005d7992.jpg?v=1720100614",
            "/images/products/Bountiful-_III_-2b.jpg?v=1720100607"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 966,
        "name": "Ayva",
        "handle": "ayva",
        "description": "Ogarnij klasyczny urok naszym bukietem składającym się z 6 namiętnych czerwonych róż ozdobionych delikatną i srebrzystą elegancją zakurzonego młynarza. Każda aksamitna czerwona róża uzupełniona jest delikatnymi, stonowanymi odcieniami zakurzonych liści młynarza, tworząc wyrafinowaną i atrakcyjną wizualnie kompozycję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Ayva-2a.jpg?v=1720691715",
            "/images/products/Ayva-1a.jpg?v=1720691715"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*36cm"
    },
    {
        "id": 967,
        "name": "Kondolencje (X)",
        "handle": "condolence-x",
        "description": "Jako wyraz współczucia, nasze statywy na kwiaty kondolencyjne przedstawiają czyste białe chryzantemy i delikatne lilie, tworząc pogodny i elegancki hołd. Takie rozwiązanie zapewnia pocieszenie i pocieszenie w chwilach głębokiego smutku, symbolizując współczucie i pamięć z wdziękiem i godnością.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Condolence-_X_-1b.jpg?v=1720138330",
            "/images/products/Condolence-Card-2024_1_3d66cf11-d8de-4787-b002-513396eabfa6.jpg?v=1720138349",
            "/images/products/Condolence-_X_-2b.jpg?v=1720138330"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Białe Lilie. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*170cm"
    },
    {
        "id": 968,
        "name": "Emma",
        "handle": "emma",
        "description": "Przedstawiamy nasz oszałamiający bukiet różowej hortensji, zachwycającą kompozycję, która ucieleśnia elegancję i urok. Ten czarujący bukiet zawiera harmonijną mieszankę delikatnych różowych hortensji, żywych różowych tulipanów i akcentów wdzięcznych, zakurzonych liści młynarza, tworząc urzekający efekt wizualny idealny na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Emma-1b.jpg?v=1720627881",
            "/images/products/Emma-2b.jpg?v=1720627882"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. 15 tulipanów. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 969,
        "name": "Kiko",
        "handle": "kiko",
        "description": "Kwitnąca życiem kreacja Flowers Poland przedstawia ciemnoczerwone róże i delikatne różowe kwiaty, delikatnie osadzone w rustykalnym koszu, wykończonym elegancką wstążką. To tętniące życiem święto naturalnego piękna i uczucia.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Kiko-2b-797894.jpg?v=1727349960",
            "/images/products/Kiko-1b.jpg?v=1727312794"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Jasne różowe róże. Goździki. Spryskaj różę. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*30cm"
    },
    {
        "id": 970,
        "name": "Skylar",
        "handle": "skylar",
        "description": "Zanurz się w żywej elegancji dzięki naszej efektownej aranżacji składającej się z 7 jaskraworóżowych róż, starannie przedstawionych w nieskazitelnym białym okrągłym pudełku. Odważne i żywe odcienie róż wyróżniają się na czystym białym tle, tworząc oszałamiający wizualnie obraz. Idealna do wyrażania odważnych uczuć lub jako elegancki i nowoczesny prezent, ta aranżacja emanuje pięknem i wyrafinowaniem w każdym urzekającym rozkwicie.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Skylar1_dbd52397-d0af-4be8-bbfe-90beb507c1c0-277020.jpg?v=1679436205",
            "/images/products/Skylar2_1-147629.jpg?v=1679436205"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Jasne różowe róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 971,
        "name": "ILU",
        "handle": "ilu-ws",
        "description": "Przedstawiamy nasz wykwintny bukiet składający się z trzech żywych czerwonych róż osadzonych w chmurze delikatnego różowego oddechu dziecka. Ta elegancka kompozycja jest pięknie opakowana w delikatny różowy papier, co czyni ją idealnym prezentem, aby wyrazić miłość i podziw.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/ILU-1a.jpg?v=1720528812",
            "/images/products/ILU-2a.jpg?v=1720528812"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 972,
        "name": "Jayla",
        "handle": "jayla",
        "description": "Rozkoszuj się subtelnym pięknem naszego bukietu składającego się z 5 uroczych róż z różowymi końcówkami, wdzięcznie akcentowanych delikatnymi kwiatami kaspii i misterną fakturą parvifolii. Idealny do wyrażania delikatnych uczuć przy różnych okazjach, ten bukiet stanowi zachwycające połączenie wdzięku i wyrafinowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Jayla-2b.jpg?v=1720965667",
            "/images/products/Jayla-1b.jpg?v=1720965667"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Różowe róże z końcówkami. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 973,
        "name": "Szmergiel",
        "handle": "emery-ws",
        "description": "Ten bukiet to pełna wdzięku kompozycja głęboko czerwonych róż, emanująca klasycznym romantyzmem, uzupełniona kępami białych kwiatów wosku i zielonych liści. Jest owinięty w delikatny różowy papier i przewiązany delikatną różową wstążką, tworząc uroczą prezentację idealną do wyrażania uczuć.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę"
        ],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Emery-1b-182769.jpg?v=1720551868",
            "/images/products/Emery-2b-165050.jpg?v=1720551868"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Wosk . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 974,
        "name": "Daebak zachwyca GHP",
        "handle": "daebak-delights-ghp",
        "description": "Daebak Delights GHP Przedstawiamy kosz dla smakoszy Daebak Delights, najlepsze połączenie koreańskiej kultury i wykwintnych przekąsek! Ten kosz zawiera ekscytującą grę w picie i różnorodne pyszne przekąski dla smakoszy, które pobudzą Twoje kubki smakowe. Idealne do przeżuwania podczas grania w pijacką grę z przyjaciółmi!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze korporacyjne"
        ],
        "price": "314.92",
        "inclTax": "314.92",
        "img": [
            "/images/products/Daebak-Delights_f94ca22f-ec9d-422f-8fbc-9c5b0ef10731-389294.jpg?v=1680572377",
            "/images/products/Daebak-Delights1_f8d63a84-7b4c-4ccd-a3dd-1f956b39aa6a-851232.jpg?v=1680572377"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. GB Roulette 16 Glass Lucky Shot Gra alkoholowa",
                "2. Jinro Soju Zielone Winogrono 360ml x 2",
                "3. Jinro Soju Truskawka 360ml x 2",
                "4. Werther’s Original Klasyczny Popcorn Karmelowy 140g",
                "5. Nibbles Premium Mieszane Orzechy 45g",
                "6. Hunter’s Gourmet Ręcznie Gotowane Chipsy Ziemniaczane Czarna Trufla 40g",
                "7. Przekąski Mieszane Camel Premium 150g",
                "8. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 975,
        "name": "Sofina",
        "handle": "sofina",
        "description": "„Czasami potrzeba złamanego serca, aby nas obudzić i pomóc nam zobaczyć, że jesteśmy warci o wiele więcej, niż się zadowalamy”. - Mandy Hale",
        "type": "Bukiety",
        "tags": [
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Sofina1A_7170b77a-c7ef-44b0-8b86-bc7b7e7a8d68-993300.jpg?v=1680573941",
            "/images/products/Sofina2A-459487.jpg?v=1680573941"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "9 białych róż. 9 Niebieskich Róż. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*40cm"
    },
    {
        "id": 976,
        "name": "Trufle Shuffle GHP",
        "handle": "truffle-shuffle-ghp",
        "description": "Truffle Shuffle GHPWypełniony najwyższej jakości smakołykami, które zachwycą nawet najbardziej wybredne podniebienia, ten wykwintny kosz upominkowy z pewnością zrobi wrażenie. Nasza Truffle Shuffle jest wypełniona bogatymi i odważnymi smakami, a także pikantnymi, słodkimi i orzeźwiającymi smakołykami i napojami, które są absolutnie idealne do dzielenia się. ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze korporacyjne"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Truffle-Shuffle1-300768.jpg?v=1680573941",
            "/images/products/Truffle-Shuffle2-267601.jpg?v=1680573941"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Esprit Woda Gazowana z Marakui i Sokiem Owocowym 300ml x 2",
                "2. Kawa Tchibo Gold Selection 50g",
                "3. Excelcium – Praliny BonBons De Chocolat 200g",
                "4. Tradycyjne Kruche Paluszki Duncan’s of Deeside 150g",
                "5. Godivine Mieszanka Orzechowa 80g",
                "6. Hamlet B",
                "7. Mini czekolada Quickbury z ciasteczkami z orzechami laskowymi 125g",
                "8. Hunter’s Gourmet Ręcznie Gotowane Chipsy Ziemniaczane Czarna Trufla 40g",
                "9. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 977,
        "name": "Biuro Oaza GHP",
        "handle": "office-oasis-ghp",
        "description": "Office Oasis GHPPraca i relaks spotykają się w tym oszałamiającym zestawie przekąsek! Office Oasis został zaprojektowany tak, aby robić dokładnie to, od czego pochodzi jego nazwa – pomóc Ci stworzyć uspokajające i wygodne środowisko na biurku. Zafunduj sobie dawkę kofeiny dzięki Starbucks Premium Mixes Coffè Mocha lub zrób sobie przerwę przy filiżance kojącej herbaty Juan Tea Osmanthus Oolong Tea. Dołączyliśmy kilka niezbędnych narzędzi, które sprawią, że Twoja praca będzie wygodniejsza i wydajniejsza, takich jak kubek na biurko, wentylator stacjonarny, nawilżacz, podstawka pod laptopa i wiele więcej!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze korporacyjne"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Office-Oasis1-995175.jpg?v=1680572759",
            "/images/products/Office-Oasis2-533657.jpg?v=1680572759"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Starbucks Premium Mieszanki Caffè Mocha 88g",
                "2. Zestaw herbat Juan Tea Osmanthus Oolong 74g",
                "3. Wentylator stacjonarny",
                "4. Regulowana podstawka do laptopa",
                "5. Nawilżacz",
                "6. Olejek eteryczny 10ml",
                "7. Kubek na biurko",
                "8. Huntleya",
                "9. Przekąska Prażone Orzechy Nerkowca 35g",
                "10. Wykwintne pudełko upominkowe (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 978,
        "name": "Hiacynta",
        "handle": "jacinta",
        "description": "„Nie można dać daru miłości, on czeka na przyjęcie”. – Rabindranath Tagore",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Jacinta1_6329c32d-b77d-4964-b133-a7dd2d0a9946-688089.jpg?v=1680747246",
            "/images/products/Jacinta2-384087.jpg?v=1680747246"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "25 Mentowych Róż. 25 białych róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 50cm*50cm"
    },
    {
        "id": 979,
        "name": "Greta",
        "handle": "greta",
        "description": "Ten bukiet to symfonia miękkiej elegancji, z udziałem różowych i kremowo białych róż osadzonych wśród delikatnego oddechu dziecka i zielonych liści. Jest pomysłowo owinięty w perłowy, holograficzny papier, który odbija światło, w połączeniu z satynową wstążką z serdecznym przesłaniem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Greta-2b.jpg?v=1720883426",
            "/images/products/Greta-1b.jpg?v=1720883426"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 980,
        "name": "Zelda",
        "handle": "zelda",
        "description": "Oszałamiająca kompozycja kwiatowa z zachwycającą mieszanką kwiatów, które tworzą harmonijną równowagę koloru i faktury. Żywe róże i goździki w odcieniach różu i pomarańczy emanują ciepłem i radością, a delikatniejsze odcienie towarzyszących im kwiatów dodają elegancji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Zelda-2b.jpg?v=1721058806",
            "/images/products/Zelda-1b.jpg?v=1721058806"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Spryskaj róże. Róża. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 981,
        "name": "99 białych róż",
        "handle": "99-white-roses",
        "description": "Zanurz się w zjawiskowej atmosferze naszego bukietu 99 białych róż. Zjawiskowa atmosfera dzięki naszemu bogatemu bukietowi składającemu się z 99 nieskazitelnych białych róż, elegancko otoczonych delikatną aureolą oddechu dziecka. Idealny na huczne uroczystości, wesela lub jako ekstrawagancki wyraz miłości, ten bukiet oddaje esencję ponadczasowego piękna i czaru. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "912.57",
        "inclTax": "912.57",
        "img": [
            "/images/products/Reia1A.jpg?v=1681093618"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "99 białych róż. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 50cm*50cm"
    },
    {
        "id": 982,
        "name": "Reia",
        "handle": "reia",
        "description": "Ten uroczy bukiet przedstawia harmonijne połączenie żywej magenty i delikatnych róż, uzupełnione delikatnymi miniróżowymi różami i bujną zielenią. Zapakowany w elegancki miętowo-zielony papier emanuje wyrafinowaniem i elegancją, dzięki czemu jest idealnym prezentem na każdą wyjątkową okazję lub aby umilić komuś dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Reia-2a-209656.jpg?v=1720789881",
            "/images/products/Reia-1a.jpg?v=1720742899"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spray do goździków. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 983,
        "name": "Obfitość (X)",
        "handle": "abundance-x",
        "description": "Odmień wielkie otwarcia biznesowe za pomocą naszego czarującego stojaka z kwiatami Lily Bloom. Sztuczne różowe lilie zajmują centralne miejsce, a towarzyszy im żywy zestaw kolorów z oszałamiającymi różowymi gerberami chmurzącymi się nad fioletowymi mamami, tworząc urzekający i urzekający wizualnie element centralny. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów w całej Polsce.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Abundance-_X_-1e.jpg?v=1720523717",
            "/images/products/Grand-Opening-Card-2024_10562e10-3a38-49f6-aa64-3178feca65b9.jpg?v=1720523723",
            "/images/products/Abundance-_X_-2e.jpg?v=1720523717"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Mamo. Goździki. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 984,
        "name": "Zofia",
        "handle": "sophie-1",
        "description": "Urzekający pokaz różnorodnych różowych goździków i kremowych róż, ten bukiet od Flowers Poland emanuje urokiem. Przewiązany delikatną różową wstążką, jest uosobieniem wdzięku i doskonałym gestem uczuć.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Sophie-2b.jpg?v=1721174398",
            "/images/products/Sophie-1b.jpg?v=1721174398"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 985,
        "name": "Alicja",
        "handle": "alice",
        "description": "Wyraź pasję za pomocą naszego bukietu czerwonych goździków i pomarańczowych róż w sprayu. Olśniewająca kompozycja złożona z dziewięciu żywych czerwonych goździków i promiennego uroku pomarańczowych róż w sprayu. Bukiet ten symbolizuje miłość, pasję i entuzjazm. Bogata czerwień i ciepłe odcienie pomarańczy tworzą oszałamiający kontrast, dzięki czemu jest to idealny prezent, aby wyrazić najgłębsze emocje i uczcić wyjątkowe okazje z polotem. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Alice-2b.jpg?v=1721174798",
            "/images/products/Alice-1b.jpg?v=1721174798"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 987,
        "name": "Usta jak cukier WGHP",
        "handle": "malesan-vin-de-france-rouge-with-chocolate-wghp",
        "description": "Lips Like Sugar Nieodparty i zachwycający, nasz koszyk na czekoladę i wino Lips like Sugar zawiera uroczą parę JP. Chenet Ice Edition Blanc 20cl i JP. Chenet Ice Edition Różowe wino musujące 20 cl. Ta skarbnica jest połączona z kremową belgijską czekoladą i zapakowana w eleganckie, ręcznie wykonane pudełko na kapelusze. Rozkoszuj się już dziś dostawą czekolady w Polsce!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Lips-Like-Sugar1-274383.jpg?v=1685991578",
            "/images/products/Lips-Like-Sugar2-450183.jpg?v=1685991578"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. JP. Chenet Ice Edition Blanc 20cl",
                "2. JP. Chenet Ice Edition Różowe wino musujące 20 cl",
                "3. Guylian Seashells Czekolada 250g",
                "4. Lindt Swiss Thin Dark 125g",
                "5. Wykwintne, ręcznie wykonane pudełko na kapelusze"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 988,
        "name": "Smak",
        "handle": "taste-colours-wghp",
        "description": "Taste Colors Wyślij to ukochanej osobie, aby upamiętnić każdą okazję! Pokaż im, jak bardzo Ci zależy, za pomocą butelki Chateau Fleurs Vignobles i kompozycji pięknych kwiatów.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "232.54",
        "inclTax": "232.54",
        "img": [
            "/images/products/Taste-_-Colours1-281802.jpg?v=1686079383",
            "/images/products/Taste-_-Colours2.jpg?v=1686008859"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zamek Fleurs Vignobles 75cl",
                "2. Wykwintna kompozycja kwiatowa",
                "3. Drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 989,
        "name": "Fleurs Vignobles z kwiatami WGHP",
        "handle": "travailleurs-with-flowers-wghp",
        "description": "Fleurs Vignobles z kwiatami Poczuj esencję wyrafinowania i luksusu dzięki naszemu zestawowi upominkowemu Fleurs Vignobles z kwiatami. Ta elegancka kolekcja obejmuje wyrafinowane Vino Tinto Fleurs Vignobles z Hiszpanii, w połączeniu z wykwintną kompozycją kwiatową. Ten zestaw upominkowy, zapakowany w pięknie wykonaną drewnianą skrzynkę ozdobioną wstążką, łączy w sobie bogate smaki doskonałego hiszpańskiego wina z delikatnym pięknem świeżych kwiatów, co czyni go idealnym wyborem do celebrowania wyjątkowych chwil lub wyrażania serdecznego uznania.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "232.54",
        "inclTax": "232.54",
        "img": [
            "/images/products/Travailleurs-with-Flowers1.jpg?v=1686009436",
            "/images/products/Travailleurs-with-Flowers2.jpg?v=1686009435"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Vino Tinto Fleurs Vignobles, Hiszpania, 75cl",
                "2. Wykwintna kompozycja kwiatowa",
                "3. Wykwintna drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 990,
        "name": "Czarująca elegancja WGHP",
        "handle": "enchanting-elegance-wghp",
        "description": "Czarująca elegancja Przedstawiamy doskonały zestaw wyrafinowanych przyjemności. Z kieliszkiem Moët i smakiem pysznej belgijskiej czekolady Twój odbiorca z pewnością będzie delektował się każdą chwilą. Już dziś wyślij koszyk Czarująca Elegancja bliskiej osobie!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "253.07",
        "inclTax": "253.07",
        "img": [
            "/images/products/Enchanting-Elegance1-420328.jpg?v=1686077639",
            "/images/products/Enchanting-Elegance2_dfdcea92-c60c-4289-9062-c529ba34dc10-241976.jpg?v=1686165800"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mini Moët 20cl",
                "2. Czekoladki Belgijskie Hamlet Asortyment 125g",
                "3. Delaviuda Bombones Ciemna Czekolada 150g",
                "4. Drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 991,
        "name": "Klejnot koronny WGHP",
        "handle": "crown-jewel-wghp-1",
        "description": "Klejnot koronny Prosty, ale prestiżowy kosz na czekoladę i wino, który lśni jak pożądany klejnot koronny. Kosz zawiera gładką Terre Forti Merlot w połączeniu z gładką i kremową czekoladą Guylian. Naprawdę wyrafinowany i stylowy prezent, który z pewnością zachwyci Twoje zmysły.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "253.07",
        "inclTax": "253.07",
        "img": [
            "/images/products/Crown-Jewel1-471209.jpg?v=1686077575",
            "/images/products/Crown-Jewel2-408349.jpg?v=1686077575"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Terre Forti Merlot 75cl",
                "2. Guylian Seashells Czekolada 250g",
                "3. Akai Bohshi Eleganckie Ciasteczka Różne 71g",
                "4. Wykwintne, ręcznie wykonane pudełko na kapelusze"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 992,
        "name": "Castelforte Corvina Veronese z Czekoladkami WGHP",
        "handle": "castelforte-corvina-veronese-with-chocolates-wghp",
        "description": "Castelforte Corvina Veronese z Czekoladkami",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "257.64",
        "inclTax": "257.64",
        "img": [
            "/images/products/Castelforte-Corvina-Veronese-with-Chocolates1_7d6e202a-da02-4433-836f-010ec7e34acd-530004.jpg?v=1686165649",
            "/images/products/Castelforte-Corvina-Veronese-with-Chocolates2-984506.jpg?v=1686098580"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Castelforte Corvina Veronese Indicazione Geografica Tipica, 75cl",
                "2. Delaviuda Bombones Ciemna Czekolada 150g",
                "3. Hamlet Różne Belgijskie Czekoladki 125g",
                "4. Drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 993,
        "name": "Pastelowa elegancja WGHP",
        "handle": "pastel-elegance-wghp",
        "description": "Pastelowa elegancjaTo wspaniały prezent wypełniony dwiema butelkami Noblesse, wspaniałą kompozycją kwiatową i podwójną skrzynką na wino.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "294.15",
        "inclTax": "294.15",
        "img": [
            "/images/products/Pastel-Elegance1.jpg?v=1686012826",
            "/images/products/Pastel-Elegance2-409949.jpg?v=1686077997"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Noblesse Merlot, Chile 75cl",
                "2. Noblesse Sauvignon Blanc, Chile 75cl",
                "3. Wykwintna kompozycja kwiatowa",
                "4. Podwójna skrzynka na wino"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 994,
        "name": "Pakiet Radiant z Flower WHP",
        "handle": "radiant-bundle-with-flower-whp",
        "description": "Radiant Bundle with Flower Przedstawiamy Radiant Bundle with Flower, nasz luksusowy zestaw wellness, który został zaprojektowany, aby odżywiać ciało i umysł ukochanej osoby. Wyposażony w najwyższej jakości, poprawiające zdrowie gadżety, ten koszyk jest idealnym prezentem dla każdego, kto dba o swoje dobre samopoczucie. Od Ptasiego Gniazda z żeń-szeniem po Esencję Jagodową Fomec – nasz koszyk wellness zawiera szeroki wybór smakołyków poprawiających zdrowie.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Radiant-Bundle-with-Flower1-349216.jpg?v=1687302100",
            "/images/products/Radiant-Bundle-with-Flower2-313355.jpg?v=1687302100"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "2. Napój Yu Xiang Yan White Fungas Bird’s Nest 3 x 75ml",
                "3. Esencja Kurczaka NewMoon 2 x 68ml",
                "4. Esencja Jagodowa Fomec 2 x 42ml",
                "5. Kompozycja z różnych kwiatów",
                "6. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 995,
        "name": "Przepis na Zdrowie Z Kwiatowym WHP",
        "handle": "recipe-of-health-with-flower-whp",
        "description": "Przepis na zdrowie z kwiatemPrzywitaj się z przepisem na zdrowie z kwiatem, starannie dobranym zestawem wellness zawierającym zdrowe smakołyki najwyższej jakości, które promują ogólny stan zdrowia i dobre samopoczucie. Ten kosz zawiera prezent Kinohimitsu Ptasie Gniazdo i zestaw upominkowy Kinohimitsu Essence of Chicken, które pomogą przywrócić ukochanej osobie to, co najlepsze. Idealny dla tych, którzy chcą priorytetowo traktować dbanie o siebie i dobre samopoczucie, ten kosz jest najlepszym prezentem dla ukochanej osoby.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Recipe-of-Health-With-Flower1-768595.jpg?v=1687302100",
            "/images/products/Recipe-of-Health-With-Flower2-126497.jpg?v=1687302100"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy Kinohimitsu Ptasie Gniazdo 8 x 75 gl",
                "2. Zestaw upominkowy Kinohimitsu Esencja Kurczaka 6 x 75g",
                "3. Kompozycja z różnych kwiatów",
                "4. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 996,
        "name": "Berry Blissful WHP",
        "handle": "nature-palate-with-flower-whp",
        "description": "Berry BlissfulTa zachwycająca kosmetyczka Berry Blissful jest jedną z naszych ulubionych i nie bez powodu! Został zaprojektowany, aby rozkoszować zmysły, jednocześnie odżywiając ciało, dzięki takim smakołykom, jak zestaw herbat Juan Tea Indulgence Floral Blend w torebce, mieszanka przeciwutleniająca Amazin' Graze Berry Trail Mix, ciastko migdałowe Godivine Cranberry i ptasie gniazdo Kinohimitsu z Longan Wolfberry. Ten bogaty w przeciwutleniacze kosz jest idealny dla osób poszukujących równowagi dobrego samopoczucia i smaku.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "175.49",
        "inclTax": "175.49",
        "img": [
            "/images/products/Berry-Blissful1-736475.jpg?v=1687301502",
            "/images/products/Berry-Blissful2-291253.jpg?v=1687301502"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw herbat Juan Tea Indulgence z mieszanką kwiatową, 74 g",
                "2. Mieszanka antyoksydacyjna Amazin’ Graze Berry Trail 130g",
                "3. Godivine Ciastko Migdałowo-Żurawinowe 70g",
                "4. Ptasie gniazdo Kinohimitsu z Longanem",
                "5. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 997,
        "name": "Skarb Wellness WHP",
        "handle": "treasure-of-wellness-whp",
        "description": "Skarb dobrego samopoczucia",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Treasure-of-Wellness-NEW1.jpg?v=1711600130",
            "/images/products/Treasure-of-Wellness-NEW2.jpg?v=1711600130"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy Kinohimitsu Birds Nest 8 x 75g",
                "2. Esencja kurczaka w nowiu z kordycepsem",
                "6 x 68ml",
                "3. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 998,
        "name": "Teora",
        "handle": "theora",
        "description": "Rozjaśnij każdą przestrzeń dzięki tej promiennej kompozycji słoneczników i pastelowych kwiatów. Słoneczniki zajmują centralne miejsce, otoczone delikatnymi białymi i delikatnymi różowymi różami, dodając odrobiny elegancji. Ten bukiet jest idealny, aby wnieść odrobinę słońca i uroku do każdego pomieszczenia. Zapakowany w elegancki szklany wazon z ozdobną wstążką, będzie przemyślanym prezentem na każdą okazję.",
        "type": "Vase Arrangement",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Theora-2a-136836.jpg?v=1724160547",
            "/images/products/Theora-1a-802766.jpg?v=1724160547"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Spryskaj róże. Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 999,
        "name": "Fioletowy",
        "handle": "violet",
        "description": "Uchwyć esencję uroczej wiejskiej łąki dzięki temu zachwycającemu bukietowi stokrotek, oddechu dziecka i lawendy. Delikatne biele i wesołe żółcie, uzupełnione gałązkami zieleni, tworzą pogodny i orzeźwiający obraz. Umieszczony w rustykalnym szklanym słoju i ozdobiony wstążką, ten bukiet wnosi odrobinę naturalnego piękna i spokoju do każdego miejsca, idealny na każdą okazję.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Violet-2a.jpg?v=1724124768",
            "/images/products/Violet-1a.jpg?v=1724124768"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Stokrotka. Oddech dziecka. Suszona lawenda. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 1000,
        "name": "Klementyna",
        "handle": "clementine",
        "description": "„Nie pamiętamy dni, pamiętamy chwile”. - Cesare Pavese",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Clementine1A_a8b65bcf-cede-42f3-b2cb-a0103e2760ac-622539.jpg?v=1689987317",
            "/images/products/Clementine2A-517896.jpg?v=1689987317"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustomy . Gossypium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 25cm*25cm"
    },
    {
        "id": 1001,
        "name": "Eudora",
        "handle": "eudora",
        "description": "„Nie pamiętamy dni, pamiętamy chwile”. - Cesare Pavese",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Eudora1_b7ce4e43-b8af-4f56-a41e-bc1a97b2f95b-506097.jpg?v=1689987372",
            "/images/products/Eudora2-813473.jpg?v=1689987372"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 25cm*25cm"
    },
    {
        "id": 1002,
        "name": "Akari FBS",
        "handle": "akari",
        "description": "Ta elegancka kompozycja z naszej charakterystycznej serii torebek zawiera żywą różową różę, delikatne różowe róże w sprayu, fioletową mattiolę i bujną zieleń, a wszystko to pięknie zaprezentowane w stylowej różowej torbie. Idealny na każdą okazję, dodaje elegancji i radości każdemu otoczeniu.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Akari-FBS-1a-569030.jpg?v=1720116838",
            "/images/products/Akari-FBS-2a.jpg?v=1720056099"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj różę. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 1003,
        "name": "Aksana FBS",
        "handle": "aksana",
        "description": "Poczuj elegancję dzięki naszej torebce w kolorze różu i białych kwiatów w kolorze cappuccino. Ta elegancka kompozycja składa się z róż w kolorze cappuccino, delikatnych białych kwiatów i bujnej zieleni, a wszystko to pięknie zaprezentowane w stylowej różowej torbie z czarnymi uchwytami. Idealny na każdą okazję, wnosi odrobinę wyrafinowania i radości do każdego otoczenia. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Seria Torebek",
        "tags": [],
        "price": "79.64",
        "inclTax": "79.64",
        "img": [
            "/images/products/Aksana1-233833.jpg?v=1690502774",
            "/images/products/Aksana2_625eb653-e696-48ba-8173-75248520709c-652913.jpg?v=1690591819"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj różę. Mattiola. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 1004,
        "name": "Balon Wellness dla dzieci HHP",
        "handle": "kids-wellness-balloon-hhp",
        "description": "Balon Wellness dla dzieci HHP Rozumiemy, że wszyscy rodzice chcą dla swoich dzieci wszystkiego, co najlepsze, dlatego stworzyliśmy koszyk na balony Wellness dla dzieci Get Well Soon, będący asortymentem zdrowych przekąsek, dzięki którym czas na przekąskę będzie zarówno przyjemny, jak i zdrowy! Spraw, aby dziecko uśmiechnęło się już dziś dzięki temu wesołemu prezentowi „Wyzdrowiej”!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kwiaty na zdrowie i bukiety",
            "Kosze z jedzeniem halal"
        ],
        "price": "180.05",
        "inclTax": "180.05",
        "img": [
            "/images/products/Kids-Wellness-_-Balloon1_e9d37113-74b1-4f93-9594-9b36dcff597b-137879.jpg?v=1690592655",
            "/images/products/Kids-Wellness-_-Balloon2_58e17000-33f0-4d11-b16b-c688ac439c32-192125.jpg?v=1690592655"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ciastko Kanapkowe Oreo 266g",
                "2. Ciasteczka owsiane z kawałkami czekolady Cowhead 178g",
                "3. Kellogg’s Froot Loops 160g",
                "4. Farmhouse Świeże Mleko UHT 1L",
                "5. Napój pakowany Ribena Blackcurrent 2 x 200ml",
                "6. Napoje Pakowane Milo 2 x 200ml",
                "7. Owocowe chipsy truskawkowe",
                "8. Różne balony bez helu",
                "9. Wykwintny kosz upominkowy (S)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 1005,
        "name": "Janet",
        "handle": "janet",
        "description": "Ten miękki i romantyczny bukiet zawiera delikatne połączenie pastelowych róż, lisianthus i innych kwiatów, zawinięte w elegancki papier. Delikatna mieszanka jasnych fioletów, różów i bieli tworzy spokojną i pełną wdzięku kompozycję, idealną do wyrażenia miłości, współczucia lub świętowania specjalnej okazji. Bukiet ten wnosi odrobinę delikatności i spokoju do każdego otoczenia, dzięki czemu jest idealnym wyborem na te cenne chwile.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Janet-2a.jpg?v=1724818487",
            "/images/products/Janet-1a.jpg?v=1724818487"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustomy . Mattiolas. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*28cm"
    },
    {
        "id": 1006,
        "name": "Alberty",
        "handle": "alberta",
        "description": "Świętuj radość dzięki naszemu żywemu bukietowi kwiatów mieszanych w kształcie stożka. Ten żywy bukiet w kształcie stożka składa się z zachwycającej mieszanki czerwonych i brzoskwiniowych róż w sprayu, delikatnych stokrotek i kwiatów w pastelowych kolorach, a wszystko to zawinięte w elegancki papier rzemieślniczy w kolorze kości słoniowej. Idealny na każdą uroczystość, wnosi kolor i radość w każdą okazję. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Alberta-2a-649818.jpg?v=1724863709",
            "/images/products/Alberta-1a.jpg?v=1724817637"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Spryskaj róże. Eustomy . Mattiolas. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 18cm*28cm"
    },
    {
        "id": 1007,
        "name": "Ożywić",
        "handle": "ginger",
        "description": "Ten czarujący bukiet składa się z zachwycającej mieszanki róż w kolorze cappuccino, matowych pomarańczowych róż w sprayu i delikatnych kwiatów rumianku, a wszystko to pięknie ułożone z bujną zielenią. Ozdobiony rustykalnym akcentem w kształcie gałązki w kształcie serca i owinięty miękkim papierem w kolorze kości słoniowej, idealnie nadaje się do wyrażania miłości i uznania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Ginger-1a.jpg?v=1720889276",
            "/images/products/Ginger-2a.jpg?v=1720889276"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Mattiolas. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1008,
        "name": "Wrotycz pospolity",
        "handle": "tansy-1",
        "description": "Rozjaśnij swój dzień naszym wesołym bukietem słonecznikówTen bukiet emanuje radością z dużym, promiennym słonecznikiem w sercu, otoczonym fioletowymi goździkami, słonecznymi żółtymi różami w sprayu oraz delikatną białą kaspią i stokrotkami. Ustawiony na tle bogatej zieleni liści i owinięty w gustowny jasnofioletowy papier, jest idealny na każdą okazję. Złóż zamówienie już teraz i ciesz się darmową dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Tansy-1e-403225.jpg?v=1720791509",
            "/images/products/Tansy-1d-478137.jpg?v=1720792169"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1009,
        "name": "Gisele",
        "handle": "gisele",
        "description": "Ten żywy bukiet przedstawia zachwycającą mieszankę kwiatów w różnych odcieniach różu, fioletu i żółci. Zawiera róże, goździki, stokrotki i efektowną żółtą gerberę, uzupełnioną delikatną zielenią. Zapakowany w miękkie pastelowe papiery i przewiązany opalizującą wstążką, emanuje radosną i elegancką atmosferą, idealną na każdą uroczystość.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Gisele-2a.jpg?v=1721175019",
            "/images/products/Gisele-1a.jpg?v=1721175019"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Mattiolas. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1010,
        "name": "Ślub IX",
        "handle": "bridal-ix",
        "description": "Do tej pięknej aranżacji ślubnej dołączony będzie wazon =)",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na wesele",
            "Kwiaty i bukiety na zaręczyny"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Bridal-IX1B-516136.jpg?v=1692585360",
            "/images/products/Bridal-IX2B-872518.jpg?v=1692585360"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie kalii. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1011,
        "name": "Everly II",
        "handle": "everly-ii",
        "description": "Żywe różowe goździki wyłaniają się wśród delikatnej zieleni, otoczone delikatnym różem i akcentowane odważną czerwoną wstążką – charakterystyczny element Flowers Poland, który jest jednocześnie porywający i elegancki.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Everly-II-2b-778355.jpg?v=1720788918",
            "/images/products/Everly-II-1b.jpg?v=1720690558"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1012,
        "name": "Roksana",
        "handle": "roxanne",
        "description": "W tej pełnej wdzięku aranżacji wazonowej znajdują się nieskazitelnie białe tulipany, uzupełnione wesołymi stokrotkami i delikatnymi gałązkami zieleni. Świeża i czysta paleta tworzy bukiet emanujący prostotą i elegancją, idealny na każdą okazję, w której pożądana jest nuta naturalnego piękna. Niezależnie od tego, czy będzie to przemyślany prezent, czy spokojny dodatek do Twojej przestrzeni, ten bukiet wnosi poczucie spokoju i wyrafinowania do każdego otoczenia.",
        "type": "Vase Arrangement",
        "tags": [
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Roxanne-2a-359305.jpg?v=1724688263",
            "/images/products/Roxanne-1a.jpg?v=1724637577"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "13 tulipanów. Stokrotka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 1013,
        "name": "Cenne Cheruby BHP",
        "handle": "cherished-cherubs-bhp",
        "description": "Cherished Cherubs Nasze urocze Cherished Cherubs są wyposażone w naszą torebkę na pieluchy Baby K Signature i 4-częściowy zestaw upominkowy Romper Baby K, a także dodaliśmy odżywczy zestaw Ptasiego Gniazda z cukrem żeń-szenia dla rodziców!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Cherished-Cherubs-368957.jpg?v=1698138070",
            "/images/products/Cherished-Cherubs1.jpg?v=1698110920"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "2. Torba na pieluchy Baby K Signature",
                "3. Zestaw upominkowy dla niemowląt K",
                "4. Wykwintna drewniana skrzynia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 1014,
        "name": "Baby Gym Club BHP",
        "handle": "baby-gym-club-bhp",
        "description": "Baby Gym ClubKosz upominkowy Baby Gym Club to idealny prezent dla ciekawskich dzieci! Do zestawu dołączyliśmy matę gimnastyczną do zabawy z pianinem, która zapewni zabawę nawet najbardziej aktywnym dzieciom. Inne niezbędne artykuły w koszyku to organiczne myjki bambusowe firmy BabyK i pieluchy Drypers, które zapewnią bezproblemową zabawę!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "271.33",
        "inclTax": "271.33",
        "img": [
            "/images/products/Baby-Gym-Club_8530bf6b-b81e-42d2-9bcc-1a9881454457-893725.jpg?v=1698137885",
            "/images/products/Baby-Gym-Club1_ca802f2d-7966-43c0-a2e0-12ffa66579aa-555200.jpg?v=1698137885"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mata gimnastyczna dla niemowląt IMBABY do gry na pianinie",
                "2. Zestaw upominkowy dla niemowląt K",
                "3. Myjka Baby K- Organic Bamboo 6 szt",
                "4. Organiczne chusteczki dla niemowląt KinderNurture Lata 80-te",
                "5. Suszarki Wee Wee Dry Pieluszki M (4szt.) x 2 opakowania",
                "6. Wykwintne pudełko upominkowe (L)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 1015,
        "name": "Ząbkowy GHP",
        "handle": "toothsome-ghp",
        "description": "Smaczny ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "262.20",
        "inclTax": "262.20",
        "img": [
            "/images/products/Toothsome-GHP1.jpg?v=1707107383",
            "/images/products/Toothsome-GHP2.jpg?v=1707107383"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Casa de Campo Premium Cabernet Sauvignon 75cl",
                "2. Wernli Chocofin 100g",
                "3. Miniprzekąska Vicenzi z kremem cukierniczym 75g",
                "4. Nairns Bezglutenowe Ciasteczka Owsiane Solony Karmel 160g",
                "5. Kubeczki deserowe Cupido Tiramisu 125g",
                "6. La Mere Poulard Vintage Les Sables Francuskie ciasteczka maślane 46,8 g",
                "7. Hunters Gourmet Ręcznie Gotowane Chipsy Ziemniaczane Czarna Trufla 40g",
                "8. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1016,
        "name": "Ostrokrzew",
        "handle": "holly",
        "description": "„Dary czasu i miłości to z pewnością podstawowe składniki naprawdę wesołych Świąt”. – Peg Bracken",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Holly-1a-347694.jpg?v=1700563338",
            "/images/products/Holly-2a-112121.jpg?v=1700563338"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Szyszka sosnowa. Liść Sosny."
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1017,
        "name": "Płatek śniegu",
        "handle": "snowflake",
        "description": "„Dary czasu i miłości to z pewnością podstawowe składniki naprawdę wesołych Świąt”. – Peg Bracken",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Snowflake-1b-119344.jpg?v=1700564513",
            "/images/products/Snowflake-2b.jpg?v=1700551375"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Szyszka sosnowa. Liść Sosny."
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1018,
        "name": "Xyla",
        "handle": "xyla",
        "description": "„Dary czasu i miłości to z pewnością podstawowe składniki naprawdę wesołych Świąt”. – Peg Bracken",
        "type": "Bukiety",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Xyla-1a.jpg?v=1700551289",
            "/images/products/Xyla-2a.jpg?v=1700551289"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Szyszka sosnowa. Liść Sosny."
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1019,
        "name": "Gwen",
        "handle": "gwen",
        "description": "Poczuj ponadczasowy urok naszego bukietu składającego się z 12 ciemnoczerwonych róż, starannie ułożonych w nieskazitelnie białym, okrągłym pudełku. Bogate czerwone odcienie róż pięknie kontrastują z czystym, eleganckim tłem, tworząc efektowną wizualnie kompozycję. Idealna do wyrażenia miłości i pasji lub jako wyrafinowany prezent, ta aranżacja ucieleśnia zarówno klasyczny romans, jak i nowoczesną estetykę, zaprezentowana w stylowym białym okrągłym pudełku.",
        "type": "Flower Boxes",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Gwen-2a-246230.jpg?v=1724158555",
            "/images/products/Gwen-1a-904286.jpg?v=1724158555"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "12 róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*20cm"
    },
    {
        "id": 1020,
        "name": "Różowa poinsecja",
        "handle": "pink-poinsettia",
        "description": "„Bo dając, otrzymujemy”. – Franciszek z Asyżu",
        "type": "Bukiety",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Pink.P1-711671.jpg?v=1701688968"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Poinsecja"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1021,
        "name": "Biała Poinsecja",
        "handle": "white-poinsettia",
        "description": "„Bo dając, otrzymujemy”. – Franciszek z Asyżu",
        "type": "Bukiety",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/White.P1-180282.jpg?v=1701690040"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Poinsecja"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1022,
        "name": "Germaine'a",
        "handle": "germaine1",
        "description": "Ten elegancki bukiet składa się z nieskazitelnie białych tulipanów, emanujących czystością i wdziękiem. Owinięty w miękki papier w neutralnej tonacji, tworzy ponadczasowy i wyrafinowany wygląd. Idealny na wesela, rocznice lub każdą okazję, podczas której chcesz podkreślić elegancję i prostotę. Piękny wybór na każdy prezent kwiatowy.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Germaine-1a.jpg?v=1720777146",
            "/images/products/Germaine-2a.jpg?v=1720777146"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1023,
        "name": "Edyta",
        "handle": "edith",
        "description": "Ten uroczy bukiet składa się z trzech delikatnych różowych stokrotek Gerbera, zaakcentowanych bujną zielenią i białym oddechem dziecka, owiniętych w elegancki brązowy papier. Idealny na każdą okazję, wnosi odrobinę świeżości i uroku, aby rozjaśnić czyjś dzień.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety z gipsówki"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Edith-1c.jpg?v=1720661652",
            "/images/products/Edith-2c-589226.jpg?v=1720788754"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1024,
        "name": "Giulia",
        "handle": "giuliana",
        "description": "Ten żywy bukiet składa się z promiennych słoneczników, efektownych niebieskich hortensji i żywych pomarańczowych róż, a wszystko to uzupełnione świeżą zielenią. Owinięty w ziemisty brązowy papier, oddaje esencję radości i ciepła, idealny do rozjaśnienia każdej przestrzeni i okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety hortensji",
            "Bukiety słoneczników"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Giuliana-2a_b51b8952-de04-4323-9f0e-59b25425bb85.jpg?v=1721360947",
            "/images/products/Giuliana-1a.jpg?v=1721360947"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Słonecznik. Spryskaj róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1025,
        "name": "Czerwona poinsecja",
        "handle": "red-poinsettia",
        "description": "„Bo dając, otrzymujemy”. – Franciszek z Asyżu",
        "type": "Bukiety",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Red-Poinsettia-1a-309651.jpg?v=1702381902",
            "/images/products/Red-Poinsettia-2a-228335.jpg?v=1702381902"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Poinsecja"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1026,
        "name": "Camerona",
        "handle": "cameron",
        "description": "Ta elegancka kompozycja łączy w sobie delikatne różowe goździki, miękkie metaróże, kremowobiałe kwiaty i bujną zieleń, akcentowaną puszystą chryzantemą i gałązkami oddechu dziecka. Umieszczony w eleganckim czarnym pudełku na kapelusze ozdobionym różową satynową wstążką, ten bukiet emanuje wyrafinowaniem i wdziękiem, co czyni go idealnym wyborem do celebrowania wyjątkowych chwil lub dodawania odrobiny piękna do każdej przestrzeni.",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Cameron-2a-212193.jpg?v=1726200286",
            "/images/products/Cameron-1a-520281.jpg?v=1726200287"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Goździki. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 1027,
        "name": "Obfity (IV)",
        "handle": "bountiful-iv",
        "description": "Dodaj uroku każdemu wielkiemu otwarciu firmy dzięki naszemu statywowi z zawieszką w kształcie kwiatu wiśni. Czerwone róże i gerbera łączą się, tworząc zachwycającą kompozycję, symbolizującą urok i sukces Twojego przedsięwzięcia.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Bountiful-_IV_-1a.jpg?v=1720104372",
            "/images/products/Grand-Opening-Card-2024_d709c93c-f9b0-4621-9c27-29ddf7f5bed1.jpg?v=1720104383",
            "/images/products/Bountiful-_IV_-2a.jpg?v=1720104372"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Gerbera . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*170cm"
    },
    {
        "id": 1028,
        "name": "Obfity (V)",
        "handle": "bountiful-v",
        "description": "Zapewnij poczucie wyrafinowania podczas wszystkich wielkich wernisaży dzięki naszym stojakom na trójnogi z kwiatami, ozdobionym urzekającym motywem niebieskiej sztucznej hortensji i pięknymi listwami przypodłogowymi. Promieniująca elegancją aranżacja ta oznacza dobrobyt i nowy początek, wzmacniając atmosferę wyjątkowej okazji odrobiną ponadczasowego piękna i stylu.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Bountiful-_V_-1d.jpg?v=1720104522",
            "/images/products/Grand-Opening-Card-2024_35d45e90-468a-42cf-bb04-8f340732c6a6.jpg?v=1720104533",
            "/images/products/Bountiful-_V_-2d.jpg?v=1720104522"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Sztuczne hortensje. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1029,
        "name": "Krzyż (ja)",
        "handle": "cross-i",
        "description": "Wyraź szczere współczucie za pomocą naszych statywów z kwiatami kondolencyjnymi. Nienagannie ukształtowane w kształcie krzyża, nieskazitelnie białe chryzantemy tworzą pogodny i dostojny obraz. Ta elegancka aranżacja służy jako symbol pocieszenia, oddając cześć zmarłym i oferując pocieszenie w chwilach smutku.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Cross-_I_-1d.jpg?v=1720091298",
            "/images/products/Condolence-Card-2024_1_b532860e-7e5d-46a0-a692-0e3959ec0549.jpg?v=1720091304",
            "/images/products/Cross-_I_-2d.jpg?v=1720091298"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Lilie . Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1030,
        "name": "Krzyż (II)",
        "handle": "cross-ii",
        "description": "W chwilach smutku nasze statywy z kwiatami kondolencyjnymi oferują pocieszenie i łaskę. Nieskazitelne czarne chryzantemy, ukształtowane w spokojny krzyż, tworzą godny i serdeczny hołd. Ta elegancka aranżacja symbolizuje współczucie i składa pełen szacunku hołd zmarłym, zapewniając pocieszenie w chwilach żałoby.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Cross-_II_-1c.jpg?v=1720091650",
            "/images/products/Condolence-Card-2024_1_94e2360a-a2a1-4049-91d9-5964e9820533.jpg?v=1720091657",
            "/images/products/Cross-_II_-2c.jpg?v=1720091649"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Lilie . Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1031,
        "name": "Krzyż (III)",
        "handle": "cross-iii",
        "description": "Przekaż najszczersze kondolencje za pomocą naszego statywu kwiatowego z białymi chryzantemami i delikatnymi różowymi eustomami misternie ukształtowanymi w krzyż. Ta elegancka aranżacja zapewnia pocieszenie, delikatny wyraz współczucia i pocieszający hołd dla zmarłych w chwilach smutku i pamięci.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Cross-_III_-1f.jpg?v=1724292964",
            "/images/products/Condolence-Card-2024_1_6b3b98ae-8847-424c-a3e0-ee1fbd0cc74e.jpg?v=1724292970",
            "/images/products/Cross-_III_-2f.jpg?v=1724292964"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Lilie . Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1032,
        "name": "Krzyż (IV)",
        "handle": "cross-iv",
        "description": "Wyraź najgłębsze współczucie za pomocą naszego statywu z kwiatami kondolencyjnymi, ozdobionego białymi chryzantemami i eustomami szampańskimi, delikatnie ukształtowanymi w krzyż. Ta elegancka aranżacja oferuje pocieszenie i jest wdzięcznym hołdem symbolizującym współczucie i pamięć w chwilach smutku i straty.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Cross-_IV_-1c.jpg?v=1720091968",
            "/images/products/Condolence-Card-2024_1_af1a922b-5de7-40e9-8c30-aab249b962c6.jpg?v=1720091973",
            "/images/products/Cross-_IV_-2c.jpg?v=1720091969"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Lilie . Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1033,
        "name": "Bogactwo (IX)",
        "handle": "wealth-ix",
        "description": "Poczuj egzotyczną elegancję dzięki naszemu statywowi Exotic Elegance. Połączenie czerwonych róż, kwiatów imbiru i helikonii tworzy oszałamiającą wizualnie wystawę, która oznacza wyrafinowanie i sukces w przypadku wszystkich wielkich otwarć firm.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Wealth-_IX_-1d-235477.jpg?v=1720141035",
            "/images/products/Grand-Opening-Card-2024_50f0f236-6a14-48f3-ac47-f121fc4b04cc.jpg?v=1720141035",
            "/images/products/Wealth-_IX_-2d-853035.jpg?v=1720118593"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Eustoma. Mattiola. Anturium . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1034,
        "name": "Sprzedam Wishful Oranges CNY",
        "handle": "心想事橙-wishful-oranges",
        "description": "心想事橙 Pomarańcze życzeńW każdej pomarańczy odkryj radość nadziei i słodycz sukcesu. Niech te osiem mandarynek przyniesie dobrobyt i spełni pragnienia twojego serca.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Wishful-Oranges1_c58aed85-17e9-4fb6-bb5b-144d60d511c7-739667.jpg?v=1704291411",
            "/images/products/Wishful-Oranges2_1d3621ea-0b5b-44fd-9cea-567303a6211a-727447.jpg?v=1704291411"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 8",
                "2. Okrągłe kremowe pudełko Bloom – S"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 1035,
        "name": "Sprzedam Bountiful Oranges CNY",
        "handle": "大吉大利-bountiful-oranges",
        "description": "大吉大利 Bountiful Oranges Osiemnaście pomarańczy symbolizujących obfite błogosławieństwa. Niech te obfite żniwa przyniosą ogromną radość i szczęście we wszystkich waszych przedsięwzięciach. ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Bountiful-Oranges1_7d2c08de-f973-4c59-940b-99669230c2ec-835728.jpg?v=1704291411",
            "/images/products/Bountiful-Oranges2-855091.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 18",
                "2. Czerwone pudełko transportowe"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 1036,
        "name": "Harmonia (III)",
        "handle": "harmony-iii",
        "description": "Świętuj triumf dzięki naszemu stojakowi kwiatowemu Majestic Fusion ze słonecznikami i żywymi czerwono-żółtymi gerberami. Ta urzekająca mieszanka symbolizuje dobrobyt i entuzjazm, tworząc wielkie oświadczenie otwierające, które rezonuje z elegancją i sukcesem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_III_-1b.jpg?v=1720102599",
            "/images/products/Grand-Opening-Card-2024_a6d5d73c-8a97-4497-994c-55f4b7a44b59.jpg?v=1720102611",
            "/images/products/Harmony-_III_-2b.jpg?v=1720102599"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Gerbery . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1037,
        "name": "Harmonia (IV)",
        "handle": "harmony-iv",
        "description": "Świętuj triumf dzięki naszemu stojakowi kwiatowemu Majestic Fusion z żywymi czerwonymi pomarańczowymi gerberami. Ta urzekająca mieszanka symbolizuje dobrobyt i entuzjazm, tworząc wielkie oświadczenie otwierające, które rezonuje z elegancją i sukcesem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_IV_-1b.jpg?v=1720102758",
            "/images/products/Grand-Opening-Card-2024_0aa23260-7a2a-4408-8bd6-1a8acce23ee7.jpg?v=1720102763",
            "/images/products/Harmony-_IV_-2b.jpg?v=1720102758"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Czerwony"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1038,
        "name": "Pocieszenie (V)",
        "handle": "solace-v",
        "description": "Składając z wdziękiem kondolencje, nasze stojaki na kwiaty przedstawiają pogodne białe chryzantemy gerberę oraz delikatne i eleganckie eustomy szampańskie. Ta aranżacja stanowi delikatny i pocieszający hołd, symbolizujący współczucie i pamięć, zapewniając pocieszenie w chwilach głębokiego smutku i refleksji z delikatnym pięknem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Solace-_V_-1b.jpg?v=1720143565",
            "/images/products/Condolence-Card-2024_1_98913f79-e933-4b33-93a5-4e7844fd2923.jpg?v=1720143569",
            "/images/products/Solace-_V_-2b.jpg?v=1720143565"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Eustoma. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1039,
        "name": "Sprzedam Auspicious Oranges CNY",
        "handle": "吉祥如意-auspicious-oranges-cny",
        "description": "吉祥如意 Pomyślne pomarańcze Osiemnaście pomarańczy, zamkniętych w słomkowym koszu, niesie w sobie esencję pomyślności. Niech szczęście i szczęście podążają za tobą jak cień. ",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "184.61",
        "inclTax": "184.61",
        "img": [
            "/images/products/Auspicious-Oranges1-299954.jpg?v=1704291412",
            "/images/products/Auspicious-Oranges2-371797.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 18",
                "2. Kosz ze słomy"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 1040,
        "name": "Pocieszenie (VI)",
        "handle": "solace-vi",
        "description": "Składając kondolencje z wdziękiem, nasze stojaki na kwiaty przedstawiają pogodne, białe, fioletowe chryzantemy. Ta aranżacja stanowi delikatny i pocieszający hołd, symbolizujący współczucie i pamięć, zapewniając pocieszenie w chwilach głębokiego smutku i refleksji z delikatnym pięknem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Solace-_VI_-1e.jpg?v=1720147374",
            "/images/products/Condolence-Card-2024_1_6cd4226c-2f21-4223-81f3-30a61b111b9c.jpg?v=1720147399",
            "/images/products/Solace-_VI_-2e.jpg?v=1720147374"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantemy. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1041,
        "name": "Sprzedam Orange Treasures CNY",
        "handle": "橙心如意-orange-treasures",
        "description": "橙心如意 Orange TreasuresDoceniaj każdą chwilę i pielęgnuj każdą radość. Niech te osiemnaście mandarynek w koszyku będzie zwiastunem szczęścia i serdecznych życzeń.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Orange-Treasures1_74ced95a-8369-4fff-b1e3-97d93bfe3f75-225996.jpg?v=1704291412",
            "/images/products/Orange-Treasures2_ad2ffee0-dc9b-4d3d-9d53-fc2305df3699-540461.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 18",
                "2. Kosz transportowy"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 1042,
        "name": "Pocieszenie (VII)",
        "handle": "solace-vii",
        "description": "Składając uroczyste kondolencje, nasze kwietniki przedstawiają żywe pomarańczowe orchidee, czysto białe chryzantemy i kwitnące egzotyczne rajskie ptaki. Ta elegancka aranżacja stanowi godny hołd, symbolizując współczucie i pamięć, zapewniając pocieszenie w chwilach głębokiego smutku i refleksji dzięki tropikalnemu pięknu.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Solace-_VII_-1d.jpg?v=1720147883",
            "/images/products/Condolence-Card-2024_1_0124311d-8c46-4d6e-9a54-0694f6ce24a1.jpg?v=1720147890",
            "/images/products/Solace-_VII_-2d.jpg?v=1720147883"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantemy. Orchidee. Rajski ptak. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1043,
        "name": "Pocieszenie (VIII)",
        "handle": "solace-viii",
        "description": "Składając szczere kondolencje, nasz trójnóg kwiatowy elegancko łączy żółte i białe odcienie, z delikatnymi gerberami, czystymi białymi chryzantemami, delikatnymi eustomami i miękkimi różami. Ozdobiona gustowną białą listwą przypodłogową, ta aranżacja stanowi godny hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Solace-_VIII_-1b.jpg?v=1720142612",
            "/images/products/Condolence-Card-2024_1_ac512586-eb4b-45d8-9bd4-76f09a685ac8.jpg?v=1720142619",
            "/images/products/Solace-_VIII_-2b.jpg?v=1720142612"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantemy. Gerbera . Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1044,
        "name": "Kup Prosperous Oranges CNY",
        "handle": "吉隆之喜-prosperous-oranges-cny",
        "description": "吉隆之喜 Dostatnie pomarańcze W tym złotym koszu osiemnaście pomarańczy błyszczy jak bryłki dobrobytu. Niech zapoczątkowują rok pełen bogactwa, zdrowia i szczęścia.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Prosperous-Oranges1-418008.jpg?v=1704291412",
            "/images/products/Prosperous-Oranges2-569836.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 18",
                "2. Złoty Kosz"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 1045,
        "name": "Sprzedam Blissful Oranges CNY",
        "handle": "橙心橙意-blissful-oranges-cny",
        "description": "橙心橙意 Błogie pomarańcze CNYBliss można znaleźć w prostych przyjemnościach, takich jak te osiemnaście mandarynek w skórzanym koszyku CNY. Niech przyniosą zadowolenie i pokój, napełniając Twój rok spokojem.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "234.82",
        "inclTax": "234.82",
        "img": [
            "/images/products/Blissful-Oranges1-297357.jpg?v=1704291412",
            "/images/products/Blissful-Oranges2-245046.jpg?v=1704291412"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 18",
                "2. Kosz skórzany CNY (S)"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem."
    },
    {
        "id": 1046,
        "name": "Pocieszenie (IX)",
        "handle": "solace-ix",
        "description": "Składając szczere kondolencje, nasz statyw kwiatowy z wdziękiem łączy odcienie błękitu i bieli, ozdobiony delikatnymi gerberami, czystymi białymi chryzantemami, delikatnymi eustomami, sztucznymi hortensjami i eleganckimi gerberami. Ubrana w gustowną białą listwę, ta aranżacja stanowi godny hołd, symbolizując współczucie i pamięć w chwilach głębokiego smutku i refleksji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "730.01",
        "inclTax": "730.01",
        "img": [
            "/images/products/Solace-_IX_-1b.jpg?v=1720143257",
            "/images/products/Condolence-Card-2024_1_8c993c87-cc26-428e-b766-462219197946.jpg?v=1720143262",
            "/images/products/Solace-_IX_-2b.jpg?v=1720143258"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Sztuczne hortensje. Chryzantemy. Gerbera . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1047,
        "name": "Obfity (VI)",
        "handle": "bountiful-vi",
        "description": "Świętuj wszystkie wielkie otwarcia dzięki naszym stojakom na kwiaty z wesołym żółto-białym motywem. Ozdobiona piękną listwą przypodłogową, ta tętniąca życiem aranżacja symbolizuje radość i sukces, tworząc przyjazną atmosferę na tę wyjątkową okazję. Uświetnij swoje wydarzenie pięknem świeżych kwiatów i obietnicą nowego początku.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Bountiful-_VI_-1b.jpg?v=1720102900",
            "/images/products/Grand-Opening-Card-2024_609a8150-615b-4072-a7a0-68609124401a.jpg?v=1720102914",
            "/images/products/Bountiful-_VI_-2b.jpg?v=1720102900"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1048,
        "name": "Obfity (VII)",
        "handle": "bountiful-vii",
        "description": "Przekształć wszystkie wielkie otwarcia w oszałamiające wizualnie wydarzenie dzięki naszym stojakom na kwiaty w kolorze pastelowego różu, podkreślonym piękną pomarańczową i różową listwą przypodłogową. Ta aranżacja, symbolizująca dobrobyt i elegancję, przygotowuje grunt pod niezapomniane i udane świętowanie nowego przedsięwzięcia biznesowego.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Bountiful-_VII_-1b.jpg?v=1720103065",
            "/images/products/Grand-Opening-Card-2024_5c7071bd-58aa-40ad-a71c-3f849ddbe5fb.jpg?v=1720103084",
            "/images/products/Bountiful-_VII_-2b.jpg?v=1720103065"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*170cm"
    },
    {
        "id": 1049,
        "name": "Obfity (VIII)",
        "handle": "bountiful-viii",
        "description": "Przekształć wszystkie wielkie otwarcia w oszałamiające wizualnie wydarzenie dzięki naszym stojakom na kwiaty w kolorze pastelowego różu, podkreślonym piękną pomarańczową i różową listwą przypodłogową. Ta aranżacja, symbolizująca dobrobyt i elegancję, przygotowuje grunt pod niezapomniane i udane świętowanie nowego przedsięwzięcia biznesowego.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Bountiful-_VIII_-1b.jpg?v=1720103183",
            "/images/products/Grand-Opening-Card-2024_0919008f-de9b-4bbc-941e-baf042be7c5b.jpg?v=1720103204",
            "/images/products/Bountiful-_VIII_-2b.jpg?v=1720103183"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Róże. Słoneczniki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1050,
        "name": "Joy Abundance w CNY",
        "handle": "joy-abundance-丰衣足食-cny",
        "description": "Joy Abundance 丰衣足食",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Joy-Abundance-_-CNY1-934880.jpg?v=1704463727",
            "/images/products/Joy-Abundance-_-CNY2-617598.jpg?v=1704463727"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo A. Żeń-szeń z białym grzybem i cukrem kamiennym 150ml",
                "2. Herbata Oolong 375g",
                "3. Herbata Premium Pu’Er 40g",
                "4. Zupa ziołowa Souper Tang – witalność w misce",
                "45 g x 2",
                "5. Opakowanie",
                "– 冰糖泡参雪耳燕窝 150ml",
                "– 375 g",
                "– 普洱茶 40g",
                "– 汤师父养生靓汤 45 g x 2",
                "– 精美包装与装饰"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1051,
        "name": "Elegance Abundance w CNY",
        "handle": "elegance-abundance-五谷丰登-cny",
        "description": "Elegancja Obfitość 五谷丰登",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Elegance-Abundance-_-CNY1-601489.jpg?v=1704463373",
            "/images/products/Elegance-Abundance-_-CNY2-705463.jpg?v=1704463373"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "➧ 陈师傅绿茶发财鱼生 ➧ X.O 埃菲尔铁塔白兰地 20cl ➧ 红酒 75cl ➧ 人参银耳冰糖燕窝 6x70ml ➧大家发卤水鲍鱼425g ➧ 鲍今天卤水鲍鱼425g ➧ 盒装铁观音 25包 ➧ 精美贺年礼篮\n – Zielona Herbata Szefa Kuchni Chena Dobrobyt Yu Sheng – Francuska Brandy XO Effiel Tower 20 cl – Vino Fernandez Merlot 75 cl – Ptasie Gniazdo z Żeń-szeniem, Białym Grzybem"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1052,
        "name": "Sparkle Abundance w CNY",
        "handle": "sparkle-abundance-丰财聚宝-cny",
        "description": "Sparkle Abundance 丰财聚宝",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "561.14",
        "inclTax": "561.14",
        "img": [
            "/images/products/Sparkle-Abundance-_-CNY1-457332.jpg?v=1704464823",
            "/images/products/Sparkle-Abundance-_-CNY2-367729.jpg?v=1704464823"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Gniazdo Ptaka Souper Tang Z Kolagenem 150ml x 3",
                "2. Ptasie gniazdo A. Żeń-szeń z białym grzybem i cukrem kamiennym 150ml",
                "3. Makaron Domowy Souper Tang Premium 200g",
                "4. Cukierki z żeń-szeniem koreańskim 100g",
                "5. Herbata z żeń-szeniem amerykańskim Souper Tang 3g x 6",
                "6. Herbata Premium Pu’Er 40g",
                "7. Zupa ziołowa Souper Tang – witalność w misce",
                "45 g x 2",
                "8. Zupa Tang Abalone 425g",
                "9. Wino czerwone – Montier 750ml",
                "10. Dziki Żeń-szeń 1szt",
                "11. Opakowanie",
                "– 胶原蛋白燕窝 150ml x 3",
                "– 冰糖泡参雪耳燕窝 150ml",
                "– 膳面线 200g",
                "– 高丽人参糖 100g",
                "– 汤师父花旗泡参茶 3g x 6",
                "– 普洱茶 40g",
                "– 汤师父养生靓汤 45 g x 2",
                "– 汤师父精选鲍鱼 425g",
                "– 高级红酒 750ml",
                "– 野山人参 1支",
                "– 精美包装与装饰"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1053,
        "name": "一帆风顺 Auspicious Cellar CNY",
        "handle": "一帆风顺-auspicious-cellar-cny",
        "description": "一帆风顺 Pomyślna piwnica",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "278.18",
        "inclTax": "278.18",
        "img": [
            "/images/products/Auspicious-Cellar-CNY1-388537.jpg?v=1704465150",
            "/images/products/Auspicious-Cellar-CNY2-990018.jpg?v=1704465150"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 8",
                "2. Bellevie Pavillon Merlot 75cl",
                "3. Zamek Fleurs Vignobles 75cl",
                "4. Czerwony tkany kosz",
                "– 8 stycznia",
                "– 红酒 75cl",
                "– 红酒 75cl"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1054,
        "name": "新岁献瑞 Celebratory Cellar CNY",
        "handle": "新岁献瑞-celebratory-cellar-cny",
        "description": "新岁献瑞 Świąteczna piwnica Skocz w stronę sukcesu dzięki temu koszowi z ośmioma mandarynkami, Chateau Les Martineaux Bordeaux, uchowcem i ptasim gniazdem w koszyku. Niech będzie to początek roku pełnego niezwykłych osiągnięć.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "301.00",
        "inclTax": "301.00",
        "img": [
            "/images/products/Celebratory-Cellar-CNY1-109652.jpg?v=1704465151",
            "/images/products/Celebratory-Cellar-CNY2.jpg?v=1704338500"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 8",
                "2. Zamek Les Martineaux Bordeaux 75cl",
                "3. Kinohimitsu Imperial Duszone Uchowce Japońskie 425g",
                "4. Kinohimitsu Longan",
                "5. Kosz transportowy",
                "– 8 stycznia",
                "– 红酒 75cl",
                "– Kinohimitsu 御皇红烧日本鲍鱼 425g",
                "– Kinohimitsu 龙眼枸杞燕窝 150ml"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1055,
        "name": "Sprzedam Prosperous Cellar CNY",
        "handle": "龙腾虎跃-prosperous-cellar-cny",
        "description": "Prosperous CellarDobrobyt skacze jak smoki i tygrysy dzięki temu wyborowi ośmiu mandarynek, De Valier Rosso, uchowca i ptasiego gniazda w koszyku. Za rok pełnego zdrowia i bogactwa.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "344.58",
        "inclTax": "344.58",
        "img": [
            "/images/products/Prosperous-Cellar-CNY1-859246.jpg?v=1704465152",
            "/images/products/Prosperous-Cellar-CNY2.jpg?v=1704344769"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 8",
                "2. De Valier Rosso 75cl",
                "3. Świetlik duszony Amidori Abalone 425g",
                "4. Kinohimitsu Longan",
                "5. Kosz transportowy",
                "– 8 stycznia",
                "– 红酒 75cl",
                "– 天亮牌高级红烧青深鲍鱼 425g",
                "– Kinohimitsu 龙眼枸杞燕窝 150ml"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1056,
        "name": "Obfity (IX)",
        "handle": "bountiful-ix",
        "description": "Uświetnij swoje wielkie otwarcie za pomocą naszego wyjątkowego statywu z kwiatami w urzekającym niebieskim motywie. Harmonijne połączenie hortensji, róż, orchidei, eustomy i mattioli tworzy zachwycającą kompozycję, symbolizującą dobrobyt i elegancję. Złóż odważne oświadczenie i zaprowadź sukces dzięki tej czarującej ekspozycji kwiatowej.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Bountiful-_IX_-1c.jpg?v=1720103380",
            "/images/products/Grand-Opening-Card-2024_3d275fe7-9c56-4e16-bc94-ab2d743bb7b3.jpg?v=1720103390",
            "/images/products/Bountiful-_VIII_-2c.jpg?v=1720103380"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Sztuczne hortensje. Róże. Eustoma. Orchidee. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1057,
        "name": "Obfity (X)",
        "handle": "bountiful-x",
        "description": "Odkryj wspaniałość wszystkich wielkich wernisaży dzięki naszemu wspaniałemu stojakowi na trójnóg z kwiatami w urzekającym, gorącym różowym motywie. Bujne róże i elegancka eustoma zostały pomysłowo zaaranżowane, podkreślone delikatną różową listwą przypodłogową. Ten tętniący życiem wyświetlacz symbolizuje dobrobyt i radość, nadając idealny ton Twojemu pomyślnemu początkowi.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "844.11",
        "inclTax": "844.11",
        "img": [
            "/images/products/Bountiful-_X_-1b.jpg?v=1720103558",
            "/images/products/Grand-Opening-Card-2024_a3593e09-9479-4b38-9c58-dc007257773b.jpg?v=1720103571",
            "/images/products/Bountiful-_X_-2b.jpg?v=1720103558"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1058,
        "name": "Kupuj w sklepie Abundant Celler CNY",
        "handle": "祥云瑞气-abundant-celler-cny",
        "description": "Obfity Celler Przepełniony obfitością ten kosz zawierający osiemnaście mandarynek, Vino Fernandez Merlot i Chateau Fleurs Vignobles w złotym koszu oznacza rok pełen fortuny i witalności.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "392.28",
        "inclTax": "392.28",
        "img": [
            "/images/products/Abundant-Celler-CNY1-749937.jpg?v=1704465154",
            "/images/products/Abundant-Celler-CNY2-935935.jpg?v=1704465154"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 18",
                "2. Wino Fernandez Merlot 75cl",
                "3. Zamek Fleurs Vignobles 75cl",
                "4. Złoty Kosz",
                "– 18 stycznia",
                "– 红酒 75cl",
                "– 红酒 75cl"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1059,
        "name": "Sprzedam Golden Celler CNY",
        "handle": "喜气腾腾-golden-celler-cny",
        "description": "Uroczystość Golden CellerElevate z ośmioma mandarynkami i 12-leciem Chivas Regal w skórzanym koszu, symbolizującym złoty rok pełen radości i prestiżowych osiągnięć.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "485.84",
        "inclTax": "485.84",
        "img": [
            "/images/products/Golden-Celler-CNY1-484066.jpg?v=1704465150",
            "/images/products/Golden-Celler-CNY2-472585.jpg?v=1704465150"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 8",
                "2. Chivas Regal 12 lat 70 kl",
                "3. Kosz skórzany CNY (L)",
                "– 8 stycznia",
                "– 12 dni 70 cl"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1060,
        "name": "龙凤呈祥 Wealthy Cellar CNY",
        "handle": "龙凤呈祥-wealthy-cellar-cny",
        "description": "Uroczystość Golden CellerElevate z ośmioma mandarynkami i 12-leciem Chivas Regal w skórzanym koszu, symbolizującym złoty rok pełen radości i prestiżowych osiągnięć.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "654.71",
        "inclTax": "654.71",
        "img": [
            "/images/products/Wealthy-Cellar-CNY1-550158.jpg?v=1704465153",
            "/images/products/Wealthy-Cellar-CNY2-937190.jpg?v=1704465153"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mandarynki x 8",
                "2. Koniak Martell VSOP 70cl",
                "3. Kosz skórzany CNY (L)",
                "– 8 stycznia",
                "– 馬爹利 VSOP干邑 70cl"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1061,
        "name": "Harmonia (V)",
        "handle": "harmony-v",
        "description": "Uwydatnij wszystkie wielkie otwarcia dzięki promieniującemu ciepłu naszego statywu z motywem pomarańczowych kwiatów. Słoneczniki zajmują centralne miejsce, uzupełnione elegancją róż i eustomy, a wszystko to na tle żywych pomarańczowych listew przypodłogowych. Ta wykwintna aranżacja symbolizuje sukces, radość i rozkwit nowych początków.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Harmony-_V_-1d.jpg?v=1721525072",
            "/images/products/Grand-Opening-Card-2024_93b8f907-1616-40e7-af31-1a9c7da4f4c3.jpg?v=1721525081",
            "/images/products/Harmony-_V_-2d.jpg?v=1721525071"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Eustoma. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1062,
        "name": "Golden Lohei w CNY",
        "handle": "golden-lohei-皆大欢喜-cny",
        "description": "Golden Lohei 皆大欢喜Świętuj symfonię smaków dzięki Golden Lohei. Yu Sheng Green Tea Prosperity szefa kuchni Chena przygotowuje scenę do radosnych spotkań, uzupełnionych odżywczym ptasim gniazdem, soczystą muszlą w sosie uchowca i nie tylko. Rzuć haj na rok wypełniony złotymi chwilami.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Golden-Lohei-_-CNY1-722328.jpg?v=1704463536",
            "/images/products/Golden-Lohei-_-CNY2-752876.jpg?v=1704463536"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Dobrobyt zielonej herbaty szefa kuchni Chena Yu Sheng",
                "2. Podwójne ptasie gniazdo z żeń-szeniem i białym grzybem",
                "3. Duszone muszle królewskie w sosie z abalone w nowiu 425g",
                "4. Skok Buddy z Księżyca w nowiu przez ścianę 425g",
                "5. Zupa rybna z nowiu 400g",
                "6. Orzeszki ziemne Camel Shandong 120g",
                "7. Znakomite opakowanie CNY",
                "–陈师傅绿茶发财鱼生",
                "–双燕人参银耳冰糖燕窝 70ml x 3",
                "–人月红烧鲍鱼汁香鲍 425g",
                "– 人月佛跳墙 425g",
                "– 人月鱼鳔汤 400g",
                "– 120 g",
                "–精美贺年礼篮"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1063,
        "name": "Radiant Lohei od CNY",
        "handle": "radiant-lohei-佳节团圆-cny",
        "description": "Radiant Lohei 佳节团圆Radiant Lohei przynosi ciepło ponownego spotkania. Dzięki wyjątkowemu Yu Sheng szefa kuchni Chen, sokowi musującemu i wyborowi skarbów dla smakoszy, jest to idealne połączenie, które zapewni świąteczny blask. Wznieś się na wyżyny szczęścia i rodzinnej harmonii.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "351.20",
        "inclTax": "351.20",
        "img": [
            "/images/products/Radiant-Lohei-_-CNY1-789172.jpg?v=1704463901",
            "/images/products/Radiant-Lohei-_-CNY2-312322.jpg?v=1704463901"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Dobrobyt zielonej herbaty szefa kuchni Chena Yu Sheng",
                "2. Sok musujący Royal Select 750ml",
                "3. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "4. 大家发 Uchowiec w solance 425g",
                "5. Szparagi Fortune Sea w zalewie 425g",
                "6. 财神 Fortune Razor Clam 425g",
                "7. Pudełko na herbatę Tie Guan Yin 25-te",
                "8. Znakomite opakowanie CNY",
                "–陈师傅绿茶发财鱼生",
                "–皇家精选气泡果汁 750ml",
                "–人参银耳冰糖燕窝 6x70ml",
                "–大家发卤水鲍鱼425g",
                "–财神清汤鲍筍 425g",
                "–财神贵妃贝425g",
                "–盒装铁观音 25包",
                "–精美贺年礼篮"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1064,
        "name": "Harmonia (VI)",
        "handle": "harmony-vi",
        "description": "Świętuj triumf dzięki naszemu stojakowi kwiatowemu Majestic Fusion z motywem słoneczników i pomarańczowych róż. Ta urzekająca mieszanka symbolizuje dobrobyt i entuzjazm, tworząc wielkie oświadczenie otwierające, które rezonuje z elegancją i sukcesem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_VI_-1c.jpg?v=1720523881",
            "/images/products/Grand-Opening-Card-2024_e67ef4db-9cb4-4163-b49a-532599651fa9.jpg?v=1720523887",
            "/images/products/Harmony-_VI_-2c.jpg?v=1720523881"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Róże. Anturium . Mamo. Sztuczne Delphinium. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 70cm*170cm"
    },
    {
        "id": 1065,
        "name": "Bountiful Lohei w CNY",
        "handle": "bountiful-lohei-多福多寿-cny",
        "description": "Bountiful Lohei 多福多寿Przyjmij obfitość dzięki Bountiful Lohei. Zielona herbata Dobrobyt Yu Sheng w połączeniu z doskonałym winem, uchowcem cesarskim i luksusowym ptasim gniazdem symbolizuje róg obfitości. Rzuć na rok obfitego zdrowia i długowieczności.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "396.84",
        "inclTax": "396.84",
        "img": [
            "/images/products/Bountiful-Lohei-_-CNY1-578396.jpg?v=1704722871",
            "/images/products/Bountiful-Lohei-_-CNY2-629710.jpg?v=1704722871"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Szef kuchni Chens Zielona herbata Dobrobyt Yu Sheng",
                "2. Bellevie Pavillon Merlot 75cl",
                "3. 鲍发 Uchowiec w solance 425g",
                "4. Podwójne gniazdo ptaków jaskółczych z żeń-szeniem i białym grzybem",
                "5. Seria białych grzybów Yu Xiang Yan Ptasie gniazdo 3s 75ml",
                "6. Budda Yu Xiang Yan Abalone skacze przez ścianę 425g",
                "7. Znakomite opakowanie CNY",
                "–陈师傅绿茶发财鱼生",
                "–红酒 75cl",
                "–鲍发 Uchowce w solance 425g",
                "–双燕人参银耳冰糖燕窝 70ml x 2",
                "–宇鄕燕银耳燕窝系列 75ml x 3",
                "–宇鄕燕鲍鱼佛跳墙 425g",
                "–精美贺年礼篮"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1066,
        "name": "Elegancki Lohei 富贵有余 CNY",
        "handle": "elegant-lohei-富贵有余-cny",
        "description": "Elegant Lohei 富贵有余Zanurz się w elegancji świątecznych uroczystości z Elegant Lohei. Zawiera Yu Sheng szefa kuchni Chena, wyśmienitą brandy X.O i szereg najwyższej jakości przysmaków, jest to toast za dobrobyt i łaskę. Podnieś swoje świętowanie z każdym rzutem.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "501.81",
        "inclTax": "501.81",
        "img": [
            "/images/products/Elegant-Lohei-_-CNY1-581116.jpg?v=1704723039",
            "/images/products/Elegant-Lohei-_-CNY2-990047.jpg?v=1704723039"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Dobrobyt zielonej herbaty szefa kuchni Chena Yu Sheng",
                "2. X.O Brandy Marquis 35cl",
                "3. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "4. 大家发 Uchowiec w solance 425g",
                "5. Fortune Razor Clam 425g",
                "6. Pudełko na herbatę Tie Guan Yin 25-te",
                "7. Znakomite opakowanie CNY",
                "–陈师傅绿茶发财鱼生",
                "–X.O 白兰地侯爵 35cl",
                "–人参银耳冰糖燕窝 6x70ml",
                "–大家发卤水鲍鱼425g",
                "–财神贵妃贝425g",
                "–盒装铁观音 25包",
                "–精美贺年礼篮"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1067,
        "name": "Wspaniały Lohei 鱼跃龙门 CNY",
        "handle": "gorgeous-lohei-鱼跃龙门-cny",
        "description": "Wspaniały Lohei 鱼跃龙门Wspaniały Lohei uosabia szczyt świętowania. Luksusowa mieszanka Yu Sheng szefa kuchni Chena, francuskiej brandy, Merlot i wybranych uchowców to wspaniały gest na nadchodzący pomyślny rok. Rzuć się w stronę roku pełnego sukcesów i szczęścia.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "572.55",
        "inclTax": "572.55",
        "img": [
            "/images/products/Grand-Lohei-_-CNY1-222658.jpg?v=1704723162",
            "/images/products/Grand-Lohei-_-CNY2-933827.jpg?v=1704723162"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Dobrobyt zielonej herbaty szefa kuchni Chena Yu Sheng",
                "2. Francuska brandy XO Wieża Eiffla 20cl",
                "3. Wino Fernandez Merlot 75cl",
                "4. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "5. 大家发 Uchowiec w solance 425g",
                "6. 鲍今天 Jade Abalone w solance 425g",
                "7. Zawiąż herbatę Guan Yin w puszce",
                "8. Znakomite opakowanie CNY",
                "–陈师傅绿茶发财鱼生",
                "–X.O 埃菲尔铁塔白兰地 20cl",
                "–红酒 75cl",
                "–人参银耳冰糖燕窝 6x70ml",
                "–大家发卤水鲍鱼425g",
                "–鲍今天卤水鲍鱼425g",
                "–盒装铁观音 25包",
                "–精美贺年礼篮"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1068,
        "name": "Radosne spotkanie 合家团圆 CNY",
        "handle": "joyful-reunion-合家团圆-cny",
        "description": "Radosne spotkanie 合家团圆Podaruj ukochanej osobie ciepło i radość podczas nadchodzących pomyślnych wakacji dzięki pięknemu, tematycznemu pudełku zawierającemu odmładzające ptasie gniazdo, przepyszne ciasteczka i inne słodkie smakołyki.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Joyful-Reunion-_-CNY1-402202.jpg?v=1704983089",
            "/images/products/Joyful-Reunion-_-CNY2-907714.jpg?v=1704983089"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "2. Ciasteczka Yam 80g",
                "3. Nowa bułka z krewetkami Peng Hiang 80g",
                "4. Pudding Hokkaido Love 50g",
                "5. Orzeszki ziemne Camel Shandong 120g",
                "6. Kueh Bangkit Pandan o smaku 60g",
                "7. Beryl’s Tiramisu Migdałowa Czekolada Mleczna 65g",
                "8. Ciasto ananasowe rodziny królewskiej 138g",
                "9. Znakomite opakowanie CNY",
                "–人参银耳冰糖燕窝 3x70ml",
                "– 香芋曲奇 80g",
                "–新品香香酥虾米卷 80g",
                "– 北海道爱心布丁 50g",
                "– 120 g",
                "–班兰味薯粉饼 60g",
                "–提拉米苏杏仁牛奶巧克力 65g",
                "– 138 g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1069,
        "name": "Błogie spotkanie 圆满丰收 CNY",
        "handle": "blissful-reunion-圆满丰收-cny",
        "description": "Błogie spotkanie 圆满丰收Ten wykwintny kosz na żywność jest idealny do świętowania Księżycowego Nowego Roku. Składający się z rewitalizującego ptasiego gniazda, smacznych tart ananasowych i innych pikantnych smakołyków, nasz koszyk miłości jest stworzony do dzielenia się między członkami rodziny i przyjaciółmi.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Blissful-Reunion-_-CNY1-468979.jpg?v=1705501798",
            "/images/products/Blissful-Reunion-_-CNY2-742816.jpg?v=1705459290"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Sok musujący Royal Select 750ml",
                "2. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "3. Kinohimitsu Longan",
                "4. Aromatyczna herbata mleczna z czarnym ziarnem Nanyang 6 sztuk x 65 g",
                "5. Ciasteczka z kremem lawowym Premium 96g",
                "6. Delfi Czekolada Migdałowa 180g",
                "7. Tarty ananasowe CNY 102g",
                "8. Chrupiące Ciastka Świeżego Mleka 36g",
                "9. Cukierki z sokiem Mahjong 100g",
                "10. Kueh Bangkit Pandan o smaku 60g",
                "11. Wykwintne opakowanie CNY",
                "–皇家精选气泡果汁 750ml",
                "–人参银耳冰糖燕窝 3x70ml",
                "–Kinohimitsu 龙眼枸杞燕窝 150ml",
                "–南洋 芳香黑五谷代餐奶茶 6 x 65 g",
                "–熔岩奶油曲奇 96g",
                "– papierosy 180g",
                "– 102 g",
                "–香脆鲜奶糕 36g",
                "–趣味麻将软糖 100g",
                "–班兰味薯粉饼 60g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1070,
        "name": "Pokój (VII)",
        "handle": "peace-vii",
        "description": "Wyraź współczucie i pocieszenie za pomocą naszego stojaka na kwiaty kondolencyjne z spokojną mieszanką fioletowych chryzantem i białych stokrotek gerbery. Symbolizująca spokój i czystość aranżacja zapewnia komfort w trudnych chwilach. Delikatne odcienie przekazują szczere kondolencje, tworząc godny hołd, aby uhonorować i przypomnieć sobie cenne życie z wdziękiem i pięknem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Peace-_VII_-1c.jpg?v=1720188598",
            "/images/products/Condolence-Card-2024_1_2ef9422a-9e24-46dd-bd70-44eacf11206a.jpg?v=1720188614",
            "/images/products/Peace-_VII_-2c.jpg?v=1720188597"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Sztuczne lilie. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 1071,
        "name": "Sezon dawania koszy",
        "handle": "season-of-giving-hamper-1-爱心献礼-1-cny",
        "description": "Sezon dawania koszy",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Season-of-Giving-Hamper-_1-_-_1-CNY1-654576.jpg?v=1705415829",
            "/images/products/Season-of-Giving-Hamper-_1-_-_1-CNY2-187763.jpg?v=1705415829"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mąka Power Sól morska z czarnym sezamem w saszetce 100g",
                "2. Kulka Ananasowa Flour Power w Słoiczku 15szt",
                "3. Ang Baos",
                "4. Kinohimitsu Longan",
                "5. Okrągłe pudełko upominkowe CNY (S)"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1072,
        "name": "Sezon dawania koszy",
        "handle": "season-of-giving-hamper-2-爱心献礼-2-cny",
        "description": "Sezon dawania koszy",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "246.23",
        "inclTax": "246.23",
        "img": [
            "/images/products/Season-of-Giving-Hamper-_2-_-_2-CNY1-770498.jpg?v=1705415829",
            "/images/products/Season-of-Giving-Hamper-_2-_-_2-CNY2-553479.jpg?v=1705415829"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mąka Power Ciasteczka Owsiane Żurawinowe 100g",
                "2. Mąka Power Sól morska z czarnym sezamem w saszetce 100g",
                "3. Kulka Ananasowa Flour Power w Słoiczku 15szt",
                "4. Herbata Tie Guan Yin w różowej puszce",
                "5. Kinohimitsu Longan",
                "6. Ang Baos",
                "7. Brązowy kosz"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1073,
        "name": "Pokój (VIII)",
        "handle": "peace-viii",
        "description": "Wyraź współczucie i pocieszenie za pomocą naszego stojaka na kwiaty kondolencyjne, ozdobionego spokojnymi różowymi gerberami i ciemnofioletowymi chryzantemami, który stanowi szczery wyraz współczucia i pocieszenia w trudnych chwilach. Delikatne, kojące kolory przekazują poczucie spokoju i pamięci, tworząc godny hołd, aby uczcić i pielęgnować pamięć o ukochanej osobie.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Peace-_VIII_-1c.jpg?v=1720155204",
            "/images/products/Condolence-Card-2024_1_ee423321-7da7-4d64-8e1d-e7dcc5da664d.jpg?v=1720155211",
            "/images/products/Peace-_VIII_-2c.jpg?v=1720155204"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Sztuczne lilie. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 1074,
        "name": "Pokój (IX)",
        "handle": "peace-ix",
        "description": "Przedstawiamy nasz stojak na kwiaty Sympathy Serenity, pełen wdzięku hołd w czarno-białym motywie. Majestatyczne białe chryzantemy symbolizują czystość, a eleganckie czarne gerbery wyrażają powagę i szacunek. Ta aranżacja, starannie zaprojektowana z myślą o złożeniu kondolencji, zapewnia pocieszenie i godne pożegnanie, wyrażając współczucie ponadczasowym wdziękiem i dyskretnym pięknem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Peace-_IX_-1d.jpg?v=1720188281",
            "/images/products/Condolence-Card-2024_1_ecf417b3-3b96-4d2f-8867-5361941aa16a.jpg?v=1720188292",
            "/images/products/Peace-_IX_-2d.jpg?v=1720188281"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Gerbera . Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1075,
        "name": "Sezon dawania koszy",
        "handle": "season-of-giving-hamper-3-爱心献礼-3-cny",
        "description": "Sezon dawania koszy",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "269.05",
        "inclTax": "269.05",
        "img": [
            "/images/products/Season-of-Giving-Hamper-_3-_-_3-CNY1-219716.jpg?v=1705503087",
            "/images/products/Season-of-Giving-Hamper-_3-_-_3-CNY2-660024.jpg?v=1705503087"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mąka Power Ciasteczka Owsiane Żurawinowe 100g",
                "2. Mąka Power Sól morska z czarnym sezamem w saszetce 100g",
                "3. Kulka Ananasowa Flour Power w Słoiczku 15szt",
                "4. Kinohimitsu Longan",
                "5. Zawiąż herbatę Guan Yin w czerwonej puszce",
                "6. Biały kot szczęścia",
                "7. Złota Skrzynia",
                "8. Ang Baos"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1076,
        "name": "Współczucie (IX)",
        "handle": "sympathy-ix",
        "description": "W chwilach smutku nasz stojak na kwiaty Sympathy Serenity zapewnia pocieszenie. Wyraźne białe orchidee, delikatne anturium i spokojne chryzantemy tworzą spokojny hołd. Ta elegancka kompozycja przekazuje najszczersze kondolencje, wyrażając pocieszenie i współczucie w trudnych chwilach, za pomocą ponadczasowej palety czystych i kojących białych kwiatów.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Sympathy-_IX_-1d.jpg?v=1720188438",
            "/images/products/Condolence-Card-2024_1_a3c791db-6334-4a58-b325-be8f3481fd16.jpg?v=1720188469",
            "/images/products/Sympathy-_IX_-2d.jpg?v=1720188437"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantemy. Orchidee. Anturium . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1077,
        "name": "Sezon dawania koszy",
        "handle": "season-of-giving-hamper-4-爱心献礼-4-cny",
        "description": "Sezon dawania koszy",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "314.69",
        "inclTax": "314.69",
        "img": [
            "/images/products/Season-of-Giving-Hamper-_4-_-_4-CNY1-925903.jpg?v=1705589045",
            "/images/products/Season-of-Giving-Hamper-_4-_-_4-CNY2-928496.jpg?v=1705589045"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Mąka Power Ciasteczka Owsiane Żurawinowe 100g",
                "2. Mąka Power Sól morska z czarnym sezamem w saszetce 100g",
                "3. Kulka Ananasowa Flour Power w Słoiczku 15szt",
                "4. Kulka Ananasowa Flour Power w Słoiczku 15szt",
                "5. Złoty kot z fortuną",
                "6. Zawiąż Guan Yin w czerwonej puszce",
                "7. Kosz z czerwoną rączką",
                "8. Ang Baos"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1078,
        "name": "Pocieszenie (X)",
        "handle": "solace-x",
        "description": "Przedstawiamy nasz elegancki stojak na kwiaty kondolencyjne, będący przemyślanym i serdecznym wyrazem współczucia w chwilach straty. Ta aranżacja, wykonana z najwyższą starannością i współczuciem, zawiera harmonijne połączenie delikatnej rośliny kapustnej, spokojnej lilii i pełnej wdzięku eustomy, zaakcentowanej spokojnym pięknem sztucznych białych hortensji.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Solace-_X_-1c.jpg?v=1720189341",
            "/images/products/Condolence-Card-2024_1_1929a7c3-0a57-4c79-ba21-4c6294711583.jpg?v=1720189348",
            "/images/products/Solace-_X_-2c.jpg?v=1720189341"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Lilie . Eustoma. Sztuczne białe hortensje. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1079,
        "name": "Współczucie (X)",
        "handle": "sympathy-x",
        "description": "Elegancja spotyka się ze współczuciem w naszym stojaku na kwiaty Condolence Jumbo. Wdziękiem ozdobiona ciemnofioletową kapustą, spokojną lilią i delikatną różową gerberą oraz delikatnymi orchideami, ta aranżacja wyraża szczere współczucie. Starannie dobrany, stanowi pocieszający hołd, oddając cześć zmarłym majestatycznym pokazem piękna i pocieszenia w trudnych czasach.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Sympathy-_X_-1c.jpg?v=1720189944",
            "/images/products/Condolence-Card-2024_1_e92f32ec-6d10-4a56-8283-6808ba8a180f.jpg?v=1720189950",
            "/images/products/Sympathy-_X_-2c.jpg?v=1720189943"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Chryzantemy. Orchidee. Gerbera . Lilie . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 1080,
        "name": "Charming Reunion w CNY",
        "handle": "charming-reunion-其乐融融-cny",
        "description": "Charming Reunion 其乐融融Czy jest lepszy sposób na powiedzenie „Szczęśliwego Nowego Roku” niż kosz pysznych smakołyków? Ten kosz na prezenty zawiera wysokiej jakości ptasie gniazdo, BBQ Bak Hwa i desery odpowiednie dla całej rodziny.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "280.46",
        "inclTax": "280.46",
        "img": [
            "/images/products/Charming-Reunion-_-CNY1-419182.jpg?v=1706283641",
            "/images/products/Charming-Reunion-_-CNY2-412723.jpg?v=1706283641"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Sok musujący Royal Select 750ml",
                "2. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "3. Delfi Czekolada Migdałowa 180g",
                "4. Nowy Peng Hiang BBQ Bak Hwa 100g",
                "5. Ciasteczka motylkowe z Hongkongu Sweetheart 66g",
                "6. Ciasto Ananasowe Rodziny Królewskiej 138g",
                "7. Solone Jajeczne Chrupiące Krakersy Ze Skórką Ryby 50g",
                "8. Pudding Hokkaido Love 50g",
                "9. 柿柿如意 Cukierki mleczne 52g",
                "10. Mala Grzyb Hotpot 30g",
                "11. Ręcznie robiona bułka jajeczna z Hongkongu 38g",
                "12. Ciasteczka Yam 80g",
                "13. Mieszane przekąski wielbłądzie 80g",
                "14. Wykwintne opakowanie CNY",
                "–皇家精选气泡果汁 750ml",
                "–人参银耳冰糖燕窝 3x70ml",
                "– papierosy 180g",
                "– 新品香烤肉干 100g",
                "–味与森甜心蝴蝶酥 66g",
                "– 138 g",
                "–香脆咸蛋黄鱼皮 50g",
                "– 北海道爱心布丁 50g",
                "– 柿柿如意牛奶糖 52g",
                "–麻辣香锅香菇脆片 30g",
                "–雅佳港式古法手工蛋卷 38g",
                "– 香芋曲奇 80g",
                "– 混合零食 80g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1081,
        "name": "Delights Reunion 福满人间 CNY",
        "handle": "delights-reunion-福满人间-cny",
        "description": "Delights Reunion 福满人间Delights Reunion łączy w sobie harmonijną mieszankę Bellevie Pavillon Merlot, luksusowego uchowca i róg obfitości przekąsek, od ciasteczek migdałowych po bułki z serem. Każdy przedmiot jest starannie dobrany, aby zwiększyć radość dzielenia się i wspólnego świętowania.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "385.43",
        "inclTax": "385.43",
        "img": [
            "/images/products/Delights-Reunion-_-CNY1-936409.jpg?v=1706283699",
            "/images/products/Delights-Reunion-_-CNY2-194446.jpg?v=1706283699"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Bellevie Pavillon Merlot 75cl",
                "2. 大家发 Uchowiec w solance 425g",
                "3. Kinohimitsu Longan",
                "4. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "5. Esencja Kurczaka z Żeń-szeniem",
                "6. Ciasteczka Migdałowe Yamazaki 160g",
                "7. Tarty ananasowe CNY 102g",
                "8. Premium Pieczony Kasztan Ringent 75g",
                "9. Ciasteczka Yam 80g",
                "10. Ciasto Bananowe Tokio 52g",
                "11. Kueh Bangkit Pandan o smaku 60g",
                "12. Mieszane przekąski wielbłądzie 80g",
                "13. Bułka jajeczna Macimimi Lava Cheese 100g",
                "14. Wykwintne opakowanie CNY",
                "–红酒 75cl",
                "–大家发卤水鲍鱼425g",
                "–Kinohimitsu 龙眼枸杞燕窝 150ml",
                "–人参银耳冰糖燕窝 3x70ml",
                "– 人参虫草鸡精 3x70ml",
                "–Yamazaki 杏仁饼 160g",
                "– 102 g",
                "–特级烤板栗75g",
                "– 香芋曲奇 80g",
                "–东京香蕉牛奶味蛋糕 52g",
                "–班兰味薯粉饼 60g",
                "– 混合零食 80g",
                "–麦米米芝士味流心蛋酥 100g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1082,
        "name": "Warmth Reunion w CNY",
        "handle": "warmth-reunion-瑞雪迎春-cny",
        "description": "Warmth Reunion 瑞雪迎春Warmth Reunion to toast za kameralne spotkania. Wyśmienite Bordeaux i francuska brandy, uzupełnione napojami z ptasich gniazd i różnorodnymi przekąskami, od ręcznie robionych ciasteczek po bułki z krewetkami, to doskonały sposób na dzielenie się ciepłem i radością w tym świątecznym okresie.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "536.04",
        "inclTax": "536.04",
        "img": [
            "/images/products/Warmth-Reunion-_-CNY1-146608.jpg?v=1706458224",
            "/images/products/Warmth-Reunion-_-CNY2-852483.jpg?v=1706458224"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Vieux Chateau Lamothe Bordeaux 75cl",
                "2. Francuska brandy XO Wieża Eiffla 20cl",
                "3. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "4. Napój Yu Xiang Yan White Fungus Bird's Nest 3 x 75ml",
                "5. Pudding Hokkaido Love 50g",
                "6. Tokio Ręcznie robione ciasteczka motylkowe 30g",
                "7. Ciasteczka Migdałowe Yamazaki 160g",
                "8. Solone Jajeczne Chrupiące Krakersy Ze Skórką Ryby 50g",
                "9. Ciasteczka z kremem lawowym Premium 96g",
                "10. Nowa bułka z krewetkami Peng Hiang 80g",
                "11. Premium Pieczony Kasztan Ringent 75g",
                "12. Vikoi Happy Peanuts 60g",
                "13. Ciasteczka Yam 80g",
                "14. Wykwintne opakowanie CNY",
                "–红酒 75cl",
                "–X.O 埃菲尔铁塔白兰地 20cl",
                "–人参银耳冰糖燕窝 3x70ml",
                "–宇鄕燕银耳燕窝系列 75ml x 3",
                "– 北海道爱心布丁 50g",
                "–Martha 东京手制蝴蝶酥 30g",
                "–Yamazaki 杏仁饼 160g",
                "–香脆咸蛋黄鱼皮 50g",
                "–熔岩奶油曲奇 96g",
                "–新品香香酥虾米卷 80g",
                "–特级烤板栗75g",
                "– 60 g",
                "– 香芋曲奇 80g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1083,
        "name": "Zestaw upominkowy Botanica De Dragon",
        "handle": "botanica-de-dragon-gift-set-1-cny",
        "description": "Zestaw upominkowy Botanica De Dragon",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Botanica-De-Dragon-Gift-Set-_1-CNY1.jpg?v=1706241942",
            "/images/products/Botanica-De-Dragon-Gift-Set-_1-CNY2.jpg?v=1706241941"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Edycja limitowana Dragon Year 2024 Botanica Chinoiserie Domowy dyfuzor zapachowy z trzciny 100ml",
                "2. Mandarynki x 8",
                "3. Przewoźnik CNY Red Box"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1084,
        "name": "Zestaw upominkowy Botanica De Dragon",
        "handle": "botanica-de-dragon-gift-set-2-cny",
        "description": "Zestaw upominkowy Botanica De Dragon",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "321.53",
        "inclTax": "321.53",
        "img": [
            "/images/products/Botanica-De-Dragon-Gift-Set-_2-CNY1-829305.jpg?v=1706283399",
            "/images/products/Botanica-De-Dragon-Gift-Set-_2-CNY2.jpg?v=1706242299"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Edycja limitowana Dragon Year 2024 Botanica Chinoiserie Domowy dyfuzor zapachowy z trzciny 100ml",
                "2. Kulka Ananasowa Flour Power w Słoiczku 15szt",
                "3. JP. Syrop Chenet Cabernet 250ml",
                "4. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "5. Przewoźnik CNY Red Box"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1085,
        "name": "Zestaw upominkowy Botanica De Dragon",
        "handle": "botanica-de-dragon-gift-set-3-cny",
        "description": "Zestaw upominkowy Botanica De Dragon",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "360.33",
        "inclTax": "360.33",
        "img": [
            "/images/products/Botanica-De-Dragon-Gift-Set-_3-CNY1-159049.jpg?v=1706283399",
            "/images/products/Botanica-De-Dragon-Gift-Set-_3-CNY2.jpg?v=1706242735"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Edycja limitowana Dragon Year 2024 Botanica Chinoiserie Domowy dyfuzor zapachowy z trzciny 100ml",
                "2. Świetlik duszony Amidori Abalone 425g",
                "3. Zawiąż herbatę Guan Yin w puszce",
                "4. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "5. Przewoźnik CNY Red Box"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1086,
        "name": "Pokój (X)",
        "handle": "peace-x",
        "description": "Elegancja rozwija się w naszym stojaku na kwiaty kondolencyjne, będącym spokojnym hołdem w kojącej palecie fioletu, zieleni i bieli. Ta aranżacja, obejmująca delikatne piękno kapustnych, chryzantem, orchidei i lilii, symbolizuje serdeczne współczucie. Wyrażając pocieszenie i pamięć, stanowi pełen wdzięku gest pocieszenia w chwilach smutku i straty.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/Peace-_X_-1b.jpg?v=1720188108",
            "/images/products/Condolence-Card-2024_1_d7f80257-d058-42da-9f69-bac63311b8fb.jpg?v=1720188141",
            "/images/products/Peace-_X_-2b.jpg?v=1720188109"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Mamo. Lilia. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 120cm*180cm"
    },
    {
        "id": 1087,
        "name": "Życzliwość (VI)",
        "handle": "benevolence-vi",
        "description": "W ramach delikatnego hołdu nasz stojak na kwiaty kondolencyjne charakteryzuje się spokojną mieszanką odcieni zieleni i bieli. Pełen wdzięku chryzantemy, delikatne orchidee i spokojne lilie splatają się, tworząc spokojną kompozycję. Symbolizujące kondolencje i współczucie, to stoisko zapewnia pocieszenie i pocieszenie w chwilach smutku, wyrażając serdeczne wsparcie i pamięć.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Benevolence-_VI_-1b.jpg?v=1720157133",
            "/images/products/Condolence-Card-2024_1_3feb5cea-8df3-4f73-b203-604dcb8124d7.jpg?v=1720157148",
            "/images/products/Benevolence-_VI_-2b.jpg?v=1720157133"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Orchidee. Lilie . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1088,
        "name": "Huat VI",
        "handle": "huat-vi",
        "description": "Wprowadź dobrobyt dzięki naszemu kwiatowemu arcydziełu. Szkarłatne tulipany i złote chryzantemy w elegancki sposób oznaczają szczęście i bogactwo, dzięki czemu będą idealnym centralnym elementem obchodów Nowego Roku.",
        "type": "Bukiety",
        "tags": [],
        "price": "202.82",
        "inclTax": "202.82",
        "img": [
            "/images/products/Huat-_VI_-1b-626064.jpg?v=1706716479",
            "/images/products/Huat-_VI_-2b-986229.jpg?v=1706716479"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Cipka Willow. Zachowana hortensja"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*40cm"
    },
    {
        "id": 1089,
        "name": "Owocowe szaleństwo FHP",
        "handle": "fruit-frenzy-fhp",
        "description": "Owocowe szaleństwo",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe",
            "Kosze korporacyjne"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Fruit-Frenzy-FHP1_4727cf8a-2a6f-4152-8197-538bcf1df50b-746394.jpg?v=1707148895",
            "/images/products/Fruit-Frenzy-FHP2_4340f9d4-0ebe-45e5-9785-62516617cb58-736790.jpg?v=1707148895"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pomarańczowy x 2",
                "2. Gruszka Forelle x 2",
                "3. Jabłko Fuji x 2",
                "4. Różne dekoracje kwiatowe",
                "5. Wykwintna drewniana skrzynia (M)"
            ]
        },
        "dimention": "Rozmiar Kosza Upominkowego : 30cm x 23cm x 25cm"
    },
    {
        "id": 1090,
        "name": "Harmony Reunion w CNY",
        "handle": "harmony-reunion-花好月圆-cny",
        "description": "Harmony Reunion Podaruj ukochanej osobie ciepło i radość podczas nadchodzących pomyślnych wakacji dzięki pięknemu, tematycznemu pudełku zawierającemu odmładzające ptasie gniazdo, przepyszne ciasteczka i inne słodkie smakołyki.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "394.56",
        "inclTax": "394.56",
        "img": [
            "/images/products/Harmony-Reunion-_-CNY1-796246.jpg?v=1707148989",
            "/images/products/Harmony-Reunion-_-CNY2-381455.jpg?v=1707148989"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "2. Praliny Excelcium BonBons De Chocolat 200g",
                "3. Nowy Peng Hiang BBQ Bak Hwa 100g",
                "4. Ciasteczka motylkowe z Hongkongu Sweetheart 66g",
                "5. Ciasto ananasowe rodziny królewskiej 138g",
                "6. Ciasteczka Migdałowe 80g",
                "7. Pudding Hokkaido Love 50g",
                "8. 柿柿如意 Cukierki mleczne 52g",
                "9. Mala Grzyb Hotpot 30g",
                "10. Ręcznie robiona bułka jajeczna z Hongkongu 38g",
                "11. Ciasteczka Yam 80g",
                "12. Mieszane przekąski wielbłądzie 80g",
                "13. Znakomite opakowanie CNY",
                "–人参银耳冰糖燕窝 3x70ml",
                "– 200 g",
                "– 新品香烤肉干 100g",
                "–味与森甜心蝴蝶酥 66g",
                "– 138 g",
                "– 80g",
                "– 北海道爱心布丁 50g",
                "– 柿柿如意牛奶糖 52g",
                "–麻辣香锅香菇脆片 30g",
                "–雅佳港式古法手工蛋卷 38g",
                "– 香芋曲奇 80g",
                "– 混合零食 80g",
                "–精美贺年包装"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1091,
        "name": "Prosperous Joy w CNY",
        "handle": "prosperous-joy-福运财生-cny",
        "description": "Prosperous Joy 福运财生Odkryj świat dobrobytu dzięki temu koszowi. Zawierający Jadeit Abalone z Morza Południowojapońskiego, odżywczą esencję z kurczaka i inne wybrane produkty premium, symbolizuje rozkwit bogactwa i szczęścia w obfitości.",
        "type": "Kosze Upominkowe",
        "tags": [],
        "price": "301.00",
        "inclTax": "301.00",
        "img": [
            "/images/products/Prosperous-Joy-_-CNY1-116024.jpg?v=1707149230",
            "/images/products/Prosperous-Joy-_-CNY2-363152.jpg?v=1707149230"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. 鲍今天 Jade Abalone w solance 425g",
                "2. Esencja Nowiu Kurczaka z Kordycepsem 68ml x 3",
                "3. Grzyb Premium Biały 100g",
                "4. Zupa Yu Xiang Yan Premium z przegrzebkami rybnymi 425g",
                "5. Grzyby Herbaciane Superior 100g",
                "6. Daktyle Czerwone Premium 100g",
                "7. Napoje Kinohimitsu Birds Nest 3s 75ml w pudełku prezentowym",
                "8. Wykwintny kosz upominkowy w CNY",
                "–鲍今天卤水鲍鱼425g",
                "–人月牌虫草鸡精 68ml x 6",
                "– 特级银耳 100g",
                "–宇鄉燕特级带子花胶汤 425g",
                "– 100 g",
                "– 100 g",
                "–Kinohimitsu燕窝饮品礼盒 75ml x 3",
                "–精美贺年礼篮"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1092,
        "name": "Harmonia (VII)",
        "handle": "harmony-vii",
        "description": "Przedstawiamy nasz oszałamiający stojak na kwiaty na wielkie otwarcie, tętniącą życiem i luksusową aranżację zaprojektowaną, aby urzekać i celebrować radosną okazję nowego początku. Ten wspaniały ekspozytor, wykonany z niezwykłą dbałością o szczegóły, zawiera harmonijną mieszankę słoneczników, jaskraworóżowych gerber, czerwonych anturium i trzech wykwintnych kwiatów imbiru, emanujących elegancją i urokiem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Harmony-_VII_-1b.jpg?v=1720495161",
            "/images/products/Grand-Opening-Card-2024_fe5c9384-2581-40ea-a0ea-409b8c45b0d0.jpg?v=1720596783",
            "/images/products/Harmony-_VII_-2b-992071.jpg?v=1720552234"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Gerbera . Anturium . Kwiat Imbiru. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 60cm*160cm"
    },
    {
        "id": 1093,
        "name": "Prosty urok GHP",
        "handle": "simple-charm-ghp",
        "description": "Prosty urok",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze korporacyjne"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Simple-Charm-GHP1_e9926aaf-13c3-492b-8d33-f9deb0d3f2cd.jpg?v=1718172658",
            "/images/products/Simple-Charm-GHP2_69c6ccec-75e3-46b7-81f6-bd01381522d7.jpg?v=1718172658"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Jacobs Creek Shiraz Cabernet 187ml",
                "2. Jacobs Creek Chardonnay 187ml",
                "3. Hamlet z Kokardą Mieszanka Belgijskiej Czekolady 125g",
                "4. Marcepan San Andres z owocami kandyzowanymi 200g",
                "5. Gavottes Crepes Dentelle 125g",
                "6. Okrągłe pudełko upominkowe"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1094,
        "name": "Gourmet Bites GHP",
        "handle": "gourmet-bites-ghp",
        "description": "Przekąski dla smakoszy",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze korporacyjne"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Gourmet-Bites-GHP1_d1a2cad6-5485-40a2-8817-4b113847e2c1.jpg?v=1718172184",
            "/images/products/Gourmet-Bites-GHP2.jpg?v=1718172184"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Dip La Costena Salsa w szklanym słoiczku 453g",
                "2. Chipsy Tortilla Misyjna 65g",
                "3. Nerkowce Z Sezamem Miodowym 100g",
                "4. Herbata Taylors of Harrogate Earl Grey 125g",
                "5. Ore Liete Cantuccini z kawałkami ciemnej czekolady 180g",
                "6. Nibbles Premium Natural Mieszane Orzechy 45g",
                "7. Tradycyjne paluszki chlebowe Primo Forno z parmezanem 125g",
                "8. Wykwintna drewniana skrzynia (L)"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1096,
        "name": "Zarumieniony wiosenny FNB",
        "handle": "blushing-springtime-fnb",
        "description": "Rumieniąc się na wiosnę Chcesz wysłać komuś czekoladki? Ten ładny różowy kosz na czekoladę z pewnością sprawi, że się zarumienią! Ciesz się butelką JP. Chenet Colombard Sauvignon 250ml wraz z różnorodnymi belgijskimi czekoladkami, dostarczane w wykwintnym, ręcznie wykonanym pudełku.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie",
            "Bukiet balonów urodzinowych",
            "Balony i kwiaty gratulacyjne",
            "Balony i kwiaty na rocznicę"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Blushing-Springtime-FNB1_11d4fdba-83f9-4b04-8606-c4be463708f9.jpg?v=1709004160",
            "/images/products/Blushing-Springtime-FNB2_e0a49a91-d9d2-44c4-9901-ccaba82d452c.jpg?v=1709004159"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. JP. Chenet Colombard Sauvignon 250ml",
                "2. Czekoladki Belgijskie Hamlet Asortyment 125g",
                "3. Czekoladki Praliny Excelcium Tradycja 180g",
                "4. Wykwintne, ręcznie wykonane pudełko na kapelusze",
                "5. Spersonalizowany tekst na balonie z 4 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 1097,
        "name": "Świetlna radość FNB",
        "handle": "luminous-joy-fnb",
        "description": "Luminous Joy Rozbudzaj radość dzięki balonowi Luminous Joy! Idealny na okazje wymagające odrobiny ekstrawagancji, ten wyśmienity kosz na żywność z JP. Chenet Colombard Sauvignon 250ml, różnorodne czekoladki i bonsy zachwycą najbardziej wybrednych smakoszy! Spersonalizuj swoją okazję za pomocą wiadomości na naszym balonie ekspozycyjnym! ",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie",
            "Bukiet balonów urodzinowych",
            "Balony i kwiaty gratulacyjne",
            "Balony i kwiaty na rocznicę"
        ],
        "price": "346.64",
        "inclTax": "346.64",
        "img": [
            "/images/products/Luminous-Joy-FNB1-391031.jpg?v=1709056689",
            "/images/products/Luminous-Joy-FNB2-102134.jpg?v=1709056689"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Praliny Excelcium BonBons De Chocolat 200g",
                "2. Czekolada Asortyment Hamlet 125g",
                "3. Pomarańczowe drzewko tipi",
                "4. Cavendisha",
                "5. Lindt Lindor Mieszana szwajcarska czekolada 200g",
                "6.JP. Chenet Colombard Sauvignon 250ml",
                "7. Ciasteczka Waflowe Beryls 80g",
                "8. Masło Owocowe ST Dalfour ( 3 x 28g )",
                "9. Praliny Excelcium Mieszane Czekolady 180g",
                "10. Spersonalizowany tekst na balonie z 6 mini balonami",
                "11. Wykwintny kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 1098,
        "name": "Macchiato Delight FNB",
        "handle": "macchiato-delight-fnb",
        "description": "Macchiato DelightNasz Macchiato Delight powstał z myślą o dzieleniu się nim, ale także sam w sobie jest przyjemny. Delektuj się dwoma klasycznymi Carbernetem i Chardonnay z Jacob’s Creek w połączeniu z smakołykami, takimi jak Cupido Latte Macchiato Dessert Cups i Walters Hazelnut Macadamia Almond Nugat. Idealny kosz na jedzenie na wspaniały wieczór!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie",
            "Bukiet balonów urodzinowych",
            "Balony i kwiaty gratulacyjne",
            "Balony i kwiaty na rocznicę"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Macchiato-Delight-FNB1_4896a40e-cead-45b6-b2f5-5a5fc395abda.jpg?v=1709008282",
            "/images/products/Macchiato-Delight-FNB2A.jpg?v=1709008282"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Jacobs Creek Shiraz Cabernet 187ml",
                "2. Jacobs Creek Klasyczne Chardonnay 187ml",
                "3. Kubeczki deserowe Cupido Latte Macchiato 125g",
                "4. Nugat migdałowo-migdałowy Walters Macadamia z orzechami laskowymi 120g",
                "5. Wykwintne, ręcznie wykonane pudełko na kapelusze",
                "6. Spersonalizowany tekst na balonie z 4 mini balonami"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 1099,
        "name": "Pyszne przysmaki FNB",
        "handle": "delicious-delights-fnb",
        "description": "Delicious Delights Świętuj osiągnięcia ukochanej osoby, oferując szereg smacznych smakołyków! Kosz Delicious Delights, wypełniony najsmaczniejszymi przekąskami, zaspokoi apetyt odbiorcy i sprawi, że będzie miał ochotę na więcej. Dodaj spersonalizowaną notatkę, a będzie to idealny prezent na każdą okazję wymagającą prawdziwego świętowania!",
        "type": "Balony i Kwiaty",
        "tags": [
            "Bukiet balonów na zdrowie",
            "Bukiet balonów urodzinowych",
            "Balony i kwiaty gratulacyjne",
            "Balony i kwiaty na rocznicę"
        ],
        "price": "198.31",
        "inclTax": "198.31",
        "img": [
            "/images/products/Delicious-Delights-FNB1-627427.jpg?v=1709056409",
            "/images/products/Delicious-Delights-FNB2.jpg?v=1709009892"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "1. Cudowne Ciastko Lisów 180g",
                "2. Oreo Holenderski Wafel Kakaowy 140g",
                "3. Bułka Waflowa Oreo 54g",
                "4. Tradycyjny napój gazowany Bickfords 275ml",
                "5. Ritter Sport Czekolada 100g",
                "6. Spersonalizowany tekst na balonie z 4 mini balonami",
                "7. Wykwintne, ręcznie robione pudełko na kapelusze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 1100,
        "name": "Pinky Paradise FNB",
        "handle": "pinky-paradise-fnb",
        "description": "Pinky ParadiseWitamy w Pinky Paradise, gdzie maluchy mogą bawić się i odpoczywać w wielkim stylu! Ten uroczy koszyczek dla niemowląt to idealny prezent dla każdego nowego rodzica, który chce rozpieszczać swoją małą księżniczkę. Dzięki pakietowi różowych balonów, które dodadzą koloru każdemu pomieszczeniu, ten kosz z pewnością będzie hitem.",
        "type": "Balony i Kwiaty",
        "tags": [
            "Kwiaty na powitanie noworodka",
            "Kosze dla niemowląt",
            "Bukiet balonów na powitanie noworodka"
        ],
        "price": "303.28",
        "inclTax": "303.28",
        "img": [
            "/images/products/Pinky-Paradise-FNB1.jpg?v=1709010673",
            "/images/products/Pinky-Paradise-FNB2-164079.jpg?v=1709056797"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Organiczna poduszka kształtująca głowę BabyK",
                "2. Kayen",
                "3. Kayen",
                "4. Miniksiążeczka BabyK Keepsake",
                "5. BabyK Baby Bath Masażer skóry głowy",
                "6. Silikonowa zabawka żyrafa BabyK",
                "7. Koc polarowy",
                "8. Spersonalizowany tekst na balonie z 6 mini balonami",
                "9. Ręcznie wykonany kosz wiklinowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*50cm"
    },
    {
        "id": 1101,
        "name": "Gloriana",
        "handle": "gloriana",
        "description": "Rozjaśnij czyjś dzień tym uroczym bukietem, żywą mieszanką skąpanego w słońcu słonecznika, delikatnych róż i bujnej eustomy. Ta kompozycja kwiatowa, elegancko zapakowana w miękki beżowy papier, to wspaniały prezent, idealny na urodziny, rocznice lub po prostu. „Kwiaty Polska” obiecują świeże, wykwintne kwiaty, które symbolizują radość i uczucie.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Gloriana-1c.jpg?v=1720881017",
            "/images/products/Gloriana-2c.jpg?v=1720881017"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Goździk .  Słonecznik.  Tulipan.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1102,
        "name": "Gawriła",
        "handle": "gavrila",
        "description": "Uchwyć esencję spokojnego ogrodu za pomocą tego bukietu, zawierającego odważny słonecznik otoczony spokojną fioletową kaspią i uzupełniony delikatną białą kaspią. Przewiązana delikatną różową wstążką kompozycja od „Flowers Poland” to pogodny akcent, idealny do wyrażenia uczuć, wdzięczności lub po prostu celebrowania piękna natury.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Gavrila-1d.jpg?v=1720531324",
            "/images/products/Gavrila-2d.jpg?v=1720531324"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik.  Kaspia"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*28cm"
    },
    {
        "id": 1103,
        "name": "Gavina",
        "handle": "gavina",
        "description": "Bukiet „Flowers Poland” emanuje romantyzmem, z pluszowymi różowymi, jaskrawoczerwonymi goździkami osadzonymi w chmurze dziecięcego oddechu. Otulony szykowną bielą i delikatnym beżem oraz wykończony delikatną różową wstążką, to klasyczny gest miłości i ponadczasowy symbol elegancji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Gavina-2b-546805.jpg?v=1721049268",
            "/images/products/Gavina-1b.jpg?v=1721010611"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1104,
        "name": "Graelyna",
        "handle": "graelyn",
        "description": "Ten wykwintny bukiet emanuje słonecznym słonecznikiem, otoczonym rumianymi goździkami i pienistym oddechem dziecka, tworząc idealną harmonię koloru i faktury. Ozdobiona różową satynową kokardką, to celebracja wdzięku i piękna, idealna na wyjątkowe chwile i codzienną elegancję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z gipsówki",
            "Kwiaty i bukiety goździków",
            "Bukiety słoneczników"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Graelyn-2c.jpg?v=1721059408",
            "/images/products/Graelyn-1c.jpg?v=1721059408"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik.  Goździk .  Oddech dziecka.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1105,
        "name": "Geraldyna",
        "handle": "geraldine",
        "description": "Ten czarujący bukiet różowych goździków przeplatanych liśćmi eukaliptusa prezentuje klasyczny i romantyczny urok. Opakowany w delikatny różowy papier i przewiązany delikatną wstążką, to ponadczasowy wyraz czułości i elegancji. Idealny na każdą okazję, która wymaga odrobiny kwiatowego wyrafinowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Geraldine-2b-140734.jpg?v=1721222758",
            "/images/products/Geraldine-1b.jpg?v=1721182129"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździk . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1106,
        "name": "Świeże kwiaty THP",
        "handle": "fresh-blossoms-thp",
        "description": "Świeże Kwiaty",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "225.69",
        "inclTax": "225.69",
        "img": [
            "/images/products/Fresh-Blossoms-THP1.jpg?v=1710396434",
            "/images/products/Fresh-Blossoms-THP2.jpg?v=1710396435"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 3 w 1",
                "2. Mieszanka Orzechów Kokosowych AmazinGrace Pandan 100g",
                "3. Róże kwiatowe Chiny",
                "4. Goździk kwiatowy Cappucino Holland",
                "5. Zachowany kwiat ryżu",
                "6. Pudełko upominkowe"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1107,
        "name": "Prosta Sonata THP",
        "handle": "simple-sonata-thp",
        "description": "Simple Sonata Podaruj komuś podwieczorek z zestawem upominkowym Simple Sonata, który zawiera mieszankę słodkich orzechów chili Amazin’Grace i pyszne mieszanki herbat kwiatowych Juan Tea 3 w 1. Nasz pyszny zestaw do herbaty z próbnikiem sprawi, że poznasz naszą gamę aromatycznych mieszanek kwiatowych, zielonych i czerwonych herbat, które zostały zaprojektowane, aby odświeżyć Twój nastrój.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "225.69",
        "inclTax": "225.69",
        "img": [
            "/images/products/Simple-Sonata-THP1_29afd350-53ce-4282-a0ba-146945b12887.jpg?v=1710734050",
            "/images/products/Fresh-Blossoms-THP2_11b34faa-f52f-4f82-a07b-e8402a14fec5.jpg?v=1710734050"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 3 w 1",
                "2. Mieszanka Słodkich Orzechów Chili AmazinGrace 100g",
                "3. Goździk holenderski",
                "4. Eustoma kwiatowa (Lisanthum)",
                "5. Zachowany kwiat ryżu",
                "6. Pudełko upominkowe"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1108,
        "name": "Poranny nastrój THP",
        "handle": "morning-mood-thp",
        "description": "Poranny nastrój Nic nie wprawia Cię w lepszy nastrój niż budzenie się przy filiżance orzeźwiającej herbaty! Zestaw pojemników na herbatę Morning Mood zawiera zestaw upominkowy Juan Tea 6 w 1. Jest to kolekcja mocnych herbat czerwonych i zielonych, zmieszanych z najwyższej jakości pąkami, aby zapewnić najlepsze doznania związane z herbatą kwiatową. Przygotuj się na to, by obudzić się z uśmiechem! ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "237.10",
        "inclTax": "237.10",
        "img": [
            "/images/products/Morning-Mood-THP1_4341a278-9398-41fd-b993-53bdab7b22ca-833317.jpg?v=1710797542",
            "/images/products/Evening-Enchantment-THP2_2fd445de-6f90-4313-bcad-fe021e644749.jpg?v=1710735016"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 6 w 1",
                "2. Róże kwiatowe Chiny",
                "3. Goździk holenderski",
                "4. Gerbera kwiatowa",
                "5. Spray z różą kwiatową",
                "6. Mini pompon w kształcie kwiatka",
                "7. Pudełko upominkowe"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1109,
        "name": "Piękny Sonnet THP",
        "handle": "lovely-sonnet-thp",
        "description": "Lovely Sonnet Indulgence ma swoją nazwę i jest to Lovely Sonnet. Zestaw upominkowy Juan Tea 3 w 1 to kolekcja solidnych herbat czerwonych i zielonych zmieszanych z najwyższej jakości pąkami, aby zapewnić najlepsze doznania związane z herbatą kwiatową. Ten zestaw upominkowy z herbatą Gavottes Crepe Dentelle jest naprawdę niezapomniany. ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Lovely-Sonnet-THP1.jpg?v=1710735073",
            "/images/products/Lovely-Sonnet-THP2.jpg?v=1710735072"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 3 w 1",
                "2. Gavottes Crepe Dentelle 125g",
                "3. Eustoma kwiatowa (Lisanthum)",
                "4. Róże kwiatowe Chiny",
                "5. Goździk holenderski kwiatowy",
                "6. Zachowany kwiat ryżu",
                "7. Powojniki kwiatowe",
                "8. Kwiat eukaliptusa",
                "9. Drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1110,
        "name": "Króliczek szczęśliwy THP",
        "handle": "bunny-happy-thp",
        "description": "Bunny Happy Życz komuś szczęśliwego dnia Bunny za pomocą tego kochanego koszyka! Ten piękny zestaw mieszanek, zawierający zestaw upominkowy 3 w 1 Juan Tea, jest idealnym prezentem dla koneserów herbaty. Czerp korzyści zdrowotne z herbat zielonych i czerwonych, które zostały pięknie zmieszane z najwyższej jakości pąkami kwiatowymi. ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "330.66",
        "inclTax": "330.66",
        "img": [
            "/images/products/Bunny-Happy-THP1.jpg?v=1710738320",
            "/images/products/Bunny-Happy-THP2.jpg?v=1710738321"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 3 w 1",
                "2. Pluszowy króliczek",
                "3. Pudełko z mieszanką orzechów Amazin Graze (8 x 30 g)",
                "4. Krakersy The Fine Cheese Co Rose Mary i Extra Virgin Oil 125g",
                "5. Róże kwiatowe Chiny",
                "6. Goździk holenderski kwiatowy",
                "7. Eustoma kwiatowa (Lisanthum)",
                "8. Zachowany kwiat ryżu",
                "9. Pudełko upominkowe"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1111,
        "name": "Króliczek Słodki THP",
        "handle": "bunny-sweet-thp",
        "description": "Bunny Sweet Życz komuś miłego dnia Bunny Sweet dzięki temu uroczemu koszykowi! Ten piękny zestaw mieszanek, zawierający zestaw upominkowy 3 w 1 Juan Tea, jest idealnym prezentem dla koneserów herbaty. Czerp korzyści zdrowotne z herbat zielonych i czerwonych, które zostały pięknie zmieszane z najwyższej jakości pąkami kwiatowymi. ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "330.66",
        "inclTax": "330.66",
        "img": [
            "/images/products/Bunny-Sweet-THP1_f7269095-7a3e-4c58-b448-74af9b126669-307059.jpg?v=1710796873",
            "/images/products/Bunny-Sweet-THP2.jpg?v=1710738999"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 3 w 1",
                "2. Pluszowy króliczek",
                "3. Pudełko z mieszanką orzechów Amazin Graze (8 x 30 g)",
                "4. Krakersy The Fine Cheese Co Rose Mary i Extra Virgin Oil 125g",
                "5. Róże kwiatowe Chiny",
                "6. Goździk holenderski kwiatowy",
                "7. Eustoma kwiatowa (lisanthum)",
                "8. Zachowany kwiat ryżu"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1112,
        "name": "Jesienna ballada THP",
        "handle": "autumn-ballad-thp",
        "description": "Jesienna Ballada Prześlij dobre smaki poprzez wspaniałe smakołyki do podwieczorku dzięki zestawowi upominkowemu do herbaty Jesienna Ballada. Zestaw upominkowy Juan Tea 3 w 1 to kolekcja solidnych herbat czerwonych i zielonych zmieszanych z najwyższej jakości pąkami, aby zapewnić najlepsze doznania związane z herbatą kwiatową. Zestaw do herbaty, zawierający świeżo upieczone ciasteczka firmy Temptations i różnorodne czekoladki marki Hamlet, zachwyci nawet najbardziej wybredne podniebienia!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "330.66",
        "inclTax": "330.66",
        "img": [
            "/images/products/Autumn-Ballad-THP1-340178.jpg?v=1710796680",
            "/images/products/Autumn-Ballad-THP2-960485.jpg?v=1710796681"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 3 w 1",
                "2. Pokusa Świeżo Upieczone Ciasteczka Masło",
                "3. Czekolada Asortyment Hamlet 125g",
                "4. Gerbera kwiatowa",
                "5. Goździk holenderski kwiatowy",
                "6. Róże kwiatowe",
                "7. Eustoma kwiatowa (Lisanthum)",
                "8. Kwiat eukaliptusa",
                "9. Drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1113,
        "name": "Szczęśliwego słońca THP",
        "handle": "happy-sunshine-thp",
        "description": "Happy SunshineHappy Sunshine Chcemy, aby Twój odbiorca był taki, gdy otrzyma ten luksusowy zestaw upominkowy z herbatą. Ta kolekcja zestawów do herbaty, zawierająca zestaw upominkowy Juan Tea 6 w 1, gwarantuje, że możesz poznać naszą gamę luksusowych mieszanek herbat kwiatowych, które zostały zaprojektowane, aby uspokoić i podnieść na duchu. Twój odbiorca zostanie poczęstowany pudełkiem z różnorodnością mieszanki orzechów Amazin Graze Nut Mix i asortymentem czekoladek Hamlet. Mogą również zaparzyć nasze najwyższej jakości liście herbaty w szklanej butelce Juan Tea, aby zapewnić sobie prawdziwie rozkoszne doznania.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Happy-Sunshine-THP1-147841.jpg?v=1710797234",
            "/images/products/Happy-Sunshine-THP2-294014.jpg?v=1710797234"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 6 w 1",
                "2. Pudełko z mieszanką orzechów Amazin Graze (8 x 30 g)",
                "3. Szklana butelka na herbatę Juan",
                "4. Czekolada Asortyment Hamlet 125g",
                "5. Gerbera kwiatowa",
                "6. Róże kwiatowe Chiny",
                "7. Eustoma kwiatowa (Lisanthum)",
                "8. Kwiat eukaliptusa",
                "9. Kwiat wosku",
                "10. Drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1114,
        "name": "Rumieniąca się ballada THP",
        "handle": "blushing-ballad-thp",
        "description": "Blushing Ballad Istnieją zestawy upominkowe na herbatę i pyszne kosze na prezent na podwieczorek. Najważniejszym elementem Blushing Ballad jest zestaw upominkowy 3 w 1 Juan Tea. Jest to kolekcja mocnych herbat czerwonych i zielonych, zmieszanych z najwyższej jakości pąkami, aby zapewnić najlepsze doznania związane z herbatą kwiatową. Ten zestaw prezentowy, wypełniony świeżo upieczoną czekoladą i ciasteczkami z podwójną czekoladą firmy Temptations, to naprawdę uniwersalny kosz na herbatę! ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Blushing-Ballad-THP1.jpg?v=1710740362",
            "/images/products/Blushing-Ballad-THP2.jpg?v=1710740363"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 3 w 1",
                "2. Pokusa Świeżo Upieczone Ciasteczka Masło",
                "3. Pokusa Świeżo Upieczone Ciasteczka Podwójna Czekolada",
                "4. Gerbera kwiatowa",
                "5. Eustoma kwiatowa (Lisanthum)",
                "6. Spray z różą kwiatową",
                "7. Mini pompon w kształcie kwiatka",
                "8. Drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1115,
        "name": "Ballada o zachodzie słońca THP",
        "handle": "sunset-ballad-thp",
        "description": "Sunset Ballad Przekazuj dobre smaki poprzez wspaniałe smakołyki do podwieczorku dzięki zestawowi upominkowemu do herbaty Sunset Ballad. Zestaw upominkowy Juan Tea 3 w 1 to kolekcja solidnych herbat czerwonych i zielonych zmieszanych z najwyższej jakości pąkami, aby zapewnić najlepsze doznania związane z herbatą kwiatową. Ten zestaw upominkowy z herbatą, wypełniony świeżo upieczoną czekoladą i ciasteczkami z podwójną czekoladą firmy Temptations, podkręci czas podwieczorku!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze z herbatą",
            "Kosze korporacyjne"
        ],
        "price": "364.89",
        "inclTax": "364.89",
        "img": [
            "/images/products/Sunset-Ballad-THP1.jpg?v=1710740882",
            "/images/products/Sunset-Ballad-THP2-266660.jpg?v=1710798585"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy odpustowy 3 w 1",
                "2. Pokusa Świeżo Upieczone Ciasteczka Masło",
                "3. Pokusa Świeżo Upieczone Ciasteczka Podwójna Czekolada",
                "4. Gerbera kwiatowa",
                "5. Goździk holenderski kwiatowy",
                "6. Róże kwiatowe",
                "7. Eustoma kwiatowa (Lisanthum)",
                "8. Kwiat eukaliptusa",
                "9. Drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1116,
        "name": "Zorah",
        "handle": "zorah",
        "description": "Elegancki i wyrafinowany bukiet składa się z soczystych czerwonych tulipanów w połączeniu z chmurą łyszczec, znaną również jako oddech dziecka. Jest owinięty gradientowym różowym papierem, tworzącym piękny kontrast, i przewiązany długą, różową satynową wstążką. Idealnie nadaje się na uroczy prezent lub specjalną okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "113.87",
        "inclTax": "113.87",
        "img": [
            "/images/products/Zorah-2c.jpg?v=1720662306",
            "/images/products/Zorah-1b.jpg?v=1720453943"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1117,
        "name": "Bogactwo (X)",
        "handle": "wealth-x",
        "description": "Świętuj wielkie otwarcie dzięki tej majestatycznej aranżacji stojącej, zawierającej żywą mieszankę słoneczników, pomarańczowych róż, żółtych kwiatów i charakterystycznych zielonych liści. Zaakcentowany figlarnymi lokami i wielką złotą kokardką, to promienny eksponat, który przekazuje najlepsze życzenia sukcesu i dobrobytu.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Wealth-_X_-1b.jpg?v=1720103922",
            "/images/products/Grand-Opening-Card-2024_bbbee8a1-ee73-4480-9390-2dbb0aef8c9d.jpg?v=1720103928",
            "/images/products/Wealth-_X_-2b.jpg?v=1720103922"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Spryskaj róże. Eustoma. Orchidee. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1118,
        "name": "Pozdrowienia dla zdrowia WHP",
        "handle": "cheers-to-health-whp",
        "description": "Pozdrowienia dla zdrowia",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "250.79",
        "inclTax": "250.79",
        "img": [
            "/images/products/Cheers-To-Health-WHP1.jpg?v=1711600781",
            "/images/products/Cheers-To-Health-WHP2.jpg?v=1711600782"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Ptasie gniazdo z żeń-szeniem i białym grzybem",
                "2. Esencja kurczaka w nowiu z kordycepsem",
                "6 s x 68 ml",
                "3. Esencja Kurczaka z Żeń-szeniem",
                "6 s x 70 ml",
                "4. Wykwintne pudełko upominkowe"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1119,
        "name": "Essential Tonic Wellness Basket z Owocami WHP",
        "handle": "essential-tonic-wellness-basket-with-fruits-whp",
        "description": "Niezbędny tonikowy kosz wellness z owocami",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "200.59",
        "inclTax": "200.59",
        "img": [
            "/images/products/Essential-Tonic-Wellness-Basket-with-Fruits-WHP1-787603.jpg?v=1711667545",
            "/images/products/Essential-Tonic-Wellness-Basket-with-Fruits-WHP2-675199.jpg?v=1711667545"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Yu Xiang Yan Premium Birdnest 3 x 70 ml",
                "2. Esencja Kurczaka z Żeń-szeniem",
                "3s x 70ml",
                "3. Formecs Berry Essence 2 sztuki x 41 ml",
                "4. Opakowania na różne owoce",
                "5. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1120,
        "name": "Esencja Nadzwyczajna WHP",
        "handle": "essence-extraordinary-whp",
        "description": "Esencja niezwykła",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "234.82",
        "inclTax": "234.82",
        "img": [
            "/images/products/Essence-Extraordinary-WHP1.jpg?v=1711601755",
            "/images/products/Essence-Extraordinary-WHP2.jpg?v=1711601755"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Bao Jin Tian Pojedynczy uchowiec w butelce po 3 sztuki w pudełku prezentowym",
                "2. Gniazdo dla ptaków Yu Xiang Yan Premium 3 x 70 ml",
                "3. Esencja kurczaka w nowiu z kordycepsem",
                "3x68ml",
                "4. Różne opakowania kwiatowe",
                "5. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1121,
        "name": "Życzenia odnowy biologicznej WHP",
        "handle": "wellness-wish-whp-1",
        "description": "Życzenie dobrego samopoczucia",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie"
        ],
        "price": "243.95",
        "inclTax": "243.95",
        "img": [
            "/images/products/Wellness-Wish-WHP1.jpg?v=1711602130",
            "/images/products/Wellness-Wish-WHP2.jpg?v=1711602130"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Bao Jin Tian Pojedynczy uchowiec w butelce po 3 sztuki w pudełku prezentowym",
                "2. Gniazdo dla ptaków Yu Xiang Yan Premium 6 x 70 ml",
                "3. Tie Guan Yin sypka herbata w puszce",
                "4. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1122,
        "name": "VinoVerde zachwyca GHP",
        "handle": "chocolate-dreams-ghp",
        "description": "VinoVerde zachwyca",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze z jedzeniem premium",
            "Kosze czekoladowe",
            "Kosze korporacyjne"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/VinoVerde-Delights-GHP1-681963.jpg?v=1712016337",
            "/images/products/VinoVerde-Delights-GHP2.jpg?v=1711949085"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zamek Les Martineaux Bordeaux 75cl",
                "2. Heidi Mountains Orzechy laskowe Premium Czekolada 150g",
                "3. La Mere Poulard Les Cookies tout Chocolat – ciasteczka z kawałkami czekolady w puszce 200g",
                "4. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 1123,
        "name": "Tessa",
        "handle": "tessa",
        "description": "Przywołaj blask wiosny z Tessą, uroczym koszykiem pełnym najdelikatniejszych różowych goździków. Każdy kwiat to delikatny szept miłości, umieszczony w rustykalnym, ręcznie tkanym koszu, akcentowany jedwabistą wstążką, ucieleśniający ponadczasową elegancję na każdą cenną okazję lub spokojną chwilę.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Tessa-1b-559761.jpg?v=1713318478",
            "/images/products/Tessa-2b.jpg?v=1713262484"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*30cm"
    },
    {
        "id": 1124,
        "name": "Cerulean",
        "handle": "cerulean",
        "description": "Przedstawiamy Cerulean, symfonię błękitnych goździków otulonych lazurową chustą, ozdobionych opalizującymi wstążkami. Bukiet ten oddaje spokojne piękno spokojnego morza, oferując spokojny szept elegancji natury na chwile refleksji lub świętowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Cerulean-2c-342854.jpg?v=1721222468",
            "/images/products/Cerulean-1c.jpg?v=1721181252"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1125,
        "name": "Aimee",
        "handle": "aimee",
        "description": "Przedstawiamy „Aimee” – wizja pierwszego różu wiosny „Aimee” to wykwintny bukiet goździków z różowymi końcówkami osadzonymi na delikatnych białych kwiatach. Otulona delikatnymi odcieniami różu kompozycja szepcze niewinność i celebruje delikatną radość kwitnącego piękna natury. Złóż zamówienie już teraz i ciesz się bezpłatną dostawą kwiatów na terenie całej Polski.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Aimee-2a.jpg?v=1721181466",
            "/images/products/Aimee-1a.jpg?v=1721181465"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1126,
        "name": "Delia",
        "handle": "delia",
        "description": "Różowe goździki swymi delikatnymi płatkami emanują uczuciem, a biała eustoma symbolizuje czystość i spokój. Razem tworzą bukiet elegancji i niewinności. Zdobądź je już dziś, aby wyrazić swoje uznanie.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Delia-2e.jpg?v=1721181982",
            "/images/products/Delia-1e.jpg?v=1721181982"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki. Eustoma"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1127,
        "name": "Glicynia",
        "handle": "wisteria",
        "description": "„Wisteria” to bukiet, który oddaje spokój pogodnego ogrodu dzięki delikatnemu zestawowi białych i subtelnych lawendowych goździków. Delikatny szept koloru spleciony z jedwabiście liliowymi wstążkami sprawia, że ​​jest to pełen wdzięku hołd dla spokojnej elegancji i ponadczasowego wyrafinowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety goździków"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Wisteria-2b.jpg?v=1720952378",
            "/images/products/Wisteria-1b.jpg?v=1720952378"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1128,
        "name": "Emilia",
        "handle": "emily",
        "description": "Ta urocza kompozycja kwiatowa obejmuje wybór bogatych, aksamitnie czerwonych róż w połączeniu z bujnymi goździkami w podobnym odcieniu, pomysłowo umieszczonymi w rustykalnym, tkanym koszu. Delikatna kokardka z nutą srebra i przezroczystymi akcentami dodaje bukietowi elegancji. Naturalne plecionkarstwo pięknie kontrastuje z bujnymi czerwonymi płatkami, dzięki czemu ta kompozycja będzie idealnym prezentem dla osoby ceniącej klasyczne piękno z domowym akcentem.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Emily-1a-327561.jpg?v=1713490110",
            "/images/products/Emily-2a-229272.jpg?v=1713490110"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki"
            ]
        },
        "dimention": "Szerokość*Wysokość: 25cm*25cm"
    },
    {
        "id": 1129,
        "name": "Miriam",
        "handle": "miriam",
        "description": "Świętuj Dzień Matki ponadczasowym pięknem żywych czerwonych goździków, delikatnie opakowanych w krystalicznie przezroczysty celofan z ciepłą czerwoną wstążką. Ten wykwintny bukiet, symbolizujący miłość, podziw i uczucie, umieszczony na uroczym tle w kształcie serca, jest doskonałym wyrazem wdzięczności dla wyjątkowej kobiety w Twoim życiu. Eleganckie, to coś więcej niż tylko kwiaty — to Twoje serce owinięte w płatki.",
        "type": "Bukiety",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Miriam-1a-709246.jpg?v=1713924938",
            "/images/products/Miriam-2a-801827.jpg?v=1713924938"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki"
            ]
        },
        "dimention": "Szerokość*Wysokość: 10cm*28cm"
    },
    {
        "id": 1130,
        "name": "Mariah",
        "handle": "mariah",
        "description": "W Dzień Matki uhonoruj ​​ją czarującym bukietem różowych goździków, których każdy płatek będzie całowany w różnorodnych odcieniach wdzięku. Kwiaty te, zawinięte w półprzezroczysty różowy rękaw i przewiązane miękką różową wstążką, są szeptem uznania i delikatnym przypomnieniem piękna i siły, które ona ucieleśnia. Idealnie zaprezentowane, są nie tylko prezentem, ale delikatnym uściskiem w kwiatowej formie.",
        "type": "Bukiety",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Mariah-1c-997986.jpg?v=1714098712",
            "/images/products/Mariah-2c-543013.jpg?v=1714098712"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Goździki"
            ]
        },
        "dimention": "Szerokość*Wysokość: 10cm*28cm"
    },
    {
        "id": 1133,
        "name": "Kolorowy pakiet pielęgnacyjny HHP",
        "handle": "colourful-care-package-hhp",
        "description": "Kolorowy pakiet pielęgnacyjny Jeśli Twoja ukochana osoba nie ma pogody, to jest to dla niej pomocnik! Kolorowy pakiet pielęgnacyjny zawiera zestaw esencji z kurczaka i kompozycję kwiatową, która poprawi humor!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze na zdrowie",
            "Kosze z jedzeniem halal"
        ],
        "price": "152.67",
        "inclTax": "152.67",
        "img": [
            "/images/products/Colourful-Care-Package-HHP1.jpg?v=1719280308",
            "/images/products/Colourful-Care-Package-HHP2.jpg?v=1719280308"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Esencja Kinohimitsu Z Kurczaka 6 x 75g",
                "2. Kompozycja z różnych kwiatów",
                "3. Wykwintne pudełko upominkowe na okrągły kapelusz (M)"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm*40cm"
    },
    {
        "id": 1134,
        "name": "Bardolino Classico z Czekoladkami WGHP",
        "handle": "bardolino-classico-with-chocolates-wghp",
        "description": "Bardolino Classico z Czekoladkami  ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "230.25",
        "inclTax": "230.25",
        "img": [
            "/images/products/Bardolino-Classico-with-Chocolates-WGHP1.jpg?v=1719979716",
            "/images/products/Bardolino-Classico-with-Chocolates-WGHP2.jpg?v=1719979717"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Domini Veneti Bardolino Classico 75cl",
                "2. Hamlet z Kokardą Mieszanka Belgijskiej Czekolady 125g",
                "3. Paluszki Czekoladowe Cupido 125g",
                "4. Drewniana skrzynia"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1135,
        "name": "Czary szampana",
        "handle": "champagne-charms-chocolate-wghp",
        "description": "Szampan Charms Czekoladowy ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "312.41",
        "inclTax": "312.41",
        "img": [
            "/images/products/Champagne-Charms-_-Chocolate-WGHP1-195544.jpg?v=1720030233",
            "/images/products/Champagne-Charms-_-Chocolate-WGHP2-867645.jpg?v=1720030233"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Adrien Chopin Champagne Brut NV, Francja 75cl",
                "2. Hamlet z Kokardą Mieszanka Belgijskiej Czekolady 125g",
                "3. Beryls Tiramisu Migdałowa Czekolada Mleczna 65g",
                "4. Okrągłe pudełko upominkowe"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1136,
        "name": "Owocowa niespodzianka FHP",
        "handle": "fruity-surprise-fhp",
        "description": "Owocowa niespodzianka",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe"
        ],
        "price": "125.28",
        "inclTax": "125.28",
        "img": [
            "/images/products/Fruity-Surprise-FHP1-362387.jpg?v=1720030522",
            "/images/products/Fruity-Surprise-FHP2_013fd575-65a0-4be8-80cf-553c54cf298d.jpg?v=1719986341"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Pomarańczowy",
                "2. Gruszka Forelle",
                "3. Jabłko Fuji",
                "4. Winogrona",
                "5. Gruszka Packham",
                "6. Kiwi",
                "7. Zielone jabłko",
                "8. Awokado",
                "9. Smoczy owoc",
                "10. Wykwintna drewniana skrzynia (M)"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1137,
        "name": "Rumieniec",
        "handle": "blush",
        "description": "Ta aranżacja, zawierająca żywą mieszankę słonecznika, róż, stokrotek i eukaliptusa, łączy w sobie szereg kolorów i faktur. Idealny na uroczystości lub jako przemyślany prezent, jest owinięty w elegancki papier, który dodaje mu odrobinę wyrafinowania.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty na zdrowie i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Blush-2b-391330.jpg?v=1720962058",
            "/images/products/Blush-1b_2d601b04-a825-4ab9-8c50-24be33c4b236.jpg?v=1720948619"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róża. Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1138,
        "name": "Sukces (VII)",
        "handle": "success-vii",
        "description": "Ta wspaniała kompozycja to kwiatowe arcydzieło składające się z mieszanki różowych i fioletowych kwiatów, w tym róż, gerber i chryzantem. Zaakcentowany bujną zielenią i elegancko owinięty w lawendowy i różowo-różowy papier, stoi dumnie na czarnym stojaku. Ta aranżacja jest idealna na ważne uroczystości, składanie gratulacji lub uświetnianie każdego eleganckiego wydarzenia swoją oszałamiającą prezencją.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "433.35",
        "inclTax": "433.35",
        "img": [
            "/images/products/Success-_VII_-1b.jpg?v=1721541477",
            "/images/products/Success-_VII_-2b.jpg?v=1721541477"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Brassica. Róże. Gerbera . Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*170cm"
    },
    {
        "id": 1139,
        "name": "Sukces (VIII)",
        "handle": "success-viii",
        "description": "Ta efektowna kompozycja zawiera mnóstwo żywych czerwonych róż, pięknie uzupełnionych bujną zielenią. Róże są elegancko owinięte w luksusowy czarny papier i wystawione na czarnym stojaku, tworząc dramatyczną i wyrafinowaną prezentację. Idealny na wielkie okazje, wyrazy głębokich uczuć lub jako odważny akcent w dowolnym otoczeniu.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "661.55",
        "inclTax": "661.55",
        "img": [
            "/images/products/Success-_VIII_-1b.jpg?v=1721492166",
            "/images/products/Success-_VIII_-2b.jpg?v=1721492166"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*170cm"
    },
    {
        "id": 1140,
        "name": "Sukces (IX)",
        "handle": "success-ix",
        "description": "Ten żywy i wesoły stojący bukiet składa się z mieszanki fioletowych, różowych i niebieskich kwiatów, akcentowanych żółtymi akcentami. Kompozycja jest pięknie opakowana w delikatny pastelowy żółty i lawendowy papier, przewiązana dużą, elegancką kokardką. Idealny do wygłaszania wielkich oświadczeń na każdym wydarzeniu lub uroczystości.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "844.11",
        "inclTax": "844.11",
        "img": [
            "/images/products/Success-_IX_-1b.jpg?v=1721539150",
            "/images/products/Success-_IX_-2a.jpg?v=1721534259"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Goździki. Eustoma. Hortensja. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*170cm"
    },
    {
        "id": 1141,
        "name": "Sukces (X)",
        "handle": "success-x",
        "description": "Ta efektowna aranżacja stoiska zawiera oszałamiającą mieszankę żywych czerwonych róż, wesołych żółtych i różowych gerber oraz bujnej zieleni. Idealny na wielkie uroczystości lub imprezy firmowe, dodaje elegancji i wyrafinowania każdemu miejscu. Stojak z motywem kwiatowym dodaje mu uroku i sprawia, że ​​stanowi on przyciągającą wzrok ekspozycję.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "524.63",
        "inclTax": "524.63",
        "img": [
            "/images/products/Success-_X_-1a.jpg?v=1721624977",
            "/images/products/Success-_IX_-2a_dc5eacec-dc10-4df5-b03d-480a2916cb10.jpg?v=1721624976"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Gerbera .Mattiola . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1142,
        "name": "Łabędź",
        "handle": "swan-1",
        "description": "Przedstawiamy nasz elegancki biały bukiet, harmonijną mieszankę nieskazitelnych białych róż, kalii i bujnej zieleni. Pomysłowo ułożony i owinięty w delikatny biały papier bukiet emanuje ponadczasową elegancją i wyrafinowaniem, dzięki czemu idealnie nadaje się na wesela, rocznice lub inne specjalne okazje. Niech ta wykwintna aranżacja wniesie odrobinę wdzięku w dzień Twojej ukochanej osoby.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety z lilii calla",
            "Kwiaty i bukiety róż"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Swan-2a.jpg?v=1721954106",
            "/images/products/Swan-1a.jpg?v=1721954107"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie kalii. Róże.  Alstremeria.  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1143,
        "name": "Tinker Tale BHP",
        "handle": "tinker-tale-bhp",
        "description": "Tinker TaleKosz prezentowy dla dziecka Tinker Tale upamiętnia piękną historię nowego nabytku. Rozpieszczaj cenne nowonarodzone dziecko wysokiej jakości artykułami pielęgnacyjnymi i niezbędnymi do kąpieli w tym wyjątkowym koszu z organizerem na caddy i zapisuj wspomnienia w Księdze kamieni milowych Hello Baby.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "186.90",
        "inclTax": "186.90",
        "img": [
            "/images/products/Tinker-Tale-BHP1-704288.jpg?v=1722463950",
            "/images/products/Tinker-Tale-BHP2.jpg?v=1722233154"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Kosz z organizerem na pieluchy Baby K",
                "2. Organiczne chusteczki dla niemowląt KinderNurture 40s",
                "3. Suszarki Wee Wee Dry Pieluchy rozmiar M (2 szt.) x 3",
                "4. Fiffy Organiczny krem ​​nawilżający od stóp do głów 750ml",
                "5. Fiffy Organiczny balsam nawilżający 450ml",
                "6. Waciki Fiffy Mini 400 szt",
                "7. Waciki Fiffy 100 wacików",
                "8. Księga kamieni milowych „Witaj kochanie”."
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 1144,
        "name": "Podwójna odwaga BHP",
        "handle": "double-dare-bhp",
        "description": "Double Dare Idealnie wyważony pod każdym względem, ten kosz spełnia wszystkie podstawowe potrzeby dwójki psotnych maluchów. Dla rodziny z córeczką i chłopcem jest to dwa razy więcej zabawy!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Double-Dare-BHP1_98a77e3d-af25-4497-9b51-9a8a85b3b919.jpg?v=1722234550",
            "/images/products/Double-Dare-BHP.jpg?v=1722233783"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy dla niemowląt K x 2",
                "2. Pluszowe zabawki króliczki x 2 zestawy",
                "3. Suszarki Wee Wee Dry Pieluszki M (2 szt.) x 4 opakowania",
                "4. Fiffy Organiczny krem ​​nawilżający od stóp do głów 750ml",
                "5. Fiffy Organiczny balsam nawilżający 450ml",
                "6. Zabawka ząbkująca Baby K Dino przeznaczona do kontaktu z żywnością x 2",
                "7. Organiczne chusteczki dla niemowląt KinderNurture 80s x 2",
                "8. Kubek niekapek Baby K x 2",
                "9. Balony bez helu dla chłopca x 2",
                "10. Wykwintny kosz ze słomy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 1145,
        "name": "Podwójne kłopoty BHP",
        "handle": "double-trouble-bhp",
        "description": "Podwójne kłopoty Dwóch chłopców oznacza podwójne kłopoty, ale oznacza także podwójne emocje! Ten wykwintny kosz obejmuje wszystkie podstawy, od kąpieli po zabawę i od posiłku po czas w samochodzie.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Double-Trouble-BHP1.jpg?v=1722234205",
            "/images/products/Double-Trouble-BHP2.jpg?v=1722234205"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy dla niemowląt K x 2",
                "2. Pluszowe zabawki króliczki x 2 zestawy",
                "3. Suszarki Wee Wee Dry Pieluszki M (2 szt.) x 4 opakowania",
                "4. Fiffy Organiczny krem ​​nawilżający od stóp do głów 750ml",
                "5. Fiffy Organiczny balsam nawilżający 450ml",
                "6. Zabawka ząbkująca Baby K Dino przeznaczona do kontaktu z żywnością x 2",
                "7. Organiczne chusteczki dla niemowląt KinderNurture 80s x 2",
                "8. Kubek niekapek Baby K x 2",
                "9. Balony bez helu dla chłopca x 2",
                "10. Wykwintny kosz ze słomy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 1146,
        "name": "Podwójna rozkosz BHP",
        "handle": "double-delight-bhp",
        "description": "Double DelightDwie dziewczyny to dwa razy więcej pracy, ale także dwa razy więcej chichotu! Kochaj i troszcz się o nowe urocze małe damy dzięki temu wyjątkowemu koszykowi, który obejmuje wszystko, od posiłków po podróże i od kąpieli po zabawę!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "472.15",
        "inclTax": "472.15",
        "img": [
            "/images/products/Double-Delight-BHP1.jpg?v=1722234446",
            "/images/products/Double-Delight-BHP2.jpg?v=1722234446"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Zestaw upominkowy dla niemowląt K x 2",
                "2. Pluszowe zabawki króliczki x 2 zestawy",
                "3. Suszarki Wee Wee Dry Pieluszki M (2 szt.) x 4 opakowania",
                "4. Fiffy Organiczny krem ​​nawilżający od stóp do głów 750ml",
                "5. Fiffy Organiczny balsam nawilżający 450ml",
                "6. Zabawka ząbkująca Baby K Dino przeznaczona do kontaktu z żywnością x 2",
                "7. Organiczne chusteczki dla niemowląt KinderNurture 80s x 2",
                "8. Kubek niekapek Baby K x 2",
                "9. Balony bez helu dla chłopca x 2",
                "10. Wykwintny kosz ze słomy"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 30cm"
    },
    {
        "id": 1147,
        "name": "Denice",
        "handle": "denice-5",
        "description": "Ten elegancki bukiet przedstawia oszałamiające skupisko fioletowych hortensji uzupełnionych białymi kwiatami i zielenią. Owinięty w miękki, biały papier przewiązany lawendową wstążką emanuje pogodnym i wyrafinowanym urokiem, idealnym do wyrażenia swoich uczuć przy każdej wyjątkowej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Denice-2a-117888.jpg?v=1722357534",
            "/images/products/Denice-1a-717087.jpg?v=1722357534"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1148,
        "name": "Eiffla",
        "handle": "eiffel",
        "description": "Zanurz się w uroku romantyzmu dzięki naszemu urzekającemu bukietowi składającemu się z 12 promiennych czerwonych róż elegancko zamkniętych w eleganckim czarnym opakowaniu. Ta wyrafinowana aranżacja emanuje pasją i tajemnicą, dzięki czemu jest idealnym prezentem na rocznice, wyznania miłości lub inną wyjątkową okazję. Podnieś swój gest dzięki temu ponadczasowemu wyrazowi uczuć.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż"
        ],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Eiffel-2a_282c00e5-5bd0-4916-8038-3f32cbdbac0a.jpg?v=1722298228",
            "/images/products/Emani-2b.jpg?v=1722298228"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Listowie"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1149,
        "name": "Elwira",
        "handle": "elvira-1",
        "description": "Rozjaśnij każde pomieszczenie naszym wesołym bukietem róż. Ta żywa kompozycja, zawierająca czarującą mieszankę pomarańczowych, żółtych, brzoskwiniowych, różowych i niebieskich róż, uzupełniona jest delikatnymi stokrotkami i bujnymi liśćmi eukaliptusa. Zapakowany w miękki niebieski papier, idealnie nadaje się do wniesienia odrobiny koloru i radości na każdą uroczystość lub wyjątkowy moment.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Elvira-1a-254605.jpg?v=1722357537",
            "/images/products/Elvira-2a-731628.jpg?v=1722357537"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Spryskaj róże. Tulipan. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 1150,
        "name": "Emberlyn",
        "handle": "emberlyn",
        "description": "Ten bukiet przedstawia żywą mieszankę tulipanów w różnych kolorach, w tym czerwonym, różowym, pomarańczowym i białym. Tulipany są elegancko ułożone i owinięte w półprzezroczysty, opalizujący papier, zabezpieczone białą wstążką. Połączenie kolorów i opalizujące opakowanie tworzą radosną i świeżą prezentację, idealną do rozjaśnienia każdej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Emberlyn-2a-641427.jpg?v=1722357537",
            "/images/products/Emberlyn-1a-117452.jpg?v=1722357537"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 1151,
        "name": "Pipsqueak Pals BHP",
        "handle": "pipsqueak-pals-bhp",
        "description": "Pipsqueak PalsNasze Pipsqueak Pals są idealne na baby shower lub po prostu do świętowania przyjścia na świat Twojego malucha! Zawiera torbę na pieluchy Baby K Signature, pieluchy Drypers DryPantz i uroczą pluszową zabawkę-królika, a wszystko to zapakowane w wykwintne pudełko na tace. Ta torba na pieluchy jest idealnym towarzyszem dla rodziców w podróży, z dużą ilością miejsca na wszystkie niezbędne rzeczy!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "207.43",
        "inclTax": "207.43",
        "img": [
            "/images/products/Pipsqueak-Pals-BHP1_1a59ee80-be48-4f1d-ac61-ca5a1b4cd250.jpg?v=1722993877",
            "/images/products/Pipsqueak-Pals-BHP2_3c73f16a-2bd0-4a85-a257-42ae788339a2.jpg?v=1722993878"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Torba na pieluchy Baby K Signature",
                "2. Suszarki Pieluchy DryPantz rozmiar M (4 szt.) x2",
                "3. Pluszowy króliczek",
                "4. Znakomita taca"
            ]
        },
        "dimention": "Szerokość*Wysokość: 35cm x 40cm"
    },
    {
        "id": 1152,
        "name": "Joe",
        "handle": "joe",
        "description": "Rozjaśnij każdą przestrzeń naszym uroczym wiosennym bukietem tulipanów. Ta kompozycja, zawierająca zachwycającą mieszankę pomarańczowych i białych tulipanów, akcentowana niebieskim ostem i bujną zielenią, jest owinięta miękkim brzoskwiniowym papierem. Idealny do świętowania nowych początków, wyrażania wdzięczności lub po prostu wnoszenia przypływu radości i koloru na każdą okazję.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Joe-2a-352159.jpg?v=1723167082",
            "/images/products/Joe-1a-507534.jpg?v=1723167082"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1153,
        "name": "Koala",
        "handle": "koala",
        "description": "Przedstawiamy nasz luksusowy bukiet składający się z urzekającego trio meta róż i trzech róż cappuccino, których głęboka czerwień i ciepłe odcienie kawy tworzą opowieść o pasji i wyrafinowaniu. Biała eustoma dodaje eterycznego akcentu, promieniując czystością i wdziękiem. Umieszczona wśród tych kwiatów kaspia nadaje delikatną konsystencję, a liście eukaliptusa zapewniają odświeżający kontrast.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety eustomy",
            "Kwiaty i bukiety róż"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Koala-2c.jpg?v=1723329541",
            "/images/products/Koala-1c.jpg?v=1723329541"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże.  Mattiola.  Eustomy .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1154,
        "name": "Szampan z kwiatami",
        "handle": "champagne-with-flowers-chocolates",
        "description": "Szampan z kwiatami Czekoladki ",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "355.76",
        "inclTax": "355.76",
        "img": [
            "/images/products/Champagne-with-Flowers-_-Chocolates-WGHP1.jpg?v=1723444211",
            "/images/products/Champagne-with-Flowers-_-Chocolates-WGHP2_2b81c25d-a827-499a-8a68-1b723aa6bf35-971439.jpg?v=1723541630"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Aubert et Fils Brut Champagne N.V. 75cl",
                "2. Hamlet Różne Belgijskie Czekoladki 125g",
                "3. Paluszki Czekoladowe Cupido 125g",
                "4. Wykwintna kompozycja kwiatowa",
                "5. Drewniana skrzynia (L)"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1155,
        "name": "Domini Veneti Bardolino Classico Z Kwiatami",
        "handle": "domini-veneti-bardolino-classico-with-flowers-chocolates-wghp",
        "description": "Domini Veneti Bardolino Classico Z Kwiatami Czekoladki",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze korporacyjne",
            "Kosze z winem"
        ],
        "price": "291.87",
        "inclTax": "291.87",
        "img": [
            "/images/products/Domini-Veneti-Bardolino-Classico-With-Flowers-_-Chocolates-WGHP1.jpg?v=1723523451",
            "/images/products/Domini-Veneti-Bardolino-Classico-With-Flowers-_-Chocolates-WGHP2.jpg?v=1723523451"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Domini Veneti Bardolino Classico 75cl",
                "2. Hamlet Różne Belgijskie Czekoladki 125g",
                "3. Paluszki Czekoladowe Cupido 125g",
                "4. Wykwintna kompozycja kwiatowa",
                "5. Drewniana skrzynia (L)"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1156,
        "name": "Naturalne pielęgnowanie BHP",
        "handle": "natural-nurture-bhp",
        "description": "Natural Nurture Ta piękna, klasycznie stylizowana koszyczka jest niezbędnym dodatkiem w życiu każdego fanatyka produktów organicznych! Zawiera całkowicie naturalne, czyste i zdrowe produkty, które dziecko pokocha.",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze dla niemowląt"
        ],
        "price": "618.19",
        "inclTax": "618.19",
        "img": [
            "/images/products/Natural-Nurture-BHP1-633415.jpg?v=1723542032",
            "/images/products/Natural-Nurture-BHP2.jpg?v=1723524708"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. Naturalny puder dla niemowląt Gaia Baby 100g",
                "2. Gaia Baby - Olejek do masażu dla dzieci 125ml",
                "3. Gaia Baby Krem Łagodzący 100ml",
                "4. Włosy dziecięce Gaia",
                "5. Nawilżający krem ​​​​nawilżający Gaia Baby 250ml",
                "6. Chusteczki bambusowe dla niemowląt Gaia Baby Lata 80",
                "7. Organiczny zestaw upominkowy Nożyce, 2 szt",
                "8. Wykwintny kosz upominkowy"
            ]
        },
        "dimention": "Szerokość*Wysokość: Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1157,
        "name": "Organiczna dobroć OHP",
        "handle": "organic-goodness-ohp",
        "description": "Organic Goodness Oferując najsmaczniejsze owoce i orzeźwiające napoje owocowe, Organic Goodness jest zdecydowanie jedyny w swoim rodzaju. Oprócz najświeższych owoców, ciekawe i rzemieślnicze drinki z pewnością zrobią wrażenie na każdym!",
        "type": "Kosze Upominkowe",
        "tags": [
            "Kosze owocowe",
            "Kosze organiczne"
        ],
        "price": "221.13",
        "inclTax": "221.13",
        "img": [
            "/images/products/Organic-Goodness-FHP1-461650.jpg?v=1723542033",
            "/images/products/Organic-Goodness-FHP2-641744.jpg?v=1723542034"
        ],
        "detail_child": {
            "title": "Przedmioty",
            "description": [
                "1. English Tea Shop Organiczne Super Jagody 40g",
                "2. Basilur Różne czarne herbaty owocowe 25 s",
                "3. Brzoskwinia świetlikowa",
                "4. Świetlik Kiwi Limonka",
                "5. Klasyczny Sok Jabłkowy z Czarną Porzeczką 300ml",
                "6. Pomarańczowy",
                "7. Kiwi",
                "8. Zielone jabłko",
                "9. Jabłko Fuji",
                "10. Gruszka Nam Shui",
                "11. Zielone winogrona"
            ]
        },
        "dimention": "Zgodnie z Pokazanym Zdjęciem"
    },
    {
        "id": 1158,
        "name": "Liwia",
        "handle": "livia",
        "description": "Przedstawiamy naszą kompozycję kwiatową „Pastel Elegance”, delikatną mieszankę delikatnych różów, fioletów i kremów, z udziałem róż, lisianthus i chryzantem. Umieszczona w stylowym szklanym wazonie, ta aranżacja emanuje wdziękiem i urokiem, co czyni ją idealnym prezentem na każdą okazję lub oszałamiającym elementem centralnym, który podniesie Twoją przestrzeń. Celebruj piękno ponadczasową elegancją.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Livia-2a-131153.jpg?v=1724158918",
            "/images/products/Livia-1a-955513.jpg?v=1724158918"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róża. Spryskaj róże. Eustomy . Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 16cm*28cm"
    },
    {
        "id": 1159,
        "name": "Leanne",
        "handle": "leanne-1",
        "description": "Odważna, pojedyncza ciemnoczerwona róża wyłania się z artystycznej oprawy czerni i kremu, tworząc dramatyczny kontrast. Przewiązany kremową wstążką, ten efektowny bukiet symbolizuje głęboką i namiętną miłość, doskonale zamkniętą w jednym wykwintnym rozkwicie.",
        "type": "Bukiety",
        "tags": [],
        "price": "45.41",
        "inclTax": "45.41",
        "img": [
            "/images/products/Leanne-1a-802268.jpg?v=1724158818",
            "/images/products/Leanne-2a-557929.jpg?v=1724158818"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Czerwona Róża"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*30cm"
    },
    {
        "id": 1160,
        "name": "Słodycze",
        "handle": "candy",
        "description": "Poczuj urok naszego eleganckiego mieszanego bukietu róż. Ta wykwintna kompozycja łączy w sobie delikatny róż, kremową żółć i nieskazitelną biel róż z delikatnymi akcentami zieleni i oddechem dziecka. Zapakowany w luksusowy biały papier i ozdobiony satynową wstążką, idealnie nadaje się do wyrażania miłości, wdzięczności lub świętowania każdej wyjątkowej okazji z ponadczasowym pięknem.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Candy-2a.jpg?v=1724251568",
            "/images/products/Candy-1a.jpg?v=1724251568"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Eustoma. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1161,
        "name": "Luwyna",
        "handle": "luvyna",
        "description": "Poczuj uosobienie romantyzmu dzięki naszemu wystawnemu bukietowi składającemu się z 40 oszałamiających czerwonych róż, elegancko zamkniętych w delikatnym różowym opakowaniu. Ta wspaniała aranżacja to zapierająca dech w piersiach symfonia pasji i elegancji, idealna do wyrażenia głębokiej miłości przy specjalnych okazjach. Odkryj piękno każdego kwiatu w tym urzekającym zestawie, ponadczasowym geście, który mówi wiele.",
        "type": "Bukiety",
        "tags": [],
        "price": "342.07",
        "inclTax": "342.07",
        "img": [
            "/images/products/Luvyna-2b-910442.jpg?v=1724512320",
            "/images/products/Luvyna-1b.jpg?v=1724507218"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "40 róż"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*38cm"
    },
    {
        "id": 1162,
        "name": "Mikayla",
        "handle": "mikayla",
        "description": "Ta tętniąca życiem aranżacja wazonów składa się z promiennej mieszanki słoneczników, brzoskwiniowych róż i wesołych kwiatów pomarańczy, a wszystko to pięknie uzupełnione bujną zielenią. Ciepła i żywa paleta kolorów tworzy bukiet emanujący radością i pozytywnością, dzięki czemu idealnie nadaje się do rozjaśnienia każdego pomieszczenia lub celebrowania wyjątkowych chwil. Idealny jako przemyślany prezent lub oszałamiający element centralny, ten bukiet wnosi przypływ słońca do każdego otoczenia.",
        "type": "Vase Arrangement",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Mikayla-2a-759164.jpg?v=1724688023",
            "/images/products/Mikayla-1a-670388.jpg?v=1724688023"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Spryskaj róże. Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 1163,
        "name": "Anania",
        "handle": "ananya-1",
        "description": "Przedstawiamy nasz Wibrujący Bukiet Tulipanów, oszałamiającą kompozycję zawierającą symfonię kolorowych tulipanów w odcieniach różu, fioletu, żółci, czerwieni i bieli. Każdy tulipan jest starannie dobrany i pomysłowo ułożony, aby stworzyć bukiet emanujący radością i elegancją. Ten bukiet, zawinięty w wysokiej jakości papier, jest idealny na każdą okazję i wnosi odrobinę wiosennej świeżości do Twoich wyjątkowych chwil.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Ananya-2a.jpg?v=1724890585",
            "/images/products/Ananya-1a.jpg?v=1724890585"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Tulipany"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*34cm"
    },
    {
        "id": 1164,
        "name": "Harmonia (VIII)",
        "handle": "harmony-viii",
        "description": "Przedstawiamy nasz oszałamiający stojak na kwiaty na wielkie otwarcie, tętniącą życiem i luksusową aranżację zaprojektowaną, aby urzekać i celebrować radosną okazję nowego początku. Ten wspaniały ekspozytor, wykonany z niezwykłą dbałością o szczegóły, stanowi harmonijne połączenie słoneczników i pomarańczowych gerber, emanując elegancją i urokiem.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Harmony-_VIII_-1a.jpg?v=1724894823",
            "/images/products/Harmony-_VIII_-2a.jpg?v=1724894823"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Gerbera . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 60cm*160cm"
    },
    {
        "id": 1165,
        "name": "Miltonia",
        "handle": "miltonia",
        "description": "Ten oszałamiający bukiet składa się z harmonijnej mieszanki delikatnych różowych hortensji, delikatnych róż i eleganckich kwiatów lisianthus, akcentowanych gałązkami oddechu dziecka. Idealna na każdą okazję, ta aranżacja jest prezentowana w przezroczystym szklanym wazonie, prezentującym świeże i żywe kolory. Idealny do rozjaśnienia każdej przestrzeni, może być przemyślanym prezentem lub piękną ozdobą domu.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "296.43",
        "inclTax": "296.43",
        "img": [
            "/images/products/Miltonia-2a.jpg?v=1724943070",
            "/images/products/Miltonia-1a-191886.jpg?v=1725054490"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Róże. Spryskaj róże. Eustoma. Mattiola. Oddech dziecka. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 1166,
        "name": "Wallis BBS",
        "handle": "wallis",
        "description": "Ta urocza kompozycja składa się z promiennego słonecznika w połączeniu z klasycznymi czerwonymi różami i delikatnymi białymi kwiatami. Zaakcentowany delikatną zielenią i umieszczony w eleganckiej, charakterystycznej torbie, wnosi odrobinę ciepła i wyrafinowania, idealny na każdą okazję, dodając przypływu radosnej elegancji każdemu otoczeniu.",
        "type": "Bloom Boxes",
        "tags": [],
        "price": "91.05",
        "inclTax": "91.05",
        "img": [
            "/images/products/Wallis-2d-807036.jpg?v=1726288792",
            "/images/products/Wallis-1d.jpg?v=1726187689"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Spryskaj róże. Eustomy . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 22cm*30cm"
    },
    {
        "id": 1167,
        "name": "Życzliwość (VII)",
        "handle": "benevolence-vii",
        "description": "Ten spokojny stojak kondolencyjny zawiera elegancką kompozycję białych lilii, róż i chryzantem, wdzięcznie zaakcentowaną bujną zielenią. Umieszczony na wysokiej podstawie z kwiatowym wzorem, przekazuje głębokie współczucie i serdeczne kondolencje, składając delikatny hołd w chwilach straty. Idealny gest, aby wyrazić swoje wsparcie i troskę.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Benevolence-_VII_-1c.jpg?v=1727225487",
            "/images/products/Condolence-Card-2024_1_cb8946dd-42d6-49ed-a36d-db508e683d25.jpg?v=1727225545",
            "/images/products/Benevolence-_VII_-2b.jpg?v=1727225487"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Orchidee. Lilie . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1168,
        "name": "Phyllis",
        "handle": "phyllis",
        "description": "Ten zachwycający bukiet składa się z żywych słoneczników otoczonych delikatnymi różowymi różami, delikatnymi goździkami i bujną zielenią. Zapakowany w elegancki biało-różowy papier emanuje ciepłem i radością, dzięki czemu będzie idealnym prezentem, który umili każdy dzień. Idealny na urodziny, uroczystości lub po prostu dlatego.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety słoneczników"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Phyllis-2a.jpg?v=1726064042",
            "/images/products/Phyllis-1a-541575.jpg?v=1726113057"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki.  Róże.  Eustomy . Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1169,
        "name": "Wróżka",
        "handle": "fairy",
        "description": "Ten wykwintny bukiet zawiera harmonijną mieszankę delikatnych różowych róż i żywych czerwonych tulipanów, akcentowanych gałązkami oddechu dziecka. Kompozycja jest owinięta delikatnym różowo-białym papierem i przewiązana pasującą wstążką, tworząc elegancką i romantyczną prezentację. Idealny do wyrażania miłości i uznania przy każdej specjalnej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety róż",
            "Kwiaty i bukiety tulipanów"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Fairy-2a.jpg?v=1726064405",
            "/images/products/Fairy-1a.jpg?v=1726064405"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Tulipany. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1170,
        "name": "Rubin",
        "handle": "ruby",
        "description": "Świętuj elegancję dzięki naszemu wspaniałemu mieszanemu bukietowi. Kompozycja ta, zawierająca bujną kombinację różowych hortensji, żywych czerwonych róż i delikatnych różowych kwiatów, jest akcentowana świeżą zielenią. Zapakowany w odważny czerwony papier i przewiązany satynową wstążką, idealnie nadaje się do wyrażania miłości, podziwu lub dodawania odrobiny wyrafinowania każdej specjalnej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty przeprosinowe i bukiety",
            "Kwiaty i bukiety hortensji"
        ],
        "price": "205.15",
        "inclTax": "205.15",
        "img": [
            "/images/products/Ruby-2a.jpg?v=1726064541",
            "/images/products/Ruby-1a.jpg?v=1726064541"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja.  Róże.  Spryskaj róże. Eustomy .  Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 26cm*32cm"
    },
    {
        "id": 1171,
        "name": "Katarzyna",
        "handle": "katherine",
        "description": "Świętuj w wielkim stylu dzięki naszemu żywemu, mieszanemu bukietowi kwiatów. Ta kompozycja, zawierająca eleganckie białe lilie, delikatne różowe róże i całą gamę kolorowych kwiatów, jest akcentowana bujną zielenią i owinięta odważnym czerwonym papierem. Idealny do wyrażania miłości, radości i uznania, dodaje koloru i elegancji każdej wyjątkowej okazji.",
        "type": "Bukiety",
        "tags": [
            "Kwiaty na rocznicę",
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty przeprosinowe i bukiety",
            "Bukiety lilii",
            "Kwiaty i bukiety róż"
        ],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Katerine-2a.jpg?v=1726064626",
            "/images/products/Katerine-1a-438887.jpg?v=1726112864"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie . Róże. Spryskaj róże. Mattiola. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 32cm*46cm"
    },
    {
        "id": 1172,
        "name": "Elorę",
        "handle": "elora",
        "description": "Ta urocza kompozycja kwiatowa przedstawia oszałamiający słonecznik w sercu, otoczony delikatnymi białymi różami, wesołymi żółtymi gerberami i żywymi czerwonymi kwiatami. Akcentowany bujnym eukaliptusem i gałązkami zieleni, z wdziękiem mieści się w złotym pudełku na kapelusze. Idealny do rozjaśnienia każdej przestrzeni, ten bukiet wnosi poczucie ciepła, radości i elegancji na każdą okazję.",
        "type": "Flower Boxes",
        "tags": [],
        "price": "159.51",
        "inclTax": "159.51",
        "img": [
            "/images/products/Elora-2a-567647.jpg?v=1726200404",
            "/images/products/Elora-1a-642832.jpg?v=1726200404"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Róże. Eustoma. Gerbera . Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*25cm"
    },
    {
        "id": 1173,
        "name": "Emanuela",
        "handle": "emanuela",
        "description": "Ta zachwycająca kompozycja zawiera promienny słonecznik, pastelowe różowe goździki i chryzantemy w kolorze lawendy, akcentowane gałązkami statyki i bujnej zieleni. Bukiet ten, zapakowany w eleganckie białe pudełko na kapelusze ozdobione delikatną wstążką, uosabia elegancję i urok, dzięki czemu jest idealnym prezentem, który rozjaśni każde pomieszczenie odrobiną słońca i kwiatowego piękna.",
        "type": "Flower Boxes",
        "tags": [],
        "price": "136.69",
        "inclTax": "136.69",
        "img": [
            "/images/products/Emanuela-2a-452508.jpg?v=1726200403",
            "/images/products/Emanuela-1a.jpg?v=1726196415"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słonecznik. Eustoma. Mamo. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 15cm*20cm"
    },
    {
        "id": 1174,
        "name": "Trixi",
        "handle": "trixie",
        "description": "Ta aranżacja wazonów składa się z promiennej mieszanki słoneczników i delikatnych pastelowych kwiatów, w tym bladobrzoskwiniowych róż i delikatnych goździków, uzupełnionych wesołymi kwiatami rumianku i świeżą zielenią. Jasne słoneczniki przynoszą przypływ słońca, a delikatne odcienie brzoskwini i kremu dodają elegancji i spokoju. Idealny do rozjaśnienia każdego pomieszczenia lub okazji, ten bukiet jest starannie przedstawiony w uroczym szklanym wazonie, przewiązanym satynową wstążką dla wyrafinowanego wykończenia.",
        "type": "Vase Arrangement",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Trixie-2c.jpg?v=1727402454",
            "/images/products/Trixie-1c.jpg?v=1727402454"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Goździk . Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 1175,
        "name": "Życzliwość (VIII)",
        "handle": "benevolence-viii",
        "description": "Ta oszałamiająca kompozycja składa się z białych chryzantem z pomponami, miękkich lawendowych goździków i efektownych rajskich ptaków, a wszystko to na tle bujnych zielonych liści. Ekspozycja ta, umieszczona na uroczym stojaku z kwiatowym wzorem, dodaje elegancji i żywotności każdemu otoczeniu, dzięki czemu idealnie nadaje się na wielkie otwarcia, uroczystości lub jako szczery gest uznania. Piękna mieszanka najlepszych kwiatów natury, która tworzy trwałe wrażenie.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "273.61",
        "inclTax": "273.61",
        "img": [
            "/images/products/Benevolence-_VIII_1a.jpg?v=1726578123",
            "/images/products/Benevolence-_VIII_2a.jpg?v=1726578123"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Rajskie ptaki. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*170cm"
    },
    {
        "id": 1176,
        "name": "Tindra",
        "handle": "tindra",
        "description": "Ta urocza kompozycja wazonowa kipi kolorem i radością, przedstawia promienny słonecznik w połączeniu z wesołymi żółtymi liliami, pomarańczowymi tulipanami oraz delikatnymi różowymi i brzoskwiniowymi różami. Akcenty białego ranunculus i gałązek eukaliptusa dodają świeżego, naturalnego akcentu. Idealny do rozjaśnienia każdej przestrzeni, wnosi ciepło, elegancję i poczucie szczęścia na każdą okazję.",
        "type": "Vase Arrangement",
        "tags": [
            "Bukiety słoneczników"
        ],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Tindra-2b-405333.jpg?v=1727037585",
            "/images/products/Tindra-1b-677525.jpg?v=1727037585"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Goździk . Eustomy . Alstremeria. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 20cm*32cm"
    },
    {
        "id": 1177,
        "name": "Akacja",
        "handle": "acacia",
        "description": "Ta kompozycja kwiatowa zawiera żywą mieszankę kwiatów, w tym duże różowe lilie, ciemnoczerwone róże, delikatnie różowe goździki i delikatne fioletowe orchidee. Uzupełniony bujną zielenią i umieszczony w wazonie z przezroczystego szkła, bukiet emanuje elegancją i świeżością. Idealny na specjalne okazje lub po prostu do rozjaśnienia pomieszczenia, miękka wstążka dodaje odrobinę wyrafinowania.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Acacia-2b-582143.jpg?v=1727153767",
            "/images/products/Acacia-1b-371564.jpg?v=1727153767"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie . Eustoma. Goździki. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 1178,
        "name": "Arabella",
        "handle": "arabella",
        "description": "Ten piękny bukiet składa się z zachwycającej mieszanki pastelowych róż i lawendowych goździków, uzupełnionej delikatną zielenią. Kompozycja umieszczona w wazonie z przezroczystego szkła emanuje elegancją i urokiem, idealna na specjalne okazje lub jako przemyślany prezent. Kwiaty przewiązane wstążką dodadzą prezentacji wdzięku i wyrafinowania.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "227.97",
        "inclTax": "227.97",
        "img": [
            "/images/products/Arabella-2b-990223.jpg?v=1727261337",
            "/images/products/Arabella-1b-580954.jpg?v=1727261337"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Lilie . Eustoma. Goździki. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 30cm*40cm"
    },
    {
        "id": 1179,
        "name": "Tymotea",
        "handle": "timothea",
        "description": "Ten czarujący bukiet przedstawia bujne zestawienie białych i żółtych kwiatów rumianku, ułożonych w zaokrąglony, naturalny sposób. Drobne kwiaty przypominające stokrotki z jasnożółtymi środkami nadają świeży i wesoły wygląd. Kompozycję umieszczono w wazonie z przezroczystego szkła przewiązanym wstążką, co czyni ją idealnym wyborem, aby dodać odrobinę rustykalnego piękna i prostoty do każdego wnętrza.",
        "type": "Vase Arrangement",
        "tags": [],
        "price": "182.33",
        "inclTax": "182.33",
        "img": [
            "/images/products/Timothea-2a-207479.jpg?v=1727156018",
            "/images/products/Timothea-1a-657029.jpg?v=1727156018"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Stokrotki. Oddech dziecka"
            ]
        },
        "dimention": "Szerokość*Wysokość: 25cm*30cm"
    },
    {
        "id": 1180,
        "name": "Harmonia (IX)",
        "handle": "harmony-ix",
        "description": "Ta oszałamiająca kompozycja kwiatowa charakteryzuje się obfitym pokazem żywych pomarańczowych i żółtych kwiatów, akcentowanych bujną zielenią i delikatnymi białymi kwiatami. Bukiet ustawiony na czarnym statywie emanuje energią i ciepłem, dzięki czemu idealnie nadaje się na uroczyste wydarzenia lub wielkie okazje. Mieszanka róż, orchidei i polnych kwiatów tworzy uderzającą równowagę elegancji i naturalnego piękna, rozjaśniając każdą przestrzeń dzięki odważnej palecie kolorów.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Harmony-_IX_-1a.jpg?v=1727060132",
            "/images/products/Harmony-_IX_-2a.jpg?v=1727060132"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Róże. Orchidee. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 60cm*160cm"
    },
    {
        "id": 1181,
        "name": "Harmonia (X)",
        "handle": "harmony-x",
        "description": "Ta urzekająca kompozycja przeplata się z żywą mieszanką róż szampańskich, efektownych słoneczników i pomarańczowych róż w sprayu, a wszystko to akcentowane bujnymi liśćmi eukaliptusa. Owinięty w eleganckie pastelowe papiery i przewiązany czerwoną satynową wstążką emanuje urokiem i ciepłem. Idealny do rozjaśnienia każdego pomieszczenia lub specjalnej okazji, ten bukiet oferuje oszałamiający pokaz kolorów i wdzięku.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "752.83",
        "inclTax": "752.83",
        "img": [
            "/images/products/Harmony-_X_-1a.jpg?v=1727135714",
            "/images/products/Harmony-_X_-2a.jpg?v=1727135714"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Róże. Spryskaj róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 60cm*160cm"
    },
    {
        "id": 1182,
        "name": "Życzliwość (IX)",
        "handle": "benevolence-ix",
        "description": "Ta elegancka kompozycja kondolencyjna zawiera spokojną mieszankę białych i brzoskwiniowych róż, delikatnych chryzantem i delikatnych różowych wypełniaczy, symbolizujących spokój i wygodę. Zaakcentowany bujną zielenią i starannie owinięty stonowanymi tonami, stanowi delikatny wyraz współczucia. Szczery hołd, niosący ciepło i pocieszenie w chwilach smutku i pamięci.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "684.37",
        "inclTax": "684.37",
        "img": [
            "/images/products/Benevolence-_IX_-1a.jpg?v=1727138331",
            "/images/products/Benevolence-_IX_-2a.jpg?v=1727138332"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Chryzantema. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1183,
        "name": "Luksus (VI)",
        "handle": "luxury-vi",
        "description": "Na tym tętniącym życiem stoisku podczas wielkiego otwarcia można podziwiać oszałamiającą gamę jasnych słoneczników, promiennych pomarańczowych gerber i wesołych żółtych orchidei oncidium. Ta elegancka aranżacja, akcentowana bujnymi zielonymi liśćmi, emanuje pozytywnością i sukcesem, co czyni ją idealnym centralnym elementem świętowania nowych początków. Pełen wdzięku design stojaka na kwiaty dodaje odrobinę wyrafinowania, przygotowując grunt pod prosperujące przedsięwzięcie.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "456.17",
        "inclTax": "456.17",
        "img": [
            "/images/products/Luxury-_VI_-2aa-149051.jpg?v=1727262258",
            "/images/products/Grand-Opening-Card-2024_484de493-4f19-4133-9457-32a0987bdfe4.jpg?v=1721487966",
            "/images/products/Luxury-_VI_-1a-592627.jpg?v=1727262258"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Słoneczniki. Gerbera . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 1184,
        "name": "Pamięć (ja)",
        "handle": "remembrance-i",
        "description": "To wielkie stoisko otwierające prezentuje urzekającą mieszankę niebieskich hortensji, delikatnych różowych róż i bujnych zielonych liści. Wzbogacona delikatnymi białymi kwiatami i bazą o tematyce kwiatowej, kompozycja emanuje elegancją i urokiem. Idealny do uczczenia doniosłej okazji, wnosi atmosferę wdzięku i świętowania, symbolizując rozwój i sukces w każdym nowym przedsięwzięciu.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Stojaki z kwiatami kondolencyjnymi"
        ],
        "price": "547.45",
        "inclTax": "547.45",
        "img": [
            "/images/products/Remembrance-_I_-2a.jpg?v=1727224343",
            "/images/products/Condolence-Card-2024_1.jpg?v=1720187690",
            "/images/products/Remembrance-_I_-1a.jpg?v=1727224343"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensje. Róże. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 80cm*160cm"
    },
    {
        "id": 1185,
        "name": "Luksus (VII)",
        "handle": "luxury-vii",
        "description": "To uderzające, wielkie stoisko otwierające pęka od żywych czerwonych gerber, ognistych kwiatów imbiru i luksusowych orchidei. Uzupełniona delikatnymi różowymi i białymi akcentami oraz odważną tropikalną zielenią, aranżacja emanuje energią i pasją. Umieszczony na podstawie z kwiatowym nadrukiem uosabia siłę i świętowanie, dzięki czemu jest idealnym centralnym elementem zwiastującym sukces i dobrobyt na każdą wielką okazję.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Luxury-_VII_-2a.jpg?v=1727227925",
            "/images/products/Grand-Opening-Card-2024_484de493-4f19-4133-9457-32a0987bdfe4.jpg?v=1721487966",
            "/images/products/Luxury-_VII_-1a.jpg?v=1727227926"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Róże. Gerbera . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 1186,
        "name": "Luksus (VIII)",
        "handle": "luxury-viii",
        "description": "Ten oszałamiający stojak kwiatowy zawiera żywą mieszankę brzoskwiniowych gerber, delikatnych różowych róż i bujnej zieleni, akcentowanej fioletowymi kwiatami. Prezentowana w eleganckim stojaku z kwiatowym wzorem ozdobionym delikatnymi różowymi wstążkami, aranżacja ta emanuje wdziękiem i urokiem, dzięki czemu jest idealnym gestem na wielkie otwarcia, uroczyste wydarzenia lub serdeczne gratulacje.",
        "type": "Stojaki na Kwiaty",
        "tags": [
            "Kwiaty na wielkie otwarcie"
        ],
        "price": "615.91",
        "inclTax": "615.91",
        "img": [
            "/images/products/Luxury-_VIII_-2a-556610.jpg?v=1727262258",
            "/images/products/Grand-Opening-Card-2024_484de493-4f19-4133-9457-32a0987bdfe4.jpg?v=1721487966",
            "/images/products/Luxury-_VIII_-1a-648914.jpg?v=1727262258"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Hortensja. Róże. Gerbera . Eustoma. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 90cm*180cm"
    },
    {
        "id": 1187,
        "name": "Nyomi",
        "handle": "nyomi",
        "description": "Ta urocza aranżacja koszy łączy w sobie delikatne niebieskie hortensje, delikatne białe róże i eustomy, wzmocnione gałązkami bujnej zieleni. Umieszczony w tkanym koszu, ten kwiatowy element wprowadza świeżą i spokojną atmosferę, dzięki czemu jest idealnym elementem centralnym na specjalne okazje lub przemyślanym prezentem. Delikatna paleta kolorów dodaje elegancji każdemu pomieszczeniu.",
        "type": "Flower Baskets",
        "tags": [
            "Kwiaty urodzinowe i bukiety",
            "Kwiaty gratulacyjne",
            "Kwiaty na zdrowie i bukiety",
            "Kwiaty i bukiety romantyczne",
            "Kwiaty i bukiety goździków",
            "Kwiaty i bukiety róż"
        ],
        "price": "387.71",
        "inclTax": "387.71",
        "img": [
            "/images/products/Nyomi-2b.jpg?v=1727312997",
            "/images/products/Nyomi-1b.jpg?v=1727312997"
        ],
        "detail_child": {
            "title": "Użyte Kwiaty",
            "description": [
                "Jasne różowe róże. Goździki. Spryskaj różę. Wypełniacze"
            ]
        },
        "dimention": "Szerokość*Wysokość: 28cm*30cm"
    }
]