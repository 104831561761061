import React from 'react'
import { useState } from 'react'
import './NavMenuBlock.css'
import roundModal from '../../assets/icon/round-modal.svg'
import { useNavigate } from 'react-router-dom';

export default function NavMenuBlock() {
    const [modalShow, setModalShow] = useState(false)

    const occasionsElements = [{"title": "Wielkie Otwarcie", "link": "/grand-opening-flowers"},{"title": "Wyrazy Współczucia", "link": "/condolence"},{"title": "Ślub", "link": "/wedding-flower-bouquets"},{"title": "Ukończenie Szkoły", "link": "/graduation-flower-bouquets"},{"title": "Rocznica", "link": "/anniversary-flower-bouquets"},{"title": "Urodziny", "link": "/birthday-flower-bouquets"},{"title": "Gratulacje", "link": "/congratulation-flower-bouquets"},{"title": "Szybkiego Powrotu do Zdrowia", "link": "/get-well-soon-flower-bouquets"},{"title": "Noworodek", "link": "/newborn-flower-bouquets"},{"title": "Propozycja/Romantyczny", "link": "/romantic"},{"title": "Przepraszam", "link": "/sorry-flower-bouquets"},{"title": "Zaręczyny/Panieński", "link": "/engagement-flower-bouquets"}]
    const hampersElements = [{"title": "Kosze Dziecięce", "link": "/baby-hampers"},{"title": "Kosze Owocowe", "link": "/fruit-hampers"},{"title": "Kosze Gourmet", "link": "/gourmet-hampers"},{"title": "Kosze z Zestawem Herbaty", "link": "/tea-set-hampers"},{"title": "Kosze Wellness", "link": "/wellness-hampers"},{"title": "Kosze Organiczne", "link": "/organic-hampers"},{"title": "Kosze Czekoladowe", "link": "/chocolate-hampers"},{"title": "Kosze Korporacyjne", "link": "/corporate-hampers"},{"title": "Kosze z Halal Jedzeniem", "link": "/halal-food-hampers"},{"title": "Prezent z Winem", "link": "/wine-gifts"}]
    const shopFlowersElements = [{"title": "Biała Bławatek", "link": "/baby-breath-bouquet-poland"},{"title": "Lilia Calla", "link": "/calla-lily-flower-bouquets"},{"title": "Goździki", "link": "/carnation-bouquet-flowers"},{"title": "Eustoma", "link": "/eustoma-flower-bouquets"},{"title": "Hortensja", "link": "/hydrangea-bouquet-flowers"},{"title": "Lilie", "link": "/lilies"},{"title": "Róże", "link": "/rose-bouquets-poland"},{"title": "Słonecznik", "link": "/sunflower-bouquets-poland"},{"title": "Tulipan", "link": "/tulip-flower-bouquets"}]
    const flowersBalloonElements = [{"title": "Noworodek/Dziecięce FNB", "link": "/newborn-fnb"},{"title": "Życzenia/Sytuacje FNB", "link": "/get-well-soon-fnb"},{"title": "Szczęśliwych Urodzin FNB", "link": "/happy-birthday-fnb"},{"title": "Gratulacje FNB", "link": "/congratulations"},{"title": "Rocznica FNB", "link": "/anniversary-fnb"},{"title": "Ukończenie Szkoły FNB", "link": "/graduation-fnb"}]

    function showElement(elements) {
        return(
            <div className='header__modal-block'><img className='modal__round right' src={roundModal} /><img className='modal__round' src={roundModal} draggable='false' />{elements.map((el) => <button onClick={() => {navigate(el.link)}} className='header__modal-button'>{el.title}</button>)}</div>
        )
    }

    const navigate = useNavigate();

    return (
        <ul className='header__menu-nav-block'>
            <li onClick={() => {navigate('/')}} className='header__menu-button'>Strona główna</li>
            <li onClick={() => {navigate('/graduation-flower-bouquets')}} className='header__menu-button'>Ukończenie szkoły</li>
            <li onMouseEnter={() => {setModalShow('occasions')}} onMouseLeave={() => {setModalShow(null)}} className='header__menu-button'>Okazje{modalShow === "occasions" && (showElement(occasionsElements))}</li>
            <li onMouseEnter={() => {setModalShow('hampers')}} onMouseLeave={() => {setModalShow(null)}} className='header__menu-button'>Kosze upominkowe{modalShow === "hampers" && (showElement(hampersElements))}</li>
            <li onMouseEnter={() => {setModalShow('shopFlowers')}} onMouseLeave={() => {setModalShow(null)}} className='header__menu-button'>Sklep Kwiaty{modalShow === "shopFlowers" && (showElement(shopFlowersElements))}</li>
            <li onMouseEnter={() => {setModalShow('flowersBalloon')}} onMouseLeave={() => {setModalShow(null)}} className='header__menu-button'>Kwiaty i Balony{modalShow === "flowersBalloon" && (showElement(flowersBalloonElements))}</li>
        </ul>
    )
}
