import React from 'react'
import './Header.css'
import NavMenuBlock from '../NavMenuBlock/NavMenuBlock'
import Search from '../Search/Search'
import Cart from '../Cart/Cart'
import MobileMenu from '../MobileMenu/MobileMenu'
import logo from '../../assets/icon/logo.svg'

export default function Header() {
  return (
    <header>
      {window.innerWidth < 1030 && (<MobileMenu />)}
      <img className='logo' src={logo} draggable='false'/>
      {window.innerWidth > 1030 && (<Search />)}
      {window.innerWidth > 1030 && (<NavMenuBlock />)}
      <Cart />
    </header>
  )
}
