import React from 'react'
import { useNavigate } from 'react-router-dom';

import Header from '../components/Header/Header'
import FaqAccordion from '../components/FaqAccordion/FaqAccordion'
import Footer from '../components/Footer/Footer'

import freeShipin from '../assets/icon/free-shipping.svg'
import priceDown from '../assets/icon/price-down.svg'
import moneyBack from '../assets/icon/money-back.svg'
import flowers from '../assets/icon/flowers.svg'
import ShopifySecion from '../components/ShopifySection/ShopifySection'
import illustration from '../assets/images/bouquet-roses.png'

import ScrollToTop from '../global/ScrollToTop'

export default function Home() {
  const blocksProducts = [
    ['Bukiety Kwiatów', 'Podaruj swoim bliskim oszałamiający bukiet kwiatów, aby uczcić ich osiągnięcia lub pokazać, jak bardzo ich doceniasz.', 'Bukiety'],
    ['Torebki Kwiatowe', 'Idealne na prezent lub do udekorowania przestrzeni, te kwiaty w torbie łączą piękno i funkcjonalność, wprowadzając przyjemny akcent do każdego wnętrza.', 'Seria Torebek'],
    ['Stojaki na Kwiaty', 'Podnieś atmosferę na weselach, uroczystych otwarciach lub innych ważnych wydarzeniach dzięki tym wspaniałym stojakom na kwiaty.', 'Stojaki na Kwiaty'],
    ['Kwiaty i Balony', 'Pozwól swoim bliskim doświadczyć radości z połączenia tradycyjnego piękna z elementami zabawy w naszej kolekcji kwiatów i balonów.', 'Balony i Kwiaty'],
    ['Kosze Owocowe', 'Nasze starannie skomponowane kosze owocowe łączą świeżość sezonowych owoców z kunsztem naszych utalentowanych florystów.', 'Kosze Upominkowe']
  ]

  const FaqAccordionData = [
    {
      'title': 'Co jest wyjątkowego w Kwiaty Polska?',
      'body': 'To, co wyróżnia Kwiaty Polska, to nasza szybka usługa dostawy oraz szeroki wybór świeżych kwiatów. Niezależnie od tego, czy szukasz delikatnych piwonii na prezent na pierwszą randkę, czy goździków na swój wielki dzień, Kwiaty Polska przygotuje najlepszą kompozycję kwiatową na każdą okazję i dostarczy ją w to samo miejsce w Polsce tego samego dnia.'
    },
    {
      'title': 'Jak szybko mogą być dostarczone kwiaty?',
      'body': "W Kwiaty Polska rozumiemy, jak ważne są terminowe dostawy. Dlatego oferujemy usługi dostawy kwiatów tego samego dnia, zapewniając, że Twoje piękne kwiaty dotrą na czas do miejsca przeznaczenia."
    },
    {
      'title': 'Jak wybrać idealny bukiet na różne okazje?',
      'body': 'Oto, co warto wziąć pod uwagę, gdy decydujesz się na zamówienie kwiatów online na specjalną okazję: Ukończenie szkoły: Słoneczniki i goździki to doskonałe bukiety na uroczystości z powodu ich pięknych kwiatów i kolorów. Dodaj balon, pluszaka lub ulubione czekoladki do bukietu, aby dodać mu uroku! Rocznica: Spraw, aby Twoja rocznica była niezapomniana dzięki pięknie skomponowanemu bukietowi, który wyrazi Twoją miłość. Róże, hortensje lub piwonie to doskonały wybór na wielkie romantyczne gesty, ponieważ mają subtelne, a jednocześnie namiętne kolory, które symbolizują silną pasję. Szybkiego powrotu do zdrowia: Kupujesz bukiet dla bliskiej osoby lub przyjaciela, który zachorował? Ożyw ich dzień jasnymi kwiatami! Słoneczniki, tulipany i goździki to doskonałe wybory dzięki różnorodnym radosnym odcieniom, które na pewno poprawią komuś nastrój.Urodziny: Świętuj szczególny dzień swojego bliskiego za pomocą wyjątkowych kwiatów! Hortensje, wielokolorowe kocanki lub nawet suszone bukiety, takie jak nasz Everlast, specjalnie skomponowany z mieszanką białej bawełny i suszonej lawendy, to imponujące kwiaty, które na pewno zrobią wrażenie. Szukasz więcej opcji? Sprawdź naszą ofertę bukietów dostępnych na każdą okazję!'
    },
    {
      'title': 'Co powinienem zrobić, jeśli kwiaty są uszkodzone lub nieprawidłowe?',
      'body': "Przed wysyłką nasz zespół starannie sprawdza każdy bukiet pod kątem dokładności i stanu. Informujemy Cię o statusie Twojego zamówienia przed przekazaniem bukietu kwiatów do dostawy."
    },
    {
      'title': 'Gdzie dostarczacie kwiaty?',
      'body': "Kwiaty Polska dostarcza kwiaty na terenie całej Polski. Obejmuje to Warszawę, Kraków, Poznań, Gdańsk, Szczecin, Bydgoszcz, Lublin i inne."
    }
  ]

  const navigate = useNavigate();

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className='main-baner-page'>
        <div className='baner__main-container'>
          <h1 className='baner-header-text'>Kwiaty, które mówią za Ciebie!</h1>
          <p className='baner-header-description'>Wyrażaj uczucia jeszcze piękniej dzięki naszej świeżej kolekcji kwiatów. Dostawa na czas, wrażenia na całe życie!</p>
          <button onClick={() => {navigate('/rose-bouquets-poland')}} className='baner-header-button'>Wybierz bukiet</button>
        </div>
      </div>
      <div className='header__footer-block'>
        <h1 className='h1__main'>Kwiaty Polska: Twój Zaufany Kwiaciarz w Polsce z Dostawą Kwiatów Tego Samego Dnia</h1>
        <div className='header__footer-mini'>
          <div className='header__footer-mini-block'>
            <img src={freeShipin} draggable='false'/>
            <h5 className='header__footer-title'>Dostawa za Darmo</h5>
            <h4 className='header__footer-description'>Oczekuj DARMOWEJ dostawy kwiatów na terenie całej Polski.</h4>
          </div>
          <div className='header__footer-mini-block'>
            <img src={priceDown} draggable='false'/>
            <h5 className='header__footer-title'>Przystępność cenowa</h5>
            <h4 className='header__footer-description'>Zamów premium kwiaty online już od 75,29 zł</h4>
          </div>
          <div className='header__footer-mini-block'>
            <img src={moneyBack} draggable='false'/>
            <h5 className='header__footer-title'>Obsługa Klienta</h5>
            <h4 className='header__footer-description'>Uzyskaj niezawodne wsparcie od naszego personelu, jeśli masz pytania.</h4>
          </div>
          <div className='header__footer-mini-block'>
            <img src={flowers} draggable='false'/>
            <h5 className='header__footer-title'>Nasza Obietnica</h5>
            <h4 className='header__footer-description'>Jeśli nie jesteś zadowolony z zamówienia, oferujemy ponowną dostawę.</h4>
          </div>
        </div>
      </div>
      <section>
        {blocksProducts.map((el) => <ShopifySecion title={el[0]} description={el[1]} type={el[2]} />)}
        <div className='section__block flex'>
          <img src={illustration} className='illustration__image' />
          <div className='section__info-text-block'>
            <h3 className='section__info-text-title'>Dostawa Kwiatów i Prezentów Tego Samego Dnia w Polsce</h3>
            <p className='section__info-text-description'>Doświadcz wygody dostawy kwiatów tego samego dnia na terenie całej Polski z Kwiaty Polska. Niezależnie od tego, czy to niespodzianka w ciągu tygodnia, czy weekendowa celebracja, mamy wszystko, czego potrzebujesz dzięki naszej bezproblemowej usłudze dostawy.</p>
            <h4 className='section__info-text-title-mini'>Godziny Dostawy: CAŁODOBOWO!!!</h4>
            <p className='section__info-text-description'><span>Darmowa Dostawa:</span>Ciesz się darmową dostawą na terenie całej Polski przy wszystkich zamówieniach. Żadnych ukrytych opłat, żadnych niespodzianek.</p>
            <p className='section__info-text-description'><span>Dodatkowe Opłaty za Specyficzny Czas:</span>Chociaż dostawa jest darmowa, wybór konkretnego czasu dostawy może wiązać się z dodatkowymi opłatami.</p>
            <p className='section__info-text-description'><span>Dostępność Odbiorcy:</span>Po złożeniu zamówienia online upewnij się, że odbiorca będzie obecny w wyznaczonym miejscu w czasie dostawy, aby uniknąć dodatkowych opłat za ponowną dostawę.</p>
          </div>
        </div>
        <div className='section__block'>
          <h1 className='section__title'>Najczęściej Zadawane Pytania dotyczące Dostawy Kwiatów</h1>
          <div className='faq-accordion__blocks'>
            <FaqAccordion data={FaqAccordionData[0]} />
            <FaqAccordion data={FaqAccordionData[1]} />
            <FaqAccordion data={FaqAccordionData[2]} />
            <FaqAccordion data={FaqAccordionData[3]} />
            <FaqAccordion data={FaqAccordionData[4]} />
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
