import React from "react";
import { CartProvider } from "./global/CartContext";
import { Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import ProductPage from './pages/ProductPage'
import MenuPage from './pages/MenuPage'
import CheckoutPage from "./pages/CheckoutPage";

export default function App()  {
  return (
    <CartProvider>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product/:handle" element={<ProductPage />} />
          <Route path="/:catigory" element={<MenuPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
      </Routes>
    </CartProvider>
  )
}