import React from 'react'
import './FaqAccordion.css'
import { useState, useRef } from 'react';
import accordionIcon from '../../assets/icon/accordion-icon.svg'

export default function FaqAccordion({data}) {
    const [isActive, setIsActive] = useState(false)
    const elementRef = useRef(null);

    return (
        <div className={isActive ? 'faq-accordion__main-block active' : 'faq-accordion__main-block'} style={isActive ? {height: 57 + elementRef.current.clientHeight + 'px'} : {height: '57px'}} onClick={() => setIsActive(!isActive)}>
            <div className='faq-accordion-item-header'>
                <h4 className='faq-accordion__title'>{data.title}</h4>
                <img className={isActive ? 'accordion__icon active' : 'accordion__icon'} src={accordionIcon} draggable='false' />
            </div>
            <div ref={elementRef} className='faq-accordion-item-body'><p>{data.body}</p></div>
        </div>
    )
}
