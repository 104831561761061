import React from 'react'
import './ShopifySection.css'
import { useState, useEffect } from 'react'
import CardElement from '../CardElement/CardElement'
import selectIcon from '../../assets/icon/btn-select.svg'
import { products } from '../../global/cartUtils'

export default function ShopifySection({title, description, type}) {
  var showElement

  if (window.innerWidth > 1300) {
    showElement = 5
  } else if (window.innerWidth <= 1300 && window.innerWidth > 1030) {
    showElement = 4
  } else if (window.innerWidth < 1030 && window.innerWidth > 800) {
    showElement = 3
  } else if (window.innerWidth < 800 && window.innerWidth > 600) {
    showElement = 2
  } else if (window.innerWidth < 600) {
    showElement = 1
  }
  const [countElement, setCountElement] = useState(0)

  const newArr = products.filter(el => el.type === type).slice(0, 20)

  var showCard = products.filter(el => el.type === type).slice(countElement, countElement+showElement)
  
  
  return (
    <div className='shopify-section'>
      <h2 className='shopify-section__title'>{title}</h2>
      <h3 className='shopify-section__description'>{description}</h3>
      <div className='shopify-section__selection-button-block'>
        <button onClick={() => { if(countElement > 0) setCountElement(countElement-1)}} className={ countElement >= 1 ? 'shopify-section__button-select' : 'shopify-section__button-select hidden'}><img src={selectIcon} style={{ rotate: '180deg', marginRight: '3px' }} draggable='false'/></button>
        <button onClick={() => { if(countElement < 20)setCountElement(countElement+1)}} className={ countElement == 20 ? 'shopify-section__button-select hidden' : 'shopify-section__button-select'}><img src={selectIcon} style={{ marginLeft: '3px' }} draggable='false'/></button>
      </div>
      <div className='shopify-section__main-card-block'>
        { showCard.map((elem) => <CardElement key={elem.id}>{elem}</CardElement>) }
      </div>
    </div>
  )
}
