import React, {useContext} from 'react'
import './Cart.css'
import { useState, useEffect, useRef } from 'react'
import trashIcon from '../../assets/icon/trash-icon.svg'
import CartContext from '../../global/CartContext'
import { useNavigate } from 'react-router-dom';

export default function Cart() {
  const [statusCart, setStatusCart] = useState(false)
  const formatter = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  });

  const cartRef = useRef(null)
  const cartButtonRef = useRef(null)

  const navigate = useNavigate()

  const handleClickOutside = (event) => {
    if (cartButtonRef.current && cartButtonRef.current.contains(event.target)) {
      return;
    }
  
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setStatusCart(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  const { cart, increaseQuantity, decreaseQuantity, removeFromCart, totalPriceCart } = useContext(CartContext);

  function showCart() {
    if (!cart.length) {
      return(
        <div ref={cartRef} className='cart__items-block'>
          <h4 className='cart__is-empty-text'>Twój koszyk jest pusty</h4>
        </div>
      )
    } else {
      return(
        <div ref={cartRef} className='cart__items-block'>
          <h1 className='cart__total-price'>Cena całkowita: {formatter.format(totalPriceCart())}</h1>
          <div className='cart__elements-block'>
          {
            cart.map((item) => {
              return(
                <div key={item.id} className='cart__item-block'>
                  <div className='cart__item-main'>
                    <img className='cart__img-item' src={item.img[0]}/>
                    <div className='cart__item-details'>
                      <h3 className="cart__item-name">{item.name}</h3>
                      <h4 className="cart__item-price">{formatter.format(item.price)}</h4>
                      <h5 className="cart__item-incltax">{formatter.format(item.inclTax)} (z VAT)</h5>
                    </div>
                  </div>
                  <div className='cart__item-counter-block'>
                    <h3 className='cart__item-count'>{item.quantity}</h3>
                    <div className='cart__counter-block-button'>
                      <button onClick={() => increaseQuantity(item.id)} className='button__count-item plus'></button>
                      <div className='button__separator'></div>
                      <button onClick={() => decreaseQuantity(item.id)} className='button__count-item minus'></button>
                    </div>
                  </div>
                  <img onClick={() => removeFromCart(item.id)} className='btn__trash' src={trashIcon} draggable='false'/>
                </div>
              )
            })
          }
          </div>
          <button onClick={() => {navigate('/checkout')}} className='cart__btn-checkout'>Złóż zamówienie</button>
        </div>
      )
    }
  }

  return (
    <div className='cart__main-block'>
        <button ref={cartButtonRef} onClick={() => {setStatusCart(!statusCart)}} className='cart__button'>
          <span className='cart__count-items-block'>{cart.length}</span>
        </button>
        {statusCart && showCart()}
    </div>
  )
}
