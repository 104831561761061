import React from 'react'
import './MobileMenu.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function MobileMenu() {
    const [isMobileMenu, setMobile] = useState(false)

    const navigate = useNavigate();

    const [isOccasion, setOccasion] = useState(false)
    const [isHampers, setHampers] = useState(false)
    const [isFlowers, setFlowers] = useState(false)
    const [isBallons, setBallons] = useState(false)

    const occasionsElements = [{"title": "Wielkie Otwarcie", "link": "/grand-opening-flowers"},{"title": "Wyrazy Współczucia", "link": "/condolence"},{"title": "Ślub", "link": "/wedding-flower-bouquets"},{"title": "Ukończenie Szkoły", "link": "/graduation-flower-bouquets"},{"title": "Rocznica", "link": "/anniversary-flower-bouquets"},{"title": "Urodziny", "link": "/birthday-flower-bouquets"},{"title": "Gratulacje", "link": "/congratulation-flower-bouquets"},{"title": "Szybkiego Powrotu do Zdrowia", "link": "/get-well-soon-flower-bouquets"},{"title": "Noworodek", "link": "/newborn-flower-bouquets"},{"title": "Propozycja/Romantyczny", "link": "/romantic"},{"title": "Przepraszam", "link": "/sorry-flower-bouquets"},{"title": "Zaręczyny/Panieński", "link": "/engagement-flower-bouquets"}]
    const hampersElements = [{"title": "Kosze Dziecięce", "link": "/baby-hampers"},{"title": "Kosze Owocowe", "link": "/fruit-hampers"},{"title": "Kosze Gourmet", "link": "/gourmet-hampers"},{"title": "Kosze z Zestawem Herbaty", "link": "/tea-set-hampers"},{"title": "Kosze Wellness", "link": "/wellness-hampers"},{"title": "Kosze Organiczne", "link": "/organic-hampers"},{"title": "Kosze Czekoladowe", "link": "/chocolate-hampers"},{"title": "Kosze Korporacyjne", "link": "/corporate-hampers"},{"title": "Kosze z Halal Jedzeniem", "link": "/halal-food-hampers"},{"title": "Prezent z Winem", "link": "/wine-gifts"}]
    const shopFlowersElements = [{"title": "Biała Bławatek", "link": "/baby-breath-bouquet-poland"},{"title": "Lilia Calla", "link": "/calla-lily-flower-bouquets"},{"title": "Goździki", "link": "/carnation-bouquet-flowers"},{"title": "Eustoma", "link": "/eustoma-flower-bouquets"},{"title": "Hortensja", "link": "/hydrangea-bouquet-flowers"},{"title": "Lilie", "link": "/lilies"},{"title": "Róże", "link": "/rose-bouquets-poland"},{"title": "Słonecznik", "link": "/sunflower-bouquets-poland"},{"title": "Tulipan", "link": "/tulip-flower-bouquets"}]
    const flowersBalloonElements = [{"title": "Noworodek/Dziecięce FNB", "link": "/newborn-fnb"},{"title": "Życzenia/Sytuacje FNB", "link": "/get-well-soon-fnb"},{"title": "Szczęśliwych Urodzin FNB", "link": "/happy-birthday-fnb"},{"title": "Gratulacje FNB", "link": "/congratulations"},{"title": "Rocznica FNB", "link": "/anniversary-fnb"},{"title": "Ukończenie Szkoły FNB", "link": "/graduation-fnb"}]

    return (
        <div className='mobile__menu-main-block'>
            <button onClick={() => {setMobile(!isMobileMenu)}} className='mobile__menu-button'></button>
            {isMobileMenu && (
                <div className='mobile__menu-item-block'>
                    <button onClick={() => {setMobile(!isMobileMenu)}} className='mobile__menu-button-close'></button>
                    <div className='mobile__menu-buttons-block'>
                        <button onClick={() => {navigate('/'); setMobile(!isMobileMenu)}} className='mobile__menu-element-button'>Strona główna</button>
                        <button onClick={() => {navigate('/graduation-flower-bouquets'); setMobile(!isMobileMenu)}} className='mobile__menu-element-button'>Ukończenie szkoły</button>
                        <div>
                            <button onClick={() => {setOccasion(!isOccasion)}} className={isOccasion ? 'mobile__menu-element-button active' : 'mobile__menu-element-button'}>Okazje</button>
                            { isOccasion &&
                                occasionsElements.map((el) => {
                                    return(
                                        <button onClick={() => {navigate(el.link); setMobile(!isMobileMenu)}} className='mobile__menu-element-button sub'>{el.title}</button>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <button onClick={() => {setHampers(!isHampers)}} className={isHampers ? 'mobile__menu-element-button active' : 'mobile__menu-element-button'}>Kosze upominkowe</button>
                            { isHampers &&
                                hampersElements.map((el) => {
                                    return(
                                        <button onClick={() => {navigate(el.link); setMobile(!isMobileMenu)}} className='mobile__menu-element-button sub'>{el.title}</button>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <button onClick={() => {setFlowers(!isFlowers)}} className={isFlowers ? 'mobile__menu-element-button active' : 'mobile__menu-element-button'}>Sklep Kwiaty</button>
                            { isFlowers &&
                                shopFlowersElements.map((el) => {
                                    return(
                                        <button onClick={() => {navigate(el.link); setMobile(!isMobileMenu)}} className='mobile__menu-element-button sub'>{el.title}</button>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <button onClick={() => {setBallons(!isBallons)}} className={isBallons ? 'mobile__menu-element-button active' : 'mobile__menu-element-button'}>Kwiaty i Balony</button>
                            { isBallons &&
                                flowersBalloonElements.map((el) => {
                                    return(
                                        <button onClick={() => {navigate(el.link); setMobile(!isMobileMenu)}} className='mobile__menu-element-button sub'>{el.title}</button>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
